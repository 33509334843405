import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import EditHotelCard from '../../components/EditCards/EditHotelCard';
import CardsWrapper from '../../components/EditCards/CardsWrapper';
import type { ISingleEventHotelsView } from './interfaces/ISingleEventHotelsView';

function SingleEventHotelsView({
  control,
  hotels,
  onCancel,
  onSave,
  onAppendEmptyHotel,
  onRemoveHotel,
  isSubmitting,
}: ISingleEventHotelsView): React.ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <Paper
        sx={{ px: 8, py: 5, mb: 3 }}
        elevation={0}
      >
        <Typography component="h2" variant="h4" mb={4}>
          {t('events.editHotels')}
        </Typography>
        <CardsWrapper
          onAppend={onAppendEmptyHotel}
          appendText={t('events.addHotel')}
        >
          {hotels.map((hotel, index) => (
            <EditHotelCard
              key={`HotelCard_${hotel.id}`}
              cardIndex={index}
              control={control}
              onRemove={() => onRemoveHotel(index)}
            />
          ))}
        </CardsWrapper>
      </Paper>
      <Stack spacing={1.5} direction="row" justifyContent="space-between">
        <Button
          color="error"
          onClick={onCancel}
          sx={{ width: 140 }}
        >
          {t('events.cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={onSave}
          disabled={isSubmitting}
          sx={{ width: 126 }}
        >
          {t('events.save')}
        </Button>
      </Stack>
    </>
  );
}

export default SingleEventHotelsView;
