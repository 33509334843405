// Single event schedule view
export const SingleEventScheduleTabs = [
  'poolPlay',
  'playOff',
];

// Single event standings tabs
export const SingleEventStandingsTabs = [
  'poolPlay',
  'overall',
];

// Events page view
export const EventsPageTabs = [
  'all',
  'today',
  'thisWeek',
  'nextWeek',
];

// Single Event page view
export const SingleEventPageTabs = [
  'overview',
  'eventDetails',
  'analytics',
  'tickets',
  'divisionsAndTeams',
  'games',
];
export const NetSalesChartWidgetTabs = ['day', 'week', 'month'];
export const CheckInsChartWidgetTabs = ['teams', 'spectators'];
export const SalesTicketsWidgetTabs = ['teams', 'spectators'];
