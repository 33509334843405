import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import TeamsTicketsForm from '../../components/TeamsTicketsForm/TeamsTicketsForm';
import { EVENT_FORM_TYPES } from '../../constants/general';
import type { ISingleEventDivisionsView } from './interfaces/ISingleEventDivisionsView';

/**
 * Single Event Divisions page view
 */
function SingleEventDivisionsView({
  eventName,
  control,
  divisions,
  eventEndDate,
  eventStartDate,
  maxRegistrationDate,
  onRemoveDivision,
  onRemoveDiscount,
  onPrecreateDivisions,
  onAddOneDivision,
  onAppendDiscount,
  onCreateSubmit,
  onCancelEdit,
  isSubmitting,
  isPublished,
  maxDiscountDate,
  onPrecreateDevisionName,
}: ISingleEventDivisionsView): React.ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <Paper sx={{ px: 8, py: 5, mb: 3 }} elevation={0}>
        <TeamsTicketsForm
          type={EVENT_FORM_TYPES.EDIT}
          control={control}
          title={t('events.editTeamTickets')}
          eventName={eventName}
          eventStartDate={eventStartDate}
          eventEndDate={eventEndDate}
          maxRegistrationDate={maxRegistrationDate}
          divisions={divisions}
          onPrecreateDivisions={onPrecreateDivisions}
          onRemoveDivision={onRemoveDivision}
          onRemoveDiscount={onRemoveDiscount}
          onAddOneDivision={onAddOneDivision}
          onAppendDiscount={onAppendDiscount}
          isPublished={isPublished}
          maxDiscountDate={maxDiscountDate}
          onPrecreateDevisionName={onPrecreateDevisionName}
        />
      </Paper>
      <Stack spacing={1.5} direction="row" justifyContent="space-between">
        <Button
          variant="outlined"
          color="error"
          onClick={onCancelEdit}
          sx={{ width: 140 }}
        >
          {t('events.cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={onCreateSubmit}
          disabled={isSubmitting}
          sx={{ width: 126 }}
        >
          {t('events.save')}
        </Button>
      </Stack>
    </>
  );
}

export default SingleEventDivisionsView;
