import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';

import SingleEventStandingsView from './SingleEventStandingsView';
import useTablePagination from '../../hooks/useTablePagination';
import { useAppSelector } from '../../store/hooks/useApp';
import { selectUserOrg } from '../../store/slices/user/selectors';
import { useGetDivisionsListQuery } from '../../store/slices/divisions/apis/divisionsApi';
import {
  useGetDivisionGamesOverallPlaysQuery,
  useGetDivisionGamesPoolPlaysQuery, useGetDivisionGamesPoolSelectsQuery, useGetDivisionGamesPoolTeamsSelectsQuery,
} from '../../store/slices/divisionGamesPool/apis/divisionGamesPool';
import type { IScheduleSelectItem } from '../../components/ScheduleSelect/interfaces/IScheduleSelect';
import { DEFAULT_MAX_PER_PAGE } from '../../constants/general';

function SingleEventStandings() {
  const { eventId } = useParams() as { eventId: string };
  const organization = useAppSelector(selectUserOrg);
  const { data: divisionsData } = useGetDivisionsListQuery(
    organization
      ? { eventId: +eventId, orgId: organization.id }
      : skipToken,
  );
  const [tabValue, setTabValue] = useState<number>(0);
  const [divisionTabValue, setDivisionTabValue] = useState<number | null>(null);
  const [selectedPoolNames, setSelectedPoolNames] = useState<IScheduleSelectItem[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<IScheduleSelectItem[]>([]);
  const pagination = useTablePagination();

  const divisionPlaysQueryRequestParams = organization?.id && eventId && divisionTabValue !== null
    ? {
      orgId: organization.id,
      eventId: +eventId,
      divisionId: divisionTabValue,
      page: pagination.page + 1,
      perPage: pagination.perPage,
    }
    : skipToken;

  const divisionGamesPoolPlays = useGetDivisionGamesPoolPlaysQuery(divisionPlaysQueryRequestParams);
  const divisionGamesOverallPlays = useGetDivisionGamesOverallPlaysQuery(divisionPlaysQueryRequestParams);

  const divisionGamesPoolSelect = useGetDivisionGamesPoolSelectsQuery(organization && divisionTabValue ? {
    orgId: organization.id,
    eventId: +eventId,
    eventDivisionId: divisionTabValue,
    perPage: DEFAULT_MAX_PER_PAGE,
  } : skipToken);

  const divisionGamesPoolTeamsSelect = useGetDivisionGamesPoolTeamsSelectsQuery(organization && divisionTabValue ? {
    orgId: organization.id,
    eventId: +eventId,
    eventDivisionId: divisionTabValue,
    perPage: DEFAULT_MAX_PER_PAGE,
  } : skipToken);

  const currentDisplayedPlays = tabValue === 0 ? divisionGamesPoolPlays : divisionGamesOverallPlays;

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    pagination.handleChangePage(null, 0);
    setSelectedPoolNames([]);
  };

  const handleDivisionTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setDivisionTabValue(newValue);
    pagination.handleChangePage(null, 0);
    setSelectedTeams([]);
  };

  useEffect(() => {
    if (divisionsData?.data && divisionsData.data.length > 0) {
      setDivisionTabValue(divisionsData.data[0].id);
    }
  }, [divisionsData]);

  useEffect(() => {
    setSelectedTeams([]);
    setSelectedPoolNames([]);
  }, [tabValue, divisionTabValue]);

  return (
    <SingleEventStandingsView
      tabValue={tabValue}
      divisionTabValue={divisionTabValue}
      standings={currentDisplayedPlays?.data?.data || []}
      divisionsTabs={divisionsData?.data.map(({ id, name }) => ({ value: id, name })) || []}
      pagination={{ total: currentDisplayedPlays?.data?.meta.total || 0, ...pagination }}
      onTabChange={handleTabChange}
      onDivisionTabChange={handleDivisionTabChange}
      divisionGamesPoolSelect={divisionGamesPoolSelect?.data?.data || []}
      divisionGamesPoolTeamsSelect={divisionGamesPoolTeamsSelect?.data?.data || []}
      selectedPoolNames={selectedPoolNames}
      setSelectedPoolNames={setSelectedPoolNames}
      selectedTeams={selectedTeams}
      setSelectedTeams={setSelectedTeams}
    />
  );
}

export default SingleEventStandings;
