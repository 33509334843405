import type { BaseQueryFn, FetchArgs } from '@reduxjs/toolkit/query/react';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import queryString from 'query-string';

import { logout, setTokens } from '../slices/auth/slice';
import AUTHENTICATED_USER from '../tags/auth';
import EVENTS_LIST_TAG from '../tags/events';
import { EVENT_DIVISIONS_LIST_TAG } from '../tags/divisions';
import { EVENT_HOTELS_LIST_TAG } from '../tags/hotels';
import { EVENT_PARTNERS_LIST_TAG } from '../tags/partners';
import { EVENT_WAIVERS_LIST_TAG } from '../tags/waivers';
import { SCHEDULE_BRACKET_TAG } from '../tags/schedule';
import PAYMENTS_STATUS from '../tags/payment';
import { EVENT_TEAM_TICKET_RULES_LIMITS_TAG, EVENT_TEAM_TICKET_RULES_TAG } from '../tags/teamTicketRules';
import { POOL_PLAYS_SCHEDULE_GAME_TAG, POOL_PLAYS_SCHEDULE_TAG } from '../tags/poolPlays';
import { EVENT_NOTIFICATIONS_LIST_TAG } from '../tags/notifications';
import { AUTH_REFRESH_TOKEN } from '../../constants/requestUrls';
import { USER_DEVICE_ID } from '../../constants/localStorageKeys';
import { ANALYTICS_TEAM_PER_DIVISION_TAG } from '../tags/analytics';
import type { RootState } from '../types/TStore';
import type IBaseQueryResponse from '../types/IBaseQueryResponse';
import type ITokens from '../slices/auth/interfaces/ITokens';

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API,
  prepareHeaders: (headers, { getState }) => {
    const { tokens } = (getState() as RootState).auth;
    if (tokens?.accessToken) {
      headers.set('authorization', `Bearer ${tokens.accessToken}`);
    }
    return headers;
  },
  paramsSerializer: (params: Record<string, any>) => queryString.stringify(params),
});

const customFetchBase: BaseQueryFn<
string | FetchArgs,
unknown,
FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error?.status === 401) {
    if (!mutex.isLocked()) {
      const { tokens } = (api.getState() as RootState).auth;
      const release = await mutex.acquire();

      try {
        const refreshResult = await baseQuery(
          {
            url: AUTH_REFRESH_TOKEN,
            method: 'POST',
            body: {
              refreshToken: tokens?.refreshToken,
              deviceId: localStorage.getItem(USER_DEVICE_ID),
            },
          },
          api,
          extraOptions,
        );

        if (refreshResult.data) {
          const { data } = refreshResult as { data: IBaseQueryResponse<ITokens> };

          api.dispatch(setTokens(data));
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logout());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

const mainApi = createApi({
  reducerPath: 'mainApi',
  tagTypes: [
    AUTHENTICATED_USER,
    EVENTS_LIST_TAG,
    PAYMENTS_STATUS,
    EVENT_HOTELS_LIST_TAG,
    EVENT_PARTNERS_LIST_TAG,
    EVENT_WAIVERS_LIST_TAG,
    EVENT_DIVISIONS_LIST_TAG,
    EVENT_TEAM_TICKET_RULES_TAG,
    EVENT_TEAM_TICKET_RULES_LIMITS_TAG,
    POOL_PLAYS_SCHEDULE_TAG,
    POOL_PLAYS_SCHEDULE_GAME_TAG,
    SCHEDULE_BRACKET_TAG,
    EVENT_NOTIFICATIONS_LIST_TAG,
    ANALYTICS_TEAM_PER_DIVISION_TAG,
  ],
  baseQuery: customFetchBase,
  endpoints: () => ({}),
});

export default mainApi;
