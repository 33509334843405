import clsx from 'clsx';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import useStyles from './messageSectionStyle-tss';
import type IMessageSection from '../interfaces/IMessageSection';

function MessageSection({
  control,
}: IMessageSection) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Paper sx={{ p: 3 }}>
      <Stack spacing={3}>
        <Typography variant="body1" component="h2">
          {t('notifications.notificationText')}
        </Typography>
        <Box>
          <Controller
            name="title"
            control={control}
            render={({
              field: { value, onChange, ...props },
              fieldState: { error: fieldError },
            }) => (
              <Box className={classes.root}>
                <TextField
                  className={clsx([classes.input, classes.title])}
                  value={value}
                  label={t('notifications.subject')}
                  variant="outlined"
                  fullWidth
                  error={!!fieldError}
                  helperText={fieldError?.message}
                  onChange={(e) => onChange(e.target.value.slice(0, 40))}
                  {...props}
                />
                <Typography
                  className={clsx([classes.helper, classes.helperRight])}
                  variant="body1"
                  color="#757575"
                >
                  {`${value?.length || 0}/40`}
                </Typography>
              </Box>
            )}
          />
        </Box>
        <Box>
          <Controller
            name="message"
            control={control}
            render={({
              field: { value, onChange, ...props },
              fieldState: { error: fieldError },
            }) => (
              <Box className={classes.root}>
                <TextField
                  className={clsx([classes.input, classes.text])}
                  label={t('notifications.message')}
                  value={value}
                  variant="outlined"
                  multiline
                  minRows={3}
                  fullWidth
                  error={!!fieldError}
                  helperText={fieldError?.message}
                  onChange={(e) => onChange(e.target.value.slice(0, 200))}
                  {...props}
                />
                <Typography
                  className={clsx([classes.helper, classes.helperBottom])}
                  sx={{ bottom: fieldError ? '32px' : '8px' }}
                  variant="body1"
                  color="#757575"
                >
                  {`${value?.length || 0}/200`}
                </Typography>
              </Box>
            )}
          />
        </Box>
      </Stack>
    </Paper>
  );
}

export default MessageSection;
