import { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';

import DocumentsView from './DocumentsView';
import {
  useCreateEventWaiverMutation,
  useDeleteWaiverByEventMutation,
  useUpdateEventWaiverMutation,
} from '../../store/slices/waivers/apis/waiversApi';
import {
  useCreateEventRuleMutation,
  useDeleteEventRuleMutation,
  useUpdateEventRuleMutation,
} from '../../store/slices/rules/apis/rulesApi';
import { addCompletedStep, setLeaveModal } from '../../store/slices/createEvent/slice';
import { useAppDispatch } from '../../store/hooks/useApp';
import { createEventStepThreeSchema } from '../../utils/validators';
import { getErrorMessage } from '../../utils/helpers';
import AppRoutes from '../../constants/AppRoutes';
import usePageData from '../../hooks/useGetPageData';
import type { IFileWithPath } from '../../interfaces/IFileWithPath';
import type { IDocuments } from '../../components/DocumentForm/interfaces/IDocumentsForm';
import type ICreateEventContext from '../../layout/CreateEventLayout/interfaces/ICreateEventContext';

function Documents(): React.ReactElement {
  const [isWaiverLoading, setIsWaiverLoading] = useState(false);
  const [isRuleLoading, setIsRuleLoading] = useState(false);
  const {
    eventId, orgId, activeStep, isFetch,
  } = useOutletContext<ICreateEventContext>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    isEditPage, event, eventWaivers, eventRules,
  } = usePageData(eventId, orgId, isFetch);

  const [createEventWaiver] = useCreateEventWaiverMutation();
  const [updateEventWaiver] = useUpdateEventWaiverMutation();
  const [deleteWaiverByEvent] = useDeleteWaiverByEventMutation();
  const [createEventRule] = useCreateEventRuleMutation();
  const [updateEventRule] = useUpdateEventRuleMutation();
  const [deleteEventRule] = useDeleteEventRuleMutation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
  } = useForm<IDocuments>({
    resolver: yupResolver<IDocuments>(createEventStepThreeSchema),
    mode: 'onBlur',
    defaultValues: {
      waivers: eventWaivers || [],
      rules: eventRules || [],
    },
  });

  const {
    fields: waivers,
    append: appendWaiver,
    remove: handleRemoveWaiverByForm,
  } = useFieldArray({ control, name: 'waivers' });

  const {
    fields: rules,
    append: appendRules,
    remove: handleRemoveRulesByForm,
  } = useFieldArray({ control, name: 'rules' });

  const handleAppendWaivers = (files: IFileWithPath[]) => {
    const newWaivers = files.map(({ file }) => {
      const name = file.name.split('.').slice(0, -1).join('.');

      return ({ name, file });
    });
    appendWaiver(newWaivers);
  };

  const handleAppendRule = (files: IFileWithPath[]) => {
    const newRules = files.map(({ file }) => {
      const name = file.name.split('.').slice(0, -1).join('.');

      return ({ name, file });
    });
    appendRules(newRules);
  };

  const handleNextStep = () => {
    const link = isEditPage
      ? AppRoutes.createEvent.concat('?editPage=true')
      : AppRoutes.createEvent;

    if (isDirty) {
      dispatch(setLeaveModal({ isOpen: true, link }));
    } else if (isEditPage) {
      navigate(AppRoutes.hotelsAndPartners.concat('?editPage=true'));
    } else {
      navigate(AppRoutes.hotelsAndPartners);
    }
  };

  const handleStepClick = useCallback((link: string) => {
    const currentLink = isEditPage
      ? link.concat('?editPage=true')
      : link;

    if (isDirty) {
      dispatch(setLeaveModal({ isOpen: true, link: currentLink }));
    } else {
      navigate(currentLink);
    }
  }, [isDirty, isEditPage, dispatch, navigate]);

  const handleCreateSubmit = handleSubmit((values) => {
    if (values.waivers && values.rules) {
      const waiversPromises = values.waivers.map((waiver) => {
        if (!waiver.documentId) {
          setIsWaiverLoading(true);
        }
        const data = {
          orgId,
          eventId: +eventId,
          title: waiver.name,
        };

        return waiver.documentId
          ? updateEventWaiver({ ...data, waiverId: waiver.documentId })
          : createEventWaiver({ ...data, file: waiver.file as File });
      });

      const rulesPromises = values.rules.map((rule) => {
        if (!rule.documentId) {
          setIsRuleLoading(true);
        }

        const data = {
          orgId,
          eventId: +eventId,
          title: rule.name,
        };

        return rule.documentId
          ? updateEventRule({ ...data, ruleId: rule.documentId })
          : createEventRule({ ...data, file: rule.file as File });
      });

      const waiversIdsToDelete = eventWaivers
        .filter((waiver) => !values?.waivers?.some((item) => item.documentId === waiver.documentId))
        .map((waiver) => waiver.documentId);

      const rulesIdsToDelete = eventRules
        .filter((rule) => !values?.rules?.some((item) => item.documentId === rule.documentId))
        .map((rule) => rule.documentId);

      const waiversDeletePromises = waiversIdsToDelete.length
        ? waiversIdsToDelete.map((waiverId) => deleteWaiverByEvent({
          orgId,
          eventId: +eventId,
          waiverId,
        })) : undefined;

      const rulesDeletePromises = rulesIdsToDelete.length ? rulesIdsToDelete.map((ruleId) => deleteEventRule({
        orgId,
        eventId: +eventId,
        ruleId,
      })) : undefined;

      const requestPromises = [
        ...waiversPromises,
        ...rulesPromises,
        waiversDeletePromises,
        rulesDeletePromises,
      ].filter(Boolean);

      Promise.all(requestPromises).then((res) => {
        setIsWaiverLoading(false);
        setIsRuleLoading(false);

        const errors = res
          .map((item) => (item && 'error' in item ? getErrorMessage(item.error) : null))
          .filter(Boolean);

        if (errors.length > 0) {
          errors.forEach((errorMessage) => {
            toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT });
          });
        } else {
          dispatch(addCompletedStep(activeStep));
          navigate(AppRoutes.teamsTickets);
          if (isEditPage) {
            navigate(AppRoutes.teamsTickets.concat('?editPage=true'));
          } else {
            navigate(AppRoutes.teamsTickets);
          }
          reset();
        }
      });
    }
  });

  useEffect(() => {
    reset({ waivers: eventWaivers, rules: eventRules });
  }, [reset, eventWaivers, eventRules]);

  return (
    <DocumentsView
      eventName={event.nameWithTagline}
      control={control}
      waivers={waivers}
      rules={rules}
      activeStep={activeStep}
      onStepClick={handleStepClick}
      onAppendWaiver={handleAppendWaivers}
      onRemoveWaiver={handleRemoveWaiverByForm}
      onAppendRule={handleAppendRule}
      onRemoveRule={handleRemoveRulesByForm}
      onCreateSubmit={handleCreateSubmit}
      onSkipStep={handleNextStep}
      isSubmitting={isSubmitting}
      withConfirm={event.published}
      isWaiverLoading={isWaiverLoading}
      isRuleLoading={isRuleLoading}
    />
  );
}

export default Documents;
