import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type IScheduleSlice from './interfaces/IScheduleSlice';

export const initialState: IScheduleSlice = {
  bracket: {
    teams: [],
  },
};

export const scheduleSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setBracketData: (state, { payload }: PayloadAction<IScheduleSlice['bracket']>) => {
      state.bracket = { ...state.bracket, ...payload };
    },
  },
});

export const { setBracketData } = scheduleSlice.actions;

export default scheduleSlice.reducer;
