import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import SingleEventTeamsView from './SingleEventTeamsView';
import { useAppDispatch, useAppSelector } from '../../store/hooks/useApp';
import { selectUserOrg } from '../../store/slices/user/selectors';
import { selectEventDivisions } from '../../store/slices/divisions/selectors';
import {
  useGetEventTeamsQuery,
  useLazyGetClubsQuery,
  useLazyGetCoachesQuery,
} from '../../store/slices/teams/apis/teamsApi';
import { useGetDivisionsListQuery } from '../../store/slices/divisions/apis/divisionsApi';
import { resetTeamsState, setTeamsState } from '../../store/slices/teams/slice';
import selectTeamsPageFilters from '../../store/slices/teams/selectors';
import useTablePagination from '../../hooks/useTablePagination';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import { teamsFiltersSchema } from '../../utils/validators';
import { toWhereFormat } from '../../utils/helpers';
import { ORDERS } from '../../constants/general';
import type ISingleEventTeamsFiltersForm from './interfaces/ISingleEventTeamsFiltersForm';
import type { ISearchInputHandler } from '../../components/SearchInput/interfaces/ISearchInput';
import type { ITableSortHandler } from '../../components/TableSort/interfaces/ITableSort';

function SingleEventTeams(): React.ReactElement {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { eventId } = useParams();
  const organization = useAppSelector(selectUserOrg);
  const filters = useAppSelector(selectTeamsPageFilters);
  const pagination = useTablePagination();
  const [tabValue, setTabValue] = useState(0);

  const areIdsSettled = organization?.id && eventId;

  const { eventDivisions } = useGetDivisionsListQuery(
    areIdsSettled ? { orgId: organization?.id as number, eventId: +eventId } : skipToken,
    {
      selectFromResult: (result) => ({ ...result, eventDivisions: selectEventDivisions(result.data) }),
    },
  );
  const [getClubs, { data: clubs }] = useLazyGetClubsQuery();
  const [getCoaches, { data: coaches }] = useLazyGetCoachesQuery();

  const { data: teams } = useGetEventTeamsQuery(
    areIdsSettled ? {
      orgId: organization.id as number,
      eventId: +eventId,
      search: filters.search,
      orderBy: `${filters.orderBy}:${filters.order}`,
      where: toWhereFormat({
        clubId: filters.clubId && `int(${filters.clubId})`,
        coachId: filters.coachId && `int(${filters.coachId})`,
        eventDivisionId: filters.eventDivisionId && `int(${filters.eventDivisionId})`,
      }),
      page: pagination.page + 1,
      perPage: pagination.perPage,
    } : skipToken,
  );

  const defaultValues = useMemo(() => ({
    search: filters.search,
    clubSearch: '',
    coachSearch: '',
    sort: {
      order: filters.order,
      orderBy: filters.orderBy,
    },
    clubId: filters.clubId,
    coachId: filters.coachId,
    eventDivisionId: filters.eventDivisionId,
  }), [filters]);

  const {
    control,
    getValues,
    reset,
  } = useForm<ISingleEventTeamsFiltersForm>({
    resolver: yupResolver<ISingleEventTeamsFiltersForm>(teamsFiltersSchema),
    defaultValues,
  });

  const divisionsTabsList = useMemo(() => eventDivisions
    .reduce((acc, division) => [...acc, { value: division.id, name: division.name }], [{
      value: 0,
      name: t('events.all'),
    }]), [eventDivisions, t]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    const divisionId = newValue ? `${newValue}` : '';
    dispatch(setTeamsState({ eventDivisionId: divisionId }));
    pagination.handleChangePage(null, 0);
  };

  const debouncedSearch = useDebouncedCallback(() => {
    const { search } = getValues();
    dispatch(setTeamsState({ search }));
    pagination.handleChangePage(null, 0);
    if (divisionsTabsList.length > 2) {
      setTabValue(0);
      dispatch(setTeamsState({ eventDivisionId: '' }));
    }
    pagination.handleChangePage(null, 0);
  });

  const debouncedSort = useDebouncedCallback(() => {
    const { sort: { order, orderBy } } = getValues();
    dispatch(setTeamsState({ order, orderBy }));
  });

  const handleSearchChange = ({ search, onChange }: ISearchInputHandler) => {
    onChange(search);
    debouncedSearch();
  };

  const handleSortChange = ({ property, onChange }: ITableSortHandler) => {
    const { sort: { order, orderBy } } = getValues();
    const isAsc = orderBy === property && order === ORDERS.ASC;

    onChange({ orderBy: property, order: isAsc ? ORDERS.DESC : ORDERS.ASC });
    debouncedSort();
  };

  const handleApplyFilters = () => {
    const { clubId, coachId } = getValues();
    dispatch(setTeamsState({ clubId, coachId }));
    pagination.handleChangePage(null, 0);
  };

  const handleResetFilters = () => {
    reset({
      search: filters.search,
      clubSearch: '',
      coachSearch: '',
      sort: {
        order: filters.order,
        orderBy: filters.orderBy,
      },
      clubId: '',
      coachId: '',
      eventDivisionId: filters.eventDivisionId,
    });
  };

  const handleClubSearch = () => {
    const { clubSearch } = getValues();

    if (areIdsSettled) {
      getClubs({
        orgId: organization?.id as number,
        eventId: +eventId,
        search: clubSearch,
      });
    }
  };

  const handleCoachSearch = () => {
    const { coachSearch } = getValues();

    if (areIdsSettled) {
      getCoaches({
        orgId: organization?.id as number,
        eventId: +eventId,
        search: coachSearch,
      });
    }
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => () => {
    dispatch(resetTeamsState());
  }, [dispatch]);

  return (
    <SingleEventTeamsView
      control={control}
      teams={teams?.data || []}
      pagination={{ ...pagination, total: teams?.meta.total || 0 }}
      divisions={divisionsTabsList}
      tabValue={tabValue}
      onTabChange={handleTabChange}
      onSearchChange={handleSearchChange}
      onSortChange={handleSortChange}
      onApplyFilters={handleApplyFilters}
      onResetFilters={handleResetFilters}
      onSearchClub={handleClubSearch}
      onSearchCoach={handleCoachSearch}
      clubs={clubs?.data || []}
      coaches={coaches?.data || []}
    />
  );
}

export default SingleEventTeams;
