import { useTranslation } from 'react-i18next';
import { Controller, useWatch } from 'react-hook-form';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { DEFAULT_DATE_FORMAT, TIME_STEPS_FOR_TIME_PICKER } from '../../../constants/general';
import type { IEventDatesSection } from '../interfaces/IEventDatesSection';

export default function EventDatesSection({ control }: IEventDatesSection) {
  const { t } = useTranslation();
  const startAt = useWatch({ control, name: 'startEventDate' });
  const endAt = useWatch({ control, name: 'endEventDate' });

  return (
    <Paper sx={{ px: 3, pt: 3, pb: 6 }}>
      <Grid container rowSpacing={3} columnSpacing={5}>
        <Grid item xs={12}>
          <Typography component="h4" variant="h6">
            {t('events.eventDates')}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="startEventDate"
            control={control}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error: fieldError },
            }) => (
              <DatePicker
                label={t('events.startDate')}
                value={value}
                onChange={onChange}
                onClose={onBlur}
                maxDate={endAt}
                slotProps={{
                  textField: {
                    error: !!fieldError,
                    helperText: fieldError?.message,
                    fullWidth: true,
                    onBlur,
                  },
                }}
                format={DEFAULT_DATE_FORMAT}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="startEventDate"
            control={control}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error: fieldError },
            }) => (
              <TimePicker
                label={t('events.startTime')}
                value={value}
                onChange={onChange}
                onClose={onBlur}
                maxTime={endAt}
                timeSteps={TIME_STEPS_FOR_TIME_PICKER}
                slotProps={{
                  textField: {
                    error: !!fieldError,
                    helperText: fieldError?.message,
                    fullWidth: true,
                    onBlur,
                  },
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="endEventDate"
            control={control}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error: fieldError },
            }) => (
              <DatePicker
                label={t('events.endDate')}
                value={value}
                onChange={onChange}
                onClose={onBlur}
                minDate={startAt}
                slotProps={{
                  textField: {
                    error: !!fieldError,
                    helperText: fieldError?.message,
                    fullWidth: true,
                    onBlur,
                  },
                }}
                format={DEFAULT_DATE_FORMAT}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="endEventDate"
            control={control}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error: fieldError },
            }) => (
              <TimePicker
                label={t('events.endTime')}
                value={value}
                onChange={onChange}
                onClose={onBlur}
                minTime={startAt}
                timeSteps={TIME_STEPS_FOR_TIME_PICKER}
                slotProps={{
                  textField: {
                    error: !!fieldError,
                    helperText: fieldError?.message,
                    fullWidth: true,
                    onBlur,
                  },
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
