import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import { Controller } from 'react-hook-form';
import IconButton from '@mui/material/IconButton';

import type ISearchInput from './interfaces/ISearchInput';
import useStyles from './searchStyles-tss';

/**
 * UI component for displaying search input with autocomplete.
 */
function SearchInput({
  control,
  onSearchChange,
  name = 'search',
}: ISearchInput) {
  const { classes } = useStyles();

  return (
    <Paper sx={{ px: 2, py: 1 }} elevation={0}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            variant="standard"
            type="text"
            placeholder="Search"
            InputProps={{
              classes,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: value ? (
                <InputAdornment position="end">
                  <IconButton onClick={() => onSearchChange({ search: '', onChange })}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ) : null,
              disableUnderline: true,
            }}
            onChange={({ target }) => onSearchChange({ search: target.value, onChange })}
            value={value}
            fullWidth
          />
        )}
      />
    </Paper>
  );
}

export default SearchInput;
