import '../../styles/calendar.scss';

import { useTranslation } from 'react-i18next';
import {
  useMemo, useCallback, useState, memo, useRef, useEffect,
} from 'react';
import { DateTime } from 'luxon';
import { Calendar, luxonLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import type { DateLocalizer, Culture } from 'react-big-calendar';
import type { DragEvent } from 'react';
import type {
  DragFromOutsideItemArgs,
  EventInteractionArgs,
} from 'react-big-calendar/lib/addons/dragAndDrop';

import GameCalendarEvent from './GameCalendarEvent';
import UnscheduledList from './UnscheduledList';
import GhostIcon from '../Icons/GhostIcon';
import { checkDatesInTimeFrames, getTimeFrameByDate } from '../../utils/bracket';
import overlap from '../../utils/overlap';
import COLORS from '../../constants/colors';
import useStyles from './calendarStyle-tss';
import type IGameCalendar from './interfaces/IGameCalendar';
import type { ICalendarEvent } from './interfaces/ICalendarEvent';
import type { ICalendarEventUnschedule } from './interfaces/ICalendarEventUnschedule';
import type IEventPlayingField from '../../store/slices/poolPlays/interfaces/IEventPlayingField';

const DnDCalendar = withDragAndDrop<ICalendarEvent, IEventPlayingField>(Calendar);

function GameCalendar({
  games, unscheduledList, fields, timezone,
  onAddFromUnscheduled, onMoveGame, onSelectGame, isEditable,
  onOpenBuyGames, timeFrames, date, noScroll, onPreventScroll,
}: IGameCalendar) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [draggedEvent, setDraggedEvent] = useState<ICalendarEventUnschedule | null>();
  const ref = useRef<HTMLDivElement | null>(null);

  const eventPropGetter = useCallback(({ division, hidden }: ICalendarEvent) => ({
    className: `rbc-division-${division}${hidden ? ' rbc-event-disabled' : ''}`,
  }), []);

  const onDragStart = useCallback((event: ICalendarEventUnschedule) => {
    setDraggedEvent(event);
  }, []);

  const onDragOver = useCallback((dragEvent: DragEvent) => {
    dragEvent.preventDefault();
  }, []);

  const onEventDrop = useCallback(
    ({
      event, start, resourceId,
    }: EventInteractionArgs<ICalendarEvent>) => {
      if (onMoveGame) {
        const timeFrame = getTimeFrameByDate(start, timeFrames);

        onMoveGame({
          ...event,
          timeFrame,
          startAt: start,
          resourceId: resourceId ? +resourceId : undefined,
        });
      }
    },
    [timeFrames, onMoveGame],
  );

  const onDropFromOutside = useCallback(
    ({
      start, end, resource,
    }: DragFromOutsideItemArgs & { resource?: number | string }) => {
      if (draggedEvent && onAddFromUnscheduled) {
        const timeFrame = getTimeFrameByDate(start, timeFrames);

        const event = {
          ...draggedEvent,
          timeFrame,
          startAt: start,
          endAt: end,
          resourceId: resource ? +resource : undefined,
        };
        onAddFromUnscheduled(event);
        setDraggedEvent(null);
      }
    },
    [draggedEvent, setDraggedEvent, onAddFromUnscheduled, timeFrames],
  );

  const slotPropGetter = useCallback(
    (slotDate: Date) => {
      const inFrame = checkDatesInTimeFrames([slotDate], timeFrames, timezone);

      return {
        style: inFrame ? {
          background: COLORS.bcPrimaryDark,
        } : undefined,
      };
    },
    [timezone, timeFrames],
  );

  const components = useMemo(() => ({
    event: GameCalendarEvent,
  }), []);

  const timeGutterFormat = useCallback((time: Date, culture?: Culture, localizer?: DateLocalizer) => (
    localizer
      ? localizer.format(time, 'h a', culture)
      : DateTime.fromJSDate(time).toFormat('h a')
  ), []);

  const eventTimeRangeFormat = useCallback((
    { start, end }: { start: Date, end: Date },
    culture?: Culture,
    localizer?: DateLocalizer,
  ) => (
    localizer
      ? `${localizer.format(start, 'hh:mm a', culture)} - ${localizer.format(end, 'hh:mm a', culture)}`
      : `${DateTime.fromJSDate(start).toFormat('hh:mm a')} - ${DateTime.fromJSDate(end).toFormat('hh:mm a')}`
  ), []);

  const {
    dateLocalizer,
    getNow,
    formats,
  } = useMemo(() => ({
    dateLocalizer: luxonLocalizer(DateTime),
    getNow: () => DateTime.local().toJSDate(),
    formats: {
      eventTimeRangeFormat,
      timeGutterFormat,
      eventTimeRangeStartFormat: eventTimeRangeFormat,
    },
  }), []);

  const { parseDate } = useMemo(() => {
    const tempDate = date ? DateTime.fromISO(date) : undefined;

    return {
      parseDate: tempDate?.toJSDate(),
    };
  }, [date]);

  useEffect(() => {
    const gamesNodes = ref?.current?.querySelectorAll('.rbc-event') as NodeListOf<HTMLElement> | null;

    if (ref.current && !noScroll) {
      if (gamesNodes?.length) {
        const scrollPosition = Math.min(...Array.from(gamesNodes).map((item) => item.offsetTop));

        ref.current.scrollTo({
          top: scrollPosition - 60,
          left: 0,
          behavior: 'smooth',
        });
      } else {
        ref.current.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [games, noScroll]);

  return (
    <Stack
      direction="row"
      className="game-calendar"
      spacing={2}
      onFocus={onPreventScroll}
    >
      <Stack>
        <UnscheduledList
          isEditable={isEditable}
          unscheduledList={unscheduledList}
          onDragStart={onDragStart}
          onSelectGame={onSelectGame}
        />
        <Button
          className={classes.ghostButton}
          variant="contained"
          color="secondary"
          onClick={onOpenBuyGames}
        >
          <GhostIcon />
          <Typography>
            {t('schedule.byeGames')}
          </Typography>
          <ArrowRightIcon />
        </Button>
      </Stack>

      <Box
        className={classes.box}
        overflow="scroll"
        ref={ref}
      >
        <Box className={classes.root}>
          <DnDCalendar
            draggableAccessor={() => isEditable}
            events={games}
            resources={fields}
            components={components}
            localizer={dateLocalizer}
            date={parseDate}
            getNow={getNow}
            onNavigate={() => {}}
            eventPropGetter={eventPropGetter}
            slotPropGetter={slotPropGetter}
            onDropFromOutside={onDropFromOutside}
            onDragOver={onDragOver}
            onEventDrop={onEventDrop}
            defaultView={Views.DAY}
            step={10}
            timeslots={6}
            resizable={false}
            toolbar={false}
            endAccessor="endAt"
            startAccessor="startAt"
            formats={formats}
            onSelectEvent={onSelectGame}
            resourceTitleAccessor="name"
            dayLayoutAlgorithm={({ ...params }) => overlap({ ...params, minimumStartDifference: 10 })}
            showMultiDayTimes
          />
        </Box>
      </Box>
    </Stack>
  );
}

export default memo(GameCalendar);
