import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import ActionDelete from '@mui/icons-material/Delete';

import PlacesAutocomplete from '../PlacesAutocomplete/PlacesAutocomplete';
import { getAddressErrorMessage } from '../../utils/helpers';
import type IHotelOrPartnerCard from './interfaces/IHotelOrPartnerCard';

function EditHotelCard({ control, cardIndex, onRemove }: IHotelOrPartnerCard): React.ReactElement {
  const { t } = useTranslation();

  return (
    <Paper sx={{ p: 3, alignSelf: 'stretch' }}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <Typography component="h4" fontSize={16} width="100%">
          {`Hotel ${cardIndex + 1}`}
        </Typography>
        <IconButton onClick={onRemove}>
          <ActionDelete />
        </IconButton>
      </Stack>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 5 }}>
        <Grid item xs={12} md={6}>
          <Controller
            name={`hotels.${cardIndex}.name`}
            control={control}
            render={({
              field,
              fieldState: { error: fieldError },
            }) => (
              <TextField
                helperText={fieldError?.message}
                error={!!fieldError}
                label={t('events.name')}
                variant="outlined"
                fullWidth
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name={`hotels.${cardIndex}.address`}
            control={control}
            render={({
              field: { value, ...props },
              fieldState: { error: fieldError },
            }) => (
              <PlacesAutocomplete
                defaultValue={value?.streetAddress1 || ''}
                label={t('events.address')}
                helperText={getAddressErrorMessage(fieldError)}
                error={!!fieldError}
                {...props}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name={`hotels.${cardIndex}.link`}
            control={control}
            render={({
              field,
              fieldState: { error: fieldError },
            }) => (
              <TextField
                helperText={fieldError?.message}
                error={!!fieldError}
                label={t('events.link')}
                variant="outlined"
                fullWidth
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default EditHotelCard;
