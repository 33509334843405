export enum EventRoundTypes {
  BASIC = 'BASIC',
  SEMI_FINAL = 'SEMI_FINAL',
  FINAL = 'FINAL',
  THIRD_PLACE_FINAL = 'THIRD_PLACE_FINAL',
}

export enum GameTeamTypes {
  REAL = 'REAL',
  GHOST = 'GHOST',
  TEMPORARY = 'TEMPORARY',
}

export enum GameTeamResults {
  WIN = 'WIN',
  LOSE = 'LOSE',
  TIE = 'TIE',
}

export interface IEventRound {
  'id': number
  'nextRoundId': number
  'name': string
  'type': EventRoundTypes
  'position': number
}

export interface IGameTeam {
  'id': number
  'name': string
  'profilePhoto': string | null
  'teamType': GameTeamTypes
  'homeFieldAddress': null
  'numberInSeeding': number
  stats?: {
    'result': GameTeamResults,
    'pointsFor': number
  }
}

export interface IBracketGame {
  'id': number
  'isByeGame': boolean
  'gameSettingsId': number
  'teamAId': number
  'teamBId': number
  'tOrgPlayingFieldId': null
  'tOrgGamesStatisticianId': null
  'tOrgEventPlayOffRoundId': number
  'startTime': string | null
  'finishedAt': null
  'isStatCollected': boolean
  'nextRoundGameId': number
  'roundPosition': number
  'status': 'UPCOMING'
  'teamA': IGameTeam
  'teamB': IGameTeam
  'tOrgPlayingField': {
    id: number
    name: string
  } | null
  'tOrgGamesStatistician': null
}

export default interface IScheduleBracket {
  eventRounds: IEventRound[]
  filters: {
    eventGamesStatisticians: { id: number, user: { id: number, firstName: string, lastName: string } }[]
    teams: { id: number, name: string }[]
  }
  games: IBracketGame[]
}
