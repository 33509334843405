import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import type IBuyGamesListDialog from '../interfaces/IBuyGamesListDialog';

function BuyGamesListDialog({ open, onClose, games }: IBuyGamesListDialog) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={!!open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%', maxWidth: 600,
        },
      }}
    >
      <Stack justifyContent="space-between" direction="row" alignItems="center">
        <DialogTitle>
          {t('schedule.byeGames')}
        </DialogTitle>
        <IconButton onClick={onClose} sx={{ width: 40, height: 40, mr: 1.5 }}>
          <CloseIcon />
        </IconButton>
      </Stack>

      <DialogContent sx={{ pt: 0 }}>
        <List>
          {games?.map((game, index) => {
            const team = game.teamA || game.teamB;

            return (
              <Fragment key={game.id}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar src={team?.profilePhoto || undefined} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={team?.name}
                    secondaryTypographyProps={{ component: Box }}
                    secondary={(
                      <Stack direction="row" alignItems="center" gap={1}>
                        <Typography variant="body1">
                          {game.division}
                        </Typography>
                        {game.poolName ? (
                          <>
                            <FiberManualRecordIcon sx={{ fontSize: 7 }} />
                            <Typography variant="body1">
                              {t('schedule.poolName', { pool: game.poolName })}
                            </Typography>
                          </>
                        ) : null}
                      </Stack>
                    )}
                  />
                </ListItem>
                {index !== games.length - 1 && <Divider component="li" />}
              </Fragment>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
}

export default BuyGamesListDialog;
