import { createSelector } from '@reduxjs/toolkit';

import { getMonthAbbreviation } from '../../../utils/helpers';
import type IBaseQueryResponse from '../../types/IBaseQueryResponse';
import type { ITotalEarned } from './interfaces/ITotalEarned';
import type { ITotalEventsResponse } from './interfaces/ITotalEventsResponse';
import type { IEventsEarned } from './interfaces/IEventsEarned';
import type { IMonthEarned } from './interfaces/IMonthEarned';

export const selectEarnedAmount = createSelector(
  (data: IBaseQueryResponse<ITotalEarned> | undefined) => data?.data,
  (amount) => ({
    totalAmount: amount?.totalAmount || 0,
    spectatorsAmount: amount?.spectatorsAmount || 0,
    teamsAmount: amount?.teamsAmount || 0,
    verificationsAmount: amount?.verificationsAmount || 0,
  }),
);

export const selectTotalEventsByStatus = createSelector(
  (data: IBaseQueryResponse<ITotalEventsResponse> | undefined) => data?.data,
  (count) => ({
    total: count?.total || 0,
    current: count?.Current || 0,
    upcoming: count?.Upcoming || 0,
    over: count?.Over || 0,
  }),
);

export const selectEarnedByEvents = createSelector(
  (data: IBaseQueryResponse<IEventsEarned> | undefined) => data?.data,
  (data) => ({
    rows: data?.rows.length ? data?.rows.map((itemData) => ({
      ...itemData,
      teamsAmount: itemData.teamsAmount,
      verificationsAmount: itemData.verificationsAmount,
      spectatorsAmount: itemData.spectatorsAmount,
      totalAmount: itemData.totalAmount,
    })) : [],
    total: {
      verificationsCount: data?.total?.verificationsCount || 0,
      spectatorsCount: data?.total?.spectatorsCount || 0,
      teamsCount: data?.total?.teamsCount || 0,
      teamsAmount: data?.total?.teamsAmount || 0,
      verificationsAmount: data?.total?.verificationsAmount || 0,
      spectatorsAmount: data?.total?.spectatorsAmount || 0,
      totalAmount: data?.total?.totalAmount || 0,
    },
  }),
);

export const selectTotalEarnedBySeason = createSelector(
  (data: IBaseQueryResponse<IMonthEarned[]> | undefined) => data?.data || [],
  (data) => data.map((month) => ({
    data: getMonthAbbreviation(month.month),
    amount: month.totalAmount || 0,
  })),
);
