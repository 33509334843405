import type IResponseMeta from '../store/types/IResponseMeta';

export const transformResponseWithTimezone = <T>(data: T, meta: IResponseMeta) => {
  let timezone: string | undefined;
  if (meta?.response?.headers && meta?.response?.headers.get) {
    timezone = meta?.response?.headers.get('x-event-tz') || undefined;
  }
  return { ...data, timezone };
};

export default transformResponseWithTimezone;
