import { Controller } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';

import type { ITicketVariantShell } from '../interfaces/ITicketVariantShell';

function TicketVariantShell({
  label, children, name, control,
}: ITicketVariantShell): React.ReactElement {
  return (
    <Stack spacing={3} alignItems="flex-start">
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value },
        }) => (
          <>
            <FormControl>
              <FormControlLabel
                control={<Checkbox checked={value as boolean} onChange={onChange} />}
                label={<Typography variant="h6">{label}</Typography>}
              />
            </FormControl>
            {value ? children : null}
          </>
        )}
      />
    </Stack>
  );
}

export default TicketVariantShell;
