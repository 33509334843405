import Paper from '@mui/material/Paper/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DateTime } from 'luxon';

import Team from './Team';
import useStyles from './bracketStyle-tss';
import type IMatchProps from './interfaces/IGame';

function Game({ game, timezone }: IMatchProps) {
  const { classes, cx } = useStyles();
  const teams = [game.teamA, game.teamB];

  return (
    <Paper className={cx(classes.match, game.error && classes.error)} sx={{ borderRadius: 0, p: 0.25 }} elevation={0}>
      {teams.map((team, index) => (
        <Team
          key={`TournamentBracketTeam_${game.id}_${team.id}_${index.toString()}`}
          team={team}
        />
      ))}

      {game.startTime || game.tOrgPlayingField ? (
        <Box className={classes.matchInfo}>
          {game.startTime ? (
            <Typography fontSize={10} fontWeight={400}>
              {DateTime.fromISO(game.startTime).setZone(timezone).toFormat('dd/MM hh:mm a')}
            </Typography>
          ) : null}
          {game.tOrgPlayingField ? (
            <Typography fontSize={10} fontWeight={400}>
              {game.tOrgPlayingField.name}
            </Typography>
          ) : null}
        </Box>
      ) : null}
    </Paper>
  );
}

export default Game;
