import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import CreateEventForm from '../../components/CreateEventForm/CreateEventForm';
import type { ISingleEventEditView } from './interfaces/ISingleEventEditView';

/**
 * Single event edit page view
 */
function SingleEventEditView({
  control,
  onCancelEdit,
  onCreateSubmit,
  onDeleteCoverPhoto,
  isSubmitting,
  onChangeWithRedirect,
}: ISingleEventEditView): React.ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <Paper sx={{ px: 8, py: 5, mb: 3 }} elevation={0}>
        <CreateEventForm
          title={t('events.editEvent')}
          control={control}
          onDeleteCoverPhoto={onDeleteCoverPhoto}
          onCreateSubmit={onCreateSubmit}
          onChangeWithRedirect={onChangeWithRedirect}
        />
      </Paper>
      <Stack direction="row" justifyContent="space-between" spacing={1.5}>
        <Button
          variant="outlined"
          color="error"
          onClick={onCancelEdit}
          sx={{ width: 140 }}
        >
          {t('events.cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={onCreateSubmit}
          disabled={isSubmitting}
          sx={{ width: 126 }}
        >
          {t('events.save')}
        </Button>
      </Stack>
    </>
  );
}

export default SingleEventEditView;
