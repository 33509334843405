import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';

import type IPartnerCard from './interface/IPartnerCard';
import useStyles from './partnerCardStyle-tss';

/**
 * UI component for displaying partner card.
 */
function PartnerCard({
  title,
  logo,
  link,
  isSmall,
  onEdit,
}: IPartnerCard): React.ReactElement {
  const { t } = useTranslation();
  const { classes } = useStyles({ isSmall });

  return (
    <Paper className={classes.paper}>
      {onEdit ? (
        <Button
          size="small"
          className={classes.editBtn}
          startIcon={<EditIcon />}
        >
          {t('common.edit')}
        </Button>
      ) : null}
      <Avatar
        sx={{ width: 70, height: 70 }}
        src={logo || undefined}
        alt="partner logo"
      />
      <Box className={classes.wrapper}>
        <Typography variant={isSmall ? 'body1' : 'h6'}>
          {title}
        </Typography>
        <Typography
          className={classes.desc}
          component="a"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {link}
        </Typography>
      </Box>
    </Paper>
  );
}

PartnerCard.defaultProps = {
  isSmall: false,
  onEdit: undefined,
};

export default PartnerCard;
