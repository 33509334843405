import { useCallback, useEffect, useMemo } from 'react';
import {
  NavLink, Outlet, useNavigate, useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { toast } from 'react-toastify';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';

import PageNotFound from '../Page/PageNotFound';
import EventCover from './components/EventCover';
import SplashScreen from '../../components/SplashScreen/SplashScreen';
import EventHint from './components/EventHint';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import useRequiredEventFields from '../../hooks/useRequiredEventFields';
import {
  useDuplicateEventMutation,
  useGetEventQuery,
  usePublishEventMutation,
} from '../../store/slices/events/apis/eventsApi';
import { resetConfirmModalData, setConfirmModalData } from '../../store/slices/events/slice';
import { selectUserOrg } from '../../store/slices/user/selectors';
import { selectEventConfirmModal } from '../../store/slices/events/selectors';
import { setIsShowTitle } from '../../store/slices/page/slice';
import { resetCreateEventState, setCompletedSteps } from '../../store/slices/createEvent/slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks/useApp';
import useRouteMatch from '../../hooks/useRouteMatch';
import useEventActions from '../../hooks/useEventActions';
import {
  checkAdmin, filterEventTabs, getErrorMessage, getRelativePath,
} from '../../utils/helpers';
import { EventStatuses } from '../../constants/Statuses';
import EventActions from '../../constants/EventActions';
import AppRoutes from '../../constants/AppRoutes';
import FIELDS_VALIDATION from '../../constants/hooksCacheKeys';
import { VALIDATION_ERROR_CODE } from '../../constants/apiCodeErrors';
import { CREATE_EVENT_EVENT_ID } from '../../constants/localStorageKeys';
import { CREATE_EVENT_STEPS } from '../../constants/general';
import { tabs, tabsFree } from '../../constants/tabsConstants';

function SingleEventLayout(): React.ReactElement {
  const { t } = useTranslation();
  const { eventId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const organization = useAppSelector(selectUserOrg);
  const eventConfirmModal = useAppSelector(selectEventConfirmModal);

  const { data: event, isLoading, isError } = useGetEventQuery(
    eventId ? { orgId: organization?.id as number, eventId: +eventId } : skipToken,
  );
  const [publishEvent, { isLoading: isPublishing }] = usePublishEventMutation({
    fixedCacheKey: FIELDS_VALIDATION,
  });
  const [duplicateEvent] = useDuplicateEventMutation();

  const { errorsList } = useRequiredEventFields();
  const currentEventAction = useEventActions({
    type: eventConfirmModal.type,
    onSuccess: () => {
      dispatch(resetConfirmModalData());
      if (eventConfirmModal.type === EventActions.Delete) {
        navigate(AppRoutes.events);
      }
    },
  });
  const trueAdmin = checkAdmin(organization?.roles || []);
  const tabsArray = trueAdmin
    ? filterEventTabs(tabs, ['schedule'], event?.data.published)
    : filterEventTabs(tabsFree, ['schedule'], event?.data.published);

  const routeMatch = useRouteMatch(tabsArray.map(({ route }) => route));
  const currentTab = routeMatch?.pattern?.path;

  useEffect(() => {
    dispatch(setIsShowTitle({ isShow: false }));

    return () => {
      dispatch(setIsShowTitle({ isShow: true }));
    };
  }, [dispatch]);

  const handleCloseConfirmModal = () => {
    dispatch(resetConfirmModalData());
  };

  const handleEditEvent = useCallback(() => {
    if (eventId) {
      dispatch(resetCreateEventState());
      dispatch(
        setCompletedSteps(
          CREATE_EVENT_STEPS.reduce((acc, item, index) => ({ ...acc, [index]: true }), {}),
        ),
      );
      localStorage.setItem(CREATE_EVENT_EVENT_ID, eventId);
      navigate(AppRoutes.createEvent.concat('?editPage=true'));
    }
  }, [dispatch, eventId, navigate]);

  const handlePublishEvent = useCallback(() => {
    const isEventInPast = event?.data.startAt
      ? DateTime.fromISO(event?.data.startAt).startOf('day') < DateTime.now().startOf('day')
      : false;

    if (isEventInPast) {
      toast.error(t('events.publishingError', { event: event?.data.name || '' }), { position: toast.POSITION.TOP_RIGHT });
    } else if (eventId) {
      publishEvent({
        orgId: organization?.id as number,
        eventId: +eventId,
      })
        .unwrap()
        .then(() => toast.success(t('events.publishSuccess', { eventName: event?.data.name || '' }), { position: toast.POSITION.TOP_RIGHT }))
        .catch((error) => {
          const errorMessage = error.data.error.code === VALIDATION_ERROR_CODE ? t('events.publishError') : getErrorMessage(error);
          toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT });
        });
    }
  }, [event?.data.name, event?.data.startAt, eventId, organization?.id, publishEvent, t]);

  const handleDuplicateEvent = useCallback(() => {
    if (eventId) {
      duplicateEvent({
        orgId: organization?.id as number,
        eventId: +eventId,
      })
        .unwrap()
        .then(({ data }) => {
          toast.success(t('events.duplicateSuccess', { eventName: event?.data.name || '' }), { position: toast.POSITION.TOP_RIGHT });
          window.open(`/events/${data.id}`, '_blank');
        })
        .catch((error) => toast.error(getErrorMessage(error), { position: toast.POSITION.TOP_RIGHT }));
    }
  }, [eventId, duplicateEvent, organization?.id, t, event?.data.name]);

  const handleCancelEvent = useCallback(() => {
    dispatch(
      setConfirmModalData({
        isOpen: true,
        title: `${t('events.cancelEventTitle')}`,
        description: `${t('events.cancelEventUndone')}`,
        secondDescription: `${t('events.cancelEventRefund')}`,
        type: EventActions.Cancel,
        event: event?.data,
      }),
    );
  }, [dispatch, event, t]);

  const handleDeletedEvent = useCallback(() => {
    dispatch(
      setConfirmModalData({
        isOpen: true,
        title: `${t('events.deleteEventTitle')}`,
        description: `${t('events.deleteEventUndone')}`,
        secondDescription: `${t('events.deleteEventRefund')}`,
        type: EventActions.Delete,
        event: event?.data,
      }),
    );
  }, [dispatch, event, t]);

  const eventActionsList = useMemo(() => ([
    {
      title: t('common.edit'),
      onClick: handleEditEvent,
      statuses: [
        EventStatuses.Draft,
        EventStatuses.Upcoming,
        EventStatuses.Current,
      ],
    },
    {
      title: t('common.publish'),
      onClick: handlePublishEvent,
      statuses: [
        EventStatuses.Draft,
      ],
    },
    {
      title: t('common.duplicate'),
      onClick: handleDuplicateEvent,
      statuses: [
        EventStatuses.Draft,
        EventStatuses.Upcoming,
        EventStatuses.Current,
        EventStatuses.Over,
        EventStatuses.Canceled,
      ],
    },
    {
      title: t('common.cancel'),
      onClick: handleCancelEvent,
      statuses: [
        EventStatuses.Upcoming,
        EventStatuses.Current,
      ],
    },
    {
      title: t('common.delete'),
      onClick: handleDeletedEvent,
      statuses: [
        EventStatuses.Draft,
      ],
    },
  ]), [
    t,
    handleEditEvent,
    handlePublishEvent,
    handleDuplicateEvent,
    handleCancelEvent,
    handleDeletedEvent,
  ]);

  if (isLoading || isPublishing) {
    return <SplashScreen />;
  }

  if (isError) {
    return <PageNotFound />;
  }

  const defaultEventCover = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_DEFAULT_COVER_PROD
    : process.env.REACT_APP_DEFAULT_COVER_STAGE;

  return (
    <>
      <Typography variant="h5" gutterBottom mb={3} lineHeight={1} fontWeight="bold">
        {t('events.eventProfile')}
      </Typography>
      <EventCover
        name={event?.data.name || ''}
        topic={event?.data.topic}
        desc={event?.data.tagline}
        cover={event?.data.cover || defaultEventCover}
        published={event?.data.published}
        onPublish={handlePublishEvent}
        dropdownList={eventActionsList}
        status={event?.data.status as EventStatuses}
      />
      {eventConfirmModal.isOpen ? (
        <ConfirmDialog
          title={eventConfirmModal.title}
          description={eventConfirmModal.description}
          secondDescription={eventConfirmModal.secondDescription}
          open={eventConfirmModal.isOpen}
          onClose={handleCloseConfirmModal}
          buttonText={t('common.delete')}
          onConfirm={() => currentEventAction(event?.data)}
        />
      ) : null}
      <Paper sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, mb: 4 }}>
        <Tabs
          value={currentTab}
          variant="fullWidth"
          indicatorColor="primary"
          centered
        >
          {tabsArray.map((tab) => (
            <Tab
              key={tab.route}
              label={t(`events.${tab.label}`)}
              value={tab.route}
              to={`/events/${eventId}${getRelativePath(tab.route, 'route')}`}
              component={NavLink}
            />
          ))}
        </Tabs>
      </Paper>
      {errorsList.length ? (
        <EventHint errorsList={errorsList} eventId={eventId as string} />
      ) : null}
      <Outlet />
    </>
  );
}

export default SingleEventLayout;
