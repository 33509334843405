import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import type { ICountWidget } from './interfaces/ICountWidget';

function CountWidget({ title, value }: ICountWidget) {
  return (
    <Paper sx={{ p: 3, height: '100%' }}>
      <Typography fontSize={16} sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Typography
        fontSize={48}
        lineHeight={1}
        fontWeight={500}
        sx={{
          color: 'primary.main',
          wordBreak: 'break-all',
        }}
      >
        {value}
      </Typography>
    </Paper>
  );
}

export default CountWidget;
