import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';

import EventPeriod from '../EventPeriod/EventPeriod';
import CreateEventTitle from '../CreateEventTitle/CreateEventTitle';
import PrecreationSection from './PrecreationSection';
import RegistrationSection from './RegistrationSection';
import EligibilitySection from './EligibilitySection';
import DivisionSection from './DivisionSection';
import RefundPolicySection from './RefundPolicySection';
import MultipleDiscountSection from './MultipleDiscountSection';
import { EVENT_FORM_TYPES } from '../../constants/general';
import type { ITeamsTicketsForm } from './interfaces/ITeamsTicketsForm';

function TeamsTicketsForm({
  title,
  eventName,
  type = EVENT_FORM_TYPES.CREATE,
  control,
  divisions,
  eventEndDate,
  eventStartDate,
  maxRegistrationDate,
  onRemoveDivision,
  onRemoveDiscount,
  onPrecreateDivisions,
  onAddOneDivision,
  onAppendDiscount,
  isPublished,
  maxDiscountDate,
  onPrecreateDevisionName,
}: ITeamsTicketsForm): React.ReactElement {
  const { t } = useTranslation();

  const hintList = [
    t('events.editTeamTicketsFirstHint'),
    t('events.editTeamTicketsSecondHint'),
    t('events.editTeamTicketsThirdHint'),
  ];

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Stack spacing={4}>
        <CreateEventTitle title={title} eventName={eventName} />
        <EventPeriod eventStartDate={eventStartDate} eventEndDate={eventEndDate} />
        {type === EVENT_FORM_TYPES.EDIT ? (
          <Alert
            icon={<InfoIcon sx={{ fontSize: 24 }} />}
            severity="info"
            variant="filled"
            sx={{ alignItems: 'center' }}
          >
            <Stack component="ul" m={0} pl={3}>
              {hintList.map((hint) => (
                <Box key={hint} component="li">
                  <Typography variant="body2">
                    {hint}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Alert>
        ) : null}
        <RegistrationSection control={control} maxRegistrationDate={maxRegistrationDate} />
        <MultipleDiscountSection control={control} />
        <EligibilitySection control={control} isPublished={isPublished} />
        {!divisions.length ? (
          <PrecreationSection
            control={control}
            onPrecreateDivisions={onPrecreateDivisions}
          />
        ) : null}
        {divisions.map((item, index) => (
          <Fragment key={`DivisionSection_${item.id}`}>
            <Divider />
            <DivisionSection
              control={control}
              divisionIndex={index}
              onRemove={onRemoveDivision}
              onAppendDiscount={onAppendDiscount}
              onRemoveDiscount={onRemoveDiscount}
              isPublished={isPublished}
              maxDiscountDate={maxDiscountDate}
              onPrecreateDevisionName={onPrecreateDevisionName}
            />
          </Fragment>
        ))}
        {divisions.length ? (
          <Stack spacing={4.25}>
            <Divider />
            <Button
              size="large"
              variant="outlined"
              onClick={onAddOneDivision}
              sx={{ alignSelf: 'start' }}
            >
              {t('events.addDivision')}
            </Button>
            <Divider />
          </Stack>
        ) : null}
        <RefundPolicySection control={control} />
      </Stack>
    </LocalizationProvider>
  );
}

export default TeamsTicketsForm;
