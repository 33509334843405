import { useTranslation } from 'react-i18next';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';

import EmptyData from '../../../components/EmptyData/EmptyData';
import useStyles from '../../SingleEventTeams/components/teamsTableStyles-tss';
import { toPriceFormat } from '../../../utils/helpers';
import type { ITeamTicketsTable } from '../interfaces/ITeamTicketsTable';

const tableHeadCells = [
  'events.fullPrice',
  'events.multipleDiscount',
  'events.discountedTeamTickets',
  'events.discountedAndMultiple',
  'common.total',
];

function TeamTicketsTable({
  teamsEarnedData: { rows, total },
}: ITeamTicketsTable) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow className={classes.head}>
            <TableCell>{t('events.date')}</TableCell>
            {tableHeadCells.map((item) => (
              <TableCell
                key={['TeamsTable', item].join('_')}
                align="center"
              >
                {t(item)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.length
            ? (
              <>
                {rows.map((team, index) => (
                  <TableRow
                    key={['TeamsTable', index].join('_')}
                    className={classes.stripped}
                  >
                    <TableCell>
                      <Typography variant="body2">
                        {team.date}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {`${toPriceFormat(team.fullPriceAmount)} (${team.fullPriceCount})`}
                    </TableCell>
                    <TableCell align="center">
                      {`${toPriceFormat(team.multipleDiscountedAmount)} (${team.multipleDiscountedCount})`}
                    </TableCell>
                    <TableCell align="center">
                      {`${toPriceFormat(team.divDiscountedAmount)} (${team.divDiscountedCount})`}
                    </TableCell>
                    <TableCell align="center">
                      {`${toPriceFormat(team.multipleAndDivDiscountedAmount)} (${team.multipleAndDivDiscountedCount})`}
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2" fontWeight={700}>
                        {`${toPriceFormat(team.totalAmount)} (${team.totalCount})`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow className={classes.stripped}>
                  <TableCell>
                    <Typography variant="body2" fontWeight={700}>
                      {t('common.total')}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2" fontWeight={700}>
                      {`${toPriceFormat(total.fullPriceAmount)} (${total.fullPriceCount})`}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2" fontWeight={700}>
                      {`${toPriceFormat(total.multipleDiscountedAmount)} (${total.multipleDiscountedCount})`}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2" fontWeight={700}>
                      {`${toPriceFormat(total.divDiscountedAmount)} (${total.divDiscountedCount})`}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2" fontWeight={700}>
                      {`${toPriceFormat(total.multipleAndDivDiscountedAmount)}
                        (${total.multipleAndDivDiscountedCount})`}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2" fontWeight={700}>
                      {`${toPriceFormat(total.totalAmount)} (${total.totalCount})`}
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <EmptyData description={t('common.noData')} />
                </TableCell>
              </TableRow>
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TeamTicketsTable;
