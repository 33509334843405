import { useNavigate, useParams } from 'react-router-dom';
import { useCallback } from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

import ScheduleCalendar from '../../../components/ScheduleCalendar/ScheduleCalendar';
import { useAppSelector } from '../../../store/hooks/useApp';
import { selectUserOrg } from '../../../store/slices/user/selectors';
import useScheduleCalendarFilterForm from '../../../hooks/useScheduleCalendarFilterForm';
import useTimezone from '../../../hooks/useTimezone';
import { ScheduleTypes } from '../../../constants/general';
import AppRoutes from '../../../constants/AppRoutes';

function PoolPlayTab() {
  const { eventId } = useParams() as { eventId: string };
  const navigate = useNavigate();

  const organization = useAppSelector(selectUserOrg);

  const {
    timezone,
    control,
    selectedGame,
    filterData,
    timeFrames,
    games,
    unscheduledList,
    fields,
    onSelectGame,
    toggleGameDetailsDialog,
    toggleBuyGamesDialog,
    buyGames,
    isOpenBuyGames,
    isLoading,
  } = useScheduleCalendarFilterForm({
    orgId: organization?.id as number,
    eventId: +eventId,
    type: ScheduleTypes.POOL_PLAY,
  });

  const handleEditField = useCallback(() => {
    navigate(AppRoutes.singleEventPoolPlaySchedule.replace(':eventId', eventId));
  }, [eventId, navigate]);

  useTimezone(timezone);

  if (isLoading) {
    return (
      <Stack alignItems="center" my={8}>
        <CircularProgress size={45} thickness={1} color="secondary" />
      </Stack>
    );
  }

  return (
    <ScheduleCalendar
      timezone={timezone}
      games={games}
      unscheduledList={unscheduledList}
      fields={fields}
      filterData={filterData}
      timeFrames={timeFrames}
      control={control}
      selectedGame={selectedGame}
      onEditFieldsClick={handleEditField}
      onSelectGame={onSelectGame}
      buyGames={buyGames}
      toggleGameDetailsDialog={toggleGameDetailsDialog}
      toggleBuyGamesDialog={toggleBuyGamesDialog}
      isEditable={false}
      isOpenBuyGames={isOpenBuyGames}
      type={ScheduleTypes.POOL_PLAY}
    />
  );
}

export default PoolPlayTab;
