import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import QRCode from 'qrcode';

import { AppThemeMode } from '../constants/AppThemes';
import { useAppSelector } from '../store/hooks/useApp';
import { selectThemeMode } from '../store/slices/ui/selectors';
import type { IQrCodeDataURL } from '../pages/QrCode/interfaces/IQrCodeView';

function useQrCode(text: string) {
  const [qrCodeDataURL, setQrCodeDataURL] = useState<IQrCodeDataURL>({
    png: '',
    svg: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const themeMode = useAppSelector(selectThemeMode);

  const options = useMemo(() => ({
    margin: 2,
    color: {
      dark: themeMode === AppThemeMode.Dark ? '#292929' : '#000000',
      light: '#FFFFFF',
    },
  }), [themeMode]);

  const generateQr = useCallback(async () => {
    try {
      setIsLoading(true);
      const qrCodePngData = await QRCode.toDataURL(text, {
        rendererOpts: {
          quality: 1,
        },
        width: 1024,
        ...options,
      });

      const qrCodeSvgData = await QRCode.toString(text, {
        type: 'svg',
        ...options,
      });

      const svgBlob = new Blob([qrCodeSvgData], { type: 'image/svg+xml' });
      const svgUrl = URL.createObjectURL(svgBlob);

      setQrCodeDataURL({
        png: qrCodePngData,
        svg: svgUrl,
      });
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [options, text]);

  useEffect(() => {
    generateQr();
  }, [generateQr]);

  return {
    qrCodeDataURL,
    isLoading,
    isError,
  };
}

export default useQrCode;
