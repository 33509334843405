import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import DiscountDateCard from './DiscountDateCard';
import type { IEventDiscountDatesSection } from '../interfaces/IEventDiscountDatesSection';

export default function EventDiscountDatesSection({
  control,
  discountsList,
}: IEventDiscountDatesSection) {
  const { t } = useTranslation();

  return (
    <>
      <Divider sx={{ my: 4.25 }} />
      <Typography component="h4" variant="h6" mb={3}>
        {t('events.discountSettings')}
      </Typography>
      <Stack spacing={3}>
        {discountsList.map(({ divisionId, name }, index) => (
          <DiscountDateCard
            key={divisionId}
            control={control}
            name={name}
            index={index}
          />
        ))}
      </Stack>
    </>
  );
}
