import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import CountWidget from '../../../components/CountWidget/CountWidget';
import type IEventDataSection from '../interfaces/IEventDataSection';

function EventDataSection({
  total,
  current,
  upcoming,
  over,
}: IEventDataSection) {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} md={6} xl={3}>
        <CountWidget
          title={t('dashboard.totalEvents')}
          value={total.toString()}
        />
      </Grid>
      <Grid item xs={12} md={6} xl={3}>
        <CountWidget
          title={t('dashboard.upcoming')}
          value={upcoming.toString()}
        />
      </Grid>
      <Grid item xs={12} md={6} xl={3}>
        <CountWidget
          title={t('dashboard.live')}
          value={current.toString()}
        />
      </Grid>
      <Grid item xs={12} md={6} xl={3}>
        <CountWidget
          title={t('dashboard.over')}
          value={over.toString()}
        />
      </Grid>

    </>
  );
}

export default EventDataSection;
