import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import type { ICardsWrapper } from './interfaces/ICardsWrapper';

function CardsWrapper({
  label,
  appendText,
  children,
  onAppend,
}: ICardsWrapper): React.ReactElement {
  return (
    <Stack spacing={3} alignItems="start">
      {label ? (
        <Typography component="h4" mb={2} fontSize={20}>
          {label}
        </Typography>
      ) : null}
      {children}
      <Button
        variant="outlined"
        onClick={onAppend}
        size="large"
      >
        {appendText}
      </Button>
    </Stack>
  );
}

export default CardsWrapper;
