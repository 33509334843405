import Typography from '@mui/material/Typography';

import type IGameRoundBadge from './interfaces/IGameRoundBadge';

function GameRoundBadge({ round }: IGameRoundBadge) {
  return (
    <div className="rbc-custom-event-round">
      <Typography variant="body2" lineHeight="16px" fontSize={12}>
        {round}
      </Typography>
    </div>
  );
}

export default GameRoundBadge;
