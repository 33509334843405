import mainApi from '../../../apis/mainApi';
import { EVENT_PARTNERS_LIST_TAG } from '../../../tags/partners';
import {
  ORG_EVENTS_PARTNERS,
  ORG_PARTNERS,
  ORG_PARTNERS_COVER_PHOTO,
  ORG_PARTNERS_ID,
} from '../../../../constants/requestUrls';
import type IBaseQueryResponse from '../../../types/IBaseQueryResponse';
import type { IPartner } from '../interfaces/IPartner';
import type { ICreatePartnerReq } from '../interfaces/ICreatePartnerReq';
import type { IBaseQueryRequest } from '../../../types/IBaseQueryRequest';

const partnersApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    createPartner: builder.mutation<IBaseQueryResponse<IPartner>, ICreatePartnerReq>({
      query: ({
        orgId, eventId, name, link,
      }) => ({
        url: ORG_PARTNERS.replace('{organizationId}', `${orgId}`),
        method: 'POST',
        body: {
          name,
          link,
          eventId,
        },
      }),
      invalidatesTags: [EVENT_PARTNERS_LIST_TAG],
    }),

    updatePartner: builder.mutation<IBaseQueryResponse<IPartner>, ICreatePartnerReq & { partnerId: number }>({
      query: ({
        orgId, partnerId, name, link,
      }) => ({
        url: ORG_PARTNERS_ID
          .replace('{organizationId}', `${orgId}`)
          .replace('{id}', `${partnerId}`),
        method: 'PATCH',
        body: {
          name,
          link,
        },
      }),
      invalidatesTags: (result, error, { partnerId }) => [{ type: EVENT_PARTNERS_LIST_TAG, id: partnerId }],
    }),

    deletePartnerByEvent: builder.mutation<IBaseQueryResponse<{}>, IBaseQueryRequest & { partnersIds: number[] }>({
      query: ({ orgId, eventId, partnersIds }) => ({
        url: ORG_EVENTS_PARTNERS
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'DELETE',
        body: { partnersIds },
      }),
      invalidatesTags: [EVENT_PARTNERS_LIST_TAG],
    }),

    uploadPartnerCoverPhoto: builder.mutation<
    IBaseQueryResponse<{ url: string }>,
    { partnerId: number, orgId: number, file: File }
    >({
      query: ({ orgId, partnerId, file }) => {
        const form = new FormData();
        form.append('file', file, file.name);

        return ({
          url: ORG_PARTNERS_COVER_PHOTO
            .replace('{organizationId}', `${orgId}`)
            .replace('{id}', `${partnerId}`),
          method: 'POST',
          body: form,
        });
      },
      invalidatesTags: (result, error, { partnerId }) => [{ type: EVENT_PARTNERS_LIST_TAG, id: partnerId }],
    }),
  }),
});

export const {
  useCreatePartnerMutation,
  useUpdatePartnerMutation,
  useDeletePartnerByEventMutation,
  useUploadPartnerCoverPhotoMutation,
} = partnersApi;

export default partnersApi;
