import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TodayIcon from '@mui/icons-material/Today';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

import InfoCard from '../../../components/InfoCard/InfoCard';
import PlayOffTableRow from './PlayOffTableRow';
import PoolPLayTableRow from './PoolPLayTableRow';
import { GameSettingsDialogTypes } from '../interfaces/ILogisticsAndGameSetupView';
import { ScheduleTypes } from '../../../constants/general';
import type IDivisionsSetupTab from '../interfaces/IDivisionsSetupTab';
import type { IDivisionsPlayOffGameSetup } from '../../../store/slices/divisions/interfaces/IDivisionsGameSetup';
import type IDivisionsGameSetup from '../../../store/slices/divisions/interfaces/IDivisionsGameSetup';

function DivisionsSetupTab({
  type,
  divisionsGameSetup,
  timeFramesRange,
  onSetupClick,
  timezone,
}: IDivisionsSetupTab) {
  const { t } = useTranslation();

  return (
    <>
      <Grid alignItems="center" spacing={2} sx={{ mb: 1.5 }} container>
        <Grid item md={6}>
          <Typography variant="h6">
            {t('schedule.divisionGameSetup')}
          </Typography>
        </Grid>
        {timeFramesRange ? (
          <Grid container spacing={2} item md={6}>
            <Grid item sm={6}>
              <InfoCard
                icon={<TodayIcon sx={{ width: 24, height: 24 }} />}
                label={t(type === ScheduleTypes.PLAY_OFF ? 'schedule.playOffStart' : 'schedule.poolPlayStart')}
                desc={DateTime.fromISO(timeFramesRange.startAt).setZone(timezone).toFormat('d LLL yyyy hh:mm a')}
              />
            </Grid>
            <Grid item sm={6}>
              <InfoCard
                icon={<InsertInvitationIcon sx={{ width: 24, height: 24 }} />}
                label={t(type === ScheduleTypes.PLAY_OFF ? 'schedule.playOffEnd' : 'schedule.poolPlayEnd')}
                desc={DateTime.fromISO(timeFramesRange.endAt).setZone(timezone).toFormat('d LLL yyyy hh:mm a')}
              />
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      <TableContainer>
        <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 16px' }}>
          <TableHead>
            <TableRow sx={{ '& > th': { py: 0, borderBottom: 'none' } }}>
              <TableCell>
                {t('schedule.division')}
              </TableCell>
              <TableCell>
                {t('schedule.gameSettings')}
              </TableCell>
              {type === ScheduleTypes.POOL_PLAY ? (
                <>
                  <TableCell>
                    {t('schedule.pools')}
                  </TableCell>
                  <TableCell>
                    {t('schedule.gamesPerTeam')}
                  </TableCell>
                </>
              ) : (
                <TableCell>
                  {t('schedule.totalRounds')}
                </TableCell>
              )}
              <TableCell>
                {t('schedule.registeredTeams')}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {divisionsGameSetup.map((item) => (type === ScheduleTypes.POOL_PLAY ? (
              <PoolPLayTableRow
                key={item.id}
                data={item as IDivisionsGameSetup}
                onEditClick={() => onSetupClick(item.id, GameSettingsDialogTypes.edit)}
                onSetupClick={() => onSetupClick(item.id, GameSettingsDialogTypes.setup)}
              />
            ) : (
              <PlayOffTableRow
                key={item.id}
                data={item as IDivisionsPlayOffGameSetup}
                onEditClick={() => onSetupClick(item.id, GameSettingsDialogTypes.edit)}
                onSetupClick={() => onSetupClick(item.id, GameSettingsDialogTypes.setup)}
              />
            )))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default DivisionsSetupTab;
