import { useEffect, useMemo } from 'react';
import {
  Outlet, useLocation, useNavigate, useSearchParams,
} from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import Box from '@mui/material/Box';

import SplashScreen from '../../components/SplashScreen/SplashScreen';
import StepLeaveDialog from './components/StepLeaveDialog';
import { useGetEventQuery } from '../../store/slices/events/apis/eventsApi';
import { setIsShowTitle } from '../../store/slices/page/slice';
import { selectUserOrg } from '../../store/slices/user/selectors';
import { useAppSelector, useAppDispatch } from '../../store/hooks/useApp';
import AppRoutes from '../../constants/AppRoutes';
import { CREATE_EVENT_EVENT_ID } from '../../constants/localStorageKeys';
import { CREATE_EVENT_STEPS } from '../../constants/general';

function CreateEventLayout(): React.ReactElement {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const organization = useAppSelector(selectUserOrg);
  const [searchParams] = useSearchParams();
  const eventId = localStorage.getItem(CREATE_EVENT_EVENT_ID);
  const isFetch = !!(eventId && organization?.id);

  const { isLoading } = useGetEventQuery(
    isFetch ? { orgId: organization.id, eventId: +eventId } : skipToken,
  );

  const mapOfSteps = useMemo(() => {
    const map = new Map();

    CREATE_EVENT_STEPS.forEach(({ route }, index) => {
      map.set(route, index);
    });

    return map;
  }, []);
  const activeStep = mapOfSteps.get(location.pathname);

  useEffect(() => {
    dispatch(setIsShowTitle({ isShow: false }));

    return () => {
      dispatch(setIsShowTitle({ isShow: true }));
    };
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [location.pathname]);

  if (!eventId && activeStep !== 0) {
    navigate(AppRoutes.createEvent);
    return <div />;
  }

  return (
    <Box mx="auto" my={0} width="100%" maxWidth={880}>
      {isLoading ? <SplashScreen /> : (
        <>
          <Outlet
            context={{
              eventId,
              orgId: organization?.id,
              activeStep,
              isFetch,
              isPublish: !!searchParams.get('isPublish'),
            }}
          />
          <StepLeaveDialog />
        </>
      )}
    </Box>
  );
}

export default CreateEventLayout;
