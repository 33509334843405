import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import GroupsIcon from '@mui/icons-material/Groups';
import { useMemo } from 'react';

import InfoCard from '../../../components/InfoCard/InfoCard';
import PreferredPlayingFields from './PreferredPlayingFields';
import GameSettings from './GameSettings';
import { GamesTypes, ScheduleTypes } from '../../../constants/general';
import type IPlayOffGameSettingsDialog from '../interfaces/IPlayOffGameSettingsDialog';

function PlayOffGameSettingsDialog({
  gameSettingsControl,
  divisionRules: { roundsCount, teamsCount },
  game7x7Settings,
  playingFields,
  onCreateGameSetting,
  onClose,
  isOpen,
  isLoading,
  divisionsGameSetup,
  fullInfoId,
}: IPlayOffGameSettingsDialog) {
  const { t } = useTranslation();
  const {
    field: game7x7SettingField,
  } = useController({ name: 'game7x7Setting', control: gameSettingsControl });
  const {
    field: finalGame7x7SettingsField,
  } = useController({ name: 'finalGame7x7Settings', control: gameSettingsControl });
  const {
    field: preferredPlayingField,
  } = useController({ name: 'preferredPlayingFields', control: gameSettingsControl });
  const {
    field: withThirdPlaceField,
  } = useController({ name: 'withThirdPlace', control: gameSettingsControl });
  const {
    field: playOffDivisionId,
  } = useController({ name: 'divisionId', control: gameSettingsControl });

  const playOffDivisionName = `${divisionsGameSetup.find(({ id }) => id === playOffDivisionId.value)?.name || ''}`;

  const { playOffGameSettings, championshipGameSettings } = useMemo(() => ({
    playOffGameSettings: game7x7Settings.filter(({ gameType }) => gameType === GamesTypes.PLAYOFF),
    championshipGameSettings: game7x7Settings.filter(({ gameType }) => gameType === GamesTypes.CHAMPIONSHIP),
  }), [game7x7Settings]);

  return (
    <Dialog
      open={isOpen}
      sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: 824 } }}
      PaperProps={{ elevation: 0 }}
    >
      <DialogTitle component="h2" variant="h4" fontSize={32}>
        {t('schedule.playOffGameSettings', { divisionName: playOffDivisionName })}
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Stack alignItems="center" my={8}>
            <CircularProgress size={45} thickness={1} color="secondary" />
          </Stack>
        ) : (
          <>
            <GameSettings
              label={t('schedule.statKeeperGameSettings')}
              game7x7Settings={playOffGameSettings}
              game7x7SettingField={game7x7SettingField}
            />

            <Divider sx={{ my: 4 }} />

            <GameSettings
              label={t('schedule.championshipGameSettings')}
              game7x7Settings={championshipGameSettings}
              game7x7SettingField={finalGame7x7SettingsField}
            />

            <Divider sx={{ my: 4 }} />

            <PreferredPlayingFields
              title={`${t('schedule.preferredFields')}`}
              fields={playingFields}
              checkedFields={preferredPlayingField.value}
              onChange={preferredPlayingField.onChange}
              type={ScheduleTypes.PLAY_OFF}
              fullInfoId={fullInfoId}
            />

            <Divider sx={{ my: 4 }} />

            <Box>
              <Typography variant="h6" mb={4}>
                {t('schedule.bracketGames')}
              </Typography>

              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <InfoCard
                    icon={<GroupsIcon />}
                    desc={teamsCount.toString()}
                    label={t('schedule.registeredTeamsInDivision')}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InfoCard
                    icon={<GroupsIcon />}
                    desc={roundsCount.toString()}
                    label={t('schedule.totalRounds')}
                  />
                </Grid>
                {teamsCount > 3 ? (
                  <Grid item sm={6} xs={12}>
                    <FormControlLabel
                      control={<Checkbox checked={withThirdPlaceField.value} onChange={withThirdPlaceField.onChange} />}
                      label="3rd Place Final"
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ gap: 1 }}>
        <Button color="secondary" onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          onClick={onCreateGameSetting}
        >
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PlayOffGameSettingsDialog;
