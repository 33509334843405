import type { IEventRound } from '../../../store/slices/schedule/interfaces/IScheduleBracket';
import type IGameProps from './IGame';

export enum RoundHalf {
  first = 'first',
  second = 'second',
  middle = 'middle',
}

export interface IRoundAttr {
  half: RoundHalf
}

export default interface IRound {
  games: IGameProps['game'][]
  round: IEventRound
  index: number
  attr: IRoundAttr
  timezone?: string
}
