import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import Avatar from '@mui/material/Avatar';

import type { IOrgLogo } from './interfaces/IOrgLogo';

function OrgLogo({ logo, width = 24, height = 24 }: IOrgLogo) {
  return (
    logo ? (
      <Avatar
        src={logo}
        sx={{ width, height }}
      />
    ) : <AccountCircleOutlined sx={{ width, height }} />
  );
}

export default OrgLogo;
