import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import EditPartnerCard from '../../components/EditCards/EditPartnerCard';
import CardsWrapper from '../../components/EditCards/CardsWrapper';
import type { ISingleEventPartnersView } from './interfaces/ISingleEventPartnersView';

function SingleEventPartnersView({
  control,
  partners,
  onCancel,
  onSave,
  onAppendEmptyPartner,
  onRemovePartner,
  isSubmitting,
}: ISingleEventPartnersView): React.ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <Paper
        sx={{ px: 8, py: 5, mb: 3 }}
        elevation={0}
      >
        <Typography component="h2" variant="h4" mb={4}>
          {t('events.editPartners')}
        </Typography>
        <CardsWrapper
          onAppend={onAppendEmptyPartner}
          appendText={t('events.addPartner')}
        >
          {partners.map((partner, index) => (
            <EditPartnerCard
              key={`PartnerCard_${partner.id}`}
              cardIndex={index}
              control={control}
              onRemove={() => onRemovePartner(index)}
            />
          ))}
        </CardsWrapper>
      </Paper>
      <Stack spacing={1.5} direction="row" justifyContent="space-between">
        <Button
          color="error"
          onClick={onCancel}
          sx={{ width: 140 }}
        >
          {t('events.cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={onSave}
          disabled={isSubmitting}
          sx={{ width: 126 }}
        >
          {t('events.save')}
        </Button>
      </Stack>
    </>
  );
}

export default SingleEventPartnersView;
