import mainApi from '../../../apis/mainApi';
import {
  ORG_EVENT_TEAM_TICKET_RULES, ORG_EVENT_TEAM_TICKET_RULES_ID, ORG_EVENT_TEAM_TICKET_RULES_ID_LIMITS,
} from '../../../../constants/requestUrls';
import { EVENT_TEAM_TICKET_RULES_LIMITS_TAG, EVENT_TEAM_TICKET_RULES_TAG } from '../../../tags/teamTicketRules';
import { CENTS_PER_DOLLAR } from '../../../../constants/general';
import { EVENT_TAG } from '../../../tags/events';
import type IBaseQueryResponse from '../../../types/IBaseQueryResponse';
import type IBaseQueryResWithPagination from '../../../types/IBaseQueryResWithPagination';
import type { IBaseQueryRequest } from '../../../types/IBaseQueryRequest';
import type { ICreateTeamTicketRule } from '../interfaces/ICreateTeamTicketRule';
import type { ITeamTicketRule } from '../interfaces/ITeamTicketRule';
import type { ITeamTicketRuleLimits } from '../interfaces/ITeamTicketRuleLimits';
import type IResponseMeta from '../../../types/IResponseMeta';

const teamTicketRulesApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getTeamTicketRule: builder.query<
    IBaseQueryResWithPagination<ITeamTicketRule>,
    IBaseQueryRequest
    >({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENT_TEAM_TICKET_RULES
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
      }),
      transformResponse: (data: IBaseQueryResWithPagination<ITeamTicketRule>, meta: IResponseMeta) => {
        let timezone;
        if (meta?.response?.headers?.get) {
          timezone = meta.response.headers.get('X-Event-Tz');
        }
        const calculatedMultipleDiscountAmount = data.data?.multipleDiscountAmount
          ? data.data.multipleDiscountAmount / CENTS_PER_DOLLAR : undefined;

        return ({
          ...data,
          data: {
            ...data.data,
            multipleDiscountAmount: calculatedMultipleDiscountAmount,
            timezone,
          },
        });
      },
      providesTags: [EVENT_TEAM_TICKET_RULES_TAG],
    }),

    getTeamTicketRuleLimits: builder.query<
    IBaseQueryResponse<ITeamTicketRuleLimits>,
    IBaseQueryRequest
    >({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENT_TEAM_TICKET_RULES_ID_LIMITS
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
      }),
      providesTags: [EVENT_TEAM_TICKET_RULES_LIMITS_TAG],
    }),

    createTeamTicketRule: builder.mutation<IBaseQueryResponse<ITeamTicketRule>, ICreateTeamTicketRule>({
      query: ({
        orgId,
        eventId,
        ...rest
      }) => ({
        url: ORG_EVENT_TEAM_TICKET_RULES
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'POST',
        body: { ...rest },
      }),
      invalidatesTags: (result, error, { eventId }) => [
        { type: EVENT_TAG, id: eventId },
        EVENT_TEAM_TICKET_RULES_TAG,
        EVENT_TEAM_TICKET_RULES_LIMITS_TAG,
      ],
    }),

    updateTeamTicketRule: builder.mutation<IBaseQueryResponse<ITeamTicketRule>, ICreateTeamTicketRule>({
      query: ({
        orgId,
        eventId,
        ...rest
      }) => ({
        url: ORG_EVENT_TEAM_TICKET_RULES
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'PATCH',
        body: { ...rest },
      }),
      invalidatesTags: (result, error, { eventId }) => [
        { type: EVENT_TAG, id: eventId },
        EVENT_TEAM_TICKET_RULES_TAG,
        EVENT_TEAM_TICKET_RULES_LIMITS_TAG,
      ],
    }),

    deleteTeamTicketRule: builder.mutation<
    IBaseQueryResponse<ITeamTicketRule>,
    IBaseQueryRequest & { teamTicketRuleId: number }
    >({
      query: ({ orgId, eventId, teamTicketRuleId }) => ({
        url: ORG_EVENT_TEAM_TICKET_RULES_ID
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{id}', `${teamTicketRuleId}`),
        method: 'DELETE',
      }),
      invalidatesTags: [
        EVENT_TEAM_TICKET_RULES_TAG,
        EVENT_TEAM_TICKET_RULES_LIMITS_TAG,
      ],
    }),

  }),
});

export const {
  useGetTeamTicketRuleQuery,
  useGetTeamTicketRuleLimitsQuery,
  useCreateTeamTicketRuleMutation,
  useUpdateTeamTicketRuleMutation,
  useDeleteTeamTicketRuleMutation,
} = teamTicketRulesApi;

export default teamTicketRulesApi;
