import { useEffect, useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../store/hooks/useApp';
import selectScheduleBracket from '../store/slices/schedule/selectors';
import { useGetDivisionsGameSetupQuery } from '../store/slices/divisions/apis/divisionsApi';
import { useGetScheduleBracketQuery } from '../store/slices/schedule/apis/scheduleApi';
import { useGetEventPlayingFieldsQuery } from '../store/slices/poolPlays/apis/poolPlaysApi';
import { useCheckValidityPOScheduleQuery, useGetPOFullInfoQuery } from '../store/slices/playOff/apis/playOffApi';
import { setBracketData } from '../store/slices/schedule/slice';
import { generateTournamentBracket } from '../utils/bracket';
import getPlayingFieldsWhereParam from '../utils/playingFields';
import { getFullNameByUser } from '../utils/helpers';
import AppRoutes from '../constants/AppRoutes';
import { DEFAULT_MAX_PER_PAGE } from '../constants/general';
import type { IScheduleSelectItem } from '../components/ScheduleSelect/interfaces/IScheduleSelect';

interface IHookProps {
  skip?: boolean,
  orgId: number,
  eventId: number,
}

const useScheduleBracket = ({
  skip,
  orgId,
  eventId,
}: IHookProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [statisticianName, setStatisticianName] = useState<IScheduleSelectItem[]>([]);
  const [isOpenEditFields, setIsOpenEditFields] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<number | undefined>(undefined);

  const { teams: selectedTeams } = useAppSelector(selectScheduleBracket);

  const { data: divisionsGameSetup, isLoading: isDivisionsLoading } = useGetDivisionsGameSetupQuery(
    skip ? skipToken : {
      orgId,
      eventId,
      perPage: 50,
    },
  );

  const {
    data: bracket,
    isLoading: isBracketLoading,
  } = useGetScheduleBracketQuery(tabValue && tabValue >= 0 && !skip
    ? { orgId, eventId: +eventId, eventDivisionId: tabValue }
    : skipToken);

  const {
    data: checkValidity,
    isLoading: isValidityLoading,
  } = useCheckValidityPOScheduleQuery(skip ? skipToken : { orgId, eventId, isInvalidate: true });

  const { data: playOffFullInfo } = useGetPOFullInfoQuery(!skip ? {
    orgId, eventId: +eventId,
  } : skipToken);

  const playingFieldsWhereParam = getPlayingFieldsWhereParam({
    playOffRules: playOffFullInfo,
  });

  const { data: playingFields, isLoading: isFieldsLoading } = useGetEventPlayingFieldsQuery(
    skip || !playingFieldsWhereParam ? skipToken : {
      orgId,
      eventId: +eventId,
      perPage: DEFAULT_MAX_PER_PAGE,
      where: playingFieldsWhereParam,
    },
  );

  const {
    tournamentBracket,
    teams,
    statisticians,
  } = useMemo(
    () => ({
      tournamentBracket: generateTournamentBracket(
        bracket?.data.eventRounds || [],
        bracket?.data.games || [],
        checkValidity?.data,
      ),
      teams: bracket?.data.filters.teams.map(({ id, name }) => ({ value: id, name })) || [],
      statisticians: bracket?.data.filters.eventGamesStatisticians
        .map(({ user }) => ({ value: user.id, name: getFullNameByUser(user) })) || [],
    }),
    [bracket, checkValidity],
  );

  const handleStatisticianChange = (value: IScheduleSelectItem[]) => {
    setStatisticianName(value);
  };

  const handleTeamChange = (value: IScheduleSelectItem[]) => {
    dispatch(setBracketData({ teams: value }));
  };

  const handleTabChange = (e: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleStatisticianClear = () => {
    setStatisticianName([]);
  };

  const handleTeamClear = () => {
    dispatch(setBracketData({ teams: [] }));
  };

  const handleBack = () => {
    navigate(
      AppRoutes.singleEventPlayOffGameSetup.replace(':eventId', `${eventId}`),
    );
  };

  const handleEditFieldsOpenChange = (open: boolean) => {
    setIsOpenEditFields(open);
  };

  useEffect(() => {
    if (divisionsGameSetup?.data.length) {
      setTabValue(divisionsGameSetup.data[0].id);
    }
  }, [divisionsGameSetup]);

  return {
    timezone: bracket?.timezone,
    tournamentBracket,
    divisionsTabs: divisionsGameSetup?.data.map((item) => ({ value: item.id, name: item.name })) || [],
    tabValue,
    statisticianName,
    teamName: selectedTeams,
    teams,
    statisticians,
    playingFields: playingFields?.data || [],
    handleBack,
    handleTabChange,
    handleStatisticianChange,
    handleTeamChange,
    handleStatisticianClear,
    handleTeamClear,
    handleEditFieldsOpenChange,
    isOpenEditFields,
    isLoading: isDivisionsLoading || isBracketLoading || isFieldsLoading || isValidityLoading || (!tabValue && !skip),
  };
};

export default useScheduleBracket;
