export default {
  welcomeMessage: 'Try to build something great',
  unknownError: 'Unknown error',
  pageNotFound: 'Oops, Page Not Found :(',
  orgName: 'Organization Name',
  goToDashboard: 'Go to Dashboard',
  edit: 'Edit',
  duplicate: 'Duplicate',
  delete: 'Delete',
  publish: 'Publish',
  save: 'Save',
  saveChanges: 'Save changes',
  cancel: 'Cancel',
  optional: 'Optional',
  addDiscountSales: '+ Add discount sales',
  removeDiscountSales: 'Remove discount sales',
  priceWithDiscount: 'Price with discount',
  discountSalesStopsOn: 'Discount sales stops on',
  discountSettings: 'Discount settings',
  noData: 'No data',
  total: 'Total',
  next: 'Next',
  back: 'Back',
  addDate: 'Add date',
  date: 'Date',
  startTime: 'Start time',
  set: 'Set',
  setup: 'Setup',
  yes: 'Yes',
  address: 'Address',
  saveButton: 'Save changes',
  ok: 'OK',
  TOURNAMENT_7ON7: '7v7 Tournament',
  saveAndPublish: 'Save and publish',
  none: 'None',
  exitEdit: 'Exit edit',
};
