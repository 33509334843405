import type { PaletteMode } from '@mui/material';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import { AppThemeMode } from '../../constants/AppThemes';
import useStyles from './papperStyle-tss';
import type IPaperBlock from './interface/IPaperBlock';

/**
 * UI component for displaying paper block.
 */
function PaperBlock({
  title,
  desc,
  children,
  whiteBg,
  noMargin,
  fullHeight,
  colorMode,
  overflowX,
  icon,
  endAdornment,
  titleClassName = '',
}: IPaperBlock): React.ReactElement {
  const { classes, cx } = useStyles();

  const color = (mode: PaletteMode | undefined) => {
    switch (mode) {
      case AppThemeMode.Light:
        return classes.colorLight;
      case AppThemeMode.Dark:
        return classes.colorDark;
      default:
        return classes.colorLight;
    }
  };

  return (
    <Paper
      className={
        cx(
          classes.root,
          noMargin && classes.noMargin,
          fullHeight && classes.fullHeight,
          color(colorMode),
        )
      }
    >
      <div className={classes.descBlock}>
        {!!icon && (
          <Avatar sx={{ color: 'white', mr: 2, bgcolor: '#303030' }}>
            {icon}
          </Avatar>
        )}
        <div className={[classes.titleText, titleClassName].join(' ')}>
          <Typography variant="h6" component="h2" className={classes.title}>
            {title}
          </Typography>
          {desc ? (
            <Typography className={classes.description}>
              {desc}
            </Typography>
          ) : null}
        </div>
        {!!endAdornment && (
          <Box sx={{ ml: 'auto' }}>{endAdornment}</Box>
        )}
      </div>
      <section className={cx(classes.content, whiteBg && classes.whiteBg, overflowX && classes.overflowX)}>
        {children}
      </section>
    </Paper>
  );
}

PaperBlock.defaultProps = {
  whiteBg: false,
  noMargin: false,
  colorMode: undefined,
  overflowX: false,
  icon: undefined,
  endAdornment: undefined,
};

export default PaperBlock;
