import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';

import {
  useCreateEventMutation,
  useDeleteEventCoverPhotoMutation,
  useGetEventQuery,
  useUpdateEventMutation,
  useUploadEventCoverPhotoMutation,
} from '../store/slices/events/apis/eventsApi';
import { selectCreatedEvent } from '../store/slices/events/selectors';
import { createEventStepOneSchema } from '../utils/validators';
import { getErrorMessage, getTimezoneFromCoordinates } from '../utils/helpers';
import { DEFAULT_EVENT_TYPE } from '../constants/general';
import type { ICreateEventFormFields } from '../components/CreateEventForm/interfaces/ICreateEventForm';
import type ICreateEventContext from '../layout/CreateEventLayout/interfaces/ICreateEventContext';

interface IHookProps {
  navigate: (eventId?: number) => void
  onSubmit?: () => void
}

const useEventForm = ({ navigate, onSubmit }: IHookProps) => {
  const {
    eventId,
    orgId,
    isFetch,
    isPublish,
  } = useOutletContext<ICreateEventContext>();
  const fetchQuery = isFetch ? { orgId, eventId: +eventId } : skipToken;

  const { event } = useGetEventQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, event: selectCreatedEvent(result.data) }),
    },
  );
  const [createEvent] = useCreateEventMutation();
  const [updateEvent] = useUpdateEventMutation();
  const [uploadEventCoverPhoto] = useUploadEventCoverPhotoMutation();
  const [deleteEventCoverPhoto] = useDeleteEventCoverPhotoMutation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
    trigger,
    watch,
    setValue,
  } = useForm<ICreateEventFormFields>({
    resolver: yupResolver<ICreateEventFormFields>(createEventStepOneSchema),
    mode: 'onBlur',
    defaultValues: {
      cover: undefined,
      name: '',
      tagline: '',
      address: undefined,
      description: '',
      topic: null,
      type: DEFAULT_EVENT_TYPE,
      email: undefined,
      phone: undefined,
      about: '',
      socialMedia: {
        twitter: '',
        facebook: '',
        instagram: '',
        tiktok: '',
        hudl: '',
        youtube: '',
      },
      startAt: null,
      endAt: null,
      isPublished: false,
      publishAttempt: false,
      withRedirect: true,
    },
  });

  const handleCancel = () => {
    navigate();
    reset();
  };

  const handleDeleteCoverPhoto = () => {
    if (event?.id) {
      deleteEventCoverPhoto({
        orgId,
        eventId: event?.id,
      });
    }
  };

  const handleChangeWithRedirect = () => {
    setValue('withRedirect', false);
  };

  const handleCreateSubmit = handleSubmit(async (values) => {
    const latitude = values.address?.latitude as number;
    const longitude = values.address?.longitude as number;
    const timezone = await getTimezoneFromCoordinates(latitude, longitude);

    const data = {
      orgId,
      name: values.name,
      phone: values.phone || null,
      email: values.email || null,
      address: {
        ...values.address,
        description: values.description || '',
      },
      about: values.about,
      tagline: values.tagline,
      topicId: values.topic?.id,
      socialMedia: values.socialMedia,
      startAt: DateTime.fromJSDate(values.startAt as Date).setZone(timezone, {
        keepLocalTime: !eventId,
      }).toISO(),
      endAt: DateTime.fromJSDate(values.endAt as Date).setZone(timezone, {
        keepLocalTime: !eventId,
      }).toISO(),
    };

    const response = eventId
      ? await updateEvent({ eventId: +eventId, ...data })
      : await createEvent(data);

    if (!('error' in response)) {
      const { data: { data: eventData } } = response;

      if (values.cover && typeof values.cover === 'object') {
        await uploadEventCoverPhoto({
          orgId,
          eventId: eventData.id,
          file: values.cover.file,
        });
      }

      reset();
      if (values.withRedirect) {
        navigate(eventData.id);
      }
      onSubmit?.();
    } else {
      toast.error(getErrorMessage(response.error), { position: toast.POSITION.TOP_RIGHT });
    }
  });

  const addressComment = watch('description');

  useEffect(() => {
    if (addressComment?.length === 1) {
      trigger('address');
    }
  }, [addressComment, trigger]);

  useEffect(() => {
    if (event) {
      reset({
        ...event,
        address: {
          ...event.address,
          description: '',
        },
        description: event.address?.description,
        isPublished: event.published,
        publishAttempt: isPublish,
      });
    }
  }, [reset, event, isPublish]);

  return {
    control,
    isDirty,
    handleCancel,
    handleCreateSubmit,
    handleDeleteCoverPhoto,
    isSubmitting,
    handleChangeWithRedirect,
  };
};

export default useEventForm;
