import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';

import { CREATE_EVENT_EVENT_ID } from '../../../constants/localStorageKeys';
import type IEventHint from '../interfaces/IEventHint';

function EventHint({ errorsList, eventId }: IEventHint): React.ReactElement {
  const { t } = useTranslation();

  const handleClick = () => localStorage.setItem(CREATE_EVENT_EVENT_ID, eventId);

  return (
    <Alert
      variant="filled"
      color="info"
      icon={<InfoIcon />}
      sx={{
        mb: 4.25,
      }}
    >
      <Stack direction="column">
        <Typography variant="body2">
          {t('events.requiredFieldsHintTitle')}
        </Typography>
        <Stack component="ul" mb={0} pl={4}>
          {errorsList.map((item) => (
            <Box key={item.name} component="li">
              <Typography
                component={NavLink}
                onClick={handleClick}
                to={`${item.path}?isPublish=true`}
                target="_blank"
                color="primary"
                variant="body2"
              >
                {`${item.name}:`}
              </Typography>
              <Typography
                variant="body2"
                component="span"
                ml={1}
              >
                {`${item.properties.map(({ label }) => label).join(', ')}.`}
              </Typography>
            </Box>
          ))}
        </Stack>
      </Stack>
    </Alert>
  );
}

export default EventHint;
