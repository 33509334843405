import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useWatch } from 'react-hook-form';

import type ISendDialog from '../interfaces/ISendDialog';

function SendDialog({
  control,
  open,
  onConfirm,
  onClose,
  isSubmitting,
}: ISendDialog): React.ReactElement {
  const { t } = useTranslation();

  const {
    recipientsValues,
    type,
    title,
    message,
  } = useWatch({ control });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: 600,
          p: '40px 34px',
        },
      }}
    >
      <DialogTitle variant="h5" fontWeight="bold" sx={{ p: 'unset', mb: 2 }}>
        {t('notifications.notificationDialogTitle')}
      </DialogTitle>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography variant="body2" color="#D9D9D9">
              {t('notifications.selectRecipients')}
            </Typography>
            <Typography variant="body1">
              {recipientsValues?.map((item) => t(`notifications.${item}`))?.join(', ')}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography variant="body2" color="#D9D9D9">
              {t('notifications.notificationType')}
            </Typography>
            <Typography variant="body1">
              {t(`notifications.${type}`)}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2}>
            <Typography variant="body2" color="#D9D9D9">
              {t('notifications.subject')}
            </Typography>
            <Typography variant="body1">
              {title}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2}>
            <Typography variant="body2" color="#D9D9D9">
              {t('notifications.message')}
            </Typography>
            <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
              {message}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <DialogActions
        sx={{ p: 'unset', mt: 4.25, gap: '12px' }}
        disableSpacing
      >
        <Button
          sx={{ width: '143px' }}
          variant="outlined"
          onClick={onClose}
        >
          {t('notifications.cancel')}
        </Button>
        <Button
          sx={{ width: '143px' }}
          variant="contained"
          onClick={onConfirm}
          disabled={isSubmitting}
        >
          {t('notifications.send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SendDialog;
