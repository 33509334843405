import { useTranslation } from 'react-i18next';
import { Controller, useWatch } from 'react-hook-form';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import clsx from 'clsx';
import { Check } from '@mui/icons-material';

import RECIPIENTS from '../../../constants/recipients';
import NOTIFICATIONS from '../../../constants/notificationType';
import CheckIcon from '../../../components/Icons/CheckIcon';
import useStyles from './setingsSectionStyle-tss';
import type ISettingsSection from '../interfaces/ISettingsSection';

function SettingsSection({
  control,
  onSelectRecipient,
  onValidateRecipient,
  onSelectType,
}: ISettingsSection) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const recipients = useWatch({ control, name: 'recipientsValues' });
  const type = useWatch({ control, name: 'type' });

  return (
    <Paper sx={{ p: 3 }}>
      <Box>
        <Typography variant="h6" component="h2" mb={3}>
          {t('notifications.notificationSettings')}
        </Typography>
        <Box mb={3}>
          <FormLabel component="legend">
            <Typography variant="body2" component="h3" mb={1} color="#D9D9D9">
              {t('notifications.selectRecipients')}
            </Typography>
          </FormLabel>
          <FormGroup>
            <Stack direction="row" flexWrap="wrap">
              {RECIPIENTS.map(({ name, label, value }) => (
                <Controller
                  key={label}
                  name={name}
                  control={control}
                  render={({
                    field: { onChange },
                  }) => (
                    <FormControl>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
                            checked={recipients.includes(value)}
                            onChange={(e) => {
                              onChange(e);
                              onSelectRecipient(value);
                              onValidateRecipient();
                            }}
                            checkedIcon={<CheckIcon />}
                          />
                      )}
                        label={<Typography variant="body1">{t(label)}</Typography>}
                      />
                    </FormControl>
                  )}
                />
              ))}
            </Stack>
          </FormGroup>
        </Box>
        <Box>
          <FormLabel component="legend">
            <Typography variant="body2" component="h3" mb={1} color="#D9D9D9">
              {t('notifications.notificationType')}
            </Typography>
          </FormLabel>
          <Stack direction="row" spacing={3}>
            {NOTIFICATIONS.map(({ title, text, value }) => (
              <Stack
                key={value}
                spacing={0.5}
                className={classes.radio}
                sx={{
                  border: type === value ? '1px solid #02A9F4' : '1px solid #757575',
                }}
                onClick={() => onSelectType(value)}
              >
                <Typography variant="body2">
                  {t(title)}
                </Typography>
                <Typography variant="body2" color="#757575" sx={{ maxWidth: '90%' }}>
                  {t(text)}
                </Typography>
                <Box className={classes.checkbox}>
                  <Box className={clsx(classes.backdrop, {
                    [classes.selected]: type === value,
                  })}
                  >
                    <Check sx={{ fontSize: '18px' }} />
                  </Box>
                </Box>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Box>
    </Paper>
  );
}

export default SettingsSection;
