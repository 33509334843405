import { useCallback, useState } from 'react';

import { DEFAULT_PER_PAGE } from '../constants/general';
import type IBasePagination from '../interfaces/IBasePagination';

function useTablePagination(): Omit<IBasePagination, 'total'> {
  const [page, setPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(DEFAULT_PER_PAGE);

  const handleChangePage = useCallback((_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangePerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPerPage(+event.target.value);
    setPage(0);
  }, []);

  return {
    page,
    perPage,
    perPageOptions: [25, 50, 100],
    handleChangePage,
    handleChangePerPage,
  };
}

export default useTablePagination;
