// S3
export const BASE_S3_URL = process.env.REACT_APP_BASE_S3_URL || '';

// Auth
export const AUTH_SIGN_IN = '/api/v1/auth/sign-in/tournaments-organizations';
export const AUTH_REFRESH_TOKEN = '/api/v1/auth/refresh-token';
export const AUTH_LOG_OUT = '/api/v1/auth/logout';

// Tournaments Organisation
export const ORG_ME_MEMBERSHIP = '/api/v1/tournaments-organizations/me/membership';
export const ORG_MEMBERS = '/api/v1/tournaments-organizations/{organizationId}/members';
export const ORG_MEMBERS_ADDRESS = '/api/v1/tournaments-organizations/{organizationId}/members/address';

// Tournaments Organisation Events
export const ORG_EVENTS = '/api/v1/tap/tournaments-organizations/{organizationId}/events';
export const ORG_EVENTS_ID = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{id}';
export const ORG_EVENTS_COVER_PHOTO = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{id}/cover-photo';
export const ORG_EVENTS_PROFILE_PHOTO = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{id}/profile-photo';
export const ORG_EVENTS_ADMIN_LIST = '/api/v1/tap/tournaments-organizations/{organizationId}/events/admin-list';
export const ORG_EVENTS_PUBLISH = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{id}/publish';
export const ORG_EVENTS_DUPLICATE = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{id}/duplicate';
export const ORG_EVENTS_CANCEL = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{id}/cancel';
export const ORG_EVENT_DIVISION_DAYS = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{id}/days';

// Tournaments Organization Events Hotel
export const ORG_EVENTS_HOTELS = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/hotels';

// Tournaments Organization Events Partner
export const ORG_EVENTS_PARTNERS = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/partners';

// Tournaments Organization Events Waiver
export const ORG_EVENTS_WAIVER = '/api/v1/tournaments-organizations/{organizationId}/events/{eventId}/waiver';
export const ORG_EVENTS_WAIVER_MUTATION = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/waiver';
export const ORG_EVENTS_WAIVER_ID = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/waiver/{id}';
export const ORG_EVENTS_WAIVER_AGREEMENT = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/waiver/list-waivers-with-agreement';
export const ORG_EVENTS_WAIVER_AGREEMENT_TEAMS = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/waiver/list-teams/filter';
export const ORG_EVENTS_WAIVER_AGREEMENT_WAIVER_LIST = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/waiver/list-waivers/filter';

// Tournament Organization Events Rules
export const ORG_EVENTS_RULES = '/api/v1/tournaments-organizations/{organizationId}/events/{eventId}/rules';
export const ORG_EVENTS_RULES_MUTATION = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/rules';
export const ORG_EVENTS_RULES_ID = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/rules/{id}';

// Tournaments Organisation Event Topics
export const ORG_EVENTS_TOPICS = '/api/v1/tap/tournaments-organizations/{organizationId}/events-topics';

// Tournaments Organization Event Division
export const ORG_EVENT_DIVISION = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/divisions';
export const ORG_EVENT_DIVISION_ID = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/divisions/{id}';
export const ORG_EVENT_DIVISION_ID_DISCOUNT = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/divisions/{id}/discount';
export const ORG_EVENT_DIVISION_ID_UNSAVED_FIELDS = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/divisions/{id}/unsaved-fields';
export const ORG_EVENT_DIVISION_ID_ATHLETE_FIELDS = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/divisions/{id}/athlete-fields';
export const ORG_EVENT_DIVISION_ID_COUNT = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/divisions/{id}/count';
export const ORG_EVENT_DIVISION_GAME_SETUP = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/divisions/game-setups';
export const ORG_EVENT_DIVISION_PLAY_OFF_GAME_SETUP = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/divisions/play-off-game-setups';

// Tournaments Organization Event Division
export const ORG_EVENT_SPECTATOR = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/spectator-rules';
export const ORG_EVENT_SPECTATOR_TYPE_RULE = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/spectator-rules/type-rule';
export const ORG_EVENT_SPECTATOR_DAY_RULE = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/spectator-rules/day-rule';
export const ORG_EVENT_SPECTATOR_DAY_RULES = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/spectator-rules/day-rules';
export const ORG_EVENT_SPECTATOR_DAY_RULE_ID = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/spectator-rules/day-rule/{dayRuleId}';
export const ORG_EVENT_SPECTATOR_TYPE_RULE_ID = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/spectator-rules/type-rule/{ruleId}';
export const ORG_EVENT_SPECTATOR_TYPE_RULE_ID_DISCOUNT = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/spectator-rules/type-rule/{ruleId}/discount';

// Tournaments Organisation Hotels
export const ORG_HOTELS = '/api/v1/tap/tournaments-organizations/{organizationId}/hotels';
export const ORG_HOTELS_ID = '/api/v1/tap/tournaments-organizations/{organizationId}/hotels/{id}';

// Tournaments Organisation Partners
export const ORG_PARTNERS = '/api/v1/tap/tournaments-organizations/{organizationId}/partners';
export const ORG_PARTNERS_ID = '/api/v1/tap/tournaments-organizations/{organizationId}/partners/{id}';
export const ORG_PARTNERS_COVER_PHOTO = '/api/v1/tap/tournaments-organizations/{organizationId}/partners/cover-photo/{id}';

// Tournament Organisation Payment Processors
export const ORG_PAYMENT_PROCESSORS = '/api/v1/tap/tournaments-organizations/{organizationId}/payment-processors';

// Tournament Organisation Teams
export const ORG_EVENT_TEAMS = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/teams/registered';
export const ORG_EVENT_TEAMS_CLUB_FILTER = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/teams/registered-club-filter';
export const ORG_EVENT_TEAMS_COACH_FILTER = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/teams/registered-coach-filter';

// Tournament Organisation Event Team Ticket Rules
export const ORG_EVENT_TEAM_TICKET_RULES = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/team-ticket-rules';
export const ORG_EVENT_TEAM_TICKET_RULES_ID = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/team-ticket-rules/{id}';
export const ORG_EVENT_TEAM_TICKET_RULES_ID_LIMITS = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/team-ticket-rules/max-limits';

// Tournaments Organization Analytics
export const ORG_ANALYTICS_TOTAL_EARNED = '/api/v1/tap/tournaments-organizations/{organizationId}/analytics/total-earned';
export const ORG_ANALYTICS_TOTAL_EVENTS = '/api/v1/tap/tournaments-organizations/{organizationId}/analytics/total-events-by-status';
export const ORG_ANALYTICS_TOTAL_EVENTS_EARNED = '/api/v1/tap/tournaments-organizations/{organizationId}/analytics/total-earned-per-event';
export const ORG_ANALYTICS_TOTAL_SEASON_EARNED = '/api/v1/tap/tournaments-organizations/{organizationId}/analytics/total-earned-for-season';

// Tournaments Organization Events Analytics
export const ORG_EVENT_ANALYTICS_TOTAL_EARNED = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/analytics/earnings';
export const ORG_EVENT_ANALYTICS_REVENUE = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/analytics/event-revenue';
export const ORG_EVENT_ANALYTICS_MEMBERS_COUNT = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/analytics/count-teams-players-coaches';
export const ORG_EVENT_ANALYTICS_TEAM_PER_DIVISION = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/analytics/registered-team-per-division';
export const ORG_EVENT_ANALYTICS_TEAMS_EARNED_BY_DIVISION = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/analytics/teams-earned-by-date';
export const ORG_EVENT_ANALYTICS_SPECTATORS_EARNED = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/analytics/spectators-earned-by-date';
export const ORG_EVENT_ANALYTICS_REFUNDS = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/analytics/refunds';
export const ORG_EVENT_ANALYTICS_SPECTATORS_BY_TYPE = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/analytics/spectator-tickets-by-type';
export const ORG_EVENT_ANALYTICS_SPECTATORS_BY_PASS = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/analytics/spectator-tickets-by-pass';

// Tournaments Organization Events Pool Play Rules
export const ORG_EVENT_POOL_PLAYS_RULES = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/pool-plays-rules';
export const ORG_EVENT_PP_RULES_TIME_FRAME = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/pool-plays-rules/time-frame';
export const ORG_EVENT_PP_RULES_TIME_FRAMES_RANGE = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/pool-plays-rules/time-frames-range';
export const ORG_EVENT_PP_RULES_TIME_FRAME_ID = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/pool-plays-rules/time-frame?timeFrameId={timeFrameId}';
export const ORG_EVENT_PP_RULES_FULL_INFO = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/pool-plays-rules/full-info';
export const ORG_EVENT_PP_RULES_ID_TIME_FRAME = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/pool-plays-rules/{playRuleId}/time-frame';
export const ORG_EVENT_PP_RULES_PUBLISH = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/pool-plays-rules/publish';

// Tournaments Organization Events Play Off Rules
export const ORG_EVENT_PLAY_OFF_RULES = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/play-off-rules';
export const ORG_EVENT_PO_RULES_ID_TIME_FRAME = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/play-off-rules/{playRuleId}/time-frame';
export const ORG_EVENT_PO_RULES_TIME_FRAME_ID = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/play-off-rules/time-frame?timeFrameId={timeFrameId}';
export const ORG_EVENT_PO_RULES_PUBLISH = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/play-off-rules/publish';

// Tournaments Organization Event Pool Plays Division rules
export const ORG_EVENT_PP_DIVISION_ID_RULES = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/pool-plays/division-rules/{divisionId}';
export const ORG_EVENT_PP_DIVISION_ID_RULES_SETUP_ALL = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/pool-plays/division-rules/{divisionId}/setup-all';
export const ORG_EVENT_PP_DIVISION_ID_RULES_PLAYING_FIELDS = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/pool-plays/division-rules/{divisionId}/playing-fields';
export const ORG_EVENT_PP_DIVISION_ID_RULES_SWAP_POOL_TEAMS = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/pool-plays/division-rules/{divisionId}/swap-pool-teams';

// Game7x7Settings
export const GAME_7X7_SETTINGS_BY_ORG_ID = '/api/v1/game7x7-settings/by-organization/{organizationId}';

// Tournaments Organization Event Playing Fields
export const ORG_EVENT_PLAYING_FIELDS = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/playing-fields';
export const ORG_EVENT_PLAYING_FIELDS_ID = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/playing-fields/{id}';
export const ORG_EVENT_PLAYING_FIELDS_ID_STATISTICIANS = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/playing-fields/{id}/statisticians';

// Tournaments Organization Events PP Rules Games Statistician
export const ORG_EVENT_PP_RULES_GAME_STATISTICIANS = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/pp-rules-games-statistician';

// Tournaments Organization Event Pool Plays Schedule
export const ORG_EVENT_PP_SCHEDULE_SETUP_GAMES = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/pool-plays-schedule/setup-games';
export const ORG_EVENT_PP_SCHEDULE_CHECK_VALIDITY = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/pool-plays-schedule/check-validity';
export const ORG_EVENT_PP_SCHEDULE_CHANGE_GAME_POSITION = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/pool-plays-schedule/bulk-change-game-position/{pPlaysRulesId}';
export const ORG_EVENT_PP_SCHEDULE_REGENERATE = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/pool-plays-schedule/reset-up-games/{pPlaysRulesId}';

// Tournaments Organization Events Play Off Rules
export const ORG_EVENT_PLAY_OFF_RULES_TIME_FRAMES = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/play-off-rules/time-frame';
export const ORG_EVENT_PLAY_OFF_RULES_TIME_FRAMES_RANGE = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/play-off-rules/time-frames-range';
export const ORG_EVENT_PO_RULES_FULL_INFO = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/play-off-rules/full-info';

// Tournaments Organization Event Play Off Division rules
export const ORG_EVENT_PLAY_OFF_DIVISION_ID_INFO = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/play-off/division-rules/{divisionId}/info';
export const ORG_EVENT_PLAY_OFF_DIVISION_ID = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/play-off/division-rules/{divisionId}';
export const ORG_EVENT_PLAY_OFF_DIVISION_ID_PLAYING_FIELDS = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/play-off/division-rules/{divisionId}/playing-fields';
export const ORG_EVENT_PLAY_OFF_DIVISION_ID_SETUP_ALL = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/play-off/division-rules/{divisionId}/setup-all';

// Tournaments Organization Event Division Games Pool
export const ORG_EVENT_DIVISION_GAMES_POOL_PLAYS = '/api/v1/tournaments-organizations/{organizationId}/events/{eventId}/division-games-pool/{divisionId}/plays';
export const ORG_EVENT_DIVISION_GAMES_OVERALL_PLAYS = '/api/v1/tournaments-organizations/{organizationId}/events/{eventId}/division-games-pool/{divisionId}/overall-plays';
export const ORG_EVENT_DIVISION_GAMES_OVERALL_SELECTS = '/api/v1/tournaments-organizations/{organizationId}/events/{eventId}/division-games-pool/selects';
export const ORG_EVENT_DIVISION_GAMES_OVERALL_TEAMS_SELECTS = '/api/v1/tournaments-organizations/{organizationId}/events/{eventId}/division-games-pool/teams/selects';

// Tournaments Organization Event Schedule
export const ORG_EVENT_SCHEDULE = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/schedule';
export const ORG_EVENT_SCHEDULE_BRACKET = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/schedule/bracket?eventDivisionId={eventDivisionId}';
export const ORG_EVENT_SCHEDULE_BULK_CHANGE_GAME_POSITION = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/schedule/bulk-change-game-position/{playOffRulesId}';
export const ORG_EVENT_SCHEDULE_GAMES_TIME_RANGE = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/schedule/games-time-range';

// Tournaments Organization Event Play Off Schedule
export const ORG_EVENT_PO_SCHEDULE_CHECK_VALIDITY = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/play-off-schedule/check-validity';
export const ORG_EVENT_PO_SCHEDULE_RE_SETUP_GAMES = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/play-off-schedule/reset-up-games/{playOffRulesId}';

// Tournaments Organization Event Notifications
export const ORG_EVENT_NOTIFICATION = '/api/v1/tap/tournaments-organizations/{organizationId}/events/{eventId}/notification';
