import mainApi from '../../../apis/mainApi';
import {
  ORG_EVENT_DIVISION,
  ORG_EVENT_DIVISION_GAME_SETUP,
  ORG_EVENT_DIVISION_ID,
  ORG_EVENT_DIVISION_ID_ATHLETE_FIELDS,
  ORG_EVENT_DIVISION_ID_COUNT,
  ORG_EVENT_DIVISION_ID_DISCOUNT,
  ORG_EVENT_DIVISION_ID_UNSAVED_FIELDS,
  ORG_EVENT_DIVISION_PLAY_OFF_GAME_SETUP,
} from '../../../../constants/requestUrls';
import { CENTS_PER_DOLLAR, TEAM_AGES } from '../../../../constants/general';
import {
  EVENT_DIVISIONS_GAME_SETUP,
  EVENT_DIVISIONS_LIST_TAG,
  EVENT_DIVISIONS_PLAY_OFF_GAME_SETUP,
} from '../../../tags/divisions';
import { EVENT_DAYS_TAG } from '../../../tags/events';
import { ANALYTICS_TEAM_PER_DIVISION_TAG } from '../../../tags/analytics';
import type IBaseQueryResponse from '../../../types/IBaseQueryResponse';
import type { IBaseQueryReqWithParams } from '../../../types/IBaseQueryReqWithParams';
import type IBaseQueryResWithPagination from '../../../types/IBaseQueryResWithPagination';
import type { IDivision } from '../interfaces/IDivision';
import type { ICreateDivisionReq } from '../interfaces/ICreateDivisionReq';
import type { IBaseQueryRequest } from '../../../types/IBaseQueryRequest';
import type { IDiscountReq } from '../interfaces/IDiscountReq';
import type { TGender } from '../../../../interfaces/TGender';
import type IDivisionsGameSetup from '../interfaces/IDivisionsGameSetup';
import type { IDivisionsPlayOffGameSetup } from '../interfaces/IDivisionsGameSetup';
import type IResponseMeta from '../../../types/IResponseMeta';

const divisionsApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    createDivision: builder.mutation<IBaseQueryResponse<IDivision>, ICreateDivisionReq>({
      query: ({
        orgId,
        eventId,
        ...rest
      }) => ({
        url: ORG_EVENT_DIVISION
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'POST',
        body: { ...rest },
      }),
      invalidatesTags: (result, error, { eventId }) => ([
        EVENT_DIVISIONS_LIST_TAG,
        { type: ANALYTICS_TEAM_PER_DIVISION_TAG, id: eventId },
      ]),
    }),

    updateDivision: builder.mutation<IBaseQueryResponse<IDivision>, ICreateDivisionReq & { divisionId: number }>({
      query: ({
        orgId,
        eventId,
        divisionId,
        ...rest
      }) => ({
        url: ORG_EVENT_DIVISION_ID
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{id}', `${divisionId}`),
        method: 'PATCH',
        body: { ...rest },
      }),
      invalidatesTags: (_result, _error, { divisionId }) => [{ type: EVENT_DIVISIONS_LIST_TAG, id: divisionId }],
    }),

    deleteDivision: builder.mutation<
    IBaseQueryResponse<IDivision>,
    IBaseQueryRequest & { eventId: number, divisionId: number }
    >({
      query: ({ orgId, eventId, divisionId }) => ({
        url: ORG_EVENT_DIVISION_ID
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{id}', `${divisionId}`),
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { eventId, divisionId }) => (
        [
          { type: EVENT_DIVISIONS_LIST_TAG, id: divisionId },
          { type: ANALYTICS_TEAM_PER_DIVISION_TAG, id: eventId },
        ]
      ),
    }),

    getDivisionsList: builder.query<
    IBaseQueryResWithPagination<IDivision[]>,
    IBaseQueryReqWithParams & { eventId: number }
    >({
      query: ({
        orgId,
        eventId,
        page,
        perPage,
      }) => ({
        url: ORG_EVENT_DIVISION
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
        params: { page, perPage },
      }),
      transformResponse: (data: IBaseQueryResWithPagination<IDivision[]>, meta: IResponseMeta) => {
        let timezone: string;
        if (meta?.response?.headers?.get) {
          timezone = meta.response.headers.get('X-Event-Tz');
        }

        return ({
          ...data,
          data: data.data
            .sort((a, b) => TEAM_AGES.indexOf(a.division.age) - TEAM_AGES.indexOf(b.division.age))
            .map((item) => ({
              ...item,
              ticketPrice: item.ticketPrice / CENTS_PER_DOLLAR,
              discount: item.discount ? { ...item.discount, price: item.discount.price / CENTS_PER_DOLLAR } : null,
              timezone,
            })),
        });
      },
      providesTags: (result) => (
        result
          ? [...result.data.map(({ id }) => ({ type: EVENT_DIVISIONS_LIST_TAG, id })), EVENT_DIVISIONS_LIST_TAG]
          : [EVENT_DIVISIONS_LIST_TAG]
      ),
    }),

    updateDiscount: builder.mutation<
    IBaseQueryResponse<IDivision>,
    IBaseQueryRequest & { eventId: number, divisionId: number, discount: IDiscountReq }
    >({
      query: ({
        orgId, eventId, divisionId, discount,
      }) => ({
        url: ORG_EVENT_DIVISION_ID_DISCOUNT
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{id}', `${divisionId}`),
        method: 'PUT',
        body: discount,
      }),
      invalidatesTags: (_result, _error, { divisionId }) => [
        { type: EVENT_DIVISIONS_LIST_TAG, id: divisionId },
        EVENT_DAYS_TAG,
      ],
    }),

    deleteDiscount: builder.mutation<
    IBaseQueryResponse<IDivision>,
    IBaseQueryRequest & { eventId: number, divisionId: number }
    >({
      query: ({ orgId, eventId, divisionId }) => ({
        url: ORG_EVENT_DIVISION_ID_DISCOUNT
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{id}', `${divisionId}`),
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { divisionId }) => [{ type: EVENT_DIVISIONS_LIST_TAG, id: divisionId }],
    }),

    updateDivisionAthleteFields: builder.mutation<
    IBaseQueryResponse<IDivision>,
    IBaseQueryRequest & { divisionId: number, gender: TGender, age: string }
    >({
      query: ({
        orgId, eventId, divisionId, ...rest
      }) => ({
        url: ORG_EVENT_DIVISION_ID_ATHLETE_FIELDS
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{id}', `${divisionId}`),
        method: 'PATCH',
        body: { ...rest },
      }),
      invalidatesTags: (_result, _error, { divisionId }) => [{ type: EVENT_DIVISIONS_LIST_TAG, id: divisionId }],
    }),

    updateDivisionUnsavedFields: builder.mutation<
    IBaseQueryResponse<IDivision>,
    IBaseQueryRequest & { divisionId: number, ticketPrice: number, name: string }
    >({
      query: ({
        orgId, eventId, divisionId, ...rest
      }) => ({
        url: ORG_EVENT_DIVISION_ID_UNSAVED_FIELDS
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{id}', `${divisionId}`),
        method: 'PATCH',
        body: { ...rest },
      }),
      invalidatesTags: (_result, _error, { divisionId }) => [{ type: EVENT_DIVISIONS_LIST_TAG, id: divisionId }],
    }),

    updateDivisionCount: builder.mutation<
    IBaseQueryResponse<IDivision>,
    IBaseQueryRequest & { divisionId: number, ticketCount: number | null }
    >({
      query: ({
        orgId, eventId, divisionId, ...rest
      }) => ({
        url: ORG_EVENT_DIVISION_ID_COUNT
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{id}', `${divisionId}`),
        method: 'PATCH',
        body: { ...rest },
      }),
      invalidatesTags: (_result, _error, { divisionId }) => [{ type: EVENT_DIVISIONS_LIST_TAG, id: divisionId }],
    }),

    getDivisionsGameSetup: builder.query<IBaseQueryResWithPagination<IDivisionsGameSetup[]>, IBaseQueryReqWithParams>({
      query: ({ orgId, eventId, ...params }) => ({
        url: ORG_EVENT_DIVISION_GAME_SETUP
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
        params,
      }),
      transformResponse: (data: IBaseQueryResWithPagination<IDivisionsGameSetup[]>) => ({
        ...data,
        data: data.data
          .map((item) => ({
            ...item,
            gamesPools: [...item.gamesPools].sort((a, b) => {
              if (a.name.length !== b.name.length) {
                return a.name.length - b.name.length;
              }
              return a.name.localeCompare(b.name);
            }),
          })),
      }),
      providesTags: (result) => (
        result
          ? [...result.data.map(({ id }) => ({ type: EVENT_DIVISIONS_GAME_SETUP, id })), EVENT_DIVISIONS_GAME_SETUP]
          : [EVENT_DIVISIONS_GAME_SETUP]
      ),
    }),

    getDivisionsPlayOffGameSetup: builder.query<
    IBaseQueryResWithPagination<IDivisionsPlayOffGameSetup[]>,
    IBaseQueryReqWithParams
    >({
      query: ({ orgId, eventId, ...params }) => ({
        url: ORG_EVENT_DIVISION_PLAY_OFF_GAME_SETUP
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
        params,
      }),
      providesTags: (result) => (
        result
          ? [
            ...result.data.map(({ id }) => ({ type: EVENT_DIVISIONS_PLAY_OFF_GAME_SETUP, id })),
            EVENT_DIVISIONS_PLAY_OFF_GAME_SETUP,
          ]
          : [EVENT_DIVISIONS_PLAY_OFF_GAME_SETUP]
      ),
    }),
  }),
});

export const {
  useCreateDivisionMutation,
  useDeleteDivisionMutation,
  useUpdateDivisionMutation,
  useUpdateDiscountMutation,
  useDeleteDiscountMutation,
  useUpdateDivisionAthleteFieldsMutation,
  useUpdateDivisionUnsavedFieldsMutation,
  useUpdateDivisionCountMutation,
  useGetDivisionsListQuery,
  useGetDivisionsGameSetupQuery,
  useGetDivisionsPlayOffGameSetupQuery,
} = divisionsApi;

export default divisionsApi;
