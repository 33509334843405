import type { SyntheticEvent } from 'react';
import {
  forwardRef, useCallback, useEffect, useState,
} from 'react';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { preparePlaceDetails } from '../../utils/helpers';
import { ONLY_NUMBERS_REGEXP } from '../../utils/validators';
import { GOOGLE_PLACES_AUTOCOMPLETE_PARAMS_TYPES } from '../../constants/general';
import type IPlacesAutocomplete from './interface/IPlacesAutocomplete';

const PlacesAutocomplete = forwardRef(({
  defaultValue = '', label, error, helperText, onChange, onBlur,
}: IPlacesAutocomplete, ref) => {
  const [placeValue, setPlaceValue] = useState<google.maps.places.AutocompletePrediction | null>(null);
  const [inputValue, setInputValue] = useState<string>(defaultValue);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE,
    sessionToken: true,
    language: 'en',
    options: {
      input: defaultValue,
      componentRestrictions: { country: 'us' },
    },
  });

  const handleInputChange = useCallback((newValue: string) => {
    setInputValue(newValue);
    getPlacePredictions({
      input: newValue,
      types: [
        ONLY_NUMBERS_REGEXP.test(newValue)
          ? GOOGLE_PLACES_AUTOCOMPLETE_PARAMS_TYPES.REGIONS
          : GOOGLE_PLACES_AUTOCOMPLETE_PARAMS_TYPES.ADDRESS,
      ],
    });
  }, [getPlacePredictions]);

  const handleSelectPlace = (
    _: SyntheticEvent<Element, Event>,
    value: google.maps.places.AutocompletePrediction | null,
  ) => {
    setPlaceValue(value);
    setInputValue(value?.description || '');

    if (value?.place_id && placesService) {
      placesService.getDetails(
        { placeId: value.place_id },
        (placeDetails) => {
          const address = preparePlaceDetails(placeDetails);
          onChange({ ...address, streetAddress1: value?.description || '' });
        },
      );
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Autocomplete
      ref={ref}
      options={placePredictions}
      filterOptions={(options) => options}
      getOptionLabel={(option) => option.description}
      renderInput={(params) => (
        <TextField
          helperText={helperText}
          error={error}
          onBlur={onBlur}
          onChange={({ target }) => handleInputChange(target.value)}
          label={label}
          variant="outlined"
          {...params}
        />
      )}
      inputValue={inputValue}
      isOptionEqualToValue={(option, v) => option?.place_id === v?.place_id}
      onChange={handleSelectPlace}
      loading={isPlacePredictionsLoading}
      value={placeValue}
      componentsProps={{ paper: { elevation: 8 } }}
      disablePortal
      fullWidth
    />
  );
});

export default PlacesAutocomplete;
