import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(
  () => ({
    root: {
      '& > .MuiButtonBase-root:first-of-type': {
        order: 1,
      },
      '& > .MuiButtonBase-root:last-of-type': {
        order: 2,
      },
    },
  }),
);

export default useStyles;
