import { useCallback } from 'react';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';

import TeamsTicketsView from './TeamsTicketsView';
import { useAppDispatch } from '../../store/hooks/useApp';
import { addCompletedStep, setLeaveModal } from '../../store/slices/createEvent/slice';
import useEventDivisionsForm from '../../hooks/useEventDivisionsForm';
import AppRoutes from '../../constants/AppRoutes';
import { DEFAULT_DATE_FORMAT, EVENT_FORM_TYPES } from '../../constants/general';
import type ICreateEventContext from '../../layout/CreateEventLayout/interfaces/ICreateEventContext';

function TeamsTickets(): React.ReactElement {
  const { activeStep } = useOutletContext<ICreateEventContext>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const isEditPage = searchParams.get('editPage');
  const {
    event,
    control,
    divisions,
    handlePrecreateDivisions,
    handleRemoveDivision,
    handleRemoveDiscount,
    handleAddOneDivision,
    handleAppendDiscount,
    handleCreateSubmit,
    handleCancel,
    isDirty,
    isSubmitting,
    handlePrecreateDivisionName,
  } = useEventDivisionsForm({
    navigate: () => {
      dispatch(addCompletedStep(activeStep));
      if (isEditPage) {
        navigate(AppRoutes.spectatorsTickets.concat('?editPage=true'));
      } else {
        navigate(AppRoutes.spectatorsTickets);
      }
    },
    onCancel: (dirty) => {
      const link = isEditPage
        ? AppRoutes.documents.concat('?editPage=true')
        : AppRoutes.documents;

      if (dirty) {
        dispatch(setLeaveModal({ isOpen: true, link }));
      } else {
        navigate(link);
      }
    },
    type: EVENT_FORM_TYPES.CREATE,
  });

  const handleStepClick = useCallback((link: string) => {
    const currentLink = isEditPage
      ? link.concat('?editPage=true')
      : link;

    if (isDirty) {
      dispatch(setLeaveModal({ isOpen: true, link: currentLink }));
    } else {
      navigate(currentLink);
    }
  }, [isDirty, isEditPage, dispatch, navigate]);

  return (
    <TeamsTicketsView
      eventName={event.nameWithTagline}
      control={control}
      divisions={divisions}
      eventStartDate={event.startAt?.toFormat(DEFAULT_DATE_FORMAT) || '-'}
      eventEndDate={event.endAt?.toFormat(DEFAULT_DATE_FORMAT) || '-'}
      maxRegistrationDate={event.endAt || null}
      maxDiscountDate={event.endAt}
      activeStep={activeStep}
      onStepClick={handleStepClick}
      onRemoveDivision={handleRemoveDivision}
      onRemoveDiscount={handleRemoveDiscount}
      onCreateSubmit={handleCreateSubmit}
      onPrecreateDivisions={handlePrecreateDivisions}
      onAddOneDivision={handleAddOneDivision}
      onAppendDiscount={handleAppendDiscount}
      onBackStep={handleCancel}
      isSubmitting={isSubmitting}
      isPublished={event.published}
      onPrecreateDevisionName={handlePrecreateDivisionName}
    />
  );
}

export default TeamsTickets;
