import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate, useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import type { SerializedError } from '@reduxjs/toolkit';

import SingleEventPartnersView from './SingleEventPartnersView';
import { useGetEventPartnersQuery } from '../../store/slices/events/apis/eventsApi';
import { selectEventPartners } from '../../store/slices/events/selectors';
import { useAppSelector } from '../../store/hooks/useApp';
import { selectUserOrg } from '../../store/slices/user/selectors';
import {
  useCreatePartnerMutation,
  useUpdatePartnerMutation,
  useDeletePartnerByEventMutation,
  useUploadPartnerCoverPhotoMutation,
} from '../../store/slices/partners/apis/partnersApi';
import { getErrorMessage } from '../../utils/helpers';
import { editEventPartnersSchema } from '../../utils/validators';
import type { IPartnersForm } from './interfaces/IPartnersForm';

function SingleEventPartners(): React.ReactElement {
  const { t } = useTranslation();
  const { eventId } = useParams();
  const navigate = useNavigate();
  const organization = useAppSelector(selectUserOrg);

  const { eventPartners } = useGetEventPartnersQuery(
    eventId ? { orgId: organization?.id as number, eventId: +eventId } : skipToken,
    {
      selectFromResult: (result) => ({ ...result, eventPartners: selectEventPartners(result.data) }),
    },
  );

  const [createPartner] = useCreatePartnerMutation();
  const [updatePartner] = useUpdatePartnerMutation();
  const [deletePartnerByEvent] = useDeletePartnerByEventMutation();
  const [uploadPartnerCoverPhoto] = useUploadPartnerCoverPhotoMutation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<IPartnersForm>({
    resolver: yupResolver<IPartnersForm>(editEventPartnersSchema),
    mode: 'onChange',
    defaultValues: {
      partners: [],
    },
  });

  const {
    fields: partners,
    append: handleAppendPartnerByForm,
    remove: handleRemovePartnerByForm,
  } = useFieldArray({ control, name: 'partners' });

  const handleAppendEmptyPartner = () => {
    const emptyPartner = { name: '', cover: null, link: '' };
    handleAppendPartnerByForm(emptyPartner);
  };

  const handleCancel = () => {
    navigate(-1);
    reset();
  };

  const handleSave = handleSubmit((values) => {
    const partnersPromises = values.partners.map(async (partner) => {
      const data = {
        orgId: organization?.id as number,
        name: partner.name,
        link: partner.link,
      };

      const res = partner.partnerId
        ? await updatePartner({ ...data, partnerId: partner.partnerId }).unwrap()
        : await createPartner({ ...data, eventId: +(eventId as string) }).unwrap();

      if (partner.cover && typeof partner.cover === 'object' && res.success) {
        await uploadPartnerCoverPhoto({
          orgId: organization?.id as number,
          partnerId: res.data.id,
          file: partner.cover.file,
        });
      }

      return res;
    });

    const partnersIdsToDelete = eventPartners
      .filter((partner) => !values.partners.some((item) => item.partnerId === partner.partnerId))
      .map((partner) => partner.partnerId);

    const partnersDeletePromise = partnersIdsToDelete.length ? deletePartnerByEvent({
      orgId: organization?.id as number,
      eventId: +(eventId as string),
      partnersIds: partnersIdsToDelete,
    }) : undefined;

    const requestPromises = [
      ...partnersPromises,
      partnersDeletePromise,
    ].filter(Boolean);

    Promise.all(requestPromises).then((res) => {
      let isSuccess = true;

      res.forEach((item) => {
        if (item && 'error' in item) {
          const errorMessage = getErrorMessage(item.error as SerializedError);

          toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT });
          isSuccess = false;
        }
      });

      if (isSuccess) {
        reset();
        navigate(-1);
        toast.success(t('events.changesSaved'), { position: toast.POSITION.TOP_RIGHT });
      }
    });
  });

  useEffect(() => {
    reset({ partners: eventPartners });
  }, [reset, eventPartners]);

  return (
    <SingleEventPartnersView
      control={control}
      partners={partners}
      onCancel={handleCancel}
      onSave={handleSave}
      onAppendEmptyPartner={handleAppendEmptyPartner}
      onRemovePartner={handleRemovePartnerByForm}
      isSubmitting={isSubmitting}
    />
  );
}

export default SingleEventPartners;
