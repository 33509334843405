import IconButton from '@mui/material/IconButton';
import ActionDelete from '@mui/icons-material/Delete';
import Description from '@mui/icons-material/Description';

import PreviewShell from './PreviewShell';

function FilePreview({ onRemove }: { onRemove: () => void }) {
  return (
    <PreviewShell>
      <Description sx={{ height: 92, width: 92 }} />
      <IconButton
        onClick={onRemove}
        size="large"
        sx={{ position: 'absolute', top: 0, right: 0 }}
      >
        <ActionDelete />
      </IconButton>
    </PreviewShell>
  );
}

export default FilePreview;
