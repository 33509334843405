import { useNavigate, useOutletContext } from 'react-router-dom';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useTranslation } from 'react-i18next';

import { useGetDivisionsGameSetupQuery } from '../store/slices/divisions/apis/divisionsApi';
import { divisionGameSettingFormSchema } from '../utils/validators';
import {
  useSetupGamesForPPScheduleMutation,
  useCreatePoolPlaysDivisionRulesMutation,
  useGetPPTimeFramesRangeQuery,
  useLazyGetGame7x7SettingsByOrgQuery,
  useLazyGetPoolPlaysDivisionRulesQuery,
  useSetupAllPPDivisionsMutation,
  useUpdatePoolPlaysDivisionRulesMutation,
  useSwapPPPoolTeamsMutation,
  useCheckValidityPPScheduleQuery,
} from '../store/slices/poolPlays/apis/poolPlaysApi';
import { getErrorMessage, handlePromiseResWithErrors, logisticsAndGameSetupCheckValidity } from '../utils/helpers';
import {
  createPPPools, getPoolsForCreate, getPoolsForSwap, parsePPGamesPools,
} from '../utils/poolPlays';
import { GameSettingsDialogTypes, GameSetupModals } from '../pages/LogisticsAndGameSetup/interfaces/ILogisticsAndGameSetupView';
import { DEFAULT_MAX_PER_PAGE, GamesTypes } from '../constants/general';
import AppRoutes from '../constants/AppRoutes';
import type ICreateEventContext from '../layout/CreateEventLayout/interfaces/ICreateEventContext';
import type { TDivisionGameSettingFormSchema } from '../utils/validators';

const usePoolPlayGameSetupForm = (skip: boolean) => {
  const { eventId, orgId } = useOutletContext<ICreateEventContext>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [modalValue, setModalValue] = useState<GameSetupModals | null>(null);
  const [tabValue, setTabValue] = useState<number>(0);

  const { data: divisionsGameSetupRes } = useGetDivisionsGameSetupQuery(skip
    ? skipToken
    : {
      orgId,
      eventId: +eventId,
      perPage: DEFAULT_MAX_PER_PAGE,
    });

  const { data: timeFramesRange } = useGetPPTimeFramesRangeQuery(skip
    ? skipToken
    : {
      orgId,
      eventId: +eventId,
    });

  const [getPoolPlaysDivisionRules, {
    data: divisionRules,
    isLoading: isDivisionRulesLoading,
    isFetching: isDivisionRulesFetching,
  }] = useLazyGetPoolPlaysDivisionRulesQuery();

  const [getGame7x7Settings, {
    data: game7x7Settings,
    isLoading: isGame7x7SettingsLoading,
  }] = useLazyGetGame7x7SettingsByOrgQuery();

  const {
    data: ppCheckValidity,
    isLoading: isCheckValidityLoading,
    refetch: refetchValidityPP,
  } = useCheckValidityPPScheduleQuery({ orgId, eventId: +eventId, isInvalidate: true });

  const [createPoolPlaysDivisionRules] = useCreatePoolPlaysDivisionRulesMutation();
  const [updatePoolPlaysDivisionRules,
    { isError: isUpdatePoolPlaysDivisionError },
  ] = useUpdatePoolPlaysDivisionRulesMutation();
  const [swapPPPoolTeams, { isError: isSwapPPPollError }] = useSwapPPPoolTeamsMutation();
  const [setupAllPPDivisions] = useSetupAllPPDivisionsMutation();
  const [setupGamesForPPSchedule, { isLoading: isSetupGamesSubmitting }] = useSetupGamesForPPScheduleMutation();

  const isError = useMemo(
    () => isUpdatePoolPlaysDivisionError || isSwapPPPollError,
    [isUpdatePoolPlaysDivisionError, isSwapPPPollError],
  );

  const divisionsGameSetup = useMemo(() => {
    if (divisionsGameSetupRes?.data) {
      return divisionsGameSetupRes.data.map((item) => ({
        ...item,
        error: ppCheckValidity?.data.existTeamsNotInSchedule.includes(item.id),
      }));
    }

    return [];
  }, [divisionsGameSetupRes, ppCheckValidity]);

  const {
    control: gameSettingsControl,
    reset,
    handleSubmit: mainHandleSubmit,
    setValue,
    formState: {
      isSubmitting: isGameSettingSubmitting, isDirty, dirtyFields,
    },
  } = useForm<TDivisionGameSettingFormSchema>({
    resolver: yupResolver(divisionGameSettingFormSchema),
    mode: 'onBlur',
    defaultValues: {
      type: GameSettingsDialogTypes.setup,
      divisionId: undefined,
      game7x7Setting: undefined,
      gamesPerTeam: undefined,
      pools: undefined,
      preferredPlayingFields: [],
    },
  });

  const handleCreateGameSetting = mainHandleSubmit(async (values) => {
    if (!isDirty) {
      setModalValue(null);
      reset();
      return;
    }

    const baseBody = {
      orgId,
      eventId: +eventId,
      divisionId: values.divisionId,
    };
    const body = {
      ...baseBody,
      game7x7SettingsId: values.game7x7Setting.id,
      gamesPerTeam: values.gamesPerTeam.gamesPerTeam,
      preferredPlayingFieldsIds: values.preferredPlayingFields,
      pools: getPoolsForCreate(values.pools),
    };

    if (values.type === GameSettingsDialogTypes.setup) {
      await createPoolPlaysDivisionRules(body)
        .unwrap()
        .then((res) => {
          if (res.included.isFirstDivisionSetup) {
            setModalValue(GameSetupModals.ApplySettings);
          } else {
            reset();
            setModalValue(null);
          }
        })
        .catch((error) => toast.error(getErrorMessage(error)));
    } else if (values.type === GameSettingsDialogTypes.edit) {
      const dirtyFieldsLength = Object.keys(dirtyFields || {}).length;
      const promises = [];

      if (dirtyFields?.game7x7Setting && dirtyFieldsLength === 1) {
        promises.push(
          updatePoolPlaysDivisionRules(body).unwrap(),
        );
      } else if (dirtyFields?.pools && dirtyFieldsLength === 1) {
        promises.push(
          swapPPPoolTeams({ ...baseBody, pools: getPoolsForSwap(values.pools) }).unwrap(),
        );
      } else {
        promises.push(
          updatePoolPlaysDivisionRules(body).unwrap(),
        );
      }

      await Promise.all(promises).then((res) => {
        handlePromiseResWithErrors(
          res,
          (message) => toast.error(message),
          () => {
            setModalValue(null);
            reset();
          },
        );
      }).catch((error) => {
        toast.error(getErrorMessage(error), { position: toast.POSITION.TOP_RIGHT });
      });
    }
  }, async (errors) => {
    if (errors?.gamesPerTeam) {
      toast.error(t('schedule.updateField'));
    }
  });

  const handleSetupAll = mainHandleSubmit(async (values) => {
    const baseBody = {
      orgId,
      eventId: +eventId,
      divisionId: values.divisionId,
    };

    await setupAllPPDivisions(baseBody)
      .unwrap()
      .then(() => {
        setModalValue(null);
        reset();
      })
      .catch((error) => {
        toast.error(getErrorMessage(error), { position: toast.POSITION.TOP_RIGHT });
      });
  });

  const handleSetupClick = useCallback((divisionId: number, dialogType: GameSettingsDialogTypes) => {
    setValue('type', dialogType);
    setValue('divisionId', divisionId);
    getPoolPlaysDivisionRules({
      orgId,
      eventId: +eventId,
      divisionId,
    });
    getGame7x7Settings({ orgId });
    setModalValue(GameSetupModals.PoolPlayGameSettings);
  }, [orgId, eventId, getPoolPlaysDivisionRules, getGame7x7Settings, setValue]);

  const handleSetupGamesSchedule = async () => {
    const body = { orgId, eventId: +eventId };

    const checkValidity = await refetchValidityPP().unwrap()
      .then((res) => res.data)
      .catch((error) => {
        toast.error(getErrorMessage(error));
      });

    if (checkValidity) {
      logisticsAndGameSetupCheckValidity(checkValidity, t);
    }

    setupGamesForPPSchedule(body).unwrap()
      .then(() => {
        navigate(AppRoutes.singleEventPoolPlaySchedule.replace(':eventId', eventId));
      })
      .catch((error) => toast.error(getErrorMessage(error)));
  };

  const handleCloseModal = () => {
    setModalValue(null);
    reset();
  };

  const handleTabChange = (event: React.SyntheticEvent, value: number) => {
    setTabValue(value);
  };

  const handleBackClick = () => {
    navigate(AppRoutes.singleEventPoolPlayTimeFrame.replace(':eventId', eventId));
  };

  useEffect(() => {
    if (divisionRules?.data && !skip && !isDivisionRulesFetching) {
      const {
        gamesPools,
        pPlaysDivRules,
        teams,
        distributionTeamsToPools,
        poolNextFreeName,
      } = divisionRules.data;

      const gameSetting = game7x7Settings?.data
        .find(({ gameType }) => gameType === GamesTypes.POOL_PLAY);
      const poolsData = pPlaysDivRules
        ? [
          ...distributionTeamsToPools.withoutGhost,
          ...distributionTeamsToPools.withGhost,
        ].find(({ gamesPerTeam }) => gamesPerTeam === pPlaysDivRules.gamesPerTeam)!
        : distributionTeamsToPools.withoutGhost[0];
      const preferredPlayingFields = pPlaysDivRules?.preferredPlayingFields?.map(({ id }) => id) || [];

      const generatedPools = gamesPools
        ? parsePPGamesPools(teams, gamesPools)
        : createPPPools(teams, poolsData.teamsInPool, poolNextFreeName);

      setValue('pools', generatedPools);
      setValue('gamesPerTeam', poolsData);
      setValue('preferredPlayingFields', preferredPlayingFields);

      if (pPlaysDivRules?.game7x7Settings) {
        setValue('game7x7Setting', pPlaysDivRules?.game7x7Settings);
      } else if (gameSetting) {
        setValue('game7x7Setting', gameSetting);
      }
    }
  }, [isDivisionRulesFetching, divisionRules, game7x7Settings, setValue, skip, isError]);

  return {
    modalValue,
    tabValue,
    divisionsGameSetup,
    timeFramesRange,
    gameSettingsControl,
    divisionRules,
    game7x7Settings,
    handleSetupGamesSchedule,
    handleCreateGameSetting,
    handleSetupAll,
    handleSetupClick,
    handleCloseModal,
    handleTabChange,
    handleBackClick,
    isDivisionRulesLoading,
    isGame7x7SettingsLoading,
    isSetupGamesSubmitting,
    isCheckValidityLoading,
    isGameSettingSubmitting,
  };
};

export default usePoolPlayGameSetupForm;
