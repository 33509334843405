import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../../types/TStore';
import type ITeamsSlice from './interfaces/ITeamsSlice';

const selectTeams = (state: RootState): ITeamsSlice => state.teams;

const selectTeamsPageFilters = createSelector(selectTeams, (teams) => ({
  search: teams.search,
  order: teams.order,
  orderBy: teams.orderBy,
  clubId: teams.clubId,
  coachId: teams.coachId,
  eventDivisionId: teams.eventDivisionId,
}));

export default selectTeamsPageFilters;
