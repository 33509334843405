import { makeStyles } from 'tss-react/mui';

export type TNestedSelectors =
  | 'icon'
  | 'text';

const useStyles = makeStyles<void, TNestedSelectors>()(
  (theme, _params, classes) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: 11,
      color: theme.palette.text.primary,
    },
    icon: {
      width: 24,
      height: 24,
      margin: 0,
    },
    text: {},
    menuHead: {
      display: 'flex',
      gap: theme.spacing(1),
      width: '100%',
      padding: '4px 9px 4px 4px',
      borderRadius: 8,
      '&:hover': {
        backgroundColor: '#1F1F1F',
      },
      '&.active': {
        backgroundColor: '#1F1F1F',
      },
      [`& .${classes.icon}`]: {
        display: 'block',
        fontSize: 24,
        color: '#415057',
      },
      [`& .${classes.text}`]: {
        display: 'block',
        width: 111,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontFamily: theme.typography.fontFamily,
        fontSize: 14,
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.text.primary,
      },
    },
  }),
);

export default useStyles;
