import SvgIcon from '@mui/material/SvgIcon';

export default function HalfTimerIcon() {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.45 5.97L19.03 7.39C20.26 8.93 21 10.88 21 13C21 17.97 17 22 12 22C9.61305 22 7.32387 21.0518 5.63604 19.364C3.94821 17.6761 3 15.3869 3 13C3 10.6131 3.94821 8.32387 5.63604 6.63604C7.32387 4.94821 9.61305 4 12 4C14.12 4 16.07 4.74 17.62 6L19.04 4.56C19.55 5 20 5.46 20.45 5.97ZM12 6V20C13.8565 20 15.637 19.2625 16.9497 17.9497C18.2625 16.637 19 14.8565 19 13C19 11.1435 18.2625 9.36301 16.9497 8.05025C15.637 6.7375 13.8565 6 12 6ZM9 1H15V3H9V1Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
