import type IEventPlayingField from '../../../store/slices/poolPlays/interfaces/IEventPlayingField';

export enum PlayingFieldsTableStatuses {
  adding = 'adding',
  editing = 'editing',
}

export default interface IPlayingFieldsTable {
  type: string
  playingFields: IEventPlayingField[]
  fullInfoId?: number
  checkedFields?: number[]
  handleCheckField?: (id: number) => void
}
