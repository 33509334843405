const settingsMenu = [
  {
    key: 'editProfile',
    name: 'Edit Profile',
    icon: 'edit_outlined',
    link: '/settings/edit-profile',
    show: false,
  },
  {
    key: 'members',
    name: 'Members',
    icon: 'account_circle_outlined',
    link: '/settings/members',
    show: false,
  },
  {
    key: 'notifications',
    name: 'Notifications',
    icon: 'notifications_outlined',
    link: '/settings/notifications',
    show: false,
  },
  {
    key: 'qrCode',
    name: 'QR-Code',
    icon: 'qr_code',
    link: '/settings/qr-code',
    show: true,
  },
  {
    key: 'paymentOptions',
    name: 'Payment options',
    icon: 'payment_outlined',
    link: '/settings/payment-options',
    show: true,
  },
];

export default settingsMenu;
