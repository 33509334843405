import Stack from '@mui/material/Stack';

import ScheduleSelect from '../../../components/ScheduleSelect/ScheduleSelect';
import type { IScheduleSelectItem } from '../../../components/ScheduleSelect/interfaces/IScheduleSelect';
import type { IStandingsTableFilters } from '../interfaces/IStandingsTableFilters';

function StandingsTableFilters({
  divisionGamesPoolTeamsSelect,
  divisionGamesPoolSelect,
  setSelectedPoolNames,
  setSelectedTeams,
  selectedTeams,
  selectedPoolNames,
} : IStandingsTableFilters) {
  const handlePoolChange = (value: IScheduleSelectItem[]) => {
    setSelectedPoolNames(value);
  };

  const handleTeamChange = (value: IScheduleSelectItem[]) => {
    setSelectedTeams(value);
  };

  return (
    <Stack direction="row">
      <ScheduleSelect
        id="teams"
        value={selectedTeams}
        items={divisionGamesPoolTeamsSelect.map(({ id, name }) => ({ value: +id, name }))}
        renderValue={(selected) => `Team ${selected.map(({ name }) => name).join(', ')}`}
        onChange={handleTeamChange}
        onClear={() => setSelectedTeams([])}
      />

      <ScheduleSelect
        id="pools"
        value={selectedPoolNames}
        items={divisionGamesPoolSelect.map(({ id, name }) => ({ value: +id, name }))}
        renderValue={(selected) => `Pool ${selected.map(({ name }) => name).join(', ')}`}
        onChange={handlePoolChange}
        onClear={() => setSelectedPoolNames([])}
      />
    </Stack>
  );
}

export default StandingsTableFilters;
