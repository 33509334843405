import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../../types/TStore';
import type ICreateEventSlice from './interfaces/ICreateEventSlice';

const selectCreateEvent = (state: RootState): ICreateEventSlice => state.createEvent;

export const selectCompletedSteps = createSelector(selectCreateEvent, (createEvent) => createEvent.completedSteps);

export const selectLeaveModal = createSelector(selectCreateEvent, (createEvent) => createEvent.leaveModal);
