import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import EditOutlined from '@mui/icons-material/EditOutlined';
import Typography from '@mui/material/Typography';

import PaperBlock from '../../../components/PaperBlock/PaperBlock';
import { createUserSocialLink, getRelativePath } from '../../../utils/helpers';
import AppRoutes from '../../../constants/AppRoutes';
import type { ISocialsWidget } from '../interfaces/ISocialsWidget';
import type { TSocialName } from '../../../constants/general';

function SocialsWidget({ socials }: ISocialsWidget): React.ReactElement {
  const { t } = useTranslation();
  const { eventId } = useParams();

  return (
    <PaperBlock
      title={t('events.socialNetworks')}
      endAdornment={(
        <Button
          startIcon={<EditOutlined />}
          component={Link}
          to={`/events/${eventId}${getRelativePath(AppRoutes.singleEventSocial, 'route')}`}
          size="small"
        >
          {t('common.edit')}
        </Button>
      )}
      whiteBg
      noMargin
    >
      <List sx={{ mt: -2 }}>
        {socials.map((item) => (
          <ListItem key={`SocialWidgetList_${item.id}`} sx={{ px: 0, py: 1.5 }}>
            <ListItemAvatar>
              <Avatar sx={{ color: 'white' }}>
                {item.icon}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={item.name}
              secondary={(
                <Typography
                  color="white"
                  fontSize={14}
                  component="a"
                  href={createUserSocialLink(item.name as TSocialName, item.value)}
                  target="_blank"
                  sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
                >
                  {item.value ? `${item.value}` : '-'}
                </Typography>
              )}
              sx={{ my: 0 }}
            />
          </ListItem>
        ))}
      </List>
    </PaperBlock>
  );
}

export default SocialsWidget;
