import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FilterList from '@mui/icons-material/FilterList';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

import useMenu from '../../hooks/useMenu';
import type IFiltersPopup from './interfaces/IFiltersPopup';

export default function FiltersPopup({
  title,
  onApplyFilters,
  onResetFilters,
  children,
}: IFiltersPopup) {
  const { t } = useTranslation();
  const {
    anchorEl,
    menuId: popoverId,
    open,
    handleClick,
    handleClose,
  } = useMenu();

  return (
    <Box>
      <Button
        aria-labelledby={popoverId}
        variant="contained"
        sx={{
          minWidth: 56,
          height: 56,
          bgcolor: '#292929',
          borderRadius: 2,
        }}
        color="inherit"
        onClick={handleClick}
      >
        <FilterList sx={{ width: 24, height: 24 }} />
      </Button>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 68,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ mb: 2 }}
        elevation={8}
      >
        <Box sx={{ p: 3, pt: 2, width: 400 }}>
          <Stack
            sx={{ mb: 2 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="nowrap"
            gap={2}
          >
            <Typography
              fontSize={14}
              textTransform="uppercase"
              fontWeight="bold"
            >
              {title}
            </Typography>
            <IconButton onClick={handleClose} sx={{ mr: -1 }}>
              <Close sx={{ width: 24, height: 24 }} />
            </IconButton>
          </Stack>
          <Grid container spacing={2}>
            {children}
            <Grid item xs={12} justifyContent="space-between">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button variant="text" onClick={onResetFilters}>
                  {t('events.reset')}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    onApplyFilters();
                    handleClose();
                  }}
                >
                  {t('events.apply')}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </Box>
  );
}
