import { useEffect } from 'react';
import { DateTime, Settings } from 'luxon';

const defaultTZ = DateTime.local().zoneName || 'default';

const useTimezone = (timezone?: string) => {
  useEffect(() => {
    Settings.defaultZone = timezone || defaultTZ;

    return () => {
      Settings.defaultZone = defaultTZ;
    };
  }, [timezone]);
};

export default useTimezone;
