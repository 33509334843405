import { useTranslation } from 'react-i18next';

import ErrorBlock from '../../components/ErrorBlock/ErrorBlock';
import useStyles from './pageStyles-tss';

function PageNotFound() {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.appFrameOuter)}>
      <main className={classes.outerContent} id="mainContent">
        <ErrorBlock title="404" desc={t('common.pageNotFound')} />
      </main>
    </div>
  );
}

export default PageNotFound;
