import { useSearchParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';

import { useGetEventHotelsQuery, useGetEventPartnersQuery, useGetEventQuery } from '../store/slices/events/apis/eventsApi';
import { selectCreatedEvent, selectEventHotels, selectEventPartners } from '../store/slices/events/selectors';
import { useGetEventWaiversQuery } from '../store/slices/waivers/apis/waiversApi';
import { useGetEventRulesQuery } from '../store/slices/rules/apis/rulesApi';
import { selectEventWaivers } from '../store/slices/waivers/selectors';
import { selectEventRules } from '../store/slices/rules/selectors';

function usePageData(eventId: string, orgId: number, isFetch: boolean) {
  const [searchParams] = useSearchParams();

  const isEditPage = searchParams.get('editPage');
  const fetchQuery = isFetch ? { orgId, eventId: +eventId } : skipToken;

  const { event } = useGetEventQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, event: selectCreatedEvent(result.data) }),
    },
  );

  const { eventWaivers } = useGetEventWaiversQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, eventWaivers: selectEventWaivers(result.data) }),
    },
  );

  const { eventRules } = useGetEventRulesQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, eventRules: selectEventRules(result.data) }),
    },
  );

  const { eventHotels } = useGetEventHotelsQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, eventHotels: selectEventHotels(result.data) }),
    },
  );

  const { eventPartners } = useGetEventPartnersQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, eventPartners: selectEventPartners(result.data) }),
    },
  );

  return {
    isEditPage, event, eventWaivers, eventRules, eventHotels, eventPartners,
  };
}

export default usePageData;
