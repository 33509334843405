export default {
  totalRevenue: 'Total revenue',
  teamsTicketsRevenue: 'Teams tickets revenue',
  spectatorsTicketsRevenue: 'Spectators tickets revenue',
  verificationRevenue: 'Verification revenue',
  seasonRevenue: 'Season revenue',
  totalEarned: 'Total earned',
  teamTickets: 'Team tickets',
  verification: 'Verification',
  spectatorsTickets: 'Spectators tickets',
  events: 'Events',
  totalEvents: 'Total Events',
  upcoming: 'Upcoming',
  live: 'Live',
  over: 'Over',
  breakdownPerEvent: 'Breakdown per event',
  eventName: 'Event name',
  teams: 'TEAMS',
  spectators: 'SPECTATORS',
  verifications: 'VERIFICATIONS',
  total: 'Total',
  earned: 'Earned',
};
