import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import type { Control } from 'react-hook-form';

import { boolToWord } from '../../../utils/helpers';
import { ScheduleTypes } from '../../../constants/general';
import type IApplySettingsDialog from '../interfaces/IApplySettingsDialog';
import type { TDivisionGameSettingFormSchema, TDivisionPlayOffGameSettingFormSchema } from '../../../utils/validators';

function ApplySettingsDialog({
  type,
  gameSettingsControl,
  onApply,
  onClose,
  isOpen,
  isLoading,
}: IApplySettingsDialog) {
  const { t } = useTranslation();

  const ppGame7x7Setting = useWatch({ name: 'game7x7Setting', control: (gameSettingsControl as Control<TDivisionGameSettingFormSchema>) });
  const ppGamesPerTeam = useWatch({ name: 'gamesPerTeam', control: (gameSettingsControl as Control<TDivisionGameSettingFormSchema>) });

  const poGame7x7Setting = useWatch({ name: 'game7x7Setting', control: (gameSettingsControl as Control<TDivisionPlayOffGameSettingFormSchema>) });
  const poWithThirdPlace = useWatch({ name: 'withThirdPlace', control: (gameSettingsControl as Control<TDivisionPlayOffGameSettingFormSchema>) });

  const isPoolPlay = type === ScheduleTypes.POOL_PLAY;

  return (
    <Dialog
      open={isOpen}
      sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: 600 } }}
      PaperProps={{ elevation: 0 }}
    >
      <DialogTitle variant="h5" fontWeight={700}>
        {t(isPoolPlay ? 'schedule.applyPPGameSettings' : 'schedule.applyPOGameSettings')}
      </DialogTitle>
      <DialogContent>
        <Stack gap={3}>
          <Box>
            {isPoolPlay ? (
              <>
                <Typography>
                  {t('schedule.wouldApplyPPGameSettings', {
                    gameSettingName: ppGame7x7Setting.description,
                    gamesPerTeam: ppGamesPerTeam.gamesPerTeam,
                  })}
                </Typography>
                <Typography variant="body2" color="text.disabled">
                  {t('schedule.wouldApplyPPGameSettingsInfo', { gamesPerTeam: ppGamesPerTeam.gamesPerTeam })}
                </Typography>
              </>
            ) : (
              <>
                <Typography>
                  {t('schedule.wouldApplyPOGameSettings', {
                    gameSettingName: poGame7x7Setting.description,
                    bool: boolToWord(poWithThirdPlace),
                  })}
                </Typography>
                <Typography variant="body2" color="text.disabled">
                  {t('schedule.wouldApplyPOGameSettingsInfo')}
                </Typography>
              </>
            )}
          </Box>
          <Typography>
            {t('schedule.preferredFieldsNotApply')}
          </Typography>
          <Typography>
            {t('schedule.youCanEditSettings')}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ gap: 1 }}>
        <Button color="secondary" onClick={onClose}>
          {t('schedule.setupByMyself')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          onClick={onApply}
          disabled={isLoading}
        >
          {t('common.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ApplySettingsDialog;
