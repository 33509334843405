import { useCallback } from 'react';
import { DateTime } from 'luxon';
import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';

import TableSort from '../../../components/TableSort/TableSort';
import TableRowActions from '../../../components/TableRowActions/TableRowActions';
import TableRowsLoader from '../../../components/TableRowsLoader/TableRowsLoader';
import { AppThemeMode } from '../../../constants/AppThemes';
import { STATUSES_PALLET_MAP } from '../../../constants/general';
import type { IEventsTable, TEventsTableOrderBy } from '../interfaces/IEventsTable';

const useStyle = makeStyles()(
  (theme) => ({
    stripped: {
      '&:nth-of-type(even)': {
        background: theme.palette.mode === AppThemeMode.Dark
          ? alpha(theme.palette.grey[900], 0.5)
          : theme.palette.grey[50],
      },
    },
    dateCell: {
      minWidth: 180,
    },
    tableChip: {
      fontWeight: 700,
      textTransform: 'capitalize',
    },
    eventWrap: {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      '& img': {
        borderRadius: theme.rounded.small,
      },
    },
  }),
);

interface HeadCell {
  disablePadding: boolean
  id: TEventsTableOrderBy
  label: string
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    disablePadding: false,
    label: 'name',
  },
  {
    id: 'topicName',
    disablePadding: true,
    label: 'topic',
  },
  {
    id: 'startAt',
    disablePadding: true,
    label: 'date',
  },
  {
    id: 'minDivisionAge',
    disablePadding: true,
    label: 'division',
  },
  {
    id: 'totalAmount',
    disablePadding: true,
    label: 'earn',
  },
  {
    id: 'teamsCount',
    disablePadding: true,
    label: 'teams',
  },
  {
    id: 'spectatorsCount',
    disablePadding: true,
    label: 'spectators',
  },
  {
    id: 'status',
    disablePadding: true,
    label: 'status',
  },
];

function EventsTable({
  control,
  isEventsLoading,
  events,
  pagination,
  onSortChange,
  getTableActions,
  admin,
}: IEventsTable) {
  const { classes } = useStyle();
  const { t } = useTranslation();

  const getDivisionCellData = useCallback((minDev: number | null, maxDev: number | null) => {
    if (!minDev) {
      return '-';
    }

    if (minDev !== maxDev) {
      return t('events.divisionTableInfo', {
        min: minDev,
        max: maxDev,
      });
    }

    return t('events.divisionTableInfoSingle', {
      min: minDev,
    });
  }, [t]);

  return (
    <>
      <Paper sx={{ p: 3 }} elevation={0}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableSort
                    key={`EventsHeadCell_${headCell.id}`}
                    control={control}
                    property={headCell.id}
                    label={t(`events.${headCell.label}`)}
                    disablePadding={headCell.disablePadding}
                    onSortChange={onSortChange}
                  />
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {isEventsLoading ? (<TableRowsLoader rowsNum={5} columnsNum={8} />) : (
                events.map((n) => (
                  <TableRow key={n.id} className={classes.stripped}>
                    <TableCell padding="normal">
                      <Link
                        component={NavLink}
                        to={admin ? n.id.toString() : `${n.id.toString()}/details`}
                        color="inherit"
                        underline="hover"
                        fontWeight={700}
                      >
                        {n.name}
                      </Link>
                    </TableCell>
                    <TableCell>{n.topicName || '-'}</TableCell>
                    <TableCell className={classes.dateCell}>
                      {DateTime.fromISO(n.startAt, { locale: 'en-US', zone: n.timezone }).toFormat('ccc, LLL d, yyyy t')}
                      {' - '}
                      {DateTime.fromISO(n.endAt, { locale: 'en-US', zone: n.timezone }).toFormat('ccc, LLL d, yyyy t ZZZZ')}
                    </TableCell>
                    <TableCell>
                      {getDivisionCellData(n.minDivisionAge, n.maxDivisionAge)}
                    </TableCell>
                    <TableCell>
                      {Intl
                        .NumberFormat('en-US', { style: 'currency', minimumFractionDigits: 2, currency: 'USD' })
                        .format(n.totalAmount)}
                    </TableCell>
                    <TableCell>{n.teamsCount}</TableCell>
                    <TableCell>{n.spectatorsCount}</TableCell>
                    <TableCell>
                      <Chip
                        label={n.status}
                        className={classes.tableChip}
                        sx={{ minWidth: 74, bgcolor: STATUSES_PALLET_MAP[n.status] }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TableRowActions actions={getTableActions(n)} status={n.status} />
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <TablePagination
        rowsPerPageOptions={pagination.perPageOptions}
        component="div"
        count={pagination.total}
        rowsPerPage={pagination.perPage}
        page={pagination.page}
        onPageChange={pagination.handleChangePage}
        onRowsPerPageChange={pagination.handleChangePerPage}
      />
    </>
  );
}

export default EventsTable;
