import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import ActionDelete from '@mui/icons-material/Delete';
import FormHelperText from '@mui/material/FormHelperText';

import DropZone from '../DropZone/DropZone';
import { IMAGES_MIME_TYPES_ACCEPT } from '../../constants/general';
import type IHotelOrPartnerCard from './interfaces/IHotelOrPartnerCard';

function EditPartnerCard({ control, cardIndex, onRemove }: IHotelOrPartnerCard): React.ReactElement {
  const { t } = useTranslation();

  return (
    <Paper sx={{ p: 3, alignSelf: 'stretch' }}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <Typography component="h4" fontSize={16} width="100%">
          {`Partner ${cardIndex + 1}`}
        </Typography>
        <IconButton onClick={onRemove}>
          <ActionDelete />
        </IconButton>
      </Stack>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 5 }}>
        <Grid item xs={12} md={6}>
          <Controller
            name={`partners.${cardIndex}.name`}
            control={control}
            render={({
              field,
              fieldState: { error: fieldError },
            }) => (
              <TextField
                helperText={fieldError?.message}
                error={!!fieldError}
                label={t('events.name')}
                variant="outlined"
                fullWidth
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name={`partners.${cardIndex}.link`}
            control={control}
            render={({
              field,
              fieldState: { error: fieldError },
            }) => (
              <TextField
                helperText={fieldError?.message}
                error={!!fieldError}
                label={t('events.website')}
                variant="outlined"
                fullWidth
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography mb={1.5}>Cover</Typography>
          <Controller
            name={`partners.${cardIndex}.cover`}
            control={control}
            render={({
              field: { onChange, value },
              fieldState: { error: fieldError },
            }) => (
              <>
                <DropZone
                  onUploadChange={([file]) => onChange(file || null)}
                  accept={IMAGES_MIME_TYPES_ACCEPT}
                  files={value ? [value] : []}
                  maxSize={5000000}
                  filesLimit={1}
                  text={t('events.dragImageText')}
                  desc={`${t('events.dragImageSecondaryDesc')}`}
                  isMultiFiles={false}
                  showPreviews
                  error={fieldError?.message}
                />
                {fieldError?.message
                  ? <FormHelperText error>{fieldError.message}</FormHelperText>
                  : null}
              </>
            )}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default EditPartnerCard;
