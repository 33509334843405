import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';

import SingleEventDatesView from './SingleEventDatesView';
import { editEventDatesSchema } from '../../utils/validators';
import { useAppDispatch, useAppSelector } from '../../store/hooks/useApp';
import { selectUserOrg } from '../../store/slices/user/selectors';
import { selectEventSpectators, selectIsDatesDialogOpen } from '../../store/slices/spectators/selectors';
import { useGetEventQuery, useGetEventTournamentDaysQuery, useUpdateEventMutation } from '../../store/slices/events/apis/eventsApi';
import { selectEventDates, selectEventDays } from '../../store/slices/events/selectors';
import { toggleReplaceDatesDialog } from '../../store/slices/spectators/slice';
import { useCreateTeamTicketRuleMutation, useUpdateTeamTicketRuleMutation } from '../../store/slices/teamTicketRules/apis/teamTicketRulesApi';
import { useUpdateDiscountMutation } from '../../store/slices/divisions/apis/divisionsApi';
import { getErrorMessage } from '../../utils/helpers';
import { getInterval } from '../../utils/dates';
import { DEFAULT_DATE_FORMAT } from '../../constants/general';
import {
  useCreateSpectatorDayRulesMutation,
  useDeleteSpectatorDayRuleMutation,
  useGetSpectatorRuleQuery,
  useUpdateSpectatorDayRulesMutation,
  useUpdateSpectatorRuleMutation,
  useUpdateSpectatorTypeRuleDiscountMutation,
} from '../../store/slices/spectators/apis/spectatorsApi';
import type { ISingleEventDatesFields } from './interfaces/ISingleEventDatesForm';
import type { IReplaceDates } from '../../components/SpectatorsTicketsForm/interfaces/IReplaceDatesDialog';

export default function SingleEventDates() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { eventId } = useParams();
  const organization = useAppSelector(selectUserOrg);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isDatesDialogOpen = useAppSelector(selectIsDatesDialogOpen);

  const fetchQuery = organization?.id && eventId ? {
    orgId: organization.id,
    eventId: +eventId,
  } : skipToken;

  const { event } = useGetEventQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, event: selectEventDates(result.data) }),
    },
  );
  const { eventDays } = useGetEventTournamentDaysQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, eventDays: selectEventDays(result.data) }),
    },
  );
  const { eventSpectatorRules } = useGetSpectatorRuleQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, eventSpectatorRules: selectEventSpectators(result.data) }),
    },
  );

  const [updateEvent] = useUpdateEventMutation();
  const [updateTeamTicketRule] = useUpdateTeamTicketRuleMutation();
  const [updateDiscount] = useUpdateDiscountMutation();
  const [updateSpectatorRule] = useUpdateSpectatorRuleMutation();
  const [updateSpectatorTypeRuleDiscount] = useUpdateSpectatorTypeRuleDiscountMutation();
  const [createTeamTicketRule] = useCreateTeamTicketRuleMutation();

  const [createSpectatorDayRules] = useCreateSpectatorDayRulesMutation();
  const [updateSpectatorDayRules] = useUpdateSpectatorDayRulesMutation();
  const [deleteSpectatorDayRules] = useDeleteSpectatorDayRuleMutation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
    watch,
    trigger,
    setValue,
    getValues,
    getFieldState,
  } = useForm<ISingleEventDatesFields>({
    resolver: yupResolver<ISingleEventDatesFields>(editEventDatesSchema),
    mode: 'onBlur',
    defaultValues: {
      isPublished: false,
      startEventDate: null,
      endEventDate: null,
      endEventRegDate: null,
      ageCutoffDate: null,
      discounts: [],
      tournamentDays: event.startAt && event.endAt
        ? getInterval(
          event.startAt.startOf('day'),
          event.endAt.endOf('day'),
        )
        : [],
      startSellingDate: null,
      endSellingDate: null,
      spectatorsOneDay: [],
      spectatorsWholeDays: [],
      eventDaysError: false,
    },
  });

  const startEventDate = watch('startEventDate') as DateTime;
  const endEventDate = watch('endEventDate') as DateTime;

  useEffect(() => {
    const isTouched = getFieldState('startEventDate').isTouched
      || getFieldState('endEventDate').isTouched;

    if (isTouched) {
      setValue('eventDaysError', true);
      trigger();
    }
  }, [trigger, startEventDate, endEventDate, setValue, getFieldState]);

  useEffect(() => {
    const defaultEndEventRegDate = !event.endEventRegDate
      ? event?.startAt?.setZone(event.timezone, {
        keepLocalTime: !eventId,
      })?.startOf('day')
      : event.endEventRegDate.setZone(event.timezone, {
        keepLocalTime: !eventId,
      });

    reset({
      isPublished: event.published,
      startEventDate: event?.startAt?.setZone(event.timezone, {
        keepLocalTime: !eventId,
      }),
      endEventDate: event?.endAt?.setZone(event.timezone, {
        keepLocalTime: !eventId,
      }),
      endEventRegDate: defaultEndEventRegDate,
      ageCutoffDate: event?.ageCutoffDate?.setZone(event.timezone, {
        keepLocalTime: !eventId,
      })
        ? event.ageCutoffDate
        : DateTime.now().set({
          year: event.startAt?.year, month: 1, day: 1, hour: 23, minute: 59, second: 0, millisecond: 0,
        }),
      discounts: eventDays.eventDiscounts,
      tournamentDays: eventSpectatorRules?.tournamentDays,
      startSellingDate: event?.sellStartAt?.setZone(event.timezone, {
        keepLocalTime: !eventId,
      })?.startOf('day') || DateTime.now().startOf('day'),
      endSellingDate: event?.sellEndAt?.setZone(event.timezone, {
        keepLocalTime: !eventId,
      })?.set({
        hour: 23, minute: 59, second: 0, millisecond: 0,
      }) || event.endAt?.setZone(event.timezone, {
        keepLocalTime: !eventId,
      }),
      spectatorsOneDay: eventDays.oneDayTickets,
      spectatorsWholeDays: eventDays.wholeEventTickets,
    });
  }, [event, eventDays, eventId, eventSpectatorRules, reset]);

  const handleCreateSubmit = handleSubmit(async (values) => {
    if (organization?.id && eventId) {
      const baseRequestData = {
        orgId: organization?.id,
        eventId: +eventId,
      };

      const eventDatesPromise = updateEvent({
        ...baseRequestData,
        startAt: DateTime.fromJSDate(values.startEventDate as Date).setZone(event.timezone, {
          keepLocalTime: !eventId,
        }).toISO() as string,
        endAt: DateTime.fromJSDate(values.endEventDate as Date).setZone(event.timezone, {
          keepLocalTime: !eventId,
        }).toISO() as string,
      });

      const teamTicketRuleData = {
        ...baseRequestData,
        ageValidateFrom: DateTime.fromJSDate(values.ageCutoffDate as Date).setZone(event.timezone, {
          keepLocalTime: !event.endEventRegDate,
        }).endOf('day').toISO() as string,
        divisionRegistrationEndAt: DateTime.fromJSDate(values.endEventRegDate as Date).setZone(event.timezone, {
          keepLocalTime: !event.endEventRegDate,
        }).toISO() as string,
      };

      const teamTicketRulePromise = event.endEventRegDate
        ? updateTeamTicketRule(teamTicketRuleData)
        : createTeamTicketRule(teamTicketRuleData);

      const divisionsPromises = values.discounts.map((discount) => updateDiscount({
        ...baseRequestData,
        divisionId: discount.divisionId,
        discount: {
          price: discount.price,
          endAt: DateTime.fromJSDate(discount.endAt as Date).setZone(event.timezone, {
            keepLocalTime: !eventId,
          }).toISO() as string,
        },
      }));

      const existingDateSet = new Set(values.tournamentDays.map((date) => DateTime.fromFormat(date, 'dd LLL yyyy').toISODate()));
      const apiDateSet = new Set(eventSpectatorRules?.oneDayTicketRules.map((apiDate) => DateTime.fromFormat(apiDate.accessToDay, 'MM/dd/yyyy').toISODate()));

      const idsDateToRemove = eventSpectatorRules?.oneDayTicketRules
        .filter((apiDate) => !existingDateSet.has(DateTime.fromFormat(apiDate.accessToDay, 'MM/dd/yyyy').toISODate()))
        .map((apiDate) => apiDate.id) || [];

      const newDates = values.tournamentDays
        .filter((date) => !apiDateSet.has(DateTime.fromFormat(date, 'dd LLL yyyy').toISODate()))
        .map((date) => DateTime.fromFormat(date, 'dd LLL yyyy').toFormat('MM/dd/yyyy'));

      const newDaysRulesPromises = newDates.map((date) => createSpectatorDayRules({
        ...baseRequestData,
        accessToDay: date,
      }));

      const removeDaysRulesPromises = idsDateToRemove.map((item) => deleteSpectatorDayRules({
        ...baseRequestData,
        dayRuleId: item,
      }));

      const spectatorPromise = updateSpectatorRule({
        ...baseRequestData,
        sellStartAt: DateTime.fromJSDate(values.startSellingDate as Date)
          .setZone(event.timezone, {
            keepLocalTime: !eventId,
          }).startOf('day').toISO() as string,
        sellEndAt: DateTime.fromJSDate(values.endSellingDate as Date).setZone(event.timezone, {
          keepLocalTime: !eventId,
        })
          .set({
            hour: 23, minute: 59, second: 0, millisecond: 0,
          }).toISO() as string,
      });

      const spectatorDiscountsPromise = [...values.spectatorsOneDay, ...values.spectatorsWholeDays]
        .map((discount) => updateSpectatorTypeRuleDiscount({
          ...baseRequestData,
          ruleId: discount.ruleId,
          price: discount.price,
          endAt: DateTime.fromJSDate(discount.endAt as Date).setZone(event.timezone, {
            keepLocalTime: !eventId,
          }).toISO() as string,
        }));

      Promise.all([
        eventDatesPromise,
        teamTicketRulePromise,
        spectatorPromise,
        ...divisionsPromises,
        ...spectatorDiscountsPromise,
        ...newDaysRulesPromises,
        ...removeDaysRulesPromises,
      ]).then((res) => {
        const errors = res
          .map((item) => ('error' in item ? getErrorMessage(item.error) : null))
          .filter(Boolean);

        if (errors.length > 0) {
          errors.forEach((errorMessage) => {
            toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT });
          });
        } else {
          navigate(`/events/${eventId}/details`);
          reset();
          toast.success(t('events.changesSaved'), { position: toast.POSITION.TOP_RIGHT });
        }
      });
    }
  });

  const handleDatesSave = ({
    update, remove, add,
  }: IReplaceDates) => {
    setIsLoading(true);
    const {
      startEventDate: startAt,
      endEventDate: endAt,
    } = getValues();

    const eventDatesPromise = updateEvent({
      orgId: organization?.id as number,
      eventId: +(eventId as string),
      startAt: (startAt as DateTime).setZone(event.timezone, {
        keepLocalTime: !eventId,
      }).toISO() as string,
      endAt: (endAt as DateTime).setZone(event.timezone, {
        keepLocalTime: !eventId,
      }).toISO() as string,
    });

    const updatePromises = update.length ? [
      updateSpectatorDayRules({
        orgId: organization?.id as number,
        eventId: +(eventId as string),
        spOneDayRules: update.map((item) => ({
          id: item.dateId,
          accessToDay: DateTime.fromJSDate(item.accessToDay as Date).toFormat('MM/dd/yyyy'),
        })),
      }),
    ] : [];

    const removePromises = remove.map((item) => deleteSpectatorDayRules({
      orgId: organization?.id as number,
      eventId: +(eventId as string),
      dayRuleId: item.dateId,
    }));

    const addPromises = add.map(({ accessToDay }) => createSpectatorDayRules({
      orgId: organization?.id as number,
      eventId: +(eventId as string),
      accessToDay: DateTime.fromJSDate(accessToDay as Date).toFormat('MM/dd/yyyy'),
    }));

    Promise.all([eventDatesPromise, ...updatePromises, ...addPromises, ...removePromises]).then((res) => {
      const errors = res
        .map((item) => ('error' in item ? getErrorMessage(item.error) : null))
        .filter(Boolean);

      if (errors.length > 0) {
        errors.forEach((errorMessage) => {
          toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT });
        });
      } else {
        toast.success(t('events.changesSaved'), { position: toast.POSITION.TOP_RIGHT });
        setValue('eventDaysError', false);
        dispatch(toggleReplaceDatesDialog(false));
      }
    }).finally(() => {
      trigger();
      setIsLoading(false);
    });
  };

  const handleTournamentDaysClick = () => {
    if (event?.published) {
      dispatch(toggleReplaceDatesDialog(true));
    }
  };

  const handleDatesDialogClose = () => {
    dispatch(toggleReplaceDatesDialog(false));
  };

  const handleCancel = () => {
    navigate(-1);
    reset();
  };

  return (
    <SingleEventDatesView
      control={control}
      eventName={event.name}
      eventStartDate={startEventDate?.toFormat(DEFAULT_DATE_FORMAT) || '-'}
      eventEndDate={endEventDate?.toFormat(DEFAULT_DATE_FORMAT) || '-'}
      discountsList={eventDays.eventDiscounts}
      tournamentDates={eventSpectatorRules?.oneDayTicketRules || []}
      tournamentDaysInterval={startEventDate && endEventDate
        ? getInterval(
          startEventDate.startOf('day'),
          endEventDate.endOf('day'),
        )
        : []}
      onTournamentDaysClick={handleTournamentDaysClick}
      onDatesSave={handleDatesSave}
      isDatesDialogOpen={isDatesDialogOpen}
      onDatesDialogClose={handleDatesDialogClose}
      spectatorsOneDay={eventDays.oneDayTickets}
      spectatorsWholeDays={eventDays.wholeEventTickets}
      onCreateSubmit={handleCreateSubmit}
      onCancelEdit={handleCancel}
      isSubmitting={isSubmitting}
      isLoading={isLoading}
    />
  );
}
