import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import type IPreferredPlayingFields from '../interfaces/IPreferredPlayingFields';
import PlayingFieldsTable from './PlayingFieldsTable';

function PreferredPlayingFields({
  title,
  fields,
  checkedFields,
  onChange,
  type,
  fullInfoId,
}: IPreferredPlayingFields) {
  const handlePreferredFieldChange = (id: number) => {
    let newValue = [...checkedFields];
    const isChecked = checkedFields.includes(id);

    if (isChecked) {
      newValue = newValue.filter((item) => item !== id);
    } else {
      newValue = [...newValue, id];
    }

    onChange(newValue);
  };

  return (
    <Box>
      {title ? (
        <Typography variant="h6" mb={1}>
          {title}
        </Typography>
      ) : null}

      <PlayingFieldsTable
        playingFields={fields}
        type={type}
        fullInfoId={fullInfoId}
        handleCheckField={handlePreferredFieldChange}
        checkedFields={checkedFields}
      />

    </Box>
  );
}

export default PreferredPlayingFields;
