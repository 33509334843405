import mainApi from '../../../apis/mainApi';
import {
  BASE_S3_URL,
  ORG_EVENT_TEAMS,
  ORG_EVENT_TEAMS_CLUB_FILTER,
  ORG_EVENT_TEAMS_COACH_FILTER,
} from '../../../../constants/requestUrls';
import type IBaseQueryResWithPagination from '../../../types/IBaseQueryResWithPagination';
import type ITeam from '../interfaces/ITeam';
import type IEventTeamsRequest from '../interfaces/IEventTeramsRequest';
import type IBaseQueryResponse from '../../../types/IBaseQueryResponse';
import type IClubsRequest from '../interfaces/IClubsRequest';
import type ICoachesRequest from '../interfaces/ICoachesRequest';
import type IClub from '../interfaces/IClub';
import type ICoach from '../interfaces/ICoach';

const teamsApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getEventTeams: builder.query<
    IBaseQueryResWithPagination<ITeam[]>,
    IEventTeamsRequest
    >({
      query: ({ orgId, eventId, ...rest }) => ({
        url: ORG_EVENT_TEAMS
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
        params: { ...rest },
      }),
      transformResponse: (data: IBaseQueryResWithPagination<ITeam[]>) => ({
        ...data,
        data: data.data.map((item) => ({
          ...item,
          profilePhoto: item.profilePhoto ? `${BASE_S3_URL}${item.profilePhoto}` : null,
        })),
      }),
    }),

    getClubs: builder.query<
    IBaseQueryResponse<IClub[]>,
    IClubsRequest
    >({
      query: ({ orgId, eventId, ...rest }) => ({
        url: ORG_EVENT_TEAMS_CLUB_FILTER
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
        params: { ...rest },
      }),
    }),

    getCoaches: builder.query<
    IBaseQueryResponse<ICoach[]>,
    ICoachesRequest
    >({
      query: ({ orgId, eventId, ...rest }) => ({
        url: ORG_EVENT_TEAMS_COACH_FILTER
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
        params: { ...rest },
      }),
    }),
  }),
});

export const {
  useGetEventTeamsQuery,
  useLazyGetClubsQuery,
  useLazyGetCoachesQuery,
} = teamsApi;

export default teamsApi;
