import { createSelector } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import { parseISODateTime, sumSecondsToMinutes } from '../../../utils/helpers';
import { filterGame, getGameError, getGameRoundShortName } from '../../../utils/poolPlays';
import { ScheduleGameStatus } from '../../../constants/Statuses';
import type IBaseQueryResponse from '../../types/IBaseQueryResponse';
import type IPPTimeFramesRange from './interfaces/IPPTimeFramesRange';
import type { IScheduleGame, IScheduleGamesFilters } from './interfaces/ISchedule';
import type ISchedule from './interfaces/ISchedule';
import type ICheckValidity from './interfaces/ICheckValidity';

const getGameEndAt = (game: IScheduleGame) => {
  const startAt = game.startTime ? DateTime.fromISO(game.startTime) : undefined;
  const endAt = game.finishedAt ? DateTime.fromISO(game.finishedAt) : undefined;

  if (startAt && endAt && startAt > endAt) {
    return startAt.plus({ minutes: sumSecondsToMinutes(game.durationSeconds) }).toJSDate();
  }

  if (endAt) {
    return endAt.toJSDate();
  }

  return undefined;
};

export const selectTimeFramesRange = createSelector(
  (data: IBaseQueryResponse<IPPTimeFramesRange> | undefined) => data?.data,
  (timeFramesRange) => ({
    startAt: parseISODateTime(timeFramesRange?.startAt),
    endAt: parseISODateTime(timeFramesRange?.endAt),
  }),
);

export const selectScheduleGames = createSelector(
  (data?: IBaseQueryResponse<ISchedule>) => data?.data?.games,
  (data: unknown, fromFilters?: IScheduleGamesFilters) => fromFilters,
  (data: unknown, fromFilters: unknown, validity?: ICheckValidity) => validity,
  (data?: IBaseQueryResponse<ISchedule>) => data?.data?.filters,
  (
    games,
    fromFilters,
    validity,
    filters,
  ) => games?.map((game) => ({
    id: game.id,
    title: [game.teamA?.name || 'TBD', game.teamB?.name || 'TBD'].filter(Boolean).join(' vs '),
    startAt: game.startTime ? DateTime.fromISO(game.startTime).toJSDate() : undefined,
    endAt: getGameEndAt(game),
    status: game.status,
    duration: sumSecondsToMinutes(game.durationSeconds),
    resourceId: game.tOrgPlayingFieldId,
    division: game.tOrgEventDivision.division.age,
    divisionName: game.tOrgEventDivision.division.name,
    divisionAge: game.tOrgEventDivision.division.age,
    divisionId: game.tOrgEventDivisionId,
    statisticianId: game.tOrgGamesStatisticianId,
    timeFrameId: game.tOrgEventPoolPlaysTimeFrameId || game.tOrgEventPlayOffTimeFrameId,
    teamA: game.teamA,
    teamB: game.teamB,
    isByeGame: game.isByeGame,
    pool: game.tOrgDivisionGamesPoolId,
    poolName: game.tOrgDivisionGamesPool?.name,
    hidden: filterGame(game, fromFilters) || game.status === ScheduleGameStatus.FINISHED,
    error: getGameError(game.id, validity),
    tOrgEventPlayOffRoundId: game.tOrgEventPlayOffRoundId,
    tOrgEventPoolPlaysRulesId: game.tOrgEventPoolPlaysRulesId,
    tOrgEventPlayOffRulesId: game.tOrgEventPlayOffRulesId,
    tOrgEventPlayOffTimeFrameId: game.tOrgEventPlayOffTimeFrameId,
    tOrgEventPoolPlaysTimeFrameId: game.tOrgEventPoolPlaysTimeFrameId,
    timeFrame: undefined,
    roundName: getGameRoundShortName(game, filters?.eventRounds || []),
  })),
);

export const selectScheduleFilters = createSelector(
  (data?: IBaseQueryResponse<ISchedule>) => data?.data,
  (data) => (data?.filters ? ({
    ...data?.filters,
    eventDivGamePools: data?.filters?.eventDivGamePools
      ? [...data.filters.eventDivGamePools].sort((a, b) => {
        if (a.name.length !== b.name.length) {
          return a.name.length - b.name.length;
        }
        return a.name.localeCompare(b.name);
      })
      : [],
  }) : undefined),
);
