import { useId, useState } from 'react';

function useMenu() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const menuId = useId();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    anchorEl,
    menuId,
    open: !!anchorEl,
    handleClick,
    handleClose,
  };
}

export default useMenu;
