import mainApi from '../../../apis/mainApi';
import {
  ORG_EVENT_DIVISION_GAMES_OVERALL_PLAYS,
  ORG_EVENT_DIVISION_GAMES_OVERALL_SELECTS,
  ORG_EVENT_DIVISION_GAMES_OVERALL_TEAMS_SELECTS,
  ORG_EVENT_DIVISION_GAMES_POOL_PLAYS,
} from '../../../../constants/requestUrls';
import type IBaseQueryResWithPagination from '../../../types/IBaseQueryResWithPagination';
import type IGetDivisionGamesPoolPlaysReq from '../interfaces/IGetDivisionGamesPoolPlaysReq';
import type IGetDivisionGamesOverallPlaysReq from '../interfaces/IGetDivisionGamesOverallPlaysReq';
import type { IStanding } from '../../../../pages/SingleEventStandings/interfaces/IStandingsTable';
import type { IGetDivisionGamesPoolSelectReq } from '../interfaces/IGetDivisionGamesPoolSelectReq';
import type ITeam from '../../teams/interfaces/ITeam';
import type IFilterTeam from '../../waivers/interfaces/IFilterTeam';

const divisionGamesPoolApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getDivisionGamesPoolPlays: builder.query<
    IBaseQueryResWithPagination<IStanding[]>,
    IGetDivisionGamesPoolPlaysReq>({
      query: ({
        orgId,
        eventId,
        divisionId,
        ...rest
      }) => ({
        url: ORG_EVENT_DIVISION_GAMES_POOL_PLAYS
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{divisionId}', `${divisionId}`),
        method: 'GET',
        params: { ...rest },
      }),
    }),

    getDivisionGamesOverallPlays: builder.query<
    IBaseQueryResWithPagination<IStanding[]>,
    IGetDivisionGamesOverallPlaysReq>({
      query: ({
        orgId,
        eventId,
        divisionId,
        ...rest
      }) => ({
        url: ORG_EVENT_DIVISION_GAMES_OVERALL_PLAYS
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{divisionId}', `${divisionId}`),
        method: 'GET',
        params: { ...rest },
      }),
    }),

    getDivisionGamesPoolSelects: builder.query<
    IBaseQueryResWithPagination<IFilterTeam[]>,
    IGetDivisionGamesPoolSelectReq>({
      query: ({
        orgId,
        eventId,
        ...rest
      }) => ({
        url: ORG_EVENT_DIVISION_GAMES_OVERALL_SELECTS
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
        params: { ...rest },
      }),
    }),

    getDivisionGamesPoolTeamsSelects: builder.query<
    IBaseQueryResWithPagination<ITeam[]>,
    IGetDivisionGamesPoolSelectReq>({
      query: ({
        orgId,
        eventId,
        ...rest
      }) => ({
        url: ORG_EVENT_DIVISION_GAMES_OVERALL_TEAMS_SELECTS
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
        params: { ...rest },
      }),
    }),
  }),
});

export const {
  useGetDivisionGamesPoolPlaysQuery,
  useGetDivisionGamesOverallPlaysQuery,
  useGetDivisionGamesPoolSelectsQuery,
  useGetDivisionGamesPoolTeamsSelectsQuery,
} = divisionGamesPoolApi;

export default divisionGamesPoolApi;
