import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import TableTabs from '../TableTabs/TableTabs';
import ScheduleSelect from '../ScheduleSelect/ScheduleSelect';
import TournamentBracket from '../TournamentBracket/TournamentBracket';
import type IScheduleBracket from './interfaces/IScheduleBracket';

function ScheduleBracket({
  tournamentBracketData,
  divisionsTabs,
  tabValue,
  teamName,
  statisticians,
  teams,
  statisticianName,
  timezone,
  onTabChange,
  onTeamChange,
  onTeamClear,
  onStatisticianChange,
  onStatisticianClear,
  onEditFieldsOpenChange,
  isEditing = true,
}: IScheduleBracket) {
  const { t } = useTranslation();

  return (
    <Stack gap={3}>
      <TableTabs
        tabs={divisionsTabs}
        tabValue={tabValue}
        onTabChange={onTabChange}
      />
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
        <Stack direction="row" mx={-1}>
          {teams.length ? (
            <ScheduleSelect
              id="teams"
              value={teamName}
              items={teams}
              renderValue={(selected) => `Team ${selected.map(({ name }) => name).join()}`}
              onChange={onTeamChange}
              onClear={onTeamClear}
            />
          ) : null}
          {/* TODO: hide for now, add this function for example in the future */}
          {statisticians.length === -1 ? (
            <ScheduleSelect
              id="statisticians"
              value={statisticianName}
              items={statisticians}
              renderValue={(selected) => `Statisticians ${selected.map(({ name }) => name).join()}`}
              onChange={onStatisticianChange}
              onClear={onStatisticianClear}
            />
          ) : null}
        </Stack>
        <Button
          size="large"
          variant="outlined"
          onClick={() => onEditFieldsOpenChange(true)}
        >
          {t(isEditing ? 'schedule.editFields' : 'schedule.editSchedule')}
        </Button>
      </Stack>
      <TournamentBracket data={tournamentBracketData} timezone={timezone} />
    </Stack>
  );
}

export default ScheduleBracket;
