import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import type { IRefundPolicy } from './interfaces/IRefundPolicy';

function RefundPolicySection({ control }: IRefundPolicy): React.ReactElement {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography component="h4" variant="h6" mb={3}>
        {t('events.refundPolicyTitle')}
      </Typography>
      <Controller
        name="refundPolicy"
        control={control}
        render={({
          field: { value = '', ...props },
          fieldState: { error: fieldError },
        }) => (
          <TextField
            value={value}
            helperText={fieldError?.message}
            error={!!fieldError}
            label={t('events.refundPolicyLabel')}
            variant="outlined"
            fullWidth
            multiline
            {...props}
          />
        )}
      />
    </Box>
  );
}

export default RefundPolicySection;
