import mainApi from '../../../apis/mainApi';
import { ANALYTICS_TEAM_PER_DIVISION_TAG } from '../../../tags/analytics';
import {
  ORG_EVENT_ANALYTICS_MEMBERS_COUNT,
  ORG_EVENT_ANALYTICS_REFUNDS,
  ORG_EVENT_ANALYTICS_REVENUE,
  ORG_EVENT_ANALYTICS_SPECTATORS_BY_PASS,
  ORG_EVENT_ANALYTICS_SPECTATORS_BY_TYPE,
  ORG_EVENT_ANALYTICS_SPECTATORS_EARNED,
  ORG_EVENT_ANALYTICS_TEAMS_EARNED_BY_DIVISION,
  ORG_EVENT_ANALYTICS_TEAM_PER_DIVISION,
  ORG_EVENT_ANALYTICS_TOTAL_EARNED,
} from '../../../../constants/requestUrls';
import { CENTS_PER_DOLLAR } from '../../../../constants/general';
import { transformDate } from '../../../../utils/helpers';
import type IBaseQueryResponse from '../../../types/IBaseQueryResponse';
import type { IBaseQueryRequest } from '../../../types/IBaseQueryRequest';
import type { IEventEarned } from '../interfaces/IEventEarned';
import type { IEventRevenue } from '../interfaces/IEventRevenue';
import type { IEventMembersCount } from '../interfaces/IEventMembersCount';
import type { ITeamsPerDevision } from '../interfaces/ITeamsPerDevision';
import type { ITeamsEarned } from '../interfaces/ITeamsEarned';
import type { ISpectatorsEarned } from '../interfaces/ISpectatorsEarned';
import type { IEventRefund } from '../interfaces/IEventRefunds';
import type { ISpectatorType } from '../interfaces/ISpectatorType';
import type { ISpectatorPass } from '../interfaces/ISpectatorPass';

const analyticsApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getEventEarnedAmount: builder.query<
    IBaseQueryResponse<IEventEarned>,
    IBaseQueryRequest
    >({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENT_ANALYTICS_TOTAL_EARNED
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
      }),
      transformResponse: (data: IBaseQueryResponse<IEventEarned>) => ({
        ...data,
        data: {
          earnings: data.data.earnings.map((itemData) => ({
            ...itemData,
            totalAmount: itemData.totalAmount / CENTS_PER_DOLLAR,
          })),
          total: data.data.earnings.reduce((acc, { totalAmount }) => totalAmount / CENTS_PER_DOLLAR + acc, 0),
        },
      }),
    }),

    getEventRevenue: builder.query<
    IBaseQueryResponse<IEventRevenue[]>,
    IBaseQueryRequest & { limit?: number }
    >({
      query: ({ orgId, eventId, ...rest }) => ({
        url: ORG_EVENT_ANALYTICS_REVENUE
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
        params: {
          ...rest,
          orderBy: 'date:desc',
        },
      }),
      transformResponse: (data: IBaseQueryResponse<IEventRevenue[]>) => ({
        ...data,
        data: data.data.map((itemData) => ({
          ...itemData,
          amount: itemData.amount / CENTS_PER_DOLLAR,
        })),
      }),
    }),

    getEventMembersCount: builder.query<
    IBaseQueryResponse<IEventMembersCount>,
    IBaseQueryRequest
    >({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENT_ANALYTICS_MEMBERS_COUNT
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
      }),
      transformResponse: (data: IBaseQueryResponse<IEventMembersCount>) => ({
        ...data,
        data: {
          ...data.data,
          earningsTeamTickets: data.data.earningsTeamTickets / CENTS_PER_DOLLAR,
        },
      }),
    }),

    getTeamsPerDevision: builder.query<
    IBaseQueryResponse<ITeamsPerDevision[]>,
    IBaseQueryRequest
    >({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENT_ANALYTICS_TEAM_PER_DIVISION
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
      }),
      providesTags: (result, error, { eventId }) => ([
        { type: ANALYTICS_TEAM_PER_DIVISION_TAG, id: eventId },
      ]),
    }),

    getTeamsEarnedByDevision: builder.query<
    IBaseQueryResponse<ITeamsEarned>,
    IBaseQueryRequest & { limit?: number, eventDivisionId?: string }
    >({
      query: ({ orgId, eventId, ...rest }) => ({
        url: ORG_EVENT_ANALYTICS_TEAMS_EARNED_BY_DIVISION
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
        params: {
          ...rest,
          orderBy: 'date:desc',
        },
      }),
      transformResponse: (data: IBaseQueryResponse<ITeamsEarned>) => ({
        ...data,
        data: {
          rows: data.data.rows.map((itemData) => ({
            ...itemData,
            date: transformDate(itemData.date),
            fullPriceAmount: itemData.fullPriceAmount / CENTS_PER_DOLLAR,
            divDiscountedAmount: itemData.divDiscountedAmount / CENTS_PER_DOLLAR,
            multipleDiscountedAmount: itemData.multipleDiscountedAmount / CENTS_PER_DOLLAR,
            multipleAndDivDiscountedAmount: itemData.multipleAndDivDiscountedAmount / CENTS_PER_DOLLAR,
            totalAmount: itemData.totalAmount / CENTS_PER_DOLLAR,
          })),
          total: {
            ...data.data.total,
            fullPriceAmount: data.data.total.fullPriceAmount / CENTS_PER_DOLLAR,
            divDiscountedAmount: data.data.total.divDiscountedAmount / CENTS_PER_DOLLAR,
            multipleDiscountedAmount: data.data.total.multipleDiscountedAmount / CENTS_PER_DOLLAR,
            multipleAndDivDiscountedAmount: data.data.total.multipleAndDivDiscountedAmount / CENTS_PER_DOLLAR,
            totalAmount: data.data.total.totalAmount / CENTS_PER_DOLLAR,
          },
        },
      }),
    }),

    getSpectatorsEarned: builder.query<
    IBaseQueryResponse<ISpectatorsEarned>,
    IBaseQueryRequest & { limit?: number }
    >({
      query: ({ orgId, eventId, ...rest }) => ({
        url: ORG_EVENT_ANALYTICS_SPECTATORS_EARNED
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
        params: {
          ...rest,
          orderBy: 'date:desc',
        },
      }),
      transformResponse: (data: IBaseQueryResponse<ISpectatorsEarned>) => ({
        ...data,
        data: {
          rows: data.data.rows.map((itemData) => ({
            ...itemData,
            date: transformDate(itemData.date),
            adultAmount: itemData.adultAmount / CENTS_PER_DOLLAR,
            adultDiscountedAmount: itemData.adultDiscountedAmount / CENTS_PER_DOLLAR,
            childAmount: itemData.childAmount / CENTS_PER_DOLLAR,
            childDiscountedAmount: itemData.childDiscountedAmount / CENTS_PER_DOLLAR,
            seniorAmount: itemData.seniorAmount / CENTS_PER_DOLLAR,
            seniorDiscountedAmount: itemData.seniorDiscountedAmount / CENTS_PER_DOLLAR,
            totalAmount: itemData.totalAmount / CENTS_PER_DOLLAR,
          })),
          total: {
            ...data.data.total,
            adultAmount: data.data.total.adultAmount / CENTS_PER_DOLLAR,
            adultDiscountedAmount: data.data.total.adultDiscountedAmount / CENTS_PER_DOLLAR,
            childAmount: data.data.total.childAmount / CENTS_PER_DOLLAR,
            childDiscountedAmount: data.data.total.childDiscountedAmount / CENTS_PER_DOLLAR,
            seniorAmount: data.data.total.seniorAmount / CENTS_PER_DOLLAR,
            seniorDiscountedAmount: data.data.total.seniorDiscountedAmount / CENTS_PER_DOLLAR,
            totalAmount: data.data.total.totalAmount / CENTS_PER_DOLLAR,
          },
        },
      }),
    }),

    getRefunds: builder.query<
    IBaseQueryResponse<IEventRefund[]>,
    IBaseQueryRequest & { limit?: number }
    >({
      query: ({ orgId, eventId, ...rest }) => ({
        url: ORG_EVENT_ANALYTICS_REFUNDS
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
        params: {
          ...rest,
          orderBy: 'createdAt:desc',
        },
      }),
      transformResponse: (data: IBaseQueryResponse<IEventRefund[]>) => ({
        ...data,
        data: data.data.map((itemData) => ({
          ...itemData,
          refundedAt: transformDate(itemData.refundedAt, 'dd.MM.yy ta'),
          amount: itemData.amount / CENTS_PER_DOLLAR,
        })),
      }),
    }),

    getSpectatorsByType: builder.query<
    IBaseQueryResponse<ISpectatorType>,
    IBaseQueryRequest
    >({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENT_ANALYTICS_SPECTATORS_BY_TYPE
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
      }),
    }),

    getSpectatorsByPass: builder.query<
    IBaseQueryResponse<ISpectatorPass>,
    IBaseQueryRequest
    >({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENT_ANALYTICS_SPECTATORS_BY_PASS
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetEventEarnedAmountQuery,
  useGetEventRevenueQuery,
  useGetEventMembersCountQuery,
  useGetTeamsPerDevisionQuery,
  useGetTeamsEarnedByDevisionQuery,
  useGetSpectatorsEarnedQuery,
  useGetRefundsQuery,
  useGetSpectatorsByTypeQuery,
  useGetSpectatorsByPassQuery,
} = analyticsApi;

export default analyticsApi;
