import Box from '@mui/material/Box';

import NotificationForm from './components/NotificationForm';
import NotificationTable from './components/NotificationTable';
import type ISingleEventNotificationsView from './interfaces/ISingleEventNotificationsView';

function SingleEventNotificationsView({
  control,
  notifications,
  pagination,
  isSubmitting,
  onSelectRecipient,
  onValidateRecipient,
  onSelectType,
  onSubmit,
}: ISingleEventNotificationsView) {
  return (
    <Box>
      <NotificationForm
        control={control}
        onSelectRecipient={onSelectRecipient}
        onValidateRecipient={onValidateRecipient}
        onSelectType={onSelectType}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
      />
      <NotificationTable
        notifications={notifications}
        pagination={pagination}
      />
    </Box>
  );
}

export default SingleEventNotificationsView;
