import mainApi from '../../../apis/mainApi';
import { EVENT_NOTIFICATIONS_LIST_TAG } from '../../../tags/notifications';
import {
  ORG_EVENT_NOTIFICATION,
} from '../../../../constants/requestUrls';
import type IBaseQueryResWithPagination from '../../../types/IBaseQueryResWithPagination';
import type IGetNotificationsRequest from '../interfaces/IGetNotificationsRequest';
import type INotification from '../interfaces/INotification';
import type ICreateNotificationRequest from '../interfaces/ICreateNotificationRequest';

const notificationsApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<IBaseQueryResWithPagination<INotification[]>, IGetNotificationsRequest>({
      query: ({
        orgId, eventId, ...rest
      }) => ({
        url: ORG_EVENT_NOTIFICATION
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
        params: { ...rest },
      }),
      providesTags: (result) => (
        result
          ? [...result.data.map(({ id }) => ({ type: EVENT_NOTIFICATIONS_LIST_TAG, id })), EVENT_NOTIFICATIONS_LIST_TAG]
          : [EVENT_NOTIFICATIONS_LIST_TAG]
      ),
    }),

    createNotification: builder.mutation<IBaseQueryResWithPagination<INotification>, ICreateNotificationRequest>({
      query: ({
        orgId, eventId, data,
      }) => ({
        url: ORG_EVENT_NOTIFICATION
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [EVENT_NOTIFICATIONS_LIST_TAG],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useCreateNotificationMutation,
} = notificationsApi;

export default notificationsApi;
