import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useWatch } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import ActionDelete from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import FormHelperText from '@mui/material/FormHelperText';
import type { DateTime } from 'luxon';

import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import TextMask from '../TextMask/TextMask';
import SalesSettings from './SalesSettings';
import { DEFAULT_DATE_FORMAT, GENDERS, TEAM_AGES } from '../../constants/general';
import { DEFAULT_PRICE_MASK } from '../../constants/textMasks';
import type { IDivisionSection } from './interfaces/IDivisionSection';

function DivisionSection({
  control,
  divisionIndex,
  onRemove,
  onAppendDiscount,
  onRemoveDiscount,
  isPublished,
  maxDiscountDate,
  onPrecreateDevisionName,
}: IDivisionSection): React.ReactElement {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const currentName = useWatch({ name: `divisions.${divisionIndex}.name`, control });
  const discount = useWatch({ name: `divisions.${divisionIndex}.discount`, control });
  const registrationEndDate = useWatch({ name: 'divisionRegistrationEndAt', control }) as DateTime;
  const ticketCountLimit = useWatch({ control, name: `divisions.${divisionIndex}.minTicketCountLimit` });
  const ageValue = useWatch({ control, name: `divisions.${divisionIndex}.age` });
  const genderValue = useWatch({ control, name: `divisions.${divisionIndex}.gender` });
  const [prevProp, setPrevProp] = useState<Record<string, string>>({
    age: ageValue,
    gender: genderValue,
  });

  useEffect(() => {
    const shouldGenerate = ageValue !== prevProp.age || genderValue !== prevProp.gender;

    if (shouldGenerate && ageValue && genderValue) {
      const genderTranslate = t(`events.${genderValue}`);
      onPrecreateDevisionName(divisionIndex, `${genderTranslate} ${ageValue}`);
    }

    setPrevProp({
      age: ageValue,
      gender: genderValue,
    });
  }, [
    t,
    ageValue,
    genderValue,
    onPrecreateDevisionName,
    divisionIndex,
    prevProp.age,
    prevProp.gender,
  ]);

  const handleToggleModal = () => setOpen((prev) => !prev);

  const handleRemove = () => onRemove(divisionIndex);

  return (
    <Box>
      <Grid container rowSpacing={3} columnSpacing={5}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={3} justifyContent="space-between">
            <Typography component="h4" variant="h6">
              {`Division ${currentName}`}
            </Typography>
            <IconButton onClick={isPublished ? handleToggleModal : handleRemove}>
              <ActionDelete />
            </IconButton>
          </Stack>
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name={`divisions.${divisionIndex}.age`}
            control={control}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error: fieldError },
            }) => (
              <FormControl error={!!fieldError} fullWidth>
                <InputLabel id="age-label">
                  {t('events.age')}
                </InputLabel>
                <Select
                  id="age"
                  labelId="age-label"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  label={t('events.age')}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      <Chip label={selected} sx={{ px: 0.5, height: 24 }} />
                    </Box>
                  )}
                >
                  {TEAM_AGES.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={value === name} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
                {fieldError?.message ? <FormHelperText>{fieldError.message}</FormHelperText> : null}
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name={`divisions.${divisionIndex}.gender`}
            control={control}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error: fieldError },
            }) => (
              <FormControl error={!!fieldError} fullWidth>
                <InputLabel id="multiple-gender-label">
                  {t('events.gender')}
                </InputLabel>
                <Select
                  id="multiple-gender"
                  labelId="multiple-gender-label"
                  label={t('events.gender')}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                >
                  {Object.values(GENDERS).map((gender) => (
                    <MenuItem key={gender} value={gender}>
                      {t(`events.${gender}`)}
                    </MenuItem>
                  ))}
                </Select>
                {fieldError?.message ? <FormHelperText>{fieldError.message}</FormHelperText> : null}
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name={`divisions.${divisionIndex}.ticketCount`}
            control={control}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error: fieldError },
            }) => (
              <TextField
                helperText={fieldError?.message}
                error={!!fieldError}
                label={t('events.teamLimit')}
                variant="outlined"
                InputProps={{
                  inputComponent: TextMask as any,
                  inputProps: { mask: Number, min: 0 },
                }}
                value={value?.toString() || null}
                onBlur={onBlur}
                onChange={(e) => {
                  onChange(e.target.value ? parseInt(e.target.value) : null);
                }}
                fullWidth
              />
            )}
          />
          {isPublished ? (
            <Typography variant="body2" color="text.secondary" mt={2}>
              {t('events.divisionTeamLimitHint', { limit: ticketCountLimit })}
            </Typography>
          ) : null}
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name={`divisions.${divisionIndex}.name`}
            control={control}
            render={({
              field,
              fieldState: { error: fieldError },
            }) => (
              <TextField
                helperText={fieldError?.message}
                error={!!fieldError}
                label={t('events.name')}
                variant="outlined"
                fullWidth
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name={`divisions.${divisionIndex}.ticketPrice`}
            control={control}
            render={({
              field,
              fieldState: { error: fieldError },
            }) => (
              <TextField
                helperText={fieldError?.message}
                error={!!fieldError}
                label={t('events.ticketPrice')}
                variant="outlined"
                InputProps={{
                  inputComponent: TextMask as any,
                  inputProps: DEFAULT_PRICE_MASK,
                }}
                fullWidth
                {...field}
              />
            )}
          />
          {registrationEndDate ? (
            <Typography variant="body2" mt={2} mb={-1} color="text.secondary">
              {t('events.registrationStops', {
                date: registrationEndDate.toFormat(`${DEFAULT_DATE_FORMAT}, hh:mm a`),
              })}
            </Typography>
          ) : null}
        </Grid>

        <Grid item xs={12}>
          {discount ? (
            <Controller
              name={`divisions.${divisionIndex}.discount`}
              control={control}
              render={() => (
                <SalesSettings
                  control={control}
                  divisionIndex={divisionIndex}
                  onRemove={() => {
                    onRemoveDiscount(divisionIndex);
                  }}
                  isPublished={isPublished}
                  maxDiscountDate={maxDiscountDate}
                />
              )}
            />
          ) : (
            <Button
              size="small"
              onClick={() => onAppendDiscount(divisionIndex)}
              sx={{ ml: -1 }}
            >
              {t('events.addDiscountSettings')}
            </Button>
          )}
        </Grid>
      </Grid>
      {isPublished ? (
        <ConfirmDialog
          title={t('events.divisionDialogDeleteTitle')}
          description={t('events.divisionDialogDeleteHint')}
          secondDescription={t('events.divisionDialogDeleteSecondHint')}
          open={open}
          onClose={handleToggleModal}
          buttonText={t('common.delete')}
          onConfirm={() => {
            handleRemove();
            handleToggleModal();
          }}
        />
      ) : null}
    </Box>
  );
}

export default DivisionSection;
