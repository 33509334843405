import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

import ScheduleCalendar from '../../components/ScheduleCalendar/ScheduleCalendar';
import type ISingleEventPoolPlaySchedule from './interfaces/ISingleEventPoolPlaySchedule';

function SingleEventPoolPlayScheduleView({
  control, games, unscheduledList, fields, filterData, buyGames, timezone,
  timeFrames, selectedGame, isOpenEditFields, isLoading, isRegenerating,
  toggleGameDetailsDialog, onEditFieldsClick, onPublishSchedule,
  onAddFromUnscheduled, onUpdateGame, onSelectGame, onRegeneratePositions, onGoBack,
  toggleBuyGamesDialog, isOpenBuyGames, isPublishing, fullInfoId, isSchedulePublished,
  selectedDate, type,
}: ISingleEventPoolPlaySchedule) {
  const { t } = useTranslation();

  return (
    <>
      <Paper
        sx={{
          px: 3, py: 3, mt: 4, mb: 3,
        }}
        elevation={0}
      >
        {isLoading ? (
          <Stack alignItems="center" my={8}>
            <CircularProgress size={45} thickness={1} color="secondary" />
          </Stack>
        ) : (
          <ScheduleCalendar
            type={type}
            timezone={timezone}
            games={games}
            buyGames={buyGames}
            unscheduledList={unscheduledList}
            fields={fields}
            filterData={filterData}
            timeFrames={timeFrames}
            selectedGame={selectedGame}
            control={control}
            onAddFromUnscheduled={onAddFromUnscheduled}
            onUpdateGame={onUpdateGame}
            onSelectGame={onSelectGame}
            isOpenEditFields={isOpenEditFields}
            onEditFieldsClick={onEditFieldsClick}
            toggleGameDetailsDialog={toggleGameDetailsDialog}
            heading={`${t('schedule.poolPlaySchedule')}`}
            toggleBuyGamesDialog={toggleBuyGamesDialog}
            isOpenBuyGames={isOpenBuyGames}
            fullInfoId={fullInfoId}
            selectedDate={selectedDate}
          />
        )}
      </Paper>
      <Stack justifyContent="flex-end" direction="row" gap={1.5} mt={3}>
        <Button variant="outlined" onClick={onGoBack}>
          {t('common.back')}
        </Button>
        <Button variant="outlined" onClick={onRegeneratePositions} disabled={isPublishing || isRegenerating}>
          {t('schedule.regenerate')}
        </Button>
        <Button variant="contained" onClick={onPublishSchedule} disabled={isPublishing || isRegenerating}>
          {t(isSchedulePublished ? 'common.exitEdit' : 'common.saveAndPublish')}
        </Button>
      </Stack>
    </>
  );
}

export default SingleEventPoolPlayScheduleView;
