import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { DateTime } from 'luxon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import ArticleIcon from '@mui/icons-material/Article';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TodayIcon from '@mui/icons-material/Today';
import EventIcon from '@mui/icons-material/Event';

import SingleEventView from './SingleEventView';
import FacebookIcon from '../../assets/images/Facebook.svg';
import InstagramIcon from '../../assets/images/Instagram.svg';
import HudlIcon from '../../assets/images/Hudl.svg';
import XIcon from '../../assets/images/X.svg';
import TiktokIcon from '../../assets/images/TikTok.svg';
import YoutoubeIcon from '../../assets/images/youtube.svg';
import { useAppSelector } from '../../store/hooks/useApp';
import { selectUserOrg } from '../../store/slices/user/selectors';
import {
  useGetEventHotelsQuery,
  useGetEventPartnersQuery,
  useGetEventQuery,
} from '../../store/slices/events/apis/eventsApi';
import { useGetEventWaiversQuery } from '../../store/slices/waivers/apis/waiversApi';
import { useGetEventRulesQuery } from '../../store/slices/rules/apis/rulesApi';
import { useGetDivisionsListQuery } from '../../store/slices/divisions/apis/divisionsApi';
import {
  createSingleEventDate, createSingleEventDetail, createSingleEventDiscountDate, formatTournamentsDays,
} from '../../utils/helpers';
import { SOCIAL_NAMES, SPECTATORS_TICKET_PASS } from '../../constants/general';
import { useGetSpectatorRuleQuery } from '../../store/slices/spectators/apis/spectatorsApi';
import { useGetTeamTicketRuleQuery } from '../../store/slices/teamTicketRules/apis/teamTicketRulesApi';
import { selectEventSpectators } from '../../store/slices/spectators/selectors';

function SingleEvent() {
  const { t } = useTranslation();
  const { eventId } = useParams();
  const organization = useAppSelector(selectUserOrg);

  const fetchQuery = organization?.id && eventId ? {
    orgId: organization.id,
    eventId: +eventId,
  } : skipToken;

  const { data: eventData } = useGetEventQuery(fetchQuery);
  const { data: hotelsData } = useGetEventHotelsQuery(fetchQuery);
  const { data: partnersData } = useGetEventPartnersQuery(fetchQuery);
  const { data: waiversData } = useGetEventWaiversQuery(fetchQuery);
  const { data: rulesData } = useGetEventRulesQuery(fetchQuery);
  const { data: divisionsData } = useGetDivisionsListQuery(fetchQuery);
  const { data: teamTicketRules } = useGetTeamTicketRuleQuery(fetchQuery);
  const { spectatorRules } = useGetSpectatorRuleQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, spectatorRules: selectEventSpectators(result.data) }),
    },
  );

  const eventDetailsList = [
    createSingleEventDetail(<PhoneIcon />, t('events.phone'), eventData?.data.phone),
    createSingleEventDetail(<EmailIcon />, t('events.email'), eventData?.data.email),
    createSingleEventDetail(<CheckCircleIcon />, t('events.type'), eventData?.data.type
      ? t(`common.${eventData?.data.type}`)
      : eventData?.data.type),
    createSingleEventDetail(<ArticleIcon />, t('events.about'), eventData?.data.about),
    createSingleEventDetail(
      <LocationOnIcon />,
      t('events.address'),
      eventData?.data?.address?.streetAddress1,
      true,
      eventData?.data?.address?.description,
    ),
  ];

  const eventSocialsList = [
    createSingleEventDetail(
      <img src={FacebookIcon} alt={`${SOCIAL_NAMES.FACEBOOK} icon`} />,
      SOCIAL_NAMES.FACEBOOK,
      eventData?.data.socialMedia.facebook,
    ),
    createSingleEventDetail(
      <img src={InstagramIcon} alt={`${SOCIAL_NAMES.INSTAGRAM} icon`} />,
      SOCIAL_NAMES.INSTAGRAM,
      eventData?.data.socialMedia.instagram,
    ),
    createSingleEventDetail(
      <img src={HudlIcon} alt={`${SOCIAL_NAMES.HUDL} icon`} />,
      SOCIAL_NAMES.HUDL,
      eventData?.data.socialMedia.hudl,
    ),
    createSingleEventDetail(
      <img src={XIcon} alt={`${SOCIAL_NAMES.X} icon`} />,
      SOCIAL_NAMES.X,
      eventData?.data.socialMedia.twitter,
    ),
    createSingleEventDetail(
      <img src={TiktokIcon} alt={`${SOCIAL_NAMES.TIKTOK} icon`} />,
      SOCIAL_NAMES.TIKTOK,
      eventData?.data.socialMedia.tiktok,
    ),
    createSingleEventDetail(
      <img src={YoutoubeIcon} alt={`${SOCIAL_NAMES.YOUTUBE} icon`} />,
      SOCIAL_NAMES.YOUTUBE,
      eventData?.data.socialMedia.youtube,
    ),
  ];

  const eventDates = [
    createSingleEventDate(t('events.startDate'), <TodayIcon />, eventData?.data.startAt, eventData?.data.timezone),
    createSingleEventDate(t('events.endDate'), <EventIcon />, eventData?.data.endAt, eventData?.data.timezone),
  ];

  const tournamentsDays = formatTournamentsDays(eventData?.data.spectatorOneDayTicketRules || []);

  const eventRegistrationDates = [
    createSingleEventDate(
      t('events.teamRegistrationEnds'),
      <EventIcon />,
      teamTicketRules?.data?.divisionRegistrationEndAt,
      eventData?.data.timezone,
    ),
    createSingleEventDate(
      t('events.ageValidationDate'),
      <EventIcon />,
      teamTicketRules?.data?.ageValidateFrom,
      eventData?.data.timezone,
      true,
    ),
  ];

  const teamDiscountsList = divisionsData?.data.filter((division) => division.discount)
    .map(({ name, discount }) => createSingleEventDiscountDate(name, discount?.endAt, eventData?.data.timezone));

  const eventSpectatorsDates = [
    createSingleEventDate(
      t('events.startSellingSpectators'),
      <TodayIcon />,
      eventData?.data?.spectatorRules?.sellStartAt,
      eventData?.data.timezone,
    ),
    createSingleEventDate(
      t('events.endSellingSpectators'),
      <EventIcon />,
      eventData?.data?.spectatorRules?.sellEndAt,
      eventData?.data.timezone,
    ),
  ];

  const oneDayTickets = eventData?.data.spectatorRules?.ticketTypeRules
    .filter((item) => item.pass === SPECTATORS_TICKET_PASS.DAY_PASS && item.discount)
    .map(({ type, discount }) => createSingleEventDiscountDate(
      t(
        `events.${type}`,
      ),
      discount?.endAt,
      eventData?.data.timezone,
    ));

  const wholeEventTickets = eventData?.data.spectatorRules?.ticketTypeRules
    .filter((item) => item.pass === SPECTATORS_TICKET_PASS.WHOLE_PASS && item.discount)
    .map(({ type, discount }) => createSingleEventDiscountDate(
      t(
        `events.${type}`,
      ),
      discount?.endAt,
      eventData?.data.timezone,
    ));

  return (
    <SingleEventView
      details={eventDetailsList}
      socials={eventSocialsList}
      dates={eventDates}
      tournamentsDays={tournamentsDays}
      teamRegistrationDates={eventRegistrationDates}
      teamDiscountsList={teamDiscountsList}
      eventSpectatorsDates={eventSpectatorsDates}
      oneDayTickets={oneDayTickets}
      wholeEventTickets={wholeEventTickets}
      hotels={hotelsData?.data}
      waivers={waiversData?.data}
      rules={rulesData?.data}
      divisionsDetails={{
        multipleDiscountAmount: teamTicketRules?.data.multipleDiscountAmount,
        multipleDiscountTeamsCount: teamTicketRules?.data.multipleDiscountTeamsCount,
        teamRegistrationEndsOn: teamTicketRules?.data
          ? DateTime.fromISO(teamTicketRules.data.divisionRegistrationEndAt, {
            locale: 'en-US', zone: eventData?.data.timezone,
          }).toFormat('ccc, LLL d, yyyy t ZZZZ')
          : '-',
        ageRange: eventData?.data.ageRange,
        gender: eventData?.data.gender,
      }}
      divisions={divisionsData?.data}
      partners={partnersData?.data}
      tickets={spectatorRules?.ticketTypeRules}
      timezone={eventData?.data.timezone}
    />
  );
}

export default SingleEvent;
