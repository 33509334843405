import { AppThemeColors } from '../constants/AppThemes';
import type { TThemePalettes, TPalette } from './types/TThemePalettes';

const commonColorConfig = {
  primaryRed: { light: '#fce4ec', main: '#e91e63', dark: '#ad1457' },
  secondaryRed: { light: '#ff80ab', main: '#ff4081', dark: '#880e4f' },
  primaryGreen: { light: '#e0f2f1', main: '#009688', dark: '#00695c' },
  primaryOrange: { light: '#fff3e0', main: '#ef6c00', dark: '#e65100' },
  primaryLightGreen: { light: '#dcedc8', main: '#9ccc65', dark: '#558b2f' },
  secondaryLightGreen: { light: '#cddc39', main: '#4caf50', dark: '#2e7d32' },
  primaryLightBlue: { light: '#b3e5fc', main: '#03a9f4', dark: '#0277bd' },
};

const createPalette = (primary: TPalette, secondary: TPalette) => ({
  palette: {
    primary: { ...primary, contrastText: '#fff' },
    secondary: { ...secondary, contrastText: '#fff' },
  },
});

export const lightPalettes: TThemePalettes = {
  [AppThemeColors.Red]: createPalette(commonColorConfig.primaryRed, commonColorConfig.secondaryRed),
  [AppThemeColors.Green]: createPalette(
    commonColorConfig.primaryGreen,
    { light: '#64ffda', main: '#008573', dark: '#004d40' },
  ),
  [AppThemeColors.Blue]: createPalette(
    { light: '#e8eaf6', main: '#3f51b5', dark: '#283593' },
    { light: '#8c9eff', main: '#304ffe', dark: '#1a237e' },
  ),
  [AppThemeColors.Purple]: createPalette(
    { light: '#f3e5f5', main: '#9c27b0', dark: '#6a1b9a' },
    { light: '#ea80fc', main: '#aa00ff', dark: '#4a148c' },
  ),
  [AppThemeColors.Orange]: createPalette(
    commonColorConfig.primaryOrange,
    { light: '#ff9e80', main: '#ff3d00', dark: '#bf360c' },
  ),
  [AppThemeColors.Grey]: createPalette(
    { light: '#cfd8dc', main: '#03a9f4', dark: '#37474f' },
    { light: '#bdbdbd', main: '#424242', dark: '#212121' },
  ),
  [AppThemeColors.LightGreen]: createPalette(
    commonColorConfig.primaryLightGreen,
    commonColorConfig.secondaryLightGreen,
  ),
  [AppThemeColors.LightBlue]: createPalette(
    commonColorConfig.primaryLightBlue,
    { light: '#80deea', main: '#00bcd4', dark: '#00838f' },
  ),
  [AppThemeColors.Brown]: createPalette(
    { light: '#d7ccc8', main: '#795548', dark: '#4e342e' },
    { light: '#ccb0b0', main: '#794848', dark: '#3e2723' },
  ),
};

export const darkPalettes: TThemePalettes = {
  [AppThemeColors.Red]: createPalette(commonColorConfig.primaryRed, commonColorConfig.secondaryRed),
  [AppThemeColors.Green]: createPalette(
    commonColorConfig.primaryGreen,
    { light: '#a7ffeb', main: '#00bfa5', dark: '#004d40' },
  ),
  [AppThemeColors.Blue]: createPalette(
    { light: '#e8eaf6', main: '#5c6bc0', dark: '#3f51b5' },
    { light: '#82b1ff', main: '#448aff', dark: '#0d47a1' },
  ),
  [AppThemeColors.Purple]: createPalette(
    { light: '#f3e5f5', main: '#ab47bc', dark: '#6a1b9a' },
    { light: '#ea80fc', main: '#e040fb', dark: '#4a148c' },
  ),
  [AppThemeColors.Orange]: createPalette(
    commonColorConfig.primaryOrange,
    { light: '#ffd180', main: '#ff9100', dark: '#e65100' },
  ),
  [AppThemeColors.Grey]: createPalette(
    { light: '#eceff1', main: '#03a9f4', dark: '#37474f' },
    { light: '#e0e0e0', main: '#757575', dark: '#263238' },
  ),
  [AppThemeColors.LightGreen]: createPalette(
    commonColorConfig.primaryLightGreen,
    commonColorConfig.secondaryLightGreen,
  ),
  [AppThemeColors.LightBlue]: createPalette(
    commonColorConfig.primaryLightBlue,
    { light: '#e0f7fa', main: '#00bcd4', dark: '#00838f' },
  ),
  [AppThemeColors.Brown]: createPalette(
    { light: '#d7ccc8', main: '#a1887f', dark: '#4e342e' },
    { light: '#ccb0b0', main: '#a06868', dark: '#3e2723' },
  ),
};
