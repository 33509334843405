import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { setUserOrganization } from '../user/slice';
import { logout } from '../auth/slice';
import type IWaiversSlice from './interfaces/IWaiversSlice';

export const initialState: IWaiversSlice = {
  search: '',
  waiverId: '',
  teamsIds: [],
  status: '',
};

export const waiversSlice = createSlice({
  name: 'waivers',
  initialState,
  reducers: {
    setWaiversState: (state, { payload }: PayloadAction<Partial<IWaiversSlice>>) => ({ ...state, ...payload }),
    resetWaiversState: () => initialState,
  },
  extraReducers: (builder): void => {
    builder.addCase(setUserOrganization, (): IWaiversSlice => initialState);
    builder.addCase(logout, (): IWaiversSlice => initialState);
  },
});

export const {
  setWaiversState,
  resetWaiversState,
} = waiversSlice.actions;

export default waiversSlice.reducer;
