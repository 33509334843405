import { useTranslation } from 'react-i18next';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { useCallback } from 'react';

import { getTableHeadCells } from '../../../constants/tableHead';
import type IStandingsTable from '../interfaces/IStandingsTable';
import type { IStanding } from '../interfaces/IStandingsTable';

function StandingsTable({
  standings, selectedTeams, selectedPoolNames, tabValue,
}: IStandingsTable) {
  const { t } = useTranslation();

  const getIsHighlighted = useCallback((standing: IStanding) => {
    if (selectedTeams.length === 0 && selectedPoolNames.length === 0) return true;
    return (
      (selectedTeams.length === 0 || selectedTeams.some(({ value }) => value === standing.team.id))
      && (selectedPoolNames.length === 0 || selectedPoolNames.some(({ value }) => value === standing.poolId)
      ));
  }, [selectedPoolNames, selectedTeams]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {getTableHeadCells(!tabValue).map((item) => (
              <TableCell key={item}>{t(item)}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {standings.map((item) => (
            <TableRow
              key={`StandingsTableRow_${item.seed || item.num}`}
              sx={{
                '&:nth-of-type(odd)': { bgcolor: 'background.secondary' },
                '& > td': { color: getIsHighlighted(item) ? 'white' : 'text.disabled' },
              }}
            >
              <TableCell>
                {item.num || item.seed}
              </TableCell>
              <TableCell>
                <Stack direction="row" gap={2} alignItems="center">
                  <Avatar src={item.team.profilePhoto} />
                  {item.team.name}
                </Stack>
              </TableCell>
              <TableCell>
                {item.gameStat?.games}
              </TableCell>
              {tabValue ? (
                <TableCell>
                  {item.gameStat?.greatestRound}
                </TableCell>
              ) : null}
              <TableCell>
                {item.gameStat?.wins}
              </TableCell>
              <TableCell>
                {item.gameStat?.losses}
              </TableCell>
              <TableCell>
                {item.gameStat?.ties}
              </TableCell>
              <TableCell>
                {item.gameStat?.pointsFor}
              </TableCell>
              <TableCell>
                {item.gameStat?.pointsAgainst}
              </TableCell>
              <TableCell>
                {item.gameStat?.pointsDiff}
              </TableCell>
              {!tabValue ? (
                <TableCell>
                  {item.gameStat?.score}
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default StandingsTable;
