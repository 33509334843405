import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  root: {
    position: 'relative',
  },
  input: {
    '.MuiInputBase-root': {
      borderRadius: '8px',
    },
  },
  title: {
    '.MuiInputBase-root': {
      '.MuiInputBase-input': {
        paddingRight: '77px',
      },
    },
  },
  text: {
    '.MuiInputBase-root': {
      paddingBottom: '35px',
    },
  },
  helper: {
    position: 'absolute',
  },
  helperRight: {
    top: '16px',
    right: '16px',
  },
  helperBottom: {
    left: '16px',
  },
});

export default useStyles;
