import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InfoIcon from '@mui/icons-material/Info';
import Stack from '@mui/material/Stack';
import TablePagination from '@mui/material/TablePagination';

import TabPanel from '../../components/TabPanel/TabPanel';
import TableTabs from '../../components/TableTabs/TableTabs';
import StandingsTable from './components/StandingsTable';
import StandingsTableFilters from './components/StandingsTableFilters';
import a11yProps from '../../utils/a11yProps';
import { SingleEventStandingsTabs } from '../../constants/Tabs';
import type ISingleEventStandingsView from './interfaces/ISingleEventStandingsView';

function SingleEventStandingsView({
  tabValue,
  divisionTabValue,
  standings,
  divisionsTabs,
  pagination,
  onTabChange,
  onDivisionTabChange,
  divisionGamesPoolTeamsSelect,
  divisionGamesPoolSelect,
  selectedTeams,
  setSelectedTeams,
  selectedPoolNames,
  setSelectedPoolNames,
}: ISingleEventStandingsView) {
  const { t } = useTranslation();

  return (
    <>
      <Typography component="h4" variant="h6">
        {t('standings.poolPlaySeedingTable')}
      </Typography>
      <Paper elevation={0} sx={{ p: 3, my: 3 }}>
        <Tabs
          value={tabValue}
          onChange={onTabChange}
          variant="fullWidth"
          scrollButtons="auto"
          sx={{ mb: 3, borderRadius: 0, bgcolor: 'background.secondary' }}
        >
          {SingleEventStandingsTabs.map((tab, index) => (
            <Tab
              key={`SingleEventScheduleTab_${tab}`}
              label={t(`standings.${tab}`)}
              {...a11yProps('tab', 'tabpanel', index)}
            />
          ))}
        </Tabs>
        {SingleEventStandingsTabs.map((tab, index) => (
          <TabPanel
            key={`SingleEventPageTabPanel_${tab}`}
            value={tabValue}
            index={index}
            slugId="tabpanel"
            slugLabelledby="tab"
          >
            <Stack gap={3}>
              {divisionsTabs.length > 0 && divisionTabValue !== null ? (
                <TableTabs
                  tabs={divisionsTabs}
                  tabValue={divisionTabValue}
                  onTabChange={onDivisionTabChange}
                />
              ) : null}
              <StandingsTableFilters
                divisionGamesPoolTeamsSelect={divisionGamesPoolTeamsSelect}
                divisionGamesPoolSelect={divisionGamesPoolSelect}
                selectedTeams={selectedTeams}
                setSelectedTeams={setSelectedTeams}
                selectedPoolNames={selectedPoolNames}
                setSelectedPoolNames={setSelectedPoolNames}
              />
              <StandingsTable
                standings={standings}
                selectedTeams={selectedTeams}
                selectedPoolNames={selectedPoolNames}
                tabValue={tabValue}
              />
            </Stack>
          </TabPanel>
        ))}
      </Paper>
      {tabValue === 0 ? (
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          color="text.secondary"
          sx={{ pl: 3 }}
        >
          <InfoIcon />
          <Typography variant="body2">
            {t('standings.scoreForSeedingInfo')}
          </Typography>
        </Stack>
      ) : null}
      <TablePagination
        component="div"
        rowsPerPageOptions={pagination.perPageOptions}
        count={pagination.total}
        rowsPerPage={pagination.perPage}
        page={pagination.page}
        onPageChange={pagination.handleChangePage}
        onRowsPerPageChange={pagination.handleChangePerPage}
      />
    </>
  );
}

export default SingleEventStandingsView;
