import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type IBaseQueryResponse from '../../types/IBaseQueryResponse';
import type IAuthSlice from './interfaces/IAuthSlice';
import type ITokens from './interfaces/ITokens';

export const initialState: IAuthSlice = {
  isLogged: false,
  tokens: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => initialState,
    setTokens: (state, action: PayloadAction<Partial<IBaseQueryResponse<ITokens>>>) => {
      state.isLogged = action.payload.success;
      state.tokens = action.payload.data;
    },
  },
});

export const { logout, setTokens } = authSlice.actions;

export default authSlice.reducer;
