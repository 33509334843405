import { createSelector } from '@reduxjs/toolkit';

import type IBaseQueryResWithPagination from '../../types/IBaseQueryResWithPagination';
import type { IWaiver } from './interfaces/IWaiver';
import type { RootState } from '../../types/TStore';
import type IWaiversSlice from './interfaces/IWaiversSlice';

export const selectEventWaivers = createSelector(
  (data: IBaseQueryResWithPagination<IWaiver[]> | undefined) => data?.data || [],
  (waivers) => waivers.map((waiver) => ({
    documentId: waiver.id,
    name: waiver.title,
    file: waiver.waiver,
  })),
);

export const selectWaiversFilters = (state: RootState): IWaiversSlice => state.waivers;

export const selectTeamsPageFilters = createSelector(selectWaiversFilters, (waivers) => ({
  search: waivers.search,
  status: waivers.status,
  waiverId: waivers.waiverId,
  teamsIds: waivers.teamsIds,
}));

export default selectEventWaivers;
