import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import SingleEventSpectatorsView from './SingleEventSpectatorsView';
import { useAppDispatch, useAppSelector } from '../../store/hooks/useApp';
import { toggleReplaceDatesDialog } from '../../store/slices/spectators/slice';
import { selectIsDatesDialogOpen } from '../../store/slices/spectators/selectors';
import useEventSpectatorsForm from '../../hooks/useEventSpectatorsForm';
import { getInterval } from '../../utils/dates';
import { DEFAULT_DATE_FORMAT } from '../../constants/general';

function SingleEventSpectators(): React.ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isDatesDialogOpen = useAppSelector(selectIsDatesDialogOpen);

  const {
    event,
    tournamentDates,
    control,
    handleDatesSave,
    handleCreateSubmit,
    handleCancel,
    isSubmitting,
    isLoading,
  } = useEventSpectatorsForm({
    navigate: () => {
      navigate(-1);
      toast.success(t('events.changesSaved'), { position: toast.POSITION.TOP_RIGHT });
    },
    onCancel: () => navigate(-1),
  });

  const handleTournamentDaysClick = () => {
    if (event?.published) {
      dispatch(toggleReplaceDatesDialog(true));
    }
  };

  const handleDatesDialogClose = () => {
    dispatch(toggleReplaceDatesDialog(false));
  };

  return (
    <SingleEventSpectatorsView
      eventName={event.nameWithTagline}
      control={control}
      eventStartDate={event.startAt?.toFormat(DEFAULT_DATE_FORMAT) || '-'}
      eventEndDate={event.endAt?.toFormat(DEFAULT_DATE_FORMAT) || '-'}
      tournamentDaysInterval={event.startAt && event.endAt
        ? getInterval(
          event.startAt.set({ hour: 0, minute: 0 }),
          event.endAt.set({ hour: 23, minute: 59 }),
        )
        : []}
      tournamentDates={tournamentDates}
      maxSalesDate={event.endAt}
      onDatesSave={handleDatesSave}
      onTournamentDaysClick={handleTournamentDaysClick}
      onCancelEdit={handleCancel}
      onCreateSubmit={handleCreateSubmit}
      onDatesDialogClose={handleDatesDialogClose}
      isDatesDialogOpen={isDatesDialogOpen}
      isSubmitting={isSubmitting}
      isLoading={isLoading}
    />
  );
}

export default SingleEventSpectators;
