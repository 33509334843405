const signaturesHeaderCells = [
  {
    padding: true,
    label: 'athlete',
  },
  {
    padding: false,
    label: 'guardian',
  },
  {
    padding: false,
    label: 'verificationStatus',
  },
  {
    padding: false,
    label: 'waiverLink',
  },
  {
    padding: false,
    label: 'waiverStatus',
  },
  {
    padding: false,
    label: 'signedBy',
  },
  {
    padding: false,
    label: 'signedDate',
  },
  {
    padding: false,
    label: 'signature',
  },
];

export default signaturesHeaderCells;
