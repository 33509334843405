import { createSlice } from '@reduxjs/toolkit';

import type IUi from './interfaces/IUi';
import { AppThemeColors, AppThemeMode } from '../../../constants/AppThemes';

export const initialState: IUi = {
  themeColor: AppThemeColors.Grey,
  themeMode: AppThemeMode.Dark,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    changeThemeColor: (state, action) => {
      state.themeColor = action.payload.color;
    },
    changeThemeMode: (state, action) => {
      state.themeMode = action.payload.mode;
    },
  },
});

export const { changeThemeColor, changeThemeMode } = uiSlice.actions;

export default uiSlice.reducer;
