import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ActionDelete from '@mui/icons-material/Delete';

import PreviewShell from './PreviewShell';
import type IImagePreview from './interfaces/IImagePreview';

function ImagePreview({ src, onRemove }: IImagePreview) {
  return (
    <PreviewShell>
      <Box component="img" src={src} alt="preview" height={92} width="auto" />
      <IconButton
        onClick={onRemove}
        size="large"
        sx={{ position: 'absolute', top: 0, right: 0 }}
      >
        <ActionDelete className="removeBtn" />
      </IconButton>
    </PreviewShell>
  );
}

export default ImagePreview;
