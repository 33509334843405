import { useEffect, useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

import DashBoardView from './DashBoardView';
import {
  useGetTotalEarnedAmountQuery,
  useGetTotalEarnedByEventQuery,
  useGetTotalEarnedBySeasonQuery,
  useGetTotalEventsByStatusQuery,
} from '../../store/slices/dashboard/apis/dashboardApi';
import { useAppSelector } from '../../store/hooks/useApp';
import { selectUserOrg } from '../../store/slices/user/selectors';
import {
  selectEarnedAmount,
  selectEarnedByEvents,
  selectTotalEarnedBySeason,
  selectTotalEventsByStatus,
} from '../../store/slices/dashboard/selectors';
import { checkAdmin, generateDashboardPieData } from '../../utils/helpers';
import AppRoutes from '../../constants/AppRoutes';

function Dashboard() {
  const navigate = useNavigate();
  const organization = useAppSelector(selectUserOrg);
  const [loadPage, isLoadPage] = useState(false);

  const trueAdmin = checkAdmin(organization?.roles || []);

  useEffect(() => {
    if (!trueAdmin) {
      navigate(AppRoutes.events);
      isLoadPage(false);
    } else {
      isLoadPage(true);
    }
  }, [navigate, trueAdmin]);

  const fetchQuery = organization?.id ? {
    orgId: organization.id,
  } : skipToken;

  const { totalEarnedAmount } = useGetTotalEarnedAmountQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, totalEarnedAmount: selectEarnedAmount(result.data) }),
    },
  );
  const { totalEvents } = useGetTotalEventsByStatusQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, totalEvents: selectTotalEventsByStatus(result.data) }),
    },
  );
  const { earnedByEvents } = useGetTotalEarnedByEventQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, earnedByEvents: selectEarnedByEvents(result.data) }),
    },
  );
  const { earnedBySeason } = useGetTotalEarnedBySeasonQuery(
    organization?.id ? {
      orgId: organization.id,
      startAt: DateTime.local().startOf('year').toISO() as string,
      endAt: DateTime.local().endOf('year').toISO() as string,
    } : skipToken,
    {
      selectFromResult: (result) => ({ ...result, earnedBySeason: selectTotalEarnedBySeason(result.data) }),
    },
  );

  const pieData = useMemo(() => generateDashboardPieData(totalEarnedAmount), [totalEarnedAmount]);

  return (
    <Box
      sx={{
        width: '100%', display: 'flex', justifyContent: 'center',
      }}
    >
      {loadPage ? (
        <DashBoardView
          earnedAmounts={totalEarnedAmount}
          earnedBySeason={earnedBySeason}
          totalEventsCounts={totalEvents}
          earnedByEvents={earnedByEvents}
          pieData={pieData}
        />
      ) : (
        <CircularProgress color="inherit" />
      )}
    </Box>
  );
}

export default Dashboard;
