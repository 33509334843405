import Box from '@mui/material/Box';

function PreviewShell({ children }: { children: React.ReactNode }): React.ReactElement {
  return (
    <Box
      height={100}
      position="relative"
      sx={{
        zIndex: 5,
        overflow: 'hidden',
        m: 1,
        border: '4px solid #fff',
        borderRadius: 1,
        backgroundColor: '#909090',
        button: {
          visibility: 'hidden',
        },
        '&:hover': {
          '& > img': {
            opacity: 0.6,
          },
          '& > svg': {
            opacity: 0.4,
          },
          '& > button': {
            visibility: 'visible',
          },
        },
      }}
    >
      {children}
    </Box>
  );
}

export default PreviewShell;
