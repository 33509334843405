import type { ReactElement } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import SplashScreen from '../../components/SplashScreen/SplashScreen';
import { useAppSelector } from '../../store/hooks/useApp';
import { useGetOrganizationByMeQuery } from '../../store/slices/user/apis/userApi';
import { selectThemeMode } from '../../store/slices/ui/selectors';
import selectPageTitle, { selectIsShowTitle } from '../../store/slices/page/selectors';
import { selectUserOrg } from '../../store/slices/user/selectors';
import { AppThemeMode } from '../../constants/AppThemes';
import mainMenu from '../../mocks/mainMenu';
import useStyles from './pageStyles-tss';
import CookiesAlarm from '../../components/CookiesAlarm/CookiesAlarm';
import { checkAdmin } from '../../utils/helpers';
import { DASHBOARD } from '../../constants/general';

/**
 * Main page layout
 */
function Page(): ReactElement {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const themeMode = useAppSelector(selectThemeMode);
  const pageTitle = useAppSelector(selectPageTitle);
  const organization = useAppSelector(selectUserOrg);
  const isShowTitle = useAppSelector(selectIsShowTitle);
  const { isLoading } = useGetOrganizationByMeQuery(organization ? skipToken : null);

  const trueAdmin = checkAdmin(organization?.roles || []);
  const parts = location.pathname.split('/');
  const place = parts[parts.length - 1];
  const title: string = pageTitle || t(`routes.${place || 'dashboard'}`);
  const hideTitle = !trueAdmin && title === DASHBOARD;
  const menuItems = trueAdmin ? mainMenu : mainMenu.slice(1);

  return (
    <div
      style={{ minHeight: '100vh' }}
      className={
        cx(
          classes.appFrameInner,
          classes.sideNav,
          themeMode === AppThemeMode.Dark ? `${AppThemeMode.Dark}-mode` : `${AppThemeMode.Light}-mode`,
        )
      }
    >
      {isLoading ? <SplashScreen /> : (
        <>
          <CookiesAlarm />
          <Header title={isShowTitle ? title : undefined} />
          <Sidebar menu={menuItems} />
          <main className={cx(classes.content)}>
            {isShowTitle && !hideTitle ? (
              <div className={classes.pageTitle}>
                <Typography component="h4" variant="h4">
                  {title}
                </Typography>
              </div>
            ) : null}
            <Outlet />
          </main>
        </>
      )}
    </div>
  );
}

export default Page;
