import common from './common';
import routes from './routes';
import events from './events';
import login from './login';
import settings from './settings';
import dashboard from './dashboard';
import schedule from './schedule';
import standings from './standings';
import notifications from './notifications';

export default {
  common,
  routes,
  dashboard,
  events,
  login,
  settings,
  schedule,
  standings,
  notifications,
};
