import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTime } from 'luxon';

import TextMask from '../TextMask/TextMask';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { DEFAULT_PRICE_MASK } from '../../constants/textMasks';
import { DEFAULT_DATE_FORMAT, TIME_STEPS_FOR_TIME_PICKER } from '../../constants/general';
import type { ISalesSettings } from './interfaces/ISalesSettings';

function SalesSettings({
  control,
  divisionIndex,
  onRemove,
  maxDiscountDate,
  isPublished,
}: ISalesSettings): React.ReactElement {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const handleToggleModal = () => setOpen((prev) => !prev);

  return (
    <Paper sx={{ p: 3 }}>
      <Grid container rowSpacing={3} columnSpacing={5}>
        <Grid item xs={12}>
          <Typography>
            {t('events.discountSettings')}
          </Typography>
        </Grid>

        <Grid item xs={12} mb={-2}>
          <Typography variant="body2">
            {t('events.discountSalesEndsOn')}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name={`divisions.${divisionIndex}.discount.endAt`}
            control={control}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error: fieldError },
            }) => (
              <DatePicker
                label={t('events.date')}
                value={value}
                onChange={(e) => {
                  const date = e as DateTime;
                  onChange(date?.set({ hour: 23, minute: 59 }));
                }}
                onClose={onBlur}
                slotProps={{
                  textField: {
                    error: !!fieldError,
                    helperText: fieldError?.message,
                    fullWidth: true,
                    onBlur,
                  },
                }}
                format={DEFAULT_DATE_FORMAT}
                maxDate={maxDiscountDate}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name={`divisions.${divisionIndex}.discount.endAt`}
            control={control}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error: fieldError },
            }) => (
              <TimePicker
                label={t('events.time')}
                value={value || DateTime.fromObject({ hour: 23, minute: 59 })}
                onChange={onChange}
                onClose={onBlur}
                timeSteps={TIME_STEPS_FOR_TIME_PICKER}
                slotProps={{
                  textField: {
                    error: !!fieldError,
                    helperText: fieldError?.message,
                    fullWidth: true,
                    onBlur,
                  },
                }}
                maxTime={maxDiscountDate}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name={`divisions.${divisionIndex}.discount.price`}
            control={control}
            render={({
              field,
              fieldState: { error: fieldError },
            }) => (
              <TextField
                helperText={fieldError?.message}
                error={!!fieldError}
                label={t('events.discountedTicketPrice')}
                variant="outlined"
                InputProps={{
                  inputComponent: TextMask as any,
                  inputProps: DEFAULT_PRICE_MASK,
                }}
                fullWidth
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            size="small"
            color="error"
            sx={{ ml: -1, mt: -1 }}
            onClick={isPublished ? handleToggleModal : onRemove}
          >
            {t('events.removeDiscount')}
          </Button>
        </Grid>
      </Grid>
      {isPublished ? (
        <ConfirmDialog
          title={t('events.discountDialogDeleteTitle')}
          description={t('events.discountDialogDeleteHint')}
          secondDescription={t('events.discountDialogDeleteSecondHint')}
          open={open}
          onClose={handleToggleModal}
          buttonText={t('common.delete')}
          onConfirm={() => {
            onRemove();
            handleToggleModal();
          }}
        />
      ) : null}
    </Paper>
  );
}

export default SalesSettings;
