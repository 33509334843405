import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { DateTime } from 'luxon';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import TimeFrameView from './TimeFrameView';
import { useGetEventQuery } from '../../store/slices/events/apis/eventsApi';
import {
  useCreatePoolPlaysMutation,
  useCreatePPTimeFrameMutation,
  useDeletePPTimeFrameMutation,
  useGetPoolPlaysQuery,
  useUpdatePoolPlaysMutation,
  useUpdatePPTimeFrameMutation,
} from '../../store/slices/poolPlays/apis/poolPlaysApi';
import {
  useCreatePlayOffMutation,
  useCreatePOTimeFrameMutation,
  useDeletePOTimeFrameMutation,
  useGetPlayOffQuery,
  useUpdatePlayOffMutation,
  useUpdatePOTimeFrameMutation,
} from '../../store/slices/playOff/apis/playOffApi';
import { timeFrameFormSchema } from '../../utils/validators';
import { getErrorMessage, handlePromiseResWithErrors, hasTrueValue } from '../../utils/helpers';
import AppRoutes from '../../constants/AppRoutes';
import { ScheduleTypes, SECONDS_IN_MINUTE } from '../../constants/general';
import type { TTimeFrameFormSchema } from '../../utils/validators';
import type ICreateEventContext from '../../layout/CreateEventLayout/interfaces/ICreateEventContext';
import type { IPoolPlayTimeFrame } from '../../store/slices/poolPlays/interfaces/IPoolPlayRequest';
import type ITimeFramePage from './interfaces/ITimeFramePage';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';

function TimeFrame({ type }: ITimeFramePage): React.ReactElement {
  const {
    eventId, orgId, isFetch,
  } = useOutletContext<ICreateEventContext>();
  const navigate = useNavigate();

  const { data: event } = useGetEventQuery(
    isFetch ? { orgId, eventId: +eventId } : skipToken,
  );

  const { data: poolPlaysRules, isLoading: isPPRulesLoading } = useGetPoolPlaysQuery(
    isFetch && type === ScheduleTypes.POOL_PLAY
      ? { orgId, eventId: +eventId }
      : skipToken,
    {
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    },
  );

  const { data: playOffRules, isLoading: isPORulesLoading } = useGetPlayOffQuery(
    isFetch && type === ScheduleTypes.PLAY_OFF
      ? { orgId, eventId: +eventId }
      : skipToken,
    {
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    },
  );

  const [createPlayOff] = useCreatePlayOffMutation();
  const [updatePlayOff] = useUpdatePlayOffMutation();
  const [createPOTimeFrame] = useCreatePOTimeFrameMutation();
  const [updatePOTimeFrame] = useUpdatePOTimeFrameMutation();
  const [deletePOTimeFrame] = useDeletePOTimeFrameMutation();

  const [createPoolPlays] = useCreatePoolPlaysMutation();
  const [updatePoolPlays] = useUpdatePoolPlaysMutation();
  const [createPPTimeFrame] = useCreatePPTimeFrameMutation();
  const [updatePPTimeFrame] = useUpdatePPTimeFrameMutation();
  const [deletePPTimeFrame] = useDeletePPTimeFrameMutation();

  const currentCreatePlay = type === ScheduleTypes.POOL_PLAY ? createPoolPlays : createPlayOff;
  const currentUpdatePlay = type === ScheduleTypes.POOL_PLAY ? updatePoolPlays : updatePlayOff;
  const currentUpdateTimeFrame = type === ScheduleTypes.POOL_PLAY ? updatePPTimeFrame : updatePOTimeFrame;
  const currentDeleteTimeFrame = type === ScheduleTypes.POOL_PLAY ? deletePPTimeFrame : deletePOTimeFrame;
  const currentCreateTimeFrame = type === ScheduleTypes.POOL_PLAY ? createPPTimeFrame : createPOTimeFrame;

  const currentPlayRules = type === ScheduleTypes.POOL_PLAY ? poolPlaysRules : playOffRules;
  const nextPage = type === ScheduleTypes.PLAY_OFF
    ? AppRoutes.singleEventPlayOffGameSetup
    : AppRoutes.singleEventPoolPlayLogisticsAndGameSetup;

  const minDate = event?.data.startAt
    ? DateTime.fromISO(event.data.startAt).setZone(currentPlayRules?.timezone) : undefined;
  const maxDate = event?.data.endAt
    ? DateTime.fromISO(event.data.endAt).setZone(currentPlayRules?.timezone) : undefined;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, dirtyFields },
    reset,
    getValues,
    setValue,
    trigger,
  } = useForm<TTimeFrameFormSchema>({
    resolver: yupResolver<TTimeFrameFormSchema>(timeFrameFormSchema),
    mode: 'onBlur',
    defaultValues: {
      editingId: undefined,
      timeFrames: [{
        frameId: undefined,
        date: undefined,
        time: undefined,
      }],
      timeBetweenGames: undefined,
    },
  });

  const {
    fields: timeFrames,
    append: handleAppendTimeFrame,
    remove: handleRemoveTimeFrame,
  } = useFieldArray({ control, name: 'timeFrames' });

  const handleEditingSubmission = async (
    values: TTimeFrameFormSchema,
    times: (IPoolPlayTimeFrame & { frameId?: number | null })[],
  ) => {
    const deletedIds = currentPlayRules?.data.timeFrames
      ?.filter((item) => !times.some(({ frameId }) => item.id === frameId))
      .map((item) => item.id) || [];

    const updatedIndex = dirtyFields.timeFrames
      ?.reduce<number[]>((acc, { time, date }, index) => {
      if (
        (typeof time === 'boolean' && time)
          || (typeof date === 'boolean' && date)
          || hasTrueValue(date)
          || (typeof time !== 'boolean' && time?.some((item) => hasTrueValue(item)))
      ) {
        return [...acc, index];
      }

      return acc;
    }, []) || [];

    const deletedPromises = deletedIds
      .map((item) => currentDeleteTimeFrame({
        orgId,
        eventId: +eventId,
        timeFrameId: item,
      }));

    const deletedRes = await Promise.all(deletedPromises);

    handlePromiseResWithErrors(
      deletedRes,
      (message) => toast.error(message),
    );

    const updatedPromises = updatedIndex
      .map((index) => times[index])
      .filter((item) => item?.frameId && !deletedIds.includes(item.frameId))
      .map((item) => currentUpdateTimeFrame({
        orgId,
        eventId: +eventId,
        timeFrameId: item.frameId as number,
        startAt: item.startAt,
        endAt: item.endAt,
      }));

    const createdPromises = times
      .filter(({ frameId }) => !frameId)
      .map((item) => currentCreateTimeFrame({
        orgId,
        eventId: +eventId,
        playRuleId: values.editingId || 0,
        startAt: item.startAt,
        endAt: item.endAt,
      }));

    await Promise.all([
      currentUpdatePlay({
        orgId,
        eventId: +eventId,
        timeBetweenGames: values.timeBetweenGames * SECONDS_IN_MINUTE,
      }),
      ...updatedPromises,
      ...createdPromises,
    ]).then((res) => {
      handlePromiseResWithErrors(
        res,
        (message) => toast.error(message),
        () => {
          navigate(nextPage.replace(':eventId', eventId));
          reset();
        },
      );
    });
  };

  const handleNewSubmission = async (
    values: TTimeFrameFormSchema,
    times: (IPoolPlayTimeFrame & { frameId?: number | null })[],
  ) => {
    await currentCreatePlay({
      orgId,
      eventId: +eventId,
      timeFrames: times,
      timeBetweenGames: values.timeBetweenGames * SECONDS_IN_MINUTE,
    }).unwrap()
      .then(() => {
        navigate(nextPage.replace(':eventId', eventId));
        reset();
      })
      .catch((error) => {
        toast.error(getErrorMessage(error));
      });
  };

  const handleCreateSubmit = handleSubmit(async (values) => {
    const isEditing = !!values.editingId;
    const times = values.timeFrames
      .reduce<(IPoolPlayTimeFrame & { frameId?: number | undefined })[]>((acc, item) => {
      const [startTime, endTime] = item.time || [];
      const startTimeHour = startTime?.get('hour');
      const startTimeMinute = startTime?.get('minute');
      const endTimeHour = endTime?.get('hour');
      const endTimeMinute = endTime?.get('minute');

      const startAt = item.date
        ?.set({ hour: startTimeHour, minute: startTimeMinute }).toISO() || null;
      const endAt = item.date
        ?.set({ hour: endTimeHour, minute: endTimeMinute }).toISO() || null;

      return [...acc, { frameId: item.frameId || undefined, startAt, endAt }];
    }, []);

    if (isEditing) {
      await handleEditingSubmission(values, times);
    } else {
      await handleNewSubmission(values, times);
    }
  });

  const handleBackClick = () => {
    navigate(AppRoutes.singleEventSchedule.replace(':eventId', eventId));
  };

  const handleValidateDate = useDebouncedCallback((index: number) => {
    const selectedDate = getValues(`timeFrames.${index}.date`);
    const formatedMinDate = minDate?.set({ hour: 0, minute: 0, millisecond: 0 });
    const formatedMaxDate = maxDate?.set({ hour: 0, minute: 0, millisecond: 0 });

    if (formatedMinDate && formatedMaxDate && selectedDate) {
      if (selectedDate < formatedMinDate) {
        setValue(`timeFrames.${index}.date`, formatedMinDate);
      }
      if (selectedDate > formatedMaxDate) {
        setValue(`timeFrames.${index}.date`, formatedMaxDate);
      }
    }
    trigger(`timeFrames.${index}.date`);
  }, 500);

  useEffect(() => {
    if (currentPlayRules?.data) {
      const sortedTimeFrames = [...currentPlayRules.data.timeFrames]
        .sort((firstTimeFrame, secondTimeFrame) => (
          new Date(firstTimeFrame.startAt).getTime() - new Date(secondTimeFrame.startAt).getTime()
        ));
      reset({
        editingId: currentPlayRules.data.id,
        timeFrames: sortedTimeFrames.map((item) => ({
          frameId: item.id,
          date: DateTime.fromISO(item.startAt).setZone(currentPlayRules.timezone),
          time: [
            DateTime.fromISO(item.startAt).setZone(currentPlayRules.timezone),
            DateTime.fromISO(item.endAt).setZone(currentPlayRules.timezone),
          ],
        })),
        timeBetweenGames: (currentPlayRules.data.timeBetweenGames / SECONDS_IN_MINUTE) || undefined,
      });
    }
  }, [currentPlayRules, reset, type]);

  return (
    <TimeFrameView
      type={type}
      control={control}
      timeFrames={timeFrames}
      maxDate={maxDate}
      minDate={minDate}
      onCreateSubmit={handleCreateSubmit}
      onAppendTimeFrame={handleAppendTimeFrame}
      onRemoveTimeFrame={handleRemoveTimeFrame}
      onBackClick={handleBackClick}
      onValidateDate={handleValidateDate}
      isSubmitting={isSubmitting}
      isLoading={isPPRulesLoading || isPORulesLoading}
    />
  );
}

export default TimeFrame;
