import { darken } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Box from '@mui/material/Box';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import BracketIcon from '../../../components/Icons/BracketIcon';
import { ScheduleView } from '../../../constants/general';
import type IScheduleTabs from '../interfaces/IScheduleTabs';

function ScheduleTabs({ view, onChange }: IScheduleTabs) {
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: ScheduleView) => {
    onChange(newValue);
  };

  return (
    <Box>
      <ToggleButtonGroup
        value={view}
        onChange={handleChange}
        aria-label="basic tabs example"
        sx={(theme) => ({
          gap: 0.25,
          px: 0.5,
          py: 0.25,
          bgcolor: darken(theme.palette.secondary.main, 0.5),
          borderRadius: theme.rounded.small,
          border: 0,
          '& .MuiToggleButtonGroup-grouped': {
            border: 0,
            height: 36,
            px: 1,
            py: 0.5,
            gap: 0.25,
            '&.Mui-selected, &.Mui-disabled': {
              bgcolor: theme.palette.primary.contrastText,
              color: theme.palette.primary.dark,
            },
            '&:not(:first-of-type)': {
              borderRadius: theme.rounded.small,
            },
            '&:first-of-type': {
              borderRadius: theme.rounded.small,
            },
          },
        })}
        exclusive
      >
        <ToggleButton
          value={ScheduleView.bracket}
          aria-label="left aligned"
          disabled={view === ScheduleView.bracket}
        >
          <BracketIcon />
          {t('schedule.bracket')}
        </ToggleButton>
        <ToggleButton
          value={ScheduleView.calendar}
          aria-label="centered"
          disabled={view === ScheduleView.calendar}
        >
          <CalendarTodayIcon />
          {t('schedule.calendar')}
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}

export default ScheduleTabs;
