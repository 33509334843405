export default {
  noDivisions: 'No divisions',
  sale: '% Sale',
  deleteSuccess: '"{{eventName}}" event was deleted',
  ADULT: 'Adult',
  CHILD: 'Child',
  SENIOR: 'Senior',
  discountedPriceUntil: 'Discounted Price until {{date}}',
  addDate: '+ add date',
  replaceDates: 'Replace Dates',
  currentDate: 'Current date',
  newDate: 'New date',
  Co_ed: 'Co_ed',
  Male: 'Male',
  Female: 'Female',
  all: 'All',
  adult: 'Adult',
  today: 'Today',
  thisWeek: 'This Week',
  nextWeek: 'Next Week',
  name: 'Name',
  date: 'Date',
  division: 'Division',
  earn: 'Earn',
  registration: 'Registration',
  status: 'Status',
  upcoming: 'Upcoming',
  overview: 'Overview',
  eventDetails: 'Event details',
  analytics: 'Analytics',
  tickets: 'Tickets',
  games: 'Games',
  divisionsAndTeams: 'Divisions & Teams',
  registrationDate: 'Registration date',
  invitationDate: 'Invitation date',
  ticketType: 'Ticket type',
  address: 'Address',
  cityAndState: 'City, State',
  organization: 'Organization',
  waiver: 'Waiver',
  hotelsAndPartners: 'Hotels & Partners',
  coach: 'Coach',
  number: 'Number',
  netSales: 'Net sales',
  day: 'Day',
  week: 'Week',
  month: 'Month',
  salesByTickets: 'Sales by tickets',
  teams: 'Teams',
  spectators: 'Spectators',
  resend: 'Resend',
  checkIns: 'Check-ins',
  inviteTeam: '+ Invite team',
  invite: '+ Invite',
  createEvent: 'Create an event',
  topic: 'Topic',
  topicName: 'Topic name',
  eventTitle: 'Event title',
  details: 'Details',
  age: 'Age',
  grade: 'Grade',
  gender: 'Gender',
  type: 'Type',
  about: 'About',
  dates: 'Dates',
  startDate: 'Start date',
  endDate: 'End date',
  hotels: 'Hotels',
  addNew: '+ Add New',
  social: 'Social',
  partners: 'Partners',
  pendingInvites: 'Pending invites',
  socialNetworks: 'Social networks',
  totalSellings: 'Total sellings',
  teamsTicketsSold: 'Teams tickets sold',
  totalGamesScheduled: 'Total games scheduled',
  gamesPlayed: 'Games played',
  upcomingGames: 'Upcoming games',
  view: 'View',
  divisions: 'Divisions',
  totalTeamsRegistered: 'Total teams registered',
  finalTicketsSold: 'Final tickets price sold',
  earlyTicketsSold: 'Early tickets price sold',
  ticketsCreated: 'Tickets created',
  ticketsTypes: 'Tickets types',
  createAnEvent: '+ Create an Event',
  cancelCreation: 'Cancel Creation',
  continue: 'Continue',
  back: 'Back',
  next: 'Next',
  teamsTickets: 'Teams tickets',
  availableTickets: 'Available tickets',
  startSellingDate: 'Start selling date',
  endSellingDate: 'End selling date',
  ticketsPrice: 'Tickets price',
  addDivision: '+ Add Division',
  addSalesSettings: '+ Add sales settings',
  addDiscountSettings: '+ Add discount settings',
  spectatorsTickets: 'Spectators tickets',
  addTicketsType: '+ Add tickets type',
  tagline: 'Tagline',
  saveAsDraft: 'Save as draft',
  mainInfo: 'Main info',
  logo: 'Logo',
  addPhoto: '+ Add Photo',
  phone: 'Phone number',
  email: 'Email',
  symbols: 'symbols',
  optional: 'optional',
  dragImageText: 'Drag and drop image or click',
  dragFileText: 'Drag and drop file or click',
  dragImageDesc: 'Image recommendations: 1080x1080px, .jpeg, .jpg or .heic',
  dragImageSecondaryDesc: 'Upload a image in .png or .jpg format and the recommended size',
  dragFileDesc: 'Upload a file in .pdf format',
  contactAndDetails: 'Contact and details',
  eventDates: 'Event Dates',
  startTime: 'Start time',
  endTime: 'End time',
  createNewTopic: '+ create new topic',
  newTopic: 'New topic',
  cancel: 'Cancel',
  create: 'Create',
  link: 'Link',
  website: 'Website',
  addHotel: '+ Add hotel',
  addPartner: '+ Add partner',
  filters: 'Filters',
  reset: 'Reset',
  none: 'None',
  waivers: 'Waivers',
  document: 'Document',
  title: 'Title',
  time: 'Time',
  MALE: 'Male',
  FEMALE: 'Female',
  CO_ED: 'Co-ed',
  discountedTicketPrice: 'Early discounted ticket price',
  discountSalesEndsOn: 'Early discount sale ends on',
  salesSettings: 'Sales settings',
  discountSettings: 'Discount settings',
  removeSales: 'Remove sales',
  removeDiscount: 'Remove discount',
  finalTicketPrice: 'Final ticket price',
  teamLimit: 'Team limit',
  ageValidationDateDesc: 'Players age will be based on the age they will be on Cutoff date',
  ageValidationDate: 'Age Cutoff date',
  eventStartDate: 'Event Start date',
  eventEndDate: 'Event End date',
  teamRegistrationEnds: 'Team registration ends on',
  precreationOfDivisions: 'Precreation of divisions',
  save: 'Save',
  skip: 'Skip',
  signed: 'Signed',
  pending: 'Pending',
  file: 'File',
  athlete: 'Athlete',
  signatures: 'Signatures',
  signature: 'Signature',
  publish: 'Publish',
  schedule: 'Schedule',
  stayHere: 'Stay here',
  leaveWithoutSave: 'Leave without saving',
  leaveModalTitle: 'If you want to preserve your modifications on this step, simply click "Save" below.',
  leaveModalDesc: 'You also can change any data later.',
  ticketPrice: 'Ticket price',
  ageRange: 'Age range',
  childTicket: 'Child ticket',
  seniorTicket: 'Senior ticket',
  additionalOptions: 'Additional options',
  generalAdmission: 'General Admission',
  discountSalesStopOn: 'Discount sales stop on',
  wholeTournamentPass: 'Whole tournament pass',
  oneDayPass: '1 day pass',
  tournamentDays: 'Tournament days',
  hotelBookings: 'Hotel bookings',
  editHotels: 'Edit Hotels',
  editPartners: 'Edit Partners',
  editWaivers: 'Edit Waivers',
  editFiles: 'Edit Files',
  editTeamTickets: 'Edit teams tickets',
  editSpectatorFirstHint: 'If you edit and save the changes for a published event, the changes will be immediately displayed.',
  editSpectatorSecondHint: 'If you remove a spectator ticket type it will influence future purchases only. The sold tickets won’t be refunded.',
  editSpectatorThirdHint: 'If you remove or change the date(s) of Event, then a refund will be issued to purchasers for the date(s).',
  editSpectatorFourthHint: 'Changes to pricing or discount settings will influence the future purchases only.',
  editTeamTicketsFirstHint: 'If you make and save the changes for a published event, the changes will be immediately displayed to the users.',
  editTeamTicketsSecondHint: 'If you delete a division and save the changes after any team is already registered to the event, then they will be given a refund.',
  editTeamTicketsThirdHint: 'If you change any requirements related to athletes eligibility and they become ineligible as result they won’t be permitted to the event. The coaches will be notified that some changes were made and they need to review participation statuses of the athletes.',
  waiverDialogDeleteTitle: 'Are you sure you want to delete this waiver?',
  waiverHint: 'Once the waiver is deleted, all signatures will reset to zero. This action cannot be undone.',
  secondWaiverHint: 'If you add another waiver later, then the players will be notified and required to have it signed to be permitted to a tournament.',
  fileDialogDeleteTitle: 'Are you sure you want to delete this file?',
  fileHint: 'Once the file is deleted, this action cannot be undone.',
  secondFileHint: 'If you add another file, then it will be immediately displayed on the event page and users will be able to access it.',
  eventProfile: 'Event profile',
  signedDate: 'Signed date',
  signedBy: 'Signed by',
  waiverLink: 'Waiver (Link)',
  teamsCount: 'Teams ({{count}})',
  registeredTeams: 'Registered teams',
  invitedTeams: 'Invited teams',
  divisionInfo: '{{age}}, {{limit}} teams limit',
  documents: 'Documents',
  rules: 'Files',
  editEvent: 'Edit event info',
  waiverCount: 'Waiver {{number}}',
  rulesCount: 'File {{number}}',
  refundPolicyTitle: 'Team tickets refund policy',
  refundPolicyLabel: 'Refund policy',
  registrationStops: 'Team registration stop on {{date}}',
  divisionTableInfo: '{{min}} to {{max}}',
  divisionTableInfoSingle: '{{min}}',
  teamTicketsSold: 'Team tickets sold',
  earnedTeamsTickets: 'Earned on teams tickets',
  spectatorsTicketsSold: 'Spectators tickets sold',
  earnedSpectatorsTickets: 'Earned on spectators tickets',
  editDivisionsAlert: "Once the division/gender value(s) is changed, registered teams will face inconsistencies. This action cannot be undone. If you chose another value, ineligible athletes/teams won't be permitted to Tournament Event",
  requiredFieldsHintTitle: 'You must enter info into this field to publish event:',
  publishError: 'Validation error: Check the warning message below.',
  publishSuccess: '"{{eventName}}" event published!',
  duplicateSuccess: '"{{eventName}}" event was duplicated',
  cancelSuccess: '"{{eventName}}" event was canceled',
  changesSaved: 'Changes was saved successfully!',
  teamRegistration: 'Team Registration',
  discountDeleted: 'Discount was deleted',
  eligibility: 'Eligibility',
  limitOfCoaches: 'Limit of coaches',
  limitOfCoachesHint: 'If you leave it empty then all team coaches can come to the event with their team for free.',
  limitOfCoachesEditHint: "Minimum coach limit {{limit}}, the current amount of registered coaches in one of the teams. You can check this team's division, delete the division, but a refund will be issued for each team.",
  limitOfAthletes: 'Limit of athletes',
  limitOfAthletesHint: 'If you leave it empty, then a coach will be able to add unlimited athletes quantity to the team.',
  limitOfAthletesEditHint: "Minimum athletes limit {{limit}}, the current amount of registered athletes in one of the teams. You can check this team's division, delete the division, but a refund will be issued for each team.",
  divisionTeamLimitHint: 'Minimum team limit {{limit}}, the current amount of registered teams. You can delete the division but a refund will be issued for each team',
  startSellingSpectators: "Spectators' tickets start selling",
  endSellingSpectators: "Spectators' tickets end selling",
  availableTicketsLimitHint: 'Minimum tickets limit {{limit}}, the current amount of purchased tickets.',
  cancelEventTitle: 'Are you sure you want to cancel this event?',
  cancelEventUndone: 'Once the Tournament Event is canceled, this action cannot be undone.',
  cancelEventRefund: 'If you cancel the event, then changes will be immediately saved. Teams and spectators who have already registered and bought tickets to the event will be issued a refund',
  deleteEventTitle: 'Are you sure you want to delete this event?',
  deleteEventUndone: 'Once the Tournament Event is deleted, this action cannot be undone.',
  deleteEventRefund: 'If you delete the event, it will be removed from the database and will no longer appear on the Tournament Events List page, and you will lose access to it.',
  multipleTeamsDiscount: 'Multiple teams discount',
  multipleTeamsDiscountHint: 'The discount amount applies to each team once minimum team quantity is registered.',
  multipleTeamsDiscountSecondHint: 'If you leave the fields empty, then there will be no discount for quantity.',
  discountPerTeam: 'Discount per team',
  minTeamQuantity: 'Discount applies after teams qty',
  ageRequired: 'Age required',
  multipleTeamsRegDiscount: 'Multiple teams registration discount',
  multipleTeamsRegDiscountInfo: 'This discount of {{amount}} applies to each additional club team after reaching the minimum required number of {{number}} teams.',
  publishingError: 'Dates in {{event}} in the past',
  divisionDialogDeleteTitle: 'Are you sure you want to delete this division?',
  divisionDialogDeleteHint: 'Once the division is deleted, this action cannot be undone.',
  divisionDialogDeleteSecondHint: 'If you delete the division after any team has already registered to the event, then they will be issued a refund.',
  discountDialogDeleteTitle: 'Are you sure you want to delete this discount settings?',
  discountDialogDeleteHint: 'Once the this discount settings are deleted, this action cannot be undone.',
  discountDialogDeleteSecondHint: 'If you delete the discount settings, then the next buyers will be required to pay the standard price.',
  ticketCategoryDialogDeleteTitle: 'Are you sure you want to delete this tickets category?',
  ticketCategoryDialogDeleteHint: 'Once the tickets category is deleted, this action cannot be undone.',
  ticketCategoryDialogDeleteSecondHint: 'If you delete the tickets category and save the changes after spectators have already purchased tickets to the event, then the next buyers will buy with updated conditions.',
  eventDatesHint: 'Event date changes affect other event dates. For editing these and another dates open ',
  eventDatesEditPage: 'Event Dates Edit page',
  duplicatedDate: 'Duplicated date',
  teamId: 'Team ID',
  teamName: 'Team name',
  clubName: 'Club name',
  apply: 'Apply',
  addressComment: 'Venue',
  totalEarned: 'Total earned',
  earnedTEAM: 'Earned for team tickets',
  earnedSPECTATOR: 'Earned for spectators',
  earnedVERIFICATION: 'Earned for verifications',
  eventRevenue: 'Event revenue',
  teamRegistrationPerDevision: 'Team registrations per divisions',
  teamTickets: 'Team tickets',
  teamsAmount: 'Amount of teams',
  teamsAthletesAmount: 'Amount of athletes in teams',
  teamsCoachesAmount: 'Amount of coaches in teams',
  registeredTeamsCount: 'Registered teams {{count}}/{{total}}',
  fullPrice: 'Full price',
  multipleDiscount: 'Multiple team tickets discount',
  discountedTeamTickets: 'Discounted team tickets',
  discountedAndMultiple: 'Discounted tickets in multiple sale',
  spectatorTickets: 'Spectator tickets',
  adultDiscounted: 'Adult discounted',
  seniorDiscounted: 'Senior discounted',
  childDiscounted: 'Child discounted',
  soldSpectator: 'Sold spectator tickets',
  refunds: 'Refunds',
  refundTo: 'Refund is given to',
  refundType: 'Automatic/manual refund',
  refundAmount: 'Refunded amount',
  team: 'Team',
  waiverStatus: 'Waiver status',
  discount: 'Discount',
  option: 'Option',
  off: 'OFF',
  subsequent: 'SUBSEQUENT',
  simultaneous: 'SIMULTANEOUS',
  offOption: 'No multiple teams discount.',
  subsequentOption: 'The discount applies to each additional team once the minimum team quantity is registered at full price.',
  simultaneousOption: 'The discount amount applies to each team after selecting the required team quantity if they are registered simultaneously. Several discount options can be selected.',
  standings: 'Standings',
  initialDate: 'Initial date: {{date}}',
  guardian: 'Guardian',
  verificationStatus: 'Verification status',
  verified: 'Verified',
};
