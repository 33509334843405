import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import SplashScreen from '../../components/SplashScreen/SplashScreen';
import SingleEventNotificationsView from './SingleEventNotificationsView';
import { notificationsSchema } from '../../utils/validators';
import { NOTIFICATION_TYPE, RECIPIENTS_TYPE } from '../../constants/general';
import { useAppDispatch, useAppSelector } from '../../store/hooks/useApp';
import { selectUserOrg } from '../../store/slices/user/selectors';
import {
  useCreateNotificationMutation,
  useGetNotificationsQuery,
} from '../../store/slices/notifications/api/notificationsApi';
import useTablePagination from '../../hooks/useTablePagination';
import { getErrorMessage } from '../../utils/helpers';
import { toggleSubmitDialog } from '../../store/slices/notifications/slice';
import type { TNotificationType, TRecipientsType } from '../../constants/general';
import type INotificationsFields from './interfaces/INotificationsFields';

function SingleEventNotifications() {
  const { t } = useTranslation();
  const { eventId } = useParams();
  const dispatch = useAppDispatch();
  const organization = useAppSelector(selectUserOrg);
  const pagination = useTablePagination();

  const { data: notifications, isLoading: isNotificationsLoading } = useGetNotificationsQuery(
    organization?.id && eventId ? {
      orgId: organization.id,
      eventId: +eventId,
      page: pagination.page + 1,
      perPage: pagination.perPage,
    } : skipToken,
  );
  const [createNotification, { isLoading: isSubmitting }] = useCreateNotificationMutation();

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    reset,
  } = useForm<INotificationsFields>({
    mode: 'onBlur',
    resolver: yupResolver<INotificationsFields>(notificationsSchema),
    defaultValues: {
      recipients: {
        all: true,
        team: false,
        coach: false,
        athleteAndGuardian: false,
        organizationMember: false,
        spectator: false,
      },
      recipientsValues: ['ALL'],
      type: NOTIFICATION_TYPE.REGULAR,
      title: '',
      message: '',
    },
  });

  const handleSelectRecipient = (value: TRecipientsType) => {
    const recipientsValues = getValues('recipientsValues');

    if (recipientsValues.includes(value)) {
      setValue('recipientsValues', recipientsValues.filter((item) => item !== value));
    } else {
      setValue('recipientsValues', [...recipientsValues, value]);
    }
  };

  const handleValidateRecipients = () => {
    const recipientsValues = getValues('recipientsValues');

    if (recipientsValues[recipientsValues.length - 1] === RECIPIENTS_TYPE.ALL) {
      setValue('recipientsValues', [RECIPIENTS_TYPE.ALL]);
    } else if (recipientsValues.length === Object.keys(RECIPIENTS_TYPE).length - 1) {
      setValue('recipientsValues', [RECIPIENTS_TYPE.ALL]);
    } else if (recipientsValues.length > 0) {
      setValue('recipientsValues', recipientsValues.filter((item) => item !== RECIPIENTS_TYPE.ALL));
    } else {
      setValue('recipientsValues', [RECIPIENTS_TYPE.ALL]);
    }
  };

  const handleSelectType = (value: TNotificationType) => {
    const currentType = getValues('type');

    if (currentType !== value) {
      setValue('type', value);
    }
  };

  const handleCreateSubmit = handleSubmit(async (values) => {
    if (organization?.id && eventId) {
      const requestData = {
        orgId: organization?.id,
        eventId: +eventId,
        data: {
          type: values.type,
          recipientTypes: values.recipientsValues,
          subject: values.title,
          message: values.message,
        },
      };

      const response = await createNotification(requestData);

      if (!('error' in response)) {
        toast.success(t('events.changesSaved'), { position: toast.POSITION.TOP_RIGHT });
        dispatch(toggleSubmitDialog(false));
        reset();
      } else {
        toast.error(getErrorMessage(response.error), { position: toast.POSITION.TOP_RIGHT });
      }
    }
  });

  if (isNotificationsLoading) {
    return <SplashScreen />;
  }

  return (
    <SingleEventNotificationsView
      control={control}
      onSelectRecipient={handleSelectRecipient}
      onValidateRecipient={handleValidateRecipients}
      onSelectType={handleSelectType}
      onSubmit={handleCreateSubmit}
      isSubmitting={isSubmitting}
      notifications={notifications?.data || []}
      pagination={{ ...pagination, total: notifications?.meta.total || 0 }}
    />
  );
}

export default SingleEventNotifications;
