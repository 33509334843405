import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useWatch } from 'react-hook-form';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

import { DEFAULT_DATE_FORMAT } from '../../../constants/general';
import SpectatorsDateCard from './SpectatorsDateCard';
import type { IEventSpectatorsDatesSection } from '../interfaces/IEventSpectatorsDatesSection';

export default function EventSpectatorsDatesSection({
  control,
  spectatorsOneDay,
  spectatorsWholeDays,
  tournamentDaysInterval,
  onTournamentDaysClick,
}: IEventSpectatorsDatesSection) {
  const { t } = useTranslation();
  const [isDaysOpen, setIsDaysOpen] = useState<boolean>(false);
  const startAt = useWatch({ control, name: 'startEventDate' });
  const endAt = useWatch({ control, name: 'endEventDate' });
  const isPublished = useWatch({ control, name: 'isPublished' });

  return (
    <>
      <Divider sx={{ my: 4.25 }} />
      <Grid container rowSpacing={3} columnSpacing={5}>
        <Grid item xs={12}>
          <Typography component="h4" variant="h6">
            {t('events.spectatorsTickets')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="tournamentDays"
            control={control}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error: fieldError },
            }) => (
              <FormControl error={!!fieldError} fullWidth>
                <InputLabel id="multiple-days-label">
                  {t('events.tournamentDays')}
                </InputLabel>
                <Select
                  id="multiple-days"
                  labelId="multiple-days-label"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  open={isDaysOpen}
                  label={t('events.tournamentDays')}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {selected.map((item) => (
                        <Chip key={item} label={item} sx={{ px: 0.5, height: 24 }} />
                      ))}
                    </Box>
                  )}
                  onOpen={() => {
                    if (!isPublished) {
                      setIsDaysOpen(true);
                    }
                  }}
                  onClose={() => {
                    if (!isPublished) {
                      setIsDaysOpen(false);
                    }
                  }}
                  onClick={onTournamentDaysClick}
                  multiple
                >
                  {tournamentDaysInterval.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={value.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
                {fieldError?.message ? <FormHelperText>{fieldError.message}</FormHelperText> : null}
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="startSellingDate"
            control={control}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error: fieldError },
            }) => (
              <DatePicker
                label={t('events.startSellingDate')}
                value={value}
                onChange={onChange}
                onClose={onBlur}
                maxDate={endAt}
                slotProps={{
                  textField: {
                    error: !!fieldError,
                    helperText: fieldError?.message,
                    fullWidth: true,
                    onBlur,
                  },
                }}
                format={DEFAULT_DATE_FORMAT}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="endSellingDate"
            control={control}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error: fieldError },
            }) => (
              <DatePicker
                label={t('events.endSellingDate')}
                value={value}
                onChange={onChange}
                onClose={onBlur}
                minDate={startAt}
                maxDate={endAt}
                slotProps={{
                  textField: {
                    error: !!fieldError,
                    helperText: fieldError?.message,
                    fullWidth: true,
                    onBlur,
                  },
                }}
                format={DEFAULT_DATE_FORMAT}
              />
            )}
          />
        </Grid>

        {spectatorsOneDay.length ? (
          <Grid item xs={12}>
            <Stack spacing={2}>
              <Typography component="h4" variant="h6">
                {t('events.oneDayPass')}
              </Typography>
              {spectatorsOneDay.map(({ type }, index) => (
                <SpectatorsDateCard
                  key={type}
                  control={control}
                  type={type}
                  index={index}
                  name="spectatorsOneDay"
                />
              ))}
            </Stack>
          </Grid>
        ) : null}

        {spectatorsWholeDays.length ? (
          <Grid item xs={12}>
            <Divider sx={{ mt: 2, mb: 3 }} />
            <Stack spacing={2}>
              <Typography component="h4" variant="h6">
                {t('events.wholeTournamentPass')}
              </Typography>
              {spectatorsWholeDays.map(({ type }, index) => (
                <SpectatorsDateCard
                  key={type}
                  control={control}
                  type={type}
                  index={index}
                  name="spectatorsWholeDays"
                />
              ))}
            </Stack>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}
