export default {
  scoreForSeedingInfo: 'Score for seeding is calculated by awarding: 3 points for a win, 1 point for a tie, 0 points for a loss. Teams are sorted by descending Point Difference in case of equal score.',
  poolPlaySeedingTable: 'Pool play seeding table',
  poolPlay: 'Pool play',
  overall: 'Overall',
  seed: 'Seed',
  team: 'Team',
  games: 'Games',
  wins: 'Wins',
  losses: 'Losses',
  ties: 'Ties',
  pointsFor: 'Points for',
  pointsAgainst: 'Points against',
  grtstRound: 'Grtst Round',
  pointDiff: 'Point diff',
  score: 'Score',
};
