import { DateTime } from 'luxon';

import { parseNumberFormattedString } from './helpers';
import type { TSpectatorsTicketPass, TSpectatorsTicketType } from '../constants/general';
import {
  AGE_RANGE,
  CENTS_PER_DOLLAR, SPECTATORS_TICKET_PASS, SPECTATORS_TICKET_TYPE,
} from '../constants/general';
import type {
  ISpectatorsTicketsFields,
  ITicketVariantFields,
} from '../components/SpectatorsTicketsForm/interfaces/ISpectatorsTicketsForm';
import type { ITicketType } from '../store/slices/spectators/interfaces/ISpectatorRuleReq';

type TicketTypeConfig = {
  key: keyof ISpectatorsTicketsFields;
  pass: TSpectatorsTicketPass;
};

const ticketTypeConfigs: TicketTypeConfig[] = [
  {
    key: 'oneDayPass',
    pass: SPECTATORS_TICKET_PASS.DAY_PASS,
  },
  {
    key: 'wholeTournamentPass',
    pass: SPECTATORS_TICKET_PASS.WHOLE_PASS,
  },
];

export const createTicketType = (
  typeConfig: ITicketVariantFields,
  ageType: TSpectatorsTicketType,
  pass: TSpectatorsTicketPass,
  timezone?: string,
): ITicketType => {
  const ticketType: ITicketType = {
    id: typeConfig.id as number,
    price: parseNumberFormattedString(typeConfig.price as string) * CENTS_PER_DOLLAR,
    ageRange: typeConfig.ageRange,
    type: ageType,
    isDiscountDeleted: typeConfig?.isDiscountDeleted || undefined,
    pass,
  };

  if (typeConfig.discount && typeConfig.isDiscount) {
    ticketType.discount = {
      id: typeConfig.discount.id || undefined,
      price: parseNumberFormattedString(typeConfig.discount.price) * CENTS_PER_DOLLAR,
      endAt: DateTime.fromJSDate(typeConfig.discount.endAt as Date)
        .setZone(timezone)
        .endOf('day').toISO() as string,
    };
  }

  return ticketType;
};

export const genTicketType = (values: ISpectatorsTicketsFields, timezone?: string) => ticketTypeConfigs.reduce<{
  newTicketTypes: ITicketType[],
  removeTicketTypes: number[]
}>(
  (acc, config) => {
    const newTicketTypes = [...acc.newTicketTypes];
    const removeTicketTypes = [...acc.removeTicketTypes];

    if ((config.key === 'oneDayPass' && values.isOneDayPass) || (config.key === 'wholeTournamentPass' && values.isWholeTournamentPass)) {
      const typeConfig = values[config.key] as ITicketVariantFields;

      if (typeConfig) {
        newTicketTypes.push(createTicketType(typeConfig, SPECTATORS_TICKET_TYPE.ADULT, config.pass, timezone));

        if (typeConfig.isChildTicket && typeConfig.childTicket) {
          newTicketTypes
            .push(createTicketType(typeConfig.childTicket, SPECTATORS_TICKET_TYPE.CHILD, config.pass, timezone));
        } else if (typeConfig.childTicket?.id) {
          removeTicketTypes.push(typeConfig.childTicket?.id);
        }

        if (typeConfig.isSeniorTicket && typeConfig.seniorTicket) {
          newTicketTypes
            .push(createTicketType(typeConfig.seniorTicket, SPECTATORS_TICKET_TYPE.SENIOR, config.pass, timezone));
        } else if (typeConfig.seniorTicket?.id) {
          removeTicketTypes.push(typeConfig.seniorTicket?.id);
        }
      }
    } else if ((config.key === 'oneDayPass' && !values.isOneDayPass) || (config.key === 'wholeTournamentPass' && !values.isWholeTournamentPass)) {
      const typeConfig = values[config.key] as ITicketVariantFields;
      const removeArray = [
        typeConfig?.id, typeConfig.childTicket?.id, typeConfig.seniorTicket?.id,
      ].filter(Boolean) as number[];

      removeTicketTypes.push(...removeArray);
    }

    return { newTicketTypes, removeTicketTypes };
  },
  { newTicketTypes: [], removeTicketTypes: [] },
);

export const parseTicketTypeRules = (
  ticketType: ITicketType[],
  timezone?: string,
): ITicketVariantFields => {
  const defaultTicketType = {
    id: null,
    price: '',
    ageRange: AGE_RANGE.ADULT,
    discount: null,
    isDiscount: false,
    isChildTicket: false,
    isSeniorTicket: false,
    childTicket: {
      id: null,
      price: '',
      ageRange: AGE_RANGE.CHILD,
      isDiscount: false,
      discount: null,
    },
    seniorTicket: {
      id: null,
      price: '',
      ageRange: AGE_RANGE.SENIOR,
      isDiscount: false,
      discount: null,
    },
  };

  const transformedObject = JSON.parse(JSON.stringify(defaultTicketType));

  ticketType.forEach((item) => {
    if (item.type === SPECTATORS_TICKET_TYPE.ADULT) {
      transformedObject.id = item.id;
      transformedObject.price = item.price.toString();
      transformedObject.ageRange = item.ageRange || '';
      transformedObject.isDiscount = item.discount !== null;
      if (item.discount) {
        transformedObject.discount = {
          id: item.discount.id,
          price: item.discount.price.toString(),
          endAt: DateTime.fromISO(item.discount.endAt).setZone(timezone, {
            keepLocalTime: !item.discount,
          }),
        };
      }
    } else if (item.type === SPECTATORS_TICKET_TYPE.CHILD) {
      transformedObject.isChildTicket = true;
      transformedObject.childTicket.id = item.id;
      transformedObject.childTicket.price = item.price.toString();
      transformedObject.childTicket.ageRange = item.ageRange || '';
      transformedObject.childTicket.isDiscount = item.discount !== null;
      if (item.discount) {
        transformedObject.childTicket.discount = {
          id: item.discount.id,
          price: item.discount.price.toString(),
          endAt: DateTime.fromISO(item.discount.endAt).setZone(timezone, {
            keepLocalTime: !item.discount,
          }),
        };
      }
    } else if (item.type === SPECTATORS_TICKET_TYPE.SENIOR) {
      transformedObject.isSeniorTicket = true;
      transformedObject.seniorTicket.id = item.id;
      transformedObject.seniorTicket.price = item.price.toString();
      transformedObject.seniorTicket.ageRange = item.ageRange || '';
      transformedObject.seniorTicket.isDiscount = item.discount !== null;
      if (item.discount) {
        transformedObject.seniorTicket.discount = {
          id: item.discount.id,
          price: item.discount.price.toString(),
          endAt: DateTime.fromISO(item.discount.endAt).setZone(timezone, {
            keepLocalTime: !item.discount,
          }),
        };
      }
    }
  });

  return transformedObject;
};
