import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import EditOutlined from '@mui/icons-material/EditOutlined';

import PaperBlock from '../../../components/PaperBlock/PaperBlock';
import PartnerCard from '../../../components/PartnerCard/PartnerCard';
import EmptyData from '../../../components/EmptyData/EmptyData';
import { getRelativePath } from '../../../utils/helpers';
import AppRoutes from '../../../constants/AppRoutes';
import type { IPartnersWidget } from '../interfaces/IPartnersWidget';

function PartnersWidget({ partners = [] }: IPartnersWidget): React.ReactElement {
  const { t } = useTranslation();
  const { eventId } = useParams();

  return (
    <PaperBlock
      title={t('events.partners')}
      endAdornment={(
        <Button
          startIcon={<EditOutlined />}
          component={Link}
          to={`/events/${eventId}${getRelativePath(AppRoutes.singleEventPartners, 'route')}`}
          size="small"
        >
          {t('common.edit')}
        </Button>
      )}
      whiteBg
      noMargin
    >
      <Stack spacing={3}>
        {partners.length ? partners.map((item) => (
          <PartnerCard
            key={`PartnersWidgetList_${item.id}`}
            link={item.link}
            title={item.name}
            logo={item.cover}
            isSmall
          />
        )) : <EmptyData description={t('common.noData')} />}
      </Stack>
    </PaperBlock>
  );
}

export default PartnersWidget;
