export default {
  noFieldsInListText: 'You can add fields that will be available for all Pool Play games of the tournament and selected some as preferred for this division here.',
  fieldName: 'Field name',
  fieldAddress: 'Field Address',
  assignedStatisticians: 'Assigned Statisticians',
  poolKey: 'Pool {{key}}',
  noFieldsInList: 'No Fields in list',
  addStatistician: '+ add a statistician',
  addField: '+ Add a field',
  timeFrame: 'Time frame',
  playOff: 'Play off',
  poolPlay: 'Pool play',
  noGamesScheduledYet: 'No games scheduled yet',
  createTournamentPoolPlaySchedule: 'Create tournament pool play schedule',
  createTournamentPlayOffSchedule: 'Create tournament play off schedule',
  logisticsAndGameSetup: 'Logistics & Game Set up ',
  schedule: 'Schedule',
  timeFramePoolPlayTitle: 'Pool Play Time Frame Customization',
  timeFramePlayOffTitle: 'Play Off Time Frame Customization',
  timeSlotsAvailableForSchedule: 'Time slots available for schedule',
  timeBetweenGames: 'Time between games',
  timeBetweenGamesPlaceholder: '0 to 90 minutes',
  timeFrameAlert: 'Add a time margin at the end of each game to allow teams on the field to change, rest or travel between fields.',
  unscheduled: 'Unscheduled ({{count}})',
  poolPlaySchedule: 'Pool Play Schedule',
  gameDetails: 'Game Details',
  team: 'Team',
  field: 'Field',
  statistician: 'Statistician',
  participants: 'Participants ({{division}})',
  poolPlayGameSettings: '{{divisionName}} Pool Play Game settings',
  playOffGameSettings: '{{divisionName}} Play Off Game settings',
  gamesPerTeam: 'Games per team',
  preferredFields: 'Preferred fields',
  statKeeperGameSettings: 'StatKeeper Game Settings',
  championshipGameSettings: 'Championship game StatKeeper Game Settings  ',
  type: 'Type',
  halfTime: 'Half-time',
  gameDuration: 'Game duration',
  overtime: 'Overtime',
  poolPlayGamesPerTeam: 'Pool Play Games per team',
  registeredTeamsInDivision: 'Registered Teams in the division',
  generatedPoolsInDivision: 'Generated Pools in the division',
  divisionGameSettings: 'Division game settings',
  divisionGameSetup: 'Division Game Set up',
  divisionSetup: 'Division set up',
  poolPlayFields: 'Pool play fields ({{total}})',
  playOffFields: 'Play off fields ({{total}})',
  poolPlayStart: 'Pool Plays Start',
  poolPlayEnd: 'Pool Plays End',
  division: 'Division',
  gameSettings: 'Game Settings',
  pools: 'Pools',
  registeredTeams: 'Registered teams',
  fields: 'Fields',
  setupByMyself: 'Setup by myself',
  applyPPGameSettings: 'Apply these Pool Play settings to all divisions?',
  applyPOGameSettings: 'Apply these Play Off settings to all divisions?',
  youCanEditSettings: 'You can edit selected division settings separately before generating the schedule.',
  preferredFieldsNotApply: 'Preferred fields will NOT be applied to the rest of the divisions.',
  wouldApplyPOGameSettingsInfo: '*with enough teams',
  wouldApplyPPGameSettingsInfo: '*if {{gamesPerTeam}} games per team option will not be available for the number of teams in the division, then the next lesser option will be applied',
  wouldApplyPPGameSettings: 'Would you like to apply {{gameSettingName}} StatKeeper Game Settings and {{gamesPerTeam}}* games per team to all divisions in this tournament?',
  wouldApplyPOGameSettings: 'Would you like to apply {{gameSettingName}} StatKeeper Game Settings and {{bool}} 3rd Place Final* to all divisions in this tournament?',
  playOffSchedule: 'Play Off Schedule',
  divisionSetUpFields: 'Division Set up & Fields',
  editFields: 'Edit fields',
  editSchedule: 'Edit schedule',
  thirdPlaceFinal: '3rd Place Final',
  roundNumber: 'Round {{number}}',
  semiFinal: 'Semi final',
  final: 'Final',
  existGamesOutsideTimeFrames: 'Warning: Game(s) may take place outside the desired time frame due to the lack of fields.\n'
    + 'Add fields or add available time slots for scheduling.',
  sameStatisticianForDiffFields: 'Warning: Some fields do have the same Statistician assigned to them. Stats for some games may not be recorded. Add different Statisticians for all fields.',
  notEnoughStatistician: 'Warning: Some fields do not have a Statistician assigned. Stats for the games played there may not be recorded. Add Statisticians for all fields.',
  notEnoughPlayingFields: 'Warning: Game(s) may take place outside the desired time frame due to the lack of fields.'
    + 'Add fields or add available time slots for scheduling.',
  existTeamsNotInSchedule: 'Error: The list of teams in some divisions has changed. Some teams may not have scheduled games, OR their opponent might have been removed. You need to update the division settings AND regenerate the schedule.',
  teamPlayManyGamesOneTime: 'Warning: Some teams OR fields have multiple games scheduled at the same time.',
  playOffStart: 'Play Off Start',
  playOffEnd: 'Play Off End',
  totalRounds: 'Total Rounds',
  bracketGames: 'Bracket games',
  name: 'Name',
  editFieldsForPoolPlay: 'Edit Fields for Pool Play',
  editFieldsForPlayOff: 'Edit Fields for Play Off',
  teamName: 'Team Name',
  statisticians: 'Statisticians',
  date: 'Date',
  regenerate: 'Regenerate',
  saveAndPublish: 'Save and Publish',
  bracketAlertInfo: 'You can schedule Play Off games while opponents are unknown. Once the winner and loser of the previous round are determined, unknown opponents will be automatically updated with the teams that passed to the next round.',
  poolName: 'Pool {{pool}}',
  byeGames: 'Bye games List',
  bracket: 'Bracket',
  calendar: 'Calendar',
  POOL_PLAY: 'Pool play',
  PLAYOFF: 'Play off',
  searchByNameOrLocation: 'Search by name or location',
  unassigned: 'Unassigned',
  existNotScheduledGame: 'Warning: Some game(s) are unscheduled',
  conflictTeamClubs: 'Warning: Teams from the same club are in the same pool',
  updateField: 'Update Pool play games per team field',
};
