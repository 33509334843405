import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { useWatch } from 'react-hook-form';

import SearchInput from '../../../components/SearchInput/SearchInput';
import SignatureFiltersSelect from './SignatureFiltersSelect';
import FiltersPopup from '../../../components/FiltersPopup/FiltersPopup';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import { SIGNATURE_STATUS } from '../../../constants/general';
import type ISignatureFilter from '../interfaces/ISignatureFilter';
import type { ISearchInputHandler } from '../../../components/SearchInput/interfaces/ISearchInput';

const boxStyle = {
  '.MuiPaper-root': {
    background: '#757575',
    padding: '6px 12px',
  },
  '.MuiInputBase-input': {
    padding: '2px',
  },
  '.MuiButtonBase-root': {
    width: '25px',
    height: '25px',
  },
};

export default function SignatureFilters({
  control,
  onSearchChange,
  onApplyFilters,
  onResetFilters,
  onGetTeams,
  onSearchWaiver,
  teamsList,
  waiversList,
}: ISignatureFilter) {
  const { t } = useTranslation();
  const teamsIds = useWatch({ control, name: 'teamsIds' }) || [];

  const debouncedWaiversSearch = useDebouncedCallback(onSearchWaiver);

  const handleWaiversSearchChange = ({ search, onChange }: ISearchInputHandler) => {
    onChange(search);
    debouncedWaiversSearch();
  };

  return (
    <Stack direction="row" spacing={3}>
      <Box sx={{ width: 276 }}>
        <SearchInput control={control} onSearchChange={onSearchChange} />
      </Box>
      <FiltersPopup
        title={t('events.filters')}
        onApplyFilters={onApplyFilters}
        onResetFilters={onResetFilters}
      >
        <Grid item xs={12}>
          <SignatureFiltersSelect
            control={control}
            selectName="waiverId"
            label={t('events.waiver')}
            onOpen={onSearchWaiver}
          >
            <Box
              mb={1}
              px={2}
              sx={boxStyle}
              onKeyDown={(e) => e.stopPropagation()}
            >
              <SearchInput
                control={control}
                name="waiverSearch"
                onSearchChange={handleWaiversSearchChange}
              />
            </Box>
            <MenuItem value="">
              <em>{t('events.none')}</em>
            </MenuItem>
            {waiversList.map((waiver) => (
              <MenuItem key={waiver.id} value={waiver.id}>
                {waiver.title}
              </MenuItem>
            ))}
          </SignatureFiltersSelect>
        </Grid>

        <Grid item xs={12}>
          <SignatureFiltersSelect
            control={control}
            selectName="status"
            label={t('events.waiverStatus')}
          >
            <MenuItem value="">
              <em>{t('events.none')}</em>
            </MenuItem>
            {Object.values(SIGNATURE_STATUS).map((status) => (
              <MenuItem key={status} value={status}>
                {t(`events.${status.toLowerCase()}`)}
              </MenuItem>
            ))}
          </SignatureFiltersSelect>
        </Grid>

        <Grid item xs={12}>
          <SignatureFiltersSelect
            control={control}
            selectName="teamsIds"
            label={t('events.team')}
            onOpen={onGetTeams}
            multiple
            list={teamsList}
          >
            {teamsList.map((team, index) => (
              <MenuItem
                key={[team.id, index].join('_')}
                value={team.id.toString()}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <Checkbox
                    checked={teamsIds.includes(team.id.toString())}
                    sx={{
                      padding: 0,
                    }}
                  />
                  <ListItemText
                    primary={team.name}
                    sx={{
                      paddingLeft: '11px',
                    }}
                  />
                </Stack>
              </MenuItem>
            ))}
          </SignatureFiltersSelect>
        </Grid>
      </FiltersPopup>
    </Stack>
  );
}
