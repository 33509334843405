import { DateTime, Interval } from 'luxon';

import { parseStringOrDateToDateTime } from './dates';
import { getGameError } from './poolPlays';
import { EventRoundTypes } from '../store/slices/schedule/interfaces/IScheduleBracket';
import type { IBracketGame, IEventRound } from '../store/slices/schedule/interfaces/IScheduleBracket';
import type { ITournamentBracketData } from '../components/TournamentBracket/interfaces/ITournamentBracket';
import type ITimeFrame from '../store/slices/poolPlays/interfaces/ITimeFrame';
import type IPOTimeFrame from '../store/slices/playOff/interfaces/IPOTimeFrame';
import type ICheckValidity from '../store/slices/poolPlays/interfaces/ICheckValidity';
import type IGameProps from '../components/TournamentBracket/interfaces/IGame';

const mergeFinals = (data: { round: IEventRound, games: IGameProps['game'][] }[]) => {
  const tempData = [...data];
  const finalRoundIndex = tempData.findIndex((item) => item.round.type === EventRoundTypes.FINAL);
  const thirdPlaceFinalRoundIndex = tempData.findIndex((item) => item.round.type === EventRoundTypes.THIRD_PLACE_FINAL);

  if (finalRoundIndex !== -1 && thirdPlaceFinalRoundIndex !== -1) {
    tempData[finalRoundIndex].games = tempData[finalRoundIndex].games.concat(
      tempData[thirdPlaceFinalRoundIndex].games.map((game) => ({
        ...game,
        position: tempData[thirdPlaceFinalRoundIndex].round.position,
      })),
    );

    tempData.splice(thirdPlaceFinalRoundIndex, 1);
  }

  return tempData;
};

export const generateTournamentBracket = (rounds: IEventRound[], games: IBracketGame[], validity?: ICheckValidity) => {
  const groupedGames = rounds
    .map((round) => ({
      round,
      games: games.filter((game) => game.tOrgEventPlayOffRoundId === round.id),
    }))
    .sort((a, b) => a.round.position - b.round.position)
    .map((item) => ({
      ...item,
      games: item.games.map((game) => ({ ...game, error: getGameError(game.id, validity) })),
    }));

  groupedGames.forEach((group) => {
    group.games.sort((a, b) => a.id - b.id);
  });

  const bracketRounds = mergeFinals(groupedGames);

  const newOrder = bracketRounds.reduce<{
    firstSide: ITournamentBracketData[],
    secondSide: ITournamentBracketData[],
  }>((acc, item) => {
    const midpoint = item.round.type === EventRoundTypes.FINAL ? item.games.length : Math.ceil(item.games.length / 2);
    const [firstHalf, secondHalf] = [item.games.slice(0, midpoint), item.games.slice(midpoint)];
    const second = item.round.type === EventRoundTypes.THIRD_PLACE_FINAL || item.round.type === EventRoundTypes.FINAL
      ? null
      : { ...item, games: secondHalf };

    return {
      firstSide: [...acc.firstSide, { ...item, games: firstHalf }],
      secondSide: second ? [second, ...acc.secondSide] : acc.secondSide,
    };
  }, { firstSide: [], secondSide: [] });

  return [...newOrder.firstSide, ...newOrder.secondSide];
};

export function checkDatesInTimeFrames(
  dateArray: Date[],
  timeFrames: (ITimeFrame | IPOTimeFrame)[],
  timezone?: string,
): boolean {
  return dateArray.some((date) => {
    const parseDate = DateTime.fromJSDate(date);

    return timeFrames.some((frame) => {
      const interval = Interval.fromDateTimes(
        DateTime.fromISO(frame.startAt).setZone(timezone).set({ second: 0 }),
        DateTime.fromISO(frame.endAt).setZone(timezone).set({ second: 0 }),
      );

      return interval.contains(parseDate);
    });
  });
}

export function getTimeFrameByDate(date: Date | string, timeFrames: (ITimeFrame | IPOTimeFrame)[]) {
  return timeFrames.find((frame) => {
    const interval = Interval.fromDateTimes(
      DateTime.fromISO(frame.startAt).set({ second: 0 }),
      DateTime.fromISO(frame.endAt).set({ second: 0 }),
    );

    return interval.contains(parseStringOrDateToDateTime(date));
  });
}
