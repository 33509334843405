import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  radio: {
    position: 'relative',
    padding: '8px 16px',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: theme.transitions.create('border'),
  },
  checkbox: {
    position: 'absolute',
    top: '8px',
    right: '16px',
    width: '18px',
    height: '18px',
    border: '2px solid #757575',
    borderRadius: '50%',
  },
  backdrop: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 0,
    height: 0,
    background: '#02A9F4',
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
    transition: theme.transitions.create(['width', 'height']),
    overflow: 'hidden',
  },
  selected: {
    width: '18px',
    height: '18px',
  },
}));

export default useStyles;
