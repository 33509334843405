import { useTranslation } from 'react-i18next';
import { Controller, useWatch } from 'react-hook-form';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { DEFAULT_DATE_FORMAT, TIME_STEPS_FOR_TIME_PICKER } from '../../../constants/general';
import type { ISpectatorsDateCard } from '../interfaces/ISpectatorsDateCard';

export default function SpectatorsDateCard({
  control,
  name,
  type,
  index,
}: ISpectatorsDateCard) {
  const { t } = useTranslation();
  const maxSalesDate = useWatch({ control, name: 'endSellingDate' });

  return (
    <>
      <Typography component="p" variant="body1">
        {t(`events.${type}`)}
      </Typography>
      <Paper sx={{ p: 3 }}>
        <Typography component="p" variant="body2" mb={2}>
          {t('events.discountSalesEndsOn')}
        </Typography>
        <Grid container rowSpacing={3} columnSpacing={5}>
          <Grid item xs={12} md={6}>
            <Controller
              name={`${name}.${index}.endAt`}
              control={control}
              render={({
                field: { onChange, value, onBlur },
                fieldState: { error: fieldError },
              }) => (
                <DatePicker
                  label={t('events.date')}
                  value={value}
                  onChange={onChange}
                  onClose={onBlur}
                  maxDate={maxSalesDate}
                  slotProps={{
                    textField: {
                      error: !!fieldError,
                      helperText: fieldError?.message,
                      fullWidth: true,
                      onBlur,
                    },
                  }}
                  format={DEFAULT_DATE_FORMAT}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name={`${name}.${index}.endAt`}
              control={control}
              render={({
                field: { onChange, value, onBlur },
                fieldState: { error: fieldError },
              }) => (
                <TimePicker
                  label={t('events.time')}
                  value={value}
                  onChange={onChange}
                  onClose={onBlur}
                  maxTime={maxSalesDate}
                  timeSteps={TIME_STEPS_FOR_TIME_PICKER}
                  slotProps={{
                    textField: {
                      error: !!fieldError,
                      helperText: fieldError?.message,
                      fullWidth: true,
                      onBlur,
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
