import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(
  () => ({
    root: {
      border: 'none',
      width: '100%',
    },
    noBorder: {
      border: 'none',
    },
  }),
);

export default useStyles;
