import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import SingleEventEditView from './SingleEventEditView';
import useEventForm from '../../hooks/useEventForm';

function SingleEventEdit(): React.ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    control,
    handleCancel,
    handleCreateSubmit,
    handleDeleteCoverPhoto,
    isSubmitting,
    handleChangeWithRedirect,
  } = useEventForm({
    navigate: () => {
      navigate(-1);
    },
    onSubmit: () => {
      toast.success(t('events.changesSaved'), { position: toast.POSITION.TOP_RIGHT });
    },
  });

  return (
    <SingleEventEditView
      control={control}
      onCancelEdit={handleCancel}
      onCreateSubmit={handleCreateSubmit}
      onDeleteCoverPhoto={handleDeleteCoverPhoto}
      isSubmitting={isSubmitting}
      onChangeWithRedirect={handleChangeWithRedirect}
    />
  );
}

export default SingleEventEdit;
