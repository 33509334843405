import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import Grid from '@mui/material/Grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';
import { Fragment, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';

import EventPeriod from '../../EventPeriod/EventPeriod';
import { spectatorsReplaceDatesSchema } from '../../../utils/validators';
import { findDuplicatedDates, findEqualDatesInArrays } from '../../../utils/dates';
import { DEFAULT_DATE_FORMAT } from '../../../constants/general';
import type { IReplaceDatesDialog, IReplaceDatesDialogForm } from '../interfaces/IReplaceDatesDialog';

function ReplaceDatesDialog({
  isOpen,
  onClose,
  eventEndDate,
  eventStartDate,
  dates,
  allowedDates,
  onSave,
  isLoading,
}: IReplaceDatesDialog) {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setError,
    clearErrors,
  } = useForm<IReplaceDatesDialogForm>({
    mode: 'onChange',
    resolver: yupResolver<IReplaceDatesDialogForm>(spectatorsReplaceDatesSchema),
    defaultValues: {
      dates: [],
      update: [],
      remove: [],
      add: [],
    },
  });

  const {
    fields: currentDates,
  } = useFieldArray({ control, name: 'dates' });

  const {
    append: handleAppendUpdateDateByForm,
  } = useFieldArray({ control, name: 'update' });

  const {
    append: handleAppendRemoveDateByForm,
  } = useFieldArray({ control, name: 'remove' });

  const {
    fields: addDates,
    append: handleAppendAddDateByForm,
    remove: handleRemoveAddDateByForm,
  } = useFieldArray({ control, name: 'add' });

  const removeDates = watch('remove')
    ?.map((item) => item.dateId);
  const existDates = watch('dates')
    ?.filter((item) => !removeDates?.includes(item.dateId as number))
    .map((item) => item.accessToDay)
    .filter(Boolean) as DateTime[];
  const newDates = watch('add')
    ?.map((item) => item.accessToDay)
    .filter(Boolean) as DateTime[];

  useEffect(() => {
    clearErrors(['dates', 'add']);

    const duplicatesInFirstArr = findDuplicatedDates(existDates);
    const {
      firstArrDuplicates,
      secondArrDuplicates,
    } = findEqualDatesInArrays(existDates, newDates);

    if (duplicatesInFirstArr.length) {
      duplicatesInFirstArr.forEach((item) => setError(
        `dates.${item}.accessToDay`,
        { type: 'custom', message: t('events.duplicatedDate') as string },
      ));
    }
    if (firstArrDuplicates.length) {
      firstArrDuplicates.forEach((item) => setError(
        `dates.${item}.accessToDay`,
        { type: 'custom', message: t('events.duplicatedDate') as string },
      ));
    }
    if (secondArrDuplicates.length) {
      secondArrDuplicates.forEach((item) => setError(
        `add.${item}.accessToDay`,
        { type: 'custom', message: t('events.duplicatedDate') as string },
      ));
    }
  }, [existDates, newDates, setError, t, clearErrors]);

  const handleSave = handleSubmit(
    (values) => {
      const {
        update = [], remove = [], add = [],
      } = values;
      const commonDates = dates.filter((date) => {
        const parseDate = DateTime.fromFormat(date.accessToDay, 'MM/dd/yyyy');
        return add.some((item) => DateTime.fromJSDate(item.accessToDay as Date).valueOf() === parseDate.valueOf());
      });

      const removeIds = remove.map((item) => item.dateId);
      const finalRemoveDate = remove.filter((r) => !commonDates.some((item) => item.id === r.dateId));
      const finalAddDates = add.filter((item) => (
        !commonDates.some((date) => {
          const parseDate = DateTime.fromFormat(date.accessToDay, 'MM/dd/yyyy');
          return item.accessToDay?.valueOf() === parseDate.valueOf();
        })
      ));

      onSave({
        update: update.filter((item) => !removeIds.includes(item.dateId)),
        remove: finalRemoveDate,
        add: finalAddDates,
      });
    },
  );

  useEffect(() => {
    const datesArray = dates.map(({ id, accessToDay }) => ({
      dateId: id,
      accessToDay: DateTime.fromFormat(accessToDay, 'LL/dd/yyyy'),
    }));

    if (!isOpen) {
      reset({
        dates: datesArray,
        update: [],
        remove: [],
        add: [],
      });
    } else {
      reset({
        dates: datesArray,
      });
    }
  }, [reset, dates, isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: 680 } }}
    >
      <DialogTitle>
        {t('events.replaceDates')}
      </DialogTitle>
      <DialogContent
        sx={{
          opacity: isLoading ? '0.5' : 1,
          pointerEvents: isLoading ? 'none' : 'initial',
        }}
      >
        <EventPeriod eventStartDate={eventStartDate} eventEndDate={eventEndDate} />
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <Grid container spacing={3} mt={0}>
            {currentDates.map(({
              id, dateId, accessToDay,
            }, index) => (
              <Controller
                key={id}
                name={`dates.${index}.isDeleted`}
                control={control}
                render={({ field: { value = false, onChange } }) => (
                  <Grid container item spacing={3} display={value ? 'none' : undefined}>
                    <Grid item xs={5.5}>
                      <DatePicker
                        label={t('events.currentDate')}
                        value={accessToDay}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                          },
                        }}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={5.5}>
                      <Controller
                        name={`dates.${index}.accessToDay`}
                        control={control}
                        render={({
                          field: { value: date, onChange: onDateChange, onBlur },
                          fieldState: { error: fieldError },
                        }) => (
                          <DatePicker
                            label={t('events.newDate')}
                            value={date}
                            onClose={onBlur}
                            onChange={(event) => {
                              onDateChange(event);
                              handleAppendUpdateDateByForm({ dateId: dateId as number, accessToDay: event });
                            }}
                            slotProps={{
                              textField: {
                                error: !!fieldError,
                                helperText: fieldError?.message,
                                fullWidth: true,
                                onBlur,
                              },
                            }}
                            minDate={allowedDates[0] ? DateTime.fromFormat(allowedDates[0], 'dd LLL yyyy') : undefined}
                            maxDate={allowedDates.length ? DateTime.fromFormat(allowedDates[allowedDates.length - 1], 'dd LLL yyyy') : undefined}
                            format={DEFAULT_DATE_FORMAT}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={1} alignSelf="center">
                      <IconButton
                        color="error"
                        onClick={() => {
                          handleAppendRemoveDateByForm({ dateId: dateId as number });
                          onChange(true);
                        }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
              />
            ))}
            {addDates.map(({ id, accessToDay }, index) => (
              <Fragment key={id}>
                <Grid item xs={5.5}>
                  <DatePicker
                    label={t('events.currentDate')}
                    value={accessToDay}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs={5.5}>
                  <Controller
                    name={`add.${index}.accessToDay`}
                    control={control}
                    render={({
                      field: { value, onChange, onBlur },
                      fieldState: { error: fieldError },
                    }) => (
                      <DatePicker
                        label={t('events.newDate')}
                        value={value}
                        onClose={onBlur}
                        onChange={onChange}
                        slotProps={{
                          textField: {
                            error: !!fieldError,
                            helperText: fieldError?.message,
                            fullWidth: true,
                            onBlur,
                          },
                        }}
                        minDate={allowedDates[0] ? DateTime.fromFormat(allowedDates[0], 'dd LLL yyyy') : undefined}
                        maxDate={allowedDates.length ? DateTime.fromFormat(allowedDates[allowedDates.length - 1], 'dd LLL yyyy') : undefined}
                        format={DEFAULT_DATE_FORMAT}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={1} alignSelf="center">
                  <IconButton color="error" onClick={() => handleRemoveAddDateByForm(index)}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </LocalizationProvider>
        <Button
          variant="outlined"
          size="small"
          onClick={() => handleAppendAddDateByForm({ accessToDay: null })}
          sx={{ mt: 3 }}
        >
          {t('events.addDate')}
        </Button>
      </DialogContent>
      <DialogActions sx={{ gap: 1 }}>
        {isLoading ? (
          <Stack spacing={1} flexGrow={1}>
            <LinearProgress />
            <LinearProgress />
            <LinearProgress />
          </Stack>
        ) : null}
        <Button
          color="inherit"
          onClick={onClose}
          disabled={isLoading}
        >
          {t('common.cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          onClick={handleSave}
          disabled={isLoading}
        >
          {t('common.saveButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ReplaceDatesDialog;
