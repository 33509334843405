import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

import { PaymentConnectionStatuses } from '../../../constants/Statuses';
import type IConnectStripeButton from '../interfaces/IConnectStripeButton';
import type { IGetButtonDataByStatus } from '../interfaces/IConnectStripeButton';

function getButtonDataByStatus(
  status?: PaymentConnectionStatuses,
): IGetButtonDataByStatus {
  if (status === PaymentConnectionStatuses.CONNECTED) {
    return {
      color: 'success',
      text: 'settings.paymentMethodConnected',
    };
  }

  return {
    color: 'error',
    text: 'settings.paymentMethodConnect',
  };
}

function ConnectStripeButton({
  conectionStatus,
  href,
  ...otherProps
}: IConnectStripeButton): React.ReactElement {
  const { t } = useTranslation();
  const { color, text } = getButtonDataByStatus(conectionStatus);

  return (
    <Button
      {...otherProps}
      href={href}
      color={color}
    >
      {t(text)}
    </Button>
  );
}

export default ConnectStripeButton;
