import mainApi from '../../../apis/mainApi';
import poolPlaysApi from '../../poolPlays/apis/poolPlaysApi';
import { SCHEDULE_BRACKET_TAG, SCHEDULE_TIME_RANGE_TAG } from '../../../tags/schedule';
import {
  ORG_EVENT_PLAY_OFF_DIVISION_ID,
  ORG_EVENT_PLAY_OFF_DIVISION_ID_INFO,
  ORG_EVENT_PLAY_OFF_DIVISION_ID_PLAYING_FIELDS,
  ORG_EVENT_PLAY_OFF_RULES_TIME_FRAMES_RANGE,
  ORG_EVENT_PO_RULES_FULL_INFO,
  ORG_EVENT_PLAY_OFF_RULES,
  ORG_EVENT_PO_RULES_ID_TIME_FRAME,
  ORG_EVENT_PO_RULES_TIME_FRAME_ID,
  ORG_EVENT_PLAY_OFF_DIVISION_ID_SETUP_ALL,
  ORG_EVENT_PO_SCHEDULE_CHECK_VALIDITY,
  ORG_EVENT_PO_SCHEDULE_RE_SETUP_GAMES,
  ORG_EVENT_PLAY_OFF_RULES_TIME_FRAMES,
  ORG_EVENT_SCHEDULE_BULK_CHANGE_GAME_POSITION,
  ORG_EVENT_PO_RULES_PUBLISH,
} from '../../../../constants/requestUrls';
import { EVENT_DIVISIONS_PLAY_OFF_GAME_SETUP } from '../../../tags/divisions';
import {
  PLAY_OFF_RULES,
  PLAY_OFF_PUBLISH_TAG,
  PLAY_OFF_RULES_LIST,
  PLAY_OFF_CHECK_VALIDATION_TAG,
} from '../../../tags/playOff';
import { POOL_PLAYS_SCHEDULE_GAME_TAG, POOL_PLAYS_SCHEDULE_TAG } from '../../../tags/poolPlays';
import { transformResponseWithTimezone } from '../../../../utils/transformResponseWithTimezone';
import { updateQueryData } from '../../../../utils/poolPlays';
import type IBaseQueryResponse from '../../../types/IBaseQueryResponse';
import type { IBaseQueryRequest } from '../../../types/IBaseQueryRequest';
import type IPPTimeFramesRange from '../../poolPlays/interfaces/IPPTimeFramesRange';
import type IPoolPlayDivisionRulesReq from '../../poolPlays/interfaces/IPoolPlayDivisionRulesReq';
import type IPlayOffDivisionRules from '../interfaces/IPlayOffDivisionRules';
import type IPoolPlayDivisionRules from '../../poolPlays/interfaces/IPoolPlayDivisionRules';
import type ICreatePlayOffDivRulesReq from '../interfaces/ICreatePlayOffDivRulesReq';
import type IUpdatePPDivisionFieldsReq from '../../../../pages/LogisticsAndGameSetup/interfaces/IUpdatePPDivisionFieldsReq';
import type IPOFullInfo from '../interfaces/IPOFullInfo';
import type IPlayOff from '../interfaces/IPlayOff';
import type IUpdatePOTimeFrameReq from '../interfaces/IUpdatePOTimeFrameReq';
import type IPoolPlay from '../../poolPlays/interfaces/IPoolPlay';
import type IPoolPlayRequest from '../../poolPlays/interfaces/IPoolPlayRequest';
import type ICreatePOTimeFrameReq from '../interfaces/ICreatePOTimeFrameReq';
import type { IDivisionsPlayOffGameSetup } from '../../divisions/interfaces/IDivisionsGameSetup';
import type ICheckValidity from '../../poolPlays/interfaces/ICheckValidity';
import type IPOTimeFrame from '../interfaces/IPOTimeFrame';
import type IUpdatePOGamePositionRequest from '../interfaces/IUpdatePOGamePositionRequest';
import type IResponseMeta from '../../../types/IResponseMeta';

const playOffApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getPOTimeFrames: builder.query<IBaseQueryResponse<IPOTimeFrame[]>, IBaseQueryRequest>({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENT_PLAY_OFF_RULES_TIME_FRAMES
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
      }),
      transformResponse: (data: IBaseQueryResponse<IPOTimeFrame[]>, meta: IResponseMeta) => (
        transformResponseWithTimezone(data, meta)
      ),
      providesTags: (result) => (
        result
          ? [...result.data.map(({ id }) => ({ type: PLAY_OFF_RULES_LIST, id })), PLAY_OFF_RULES_LIST]
          : [PLAY_OFF_RULES_LIST]
      ),
    }),

    getPlayOffTimeFramesRange: builder.query<IBaseQueryResponse<IPPTimeFramesRange>, IBaseQueryRequest>({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENT_PLAY_OFF_RULES_TIME_FRAMES_RANGE
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
      }),
      transformResponse: (data: IBaseQueryResponse<IPPTimeFramesRange>, meta: IResponseMeta) => (
        transformResponseWithTimezone(data, meta)
      ),
      providesTags: [PLAY_OFF_RULES_LIST],
    }),

    getPlayOffDivisionRules: builder.query<IBaseQueryResponse<IPlayOffDivisionRules>, IPoolPlayDivisionRulesReq>({
      query: ({ orgId, eventId, divisionId }) => ({
        url: ORG_EVENT_PLAY_OFF_DIVISION_ID_INFO
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{divisionId}', `${divisionId}`),
        method: 'GET',
      }),
    }),

    getPOFullInfo: builder.query<IBaseQueryResponse<IPOFullInfo>, IBaseQueryRequest>({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENT_PO_RULES_FULL_INFO
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
      }),
      providesTags: [PLAY_OFF_PUBLISH_TAG],
    }),

    createPlayOffDivRules: builder.mutation<
    IBaseQueryResponse<IPoolPlayDivisionRules, { isFirstDivisionSetup: boolean }>,
    ICreatePlayOffDivRulesReq
    >({
      query: ({
        orgId, eventId, divisionId, ...body
      }) => ({
        url: ORG_EVENT_PLAY_OFF_DIVISION_ID
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{divisionId}', `${divisionId}`),
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { divisionId }) => [
        { type: EVENT_DIVISIONS_PLAY_OFF_GAME_SETUP, id: divisionId },
        { type: SCHEDULE_BRACKET_TAG, id: divisionId },
        PLAY_OFF_CHECK_VALIDATION_TAG,
      ],
    }),

    updatePlayOffDivRules: builder.mutation<
    IBaseQueryResponse<IPoolPlayDivisionRules>,
    Omit<ICreatePlayOffDivRulesReq, 'preferredPlayingFieldsIds'>
    >({
      query: ({
        orgId, eventId, divisionId, ...body
      }) => ({
        url: ORG_EVENT_PLAY_OFF_DIVISION_ID
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{divisionId}', `${divisionId}`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, { divisionId }) => [
        { type: EVENT_DIVISIONS_PLAY_OFF_GAME_SETUP, id: divisionId },
        { type: SCHEDULE_BRACKET_TAG, id: divisionId },
        PLAY_OFF_CHECK_VALIDATION_TAG,
      ],
    }),

    updatePlayOffFields: builder.mutation<void, IUpdatePPDivisionFieldsReq>({
      query: ({
        orgId, eventId, divisionId, ...body
      }) => ({
        url: ORG_EVENT_PLAY_OFF_DIVISION_ID_PLAYING_FIELDS
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{divisionId}', `${divisionId}`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { divisionId }) => [
        { type: EVENT_DIVISIONS_PLAY_OFF_GAME_SETUP, id: divisionId },
      ],
    }),

    getPlayOff: builder.query<IBaseQueryResponse<IPlayOff>, IBaseQueryRequest>({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENT_PLAY_OFF_RULES
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
      }),
      transformResponse: (data: IBaseQueryResponse<IPlayOff>, meta: IResponseMeta) => (
        transformResponseWithTimezone(data, meta)
      ),
      providesTags: [PLAY_OFF_RULES],
    }),

    createPlayOff: builder.mutation<IBaseQueryResponse<IPlayOff>, IBaseQueryRequest>({
      query: ({ orgId, eventId, ...body }) => ({
        url: ORG_EVENT_PLAY_OFF_RULES
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'POST',
        body,
      }),
    }),

    updatePlayOff: builder.mutation<IBaseQueryResponse<IPoolPlay>, Omit<IPoolPlayRequest, 'timeFrames'>>({
      query: ({ orgId, eventId, ...body }) => ({
        url: ORG_EVENT_PLAY_OFF_RULES
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [PLAY_OFF_RULES_LIST],
    }),

    createPOTimeFrame: builder.mutation<IBaseQueryResponse<IPlayOff>, ICreatePOTimeFrameReq>({
      query: ({
        orgId, eventId, playRuleId, ...body
      }) => ({
        url: ORG_EVENT_PO_RULES_ID_TIME_FRAME
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{playRuleId}', `${playRuleId}`),
        method: 'POST',
        body,
      }),
      invalidatesTags: [PLAY_OFF_RULES_LIST, SCHEDULE_TIME_RANGE_TAG],
    }),

    updatePOTimeFrame: builder.mutation<IBaseQueryResponse<IPlayOff>, IUpdatePOTimeFrameReq>({
      query: ({
        orgId, eventId, timeFrameId, ...body
      }) => ({
        url: ORG_EVENT_PO_RULES_TIME_FRAME_ID
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{timeFrameId}', `${timeFrameId}`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_result, _error, { timeFrameId }) => [
        { type: PLAY_OFF_RULES_LIST, id: timeFrameId }, PLAY_OFF_RULES_LIST, SCHEDULE_TIME_RANGE_TAG,
      ],
    }),

    deletePOTimeFrame: builder.mutation<IBaseQueryResponse<IPlayOff>, Omit<IUpdatePOTimeFrameReq, 'startAt' | 'endAt'>>({
      query: ({ orgId, eventId, timeFrameId }) => ({
        url: ORG_EVENT_PO_RULES_TIME_FRAME_ID
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{timeFrameId}', `${timeFrameId}`),
        method: 'DELETE',
      }),
      onQueryStarted: async ({ timeFrameId }, { dispatch, queryFulfilled, getState }) => {
        const state = getState();
        const entries = playOffApi.util.selectInvalidatedBy(
          state,
          [PLAY_OFF_RULES],
        );

        const patchResults = entries.map(({ endpointName, originalArgs }: any) => (
          dispatch(playOffApi.util.updateQueryData(endpointName, originalArgs, (draft: any) => {
            const currentData = draft.data;
            const timeFrames = draft.data.timeFrames.filter((item: any) => item.id !== timeFrameId);

            Object.assign(draft.data, { ...currentData, timeFrames });

            return draft;
          }))
        ));

        queryFulfilled.catch(() => {
          patchResults.forEach((item) => item?.undo());
        });
      },
      invalidatesTags: (_result, _error, { timeFrameId }) => [
        { type: PLAY_OFF_RULES_LIST, id: timeFrameId }, PLAY_OFF_RULES_LIST, SCHEDULE_TIME_RANGE_TAG,
      ],
    }),

    setupAllPODivisions: builder.mutation<
    IBaseQueryResponse<IDivisionsPlayOffGameSetup[]>,
    IBaseQueryRequest & { divisionId: number }
    >({
      query: ({ orgId, eventId, divisionId }) => ({
        url: ORG_EVENT_PLAY_OFF_DIVISION_ID_SETUP_ALL
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{divisionId}', `${divisionId}`),
        method: 'POST',
      }),
      invalidatesTags: [EVENT_DIVISIONS_PLAY_OFF_GAME_SETUP],
    }),

    publishPOSchedule: builder.mutation<unknown, IBaseQueryRequest>({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENT_PO_RULES_PUBLISH
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'PATCH',
      }),
      invalidatesTags: [PLAY_OFF_PUBLISH_TAG],
    }),

    setupGamesForPOSchedule: builder.mutation<unknown, IBaseQueryRequest & { playOffRulesId: number }>({
      query: ({ orgId, eventId, playOffRulesId }) => ({
        url: ORG_EVENT_PO_SCHEDULE_RE_SETUP_GAMES
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{playOffRulesId}', `${playOffRulesId}`),
        method: 'PUT',
      }),
      invalidatesTags: [POOL_PLAYS_SCHEDULE_TAG],
    }),

    checkValidityPOSchedule: builder.query<
    IBaseQueryResponse<ICheckValidity>,
    IBaseQueryRequest & { isInvalidate?: boolean }
    >({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENT_PO_SCHEDULE_CHECK_VALIDITY
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
      }),
      providesTags: (result, error, { isInvalidate }) => (
        isInvalidate ? [PLAY_OFF_CHECK_VALIDATION_TAG] : []
      ),
    }),

    updatePOGamePosition: builder.mutation<unknown, IUpdatePOGamePositionRequest>({
      query: ({
        orgId, eventId, playOffRulesId, ...body
      }) => ({
        url: ORG_EVENT_SCHEDULE_BULK_CHANGE_GAME_POSITION
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{playOffRulesId}', `${playOffRulesId}`),
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async ({ gameFields }, { dispatch, queryFulfilled, getState }) => {
        const field = gameFields[0];
        const state = getState();
        const entries = poolPlaysApi.util.selectInvalidatedBy(
          state,
          [{ type: POOL_PLAYS_SCHEDULE_GAME_TAG, id: field.gameId }, SCHEDULE_TIME_RANGE_TAG],
        );

        const patchResults = entries.map(({ endpointName, originalArgs }: any) => (
          updateQueryData(dispatch, endpointName, originalArgs, field)
        ));

        queryFulfilled.catch(() => {
          patchResults.forEach((item) => item?.undo());
        });
      },
      invalidatesTags: (result, error, { gameFields }) => {
        const field = gameFields[0];
        return [{ type: SCHEDULE_BRACKET_TAG, id: field.gameId }, PLAY_OFF_CHECK_VALIDATION_TAG];
      },
    }),
  }),
});

export const {
  useGetPOTimeFramesQuery,
  useCreatePlayOffMutation,
  useUpdatePlayOffMutation,
  useGetPlayOffQuery,
  useCreatePOTimeFrameMutation,
  useUpdatePOTimeFrameMutation,
  useDeletePOTimeFrameMutation,
  useGetPlayOffTimeFramesRangeQuery,
  useGetPOFullInfoQuery,
  useLazyGetPlayOffDivisionRulesQuery,
  useCreatePlayOffDivRulesMutation,
  useUpdatePlayOffDivRulesMutation,
  useUpdatePlayOffFieldsMutation,
  useSetupAllPODivisionsMutation,
  useSetupGamesForPOScheduleMutation,
  useLazyCheckValidityPOScheduleQuery,
  useCheckValidityPOScheduleQuery,
  useUpdatePOGamePositionMutation,
  usePublishPOScheduleMutation,
} = playOffApi;

export default playOffApi;
