import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(
  () => ({
    box: {
      flexGrow: 1,
      height: 700,
      overflow: 'auto',

      '&::-webkit-scrollbar': {
        width: 6,
        height: 6,
        padding: '8px 0',
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(255,255,255,.5)',
        borderRadius: 100,
      },

      '&::-webkit-scrollbar-track': {
        width: 10,
        backgroundColor: 'rgba(255,255,255,.1)',
        borderRadius: 100,
        outline: '1px solid rgba(255,255,255,.1)',
      },
    },
    root: {
      width: 'fit-content',

      '& .rbc-time-header': {
        marginBottom: 6,
      },

      '& .rbc-time-header.rbc-overflowing': {
        borderColor: 'transparent',
      },

      '& .rbc-header': {
        padding: 0,
        fontFamily: 'Maven Pro, sans-serif',
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
        textAlign: 'start',
      },
    },
    unscheduled: {
      height: 625,
      width: 230,
      padding: '5px',
      border: '1px solid #757575',
      overflowY: 'auto',

      '&::-webkit-scrollbar': {
        width: 6,
        height: 6,
        padding: '8px 0',
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(255,255,255,.5)',
        borderRadius: 100,
      },

      '&::-webkit-scrollbar-track': {
        width: 10,
        backgroundColor: 'rgba(255,255,255,.1)',
        borderRadius: 100,
        outline: '1px solid rgba(255,255,255,.1)',
      },
    },
    ghostButton: {
      justifyContent: 'space-between',
      marginTop: 10,
      textTransform: 'none',
    },
  }),
);

export default useStyles;
