import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { ISpectatorsSlice } from './interfaces/ISpectatorsSlice';

export const initialState: ISpectatorsSlice = {
  isReplaceDatesDialogOpen: false,
};

const spectatorsSlice = createSlice({
  name: 'spectators',
  initialState,
  reducers: {
    toggleReplaceDatesDialog: (state, { payload }: PayloadAction<boolean>) => {
      state.isReplaceDatesDialogOpen = payload;
    },
  },
});

export const { toggleReplaceDatesDialog } = spectatorsSlice.actions;

export default spectatorsSlice.reducer;
