import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

import type { IInfoCard } from './interfaces/IInfoCard';

function InfoCard({ icon, label, desc }: IInfoCard): React.ReactElement {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Avatar sx={{ color: 'white', mr: 2, bgcolor: '#415057' }}>
        {icon}
      </Avatar>
      <Stack spacing={0.5}>
        <Typography variant="body2">
          {label}
        </Typography>
        <Typography variant="body1">
          {desc}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default InfoCard;
