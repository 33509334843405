import { useCallback } from 'react';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';

import SpectatorsTicketsView from './SpectatorsTicketsView';
import { setLeaveModal } from '../../store/slices/createEvent/slice';
import { selectIsDatesDialogOpen } from '../../store/slices/spectators/selectors';
import { toggleReplaceDatesDialog } from '../../store/slices/spectators/slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks/useApp';
import useEventSpectatorsForm from '../../hooks/useEventSpectatorsForm';
import { getInterval } from '../../utils/dates';
import { DEFAULT_DATE_FORMAT } from '../../constants/general';
import AppRoutes from '../../constants/AppRoutes';
import type ICreateEventContext from '../../layout/CreateEventLayout/interfaces/ICreateEventContext';

function SpectatorsTickets(): React.ReactElement {
  const { eventId, activeStep } = useOutletContext<ICreateEventContext>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const isEditPage = searchParams.get('editPage');
  const isDatesDialogOpen = useAppSelector(selectIsDatesDialogOpen);

  const {
    event,
    tournamentDates,
    control,
    handleDatesSave,
    handleCreateSubmit,
    handleCancel,
    isDirty,
    isSubmitting,
    isLoading,
  } = useEventSpectatorsForm({
    navigate: () => {
      navigate(`/events/${eventId}`);
    },
    onCancel: (dirty) => {
      const link = isEditPage
        ? AppRoutes.createEvent.concat('?editPage=true')
        : AppRoutes.teamsTickets;

      if (dirty) {
        dispatch(setLeaveModal({ isOpen: true, link }));
      } else {
        navigate(link);
      }
    },
  });

  const handleTournamentDaysClick = () => {
    if (event?.published) {
      dispatch(toggleReplaceDatesDialog(true));
    }
  };

  const handleDatesDialogClose = () => {
    dispatch(toggleReplaceDatesDialog(false));
  };

  const handleStepClick = useCallback((link: string) => {
    const currentLink = isEditPage
      ? link.concat('?editPage=true')
      : link;

    if (isDirty) {
      dispatch(setLeaveModal({ isOpen: true, link: currentLink }));
    } else {
      navigate(currentLink);
    }
  }, [isDirty, isEditPage, dispatch, navigate]);

  return (
    <SpectatorsTicketsView
      eventName={event.nameWithTagline}
      tournamentDates={tournamentDates}
      control={control}
      eventStartDate={event.startAt?.toFormat(DEFAULT_DATE_FORMAT) || '-'}
      eventEndDate={event.endAt?.toFormat(DEFAULT_DATE_FORMAT) || '-'}
      tournamentDaysInterval={event.startAt && event.endAt
        ? getInterval(
          event.startAt.set({ hour: 0, minute: 0, second: 0 }),
          event.endAt.set({ hour: 23, minute: 59, second: 0 }),
        )
        : []}
      maxSalesDate={event.endAt}
      activeStep={activeStep}
      onTournamentDaysClick={handleTournamentDaysClick}
      onDatesDialogClose={handleDatesDialogClose}
      onDatesSave={handleDatesSave}
      onStepClick={handleStepClick}
      onCreateSubmit={handleCreateSubmit}
      onSkipStep={handleCancel}
      isSubmitting={isSubmitting}
      isDatesDialogOpen={isDatesDialogOpen}
      isLoading={isLoading}
    />
  );
}

export default SpectatorsTickets;
