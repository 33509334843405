import { getDivisionAgeRange } from '../../../../utils/helpers';
import mainApi from '../../../apis/mainApi';
import EVENTS_LIST_TAG, { EVENT_DAYS_TAG, EVENT_TAG } from '../../../tags/events';
import { EVENT_PARTNERS_LIST_TAG } from '../../../tags/partners';
import { EVENT_HOTELS_LIST_TAG } from '../../../tags/hotels';
import {
  ORG_EVENTS,
  ORG_EVENTS_ADMIN_LIST,
  ORG_EVENTS_CANCEL,
  ORG_EVENTS_COVER_PHOTO,
  ORG_EVENTS_DUPLICATE,
  ORG_EVENTS_HOTELS,
  ORG_EVENTS_ID,
  ORG_EVENTS_PARTNERS,
  ORG_EVENTS_PROFILE_PHOTO,
  ORG_EVENTS_PUBLISH,
  ORG_EVENTS_TOPICS,
  ORG_EVENT_DIVISION_DAYS,
} from '../../../../constants/requestUrls';
import { CENTS_PER_DOLLAR, GENDERS_ORDER } from '../../../../constants/general';
import type IListEvent from '../interfaces/IListEvent';
import type IBaseQueryResWithPagination from '../../../types/IBaseQueryResWithPagination';
import type IBaseQueryResponse from '../../../types/IBaseQueryResponse';
import type IEventTopic from '../interfaces/IEventTopic';
import type IEventTopicRequest from '../interfaces/IEventTopicRequest';
import type { IEventRequest } from '../interfaces/IEventRequest';
import type { IBaseQueryReqWithParams } from '../../../types/IBaseQueryReqWithParams';
import type { ISingleEvent } from '../interfaces/ISingleEvent';
import type { IHotel } from '../../hotels/interfaces/IHotel';
import type { IPartner } from '../../partners/interfaces/IPartner';
import type { IEventDays } from '../interfaces/IEventDays';
import type { TGender } from '../../../../interfaces/TGender';
import type IResponseMeta from '../../../types/IResponseMeta';

const eventsApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllEvents: builder.query<
    IBaseQueryResWithPagination<IListEvent[]>,
    IBaseQueryReqWithParams & { statusOrderBy: string, search?: string }
    >({
      query: ({ orgId, ...rest }) => ({
        url: ORG_EVENTS_ADMIN_LIST.replace('{organizationId}', `${orgId}`),
        method: 'GET',
        params: { ...rest },
      }),
      transformResponse: (data: IBaseQueryResWithPagination<IListEvent[]>) => ({
        ...data,
        data: data.data.map((item) => ({
          ...item,
          totalAmount: item.totalAmount ? item.totalAmount / CENTS_PER_DOLLAR : 0,
          profilePhoto: item.profilePhoto ? `${item.profilePhoto}` : null,
        })),
      }),
      providesTags: (result) => (
        result
          ? [...result.data.map(({ id }) => ({ type: EVENTS_LIST_TAG, id })), EVENTS_LIST_TAG]
          : [EVENTS_LIST_TAG]
      ),
    }),

    getEventsTopics: builder.query<IBaseQueryResWithPagination<IEventTopic[]>, { orgId: number }>({
      query: ({ orgId }) => ({
        url: ORG_EVENTS_TOPICS.replace('{organizationId}', `${orgId}`),
        method: 'GET',
      }),
    }),

    getEvent: builder.query<IBaseQueryResponse<ISingleEvent>, { orgId: number, eventId: number }>({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENTS_ID
          .replace('{organizationId}', `${orgId}`)
          .replace('{id}', `${eventId}`),
        method: 'GET',
      }),
      transformResponse: (data: IBaseQueryResponse<ISingleEvent>, meta: IResponseMeta) => {
        let timezone;
        if (meta?.response?.headers?.get) {
          timezone = meta.response.headers.get('X-Event-Tz');
        }
        const profilePhoto = data.data.profilePhoto ? `${data.data.profilePhoto}` : null;
        const cover = data.data.cover ? `${data.data.cover}` : null;
        const gender = data.data.gender
          .split(', ')
          .sort((a, b) => GENDERS_ORDER[a.toUpperCase() as TGender] - GENDERS_ORDER[b.toUpperCase() as TGender])
          .join(', ') as TGender;

        return {
          ...data,
          data: {
            ...data.data,
            ageRange: getDivisionAgeRange(data.data.minDivisionAge, data.data.maxDivisionAge),
            gender,
            profilePhoto,
            cover,
          },
          timezone,
        };
      },
      providesTags: (result) => [{ type: EVENT_TAG, id: result?.data.id }],
    }),

    createEvent: builder.mutation<IBaseQueryResponse<ISingleEvent>, IEventRequest>({
      query: ({ orgId, ...rest }) => ({
        url: ORG_EVENTS.replace('{organizationId}', `${orgId}`),
        method: 'POST',
        body: { ...rest },
      }),
      invalidatesTags: [EVENTS_LIST_TAG],
    }),

    updateEvent: builder.mutation<IBaseQueryResponse<ISingleEvent>, { eventId: number } & Partial<IEventRequest>>({
      query: ({ orgId, eventId, ...rest }) => ({
        url: ORG_EVENTS_ID
          .replace('{organizationId}', `${orgId}`)
          .replace('{id}', `${eventId}`),
        method: 'PATCH',
        body: { ...rest },
      }),
      invalidatesTags: (result, error, { eventId }) => [{ type: EVENT_TAG, id: eventId }],
    }),

    deleteEvent: builder.mutation<IBaseQueryResponse<ISingleEvent>, { orgId: number, eventId: number }>({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENTS_ID
          .replace('{organizationId}', `${orgId}`)
          .replace('{id}', `${eventId}`),
        method: 'DELETE',
      }),
      invalidatesTags: [
        EVENTS_LIST_TAG,
      ],
    }),

    uploadEventProfilePhoto: builder.mutation<
    IBaseQueryResponse<{ url: string }>,
    { eventId: number, orgId: number, file: File }
    >({
      query: ({ orgId, eventId, file }) => {
        const form = new FormData();
        form.append('file', file, file.name);

        return ({
          url: ORG_EVENTS_PROFILE_PHOTO
            .replace('{organizationId}', `${orgId}`)
            .replace('{id}', `${eventId}`),
          method: 'PUT',
          body: form,
        });
      },
      invalidatesTags: (result, error, { eventId }) => [{ type: EVENT_TAG, id: eventId }],
    }),

    uploadEventCoverPhoto: builder.mutation<
    IBaseQueryResponse<{ url: string }>,
    { eventId: number, orgId: number, file: File }
    >({
      query: ({ orgId, eventId, file }) => {
        const form = new FormData();
        form.append('file', file, file.name);

        return ({
          url: ORG_EVENTS_COVER_PHOTO
            .replace('{organizationId}', `${orgId}`)
            .replace('{id}', `${eventId}`),
          method: 'PUT',
          body: form,
        });
      },
      invalidatesTags: (result, error, { eventId }) => [{ type: EVENT_TAG, id: eventId }],
    }),

    createEventsTopic: builder.mutation<IBaseQueryResponse<IEventTopic>, Partial<IEventTopicRequest>>({
      query: ({ orgId, name }) => ({
        url: ORG_EVENTS_TOPICS.replace('{organizationId}', `${orgId}`),
        method: 'POST',
        body: { name },
      }),
    }),

    getEventHotels: builder.query<IBaseQueryResWithPagination<IHotel[]>, { orgId: number, eventId: number }>({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENTS_HOTELS
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
      }),
      providesTags: (result) => (
        result
          ? [...result.data.map(({ id }) => ({ type: EVENT_HOTELS_LIST_TAG, id })), EVENT_HOTELS_LIST_TAG]
          : [EVENT_HOTELS_LIST_TAG]
      ),
    }),

    getEventPartners: builder.query<IBaseQueryResWithPagination<IPartner[]>, { orgId: number, eventId: number }>({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENTS_PARTNERS
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
      }),
      transformResponse: (data: IBaseQueryResWithPagination<IPartner[]>) => ({
        ...data,
        data: data.data.map((item) => ({
          ...item,
          cover: item.cover ? `${item.cover}` : null,
        })),
      }),
      providesTags: (result) => (
        result
          ? [...result.data.map(({ id }) => ({ type: EVENT_PARTNERS_LIST_TAG, id })), EVENT_PARTNERS_LIST_TAG]
          : [EVENT_PARTNERS_LIST_TAG]
      ),
    }),

    publishEvent: builder.mutation<IBaseQueryResponse<ISingleEvent>, { orgId: number, eventId: number }>({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENTS_PUBLISH
          .replace('{organizationId}', `${orgId}`)
          .replace('{id}', `${eventId}`),
        method: 'POST',
      }),
      invalidatesTags: (result, error, { eventId }) => [
        { type: EVENT_TAG, id: eventId },
        { type: EVENTS_LIST_TAG, id: eventId },
      ],
    }),

    duplicateEvent: builder.mutation<IBaseQueryResponse<ISingleEvent>, { orgId: number, eventId: number }>({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENTS_DUPLICATE
          .replace('{organizationId}', `${orgId}`)
          .replace('{id}', `${eventId}`),
        method: 'POST',
      }),
      invalidatesTags: (result, error, { eventId }) => [
        { type: EVENT_TAG, id: eventId },
        EVENTS_LIST_TAG,
      ],
    }),

    cancelEvent: builder.mutation<IBaseQueryResponse<ISingleEvent>, { orgId: number, eventId: number }>({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENTS_CANCEL
          .replace('{organizationId}', `${orgId}`)
          .replace('{id}', `${eventId}`),
        method: 'PATCH',
        body: {
          cancelReason: 'cancel',
          cancelComment: 'some comment',
        },
      }),
      invalidatesTags: (result, error, { eventId }) => [
        { type: EVENT_TAG, id: eventId },
        { type: EVENTS_LIST_TAG, id: eventId },
      ],
    }),

    deleteEventCoverPhoto: builder.mutation<IBaseQueryResponse<ISingleEvent>, { orgId: number, eventId: number }>({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENTS_COVER_PHOTO
          .replace('{organizationId}', `${orgId}`)
          .replace('{id}', `${eventId}`),
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { eventId }) => [
        { type: EVENT_TAG, id: eventId },
        { type: EVENTS_LIST_TAG, id: eventId },
      ],
    }),

    getEventTournamentDays: builder.query<IBaseQueryResponse<IEventDays>, { orgId: number, eventId: number }>({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENT_DIVISION_DAYS
          .replace('{organizationId}', `${orgId}`)
          .replace('{id}', `${eventId}`),
        method: 'GET',
      }),
      transformResponse: (data: IBaseQueryResponse<IEventDays>, meta: IResponseMeta) => {
        let timezone;
        if (meta?.response?.headers?.get) {
          timezone = meta.response.headers.get('X-Event-Tz');
        }

        return ({
          ...data,
          data: {
            ...data.data,
            timezone,
          },
        });
      },
      providesTags: [EVENT_DAYS_TAG],
    }),
  }),
});

export const {
  useGetEventQuery,
  useGetAllEventsQuery,
  useLazyGetEventsTopicsQuery,
  useCreateEventsTopicMutation,
  useCreateEventMutation,
  useUploadEventCoverPhotoMutation,
  useDeleteEventCoverPhotoMutation,
  useUploadEventProfilePhotoMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,
  useGetEventHotelsQuery,
  useGetEventPartnersQuery,
  usePublishEventMutation,
  useDuplicateEventMutation,
  useCancelEventMutation,
  useGetEventTournamentDaysQuery,
} = eventsApi;

export default eventsApi;
