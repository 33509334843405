import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { setUserOrganization } from '../user/slice';
import { logout } from '../auth/slice';
import { ORDERS } from '../../../constants/general';
import type ITeamsSlice from './interfaces/ITeamsSlice';

export const initialState: ITeamsSlice = {
  search: '',
  order: ORDERS.ASC,
  orderBy: 'registrationDate',
  clubId: '',
  coachId: '',
  eventDivisionId: '',
};

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setTeamsState: (state, { payload }: PayloadAction<Partial<ITeamsSlice>>) => ({ ...state, ...payload }),
    resetTeamsState: () => initialState,
  },
  extraReducers: (builder): void => {
    builder.addCase(setUserOrganization, (): ITeamsSlice => initialState);
    builder.addCase(logout, (): ITeamsSlice => initialState);
  },
});

export const {
  setTeamsState,
  resetTeamsState,
} = teamsSlice.actions;

export default teamsSlice.reducer;
