import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormHelperText from '@mui/material/FormHelperText';

import { GENDERS, TEAM_AGES } from '../../constants/general';
import type { IPrecreationSection } from './interfaces/IPrecreationSection';

function PrecreationSection({ control, onPrecreateDivisions }: IPrecreationSection): React.ReactElement {
  const { t } = useTranslation();

  return (
    <Paper sx={{ p: 3 }}>
      <Grid container rowSpacing={3} columnSpacing={5}>
        <Grid item xs={12}>
          <Typography component="h4" variant="h6">
            {t('events.precreationOfDivisions')}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="ages"
            control={control}
            defaultValue={[]}
            render={({
              field: { onChange, value = [], onBlur },
              fieldState: { error: fieldError },
            }) => (
              <FormControl error={!!fieldError} fullWidth>
                <InputLabel id="multiple-age-label">
                  {t('events.age')}
                </InputLabel>
                <Select
                  id="multiple-age"
                  labelId="multiple-age-label"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  label={t('events.age')}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {selected.map((item) => (
                        <Chip key={item} label={item} sx={{ px: 0.5, height: 24 }} />
                      ))}
                    </Box>
                  )}
                  multiple
                >
                  {TEAM_AGES.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={value.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
                {fieldError?.message ? <FormHelperText>{fieldError.message}</FormHelperText> : null}
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="gender"
            control={control}
            defaultValue=""
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error: fieldError },
            }) => (
              <FormControl error={!!fieldError} fullWidth>
                <InputLabel id="multiple-gender-label">
                  {t('events.gender')}
                </InputLabel>
                <Select
                  id="multiple-gender"
                  labelId="multiple-gender-label"
                  label={t('events.gender')}
                  value={value}
                  onChange={onChange}
                  onClose={onBlur}
                >
                  {Object.values(GENDERS).map((gender) => (
                    <MenuItem key={gender} value={gender}>
                      {t(`events.${gender}`)}
                    </MenuItem>
                  ))}
                </Select>
                {fieldError?.message ? <FormHelperText>{fieldError.message}</FormHelperText> : null}
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="outlined" size="large" onClick={onPrecreateDivisions}>
            {t('events.create')}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default PrecreationSection;
