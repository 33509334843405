import type { ReactElement } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import LoginView from './LoginView';
import { useAppDispatch, useAppSelector } from '../../store/hooks/useApp';
import { selectUserIsLogged } from '../../store/slices/auth/selectors';
import { setTokens } from '../../store/slices/auth/slice';
import { useLoginMutation } from '../../store/slices/auth/apis/authApi';
import { loginSchema } from '../../utils/validators';
import AppRoutes from '../../constants/AppRoutes';
import { USER_DEVICE_ID } from '../../constants/localStorageKeys';
import type ILoginForm from './interfaces/ILoginForm';

function Login(): ReactElement {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userIsLogged = useAppSelector(selectUserIsLogged);
  const [login, { isLoading, isError, error }] = useLoginMutation();
  const {
    control,
    handleSubmit,
    reset,
  } = useForm<ILoginForm>({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const handleLoginSubmit = handleSubmit(async (credentials): Promise<void> => {
    const deviceId = crypto.randomUUID();
    const response = await login({ ...credentials, deviceId });

    if (!('error' in response)) {
      reset();
      navigate(AppRoutes.home);
      dispatch(setTokens(response.data));
      localStorage.setItem(USER_DEVICE_ID, deviceId);
    }
  });

  return userIsLogged ? (
    <Navigate to={AppRoutes.home} />
  ) : (
    <LoginView
      onLoginSubmit={handleLoginSubmit}
      control={control}
      isLoading={isLoading}
      isError={isError}
      error={error}
    />
  );
}

export default Login;
