import { NavLink } from 'react-router-dom';
import ButtonBase from '@mui/material/ButtonBase';
import Icon from '@mui/material/Icon';
import Avatar from '@mui/material/Avatar';

import { useAppSelector } from '../../store/hooks/useApp';
import { selectUserOrg } from '../../store/slices/user/selectors';
import type { ISidebar } from './interfaces/ISidebar';
import useStyles from './sidebarStyles-tss';

function Sidebar({ menu }: ISidebar) {
  const organization = useAppSelector(selectUserOrg);
  const { classes } = useStyles();

  return (
    <aside className={classes.bigSidebar}>
      <nav className={classes.category}>
        <div className={classes.fixedWrap}>
          <ButtonBase className={classes.avatarHead} onClick={() => {}}>
            <Avatar
              alt={organization?.name}
              src={organization?.profilePhoto || undefined}
              sx={{ width: 48, height: 48 }}
            />
          </ButtonBase>
          {menu.map((item) => (
            item.show ? (
              <ButtonBase
                key={item.key}
                focusRipple
                className={classes.menuHead}
                component={NavLink}
                to={item.link}
              >
                <Icon className={classes.icon}>{item.icon}</Icon>
                <span className={classes.text}>{item.name}</span>
              </ButtonBase>
            ) : null
          ))}
        </div>
      </nav>
    </aside>
  );
}

export default Sidebar;
