import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import ScheduleBracket from '../../../components/ScheduleBracket/ScheduleBracket';
import ScheduleCalendar from '../../../components/ScheduleCalendar/ScheduleCalendar';
import { useAppSelector } from '../../../store/hooks/useApp';
import { selectUserOrg } from '../../../store/slices/user/selectors';
import useScheduleCalendarFilterForm from '../../../hooks/useScheduleCalendarFilterForm';
import useScheduleBracket from '../../../hooks/useScheduleBracket';
import useTimezone from '../../../hooks/useTimezone';
import AppRoutes from '../../../constants/AppRoutes';
import { ScheduleTypes, ScheduleView } from '../../../constants/general';
import type IPlayOffTab from '../interfaces/IPlayOffTab';

function PlayOffTab({ view }: IPlayOffTab) {
  const { eventId } = useParams() as { eventId: string };
  const navigate = useNavigate();

  const organization = useAppSelector(selectUserOrg);

  const {
    timezone: calendarTimezone,
    control,
    selectedGame,
    filterData,
    timeFrames,
    games,
    unscheduledList,
    fields,
    onSelectGame,
    toggleGameDetailsDialog,
    toggleBuyGamesDialog,
    buyGames,
    isOpenBuyGames,
    isLoading: isScheduleLoading,
  } = useScheduleCalendarFilterForm({
    orgId: organization?.id as number,
    eventId: +eventId,
    type: ScheduleTypes.PLAY_OFF,
  });

  const {
    timezone: bracketTimezone,
    tabValue: divisionTabValue,
    statisticianName,
    teamName,
    teams,
    statisticians,
    tournamentBracket,
    divisionsTabs,
    handleTabChange: handleDivisionTabChange,
    handleStatisticianChange,
    handleTeamChange,
    handleStatisticianClear,
    handleTeamClear,
    isLoading: isCalendarLoading,
  } = useScheduleBracket({
    skip: view === ScheduleView.calendar,
    eventId: +eventId,
    orgId: organization?.id as number,
  });

  const handleEditField = useCallback(() => {
    const nextPage = AppRoutes.singleEventPlayOffSchedule.replace(':eventId', eventId);
    const pathname = `${nextPage}?view=${view}`;

    navigate(pathname);
  }, [eventId, navigate, view]);

  const timezone = calendarTimezone || bracketTimezone;

  useTimezone(timezone);

  if (isCalendarLoading || isScheduleLoading) {
    return (
      <Stack alignItems="center" my={8}>
        <CircularProgress size={45} thickness={1} color="secondary" />
      </Stack>
    );
  }

  if (view === ScheduleView.bracket) {
    return (
      <ScheduleBracket
        timezone={timezone}
        tournamentBracketData={tournamentBracket}
        divisionsTabs={divisionsTabs}
        tabValue={divisionTabValue}
        teamName={teamName}
        statisticians={statisticians}
        teams={teams}
        statisticianName={statisticianName}
        onTabChange={handleDivisionTabChange}
        onTeamChange={handleTeamChange}
        onTeamClear={handleTeamClear}
        onStatisticianChange={handleStatisticianChange}
        onStatisticianClear={handleStatisticianClear}
        onEditFieldsOpenChange={handleEditField}
        isEditing={false}
      />
    );
  }

  return (
    <ScheduleCalendar
      timezone={timezone}
      games={games}
      unscheduledList={unscheduledList}
      fields={fields}
      filterData={filterData}
      timeFrames={timeFrames}
      control={control}
      selectedGame={selectedGame}
      onEditFieldsClick={handleEditField}
      onSelectGame={onSelectGame}
      buyGames={buyGames}
      toggleGameDetailsDialog={toggleGameDetailsDialog}
      toggleBuyGamesDialog={toggleBuyGamesDialog}
      isEditable={false}
      isOpenBuyGames={isOpenBuyGames}
      type={ScheduleTypes.PLAY_OFF}
    />
  );
}

export default PlayOffTab;
