import { useTranslation } from 'react-i18next';
import {
  ResponsiveContainer, PieChart, Pie, Cell,
} from 'recharts';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import EmptyData from '../../../components/EmptyData/EmptyData';
import PieLabel from '../../../components/PieWidget/PieLabel';
import type { ISpectatorByPassChart } from '../interfaces/ISpectatorByPassChart';

function SpectatorByPassChart({
  pieData,
}: ISpectatorByPassChart) {
  const { t } = useTranslation();

  const isShowData = pieData.reduce((acc, item) => item.value + acc, 0) > 0;

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h5" lineHeight="24px" mb={3}>
        {t('events.spectatorTickets')}
      </Typography>
      <Grid
        columnSpacing={4.25}
        rowSpacing={4.25}
        container
      >
        {isShowData ? (
          <Grid item xs={12} md={7}>
            <ResponsiveContainer width="100%" height={339}>
              <PieChart width={339} height={339}>
                <Pie
                  data={pieData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={PieLabel}
                  outerRadius="100%"
                  fill="white"
                  dataKey="value"
                >
                  {pieData.map((entry, index) => (
                    <Cell
                      key={`cell-${entry.name}`}
                      fill={pieData[index % pieData.length].color}
                      style={{
                        outline: 'none',
                      }}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Grid>
        ) : null}
        {isShowData ? (
          <Grid item xs={12} md={5}>
            <Grid
              columnSpacing={2}
              rowSpacing={2}
              container
              justifyContent="center"
            >
              {pieData.map((item) => (
                <Grid
                  item
                  xs="auto"
                  md={12}
                  key={item.label}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      '&:before': {
                        content: '""',
                        display: 'block',
                        width: '10px',
                        height: '10px',
                        background: item.color,
                        borderRadius: '50%',
                      },
                    }}
                  >
                    {t(item.label)}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ) : null}
        {!isShowData ? (
          <Grid item xs={12}>
            <EmptyData description={t('common.noData')} />
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
}

export default SpectatorByPassChart;
