import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import GameItem from './GameItem';
import useStyles from './calendarStyle-tss';
import type IUnscheduledList from './interfaces/IUnscheduledList';
import type { ICalendarEventUnschedule } from './interfaces/ICalendarEventUnschedule';

const getListItemHeight = (duration: number) => duration * (13 / 10);

function UnscheduledList({
  unscheduledList,
  onDragStart,
  isEditable,
  onSelectGame,
}: IUnscheduledList) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const onClickGame = (event: ICalendarEventUnschedule) => {
    if (onSelectGame) {
      onSelectGame(event);
    }
  };

  return (
    <Box flex={1}>
      <Typography
        variant="body2"
        fontWeight={600}
        fontSize={12}
        sx={{ mb: '6px' }}
      >
        {t('schedule.unscheduled', { count: unscheduledList?.length || 0 })}
      </Typography>
      <Box
        flex={1}
        className={classes.unscheduled}
      >
        {unscheduledList?.map((event) => (
          <Box
            key={event.id}
            draggable={isEditable}
            onClick={() => onClickGame(event)}
            onDragStart={() => onDragStart(event)}
            className={`rbc-event rbc-division-${event.division}${event.hidden ? ' rbc-event-disabled' : ''}`}
            sx={{ mb: 1, height: getListItemHeight(event.duration) }}
            title={event.title}
          >
            <GameItem
              division={event.division}
              roundName={event.roundName}
              duration={event.duration}
              title={event.title}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default UnscheduledList;
