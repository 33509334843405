import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material/styles';

import COLORS from '../../../constants/colors';
import { AppThemeMode } from '../../../constants/AppThemes';

const useStyles = makeStyles()(
  (theme) => ({
    title: {
      marginBottom: '-16px',
      '& > .MuiTypography-root': {
        fontSize: '24px',
        lineHeight: 0.9,
        textTransform: 'unset',
      },
    },
    head: {
      '.MuiTableCell-root': {
        color: COLORS.bcSecondaryDark,
        '& .MuiTableSortLabel-root.Mui-active': {
          color: 'inherit',
          '.MuiSvgIcon-root': {
            color: 'inherit',
          },
        },
        '& .MuiTableSortLabel-root:hover': {
          color: 'inherit',
        },
      },
    },
    stripped: {
      '&:nth-of-type(odd)': {
        background: theme.palette.mode === AppThemeMode.Dark
          ? alpha(theme.palette.grey[900], 0.5)
          : theme.palette.grey[50],
      },
    },
  }),
);

export default useStyles;
