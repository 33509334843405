import { createSelector } from '@reduxjs/toolkit';

import { getRandomColor, transformDate, transformSpecFormatDate } from '../../../utils/helpers';
import type IBaseQueryResponse from '../../types/IBaseQueryResponse';
import type { IEventEarned } from './interfaces/IEventEarned';
import type { IEventRevenue } from './interfaces/IEventRevenue';
import type { IEventMembersCount } from './interfaces/IEventMembersCount';
import type { ITeamsEarned } from './interfaces/ITeamsEarned';
import type { ISpectatorsEarned } from './interfaces/ISpectatorsEarned';
import type { ISpectatorType } from './interfaces/ISpectatorType';
import type { ISpectatorPass } from './interfaces/ISpectatorPass';
import { SPECTATORS_TICKET_PASS } from '../../../constants/general';

export const selectEventEarnings = createSelector(
  (data: IBaseQueryResponse<IEventEarned> | undefined) => data?.data,
  (data) => ({
    earnings: data?.earnings ? data.earnings : [],
    total: data?.total || 0,
  }),
);

export const selectEventRevenue = createSelector(
  (data: IBaseQueryResponse<IEventRevenue[]> | undefined) => data?.data || [],
  (data) => data.map((item) => ({
    data: transformDate(item.date),
    amount: item.amount,
  })),
);

export const selectEventMembersCount = createSelector(
  (data: IBaseQueryResponse<IEventMembersCount> | undefined) => data?.data,
  (data) => ({
    teamsCount: data?.teamsCount || 0,
    playersCount: data?.playersCount || 0,
    coachesCount: data?.coachesCount || 0,
    earningsTeamTickets: data?.earningsTeamTickets || 0,
  }),
);

export const selectTeamsEarned = createSelector(
  (data: IBaseQueryResponse<ITeamsEarned> | undefined) => data?.data,
  (data) => ({
    rows: data?.rows || [],
    total: {
      fullPriceCount: data?.total.fullPriceCount || 0,
      fullPriceAmount: data?.total.fullPriceAmount || 0,
      divDiscountedCount: data?.total.divDiscountedCount || 0,
      divDiscountedAmount: data?.total.divDiscountedAmount || 0,
      multipleDiscountedCount: data?.total.multipleDiscountedCount || 0,
      multipleDiscountedAmount: data?.total.multipleDiscountedAmount || 0,
      multipleAndDivDiscountedCount: data?.total.multipleAndDivDiscountedCount || 0,
      multipleAndDivDiscountedAmount: data?.total.multipleAndDivDiscountedAmount || 0,
      totalCount: data?.total.totalCount || 0,
      totalAmount: data?.total.totalAmount || 0,
    },
  }),
);

export const selectSpectatorsEarned = createSelector(
  (data: IBaseQueryResponse<ISpectatorsEarned> | undefined) => data?.data,
  (data) => ({
    rows: data?.rows || [],
    total: {
      adultAmount: data?.total.adultAmount || 0,
      adultCount: data?.total.adultCount || 0,
      adultDiscountedAmount: data?.total.adultDiscountedAmount || 0,
      adultDiscountedCount: data?.total.adultDiscountedCount || 0,
      childAmount: data?.total.childAmount || 0,
      childCount: data?.total.childCount || 0,
      childDiscountedAmount: data?.total.childDiscountedAmount || 0,
      childDiscountedCount: data?.total.childDiscountedCount || 0,
      seniorAmount: data?.total.seniorAmount || 0,
      seniorCount: data?.total.seniorCount || 0,
      seniorDiscountedAmount: data?.total.seniorDiscountedAmount || 0,
      seniorDiscountedCount: data?.total.seniorDiscountedCount || 0,
      totalAmount: data?.total.totalAmount || 0,
      totalCount: data?.total.totalCount || 0,
    },
  }),
);

export const selectSpectatorByType = createSelector(
  (data: IBaseQueryResponse<ISpectatorType> | undefined) => data?.data,
  (data) => ({
    childTicketsCount: data?.childTicketsCount || 0,
    adultTicketsCount: data?.adultTicketsCount || 0,
    seniorTicketsCount: data?.seniorTicketsCount || 0,
    total: data?.total || 0,
  }),
);

export const selectSpectatorByPass = createSelector(
  (data: IBaseQueryResponse<ISpectatorPass> | undefined) => data?.data,
  (data) => {
    const wholePass = data?.resultCountPass.reduce((acc, pass) => {
      if (pass.type === SPECTATORS_TICKET_PASS.WHOLE_PASS) {
        acc.value += pass.total;
      }

      return acc;
    }, {
      name: 'Whole tournament pass',
      value: 0,
      color: '#2096F3',
      label: 'Whole tournament pass',
      isText: true,
    });

    const oneDayPass = data?.resultCountPass
      .filter((pass) => pass.type === SPECTATORS_TICKET_PASS.DAY_PASS)
      .map((pass) => ({
        name: `${pass.accessToDay} - 1 day pass`,
        value: pass.total,
        color: getRandomColor(),
        label: `${transformSpecFormatDate(pass.accessToDay, 'M/d/yyyy', 'dd.MM.yyyy')} - 1 day pass`,
        isText: true,
      })) || [];

    const result = [];

    if (wholePass?.value) {
      result.push(wholePass);
    }
    if (oneDayPass.length) {
      result.push(...oneDayPass);
    }

    return result;
  },
);
