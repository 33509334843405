import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';

import SignatureListItem from './components/SignatureListItem';
import SignatureAthletesTable from './components/SignatureAthletesTable';
import EmptyData from '../../components/EmptyData/EmptyData';
import type { ISingleEventSignaturesView } from './interfaces/ISingleEventSignaturesView';
import SignatureFilters from './components/SignatureFilters';

/**
 * Single Event Signatures page view
 */
function SingleEventSignaturesView({
  control,
  waivers,
  pagination,
  onSearchChange,
  onGetTeams,
  onWaiversSearch,
  onApplyFilters,
  onReasetFilters,
  teamsList,
  waiversList,
}: ISingleEventSignaturesView): React.ReactElement {
  const { t } = useTranslation();
  const isEmpty = waivers.length === 0;

  return (
    <>
      <Paper sx={{ p: 3 }}>
        <Stack direction="row" mb={3} alignItems="center">
          <Typography variant="h5" fontWeight={700}>
            {t('events.signatures')}
          </Typography>
          <Box sx={{ ml: 'auto' }}>
            <SignatureFilters
              control={control}
              onSearchChange={onSearchChange}
              onApplyFilters={onApplyFilters}
              onResetFilters={onReasetFilters}
              onGetTeams={onGetTeams}
              onSearchWaiver={onWaiversSearch}
              teamsList={teamsList}
              waiversList={waiversList}
            />
          </Box>
        </Stack>
        <Typography variant="body2" color="text.disabled" mb={3}>
          {t('events.teamsCount', { count: pagination.total })}
        </Typography>
        {isEmpty ? (
          <EmptyData description={t('common.noData')} showDevider />
        ) : (
          <List disablePadding>
            {waivers.map(({ team, players }, index) => (
              <SignatureListItem
                key={[team.id, index].join('_')}
                teamPhoto={team.profilePhoto}
                teamName={team.name}
                isEven={index % 2 !== 0}
              >
                <SignatureAthletesTable athletes={players} />
              </SignatureListItem>
            ))}
          </List>
        )}
      </Paper>
      {isEmpty ? null : (
        <TablePagination
          rowsPerPageOptions={pagination.perPageOptions}
          component="div"
          count={pagination.total}
          rowsPerPage={pagination.perPage}
          page={pagination.page}
          onPageChange={pagination.handleChangePage}
          onRowsPerPageChange={pagination.handleChangePerPage}
        />
      )}
    </>
  );
}

export default SingleEventSignaturesView;
