import type IPPFullInfo from '../store/slices/poolPlays/interfaces/IPPFullInfo';
import type IPOFullInfo from '../store/slices/playOff/interfaces/IPOFullInfo';

interface IParams {
  poolPlaysRules?: {
    data: IPPFullInfo
  },
  playOffRules?: {
    data: IPOFullInfo
  }
}

const getPlayingFieldsWhereParam = ({ poolPlaysRules, playOffRules }: IParams) => {
  if (poolPlaysRules?.data.id) return `pPlaysRulesId: ${poolPlaysRules.data.id}`;
  if (playOffRules?.data.id) return `playOffRulesId: ${playOffRules.data.id}`;
  return null;
};

export default getPlayingFieldsWhereParam;
