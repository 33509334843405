import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import type IBaseDialog from './interfaces/IBaseDialog';

function BaseDialog({
  children,
  open,
  title,
  onClose,
  sx,
}: IBaseDialog) {
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      sx={sx}
      PaperProps={{ elevation: 0 }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

export default BaseDialog;
