import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../../types/TStore';
import type INotificationSlice from './interfaces/INotificationSlice';

const notifications = (state: RootState): INotificationSlice => state.notifications;

const selectIsSubmitDialogOpen = createSelector(notifications, (state) => state.isSubmitDialogOpen);

export default selectIsSubmitDialogOpen;
