import { Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import type ISignatureFiltersSelect from '../interfaces/ISignatureFiltersSelect';

const menuStyle = {
  padding: '12px 0',
  '.MuiMenuItem-root': {
    display: 'block',
    maxWidth: 357,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};

function SignatureFiltersSelect({
  control,
  selectName,
  label,
  children,
  onOpen,
  multiple = false,
  list,
}: ISignatureFiltersSelect) {
  const handleRenderValue = (selected: string | string[]) => {
    if (multiple && Array.isArray(selected) && list) {
      const selectedNames = list
        .filter(({ id }) => selected.includes(String(id)))
        .map(({ name }) => name);
      return selectedNames.join(', ');
    }

    return selected;
  };

  return (
    <Controller
      name={selectName}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth>
          <InputLabel htmlFor={`outlined-${selectName}-name`}>
            {label}
          </InputLabel>
          <Select
            id={`outlined-${selectName}-name`}
            multiple={multiple}
            value={value}
            variant="outlined"
            label={label}
            onChange={onChange}
            onOpen={onOpen}
            renderValue={multiple ? handleRenderValue : undefined}
            MenuProps={{
              MenuListProps: {
                sx: menuStyle,
              },
            }}
          >
            {children}
          </Select>
        </FormControl>
      )}
    />
  );
}

export default SignatureFiltersSelect;
