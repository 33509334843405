import { useCallback, useEffect, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { useGetEventQuery } from '../store/slices/events/apis/eventsApi';
import {
  useCreateDivisionMutation,
  useUpdateDiscountMutation,
  useDeleteDiscountMutation,
  useDeleteDivisionMutation,
  useGetDivisionsListQuery,
  useUpdateDivisionMutation,
  useUpdateDivisionAthleteFieldsMutation,
  useUpdateDivisionUnsavedFieldsMutation,
  useUpdateDivisionCountMutation,
} from '../store/slices/divisions/apis/divisionsApi';
import {
  useCreateTeamTicketRuleMutation,
  useGetTeamTicketRuleLimitsQuery,
  useGetTeamTicketRuleQuery,
  useUpdateTeamTicketRuleMutation,
} from '../store/slices/teamTicketRules/apis/teamTicketRulesApi';
import { selectCreatedEvent } from '../store/slices/events/selectors';
import { selectEventDivisions } from '../store/slices/divisions/selectors';
import { selectEventTeamTicketRule, selectEventTeamTicketRuleLimit } from '../store/slices/teamTicketRules/selectors';
import { createEventStepFourSchema } from '../utils/validators';
import { getErrorMessage, parseNumberFormattedString } from '../utils/helpers';
import { CENTS_PER_DOLLAR, DISCOUNT_OPTION, EVENT_FORM_TYPES } from '../constants/general';
import type { TEventFormTypes } from '../constants/general';
import type { IDivision, IITeamsTicketsFormFields } from '../components/TeamsTicketsForm/interfaces/ITeamsTicketsForm';
import type { TGender } from '../interfaces/TGender';
import type ICreateEventContext from '../layout/CreateEventLayout/interfaces/ICreateEventContext';

interface IHookProps {
  navigate: () => void
  onCancel: (isDirty: boolean) => void
  type: TEventFormTypes
}

const useEventDivisionsForm = ({ navigate, onCancel, type }: IHookProps) => {
  const { t } = useTranslation();
  const {
    eventId, orgId, isFetch, isPublish,
  } = useOutletContext<ICreateEventContext>();
  const fetchQuery = isFetch ? { orgId, eventId: +eventId } : skipToken;

  const { event } = useGetEventQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, event: selectCreatedEvent(result.data) }),
    },
  );
  const { eventDivisions } = useGetDivisionsListQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, eventDivisions: selectEventDivisions(result.data) }),
    },
  );
  const { eventTeamTicketRule } = useGetTeamTicketRuleQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({
        eventTeamTicketRule: selectEventTeamTicketRule(result.data),
      }),
    },
  );
  const { eventTeamTicketRuleLimits } = useGetTeamTicketRuleLimitsQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({
        eventTeamTicketRuleLimits: selectEventTeamTicketRuleLimit(result.data),
      }),
    },
  );

  const [createDivision] = useCreateDivisionMutation();
  const [updateDivision] = useUpdateDivisionMutation();
  const [deleteDivision] = useDeleteDivisionMutation();
  const [updateDiscount] = useUpdateDiscountMutation();
  const [deleteDiscount] = useDeleteDiscountMutation();
  const [createTeamTicketRule] = useCreateTeamTicketRuleMutation();
  const [updateTeamTicketRule] = useUpdateTeamTicketRuleMutation();
  const [updateDivisionAthleteFields] = useUpdateDivisionAthleteFieldsMutation();
  const [updateDivisionUnsavedFields] = useUpdateDivisionUnsavedFieldsMutation();
  const [updateDivisionCount] = useUpdateDivisionCountMutation();

  const {
    control,
    handleSubmit,
    getValues,
    formState: {
      isSubmitting, isDirty,
    },
    setValue,
    reset,
    watch,
    setError,
    resetField,
    trigger,
  } = useForm<IITeamsTicketsFormFields>({
    resolver: yupResolver<IITeamsTicketsFormFields>(createEventStepFourSchema),
    mode: 'onBlur',
    defaultValues: {
      divisionRegistrationEndAt: null,
      ages: [],
      gender: '',
      ageValidateFrom: null,
      limitOfCoaches: 0,
      limitOfAthletes: 0,
      divisions: eventDivisions,
      refundPolicy: eventTeamTicketRule.refundPolicy,
      minCoachLimit: null,
      minPlayerLimit: null,
      isPublished: event.published,
      isRequired: isPublish,
      multipleDiscountAmount: undefined,
      multipleDiscountTeamsCount: undefined,
      multipleDiscountAmountSecond: undefined,
      multipleDiscountTeamsCountSecond: undefined,
      discountOption: DISCOUNT_OPTION.OFF,
    },
  });

  const {
    fields: divisions,
    append: appendDivisionByForm,
    update: updateDivisionByForm,
    remove: removeDivisionByForm,
  } = useFieldArray({ control, name: 'divisions', shouldUnregister: true });

  const previousDivisionsValues = useRef<IDivision[] | null>(null);

  const handleCancel = () => {
    onCancel(isDirty);
  };

  const handleRemoveDiscount = (index?: number | number[]) => {
    const findDivision = typeof index === 'number' && eventId ? divisions[index] : null;

    if (findDivision?.divisionId) {
      deleteDiscount({ orgId, eventId: +eventId, divisionId: findDivision.divisionId })
        .unwrap()
        .then(() => {
          setValue(`divisions.${index as number}.discount`, null);
          toast.success(t('events.discountDeleted'), { position: toast.POSITION.TOP_RIGHT });
        })
        .catch((error) => {
          toast.error(getErrorMessage(error), { position: toast.POSITION.TOP_RIGHT });
        });
    } else {
      setValue(`divisions.${index as number}.discount`, null);
    }
  };

  const handlePrecreateDivisions = async () => {
    const { ages = [], gender = '' } = getValues();

    if (!ages.length) {
      setError('ages', { type: 'required', message: 'Ages is required' });
    }
    if (!gender.length) {
      setError('gender', { type: 'required', message: 'Gender is required' });
    }
    if (ages.length && gender.length) {
      const genderTranslate = t(`events.${gender}`);

      const newDivisions = ages.map((age) => ({
        age,
        gender,
        name: `${genderTranslate} ${age}`,
        ticketCount: null,
        minTicketCountLimit: 0,
        ticketPrice: '',
        discount: null,
      }));
      appendDivisionByForm(newDivisions);
      resetField('ages');
      resetField('gender');
    }
  };

  const handleAddOneDivision = () => appendDivisionByForm({
    age: '',
    gender: '',
    name: '',
    ticketCount: null,
    minTicketCountLimit: 0,
    ticketPrice: '',
    discount: null,
  });

  const handleAppendDiscount = (divisionIndex: number) => {
    const currentDivisions = watch('divisions');
    updateDivisionByForm(
      divisionIndex,
      {
        ...currentDivisions[divisionIndex],
        discount: {
          price: '',
          endAt: null,
        },
      },
    );
  };

  const handlePrecreateDivisionName = (index: number, value: string) => {
    setValue(`divisions.${index}.name`, value);
    trigger(`divisions.${index}.name`);
  };

  const handleCreateSubmit = handleSubmit(async (values) => {
    const divisionsPromises = values.divisions.map((division) => {
      const data = {
        orgId,
        eventId: +eventId,
        ticketCount: division.ticketCount,
        ticketPrice: parseNumberFormattedString(division.ticketPrice) * CENTS_PER_DOLLAR,
        gender: division.gender as TGender,
        age: division.age,
        name: division.name,
      };

      const discount = division.discount ? {
        price: parseNumberFormattedString(division.discount.price) * CENTS_PER_DOLLAR,
        endAt: DateTime.fromJSDate(division.discount.endAt as Date).setZone(event.timezone).toISO(),
      } : undefined;

      const res = [];

      if (division.id && discount) {
        res.push(
          updateDiscount({
            orgId,
            eventId: +eventId,
            divisionId: division.id as number,
            discount,
          }),
        );
      }

      if (event?.published && division.id) {
        const baseData = {
          orgId,
          eventId: +eventId,
          divisionId: division.id as number,
        };

        res.push(
          updateDivisionUnsavedFields({
            ...baseData,
            ticketPrice: data.ticketPrice,
            name: data.name,
          }),
        );

        const prevState = previousDivisionsValues.current?.find((item) => item.id === division.id);
        const isAgeChanged = prevState?.age !== data.age;
        const isGenderChanged = prevState?.gender !== data.gender;
        const isCountChanged = prevState?.ticketCount !== data.ticketCount;

        if (isAgeChanged || isGenderChanged) {
          res.push(
            updateDivisionAthleteFields({
              ...baseData,
              age: data.age,
              gender: data.gender,
            }),
          );
        }

        if (isCountChanged) {
          res.push(
            updateDivisionCount({
              ...baseData,
              ticketCount: data.ticketCount,
            }),
          );
        }
      } else if (division.id) {
        res.push(updateDivision({ ...data, divisionId: division.id as number }));
      } else {
        res.push(createDivision({ ...data, discount }));
      }

      return res;
    }).flat();

    const teamTicketRuleData = {
      orgId,
      eventId: +eventId,
      divisionRegistrationEndAt: DateTime.fromJSDate(values.divisionRegistrationEndAt as Date)
        .setZone(event.timezone).toISO() as string,
      ageValidateFrom: DateTime.fromJSDate(values.ageValidateFrom as Date)
        .setZone(event.timezone)
        .endOf('day').toISO() as string,
      coachLimit: values.limitOfCoaches,
      playerLimit: values.limitOfAthletes,
      multipleDiscountAmount: values.multipleDiscountAmount
        ? parseNumberFormattedString(values.multipleDiscountAmount) * CENTS_PER_DOLLAR : null,
      multipleDiscountTeamsCount: values.multipleDiscountTeamsCount
        ? +values.multipleDiscountTeamsCount : null,
      refundPolicy: values.refundPolicy || null,
      expandedMultipleDiscount: [],
    };

    if (values.discountOption === DISCOUNT_OPTION.OFF) {
      teamTicketRuleData.multipleDiscountAmount = null;
      teamTicketRuleData.multipleDiscountTeamsCount = null;
      teamTicketRuleData.expandedMultipleDiscount = [];
    }

    const teamTicketRuleDataMultiple = {
      orgId,
      eventId: +eventId,
      divisionRegistrationEndAt: DateTime.fromJSDate(values.divisionRegistrationEndAt as Date)
        .setZone(event.timezone, {
          keepLocalTime: !eventTeamTicketRule.teamTicketRuleId,
        }).toISO() as string,
      ageValidateFrom: DateTime.fromJSDate(values.ageValidateFrom as Date)
        .setZone(event.timezone, {
          keepLocalTime: !eventTeamTicketRule.teamTicketRuleId,
        })
        .endOf('day').toISO() as string,
      coachLimit: values.limitOfCoaches,
      playerLimit: values.limitOfAthletes,
      multipleDiscountAmount: null,
      multipleDiscountTeamsCount: null,
      expandedMultipleDiscount: [

        {
          teamsCount: values.multipleDiscountTeamsCount
            ? +values.multipleDiscountTeamsCount : null,
          amount: values.multipleDiscountAmount
            ? parseNumberFormattedString(values.multipleDiscountAmount) * CENTS_PER_DOLLAR : null,
        },
        ...(values.multipleDiscountTeamsCountSecond ? [{
          teamsCount: values.multipleDiscountTeamsCountSecond
            ? +values.multipleDiscountTeamsCountSecond : null,
          amount: values.multipleDiscountAmountSecond
            ? parseNumberFormattedString(values.multipleDiscountAmountSecond) * CENTS_PER_DOLLAR : null,
        }] : []),
      ],
      refundPolicy: values.refundPolicy || null,
    };

    const teamTicketRuleDataCurrent = values.discountOption === DISCOUNT_OPTION.SIMULTANEOUS
      ? teamTicketRuleDataMultiple : teamTicketRuleData;

    const teamTicketRulePromise = eventTeamTicketRule.teamTicketRuleId
      ? updateTeamTicketRule(teamTicketRuleDataCurrent)
      : createTeamTicketRule(teamTicketRuleDataCurrent);

    const divisionsIdsToDelete = eventDivisions
      .filter((division) => !values.divisions.some((item) => item.divisionId === division.divisionId))
      .map((division) => division.divisionId);

    const divisionsDeletePromises = divisionsIdsToDelete.length
      ? divisionsIdsToDelete.map((divisionId) => deleteDivision({
        orgId,
        eventId: +eventId,
        divisionId,
      })) : undefined;

    const requestPromises = [
      ...divisionsPromises,
      teamTicketRulePromise,
      divisionsDeletePromises,
    ].filter(Boolean);

    Promise.all(requestPromises).then((res) => {
      const errors = res
        .map((item) => (item && 'error' in item ? getErrorMessage(item.error) : null))
        .filter(Boolean);

      if (errors.length > 0) {
        errors.forEach((errorMessage) => {
          toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT });
        });
      } else {
        navigate();
        reset();
        if (type === EVENT_FORM_TYPES.EDIT) {
          toast.success(t('events.changesSaved'), { position: toast.POSITION.TOP_RIGHT });
        }
      }
    });
  });

  const resetOption = useCallback(() => {
    let option = DISCOUNT_OPTION.OFF;
    if (eventTeamTicketRule.expandedMultipleDiscount && eventTeamTicketRule.expandedMultipleDiscount.length > 0) {
      option = DISCOUNT_OPTION.SIMULTANEOUS;
    } else if (!eventTeamTicketRule.expandedMultipleDiscount && eventTeamTicketRule.multipleDiscountAmount
       && eventTeamTicketRule.multipleDiscountTeamsCount) {
      option = DISCOUNT_OPTION.SUBSEQUENT;
    }
    return option;
  }, [eventTeamTicketRule.expandedMultipleDiscount,
    eventTeamTicketRule.multipleDiscountAmount,
    eventTeamTicketRule.multipleDiscountTeamsCount]);

  useEffect(() => {
    previousDivisionsValues.current = eventDivisions;

    const defaultDevRegEndAtDate = type === EVENT_FORM_TYPES.CREATE
      && !eventTeamTicketRule.teamTicketRuleId
      ? event.startAt?.setZone(event.timezone, {
        keepLocalTime: true,
      })?.set({ hour: 23, minute: 59 })
      : eventTeamTicketRule?.divisionRegistrationEndAt?.setZone(event.timezone, {
        keepLocalTime: false,
      });

    reset({
      divisions: eventDivisions,
      ageValidateFrom: eventTeamTicketRule.ageValidateFrom
        || DateTime.now()
          .set({
            year: event.startAt?.year, month: 1, day: 1, hour: 23, minute: 59, second: 0, millisecond: 0,
          }),
      divisionRegistrationEndAt: defaultDevRegEndAtDate || event.startAt?.minus({ day: 15 }),
      limitOfCoaches: eventTeamTicketRule.coachLimit,
      limitOfAthletes: eventTeamTicketRule.playerLimit,
      minCoachLimit: eventTeamTicketRuleLimits.maxCoachLimit,
      minPlayerLimit: eventTeamTicketRuleLimits.maxPlayerLimit,
      isPublished: event.published,

      multipleDiscountAmount: eventTeamTicketRule?.expandedMultipleDiscount
        ? `${Number(eventTeamTicketRule.expandedMultipleDiscount[0].amount) / 100}`
        : eventTeamTicketRule.multipleDiscountAmount,
      multipleDiscountTeamsCount: eventTeamTicketRule?.expandedMultipleDiscount
        ? `${Number(eventTeamTicketRule?.expandedMultipleDiscount[0]?.teamsCount)}`
        : eventTeamTicketRule.multipleDiscountTeamsCount,
      multipleDiscountAmountSecond:
        eventTeamTicketRule?.expandedMultipleDiscount && eventTeamTicketRule?.expandedMultipleDiscount[1]
          ? `${Number(eventTeamTicketRule.expandedMultipleDiscount[1]?.amount) / 100}`
          : undefined,
      multipleDiscountTeamsCountSecond:
        eventTeamTicketRule?.expandedMultipleDiscount && eventTeamTicketRule?.expandedMultipleDiscount[1]
          ? `${Number(eventTeamTicketRule?.expandedMultipleDiscount[1]?.teamsCount)}`
          : undefined,

      refundPolicy: eventTeamTicketRule.refundPolicy,
      discountOption: resetOption(),
    });
  }, [reset, eventDivisions, event, type, eventTeamTicketRule, eventTeamTicketRuleLimits, resetOption]);

  return {
    control,
    event,
    divisions,
    handlePrecreateDivisions,
    handleRemoveDivision: removeDivisionByForm,
    handleRemoveDiscount,
    handleAddOneDivision,
    handleAppendDiscount,
    handleCancel,
    handleCreateSubmit,
    isDirty,
    isSubmitting,
    handlePrecreateDivisionName,
  };
};

export default useEventDivisionsForm;
