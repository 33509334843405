import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import TablePagination from '@mui/material/TablePagination';

import tableHeadData from '../../../data/notificationTableCells.json';
import EmptyData from '../../../components/EmptyData/EmptyData';
import useStyles from './notificationTableStyles-tss';
import MessageCell from './MessageCell';
import type INotificationTable from '../interfaces/INotificationTable';

function NotificationTable({
  notifications,
  pagination,
}: INotificationTable) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <>
      <Paper sx={{ mt: 4.25, p: 3 }} elevation={0}>
        <Typography
          variant="h5"
          component="h2"
          fontWeight={700}
        >
          {t('notifications.notificationsLog')}
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow className={classes.head}>
                {tableHeadData.map((item) => (
                  <TableCell key={item.id}>{t(item.label)}</TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {notifications.length ? notifications
                .map((n) => (
                  <TableRow key={['TeamsTable', n.id].join('_')} className={classes.stripped}>
                    <TableCell>
                      {n.recipientTypes.map((item) => t(`notifications.${item}`)).join(', ')}
                    </TableCell>
                    <TableCell>
                      {t(`notifications.${n.type}`)}
                    </TableCell>
                    <TableCell>
                      {n.subject}
                    </TableCell>
                    <MessageCell
                      message={n.message}
                    />
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <EmptyData description={t('common.noData')} />
                    </TableCell>
                  </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <TablePagination
        rowsPerPageOptions={pagination.perPageOptions}
        component="div"
        count={pagination.total}
        rowsPerPage={pagination.perPage}
        page={pagination.page}
        onPageChange={pagination.handleChangePage}
        onRowsPerPageChange={pagination.handleChangePerPage}
      />

    </>
  );
}

export default NotificationTable;
