import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import TeamsTicketsForm from '../../components/TeamsTicketsForm/TeamsTicketsForm';
import CreateEventStepper from '../../components/CreateEventStepper/CreateEventStepper';
import type { ITeamsTicketsView } from './interfaces/ITeamsTicketsView';

/**
 * Team tickets page view
 */
function TeamsTicketsView({
  eventName,
  control,
  divisions,
  eventEndDate,
  eventStartDate,
  maxRegistrationDate,
  activeStep,
  onStepClick,
  onRemoveDivision,
  onRemoveDiscount,
  onPrecreateDivisions,
  onAddOneDivision,
  onAppendDiscount,
  onCreateSubmit,
  onBackStep,
  isSubmitting,
  isPublished,
  maxDiscountDate,
  onPrecreateDevisionName,
}: ITeamsTicketsView): React.ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <CreateEventStepper activeStep={activeStep} onStepClick={onStepClick} />
      <Paper
        sx={{
          px: 8, py: 5, mt: 4, mb: 3,
        }}
        elevation={0}
      >
        <TeamsTicketsForm
          control={control}
          title={t('events.teamsTickets')}
          eventName={eventName}
          eventStartDate={eventStartDate}
          eventEndDate={eventEndDate}
          maxRegistrationDate={maxRegistrationDate}
          divisions={divisions}
          onPrecreateDivisions={onPrecreateDivisions}
          onRemoveDivision={onRemoveDivision}
          onRemoveDiscount={onRemoveDiscount}
          onAddOneDivision={onAddOneDivision}
          onAppendDiscount={onAppendDiscount}
          isPublished={isPublished}
          maxDiscountDate={maxDiscountDate}
          onPrecreateDevisionName={onPrecreateDevisionName}
        />
      </Paper>
      <Stack spacing={1.5} direction="row" justifyContent="flex-end">
        <Button
          variant="outlined"
          onClick={onBackStep}
          sx={{ width: 140 }}
        >
          {t('events.back')}
        </Button>
        <Button
          variant="contained"
          onClick={onCreateSubmit}
          disabled={isSubmitting}
          sx={{ width: 126 }}
        >
          {t('events.continue')}
        </Button>
      </Stack>
    </>
  );
}

export default TeamsTicketsView;
