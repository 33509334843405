import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TodayIcon from '@mui/icons-material/Today';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';

import InfoCard from '../InfoCard/InfoCard';
import type { IEventPeriod } from './interfaces/IEventPeriod';

function EventPeriod({ eventStartDate, eventEndDate }: IEventPeriod): React.ReactElement {
  const { t } = useTranslation();

  return (
    <Box>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <InfoCard
            icon={<TodayIcon />}
            label={t('events.eventStartDate')}
            desc={eventStartDate}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InfoCard
            icon={<InsertInvitationIcon />}
            label={t('events.eventEndDate')}
            desc={eventEndDate}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default EventPeriod;
