import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';

import EmptyData from '../../../components/EmptyData/EmptyData';
import TableSort from '../../../components/TableSort/TableSort';
import useStyles from './teamsTableStyles-tss';
import headCells from '../../../data/teamsTableCells.json';
import type ITeamsTable from '../interfaces/ITeamsTable';

function TeamsTable({
  control,
  teams,
  onSortChange,
  onResend,
}: ITeamsTable) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow className={classes.head}>
            {headCells.map((headCell) => (
              <TableSort
                key={`TeamsHeadCell_${headCell.id}`}
                control={control}
                property={headCell.id}
                label={t(`events.${headCell.label}`)}
                disablePadding={headCell.disablePadding}
                onSortChange={onSortChange}
              />
            ))}
            {onResend ? <TableCell /> : null}
            <TableCell>
              {t('events.coach')}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {teams.length ? teams
            .map((team) => (
              <TableRow key={`TeamsTable_${team.eventTeamId}`} className={classes.stripped}>
                <TableCell padding="normal">
                  {team.id || '-'}
                </TableCell>
                <TableCell>
                  <div className={classes.teamWrap}>
                    <Avatar
                      src={team.profilePhoto || undefined}
                      alt={team.name ? `${team.name} logo` : 'logo'}
                    />
                    <span>{team.name || '-'}</span>
                  </div>
                </TableCell>
                <TableCell>
                  {team.club.name || '-'}
                </TableCell>
                <TableCell>
                  {DateTime.fromISO(team.createdAt).toFormat('ccc, LLL d, t a') || '-'}
                </TableCell>
                <TableCell>
                  {team.homeFieldAddress?.city || '-'}
                </TableCell>
                <TableCell>
                  {team.coach.length
                    ? team.coach.map(({ firstName, lastName }) => `${firstName} ${lastName}`.trim()).join(', ')
                    : '-'}
                </TableCell>
                {onResend ? (
                  <TableCell align="right">
                    <Button variant="outlined" color="primary">
                      {t('events.resend')}
                    </Button>
                  </TableCell>
                ) : null}
              </TableRow>
            )) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <EmptyData description={t('common.noData')} />
                </TableCell>
              </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TeamsTable;
