import { Outlet } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useLayoutEffect } from 'react';

import SubSidebar from '../SubSidebar/SubSidebar';
import settingsMenu from '../../mocks/settingsMenu';
import { setIsShowTitle } from '../../store/slices/page/slice';
import { useAppDispatch } from '../../store/hooks/useApp';

function SettingsLayout() {
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    dispatch(setIsShowTitle({ isShow: false }));

    return () => {
      dispatch(setIsShowTitle({ isShow: true }));
    };
  }, [dispatch]);

  return (
    <Grid container columnSpacing={29} wrap="nowrap">
      <Grid item sx={{ position: 'relative', ml: { xs: -3, lg: -6 } }}>
        <Box sx={{ position: 'fixed', top: 98 }}>
          <SubSidebar menuList={settingsMenu} />
        </Box>
      </Grid>
      <Grid item xs>
        <Outlet />
      </Grid>
    </Grid>
  );
}

export default SettingsLayout;
