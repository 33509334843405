import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { Link, useParams } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import EditOutlined from '@mui/icons-material/EditOutlined';
import Apartment from '@mui/icons-material/Apartment';
import Typography from '@mui/material/Typography';

import PaperBlock from '../../../components/PaperBlock/PaperBlock';
import EmptyData from '../../../components/EmptyData/EmptyData';
import { getRelativePath } from '../../../utils/helpers';
import AppRoutes from '../../../constants/AppRoutes';
import type { IHotelsWidget } from '../interfaces/IHotelsWidget';

const useStyles = makeStyles()({
  title: {
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline',
    },
  },
});

function HotelsWidget({ hotels = [] }: IHotelsWidget): React.ReactElement {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { eventId } = useParams();

  return (
    <PaperBlock
      title={t('events.hotelBookings')}
      endAdornment={(
        <Button
          startIcon={<EditOutlined />}
          component={Link}
          to={`/events/${eventId}${getRelativePath(AppRoutes.singleEventHotels, 'route')}`}
          size="small"
        >
          {t('common.edit')}
        </Button>
      )}
      whiteBg
      noMargin
    >
      <List sx={{ py: 0, mt: -2 }}>
        {hotels.length ? hotels.map((item) => (
          <ListItem key={`HotelWidgetList_${item.id}`} sx={{ px: 0, py: 1.5 }}>
            <ListItemAvatar>
              <Avatar sx={{ color: 'white' }}>
                <Apartment />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={(
                <Typography
                  className={classes.title}
                  component="a"
                  href={item.link}
                  target="_blank"
                  color="primary"
                >
                  {item.name}
                </Typography>
              )}
              secondary={item.address.streetAddress1}
              sx={{ my: 0 }}
            />
          </ListItem>
        )) : <EmptyData description={t('common.noData')} />}
      </List>
    </PaperBlock>
  );
}

export default HotelsWidget;
