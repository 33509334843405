const mainMenu = [
  {
    key: 'dashboard',
    name: 'Dashboard',
    icon: 'space_dashboard_outlined',
    link: '/',
    show: true,
  },
  {
    key: 'events',
    name: 'Events',
    icon: 'today_outlined',
    link: '/events',
    show: true,
  },
  {
    key: 'teams',
    name: 'Teams',
    icon: 'groups_outlined',
    link: '/teams',
    show: false,
  },
  {
    key: 'members',
    name: 'Members',
    icon: 'people_outlined',
    link: '/members',
    show: false,
  },
  {
    key: 'settings',
    name: 'Settings',
    icon: 'settings_outlined',
    link: '/settings',
    show: true,
  },
];

export default mainMenu;
