import { NavLink } from 'react-router-dom';
import ButtonBase from '@mui/material/ButtonBase';
import Icon from '@mui/material/Icon';

import type { ISubSidebar } from './interfaces/ISubSidebar';
import useStyles from './subSidebarStyle-tss';

function SubSidebar({ menuList }: ISubSidebar) {
  const { classes } = useStyles();

  return (
    <nav className={classes.root}>
      {menuList.map((item) => (
        item.show && (
        <ButtonBase
          key={item.key}
          focusRipple
          className={classes.menuHead}
          component={NavLink}
          to={item.link}
        >
          <Icon className={classes.icon}>{item.icon}</Icon>
          <span className={classes.text}>{item.name}</span>
        </ButtonBase>
        )
      ))}
    </nav>

  );
}

export default SubSidebar;
