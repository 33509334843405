import { DateTime } from 'luxon';
import * as yup from 'yup';
import 'yup-phone-lite';
import type { DateRange } from '@mui/x-date-pickers-pro';

import { DATE_RANGE, DISCOUNT_OPTION } from '../constants/general';
import { GameSettingsDialogTypes } from '../pages/LogisticsAndGameSetup/interfaces/ILogisticsAndGameSetupView';
import { validateDateRange } from './helpers';
import type IAddressFields from '../interfaces/IAddressFields';
import type { IHotel, IPartner } from '../pages/HotelsAndPartners/interfaces/IHotelsAndPartnersForm';
import type { TOrder } from '../interfaces/TOrder';
import type { TEventsTableOrderBy } from '../pages/Events/interfaces/IEventsTable';
import type { IDivision, IDiscount } from '../components/TeamsTicketsForm/interfaces/ITeamsTicketsForm';
import type { TGender } from '../interfaces/TGender';
import type { ISocialMedia } from '../interfaces/ISocialMedia';
import type { IFileWithPath } from '../interfaces/IFileWithPath';
import type { IEventTopic } from '../components/CreateEventForm/interfaces/ICreateEventForm';
import type { IDocument } from '../components/DocumentForm/interfaces/IDocumentsForm';
import type { ISpectatorTicket, ITicketVariantFields } from '../components/SpectatorsTicketsForm/interfaces/ISpectatorsTicketsForm';
import type { IDiscountWithId, ISpectatorDiscountWithId } from '../pages/SingleEventDates/interfaces/ISingleEventDatesForm';
import type { TDiscountOptions } from '../interfaces/IDiscountOptions';
import type { TNotificationType, TRecipientsType } from '../constants/general';
import type IGame7x7Settings from '../store/slices/poolPlays/interfaces/IGame7x7Settings';
import type { IGamePoolTeam } from '../store/slices/poolPlays/interfaces/IPoolPlayDivisionRules';

export const ONLY_NUMBERS_REGEXP = /^\d+$/;
export const WEBSITE_REGEXP = /^(https?:\/\/)?([\w.-]+\.[a-zA-Z]+)?(\/\S*)?$/;
export const IS_NOT_URL_REGEX = /^(?!https?:\/\/\S+|http:\/\/\S+|www\.\S+\.\S+|ftp:\/\/\S+|mailto:\S+).*/;
export const TWITTER_LINK_REGEXP = /https?:\/\/(www\.)?twitter\.com\/([a-zA-Z0-9_@+.]+)/;
export const FACEBOOK_LINK_REGEXP = /https?:\/\/(www\.)?facebook\.com\/([a-zA-Z0-9_@+.]+)/;
export const INSTAGRAM_LINK_REGEXP = /https?:\/\/(www\.)?instagram\.com\/([a-zA-Z0-9_@+.]+)/;
export const HUDL_LINK_REGEXP = /(https?:\/\/)?(www\.)?hudl\.com\/profile\/([a-zA-Z0-9_@+.]+)/;
export const TIKTOK_LINK_REGEXP = /https?:\/\/(www\.)?tiktok\.com\/([a-zA-Z0-9_@+.]+)/;
export const YOUTUBE_LINK_REGEXP = /(https?:\/\/)?(www\.)?youtube\.com\/([a-zA-Z0-9_@+.]+)/;

const mapRules = <T>(map: Record<string, unknown>, rule: T): Record<string, T> => (
  Object.keys(map).reduce((newMap, key) => ({ ...newMap, [key]: rule }), {})
);

const addressYup = (whenKey: string) => yup.object<IAddressFields>().when(whenKey, {
  is: true,
  then: (schema) => schema.shape({
    zipCode: yup.string().required(),
    state: yup.string().required(),
    city: yup.string().required(),
    latitude: yup.number().required(),
    longitude: yup.number().required(),
    streetAddress1: yup.string().nullable(),
    streetAddress2: yup.string().nullable(),
  }),
});

const simpleAddressYup = () => yup.object<IAddressFields>().shape({
  zipCode: yup.string().required(),
  state: yup.string().required(),
  city: yup.string().required(),
  latitude: yup.number().required(),
  longitude: yup.number().required(),
  streetAddress1: yup.string().nullable(),
  streetAddress2: yup.string().nullable(),
});

const ticketYup = (whenKey: string) => yup.object().when(whenKey, {
  is: true,
  then: () => yup.object<ISpectatorTicket>().shape({
    price: yup.string().required('Required field'),
    ageRange: yup.string().required('Required field'),
    discount: yup.object<IDiscount>().nullable().when('isDiscount', {
      is: true,
      then: (schema) => schema.shape({
        endAt: yup
          .date()
          .nullable()
          .required('Required field')
          .test('minDate', 'Discount end at date cant be before "Start selling date"', (value, testContext) => DateTime.fromJSDate(value) >= testContext.from?.[testContext.from.length - 1].value.startAt),
        price: yup.string().required('Required field'),
      }),
    }),
    isDiscountDeleted: yup.boolean().default(false),
    isDiscount: yup.boolean().default(false),
  }),
  otherwise: () => yup.object<ISpectatorTicket>().shape({
    price: yup.string(),
    ageRange: yup.string(),
    discount: yup.object<IDiscount>().nullable(),
  }),
});

const spectatorsTicketPass = () => yup.object().shape({
  price: yup.string().required('Required field'),
  ageRange: yup.string().when(['isChildTicket', 'isSeniorTicket'], {
    is: (isChildTicket: boolean, isSeniorTicket: boolean) => isChildTicket || isSeniorTicket,
    then: (schema) => schema.required('Required field'),
  }),
  discount: yup.object<IDiscount>().nullable().when('isDiscount', {
    is: true,
    then: (schema) => schema.shape({
      endAt: yup
        .date()
        .nullable()
        .required('Required field')
        .test('minDate', 'Discount end at date cant be before "Start selling date"', (value, testContext) => DateTime.fromJSDate(value) >= testContext.from?.[testContext.from.length - 1].value.startAt),
      price: yup.string().required('Required field'),
      isDeleting: yup.boolean(),
    }),
  }),
  isDiscountDeleted: yup.boolean().default(false),
  isDiscount: yup.boolean().default(false),
  isChildTicket: yup.boolean().required('Required field'),
  isSeniorTicket: yup.boolean().required('Required field'),
  childTicket: ticketYup('isChildTicket'),
  seniorTicket: ticketYup('isSeniorTicket'),
});

const hotelYup = () => yup.object().shape({
  isRequired: yup.boolean().required().default(true),
  parentId: yup.number(),
  name: yup.string().required('Required field'),
  link: yup.string().matches(WEBSITE_REGEXP, 'String must be website type'),
  address: addressYup('isRequired').when('isRequired', {
    is: true,
    then: (schema) => schema.required('Required field'),
    otherwise: (schema) => schema,
  }),
});

const partnerYup = () => yup.object().shape({
  partnerId: yup.number(),
  name: yup.string().required('Required field'),
  link: yup.string().matches(WEBSITE_REGEXP, 'String must be website type').required('Required field'),
  cover: yup.mixed<IFileWithPath>().nullable().required('Required field'),
});

const documentYup = () => yup.object<IDocument>().shape({
  documentId: yup.number(),
  name: yup.string().required('Required field'),
  file: yup.mixed<File>().required('Required field'),
});

export const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(3).required(),
});

export const eventsFiltersSchema = yup.object().shape({
  search: yup.string(),
  sort: yup.object().shape({
    order: yup.string<TOrder>().required(),
    orderBy: yup.string<TEventsTableOrderBy>().required(),
  }).required(),
  status: yup.string(),
  topicId: yup.string(),
});

export const editEventHotelsSchema = yup.object().shape({
  hotels: yup.array().of<IHotel>(hotelYup()).default([]),
});

export const editEventPartnersSchema = yup.object().shape({
  partners: yup.array().of<IPartner>(partnerYup()).default([]),
});

export const eventWaiversSchema = yup.object().shape({
  waivers: yup.array().of<IDocument>(documentYup()).default([]),
});

export const eventRulesSchema = yup.object().shape({
  rules: yup.array().of<IDocument>(documentYup()).default([]),
});

export const socialMediaSchema = yup.object<ISocialMedia>().shape({
  twitter: yup.string().matches(IS_NOT_URL_REGEX, 'Enter username or correct url'),
  facebook: yup.string().matches(IS_NOT_URL_REGEX, 'Enter username or correct url'),
  instagram: yup.string().matches(IS_NOT_URL_REGEX, 'Enter username or correct url'),
  tiktok: yup.string().matches(IS_NOT_URL_REGEX, 'Enter username or correct url'),
  hudl: yup.string().matches(IS_NOT_URL_REGEX, 'Enter username or correct url'),
  youtube: yup.string().matches(IS_NOT_URL_REGEX, 'Enter username or correct url'),
});

export const editSocialMediaSchema = yup.object().shape({
  socialMedia: socialMediaSchema,
});

export const createEventStepOneSchema = yup.object().shape({
  withRedirect: yup.boolean().required().default(true),
  isPublished: yup.boolean().required().default(false),
  publishAttempt: yup.boolean().required().default(false),
  name: yup.string().max(60).required('Required field'),
  topic: yup.object<IEventTopic>().shape({
    id: yup.number(),
    name: yup.string(),
  }).nullable(),
  tagline: yup.string().max(100),
  address: simpleAddressYup().when('description', {
    is: (description: string) => !description,
    then: (schema) => schema.required('Required field'),
    otherwise: (schema) => schema,
  }),
  description: yup.string().max(512),
  cover: yup.mixed<IFileWithPath>(),
  phone: yup.string()
    .phone('US', 'Please, enter a valid phone number'),
  email: yup.string().email().when('publishAttempt', {
    is: true,
    then: (schema) => schema.required('Required field'),
    otherwise: (schema) => schema,
  }),
  type: yup.string().required('Required field'),
  about: yup.string().max(200),
  startAt: yup.date().nullable().required('Required field'),
  endAt: yup.date().nullable().required('Required field'),
  socialMedia: socialMediaSchema,
});

export const createEventStepTwoSchema = yup.object().shape({
  hotels: yup.array().of<IHotel>(hotelYup()).default([]),
  partners: yup.array().of<IPartner>(partnerYup()).default([]),
});

export const createEventStepThreeSchema = yup.object().shape({
  waivers: yup.array().of<IDocument>(documentYup()).default([]),
  rules: yup.array().of<IDocument>(documentYup()).default([]),
});

export const createEventStepFourSchema = yup.object().shape({
  isPublished: yup.boolean().required().default(false),
  isRequired: yup.boolean().default(false),
  divisionRegistrationEndAt: yup.date().nullable().required(),
  ages: yup.array().of<string>(yup.string().required()).default([]),
  gender: yup.string<TGender>(),
  ageValidateFrom: yup.date().nullable().required(),
  minCoachLimit: yup.number().nullable().default(null),
  minPlayerLimit: yup.number().nullable().default(null),
  limitOfCoaches: yup.number().nullable().default(null),
  // TODO: Return when BE logic will be ready
  // .when(['isPublished', 'minCoachLimit'], {
  //   is: (isPublished: boolean, minCoachLimit: number | null) => isPublished && minCoachLimit,
  //   then: (schema) => schema.min(yup.ref('minCoachLimit')).required('Required field'),
  //   otherwise: (schema) => schema,
  // }),
  limitOfAthletes: yup.number().min(7).nullable().default(null),
  // TODO: Return when BE logic will be ready
  // .when(['isPublished', 'minPlayerLimit'], {
  //   is: (isPublished: boolean, minPlayerLimit: number | null) => isPublished && minPlayerLimit,
  //   then: (schema) => schema.min(yup.ref('minPlayerLimit')).required('Required field'),
  //   otherwise: (schema) => schema,
  // }),
  divisions: yup.array().of<IDivision>(
    yup.object().shape({
      age: yup.string().required('Required field'),
      name: yup.string().required('Required field'),
      gender: yup.string<TGender>().required('Required field'),
      minTicketCountLimit: yup.number().required(),
      ticketCount: yup.number().nullable().default(null)
        .when('isPublished', {
          is: true,
          then: (schema) => schema.min(yup.ref('minTicketCountLimit')),
          otherwise: (schema) => schema,
        })
        .required('Required field'),
      ticketPrice: yup.string().required('Required field'),
      discount: yup.object<IDiscount>().shape({
        endAt: yup
          .date()
          .nullable()
          .required('Required field')
          .test('minDate', 'Should be less than "Team registration ends on"', (value, context) => DateTime.fromJSDate(value) <= context.from?.[context.from.length - 1].value.divisionRegistrationEndAt),
        price: yup.string().required('Required field'),
      }).nullable(),
    }),
  ).default([]).required(),
  refundPolicy: yup.string(),

  multipleDiscountAmount: yup.string().when(['multipleDiscountTeamsCount', 'discountOption'], {
    is: (multipleDiscountTeamsCount: string, discountOption: string) => (
      discountOption === DISCOUNT_OPTION.SIMULTANEOUS
      || discountOption === DISCOUNT_OPTION.SUBSEQUENT
      || !!multipleDiscountTeamsCount
    ),
    then: (schema) => schema.required('Required field'),
  }),
  multipleDiscountTeamsCount: yup.string().when(['multipleDiscountAmount', 'discountOption'], {
    is: (multipleDiscountAmount: string, discountOption: string) => (
      discountOption === DISCOUNT_OPTION.SIMULTANEOUS
      || discountOption === DISCOUNT_OPTION.SUBSEQUENT
      || !!multipleDiscountAmount
    ),
    then: (schema) => schema.required('Required field'),
  }),

  multipleDiscountAmountSecond: yup.string().when(['multipleDiscountTeamsCountSecond', 'discountOption'], {
    is: (multipleDiscountTeamsCountSecond: string) => !!multipleDiscountTeamsCountSecond,
    then: (schema) => schema.required('Required field'),
  }),
  multipleDiscountTeamsCountSecond: yup.string().when(['multipleDiscountAmountSecond', 'discountOption'], {
    is: (multipleDiscountAmountSecond: string) => !!multipleDiscountAmountSecond,
    then: (schema) => schema.required('Required field'),
  }),

  discountOption: yup.string<TDiscountOptions>().required('Required field'),
}, [['multipleDiscountAmount', 'multipleDiscountTeamsCount'],
  ['multipleDiscountAmountSecond', 'multipleDiscountTeamsCountSecond']]);

export const createEventStepFiveSchema = yup.object().shape({
  isPublished: yup.boolean().required().default(false),
  spectatorId: yup.number(),
  tournamentDays: yup.array().of<string>(yup.string().required()).min(1).required('Required field'),
  startAt: yup.date().nullable().required('Required field'),
  endAt: yup.date().nullable().required('Required field'),
  minAvailableTickets: yup.number().default(0),
  availableTickets: yup.number().nullable().default(null)
    .when(['isPublished'], {
      is: (isPublished: boolean, minCoachLimit: number) => isPublished && minCoachLimit > 0,
      then: (schema) => schema.required('Required field'),
      otherwise: (schema) => schema,
    }),
  isOneDayPass: yup.boolean().required('Required field'),
  isWholeTournamentPass: yup.boolean().required('Required field'),
  oneDayPass: yup.object<ITicketVariantFields>().when('isOneDayPass', {
    is: true,
    then: () => spectatorsTicketPass(),
  }),
  wholeTournamentPass: yup.object<ITicketVariantFields>().when('isWholeTournamentPass', {
    is: true,
    then: () => spectatorsTicketPass(),
  }),
});

export const editEventDatesSchema = yup.object().shape({
  isPublished: yup.boolean().required().default(false),
  eventDaysError: yup.boolean().required().default(false),
  startEventDate: yup.date().nullable().required('Required field'),
  endEventDate: yup.date().nullable().required('Required field'),
  endEventRegDate: yup
    .date()
    .nullable()
    .required('Required field')
    .test('minDate', 'Should be less than "Event end date"', (value, context) => DateTime.fromJSDate(value) <= context.from?.[context.from.length - 1].value.endEventDate),
  ageCutoffDate: yup.date().nullable().required('Required field'),
  discounts: yup.array().of<IDiscountWithId>(yup.object().shape({
    divisionId: yup.number().required(),
    price: yup.number().required(),
    endAt: yup
      .date()
      .nullable()
      .required('Required field')
      .test('minDate', 'Should be less than "Team registration ends on"', (value, context) => DateTime.fromJSDate(value) <= context.from?.[context.from.length - 1].value.endEventRegDate),
  })).default([]).required(),
  tournamentDays: yup.array().of<string>(yup.string().required()).min(1).required('Required field')
    .test('eventDaysErrorValidation', 'Error', (value, testContext) => {
      const { eventDaysError, isPublished } = testContext.parent;
      if (eventDaysError && isPublished) {
        return testContext.createError({ message: 'Please, check tournaments days and save them' });
      }
      return true;
    }),
  startSellingDate: yup.date().nullable().required('Required field'),
  endSellingDate: yup
    .date()
    .nullable()
    .required('Required field')
    .test('minDate', 'Should be less than "Event end date"', (value, context) => DateTime.fromJSDate(value).startOf('day') <= context.from?.[context.from.length - 1].value.endEventDate),
  spectatorsOneDay: yup.array().of<ISpectatorDiscountWithId>(yup.object().shape({
    ruleId: yup.number().required(),
    price: yup.number().required(),
    endAt: yup
      .date()
      .nullable()
      .required('Required field')
      .test('minDate', 'Should be less than "End selling date"', (value, context) => DateTime.fromJSDate(value) <= context.from?.[context.from.length - 1].value.endSellingDate),
  })).default([]).required(),
  spectatorsWholeDays: yup.array().of<ISpectatorDiscountWithId>(yup.object().shape({
    ruleId: yup.number().required(),
    price: yup.number().required(),
    endAt: yup
      .date()
      .nullable()
      .required('Required field')
      .test('minDate', 'Should be less than "End selling date"', (value, context) => DateTime.fromJSDate(value) <= context.from?.[context.from.length - 1].value.endSellingDate),
  })).default([]).required(),
});

export const createEventTopicSchema = yup.object().shape({
  name: yup.string().required(),
});

export const spectatorsReplaceDatesSchema = yup.object().shape({
  dates: yup.array().of(
    yup.object().shape({
      dateId: yup.number(),
      accessToDay: yup.date().nullable().required(),
    }),
  ).required(),
  update: yup.array().of(
    yup.object().shape({
      dateId: yup.number().required(),
      accessToDay: yup.date().nullable().required(),
    }),
  ).required().default([]),
  remove: yup.array().of(
    yup.object().shape({
      dateId: yup.number().required(),
    }),
  ).required().default([]),
  add: yup.array().of(
    yup.object().shape({
      accessToDay: yup.date().nullable().required('Required field'),
    }),
  ).required().default([]),
});

export const teamsFiltersSchema = yup.object().shape({
  search: yup.string(),
  clubSearch: yup.string(),
  coachSearch: yup.string(),
  sort: yup.object().shape({
    order: yup.string<TOrder>().required(),
    orderBy: yup.string().required(),
  }).required(),
  clubId: yup.string(),
  coachId: yup.string(),
  eventDivisionId: yup.string(),
});

export const signatureFiltersSchema = yup.object().shape({
  search: yup.string(),
  waiverSearch: yup.string(),
  waiverId: yup.string(),
  teamsIds: yup.array(),
  status: yup.string(),
});

export const timeFrameFormSchema = yup.object().shape({
  editingId: yup.number().integer(),
  timeBetweenGames: yup
    .number()
    .integer()
    .typeError('Must be a number')
    .min(0, 'Time between games must be less then or equal to 90')
    .max(90, 'Time between games must be less then or equal to 90')
    .required('Required field'),
  timeFrames: yup.array().of(
    yup.object().shape({
      frameId: yup.number().nullable(),
      date: yup.mixed<DateTime>().nullable()
        .test('isReq', 'Required field', (value) => value !== undefined && value !== null)
        .test(DATE_RANGE, 'Years range from 2020 to 3000', validateDateRange),
      time: yup.mixed<DateRange<DateTime>>().nullable()
        .test('isReq', 'Required field', (value) => value !== undefined && value !== null)
        .test('isValid', 'Field is not valid', (value) => {
          const [first, second] = value || [];
          return first?.isValid && second?.isValid && first <= second;
        }),
    }),
  ).min(1).required(),
}).required();
export type TTimeFrameFormSchema = yup.InferType<typeof timeFrameFormSchema>;

const dynamicPool: yup.Schema<{ id?: number, name?: string, teams: IGamePoolTeam[] }> = yup.object().shape({
  id: yup.number(),
  name: yup.string(),
  teams: yup.array().of(
    yup.mixed<IGamePoolTeam>().required(),
  ).required(),
}).required();

export const divisionGameSettingFormSchema = yup.object().shape({
  type: yup.string()
    .oneOf<GameSettingsDialogTypes>([GameSettingsDialogTypes.setup, GameSettingsDialogTypes.edit])
    .default(GameSettingsDialogTypes.setup),
  divisionId: yup.number().nullable().default(null).required(),
  game7x7Setting: yup.mixed<IGame7x7Settings>().required(),
  gamesPerTeam: yup.object().shape({
    gamesPerTeam: yup.number().required(),
    teamsInPool: yup.number().required(),
    countPools: yup.number().required(),
  }),
  pools: yup.lazy((map) => yup.object(
    mapRules<typeof dynamicPool>(map, dynamicPool),
  )),
  preferredPlayingFields: yup.array().of(yup.number().required()).required(),
}).required();
export type TDivisionGameSettingFormSchema = yup.InferType<typeof divisionGameSettingFormSchema>;

export const divisionPlayOffGameSettingFormSchema = yup.object().shape({
  type: yup.string()
    .oneOf<GameSettingsDialogTypes>([GameSettingsDialogTypes.setup, GameSettingsDialogTypes.edit])
    .default(GameSettingsDialogTypes.setup),
  divisionId: yup.number().nullable().default(null).required(),
  game7x7Setting: yup.mixed<IGame7x7Settings>().required(),
  finalGame7x7Settings: yup.mixed<IGame7x7Settings>().required(),
  withThirdPlace: yup.boolean().default(false).required(),
  preferredPlayingFields: yup.array().of(yup.number().required()).required(),
}).required();
export type TDivisionPlayOffGameSettingFormSchema = yup.InferType<typeof divisionPlayOffGameSettingFormSchema>;

export const poolPlayFieldFormSchema = yup.object().shape({
  name: yup.string().required(),
  address: simpleAddressYup().required(),
  statisticians: yup.array().of(
    yup.object().shape({
      id: yup.number().required(),
      firstName: yup.string().required(),
      lastName: yup.string().required(),
    }),
  ).default([]),
}).required();
export type TPoolPlayFieldFormSchema = yup.InferType<typeof poolPlayFieldFormSchema>;

export const notificationsSchema = yup.object().shape({
  recipients: yup.object().shape({
    all: yup.boolean().default(true),
    coach: yup.boolean().default(false),
    athleteAndGuardian: yup.boolean().default(false),
    organizationMember: yup.boolean().default(false),
    team: yup.boolean().default(false),
    spectator: yup.boolean().default(false),
  }),
  recipientsValues: yup.array().of(yup.string<TRecipientsType>().required()).required(),
  type: yup.string<TNotificationType>().required('Required field'),
  title: yup.string().max(40).required('Required field'),
  message: yup.string().max(200).required('Required field'),
});

export const gameDetailsSchema = yup.object().shape({
  isUnscheduled: yup.boolean().required().default(true),
  resourceId: yup.number().nullable().when('isUnscheduled', {
    is: true,
    then: (schema) => schema.required('Required field'),
    otherwise: (schema) => schema,
  }),
  startAt: yup.mixed<DateTime>().nullable()
    .test(DATE_RANGE, 'Years range from 2020 to 3000', validateDateRange)
    .when('isUnscheduled', {
      is: true,
      then: (schema) => schema.test('isReq', 'Required field', (value) => value !== undefined && value !== null),
      otherwise: (schema) => schema,
    }),
  statisticianId: yup.number().nullable(),
});
export type TGameDetailsSchema = yup.InferType<typeof gameDetailsSchema>;
