import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import SingleEventSignaturesView from './SingleEventSignaturesView';
import { useAppDispatch, useAppSelector } from '../../store/hooks/useApp';
import { selectUserOrg } from '../../store/slices/user/selectors';
import { useGetEventWaiversWithAgreementQuery, useLazyGetFiltersTeamsListQuery, useLazyGetFiltersWaiversListQuery } from '../../store/slices/waivers/apis/waiversApi';
import { resetWaiversState, setWaiversState } from '../../store/slices/waivers/slice';
import { selectTeamsPageFilters } from '../../store/slices/waivers/selectors';
import useTablePagination from '../../hooks/useTablePagination';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import { signatureFiltersSchema } from '../../utils/validators';
import type ISignatureFiltersForm from './interfaces/ISignatureFiltersForm';
import type { ISearchInputHandler } from '../../components/SearchInput/interfaces/ISearchInput';

function SingleEventSignatures(): React.ReactElement {
  const dispatch = useAppDispatch();
  const { eventId } = useParams();
  const pagination = useTablePagination();
  const organization = useAppSelector(selectUserOrg);
  const filters = useAppSelector(selectTeamsPageFilters);

  const areIdsSettled = organization?.id && eventId;

  const { data: waivers } = useGetEventWaiversWithAgreementQuery(
    areIdsSettled ? {
      orgId: organization.id,
      eventId: +eventId,
      page: pagination.page + 1,
      perPage: pagination.perPage,
      search: filters.search,
      status: filters.status || undefined,
      waiverId: filters.waiverId || undefined,
      teamIds: filters.teamsIds.length ? filters.teamsIds : undefined,
    } : skipToken,
  );
  const [getTeamsList, { data: teamsList }] = useLazyGetFiltersTeamsListQuery();
  const [getWaivers, { data: waiversList }] = useLazyGetFiltersWaiversListQuery();

  const defaultValues = useMemo(() => ({
    search: filters.search,
    waiverSearch: '',
    waiverId: filters.waiverId,
    teamsIds: filters.teamsIds,
    status: filters.status,
  }), [filters]);

  const {
    control,
    getValues,
    reset,
  } = useForm<ISignatureFiltersForm>({
    resolver: yupResolver<ISignatureFiltersForm>(signatureFiltersSchema),
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => () => {
    dispatch(resetWaiversState());
  }, [dispatch]);

  const debouncedSearch = useDebouncedCallback(() => {
    const { search } = getValues();
    dispatch(setWaiversState({ search }));
    pagination.handleChangePage(null, 0);
  });

  const handleSearchChange = ({ search, onChange }: ISearchInputHandler) => {
    onChange(search);
    debouncedSearch();
  };

  const handleGetTeams = () => {
    if (areIdsSettled) {
      getTeamsList({
        orgId: organization?.id as number,
        eventId: +eventId,
        perPage: 50,
      });
    }
  };

  const handleWaiversSearch = () => {
    const { waiverSearch } = getValues();

    if (areIdsSettled) {
      getWaivers({
        orgId: organization?.id as number,
        eventId: +eventId,
        search: waiverSearch,
        perPage: 50,
      });
    }
  };

  const handleApplyFilters = () => {
    const { waiverId, status, teamsIds } = getValues();
    dispatch(setWaiversState({
      waiverId,
      status,
      teamsIds,
    }));
  };

  const handleResetFilters = () => {
    reset({
      search: filters.search,
      waiverSearch: '',
      waiverId: '',
      teamsIds: [],
      status: '',
    });
  };

  return (
    <SingleEventSignaturesView
      control={control}
      waivers={waivers?.data || []}
      pagination={{ total: waivers?.meta.total || 0, ...pagination }}
      onSearchChange={handleSearchChange}
      onGetTeams={handleGetTeams}
      onWaiversSearch={handleWaiversSearch}
      onApplyFilters={handleApplyFilters}
      onReasetFilters={handleResetFilters}
      teamsList={teamsList?.data || []}
      waiversList={waiversList?.data || []}
    />
  );
}

export default SingleEventSignatures;
