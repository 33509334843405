import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import PaperBlock from '../../../components/PaperBlock/PaperBlock';
import EmptyData from '../../../components/EmptyData/EmptyData';
import useStyles from '../../SingleEventAnalytics/components/tableStyles-tss';
import { AppThemeMode } from '../../../constants/AppThemes';
import { useAppSelector } from '../../../store/hooks/useApp';
import { selectThemeMode } from '../../../store/slices/ui/selectors';
import AppRoutes from '../../../constants/AppRoutes';
import { toPriceFormat } from '../../../utils/helpers';
import type { IBreackdownTable } from '../interfaces/IBreackdownTable';

const tableHeadCells = [
  'dashboard.eventName',
  'dashboard.teams',
  'dashboard.spectators',
  'dashboard.verifications',
  'common.total',
];

function BreakdownTable({
  rows,
  total,
}: IBreackdownTable) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const themeMode = useAppSelector(selectThemeMode);
  const colorMode = themeMode === AppThemeMode.Dark ? AppThemeMode.Dark : AppThemeMode.Light;

  return (
    <PaperBlock
      title={t('dashboard.breakdownPerEvent')}
      colorMode={colorMode}
      whiteBg
      noMargin
      titleClassName={classes.title}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow className={classes.head}>
              {tableHeadCells.map((item) => (<TableCell key={item}>{t(item)}</TableCell>))}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.length
              ? (
                <>
                  {rows.map((event) => (
                    <TableRow key={`TeamsTable_${event.eventId}`} className={classes.stripped}>
                      <TableCell>
                        <Link
                          component={NavLink}
                          to={AppRoutes.singleEventAnalytics.replace(':eventId', `${event.eventId}`)}
                          color="primary"
                          underline="hover"
                        >
                          {event.eventName}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {`${toPriceFormat(event.teamsAmount)} (${event.teamsCount})`}
                      </TableCell>
                      <TableCell>
                        {`${toPriceFormat(event.spectatorsAmount)} (${event.spectatorsCount})`}
                      </TableCell>
                      <TableCell>
                        {`${toPriceFormat(event.verificationsAmount)} (${event.verificationsCount})`}
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" fontWeight={700}>
                          {toPriceFormat(event.totalAmount)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow key="TeamsTable_Total" className={classes.stripped}>
                    <TableCell>
                      <Typography variant="body2" fontWeight={700}>
                        {t('common.total')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" fontWeight={700}>
                        {`${toPriceFormat(total.teamsAmount)} (${total.teamsCount})`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" fontWeight={700}>
                        {`${toPriceFormat(total.spectatorsAmount)} (${total.spectatorsCount})`}
                      </Typography>

                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" fontWeight={700}>
                        {`${toPriceFormat(total.verificationsAmount)} (${total.verificationsCount})`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" fontWeight={700}>
                        {toPriceFormat(total.totalAmount)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <EmptyData description={t('common.noData')} />
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </PaperBlock>
  );
}

export default BreakdownTable;
