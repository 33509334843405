import type { Control } from 'react-hook-form';

import type IDivisionsSetupTab from './IDivisionsSetupTab';
import type IEventPlayingField from '../../../store/slices/poolPlays/interfaces/IEventPlayingField';
import type IGameSettingsDialog from './IGameSettingsDialog';
import type { ScheduleTypes } from '../../../constants/general';
import type IPlayOffGameSettingsDialog from './IPlayOffGameSettingsDialog';
import type { TDivisionGameSettingFormSchema, TDivisionPlayOffGameSettingFormSchema } from '../../../utils/validators';

export enum GameSettingsDialogTypes {
  edit = 'edit',
  setup = 'setup',
}

export enum GameSetupModals {
  PoolPlayGameSettings = 'PoolPlayGameSettings',
  PlayOffGameSettings = 'PlayOffGameSettings',
  ApplySettings = 'ApplySettings',
}

export default interface ILogisticsAndGameSetupView extends
  Omit<IGameSettingsDialog, 'divisionRules' | 'isLoading' | 'isOpen' | 'onClose' | 'gameSettingsControl'>,
  IDivisionsSetupTab {
  gameSettingsControl: Control<TDivisionGameSettingFormSchema> | Control<TDivisionPlayOffGameSettingFormSchema>
  type: ScheduleTypes
  tabValue: number
  modalValue: GameSetupModals | null
  fullInfoId?: number
  playingFields: IEventPlayingField[]
  divisionRules?: IGameSettingsDialog['divisionRules'] | IPlayOffGameSettingsDialog['divisionRules']
  poolPlaySetupTabs: {
    text: string
    options: object
  }[]
  onTabChange: (event: React.SyntheticEvent, index: number) => void
  onSetupAll: () => void
  onCloseModal: () => void
  onBackClick: () => void
  onSetupGamesSchedule: () => void
  isDivisionRulesLoading: boolean
  isGame7x7SettingsLoading: boolean
  isGameSettingSubmitting: boolean
  isSubmitting: boolean
  isNextDisabled: boolean
}
