export const getTableHeadCells = (isOverall: boolean) => [
  isOverall ? 'standings.seed' : '#',
  'standings.team',
  'standings.games',
  ...(isOverall ? [] : ['standings.grtstRound']),
  'standings.wins',
  'standings.losses',
  'standings.ties',
  'standings.pointsFor',
  'standings.pointsAgainst',
  'standings.pointDiff',
  ...(isOverall ? ['standings.score'] : []),
];

export default getTableHeadCells;
