export const DEFAULT_PHONE_MASK = '+1 000 000 0000';

export const DEFAULT_PRICE_MASK = {
  mask: '$num',
  blocks: {
    num: {
      mask: Number,
      scale: 2,
      signed: false,
      thousandsSeparator: ',',
      padFractionalZeros: true,
      normalizeZeros: true,
      radix: '.',
      mapToRadix: ['.'],
    },
  },
};
