import { useState } from 'react';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import TableCell from '@mui/material/TableCell';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

import type IMessageCell from '../interfaces/IMessageCell';

function MessageCell({
  message,
}: IMessageCell) {
  const [open, setOpen] = useState(false);

  const isShow = message.length > 120;

  return (
    <>
      <TableCell>
        <Collapse in={!open} timeout="auto" unmountOnExit>
          <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
            {isShow ? message.slice(0, 120).concat('...') : message}
          </Typography>
        </Collapse>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
            {message}
          </Typography>
        </Collapse>
      </TableCell>
      <TableCell
        sx={{
          opacity: isShow ? 1 : 0,
          pointerEvents: isShow ? 'initial' : 'none',
        }}
        align="right"
      >
        <Button
          sx={{
            minWidth: 'unset',
            p: '8px',
          }}
          variant="text"
          color="primary"
          onClick={() => setOpen((prev) => !prev)}
        >
          <ExpandMoreIcon
            sx={{
              fontSize: '24px',
              transform: open ? 'rotate(180deg)' : '',
            }}
          />
        </Button>
      </TableCell>
    </>
  );
}

export default MessageCell;
