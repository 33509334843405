import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextMask from '../TextMask/TextMask';
import { DEFAULT_PRICE_MASK } from '../../constants/textMasks';
import type { IMultipleDiscountSection } from './interfaces/IMultipleDiscountSection';
import { DISCOUNT_OPTION, TEXT_DISCOUNT_OPTION } from '../../constants/general';

export default function MultipleDiscountSection({ control }: IMultipleDiscountSection) {
  const { t } = useTranslation();

  const getTitle = (text: string) => TEXT_DISCOUNT_OPTION[text.toUpperCase()];

  return (
    <Paper sx={{ p: 3 }}>
      <Grid container rowSpacing={3} columnSpacing={5}>
        <Grid item xs={12}>
          <Typography component="h4" variant="h6">
            {t('events.multipleTeamsDiscount')}
          </Typography>
        </Grid>
        <Grid item xs={4} md={4}>
          <Controller
            name="discountOption"
            control={control}
            defaultValue=""
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error: fieldError },
            }) => (
              <FormControl error={!!fieldError} fullWidth>
                <InputLabel id="discountOption-label">
                  {t('events.discount')}
                </InputLabel>
                <Select
                  id="discountOption"
                  labelId="discountOption-label"
                  label={t('events.discount')}
                  value={value}
                  onChange={onChange}
                  onClose={onBlur}
                >
                  {Object.values(DISCOUNT_OPTION).map((name) => (
                    <MenuItem key={name} value={name}>
                      {t(`events.${name}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />

        </Grid>
        <Grid item xs={12} md={8}>
          <Controller
            name="discountOption"
            control={control}
            defaultValue=""
            render={({
              field: { value },
            }) => (
              <Typography variant="body2">
                {t(`events.${getTitle(value || 'off')}`)}
              </Typography>
            )}
          />
        </Grid>
        <Controller
          name="discountOption"
          control={control}
          defaultValue=""
          render={({
            field: { value },
          }) => (
            <>
              <Grid item xs={12} md={6}>
                {value !== DISCOUNT_OPTION.OFF && (
                <Controller
                  name="multipleDiscountAmount"
                  control={control}
                  render={({
                    field,
                    fieldState: { error: fieldError },
                  }) => (
                    <TextField
                      helperText={fieldError?.message}
                      error={!!fieldError}
                      label={t('events.discountPerTeam')}
                      variant="outlined"
                      InputProps={{
                        inputComponent: TextMask as any,
                        inputProps: DEFAULT_PRICE_MASK,
                      }}
                      fullWidth
                      {...field}
                    />
                  )}
                />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {value !== DISCOUNT_OPTION.OFF && (
                  <Controller
                    name="multipleDiscountTeamsCount"
                    control={control}
                    render={({
                      field,
                      fieldState: { error: fieldError },
                    }) => (
                      <TextField
                        helperText={fieldError?.message}
                        error={!!fieldError}
                        label={t('events.minTeamQuantity')}
                        variant="outlined"
                        InputProps={{
                          inputComponent: TextMask as any,
                          inputProps: { mask: Number, min: 0 },
                        }}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                )}
              </Grid>
            </>
          )}
        />
        <Controller
          name="discountOption"
          control={control}
          defaultValue=""
          render={({
            field: { value },
          }) => (
            <>
              <Grid item xs={12} md={6}>
                {value === DISCOUNT_OPTION.SIMULTANEOUS && (
                <Controller
                  name="multipleDiscountAmountSecond"
                  control={control}
                  render={({
                    field,
                    fieldState: { error: fieldError },
                  }) => (
                    <TextField
                      helperText={fieldError?.message}
                      error={!!fieldError}
                      label={t('events.discountPerTeam')}
                      variant="outlined"
                      InputProps={{
                        inputComponent: TextMask as any,
                        inputProps: DEFAULT_PRICE_MASK,
                      }}
                      fullWidth
                      {...field}
                    />
                  )}
                />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {value === DISCOUNT_OPTION.SIMULTANEOUS && (
                  <Controller
                    name="multipleDiscountTeamsCountSecond"
                    control={control}
                    render={({
                      field,
                      fieldState: { error: fieldError },
                    }) => (
                      <TextField
                        helperText={fieldError?.message}
                        error={!!fieldError}
                        label={t('events.minTeamQuantity')}
                        variant="outlined"
                        InputProps={{
                          inputComponent: TextMask as any,
                          inputProps: { mask: Number, min: 0 },
                        }}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                )}
              </Grid>
            </>
          )}
        />
      </Grid>
    </Paper>
  );
}
