import { Controller } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import TextMask from '../TextMask/TextMask';
import type IEligibilityField from './interfaces/IEligibilityField';

export default function EligibilityField({
  control,
  name,
  label,
  limit,
  hint,
  publishedHint,
  isPublished,
}: IEligibilityField) {
  return (
    <Grid item xs={12} md={6}>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error: fieldError },
        }) => (
          <TextField
            helperText={fieldError?.message}
            error={!!fieldError}
            label={label}
            variant="outlined"
            InputProps={{
              inputComponent: TextMask as any,
              inputProps: { mask: Number, min: 0 },
            }}
            fullWidth
            value={value ? value.toString() : null}
            onBlur={onBlur}
            onChange={(e) => onChange(e.target.value || null)}
          />
        )}
      />
      {!isPublished ? (
        <Typography variant="body2" color="text.secondary" mt={2}>
          {hint}
        </Typography>
      ) : null}
      {limit && isPublished ? (
        <Typography variant="body2" color="text.secondary" mt={2}>
          {publishedHint}
        </Typography>
      ) : null}
    </Grid>
  );
}
