export default {
  login: 'Log in',
  userName: 'User Name',
  password: 'Password',
  email: 'Email',
  logout: 'Logout',
  logoutTitle: 'Are you sure you want to log out?',
  logoutDesc: "Logging out will end your current session. Make sure you've saved any unsaved work before proceeding.",
  welcomeTo: 'Welcome to',
  forgotPassword: 'Forgot password',
  resetPassword: 'Reset Password',
  sendResetTo: 'Send reset password link to Your email',
  sendResetLink: 'Send reset link',
  hint: 'Use the app to change your GRIT password',
};
