import mainApi from '../../../apis/mainApi';
import { transformResponseWithTimezone } from '../../../../utils/transformResponseWithTimezone';
import {
  ORG_EVENT_SCHEDULE,
  ORG_EVENT_SCHEDULE_BRACKET,
  ORG_EVENT_SCHEDULE_GAMES_TIME_RANGE,
} from '../../../../constants/requestUrls';
import { POOL_PLAYS_SCHEDULE_GAME_TAG, POOL_PLAYS_SCHEDULE_TAG } from '../../../tags/poolPlays';
import { SCHEDULE_BRACKET_TAG, SCHEDULE_TIME_RANGE_TAG } from '../../../tags/schedule';
import type IScheduleBracket from '../interfaces/IScheduleBracket';
import type IBaseQueryResponse from '../../../types/IBaseQueryResponse';
import type ISchedule from '../../poolPlays/interfaces/ISchedule';
import type IScheduleGamesRequest from '../../poolPlays/interfaces/IScheduleGamesRequest';
import type IResponseMeta from '../../../types/IResponseMeta';
import type IScheduleTimeRangeRequest from '../interfaces/IScheduleTimeRangeRequest';
import type IScheduleTimeRange from '../interfaces/IScheduleTimeRange';

const scheduleApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getScheduleBracket: builder.query<
    IBaseQueryResponse<IScheduleBracket>,
    { orgId: number, eventId: number, eventDivisionId: number }
    >({
      query: ({
        orgId, eventId, eventDivisionId,
      }) => ({
        url: ORG_EVENT_SCHEDULE_BRACKET
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{eventDivisionId}', `${eventDivisionId}`),
        method: 'GET',
      }),
      transformResponse: (data: IBaseQueryResponse<IScheduleBracket>, meta: IResponseMeta) => (
        transformResponseWithTimezone(data, meta)
      ),
      providesTags: (result, error, arg) => (
        result
          ? [
            ...result.data.games.map(({ id }) => ({ type: SCHEDULE_BRACKET_TAG, id })),
            { type: SCHEDULE_BRACKET_TAG, id: arg.eventDivisionId },
            SCHEDULE_BRACKET_TAG,
            POOL_PLAYS_SCHEDULE_TAG,
          ]
          : [
            { type: SCHEDULE_BRACKET_TAG, id: arg.eventDivisionId },
            SCHEDULE_BRACKET_TAG,
            POOL_PLAYS_SCHEDULE_TAG,
          ]
      ),
    }),

    getScheduleGames: builder.query<IBaseQueryResponse<ISchedule>, IScheduleGamesRequest>({
      query: ({
        orgId, eventId, ...params
      }) => ({
        url: ORG_EVENT_SCHEDULE
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
        params,
      }),
      transformResponse: (data: IBaseQueryResponse<ISchedule>, meta: IResponseMeta) => (
        transformResponseWithTimezone(data, meta)
      ),
      providesTags:
        (result) => (
          result ? [
            ...(result?.data?.games?.map(({ id }) => ({ type: POOL_PLAYS_SCHEDULE_GAME_TAG, id })) || []),
            POOL_PLAYS_SCHEDULE_TAG,
          ] : [POOL_PLAYS_SCHEDULE_TAG]
        ),
    }),

    getScheduleTimeRange: builder.query<IBaseQueryResponse<IScheduleTimeRange[]>, IScheduleTimeRangeRequest>({
      query: ({
        orgId, eventId, ...params
      }) => ({
        url: ORG_EVENT_SCHEDULE_GAMES_TIME_RANGE
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
        params,
      }),
      transformResponse: (data: IBaseQueryResponse<IScheduleTimeRange[]>, meta: IResponseMeta) => (
        transformResponseWithTimezone(data, meta)
      ),
      providesTags: [SCHEDULE_TIME_RANGE_TAG],
    }),
  }),
});

export const {
  useGetScheduleBracketQuery,
  useGetScheduleGamesQuery,
  useGetScheduleTimeRangeQuery,
} = scheduleApi;

export default scheduleApi;
