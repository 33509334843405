import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import FilterList from '@mui/icons-material/FilterList';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Controller, useFormState } from 'react-hook-form';

import { useAppSelector } from '../../../store/hooks/useApp';
import { selectUserOrg } from '../../../store/slices/user/selectors';
import { useLazyGetEventsTopicsQuery } from '../../../store/slices/events/apis/eventsApi';
import useMenu from '../../../hooks/useMenu';
import { EventStatuses } from '../../../constants/Statuses';
import type { IEventsFilter } from '../interfaces/IEventsFilter';

function EventsFilter({ control, onResetFilters, onApplyFilters }: IEventsFilter) {
  const { t } = useTranslation();
  const {
    anchorEl,
    menuId: popoverId,
    open,
    handleClick,
    handleClose,
  } = useMenu();
  const org = useAppSelector(selectUserOrg);
  const { dirtyFields } = useFormState({ control });

  const [getEventsTopics, { data: topics }] = useLazyGetEventsTopicsQuery();

  const isShowReset = dirtyFields.topicId || dirtyFields.status;

  const handleFilterClose = () => {
    handleClose();
    onApplyFilters();
  };

  return (
    <>
      <Button
        aria-labelledby={popoverId}
        variant="contained"
        sx={{
          borderRadius: 2, height: 'auto', bgcolor: 'background.default',
        }}
        color="inherit"
        onClick={handleClick}
      >
        <FilterList sx={{ width: 24, height: 24 }} />
      </Button>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 68,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ mb: 2 }}
        elevation={8}
      >
        <Box sx={{ p: 3, pt: 2, width: 400 }}>
          <Stack
            sx={{ mb: 2 }}
            direction="row"
            justifyContent="space-between"
            flexWrap="nowrap"
            gap={2}
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <Typography
                fontSize={14}
                textTransform="uppercase"
                fontWeight="bold"
              >
                {t('events.filters')}
              </Typography>
              {isShowReset ? (
                <Button variant="text" onClick={onResetFilters}>
                  {t('events.reset')}
                </Button>
              ) : null}
            </Stack>
            <IconButton onClick={handleFilterClose} sx={{ mr: -1 }}>
              <Close sx={{ width: 24, height: 24 }} />
            </IconButton>
          </Stack>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Controller
                name="topicId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-topic">
                      {t('events.topic')}
                    </InputLabel>
                    <Select
                      value={value}
                      name="topic"
                      variant="outlined"
                      id="outlined-topic"
                      label={t('events.topic')}
                      onChange={onChange}
                      onOpen={() => (org?.id ? getEventsTopics({ orgId: org.id }) : null)}
                    >
                      <MenuItem value="">
                        <em>{t('events.none')}</em>
                      </MenuItem>
                      {topics?.data.map((item) => (
                        <MenuItem key={`EventsFilterTopic_${item.id}`} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="status"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-status-native-simple">
                      {t('events.status')}
                    </InputLabel>
                    <Select
                      value={value}
                      name="status"
                      variant="outlined"
                      id="outlined-status-native-simple"
                      label={t('events.status')}
                      onChange={onChange}
                    >
                      <MenuItem value="">
                        <em>{t('events.none')}</em>
                      </MenuItem>
                      {(Object.keys(EventStatuses) as Array<keyof typeof EventStatuses>).map((status) => (
                        <MenuItem key={`EventFilterStatus_${status}`} value={status}>
                          {status}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </>
  );
}

export default EventsFilter;
