import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';

import styles from './sass/CookiesAlarm.module.scss';

function CookiesAlarm() {
  const { t } = useTranslation();
  const [isHidden, setIsHidden] = useState<boolean>(true);

  const handleButtonClick = () => {
    localStorage.setItem('cookiesClosed', 'true');
    setIsHidden(true);
  };

  useEffect(() => {
    setIsHidden(localStorage.getItem('cookiesClosed') === 'true');
  }, [isHidden]);

  return !isHidden ? (
    <motion.div
      className={styles.container}
      initial={{
        y: 50,
      }}
      whileInView={{
        y: 0,
        transition: {
          duration: 0.8,
        },
      }}
      viewport={{ once: true }}
    >
      <Typography mb={1.5}>
        We use cookies to ensure that we give you the best experience on our website.
        By continuing, you consent to the use of cookies. Learn more in our
        {' '}
        <Link
          href={`${process.env.REACT_APP_DEFAULT_URL}/privacy-policy`}
          underline="none"
          target="_blank"
        >
          Privacy Policy

        </Link>
        .
      </Typography>
      <Button
        variant="contained"
        onClick={handleButtonClick}
        sx={{ width: 80 }}
      >
        {t('common.ok')}
      </Button>
    </motion.div>
  ) : <div />;
}

export default CookiesAlarm;
