import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import DetailsWidget from './components/DetailsWidget';
import SocialsWidget from './components/SocialsWidget';
import DatesWidget from './components/DatesWidget';
import HotelsWidget from './components/HotelsWidget';
import PartnersWidget from './components/PartnersWidget';
import DivisionsWidget from './components/DivisionsWidget';
import DocumentsWidget from './components/DocumentsWidget';
import SpectatorsTicketsWidget from './components/SpectatorsTicketsWidget';
import AppRoutes from '../../constants/AppRoutes';
import type ISingleEventView from './interfaces/ISingleEventView';

function SingleEventView({
  details,
  socials,
  dates,
  tournamentsDays,
  teamRegistrationDates,
  teamDiscountsList,
  eventSpectatorsDates,
  oneDayTickets,
  wholeEventTickets,
  hotels,
  waivers,
  rules,
  divisions,
  divisionsDetails,
  partners,
  tickets,
  timezone,
}: ISingleEventView) {
  const { t } = useTranslation();

  return (
    <Grid columnSpacing={5} rowSpacing={3} container>
      <Grid item xs={12} md={6}>
        <Grid container spacing={4.25}>
          <Grid item xs={12}>
            <DatesWidget
              dates={dates}
              tournamentsDays={tournamentsDays}
              teamRegistrationDates={teamRegistrationDates}
              teamDiscountsList={teamDiscountsList}
              eventSpectatorsDates={eventSpectatorsDates}
              oneDayTickets={oneDayTickets}
              wholeEventTickets={wholeEventTickets}
            />
          </Grid>
          <Grid item xs={12}>
            <DetailsWidget details={details} />
          </Grid>
          <Grid item xs={12}>
            <DivisionsWidget
              divisions={divisions}
              divisionsDetails={divisionsDetails}
              timezone={timezone}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={4.25}>
          <Grid item xs={12}>
            <SocialsWidget socials={socials} />
          </Grid>
          <Grid item xs={12}>
            <HotelsWidget hotels={hotels} />
          </Grid>
          <Grid item xs={12}>
            <PartnersWidget partners={partners} />
          </Grid>
          <Grid item xs={12}>
            <SpectatorsTicketsWidget
              tickets={tickets}
              timezone={timezone}
            />
          </Grid>
          <Grid item xs={12}>
            <DocumentsWidget
              documents={waivers}
              title={t('events.waivers')}
              editLink={AppRoutes.singleEventWaivers}
            />
          </Grid>
          <Grid item xs={12}>
            <DocumentsWidget
              documents={rules}
              title={t('events.rules')}
              editLink={AppRoutes.singleEventRules}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SingleEventView;
