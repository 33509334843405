import { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';

import { useAppDispatch, useAppSelector } from '../../store/hooks/useApp';
import { setIsShowTitle } from '../../store/slices/page/slice';
import { selectUserOrg } from '../../store/slices/user/selectors';
import { useGetEventQuery } from '../../store/slices/events/apis/eventsApi';
import SplashScreen from '../../components/SplashScreen/SplashScreen';
import PageNotFound from '../Page/PageNotFound';

const useStyles = makeStyles()(
  () => ({
    root: {
      width: '100%',
      maxWidth: 880,
      margin: '0 auto',
    },
  }),
);

function EditEventLayout(): React.ReactElement {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { eventId } = useParams();
  const location = useLocation();
  const organization = useAppSelector(selectUserOrg);
  const isFetch = !!(eventId && organization?.id);

  const { isLoading, isError } = useGetEventQuery(
    isFetch ? { orgId: organization.id, eventId: +eventId } : skipToken,
  );

  useEffect(() => {
    dispatch(setIsShowTitle({ isShow: false }));

    return () => {
      dispatch(setIsShowTitle({ isShow: true }));
    };
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [location.pathname]);

  if (isLoading) {
    return <SplashScreen />;
  }

  if (isError) {
    return <PageNotFound />;
  }

  return (
    <div className={classes.root}>
      <Outlet context={{ eventId, orgId: organization?.id, isFetch }} />
    </div>
  );
}

export default EditEventLayout;
