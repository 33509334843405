import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';

import { useAppSelector, useAppDispatch } from '../../../store/hooks/useApp';
import { selectLeaveModal } from '../../../store/slices/createEvent/selectors';
import { setLeaveModal } from '../../../store/slices/createEvent/slice';

function StepLeaveDialog(): React.ReactElement {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const leaveModal = useAppSelector(selectLeaveModal);

  const handleClose = () => {
    dispatch(setLeaveModal({ isOpen: false, link: '' }));
  };

  useEffect(() => () => {
    dispatch(setLeaveModal({ isOpen: false, link: '' }));
  }, [dispatch]);

  return (
    <Dialog
      open={leaveModal.isOpen}
      onClose={handleClose}
      sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: 600 } }}
    >
      <DialogTitle>
        {t('events.leaveModalTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('events.leaveModalDesc')}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ gap: 3 }}>
        <Button
          component={NavLink}
          to={leaveModal.link}
          color="inherit"
          onClick={handleClose}
        >
          {t('events.leaveWithoutSave')}
        </Button>
        <Button type="submit" variant="contained" onClick={handleClose}>
          {t('events.stayHere')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default StepLeaveDialog;
