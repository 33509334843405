import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';

function CheckIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props} fill="none">
      <rect x="2" y="2" width="20" height="20" rx="2" fill="#02A9F4" />
      <mask id="mask0_5262_46662" maskUnits="userSpaceOnUse" x="2" y="2" width="21" height="20">
        <rect x="2.06934" y="2" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5262_46662)">
        <path d="M10.0273 16.9998L5.27734 12.2498L6.46484 11.0623L10.0273 14.6248L17.6732 6.979L18.8607 8.1665L10.0273 16.9998Z" fill="white" />
      </g>
    </SvgIcon>
  );
}

export default CheckIcon;
