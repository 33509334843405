import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { useCancelEventMutation, useDeleteEventMutation } from '../store/slices/events/apis/eventsApi';
import { useAppSelector } from '../store/hooks/useApp';
import { selectUserOrg } from '../store/slices/user/selectors';
import { getErrorMessage } from '../utils/helpers';
import EventActions from '../constants/EventActions';
import type { ISingleEvent } from '../store/slices/events/interfaces/ISingleEvent';
import type IListEvent from '../store/slices/events/interfaces/IListEvent';

interface IHookProps {
  onSuccess: () => void
  type: EventActions | null
}

const useEventActions = ({ type, onSuccess }: IHookProps) => {
  const { t } = useTranslation();
  const organization = useAppSelector(selectUserOrg);

  const [cancelEvent] = useCancelEventMutation();
  const [deleteEvent] = useDeleteEventMutation();

  const actionsMap: Record<EventActions, (event?: ISingleEvent | IListEvent) => void> = {
    [EventActions.Delete]: (event) => {
      if (event) {
        deleteEvent({
          orgId: organization?.id as number,
          eventId: event.id,
        })
          .unwrap()
          .then(() => {
            toast.success(t('events.deleteSuccess', { eventName: event.name || '' }), { position: toast.POSITION.TOP_RIGHT });
          })
          .catch((error) => toast.error(getErrorMessage(error), { position: toast.POSITION.TOP_RIGHT }))
          .finally(onSuccess);
      }
    },
    [EventActions.Cancel]: (event) => {
      if (event) {
        cancelEvent({
          orgId: organization?.id as number,
          eventId: event.id,
        })
          .unwrap()
          .then(() => toast.success(t('events.cancelSuccess', { eventName: event.name || '' }), { position: toast.POSITION.TOP_RIGHT }))
          .catch((error) => toast.error(getErrorMessage(error), { position: toast.POSITION.TOP_RIGHT }))
          .finally(onSuccess);
      }
    },
    [EventActions.Default]: () => null,
  };

  return type ? actionsMap[type] : actionsMap.default;
};

export default useEventActions;
