import { useTranslation } from 'react-i18next';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';

import { selectCompletedSteps } from '../../store/slices/createEvent/selectors';
import { useAppSelector } from '../../store/hooks/useApp';
import { CREATE_EVENT_STEPS } from '../../constants/general';
import type { ICreateEventStepper } from './interfaces/ICreateEventStepper';

function CreateEventStepper({ activeStep, onStepClick }: ICreateEventStepper): React.ReactElement {
  const { t } = useTranslation();
  const completedSteps = useAppSelector(selectCompletedSteps);

  return (
    <Stepper nonLinear activeStep={activeStep}>
      {CREATE_EVENT_STEPS.map(({ label, route }, index) => (
        <Step
          key={route}
          disabled={!completedSteps[index] || index === activeStep}
          completed={!!(completedSteps[index] && index !== activeStep)}
        >
          <StepButton onClick={() => onStepClick(route)}>
            {t(`events.${label}`)}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
}

export default CreateEventStepper;
