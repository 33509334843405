import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import TextMask from '../../TextMask/TextMask';
import { DEFAULT_PRICE_MASK } from '../../../constants/textMasks';
import { DEFAULT_DATE_FORMAT } from '../../../constants/general';
import type { IDiscountSettings } from '../interfaces/IDiscountSettings';

function DiscountSettings({
  price,
  date,
  errors,
  onPriceChange,
  onDateChange,
  minSalesDate,
  maxSalesDate,
  timezone,
  onRemove,
}: IDiscountSettings): React.ReactElement {
  const { t } = useTranslation();

  return (
    <Paper sx={{ p: 3 }}>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 5 }}>
        <Grid item xs={12}>
          <Typography>
            {t('common.discountSettings')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            helperText={errors?.price?.message}
            error={!!errors?.price}
            onChange={onPriceChange}
            value={price}
            label={t('common.priceWithDiscount')}
            variant="outlined"
            InputProps={{
              inputComponent: TextMask as any,
              inputProps: DEFAULT_PRICE_MASK,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePicker
            label={t('common.discountSalesStopsOn')}
            value={date}
            onChange={onDateChange}
            slotProps={{
              textField: {
                error: !!errors?.endAt,
                helperText: errors?.endAt?.message,
                fullWidth: true,
              },
            }}
            format={DEFAULT_DATE_FORMAT}
            minDate={minSalesDate}
            maxDate={maxSalesDate}
            timezone={timezone || undefined}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            size="small"
            color="error"
            onClick={onRemove}
            style={{ marginLeft: -8 }}
          >
            {t('common.removeDiscountSales')}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default DiscountSettings;
