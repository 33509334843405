import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { useCreateEventsTopicMutation } from '../../../store/slices/events/apis/eventsApi';
import { selectUserOrg } from '../../../store/slices/user/selectors';
import { useAppSelector } from '../../../store/hooks/useApp';
import { createEventTopicSchema } from '../../../utils/validators';
import type IEventTopic from '../../../store/slices/events/interfaces/IEventTopic';

function CreateTopicDialog({ onCreate }: { onCreate: (topic: IEventTopic) => void }): React.ReactElement {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const organization = useAppSelector(selectUserOrg);

  const [createEventsTopic, { isLoading }] = useCreateEventsTopicMutation();

  const {
    control,
    handleSubmit,
    reset,
  } = useForm<{ name: string }>({
    resolver: yupResolver(createEventTopicSchema),
    defaultValues: {
      name: '',
    },
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const handleCreateSubmit = handleSubmit(async (values): Promise<void> => {
    if (organization?.id) {
      const response = await createEventsTopic({ orgId: organization.id, ...values });
      if (!('error' in response)) {
        handleClose();
        onCreate(response.data.data);
      }
    }
  });

  return (
    <div>
      <Button onClick={handleOpen}>
        {t('events.createNewTopic')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: 600 } }}
      >
        <form onSubmit={handleCreateSubmit}>
          <DialogTitle>{t('events.newTopic')}</DialogTitle>
          <DialogContent>
            <Controller
              name="name"
              control={control}
              render={({
                field,
                fieldState: { error: fieldError },
              }) => (
                <TextField
                  helperText={fieldError?.message}
                  error={!!fieldError}
                  label={t('events.topicName')}
                  variant="outlined"
                  sx={{ mt: 1 }}
                  fullWidth
                  onKeyDown={(e) => e.stopPropagation()}
                  {...field}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              {t('events.cancel')}
            </Button>
            <Button type="submit" variant="contained" disabled={isLoading}>
              {t('events.create')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default CreateTopicDialog;
