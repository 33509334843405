import { darken } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import type { SelectChangeEvent } from '@mui/material/Select';

import type IScheduleSelect from './interfaces/IScheduleSelect';

function ScheduleSelect({
  id,
  value,
  items,
  renderValue,
  onChange,
  onClear,
}: IScheduleSelect) {
  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value: newValue },
    } = event;

    onChange(
      typeof newValue === 'string'
        ? []
        : items.filter((item) => newValue.includes(item.value)),
    );
  };

  return (
    <FormControl sx={{ m: 1, width: 163 }}>
      <Select
        id={id}
        value={value.map((item) => item.value)}
        renderValue={(selected) => renderValue(items.filter((item) => selected.includes(item.value)))}
        IconComponent={value.length ? 'div' : undefined}
        endAdornment={value.length ? (
          <IconButton onClick={onClear} size="small">
            <ClearIcon
              sx={(theme) => ({ color: darken(theme.palette.primary.contrastText, 1) })}
            />
          </IconButton>
        ) : null}
        onChange={handleChange}
        input={(
          <InputBase
            sx={(theme) => ({
              py: 1,
              px: 1.5,
              borderRadius: 2,
              bgcolor: value.length ? theme.palette.primary.contrastText : darken(theme.palette.secondary.main, 0.5),
              color: value.length ? darken(theme.palette.primary.contrastText, 1) : 'inherit',
            })}
          />
        )}
        SelectDisplayProps={{ style: { paddingRight: 0 } }}
        displayEmpty
        multiple
      >
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default ScheduleSelect;
