import mainApi from '../../../apis/mainApi';
import {
  ORG_ANALYTICS_TOTAL_EARNED,
  ORG_ANALYTICS_TOTAL_EVENTS,
  ORG_ANALYTICS_TOTAL_EVENTS_EARNED,
  ORG_ANALYTICS_TOTAL_SEASON_EARNED,
} from '../../../../constants/requestUrls';
import { CENTS_PER_DOLLAR } from '../../../../constants/general';
import type IBaseQueryResponse from '../../../types/IBaseQueryResponse';
import type { IBaseQueryRequest } from '../../../types/IBaseQueryRequest';
import type { ITotalEarned } from '../interfaces/ITotalEarned';
import type { ITotalEventsResponse } from '../interfaces/ITotalEventsResponse';
import type { IEventsEarned } from '../interfaces/IEventsEarned';
import type { IMonthEarned } from '../interfaces/IMonthEarned';
import type { ITotalEarnedBySeasonRequest } from '../interfaces/ITotalEarnedBySeasonRequest';

const dashboardApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getTotalEarnedAmount: builder.query<
    IBaseQueryResponse<ITotalEarned>,
    IBaseQueryRequest
    >({
      query: ({ orgId }) => ({
        url: ORG_ANALYTICS_TOTAL_EARNED
          .replace('{organizationId}', `${orgId}`),
        method: 'GET',
      }),
      transformResponse: (data: IBaseQueryResponse<ITotalEarned>) => ({
        ...data,
        data: {
          verificationsAmount: data.data.verificationsAmount / CENTS_PER_DOLLAR,
          spectatorsAmount: data.data.spectatorsAmount / CENTS_PER_DOLLAR,
          teamsAmount: data.data.teamsAmount / CENTS_PER_DOLLAR,
          totalAmount: data.data.totalAmount / CENTS_PER_DOLLAR,
        },
      }),
    }),

    getTotalEventsByStatus: builder.query<
    IBaseQueryResponse<ITotalEventsResponse>,
    IBaseQueryRequest
    >({
      query: ({ orgId }) => ({
        url: ORG_ANALYTICS_TOTAL_EVENTS
          .replace('{organizationId}', `${orgId}`),
        method: 'GET',
      }),
    }),

    getTotalEarnedByEvent: builder.query<
    IBaseQueryResponse<IEventsEarned>,
    IBaseQueryRequest
    >({
      query: ({ orgId }) => ({
        url: ORG_ANALYTICS_TOTAL_EVENTS_EARNED
          .replace('{organizationId}', `${orgId}`),
        method: 'GET',
      }),
      transformResponse: (data: IBaseQueryResponse<IEventsEarned>) => ({
        ...data,
        data: {
          rows: data.data.rows.map((itemData) => ({
            ...itemData,
            teamsAmount: itemData.teamsAmount / CENTS_PER_DOLLAR,
            verificationsAmount: itemData.verificationsAmount / CENTS_PER_DOLLAR,
            spectatorsAmount: itemData.spectatorsAmount / CENTS_PER_DOLLAR,
            totalAmount: itemData.totalAmount / CENTS_PER_DOLLAR,
          })),
          total: {
            ...data.data.total,
            teamsAmount: data.data.total.teamsAmount / CENTS_PER_DOLLAR,
            verificationsAmount: data.data.total.verificationsAmount / CENTS_PER_DOLLAR,
            spectatorsAmount: data.data.total.spectatorsAmount / CENTS_PER_DOLLAR,
            totalAmount: data.data.total.totalAmount / CENTS_PER_DOLLAR,
          },
        },
      }),
    }),

    getTotalEarnedBySeason: builder.query<
    IBaseQueryResponse<IMonthEarned[]>,
    ITotalEarnedBySeasonRequest
    >({
      query: ({ orgId, ...rest }) => ({
        url: ORG_ANALYTICS_TOTAL_SEASON_EARNED
          .replace('{organizationId}', `${orgId}`),
        method: 'GET',
        params: { ...rest },
      }),
      transformResponse: (data: IBaseQueryResponse<IMonthEarned[]>) => ({
        ...data,
        data: data.data.map((itemData) => ({
          ...itemData,
          totalAmount: itemData.totalAmount / CENTS_PER_DOLLAR,
        })),
      }),
    }),
  }),
});

export const {
  useGetTotalEarnedAmountQuery,
  useGetTotalEventsByStatusQuery,
  useGetTotalEarnedByEventQuery,
  useGetTotalEarnedBySeasonQuery,
} = dashboardApi;

export default dashboardApi;
