import { useSortable } from '@dnd-kit/sortable';
import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import type { CSSProperties } from 'react';

import type IPoolItem from '../interfaces/IPoolItem';

const useStyles = makeStyles()(() => ({
  item: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    padding: 16,
    boxSizing: 'border-box',
    listStyle: 'none',
    fontWeight: 400,
    fontSize: '1rem',
    borderRadius: 8,
    gap: 8,
  },
  drag: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 auto',
    touchAction: 'none',
    cursor: 'pointer',
    borderRadius: 5,
    border: 'none',
    outline: 'none',
    appearance: 'none',
    marginRight: 4,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    '&:focus-visible': {
      boxShadow: '0 0 0 2px #4c9ffe',
    },
  },
  divider: {
    width: '100%',
    height: 4,
    borderRadius: '9999px',
    background: '#02A9F4',
    outline: '2px solid rgba(2, 169, 244, .4)',
  },
}));

function PoolItem({ children, id, isError }: IPoolItem) {
  const { classes } = useStyles();
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    isOver,
    overIndex,
    activeIndex,
    active,
    over,
  } = useSortable({ id });

  const activeContainerId = active?.data?.current?.sortable?.containerId;
  const overContainerId = over?.data?.current?.sortable?.containerId;

  const isOverContainer = activeContainerId !== overContainerId && id === over?.id;

  const style: CSSProperties | undefined = isDragging || isOverContainer ? {
    border: '1px dashed #FFF',
    background: 'rgba(255, 255, 255, 0.20)',
  } : {};

  const errorStyle: CSSProperties | undefined = isError ? {
    background: '#793030',
  } : {};

  return (
    <>
      {isOver && !isOverContainer && activeIndex > overIndex && overIndex !== activeIndex ? (
        <Box className={classes.divider} />
      ) : null}

      <Paper
        component="li"
        sx={isDragging ? { '& > *': { visibility: 'hidden' } } : { border: '1px solid transparent' }}
        elevation={8}
        className={classes.item}
        ref={setNodeRef}
        style={{ ...style, ...errorStyle }}
      >
        <button type="button" className={classes.drag} {...attributes} {...listeners} ref={setActivatorNodeRef}>
          <DragIndicatorIcon sx={{ rotate: '90deg', color: '#919eab' }} />
        </button>
        {children}
      </Paper>

      {isOver && !isOverContainer && activeIndex < overIndex && overIndex !== activeIndex ? (
        <Box className={classes.divider} />
      ) : null}
    </>
  );
}

export default PoolItem;
