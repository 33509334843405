import { useForm } from 'react-hook-form';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import {
  useCheckValidityPPScheduleQuery,
  useGetEventPlayingFieldsQuery,
  useGetPPFullInfoQuery,
  useGetPPTimeFrameQuery,
  usePublishPPScheduleMutation,
  useRegeneratePPScheduleMutation,
  useUpdateGamePositionMutation,
} from '../store/slices/poolPlays/apis/poolPlaysApi';
import {
  useCheckValidityPOScheduleQuery,
  useGetPOFullInfoQuery,
  useGetPOTimeFramesQuery,
  usePublishPOScheduleMutation,
  useUpdatePOGamePositionMutation,
} from '../store/slices/playOff/apis/playOffApi';
import { useGetScheduleGamesQuery, useGetScheduleTimeRangeQuery } from '../store/slices/schedule/apis/scheduleApi';
import { selectScheduleFilters, selectScheduleGames } from '../store/slices/poolPlays/selectors';
import { getErrorMessage, logisticsAndGameSetupCheckValidity } from '../utils/helpers';
import { getUniqueDates, groupedByDate, parseStringOrDateToDateTime } from '../utils/dates';
import getPlayingFieldsWhereParam from '../utils/playingFields';
import { compareByAge } from '../utils/poolPlays';
import { DEFAULT_MAX_PER_PAGE, ScheduleTypes } from '../constants/general';
import AppRoutes from '../constants/AppRoutes';
import type { IScheduleGamesFilters } from '../store/slices/poolPlays/interfaces/ISchedule';
import type { ICalendarEvent } from '../components/GameCalendar/interfaces/ICalendarEvent';
import type IPOTimeFrame from '../store/slices/playOff/interfaces/IPOTimeFrame';
import type ITimeFrame from '../store/slices/poolPlays/interfaces/ITimeFrame';

export interface IScheduleCalendarFilterForm extends IScheduleGamesFilters {
  date: string
  search: string
}

export interface IHookProps {
  orgId: number
  eventId: number
  type: ScheduleTypes
}

const useScheduleCalendarFilterForm = ({
  orgId,
  eventId,
  type,
}: IHookProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [selectedGameDetails, setSelectedGameDetails] = useState<ICalendarEvent | undefined>(undefined);
  const [isOpenEditFields, setIsOpenEditFields] = useState(false);
  const [isOpenBuyGames, setIsOpenBuyGames] = useState(false);

  const isPoolPlayType = type === ScheduleTypes.POOL_PLAY;
  const isPlayOffType = type === ScheduleTypes.PLAY_OFF;

  const { control, watch, setValue } = useForm<IScheduleCalendarFilterForm>({
    defaultValues: {
      pools: [],
      divisions: [],
      date: '',
      statisticians: [],
      teams: [],
      search: '',
    },
  });

  const form = watch();

  const [publishPPSchedule, { isLoading: isPPPublishing }] = usePublishPPScheduleMutation();
  const [regenerateGamePositions, { isLoading: isPPRegenerating }] = useRegeneratePPScheduleMutation();
  const [updateGamePosition] = useUpdateGamePositionMutation();
  const {
    data: ppCheckValidity,
    isLoading: isPPValidityLoading,
    refetch: refetchValidityPP,
  } = useCheckValidityPPScheduleQuery(isPoolPlayType ? { orgId, eventId, isInvalidate: true } : skipToken);

  const [publishPOSchedule, { isLoading: isPOPublishing }] = usePublishPOScheduleMutation();
  const [updatePOGamePosition] = useUpdatePOGamePositionMutation();
  const {
    data: poCheckValidity,
    isLoading: isPOValidityLoading,
    refetch: refetchValidityPO,
  } = useCheckValidityPOScheduleQuery(isPlayOffType ? { orgId, eventId, isInvalidate: true } : skipToken);

  const { data: poolPlaysRules, isLoading: isPPRulesLoading } = useGetPPFullInfoQuery(isPoolPlayType
    ? { orgId, eventId: +eventId }
    : skipToken);

  const { data: playOffRules, isLoading: isPORulesLoading } = useGetPOFullInfoQuery(isPlayOffType
    ? { orgId, eventId: +eventId }
    : skipToken);

  const playingFieldsWhereParam = getPlayingFieldsWhereParam({ playOffRules, poolPlaysRules });

  const { data: playingFields, isLoading: isPlayingFieldsLoading } = useGetEventPlayingFieldsQuery(
    playingFieldsWhereParam
      ? {
        orgId,
        eventId,
        perPage: DEFAULT_MAX_PER_PAGE,
        where: playingFieldsWhereParam,
      } : skipToken,
  );

  const {
    data: { data: ppTimeFrames, timezone: ppTimezone } = {},
    isLoading: isPPTimeFramesLoading,
  } = useGetPPTimeFrameQuery(isPoolPlayType
    ? { orgId, eventId }
    : skipToken);

  const {
    data: { data: poTimeFrames, timezone: poTimezone } = {},
    isLoading: isPOTimeFramesLoading,
  } = useGetPOTimeFramesQuery(isPlayOffType
    ? { orgId, eventId }
    : skipToken);

  const timezone = ppTimezone || poTimezone;
  const playsRules = isPoolPlayType ? poolPlaysRules?.data : playOffRules?.data;

  const { data: timeRange, isLoading: isTimeRangeLoading } = useGetScheduleTimeRangeQuery(playsRules?.id ? {
    orgId,
    eventId,
    type,
    playRuleId: isPoolPlayType ? playsRules.id : undefined,
    playOffPlayRuleId: isPlayOffType ? playsRules.id : undefined,
  } : skipToken);

  const { poUniqueDates, poGroupedTimeFrames } = useMemo(
    () => {
      const uniqueDates = getUniqueDates(poTimeFrames, 'startAt', timezone);
      const grouped = groupedByDate<IPOTimeFrame>(poTimeFrames, 'startAt', timezone);

      return {
        poUniqueDates: uniqueDates,
        poGroupedTimeFrames: grouped,
      };
    },
    [timezone, poTimeFrames],
  );

  const { ppUniqueDates, ppGroupedTimeFrames } = useMemo(
    () => {
      const uniqueDates = getUniqueDates(ppTimeFrames, 'startAt', timezone);
      const grouped = groupedByDate<ITimeFrame>(ppTimeFrames, 'startAt', timezone);

      return {
        ppUniqueDates: uniqueDates,
        ppGroupedTimeFrames: grouped,
      };
    },
    [timezone, ppTimeFrames],
  );

  const dates = useMemo(() => {
    if (timeRange) {
      const gamesDates = timeRange.data.map((item) => (
        DateTime.fromISO(item.date).setZone('utc').toISODate()
      )).filter((item): item is string => !!item);
      const timeFramesDates = isPoolPlayType ? ppUniqueDates : poUniqueDates;

      return Array.from(new Set([...gamesDates, ...timeFramesDates])).sort();
    }

    return [];
  }, [timeRange, isPoolPlayType, ppUniqueDates, poUniqueDates]);

  const selectedPPTimeFrames = useMemo(
    () => ppGroupedTimeFrames?.[form.date],
    [ppGroupedTimeFrames, form.date],
  );

  const selectedPOTimeFrames = useMemo(
    () => poGroupedTimeFrames?.[form.date],
    [poGroupedTimeFrames, form.date],
  );

  const scheduleParams = useMemo(
    () => {
      const date = DateTime.fromISO(form.date);
      const startOfDay = date.startOf('day').toISO();
      const endOfDay = date.endOf('day').toISO();

      if (poolPlaysRules && isPoolPlayType && startOfDay && endOfDay) {
        return {
          type: ScheduleTypes.POOL_PLAY,
          startAt: startOfDay,
          endAt: endOfDay,
          playRuleId: poolPlaysRules.data.id,
        };
      }
      if (playOffRules && isPlayOffType && startOfDay && endOfDay) {
        return {
          type: ScheduleTypes.PLAY_OFF,
          startAt: startOfDay,
          endAt: endOfDay,
          playOffPlayRuleId: playOffRules.data.id,
        };
      }

      return undefined;
    },
    [isPoolPlayType, isPlayOffType, poolPlaysRules, playOffRules, form.date],
  );

  const { games, filterData, isLoading: isScheduleLoading } = useGetScheduleGamesQuery(
    scheduleParams ? {
      orgId,
      eventId,
      ...scheduleParams,
    } : skipToken,
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: (result) => ({
        ...result,
        games: selectScheduleGames(result.data, form, isPoolPlayType ? ppCheckValidity?.data : poCheckValidity?.data),
        filterData: selectScheduleFilters(result.data),
      }),
    },
  );

  useEffect(() => {
    if (timeRange?.data && timeRange?.data?.length && dates?.length && !form.date) {
      const gamesDates = timeRange.data.map((item) => (
        DateTime.fromISO(item.date).setZone('utc').toISODate()
      )).filter((item): item is string => !!item);

      setValue('date', gamesDates[0] || '');
    } else if (dates?.length && !form.date) {
      setValue('date', dates[0] || '');
    }
  }, [setValue, dates, form.date, timeRange]);

  const unscheduledGames = useMemo(
    () => games
      ?.filter(({ resourceId, isByeGame }) => !resourceId && !isByeGame)
      .sort(compareByAge),
    [games],
  );

  const buyGames = useMemo(
    () => games
      ?.filter(({ isByeGame }) => isByeGame)
      .sort(compareByAge),
    [games],
  );

  const onUpdateGame = useCallback((movedGame: ICalendarEvent) => {
    const {
      id,
      startAt,
      resourceId,
      statisticianId,
      timeFrame,
      tOrgEventPlayOffRoundId,
      tOrgEventPlayOffRulesId,
      tOrgEventPoolPlaysRulesId,
    } = movedGame;
    const baseBody = { orgId, eventId };
    const currentStatisticianId = !statisticianId && resourceId && playingFields
      ? playingFields.data.find((field) => field.id === resourceId)?.gamesStatisticians[0]?.id
      : statisticianId;
    const baseGameField = {
      gameId: id,
      startTime: (startAt && resourceId && parseStringOrDateToDateTime(startAt)?.toISO()) || null,
      tOrgGamesStatisticianId: currentStatisticianId || null,
      tOrgPlayingFieldId: startAt && resourceId ? resourceId : null,
    };

    if (isPoolPlayType) {
      const gameField = { ...baseGameField, tOrgEventPoolPlaysTimeFrameId: timeFrame?.id || null };
      const body = {
        ...baseBody,
        pPlaysRulesId: tOrgEventPoolPlaysRulesId,
        gameFields: [gameField],
      };

      updateGamePosition(body).unwrap()
        .then(() => {
          if (baseGameField.startTime) {
            setValue('date', DateTime.fromISO(baseGameField.startTime).toISODate() || '');
          }
          setSelectedGameDetails(undefined);
        })
        .catch((error) => {
          toast.error(getErrorMessage(error));
        });
    } else if (isPlayOffType) {
      const gameField = {
        ...baseGameField,
        tOrgEventPlayOffRoundId,
        tOrgEventPlayOffTimeFrameId: timeFrame?.id || null,
      };
      const body = {
        ...baseBody,
        playOffRulesId: tOrgEventPlayOffRulesId,
        gameFields: [gameField],
      };

      updatePOGamePosition(body).unwrap()
        .then(() => {
          if (baseGameField.startTime) {
            setValue('date', DateTime.fromISO(baseGameField.startTime).toISODate() || '');
          }
          setSelectedGameDetails(undefined);
        })
        .catch((error) => {
          toast.error(getErrorMessage(error));
        });
    }
  }, [
    orgId, eventId, isPoolPlayType, isPlayOffType, updateGamePosition, updatePOGamePosition, setValue, playingFields,
  ]);

  const onAddFromUnscheduled = useCallback((game: ICalendarEvent) => {
    onUpdateGame(game);
  }, [onUpdateGame]);

  const onSelectGame = useCallback((game: ICalendarEvent) => {
    setSelectedGameDetails(game);
  }, []);

  const toggleEditFieldsDialog = useCallback(() => {
    setIsOpenEditFields((prev) => !prev);
  }, []);

  const toggleBuyGamesDialog = useCallback(() => {
    setIsOpenBuyGames((prev) => !prev);
  }, []);

  const toggleGameDetailsDialog = useCallback(() => {
    setSelectedGameDetails(undefined);
  }, []);

  const handleValidity = useCallback(async () => {
    const scheduleValidityHandler = isPoolPlayType ? refetchValidityPP : refetchValidityPO;

    const checkValidity = await scheduleValidityHandler().unwrap()
      .then((res) => res.data)
      .catch((error) => {
        toast.error(getErrorMessage(error));
      });

    if (checkValidity) {
      logisticsAndGameSetupCheckValidity(checkValidity, t);
    }
  }, [isPoolPlayType]);

  const onRegeneratePositions = useCallback(async () => {
    if (playsRules) {
      await regenerateGamePositions({ orgId, eventId, pPlaysRulesId: playsRules.id }).unwrap()
        .catch((error) => {
          toast.error(getErrorMessage(error));
        });
      await handleValidity();
    }
  }, [orgId, eventId, playsRules]);

  const onPublishSchedule = useCallback(async () => {
    const body = { orgId, eventId };
    const schedulePublishHandler = isPoolPlayType ? publishPPSchedule : publishPOSchedule;
    const nextPage = AppRoutes.singleEventSchedule.replace(':eventId', `${eventId}`);
    const pathname = `${nextPage}?tab=${isPoolPlayType ? ScheduleTypes.POOL_PLAY : ScheduleTypes.PLAY_OFF}`;

    await handleValidity();

    if (playsRules?.published) {
      navigate(pathname);
    } else {
      schedulePublishHandler(body).unwrap()
        .then(() => {
          navigate(pathname);
        })
        .catch((error) => {
          toast.error(getErrorMessage(error));
        });
    }
  }, [isPoolPlayType, navigate, orgId, eventId, playsRules, handleValidity]);

  return {
    timezone,
    control,
    isOpenBuyGames,
    onAddFromUnscheduled,
    onUpdateGame,
    onSelectGame,
    selectedGame: selectedGameDetails,
    isOpenEditFields,
    games,
    buyGames,
    unscheduledList: unscheduledGames,
    filterData: filterData ? { ...filterData, dates } : undefined,
    timeFrames: isPoolPlayType ? selectedPPTimeFrames : selectedPOTimeFrames,
    fields: playingFields?.data || [],
    toggleEditFieldsDialog,
    toggleGameDetailsDialog,
    toggleBuyGamesDialog,
    onRegeneratePositions,
    onPublishSchedule,
    isPublishing: isPPPublishing || isPOPublishing || isPOValidityLoading || isPPValidityLoading,
    isRegenerating: isPPRegenerating || isPOValidityLoading || isPPValidityLoading,
    isSchedulePublished: playsRules?.published || false,
    fullInfoId: isPoolPlayType ? poolPlaysRules?.data?.id : playOffRules?.data?.id,
    isLoading: isPPRulesLoading || isPORulesLoading || isPlayingFieldsLoading || isPPTimeFramesLoading
      || isPOTimeFramesLoading || isTimeRangeLoading || isScheduleLoading,
    selectedDate: form.date,
  };
};

export default useScheduleCalendarFilterForm;
