import mainApi from '../../../apis/mainApi';
import { EVENT_HOTELS_LIST_TAG } from '../../../tags/hotels';
import { ORG_EVENTS_HOTELS, ORG_HOTELS, ORG_HOTELS_ID } from '../../../../constants/requestUrls';
import type IBaseQueryResponse from '../../../types/IBaseQueryResponse';
import type { IBaseQueryReqWithParams } from '../../../types/IBaseQueryReqWithParams';
import type { IHotel } from '../interfaces/IHotel';
import type { ICreateHotelReq } from '../interfaces/ICreateHotelReq';
import type { IBaseQueryRequest } from '../../../types/IBaseQueryRequest';

const hotelsApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    createHotel: builder.mutation<IBaseQueryResponse<IHotel[]>, ICreateHotelReq>({
      query: ({
        orgId, eventId, name, link, address,
      }) => ({
        url: ORG_HOTELS.replace('{organizationId}', `${orgId}`),
        method: 'POST',
        body: {
          name,
          link,
          address,
          eventId,
        },
      }),
      invalidatesTags: [EVENT_HOTELS_LIST_TAG],
    }),

    updateHotel: builder.mutation<IBaseQueryResponse<IHotel[]>, ICreateHotelReq & { hotelId: number }>({
      query: ({
        orgId, hotelId, name, link, address,
      }) => ({
        url: ORG_HOTELS_ID
          .replace('{organizationId}', `${orgId}`)
          .replace('{id}', `${hotelId}`),
        method: 'PATCH',
        body: {
          name,
          link,
          address,
        },
      }),
      invalidatesTags: (result, error, { hotelId }) => [{ type: EVENT_HOTELS_LIST_TAG, id: hotelId }],
    }),

    deleteHotelByEvent: builder.mutation<IBaseQueryResponse<IHotel[]>, IBaseQueryRequest & { hotelsIds: number[] }>({
      query: ({ orgId, eventId, hotelsIds }) => ({
        url: ORG_EVENTS_HOTELS
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'DELETE',
        body: { hotelsIds },
      }),
      invalidatesTags: [EVENT_HOTELS_LIST_TAG],
    }),

    getAllHotels: builder.query<IBaseQueryResponse<IHotel>, IBaseQueryReqWithParams>({
      query: ({ orgId, where }) => ({
        url: ORG_HOTELS.replace('{organizationId}', `${orgId}`),
        method: 'GET',
        params: { where },
      }),
    }),
  }),
});

export const {
  useCreateHotelMutation,
  useUpdateHotelMutation,
  useDeleteHotelByEventMutation,
} = hotelsApi;

export default hotelsApi;
