import ConnectStripeBanner from './components/ConnectStripeBanner';
import type IPaymentOptionView from './interfaces/IPaymentOptionsView';

function PaymentOptionsView({
  onConnect,
  onDisconnect,
  isShowDisconnect,
  status,
  stripeConnectUrl,
}: IPaymentOptionView): React.ReactElement {
  return (
    <ConnectStripeBanner
      onConnect={onConnect}
      onDisconnect={onDisconnect}
      isShowDisconnect={isShowDisconnect}
      status={status}
      stripeConnectUrl={stripeConnectUrl}
    />
  );
}

export default PaymentOptionsView;
