import { RECIPIENTS_TYPE, type TRecipientsType } from './general';

interface IRecipients {
  name: 'recipients.all'
  | 'recipients.team'
  | 'recipients.coach'
  | 'recipients.athleteAndGuardian'
  | 'recipients.organizationMember'
  | 'recipients.spectator'
  label: string
  value: TRecipientsType
}

const RECIPIENTS: IRecipients[] = [
  {
    name: 'recipients.all',
    label: 'notifications.all',
    value: RECIPIENTS_TYPE.ALL,
  },
  {
    name: 'recipients.team',
    label: 'notifications.teams',
    value: RECIPIENTS_TYPE.TEAM,
  },
  {
    name: 'recipients.coach',
    label: 'notifications.coaches',
    value: RECIPIENTS_TYPE.COACH,
  },
  {
    name: 'recipients.athleteAndGuardian',
    label: 'notifications.athletesAndGuardians',
    value: RECIPIENTS_TYPE.ATHLETE_AND_GUARDIAN,
  },
  {
    name: 'recipients.organizationMember',
    label: 'notifications.organizationMembers',
    value: RECIPIENTS_TYPE.ORGANIZATION_MEMBER,
  },
  {
    name: 'recipients.spectator',
    label: 'notifications.spectators',
    value: RECIPIENTS_TYPE.SPECTATOR,
  },
];

export default RECIPIENTS;
