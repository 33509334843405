import { useTranslation } from 'react-i18next';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import { generateGoogleMapLink, getFullNameByUser } from '../../../utils/helpers';
import type IPlayingFieldRow from '../interfaces/IPlayingFieldRow';

function PlayingFieldRow({
  number,
  data,
  disabled,
  onEdit,
  handleCheck,
  isChecked,
}: IPlayingFieldRow) {
  const { t } = useTranslation();

  return (
    <Paper component={TableRow}>
      {handleCheck ? (
        <TableCell padding="none">
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Checkbox
              checked={isChecked}
              onChange={handleCheck}
            />
          </Stack>
        </TableCell>
      ) : null}
      <TableCell width="5%">
        {number}
      </TableCell>
      <TableCell width="27%" padding="none">
        {data.name}
      </TableCell>
      <TableCell width="27%">
        <Typography
          component="a"
          href={generateGoogleMapLink(data.address.streetAddress1)}
          sx={{ color: '#02A9F4' }}
          target="_blank"
          rel="noreferrer"
        >
          {data.address.streetAddress1}
        </Typography>
      </TableCell>
      <TableCell width="27%" padding="none">
        {data.gamesStatisticians
          .map(({ user }) => getFullNameByUser(user))
          .join(', ')
          || (
            <Button size="small" onClick={onEdit} sx={{ textTransform: 'lowercase', py: 0.5, mx: -1.5 }}>
              {t('schedule.addStatistician')}
            </Button>
          )}
      </TableCell>
      <TableCell width="13%" align="right">
        <Button
          disabled={disabled}
          onClick={onEdit}
          variant="outlined"
        >
          {t('common.edit')}
        </Button>
      </TableCell>
    </Paper>
  );
}

export default PlayingFieldRow;
