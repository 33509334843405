import AppRoutes from '../constants/AppRoutes';

export interface IEventValidation {
  name: string
  path: string
  properties: {
    name: string
    label: string
  }[]
}

const eventValidation: IEventValidation[] = [
  {
    name: 'Create an event',
    path: AppRoutes.createEvent,
    properties: [
      {
        name: 'topicId',
        label: 'Topic',
      },
      {
        name: 'addressId',
        label: 'Address',
      },
      {
        name: 'email',
        label: 'Email',
      },
      {
        name: 'startAt',
        label: 'Start Date',
      },
      {
        name: 'endAt',
        label: 'End Date',
      },
    ],
  },
  {
    name: 'Hotels & Partners',
    path: AppRoutes.hotelsAndPartners,
    properties: [
      {
        name: 'hotels',
        label: 'Hotels',
      },
      {
        name: 'partners',
        label: 'Partners',
      },
    ],
  },
  {
    name: 'Documents',
    path: AppRoutes.documents,
    properties: [
      {
        name: 'waivers',
        label: 'Waivers',
      },
    ],
  },
  {
    name: 'Team tickets',
    path: AppRoutes.teamsTickets,
    properties: [
      {
        name: 'eventDivisions',
        label: 'Divisions',
      },
    ],
  },
];

export default eventValidation;
