import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type IPage from './interfaces/IPage';

export const initialState: IPage = {
  isShowTitle: true,
};

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setIsShowTitle: (state, { payload }: PayloadAction<{ isShow: boolean }>) => {
      state.isShowTitle = payload.isShow;
    },
  },
});

export const { setIsShowTitle } = pageSlice.actions;

export default pageSlice.reducer;
