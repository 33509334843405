import { alpha, darken } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { useNavigate, useParams } from 'react-router-dom';

import useMenu from '../../../hooks/useMenu';
import { STATUSES_PALLET_MAP } from '../../../constants/general';
import type IEventCover from '../interfaces/IEventCover';
import AppRoutes from '../../../constants/AppRoutes';

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      flexGrow: 1,
    },
    cover: {
      position: 'relative',
      width: '100%',
      overflow: 'hidden',
      height: 360,
      backgroundColor: theme.palette.mode === 'dark' ? darken(theme.palette.primary.dark, 0.8) : theme.palette.primary.dark,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      backgroundSize: 'cover',
      textAlign: 'center',
      boxShadow: theme.shadows[7],
      backgroundPosition: 'bottom center',
      borderTopLeftRadius: theme.rounded.medium,
      borderTopRightRadius: theme.rounded.medium,
      '&:before': {
        background: 'linear-gradient(180deg, rgba(17, 20, 24, 0.00) 0%, #111418 57.23%)',
        content: '""',
        position: 'absolute',
        top: -390,
        left: 0,
        right: 0,
        height: 1400,
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 16,
      zIndex: 1,
      height: '100%',
      width: '100%',
      padding: theme.spacing(3),
    },
  }),
);

function EventCover({
  name,
  topic,
  desc,
  cover,
  published,
  onPublish,
  dropdownList,
  status,
}: IEventCover): React.ReactElement {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { eventId } = useParams();
  const {
    anchorEl,
    menuId,
    open,
    handleClick,
    handleClose,
  } = useMenu();

  const handleMessageClick = () => {
    navigate(AppRoutes.singleEventNotifications.replace(':eventId', eventId as string));
  };

  return (
    <div
      className={classes.cover}
      style={{
        backgroundImage: `url(${cover}) `,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Stack direction="row" spacing={3} position="absolute" top={12} right={16} zIndex={2}>
        {!published ? (
          <Button
            variant="contained"
            size="large"
            onClick={onPublish}
          >
            {t('events.publish')}
          </Button>
        ) : null}
        {published ? (
          <Button
            variant="contained"
            size="large"
            onClick={handleMessageClick}
          >
            {t('notifications.message')}
          </Button>
        ) : null}
        <IconButton
          aria-controls={open ? `${menuId}-menu` : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{
            '&:hover': {
              backgroundColor: alpha('#1F1F1F', 0.6),
            },
          }}
        >
          <MoreHorizIcon sx={{ width: 24, height: 24 }} />
        </IconButton>
        <Menu
          id={menuId}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': menuId,
          }}
          slotProps={{
            paper: { style: { width: 120 } },
          }}
          anchorOrigin={{
            vertical: anchorEl?.clientHeight ? anchorEl.clientHeight + 8 : 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {dropdownList.map(({
            title, onClick, disabled, statuses,
          }) => (
            statuses.includes(status) && (
              <MenuItem
                key={`EventActionList_${title}`}
                onClick={() => {
                  onClick();
                  handleClose();
                }}
                disabled={disabled}
              >
                {title}
              </MenuItem>
            )
          ))}
        </Menu>
      </Stack>
      <div className={classes.content}>
        <Typography variant="h4">
          {name}
          {topic?.name ? ` (${topic.name})` : ''}
        </Typography>
        {desc ? (
          <Typography>{desc}</Typography>
        ) : null}
        <Chip
          size="small"
          label={<Typography variant="body2" fontSize={12}>{status}</Typography>}
          sx={{ minWidth: 74, bgcolor: STATUSES_PALLET_MAP[status] }}
        />
      </div>
    </div>
  );
}

export default EventCover;
