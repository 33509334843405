import { createSelector } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import { parseTicketTypeRules } from '../../../utils/spectatorsTickets';
import { SPECTATORS_TICKET_PASS } from '../../../constants/general';
import { formatTournamentsDays, parseISODateTime } from '../../../utils/helpers';
import type IBaseQueryResponse from '../../types/IBaseQueryResponse';
import type { ISpectatorRule } from './interfaces/ISpectatorRule';
import type { ISpectatorsSlice } from './interfaces/ISpectatorsSlice';
import type { RootState } from '../../types/TStore';

const spectators = (state: RootState): ISpectatorsSlice => state.spectators;

export const selectIsDatesDialogOpen = createSelector(spectators, (state) => state.isReplaceDatesDialogOpen);

export const selectEventSpectators = createSelector(
  (data: IBaseQueryResponse<ISpectatorRule> | undefined) => data?.data,
  (spectator) => {
    if (spectator) {
      const dayPassRules = spectator.ticketTypeRules
        .filter(({ pass }) => pass === SPECTATORS_TICKET_PASS.DAY_PASS);
      const wholePassRules = spectator.ticketTypeRules
        .filter(({ pass }) => pass === SPECTATORS_TICKET_PASS.WHOLE_PASS);
      const oneDayPass = parseTicketTypeRules(dayPassRules, spectator.timezone);
      const wholeTournamentPass = parseTicketTypeRules(wholePassRules, spectator.timezone);

      return ({
        spectatorId: spectator?.id,
        startAt: parseISODateTime(spectator.sellStartAt),
        endAt: parseISODateTime(spectator.sellEndAt),
        availableTickets: spectator?.ticketCount,
        tournamentDays: spectator?.oneDayTicketRules ? formatTournamentsDays(spectator.oneDayTicketRules) : undefined,
        ticketTypeRules: spectator?.ticketTypeRules,
        isOneDayPass: dayPassRules?.length > 0,
        isWholeTournamentPass: wholePassRules?.length > 0,
        oneDayTicketRules: spectator?.oneDayTicketRules ? [...spectator.oneDayTicketRules]
          .sort((a, b) => DateTime.fromFormat(a.accessToDay, 'M/d/yyyy').toMillis() - DateTime.fromFormat(b.accessToDay, 'M/d/yyyy').toMillis()) : [],
        oneDayPass,
        wholeTournamentPass,
      });
    }

    return undefined;
  },
);
