import { DateTime } from 'luxon';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FieldError } from 'react-hook-form';
import { toast } from 'react-toastify';

import type { TSocialName } from '../constants/general';
import {
  FACEBOOK_URL,
  GOOGLE_PLACES_ADDRESS_COMPONENTS_TYPES,
  HUDL_URL,
  INSTAGRAM_URL,
  MONTHS,
  SOCIAL_NAMES,
  TIKTOK_URL,
  TWITTER_URL,
  USER_ROLES,
  YOUTUBE,
  TOAST_CONTAINER,
  TIMEFRAME_YEARS_RANGE,
} from '../constants/general';
import eventValidation from '../mocks/eventValidation';
import type { IEventValidation } from '../mocks/eventValidation';
import type IPlaceDetails from '../components/PlacesAutocomplete/interface/IPlaceDetails';
import type IAddressFields from '../interfaces/IAddressFields';
import type { ISingleEvent } from '../store/slices/events/interfaces/ISingleEvent';
import type { ISpectatorOneDayTicketRules } from '../store/slices/events/interfaces/IEventSpectatorRule';
import type { ITotalEarned } from '../store/slices/dashboard/interfaces/ITotalEarned';
import type { ISpectatorType } from '../store/slices/analytics/interfaces/ISpectatorType';
import type ICheckValidity from '../store/slices/poolPlays/interfaces/ICheckValidity';

export const toPriceFormat = (value: number) => Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);

export function hasTruthyProperty(obj: Record<string, any>): boolean {
  return Object.values(obj).some((value) => !!value);
}

export function isImage(file: File | string): boolean {
  const fileName = (typeof file === 'object' && file?.name) || file as string;
  const suffix = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();

  return suffix === 'jpg' || suffix === 'jpeg' || suffix === 'bmp' || suffix === 'png' || suffix === 'heic';
}

export const toWhereFormat = (where: Record<string, any>): string => (
  Object
    .entries(where)
    .reduce((acc: string[], [key, value]) => (
      [...acc, value ? `${key}:${value}` : '']
    ), [])
    .filter((item) => !!item)
    .join(', ')
);

export const preparePlaceDetails = (placeDetails: IPlaceDetails | null): IAddressFields => {
  const getAddressComponent = (type: string): string | undefined => {
    const component = placeDetails?.address_components?.find((item) => item.types.includes(type));
    return component?.short_name || undefined;
  };

  const coordinates = placeDetails?.geometry?.location;
  const zipCode = getAddressComponent(GOOGLE_PLACES_ADDRESS_COMPONENTS_TYPES.POSTAL_CODE);
  const state = getAddressComponent(GOOGLE_PLACES_ADDRESS_COMPONENTS_TYPES.STATE);
  const city = getAddressComponent(GOOGLE_PLACES_ADDRESS_COMPONENTS_TYPES.CITY);

  return {
    zipCode: zipCode || '',
    state: state || '',
    city: city || '',
    streetAddress1: placeDetails?.formatted_address,
    latitude: coordinates?.lat(),
    longitude: coordinates?.lng(),
  };
};

export const getErrorMessage = (error: FetchBaseQueryError | SerializedError | undefined): string | undefined => {
  let result;

  if (error && 'data' in error) {
    if (error.data && typeof error.data === 'object' && 'error' in error.data) {
      if (error.data.error && typeof error.data.error === 'object'
        && 'message' in error.data.error) {
        if (typeof error.data.error.message === 'string') {
          const errorCode = Number.parseInt(error.data.error.message.split(':')[0]);

          if (Number.isNaN(errorCode)) {
            result = `${error.data.error.message}`;
          } else {
            result = error.data.error.message.split(':').slice(1).join(' ');
          }
        }
      }
    } else if (error.data && typeof error.data === 'object' && 'message' in error.data) {
      result = `${error.data.message}`;
    }
  }

  return result;
};

export const extractUsernameFromLink = (text: string, regex: RegExp): string => {
  if (regex.test(text)) {
    const match = text.match(regex);

    return match && match.length ? match[match.length - 1] : text;
  }

  return text;
};

// Single Event data formater helpers

export const createSingleEventDetail = (
  icon: React.ReactNode,
  name: string,
  value?: string | null,
  isLink?: boolean,
  description?: string,
) => ({
  id: crypto.randomUUID(),
  icon,
  name,
  value,
  description,
  isLink,
});

export const createSingleEventDate = (
  name: string,
  icon: React.ReactNode,
  date?: string,
  zone?: string,
  skipTime?: boolean,
) => {
  const dateFormat = skipTime ? 'ccc. LLL dd yyyy' : 'ccc. LLL dd yyyy, t ZZZZ';

  return ({
    id: crypto.randomUUID(),
    icon,
    name,
    date: date ? DateTime.fromISO(date, { locale: 'en-US', zone }).toFormat(dateFormat) : '-',
  });
};

export const createSingleEventDiscountDate = (name: string, date?: string, zone?: string) => ({
  id: crypto.randomUUID(),
  name,
  date: date ? DateTime.fromISO(date, { locale: 'en-US', zone }).toFormat('ccc. LLL dd yyyy, t ZZZZ') : '-',
});

export const formatTournamentsDays = (dates: ISpectatorOneDayTicketRules[]) => {
  const format = 'M/d/yyyy';
  const outputFormat = 'dd LLL yyyy';

  return dates
    .map(({ accessToDay }) => DateTime.fromFormat(accessToDay, format))
    .sort((a, b) => a.toMillis() - b.toMillis())
    .map((date) => date.toFormat(outputFormat));
};
// ------------------------------------

export const createUserSocialLink = (socialName: TSocialName, username?: string | null) => {
  let link;

  if (!username) return undefined;

  switch (socialName) {
    case SOCIAL_NAMES.FACEBOOK:
      link = `${FACEBOOK_URL}${username}`;
      break;

    case SOCIAL_NAMES.INSTAGRAM:
      link = `${INSTAGRAM_URL}${username}`;
      break;

    case SOCIAL_NAMES.HUDL:
      link = `${HUDL_URL}${username}`;
      break;

    case SOCIAL_NAMES.X:
      link = `${TWITTER_URL}${username}`;
      break;

    case SOCIAL_NAMES.YOUTUBE:
      link = `${YOUTUBE}${username}`;
      break;

    default:
      link = `${TIKTOK_URL}${username}`;
      break;
  }

  return link;
};

export const getAddressErrorMessage = (
  errors: FieldError | undefined,
): string | undefined => {
  if (!errors) return undefined;

  if (errors.message) return errors.message;

  const extractErrorsKeys = Object.keys(errors);
  return `Please, provide specific address(${extractErrorsKeys.join(', ')})`;
};

export const getRelativePath = (route: string, relative: 'path' | 'route' = 'path'): string => {
  const parts = route.split('/');
  const lastIndex = parts.findIndex((part) => part.startsWith(':'));
  const prefix = relative === 'path' ? '.' : '';

  if (lastIndex !== -1) {
    const path = parts.slice(lastIndex + 1).join('/');
    return path.length ? `${prefix}/${path}` : prefix;
  }

  return route;
};

export const getOrgLinkInApp = (id: number) => {
  const link = `${process.env.REACT_APP_APPLICATION_URL}tournament-organization`;
  const linkParams = new URLSearchParams({
    id: id.toString(),
    apn: process.env.REACT_APP_APN_GRIT as string,
    ibi: process.env.REACT_APP_APN_GRIT as string,
    isi: '6448632855',
    efr: '1',
  }).toString();

  return `${process.env.REACT_APP_BREAKOUTNETWORK}/?link=${link}?${linkParams}`;
};

export const parseNumberFormattedString = (numberString: string, skipError?: boolean) => {
  const cleanedString = numberString.replace(/[^\d.]/g, '');
  const parsedNumber = parseFloat(cleanedString);

  if (!Number.isNaN(parsedNumber)) {
    return parsedNumber;
  }
  if (skipError) {
    return 0;
  }
  throw new Error('Invalid number format');
};

export const getErrorDetails = (error: FetchBaseQueryError | SerializedError | undefined):
{ property: string }[] | undefined => {
  let result;

  if (error && 'data' in error) {
    if (error.data && typeof error.data === 'object' && 'error' in error.data) {
      if (error.data.error && typeof error.data.error === 'object' && 'details' in error.data.error && typeof error.data.error.details === 'object') {
        result = error.data.error.details as { property: string }[];
      }
    }
  }

  return result;
};

export const findRequiredEventFields = (errors: { property: string }[]): IEventValidation[] => {
  const result: IEventValidation[] = [];

  if (errors) {
    const propertySet = new Set(errors.map((error) => error.property));

    eventValidation.forEach((item) => {
      const matchingProperties = item.properties.filter((prop) => propertySet.has(prop.name));

      if (matchingProperties.length > 0) {
        const existingResult = result.find((elem) => elem.path === item.path);

        if (!existingResult) {
          result.push({
            name: item.name,
            path: item.path,
            properties: matchingProperties,
          });
        } else {
          existingResult.properties.push(...matchingProperties);
        }
      }
    });
  }

  return result;
};

export const extractSocialMedia = (socialMedia?: ISingleEvent['socialMedia']) => ({
  twitter: socialMedia?.twitter || '',
  facebook: socialMedia?.facebook || '',
  instagram: socialMedia?.instagram || '',
  tiktok: socialMedia?.tiktok || '',
  hudl: socialMedia?.hudl || '',
  youtube: socialMedia?.youtube || '',
});

// eslint-disable-next-line max-len
export const parseISODateTime = (isoDate?: string, zone?: string) => (isoDate ? DateTime.fromISO(isoDate, { zone }) : null);

export const getDivisionAgeRange = (minDev: string | null, maxDev: string | null) => {
  if (!minDev) {
    return undefined;
  }

  if (minDev !== maxDev && maxDev) {
    return `${minDev} - ${maxDev}`;
  }

  return minDev;
};

export const generateGoogleMapLink = (address?: string | null) => {
  if (address) {
    const formattedAddress = encodeURIComponent(address);

    return `${process.env.REACT_APP_GOOGLE_API_MAP}/maps/search/?api=1&query=${formattedAddress}`;
  }

  return undefined;
};

export const generateDashboardPieData = (data: ITotalEarned) => ([
  {
    name: 'Team tickets',
    value: data.teamsAmount,
    color: '#9C27B0',
    label: 'dashboard.teamTickets',
  },
  {
    name: 'Verification',
    value: data.verificationsAmount,
    color: '#2096F3',
    label: 'dashboard.verification',
  },
  {
    name: 'Spectators tickets',
    value: data.spectatorsAmount,
    color: '#00BCD4',
    label: 'dashboard.spectatorsTickets',
  },
]);

export const generateSpectatorTypePieData = (data: ISpectatorType) => ([
  {
    name: 'Adult',
    value: data.adultTicketsCount,
    color: '#9C27B0',
    label: 'events.ADULT',
    isText: true,
  },
  {
    name: 'Senior',
    value: data.seniorTicketsCount,
    color: '#2096F3',
    label: 'events.SENIOR',
    isText: true,
  },
  {
    name: 'Child',
    value: data.childTicketsCount,
    color: '#00BCD4',
    label: 'events.CHILD',
    isText: true,
  },
]);

export const getMonthAbbreviation = (monthNumber: number) => {
  if (monthNumber >= 1 && monthNumber <= 12) {
    return MONTHS[monthNumber - 1];
  }
  return 'Invalid Month';
};

export const transformDate = (inputDate: string, format: string = 'dd.MM.') => DateTime.fromISO(inputDate).toFormat(format);

export const transformSpecFormatDate = (
  date: string,
  inputFormat: string,
  outputFormat: string,
) => DateTime.fromFormat(date, inputFormat).toFormat(outputFormat);

export const getRandomColor = () => `#${Math.floor(Math.random() * 2 ** 24).toString(16).padStart(6, '0')}`;

// eslint-disable-next-line max-len
export const getOrgLinkById = (id: number) => `${process.env.REACT_APP_BREAKOUTNETWORK}/?link=https%3A%2F%2Fwww.gritnetwork.com%2Ftournament-organization%3Fid%3D${id}&apn=com.gritnetwork&isi=6448632855&ibi=com.gritnetwork&efr=1&ofl=https%3A%2F%2Fwww.gritnetwork.com`;

export const checkAdmin = (
  permission : string[],
) => permission.findIndex((item) => item === USER_ROLES.ADMIN) !== -1;

export const boolToWord = (bool: boolean | undefined) => {
  if (bool === undefined) {
    return null;
  }

  return bool ? 'Yes' : 'No';
};

export const chunkArray = <T>(array: T[], chunkSize: number): T[][] => {
  if (chunkSize <= 0) {
    throw new Error('Chunk size must be greater than 0');
  }

  const result: T[][] = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }

  return result;
};

export const getNextChar = (char: string) => String.fromCharCode(char.charCodeAt(0) + 1);

export const sumSecondsToMinutes = (secondsArray: number[]): number => {
  if (!Array.isArray(secondsArray) || secondsArray.length === 0) {
    throw new Error('Input should be a non-empty array of seconds.');
  }

  const totalSeconds = secondsArray.reduce((acc, seconds) => acc + seconds, 0);

  return totalSeconds / 60;
};

export const getFullNameByUser = (user: { lastName: string, firstName: string }): string => (
  `${user.firstName} ${user.lastName}`
);

export const handlePromiseResWithErrors = (
  res: any[],
  errorCallback: (message: string) => void,
  successCallback?: () => void,
) => {
  const errors = res
    .map((item) => (item && 'error' in item ? getErrorMessage(item.error) : ''))
    .filter<string>((item): item is string => !!item);

  if (errors.length > 0) {
    errors.forEach((errorMessage) => {
      errorCallback(errorMessage);
    });
  } else if (successCallback) {
    successCallback();
  }
};

type NestedObject = {
  [key: string]: NestedObject | boolean;
};

export const hasTrueValue = (obj?: NestedObject | boolean | null) => {
  if (obj === true) {
    return true;
  }

  if (!obj) {
    return false;
  }

  let hasTrue = false;

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === 'object' && value !== null) {
      if (hasTrueValue(value)) {
        hasTrue = true;
      }
    } else if (value) {
      hasTrue = true;
    }
  });

  return hasTrue;
};

export const getTimezoneFromCoordinates = (latitude: number, longitude: number): Promise<string> => {
  // eslint-disable-next-line max-len
  const apiUrl = `${process.env.REACT_APP_TIMEZONE_DB}/v2.1/get-time-zone?key=${process.env.REACT_APP_TIMEZONE_API_KEY}&format=json&by=position&lat=${latitude}&lng=${longitude}`;

  const timezone = fetch(apiUrl)
    .then((response) => response.json())
    .then((data) => {
      if (data.status === 'OK') {
        return data.zoneName;
      }
      return 'locale';
    })
    .catch(() => 'locale');

  return timezone;
};

export const transformDateToHelperText = (inputDate: DateTime | undefined, format: string = 'dd LLL yyyy hh:mm a') => {
  if (inputDate) {
    return inputDate.toFormat(format);
  }

  return null;
};

export const logisticsAndGameSetupCheckValidity = (checkValidity: ICheckValidity, t: (value: string) => string) => {
  const { notEnoughPlayingFields, existGamesOutsideTimeFrames } = checkValidity;
  const validityObject = notEnoughPlayingFields.length && existGamesOutsideTimeFrames.length
    ? { ...checkValidity, notEnoughPlayingFields: [] }
    : { ...checkValidity };
  Object.entries(validityObject).forEach(([key, value]) => {
    if (value.length) {
      const toastFunction = key === 'existTeamsNotInSchedule' ? toast.error : toast.warning;
      toastFunction(t(`schedule.${key}`), {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: false,
        containerId: TOAST_CONTAINER.SCHEDULE,
      });
    }
  });
};

export const getFirstLetters = (str: string) => str
  .split(' ')
  .map((word) => word.charAt(0))
  .join('');

export const filterEventTabs = (
  tabs: { label: string, route: string }[],
  names: string[],
  condition?: boolean,
) => {
  if (condition) {
    return tabs;
  }

  return tabs.filter(({ label }) => !names.includes(label));
};

export const validateDateRange = (value: DateTime | null | undefined) => {
  if (value) {
    const isMinRange = value.get('year') >= TIMEFRAME_YEARS_RANGE.MIN;
    const isMaxRange = value.get('year') <= TIMEFRAME_YEARS_RANGE.MAX;

    return isMinRange && isMaxRange;
  }
  return !!value;
};
