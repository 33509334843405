import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import type { IQrCoreView } from './interfaces/IQrCodeView';

function QrCodeView({
  qrCodeDataURL,
  subtitle,
  fileName,
}: IQrCoreView) {
  const { t } = useTranslation();

  return (
    <Paper
      sx={{ maxWidth: 880, px: 7.5, py: 5 }}
      elevation={0}
    >
      <Box mb={4.25}>
        <Typography
          fontSize={34}
          variant="h1"
          sx={{ display: 'inline-block' }}
        >
          {t('settings.qrCodeTitle')}
        </Typography>
        <Typography
          fontSize={16}
          sx={{ display: 'inline-block' }}
          ml={1}
        >
          {subtitle}
        </Typography>
      </Box>
      <Stack alignItems="center">
        <img
          src={qrCodeDataURL.svg}
          alt="qr-code"
          width={291}
          height={291}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        spacing={2}
        mt={3}
      >
        <Button
          href={qrCodeDataURL.png}
          download={fileName}
          target="_blank"
          rel="noopener noreferrer"
          variant="outlined"
          sx={{ py: 1.5, px: 2, lineHeight: '100%' }}
        >
          {t('settings.downloadPng')}
        </Button>
        <Button
          href={qrCodeDataURL.svg}
          download={fileName}
          target="_blank"
          rel="noopener noreferrer"
          variant="outlined"
          sx={{ py: 1.5, px: 2, lineHeight: '100%' }}
        >
          {t('settings.downloadSvg')}
        </Button>
      </Stack>
    </Paper>
  );
}

export default QrCodeView;
