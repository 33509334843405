import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import Box from '@mui/material/Box';

import InfoCard from '../../../components/InfoCard/InfoCard';
import HalfTimerIcon from '../../../components/Icons/HalfTimerIcon';
import OvertimeTimerIcon from '../../../components/Icons/OvertimeTimerIcon';
import { boolToWord, sumSecondsToMinutes } from '../../../utils/helpers';
import type IStatKeeperGameSettings from '../interfaces/IStatKeeperGameSettings';

function GameSettings({ label, game7x7Settings, game7x7SettingField }: IStatKeeperGameSettings) {
  const { t } = useTranslation();

  const handleGame7x7SettingChange = (value: number) => {
    const find = game7x7Settings.find((item) => item.id === value);

    if (find) {
      game7x7SettingField.onChange(find);
    }
  };

  return (
    <Box>
      <Typography variant="h6" mb={4}>
        {label}
      </Typography>

      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="outlined-divisionGameSettings" shrink>
              {t('schedule.divisionGameSettings')}
            </InputLabel>
            <Select
              value={game7x7SettingField.value?.id || ''}
              name="divisionGameSettings"
              variant="outlined"
              id="outlined-divisionGameSettings"
              label={t('schedule.divisionGameSettings')}
              onChange={({ target }) => handleGame7x7SettingChange(target.value as number)}
              renderValue={(selected) => game7x7Settings.find(({ id }) => id === selected)?.description}
              notched
              displayEmpty
              fullWidth
            >
              {game7x7Settings.map((item, index) => (
                <MenuItem key={`GamesSettingOption_${index.toString()}`} value={item.id}>
                  {item.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid container item spacing={3}>
          <Grid item sm={6} xs={12}>
            <InfoCard
              icon={<SportsFootballIcon />}
              desc={game7x7SettingField.value?.gameType || '-'}
              label={t('schedule.type')}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InfoCard
              icon={<TimerOutlinedIcon />}
              desc={game7x7SettingField.value?.durationSeconds
                ? `${sumSecondsToMinutes(game7x7SettingField.value.durationSeconds)} min`
                : '-'}
              label={t('schedule.gameDuration')}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={3}>
          <Grid item sm={6} xs={12}>
            <InfoCard
              icon={<HalfTimerIcon />}
              desc={boolToWord(game7x7SettingField.value?.isHalfTimeAllowed) || '-'}
              label={t('schedule.halfTime')}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InfoCard
              icon={<OvertimeTimerIcon />}
              desc={boolToWord(game7x7SettingField.value?.isOvertimeAllowed) || '-'}
              label={t('schedule.overtime')}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default GameSettings;
