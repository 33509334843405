import type ITabPanel from './interfaces/ITabPanel';

function TabPanel({
  children, slugId, slugLabelledby, value, index, ...rest
}: ITabPanel) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${slugId}-${index}`}
      aria-labelledby={`${slugLabelledby}-${index}`}
      {...rest}
    >
      {value === index && children}
    </div>
  );
}

export default TabPanel;
