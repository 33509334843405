/* eslint-disable @typescript-eslint/no-unused-vars */
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import EditOutlined from '@mui/icons-material/EditOutlined';
import Typography from '@mui/material/Typography';

import PaperBlock from '../../../components/PaperBlock/PaperBlock';
import { generateGoogleMapLink, getRelativePath } from '../../../utils/helpers';
import AppRoutes from '../../../constants/AppRoutes';
import type { IDetailsWidget } from '../interfaces/IDetailsWidget';

function DetailsWidget({ details }: IDetailsWidget): React.ReactElement {
  const { t } = useTranslation();
  const { eventId } = useParams();

  return (
    <PaperBlock
      title={t('events.details')}
      endAdornment={(
        <Button
          startIcon={<EditOutlined />}
          component={Link}
          to={`/events/${eventId}${getRelativePath(AppRoutes.singleEventEdit, 'route')}`}
          size="small"
        >
          {t('common.edit')}
        </Button>
      )}
      whiteBg
      noMargin
    >
      <Divider sx={{ my: 3 }} />
      <List sx={{ py: 0 }}>
        {details.map((item) => (
          <ListItem key={`DetailsWidgetList_${item.id}`} sx={{ px: 0, py: 1.5, alignItems: 'start' }}>
            <ListItemAvatar>
              <Avatar sx={{ color: 'white' }}>
                {item.icon}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={item.name}
              secondary={item.isLink ? (
                <>
                  <Typography
                    color="white"
                    fontSize={14}
                    component="a"
                    href={generateGoogleMapLink(item.value)}
                    target="_blank"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      opacity: 0.7,
                      textDecoration: 'none',
                      '&:hover': { textDecoration: 'underline' },
                    }}
                  >
                    {item.value || '-'}
                  </Typography>
                  {item.description ? (
                    <Typography
                      color="white"
                      fontSize={14}
                      component="span"
                      sx={{ opacity: 0.7 }}
                    >
                      {item.description}
                    </Typography>
                  ) : null}
                </>
              ) : (
                <Typography
                  color="white"
                  fontSize={14}
                  component="p"
                  sx={{ opacity: 0.7 }}
                >
                  {item.value || '-'}
                </Typography>
              )}
              sx={{ my: 0, whiteSpace: 'initial', wordBreak: 'break-word' }}
            />
          </ListItem>
        ))}
      </List>
    </PaperBlock>
  );
}

export default DetailsWidget;
