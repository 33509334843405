import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import EditOutlined from '@mui/icons-material/EditOutlined';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

import PaperBlock from '../../../components/PaperBlock/PaperBlock';
import EmptyData from '../../../components/EmptyData/EmptyData';
import SpectatorsTicketsWidgetItem from './SpectatorsTicketsWidgetItem';
import { getRelativePath } from '../../../utils/helpers';
import AppRoutes from '../../../constants/AppRoutes';
import { SPECTATORS_TICKET_PASS } from '../../../constants/general';
import type { ISpectatorsTicketsWidget } from '../interfaces/ISpectatorsTicketsWidget';

function SpectatorsTicketsWidget({ tickets = [], timezone }: ISpectatorsTicketsWidget): React.ReactElement {
  const { t } = useTranslation();
  const { eventId } = useParams();

  const dayPass = tickets?.filter(({ pass }) => pass === SPECTATORS_TICKET_PASS.DAY_PASS)
    .sort((a, b) => a.type.localeCompare(b.type));
  const wholePass = tickets?.filter(({ pass }) => pass === SPECTATORS_TICKET_PASS.WHOLE_PASS)
    .sort((a, b) => a.type.localeCompare(b.type));

  return (
    <PaperBlock
      title={t('events.spectatorsTickets')}
      endAdornment={(
        <Button
          startIcon={<EditOutlined />}
          component={Link}
          to={`/events/${eventId}${getRelativePath(AppRoutes.singleEventSpectators, 'route')}`}
          size="small"
        >
          {t('common.edit')}
        </Button>
      )}
      whiteBg
      noMargin
    >
      <Divider sx={{ my: 3 }} />
      {dayPass.length === 0 && wholePass.length === 0 ? (
        <EmptyData description={t('common.noData')} />
      ) : (
        <List sx={{ py: 0 }}>
          {dayPass.length ? (
            <ListItem disableGutters>
              <ListItemAvatar>
                <Avatar sx={{ color: 'white' }}>
                  <ConfirmationNumberIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={t('events.oneDayPass')} />
            </ListItem>
          ) : null}
          {dayPass.map((item) => (
            <SpectatorsTicketsWidgetItem
              key={`SpectatorsTicketsWidgetDayPass_${item.id}`}
              label={t(`events.${item.type}`)}
              price={item.price}
              discount={item.discount}
              timezone={timezone}
            />
          ))}
          {wholePass.length ? (
            <ListItem disableGutters>
              <ListItemAvatar>
                <Avatar sx={{ color: 'white' }}>
                  <ConfirmationNumberIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={t('events.wholeTournamentPass')} />
            </ListItem>
          ) : null}
          {wholePass.map((item) => (
            <SpectatorsTicketsWidgetItem
              key={`SpectatorsTicketsWidgetWholePass_${item.id}`}
              label={t(`events.${item.type}`)}
              price={item.price}
              discount={item.discount}
              timezone={timezone}
            />
          ))}
        </List>
      )}
    </PaperBlock>
  );
}

export default SpectatorsTicketsWidget;
