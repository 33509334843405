import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import EditOutlined from '@mui/icons-material/EditOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Typography from '@mui/material/Typography';

import PaperBlock from '../../../components/PaperBlock/PaperBlock';
import EmptyData from '../../../components/EmptyData/EmptyData';
import { getRelativePath } from '../../../utils/helpers';
import type { IDocumentsWidget } from '../interfaces/IDocumentsWidget';

const useStyles = makeStyles()({
  title: {
    textDecoration: 'none',
    color: '#ffffff',
    ':hover': {
      textDecoration: 'underline',
    },
  },
});

function DocumentsWidget({ documents = [], title, editLink }: IDocumentsWidget): React.ReactElement {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { eventId } = useParams();

  return (
    <PaperBlock
      title={title}
      endAdornment={(
        <Button
          startIcon={<EditOutlined />}
          component={Link}
          to={`/events/${eventId}${getRelativePath(editLink, 'route')}`}
          size="small"
        >
          {t('common.edit')}
        </Button>
      )}
      whiteBg
      noMargin
    >
      <Divider sx={{ my: 3 }} />
      <List sx={{ py: 0 }}>
        {documents.length ? documents.map((item) => (
          <ListItem key={`DetailsWidgetList_${item.id}`} sx={{ px: 0, py: 1.5 }}>
            <ListItemAvatar>
              <Avatar sx={{ color: 'white' }}>
                <PictureAsPdfIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={(
                <Typography
                  className={classes.title}
                  component="a"
                  href={item.link}
                  target="_blank"
                >
                  {item.title}
                </Typography>
              )}
              secondary={DateTime.fromISO(item.createdAt).toFormat('ccc. LLL dd yyyy, ta')}
              sx={{ my: 0, whiteSpace: 'initial' }}
            />
          </ListItem>
        )) : <EmptyData description={t('common.noData')} />}
      </List>
    </PaperBlock>
  );
}

export default DocumentsWidget;
