import { makeStyles } from 'tss-react/mui';

import { AppThemeMode } from '../../constants/AppThemes';
import borderHexaWhite from '../../assets/images/hexaWhite.svg';
import borderHexaGrey from '../../assets/images/hexaGrey.svg';

const useStyles = makeStyles<void, 'invert'>()(
  (theme, _params, classes) => ({
    invert: {},
    errorWrap: {
      background: `url(${theme.palette.mode === AppThemeMode.Dark ? borderHexaWhite : borderHexaGrey}) no-repeat`,
      backgroundSize: '100% 100%',
      backgroundPosition: '-4px center',
      width: 500,
      height: 500,
      [theme.breakpoints.down('sm')]: {
        width: 300,
        height: 300,
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      position: 'relative',
      margin: `${theme.spacing(3)} auto`,
      [`&.${classes.invert}`]: {
        '& h1, h5': {
          color: theme.palette.common.white,
        },
      },
      '& h5': {
        [theme.breakpoints.down('sm')]: {
          fontSize: '1.2rem',
        },
      },
    },
    title: {
      color: theme.palette.text.secondary,
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        fontSize: '4rem',
        marginBottom: theme.spacing(2),
      },
    },
  }),
);

export default useStyles;
