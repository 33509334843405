import { useTranslation } from 'react-i18next';
import { Controller, useController, useWatch } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useEffect } from 'react';

import TextMask from '../../TextMask/TextMask';
import DiscountSettings from './DiscountSettings';
import { DEFAULT_PRICE_MASK } from '../../../constants/textMasks';
import { parseNumberFormattedString } from '../../../utils/helpers';
import type { ISpectatorTicket } from '../interfaces/ISpectatorTicket';

function SpectatorTicket({ name, control, timezone }: ISpectatorTicket): React.ReactElement {
  const { t } = useTranslation();
  const {
    field: discountPriceField,
    fieldState: { error: discountPriceError },
  } = useController({ control, name: `${name}.discount.price` });
  const {
    field: discountDateField,
    fieldState: { error: discountDateError },
  } = useController({ control, name: `${name}.discount.endAt` });
  const {
    field: discountIsDeletedField,
  } = useController({ control, name: `${name}.isDiscountDeleted` });
  const {
    field: isDiscountField,
  } = useController({ control, name: `${name}.isDiscount` });
  const minSalesDate = useWatch({ control, name: 'startAt' });
  const maxSalesDate = useWatch({ control, name: 'endAt' });
  const ticketPrice = useWatch({ control, name: `${name}.price` }) || '';

  useEffect(() => {
    if (!isDiscountField.value) {
      discountPriceField.onChange('');
      discountDateField.onChange(null);
      isDiscountField.onChange(false);
      if (!discountIsDeletedField.value
        && discountIsDeletedField.value !== undefined
        && discountPriceField.value
        && discountDateField.value
      ) {
        discountIsDeletedField.onChange(true);
      }
    } else {
      discountIsDeletedField.onChange(false);
    }
  }, [discountDateField, discountIsDeletedField, discountPriceField, isDiscountField]);

  return (
    <>
      <Box sx={{ alignSelf: 'stretch' }}>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 5 }}>
          <Grid item xs={12} md={6}>
            <Controller
              name={`${name}.price`}
              control={control}
              render={({
                field: { value = '', onBlur, onChange },
                fieldState: { error: fieldError },
              }) => (
                <TextField
                  helperText={fieldError?.message}
                  error={!!fieldError}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  label={t('events.ticketPrice')}
                  variant="outlined"
                  InputProps={{
                    inputComponent: TextMask as any,
                    inputProps: DEFAULT_PRICE_MASK,
                  }}
                  fullWidth
                />
              )}
              shouldUnregister
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name={`${name}.ageRange`}
              control={control}
              render={({
                field: { value = '', onBlur, onChange },
                fieldState: { error: fieldError },
              }) => (
                <TextField
                  helperText={fieldError?.message}
                  error={!!fieldError}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  label={t('events.ageRange')}
                  variant="outlined"
                  fullWidth
                />
              )}
              shouldUnregister
            />
          </Grid>
        </Grid>
      </Box>
      <Controller
        name={`${name}.isDiscount`}
        control={control}
        render={({
          field: { value, onChange },
        }) => (
          value ? (
            <DiscountSettings
              price={discountPriceField.value}
              date={discountDateField.value}
              minSalesDate={minSalesDate}
              maxSalesDate={maxSalesDate}
              onPriceChange={discountPriceField.onChange}
              onDateChange={discountDateField.onChange}
              errors={{
                price: discountPriceError,
                endAt: discountDateError,
              }}
              onRemove={() => {
                onChange(false);
                discountPriceField.onChange('');
                discountDateField.onChange(null);
                if (discountPriceField.value && discountDateField.value) {
                  discountIsDeletedField.onChange(true);
                }
              }}
              timezone={timezone}
            />
          ) : (
            <Button
              size="small"
              onClick={() => {
                onChange(true);
                discountIsDeletedField.onChange(false);
              }}
              style={{ marginLeft: -8 }}
              disabled={!parseNumberFormattedString(ticketPrice, true)}
            >
              {t('common.addDiscountSales')}
            </Button>
          )
        )}
        shouldUnregister
      />
    </>
  );
}

export default SpectatorTicket;
