import { useState, useCallback, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import type { FileWithPath } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import CloudUpload from '@mui/icons-material/CloudUpload';
import Stack from '@mui/material/Stack';

import ImagePreview from './ImagePreview';
import FilePreview from './FilePreview';
import { isImage } from '../../utils/helpers';
import type IDropZone from './interfaces/IDropZone';
import type { IFileWithPath } from '../../interfaces/IFileWithPath';

interface IDropzoneStyles {
  error?: string
}

const useStyles = makeStyles<IDropzoneStyles>()((theme, params) => ({
  dropZone: {
    borderColor: params.error ? theme.palette.error.main : theme.palette.divider,
    borderStyle: 'dashed',
    borderWidth: 2,
    borderRadius: theme.rounded.medium,
    color: theme.palette.text.disabled,
    textAlign: 'center',
    cursor: 'pointer',
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      background: theme.palette.background.default,
    },
    width: '100%',
  },
}));

function DropZone({
  files,
  showPreviews,
  maxSize,
  text,
  desc,
  filesLimit,
  accept,
  isMultiFiles = true,
  onUploadChange,
  onDelete,
  error = '',
}: IDropZone): React.ReactElement {
  const [openSnackBar, setOpenSnackbar] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [currentFiles, setFiles] = useState<Array<string | IFileWithPath>>(files);

  const { classes } = useStyles({ error });

  const onDrop = useCallback((acceptedFiles: FileWithPath[]) => {
    const filesLimitVal = filesLimit;
    const newFiles: Array<string | IFileWithPath> = acceptedFiles.map((acceptedFile) => ({
      file: acceptedFile as File,
      path: acceptedFile.path,
    }));
    const combinedFiles = isMultiFiles ? [...currentFiles, ...newFiles] : [...newFiles];

    if (filesLimit && combinedFiles.length > filesLimit) {
      setOpenSnackbar(true);
      setErrorMessage(`Cannot upload more than ${filesLimitVal} items.`);
    } else {
      setFiles(combinedFiles);
      onUploadChange(combinedFiles);
    }
  }, [onUploadChange, currentFiles, filesLimit, isMultiFiles]);

  const onDropRejected = () => {
    setOpenSnackbar(true);
    setErrorMessage('File too big');
  };

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    accept,
    onDrop,
    onDropRejected,
    maxSize,
    multiple: isMultiFiles,
  });

  const handleCloseSnackBar = () => {
    setOpenSnackbar(false);
  };

  const handleRemove = useCallback((_: File | string, fileIndex: number) => {
    setFiles((thisFiles) => {
      const tempFiles = [...thisFiles];
      tempFiles.splice(fileIndex, 1);
      onUploadChange(tempFiles);
      return tempFiles;
    });
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }, [inputRef, onUploadChange]);

  useEffect(() => {
    setFiles(files);
  }, [files]);

  return (
    <>
      <div {...getRootProps()} className={classes.dropZone}>
        <input {...getInputProps()} />
        <Stack spacing={1} alignItems="center">
          <CloudUpload sx={(theme) => ({ fill: theme.palette.primary.main, width: 72, height: 72 })} />
          <Typography color="text.primary">{text}</Typography>
          <Typography color="text.disabled" fontSize={14}>{desc}</Typography>
        </Stack>
      </div>
      {showPreviews && files.length ? (
        <Stack direction="row" m={1.25}>
          {files.map((item, index) => {
            const isFile = typeof item === 'object';
            const value = isFile ? item.file : item;
            const key = `FilePreview_${index}`;

            return (isImage(value) ? (
              <ImagePreview
                key={key}
                src={isFile ? URL.createObjectURL(item.file) : item}
                onRemove={() => {
                  handleRemove(value, index);
                  if (onDelete) {
                    onDelete();
                  }
                }}
              />
            ) : (
              <FilePreview key={key} onRemove={() => handleRemove(value, index)} />
            ));
          })}
        </Stack>
      ) : null}
      <Snackbar
        open={openSnackBar}
        message={errorMessage}
        autoHideDuration={4000}
        onClose={handleCloseSnackBar}
      />
    </>
  );
}

DropZone.defaultProps = {
  maxSize: 3000000,
  desc: undefined,
  accept: [],
  showPreviews: false,
  isMultiFiles: true,
};

export default DropZone;
