import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import type { ISpectatorsTicketsWidgetItem } from '../interfaces/ISpectatorsTicketsWidgetItem';

function SpectatorsTicketsWidgetItem({
  label, price, discount, timezone,
}: ISpectatorsTicketsWidgetItem) {
  const { t } = useTranslation();

  const currentPrice = discount?.price
    ? discount.price
    : price;

  return (
    <ListItem sx={{ py: 1.5, pl: 7 }}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%' }}
      >
        <ListItemText
          primary={(
            <Stack direction="row" spacing={1}>
              <Typography>
                {label}
              </Typography>
              {discount ? (
                <Box bgcolor="error.dark" py="2px" px={1}>
                  <Typography textTransform="uppercase" fontSize={12} fontWeight={600}>
                    {t('events.sale')}
                  </Typography>
                </Box>
              ) : null}
            </Stack>
          )}
          secondary={discount?.endAt
            ? t('events.discountedPriceUntil', {
              date: DateTime.fromISO(discount.endAt).setZone(timezone).toFormat('LLL dd, yyyy ZZZZ'),
            })
            : undefined}
        />
        <ListItemText
          primary={Intl
            .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
            .format(currentPrice)}
          secondary={discount ? (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ textDecoration: 'line-through', textAlign: 'right' }}
            >
              {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price)}
            </Typography>
          ) : undefined}
          sx={{ flexGrow: 0 }}
        />
      </Stack>
    </ListItem>
  );
}

export default SpectatorsTicketsWidgetItem;
