import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  mask: string;
}

const TextMask = forwardRef<HTMLInputElement, CustomProps>(
  (props, ref) => {
    const { onChange, mask, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask={mask}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
      />
    );
  },
);

export default TextMask;
