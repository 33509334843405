import { createSelector } from '@reduxjs/toolkit';

import type IPage from './interfaces/IPage';
import type { RootState } from '../../types/TStore';

const selectUi = (state: RootState): IPage => state.page;

const selectPageTitle = createSelector(selectUi, (ui) => ui.title);

export const selectIsShowTitle = createSelector(selectUi, (ui) => ui.isShowTitle);

export default selectPageTitle;
