import { useTranslation } from 'react-i18next';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import COLORS from '../../../constants/colors';
import type IPlayingFieldsEmpty from '../interfaces/IPlayingFieldsEmpty';

function PlayingFieldsEmpty({ onAddClick, sx }: IPlayingFieldsEmpty) {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" gap={2} sx={sx}>
      <ListRoundedIcon width={48} height={48} sx={{ width: 48, height: 48, color: COLORS.bcSecondaryDark }} />
      <Typography variant="h6">
        {t('schedule.noFieldsInList')}
      </Typography>
      <Typography fontSize={14} mb={1} textAlign="center" maxWidth={540}>
        {t('schedule.noFieldsInListText')}
      </Typography>
      <Button onClick={onAddClick} variant="outlined">
        {t('schedule.addField')}
      </Button>
    </Stack>
  );
}

export default PlayingFieldsEmpty;
