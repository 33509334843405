import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import GameRoundBadge from './GameRoundBadge';
import { MIN_MINUTES_FOR_GAME } from '../../constants/general';
import type IGameItem from './interfaces/IGameItem';

function GameItem({
  error, division, roundName, title, duration,
}: IGameItem) {
  const isShowRound = !!roundName && duration > MIN_MINUTES_FOR_GAME;

  return (
    <div className={`rbc-event-content-game ${isShowRound ? 'with-round' : ''} ${error ? 'with-error' : ''}`}>
      <div className="rbc-custom-event-label">
        <div className="rbc-custom-event-division">
          <Typography variant="body2" lineHeight="16px" fontSize={14}>
            {division}
          </Typography>
        </div>
        {isShowRound ? <GameRoundBadge round={roundName} /> : null}
      </div>
      <Box className="rbc-custom-event-content">
        <Typography variant="body2" whiteSpace="nowrap" lineHeight={1}>
          {title}
        </Typography>
      </Box>
      {roundName && duration <= MIN_MINUTES_FOR_GAME ? <GameRoundBadge round={roundName} /> : null}
    </div>
  );
}

export default GameItem;
