import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useNavigate, useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import SingleEventRulesView from './SingleEventRulesView';
import { useAppSelector } from '../../store/hooks/useApp';
import { selectUserOrg } from '../../store/slices/user/selectors';
import {
  useGetEventRulesQuery,
  useCreateEventRuleMutation,
  useDeleteEventRuleMutation,
  useUpdateEventRuleMutation,
} from '../../store/slices/rules/apis/rulesApi';
import { useGetEventQuery } from '../../store/slices/events/apis/eventsApi';
import { selectEventRules } from '../../store/slices/rules/selectors';
import { selectEventIsPublished } from '../../store/slices/events/selectors';
import { eventRulesSchema } from '../../utils/validators';
import { getErrorMessage } from '../../utils/helpers';
import type { IDocument, IDocuments } from '../../components/DocumentForm/interfaces/IDocumentsForm';
import type { IFileWithPath } from '../../interfaces/IFileWithPath';

type SingleEventRulesForm = Pick<IDocuments, 'rules'>;

function SingleEventRules(): React.ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { eventId } = useParams();
  const organization = useAppSelector(selectUserOrg);
  const [isRuleLoading, setIsRuleLoading] = useState(false);

  const areIdsSettled = organization?.id && eventId;

  const { isPublished } = useGetEventQuery(
    areIdsSettled ? { orgId: organization?.id as number, eventId: +eventId } : skipToken,
    {
      selectFromResult: (result) => ({ ...result, isPublished: selectEventIsPublished(result.data) }),
    },
  );
  const { eventRules } = useGetEventRulesQuery(
    areIdsSettled ? { orgId: organization?.id as number, eventId: +eventId } : skipToken,
    {
      selectFromResult: (result) => ({ ...result, eventRules: selectEventRules(result.data) }),
    },
  );

  const [createEventRule] = useCreateEventRuleMutation();
  const [updateEventRule] = useUpdateEventRuleMutation();
  const [deleteEventRule] = useDeleteEventRuleMutation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<SingleEventRulesForm>({
    resolver: yupResolver<SingleEventRulesForm>(eventRulesSchema),
    mode: 'onChange',
    defaultValues: {
      rules: eventRules || [],
    },
  });

  useEffect(() => {
    reset({ rules: eventRules });
  }, [reset, eventRules]);

  const {
    fields: rules,
    append: handleAppendRuleByForm,
    remove: handleRemoveRuleByForm,
  } = useFieldArray({ control, name: 'rules' });

  const handleAppendRules = (files: IFileWithPath[]) => {
    const newWaivers = files.map(({ file }) => {
      const name = file.name.split('.').slice(0, -1).join('.');

      return ({ name, file });
    });
    handleAppendRuleByForm(newWaivers);
  };

  const handleCancel = () => {
    navigate(-1);
    reset();
  };

  const handleCreateSubmit = handleSubmit((values) => {
    const rulesPromises = (values.rules as IDocument[]).map((rule) => {
      if (!rule.documentId) {
        setIsRuleLoading(true);
      }

      const data = {
        orgId: organization?.id as number,
        eventId: +(eventId as string),
        title: rule.name,
      };

      return rule.documentId
        ? updateEventRule({ ...data, ruleId: rule.documentId })
        : createEventRule({ ...data, file: rule.file as File });
    });

    const rulesIdsToDelete = eventRules
      .filter((rule) => !values?.rules?.some((item) => item.documentId === rule.documentId))
      .map((rule) => rule.documentId);

    const rulesDeletePromises = rulesIdsToDelete.length ? rulesIdsToDelete.map((ruleId) => deleteEventRule({
      orgId: organization?.id as number,
      eventId: +(eventId as string),
      ruleId,
    })) : undefined;

    const requestPromises = [
      ...rulesPromises,
      rulesDeletePromises,
    ].filter(Boolean);

    Promise.all(requestPromises).then((res) => {
      setIsRuleLoading(false);

      const errors = res
        .map((item) => (item && 'error' in item ? getErrorMessage(item.error) : null))
        .filter(Boolean);

      if (errors.length > 0) {
        errors.forEach((errorMessage) => {
          toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT });
        });
      } else {
        handleCancel();
        toast.success(t('events.changesSaved'), { position: toast.POSITION.TOP_RIGHT });
      }
    });
  });

  return (
    <SingleEventRulesView
      control={control}
      rules={rules}
      onCancel={handleCancel}
      onAppendDocument={handleAppendRules}
      onRemoveDocument={handleRemoveRuleByForm}
      onCreateSubmit={handleCreateSubmit}
      isSubmitting={isSubmitting}
      withConfirm={isPublished}
      isRuleLoading={isRuleLoading}
    />
  );
}

export default SingleEventRules;
