import Typography from '@mui/material/Typography';

import type IUserCellContent from '../interfaces/IUserCellContent';

function UserCellContent({
  firstName,
  lastName,
  email,
  phone,
}: IUserCellContent) {
  return (
    <>
      <Typography variant="body2">
        {`${firstName} ${lastName}`}
      </Typography>
      {email ? (
        <Typography
          variant="body2"
          component="a"
          href={`mailto:${email}`}
          fontSize={12}
          color="inherit"
          sx={{ textDecoration: 'none' }}
        >
          Email:
          <Typography
            variant="inherit"
            component="span"
            color="primary.main"
          >
            {email}
          </Typography>
        </Typography>
      ) : null}
      {phone ? (
        <Typography
          variant="body2"
          component="a"
          href={`tel:${phone}`}
          fontSize={12}
          color="inherit"
          sx={{ textDecoration: 'none' }}
        >
          Phone:
          <Typography
            variant="inherit"
            component="span"
            color="primary.main"
          >
            {phone}
          </Typography>
        </Typography>
      ) : null}
    </>
  );
}

export default UserCellContent;
