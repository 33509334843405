import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import useStyles from './tableTabsStyle-tss';
import type { ITableTabs } from './interfaces/ITableTabs';

function TableTabs({
  tabs,
  tabValue,
  onTabChange,
}: ITableTabs): React.ReactElement {
  const { classes } = useStyles();

  return (
    <Tabs
      value={tabValue}
      onChange={onTabChange}
      variant="scrollable"
      sx={{ bgcolor: 'background.secondary', borderRadius: 'unset' }}
      className={classes.root}
      scrollButtons
    >
      {tabs.map((item) => (
        <Tab
          key={item.value}
          label={item.name}
          value={item.value}
          sx={{ minWidth: 145 }}
        />
      ))}
    </Tabs>
  );
}

export default TableTabs;
