import { createSelector } from '@reduxjs/toolkit';

import type IScheduleSlice from './interfaces/IScheduleSlice';
import type { RootState } from '../../types/TStore';

const selectSchedule = (state: RootState): IScheduleSlice => state.schedule;

const selectScheduleBracket = createSelector(selectSchedule, (schedule) => schedule.bracket);

export default selectScheduleBracket;
