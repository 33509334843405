import { useNavigate, useOutletContext } from 'react-router-dom';
import { useCallback } from 'react';

import SingleEventPoolPlayScheduleView from './SingleEventPoolPlayScheduleView';
import AppRoutes from '../../constants/AppRoutes';
import useScheduleCalendarFilterForm from '../../hooks/useScheduleCalendarFilterForm';
import useTimezone from '../../hooks/useTimezone';
import { ScheduleTypes } from '../../constants/general';
import type ICreateEventContext from '../../layout/CreateEventLayout/interfaces/ICreateEventContext';

function SingleEventPoolPlaySchedule() {
  const navigate = useNavigate();
  const { eventId, orgId } = useOutletContext<ICreateEventContext>();

  const {
    timezone,
    control,
    onAddFromUnscheduled,
    onUpdateGame,
    onSelectGame,
    selectedGame,
    isOpenEditFields,
    isOpenBuyGames,
    games,
    buyGames,
    unscheduledList,
    filterData,
    timeFrames,
    fields,
    toggleEditFieldsDialog,
    toggleGameDetailsDialog,
    toggleBuyGamesDialog,
    onRegeneratePositions,
    onPublishSchedule,
    isPublishing,
    fullInfoId,
    isLoading,
    isRegenerating,
    isSchedulePublished,
    selectedDate,
  } = useScheduleCalendarFilterForm({
    orgId,
    eventId: +eventId,
    type: ScheduleTypes.POOL_PLAY,
  });

  const onGoBack = useCallback(() => {
    navigate(AppRoutes.singleEventPoolPlayLogisticsAndGameSetup.replace(':eventId', eventId));
  }, [eventId, navigate]);

  useTimezone(timezone);

  return (
    <SingleEventPoolPlayScheduleView
      type={ScheduleTypes.POOL_PLAY}
      control={control}
      onAddFromUnscheduled={onAddFromUnscheduled}
      onUpdateGame={onUpdateGame}
      onSelectGame={onSelectGame}
      selectedGame={selectedGame}
      isOpenEditFields={isOpenEditFields}
      isOpenBuyGames={isOpenBuyGames}
      timezone={timezone}
      games={games}
      buyGames={buyGames}
      unscheduledList={unscheduledList}
      filterData={filterData}
      timeFrames={timeFrames}
      fields={fields}
      onEditFieldsClick={toggleEditFieldsDialog}
      toggleGameDetailsDialog={toggleGameDetailsDialog}
      toggleBuyGamesDialog={toggleBuyGamesDialog}
      onRegeneratePositions={onRegeneratePositions}
      onGoBack={onGoBack}
      onPublishSchedule={onPublishSchedule}
      isPublishing={isPublishing}
      fullInfoId={fullInfoId}
      isLoading={isLoading}
      isRegenerating={isRegenerating}
      isSchedulePublished={isSchedulePublished}
      selectedDate={selectedDate}
    />
  );
}

export default SingleEventPoolPlaySchedule;
