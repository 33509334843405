import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import CountWidget from '../../../components/CountWidget/CountWidget';
import { toPriceFormat } from '../../../utils/helpers';
import type { IEarnedSection } from '../interfaces/IEarnedSection';

function EarnedSection({
  earnings,
  total,
}: IEarnedSection) {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} md={6} xl={3}>
        <CountWidget
          title={t('events.totalEarned')}
          value={toPriceFormat(total)}
        />
      </Grid>
      {earnings.map((item) => (
        <Grid item xs={12} md={6} xl={3} key={item.ticketOrderType}>
          <CountWidget
            title={t(`events.earned${item.ticketOrderType}`)}
            value={toPriceFormat(item.totalAmount)}
          />
        </Grid>
      ))}
    </>
  );
}

export default EarnedSection;
