import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import Game from './Game';
import useStyles from './bracketStyle-tss';
import { EventRoundTypes } from '../../store/slices/schedule/interfaces/IScheduleBracket';
import type IRound from './interfaces/IRound';

function Round({
  round,
  games,
  index,
  attr,
  timezone,
}: IRound) {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const { half } = attr;
  const isHasThirdPlace = games.length === 2 && round.type === EventRoundTypes.FINAL;

  return (
    <Stack className={classes.round} flex={1} data-type={round.type} data-half={half}>
      <Typography className={classes.title} component="h3" mx={2.5} p={0.5} textAlign="center">
        {round.name}
      </Typography>
      <ul className={classes.list}>
        {games.map((game, gameIndex) => {
          const isThirdPlace = game.tOrgEventPlayOffRoundId !== round.id && isHasThirdPlace;

          return (
            <Box
              key={`TournamentBracketGame_${index}_${gameIndex.toString()}`}
              className={cx(classes.listItem, isThirdPlace && classes.thirdPlace)}
            >
              {isThirdPlace ? (
                <Typography mb={0.5} width="100%" textAlign="center" variant="body2" color="text.secondary">
                  {t('schedule.thirdPlaceFinal')}
                </Typography>
              ) : null}
              <Game game={game} timezone={timezone} />
            </Box>
          );
        })}
      </ul>
    </Stack>
  );
}

export default Round;
