import { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import Step from '@mui/material/Step';

import PageNotFound from '../Page/PageNotFound';
import SplashScreen from '../../components/SplashScreen/SplashScreen';
import { selectUserOrg } from '../../store/slices/user/selectors';
import { setIsShowTitle } from '../../store/slices/page/slice';
import { useGetEventQuery } from '../../store/slices/events/apis/eventsApi';
import { useAppDispatch, useAppSelector } from '../../store/hooks/useApp';
import type IPoolPlayScheduleLayout from './IPoolPlayScheduleLayout/IPoolPlayScheduleLayout';

function PoolPlayScheduleLayout({ steps }: IPoolPlayScheduleLayout): React.ReactElement {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { eventId } = useParams();
  const organization = useAppSelector(selectUserOrg);
  const isFetch = !!(eventId && organization?.id);

  const { isLoading, isError } = useGetEventQuery(
    isFetch ? { orgId: organization.id, eventId: +eventId } : skipToken,
  );

  const mapOfSteps = useMemo(() => {
    const map = new Map();

    steps.forEach(({ route }, index) => {
      map.set(eventId ? route.replace(':eventId', eventId) : route, index);
    });

    return map;
  }, [eventId, steps]);
  const activeStep = mapOfSteps.get(location.pathname);

  useEffect(() => {
    dispatch(setIsShowTitle({ isShow: false }));

    return () => {
      dispatch(setIsShowTitle({ isShow: true }));
    };
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  if (isError) {
    return <PageNotFound />;
  }

  return (
    <Box mx="auto" my={0} width="100%">
      <Stepper activeStep={activeStep}>
        {steps.map(({ label, route }) => (
          <Step key={route}>
            <StepLabel>{t(label)}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Outlet context={{ eventId, orgId: organization?.id, isFetch }} />
    </Box>
  );
}

export default PoolPlayScheduleLayout;
