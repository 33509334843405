export default {
  dashboard: 'Dashboard',
  events: 'Events',
  teams: 'Teams',
  members: 'Members',
  settings: 'Settings',
  event: 'Create event',
  'hotels-and-partners': 'Hotels & Partners',
  documents: 'Documents',
  'teams-tickets': 'Teams tickets',
  'spectators-tickets': 'Spectators tickets',
  'qr-code': 'QR-Code',
  'payment-options': 'Payment Options',
};
