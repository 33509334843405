import mainApi from '../../../apis/mainApi';
import PAYMENTS_STATUS from '../../../tags/payment';
import { ORG_PAYMENT_PROCESSORS } from '../../../../constants/requestUrls';
import type IBaseQueryResponse from '../../../types/IBaseQueryResponse';
import type IPayment from '../interfaces/IPayment';
import type IPaymentConnect from '../interfaces/IPaymentConnect';

const paymentApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizationPayment: builder.query<IBaseQueryResponse<IPayment>, { orgId: number }>({
      query: ({ orgId }) => ({
        url: ORG_PAYMENT_PROCESSORS
          .replace('{organizationId}', `${orgId}`),
        method: 'GET',
      }),
      providesTags: [PAYMENTS_STATUS],
    }),
    setOrganizationPayment: builder.mutation<IBaseQueryResponse<IPaymentConnect>, {
      orgId: number
      returnUrl: string
    }>({
      query: ({ orgId, returnUrl }) => ({
        url: ORG_PAYMENT_PROCESSORS
          .replace('{organizationId}', `${orgId}`),
        method: 'POST',
        params: {
          returnUrl,
        },
      }),
      invalidatesTags: [PAYMENTS_STATUS],
    }),
    deleteOrganizationPayment: builder.mutation<IBaseQueryResponse<{}>, { orgId: number }>({
      query: ({ orgId }) => ({
        url: ORG_PAYMENT_PROCESSORS
          .replace('{organizationId}', `${orgId}`),
        method: 'DELETE',
      }),
      invalidatesTags: [PAYMENTS_STATUS],
    }),
  }),
});

export const {
  useGetOrganizationPaymentQuery,
  useSetOrganizationPaymentMutation,
  useDeleteOrganizationPaymentMutation,
} = paymentApi;

export default paymentApi;
