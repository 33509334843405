import { useCallback, useRef } from 'react';

const useDebouncedCallback = (callback: Function, delay: number = 500) => {
  const timeout = useRef<NodeJS.Timeout>();

  return useCallback((...args: any[]) => {
    const later = () => {
      clearTimeout(timeout.current);
      callback(...args);
    };

    clearTimeout(timeout.current);
    timeout.current = setTimeout(later, delay);
  }, [callback, delay]);
};

export default useDebouncedCallback;
