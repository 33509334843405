import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import ListItemIcon from '@mui/material/ListItemIcon';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

function LogoutDialog({ onLogout }: { onLogout: () => void }): React.ReactElement {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MenuItem onClick={handleOpen} dense>
        <ListItemIcon sx={{ minWidth: 24 }}>
          <LogoutIcon sx={{ width: 24, height: 24 }} />
        </ListItemIcon>
        <ListItemText>{t('login.logout')}</ListItemText>
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: 600 } }}
      >
        <DialogTitle variant="h5">
          {t('login.logoutTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant="body2">
            {t('login.logoutDesc')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: 'text.disabled' }}>
            {t('common.cancel')}
          </Button>
          <Button variant="contained" onClick={onLogout}>
            {t('login.logout')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default LogoutDialog;
