import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useController, useWatch } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import SpectatorTicket from './SpectatorTicket';
import { AGE_RANGE } from '../../../constants/general';
import type { ISpectatorsTicketVariant } from '../interfaces/ISpectatorsTicketVariant';

function SpectatorsTicketVariant({ control, variant, timezone }: ISpectatorsTicketVariant): React.ReactElement {
  const { t } = useTranslation();
  const {
    field: ageRange,
  } = useController({ control, name: `${variant}.ageRange` });
  const isChildTicket = useWatch({ control, name: `${variant}.isChildTicket` });
  const isSeniorTicket = useWatch({ control, name: `${variant}.isSeniorTicket` });

  const title = useMemo(() => {
    const isAdult = isChildTicket || isSeniorTicket;
    const adultText = isAdult ? ` (${t('events.adult')})` : '';

    return `${t('events.generalAdmission')}${adultText}`;
  }, [t, isChildTicket, isSeniorTicket]);

  return (
    <>
      <Typography>{title}</Typography>
      <SpectatorTicket name={variant} control={control} timezone={timezone} />
      <Typography variant="body2">
        {t('events.additionalOptions')}
      </Typography>
      <Controller
        name={`${variant}.isChildTicket`}
        control={control}
        render={({
          field: { value, onChange },
        }) => (
          <FormControl>
            <FormControlLabel
              label={t('events.childTicket')}
              control={(
                <Checkbox
                  checked={value}
                  onChange={(_, checked) => {
                    onChange(checked);
                    if (checked) {
                      ageRange.onChange(AGE_RANGE.ADULT);
                    }
                  }}
                />
              )}
            />
          </FormControl>
        )}
      />
      {isChildTicket ? (
        <>
          <SpectatorTicket name={`${variant}.childTicket`} control={control} timezone={timezone} />
          <Divider sx={{ width: '50%' }} />
        </>
      ) : null}
      <Controller
        name={`${variant}.isSeniorTicket`}
        control={control}
        render={({
          field: { value, onChange },
        }) => (
          <FormControl>
            <FormControlLabel
              label={t('events.seniorTicket')}
              control={(
                <Checkbox
                  checked={value}
                  onChange={(_, checked) => {
                    onChange(checked);
                    if (checked) {
                      ageRange.onChange(AGE_RANGE.ADULT);
                    }
                  }}
                />
              )}
            />
          </FormControl>
        )}
      />
      {isSeniorTicket ? (
        <SpectatorTicket name={`${variant}.seniorTicket`} control={control} timezone={timezone} />
      ) : null}
    </>
  );
}

export default SpectatorsTicketVariant;
