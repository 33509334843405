import {
  Fragment, useEffect, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  closestCorners,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import type {
  Active,
  DragEndEvent,
  DragStartEvent,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import type { Id } from 'react-toastify/dist/types';

import PoolOverlay from './PoolOverlay';
import { poolSameClubIdError, swapItemsBetweenArrays } from '../../../utils/poolPlays';
import { TOAST_CONTAINER } from '../../../constants/general';
import type IPools from '../interfaces/IPools';
import type { IGamePoolTeam } from '../../../store/slices/poolPlays/interfaces/IPoolPlayDivisionRules';

function Pools({ items, onChange, renderItem }: IPools) {
  const { t } = useTranslation();
  const [active, setActive] = useState<Active | null>(null);
  const [toastId, setToastId] = useState<Id | null>(null);

  useEffect(() => {
    if (poolSameClubIdError(items) && !toastId) {
      const id = toast.warning(t('schedule.conflictTeamClubs'), {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: false,
        containerId: TOAST_CONTAINER.SCHEDULE,
      });
      setToastId(id);
    }

    return () => {
      if (toastId) {
        toast.dismiss(toastId);
        setToastId(null);
      }
    };
  }, [toastId, items]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const activeItem = useMemo(
    () => {
      const allItems = Object.values(items)
        .reduce<IGamePoolTeam[]>((acc, item) => [...acc, ...item.teams], []);
      return allItems.find((item) => item.id === active?.id);
    },
    [active, items],
  );

  const findContainer = (id: number | string | undefined) => {
    if (!id) {
      return null;
    }

    if (id && 'id' in items) {
      return id;
    }

    return Object.keys(items).find((key) => items[key].teams.some((item) => item.id === id));
  };

  const handleDragStart = (event: DragStartEvent) => {
    setActive(event.active);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active: currentActive, over } = event;

    const activeContainer = findContainer(active?.id);
    const overContainer = findContainer(over?.id);

    if (!activeContainer || !overContainer) {
      return;
    }

    if (activeContainer === overContainer) {
      if (over && currentActive.id !== over?.id) {
        const activeIndex = items[activeContainer].teams.findIndex(({ id }) => id === currentActive.id);
        const overIndex = items[activeContainer].teams.findIndex(({ id }) => id === over.id);

        onChange({
          ...items,
          [activeContainer]: {
            ...items[activeContainer],
            teams: arrayMove(items[activeContainer].teams, activeIndex, overIndex),
          },
        });
      }

      setActive(null);
      return;
    }

    const activeIndex = items[activeContainer].teams.findIndex(({ id }) => id === currentActive.id);
    const overIndex = items[overContainer].teams.findIndex(({ id }) => id === over?.id);

    const [newActiveArray, newOverArray] = swapItemsBetweenArrays(
      items[activeContainer].teams,
      activeIndex,
      items[overContainer].teams,
      overIndex,
    );

    onChange({
      ...items,
      [activeContainer]: {
        ...items[activeContainer],
        teams: newActiveArray,
      },
      [overContainer]: {
        ...items[overContainer],
        teams: newOverArray,
      },
    });

    setActive(null);
  };

  return (
    <Grid spacing={3} container>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        {Object.entries(items).map(([key, item], index) => (
          <Grid key={key} item sm={6} xs={12}>
            <Typography variant="h6" mb={2}>
              {t('schedule.poolKey', { key: index + 1 })}
            </Typography>
            <SortableContext id={key} items={item.teams}>
              <Stack
                component="ul"
                role="application"
                gap={2}
                sx={{ m: 0, p: 0, listStyle: 'none' }}
              >
                {item.teams.map((team, teamIndex, self) => {
                  const teamWithSameClubId = self.find((selfItem) => (
                    selfItem.clubId === team.clubId && selfItem.id !== team.id
                  ));

                  return (
                    <Fragment key={team.id}>{renderItem(team, !!teamWithSameClubId)}</Fragment>
                  );
                })}
              </Stack>
            </SortableContext>
          </Grid>
        ))}
        <PoolOverlay>
          {activeItem ? renderItem(activeItem) : null}
        </PoolOverlay>
      </DndContext>
    </Grid>
  );
}

export default Pools;
