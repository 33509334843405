import { createSelector } from '@reduxjs/toolkit';

import { parseISODateTime } from '../../../utils/helpers';
import type IBaseQueryResponse from '../../types/IBaseQueryResponse';
import type { ITeamTicketRule } from './interfaces/ITeamTicketRule';
import type { ITeamTicketRuleLimits } from './interfaces/ITeamTicketRuleLimits';

export const selectEventTeamTicketRule = createSelector(
  (data: IBaseQueryResponse<ITeamTicketRule | null> | undefined) => data?.data,
  (rule) => ({
    teamTicketRuleId: rule?.id,
    ageValidateFrom: parseISODateTime(rule?.ageValidateFrom, rule?.timezone),
    divisionRegistrationEndAt: parseISODateTime(rule?.divisionRegistrationEndAt, rule?.timezone),
    coachLimit: rule?.coachLimit ?? 0,
    playerLimit: rule?.playerLimit ?? 0,
    multipleDiscountAmount: rule?.multipleDiscountAmount?.toString(),
    multipleDiscountTeamsCount: rule?.multipleDiscountTeamsCount?.toString(),
    refundPolicy: rule?.refundPolicy?.toString(),
    expandedMultipleDiscount: rule?.expandedMultipleDiscount || null,
    timezone: rule?.timezone,
  }),
);

export const selectEventTeamTicketRuleLimit = createSelector(
  (data: IBaseQueryResponse<ITeamTicketRuleLimits> | undefined) => data?.data,
  (limits) => ({
    maxCoachLimit: limits?.maxCoachLimit,
    maxPlayerLimit: limits?.maxPlayerLimit,
  }),
);

export default selectEventTeamTicketRule;
