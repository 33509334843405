import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Hidden from '@mui/material/Hidden';
import Box from '@mui/material/Box';

import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import useErrorMessage from '../../hooks/useErrorMessage';
import brand from '../../mocks/brand';
import AppRoutes from '../../constants/AppRoutes';
import type ILoginView from './interfaces/ILoginView';
import useStyles from './loginStyles-tss';

/**
 * Login page view
 */
function LoginView({
  onLoginSubmit,
  control,
  isLoading,
  isError,
  error,
}: ILoginView): ReactElement {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const errorMessage = useErrorMessage(error);

  return (
    <div className={classes.rootFull}>
      <div className={classes.containerSide}>
        <Hidden mdDown>
          <div className={classes.opening}>
            <Box sx={{ position: 'absolute', top: 80, left: { xs: 24, lg: 40 } }}>
              <NavLink to={AppRoutes.home}>
                <img src={brand.logo} alt={brand.logoText} width={40} height={40} />
              </NavLink>
            </Box>
            <Typography
              variant="h3"
              component="h1"
              fontWeight={500}
            >
              {t('login.welcomeTo')}
              <br />
              {brand.name}
            </Typography>
          </div>
        </Hidden>
        <div className={classes.sideFormWrap}>
          <Stack justifyContent="center" alignItems="center" p={3} width="100%">
            <Box component="form" onSubmit={onLoginSubmit} width={{ xs: '100%', md: 520 }}>
              <Typography
                component="h4"
                variant="h3"
                color="text.primary"
                fontWeight={500}
                mb={4}
              >
                {t('login.login')}
              </Typography>
              <Stack spacing={3}>
                <Controller
                  name="email"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error: fieldError },
                  }) => (
                    <TextField
                      className={classes.textFields}
                      helperText={fieldError?.message}
                      error={!!fieldError}
                      onChange={(e) => onChange(e.target.value.replace(/ /g, ''))}
                      value={value}
                      label={t('login.email')}
                      variant="outlined"
                      autoComplete="off"
                      autoCapitalize="off"
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error: fieldError },
                  }) => (
                    <TextField
                      className={classes.textFields}
                      type="password"
                      helperText={fieldError?.message}
                      error={!!fieldError}
                      onChange={(e) => onChange(e.target.value.replace(/ /g, ''))}
                      value={value}
                      label={t('login.password')}
                      variant="outlined"
                      autoComplete="off"
                      autoCapitalize="off"
                      fullWidth
                    />
                  )}
                />
              </Stack>
              <Stack direction="row" mt={1.5}>
                <Button
                  size="small"
                  variant="text"
                  onClick={() => toast.info(t('login.hint'))}
                  sx={{ ml: -1 }}
                >
                  {t('login.forgotPassword')}
                </Button>
              </Stack>
              <Button
                sx={{ mb: 2 }}
                variant="contained"
                type="submit"
                size="large"
                disabled={isLoading}
                fullWidth
              >
                {t('login.login')}
              </Button>
              {isError ? <ErrorMessage message={errorMessage} /> : null}
            </Box>
          </Stack>
        </div>
      </div>
    </div>
  );
}

export default LoginView;
