import logo from '../assets/images/logo.svg';

const brand = {
  name: 'Grit Network',
  logo,
  prefix: 'grit',
  logoText: 'Grit Network',
};

export default brand;
