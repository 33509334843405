import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import mainApi from './apis/mainApi';
import ui from './slices/ui/slice';
import auth from './slices/auth/slice';
import user from './slices/user/slice';
import page from './slices/page/slice';
import events from './slices/events/slice';
import createEvent from './slices/createEvent/slice';
import spectators from './slices/spectators/slice';
import teams from './slices/teams/slice';
import waivers from './slices/waivers/slice';
import schedule from './slices/schedule/slice';
import notifications from './slices/notifications/slice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth', 'createEvent', 'user'],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    [mainApi.reducerPath]: mainApi.reducer,
    ui,
    page,
    user,
    auth,
    events,
    createEvent,
    spectators,
    teams,
    waivers,
    schedule,
    notifications,
  }),
);

export function makeStore(preloadedState = {}) {
  return configureStore({
    reducer: persistedReducer,
    preloadedState: Object.keys(preloadedState).length ? preloadedState : undefined,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat([mainApi.middleware]),
  });
}

export const store = makeStore();

setupListeners(store.dispatch);

export const persistor = persistStore(store);
