import { useTranslation } from 'react-i18next';
import { Controller, useWatch } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';

import TextMask from '../../TextMask/TextMask';
import { DEFAULT_DATE_FORMAT, EVENT_FORM_TYPES } from '../../../constants/general';
import type { IMainInfoSection } from '../interfaces/IMainInfoSection';

function MainInfoSection({
  control,
  tournamentDaysInterval,
  maxSalesDate,
  formType,
  onTournamentDaysClick,
}: IMainInfoSection): React.ReactElement {
  const { t } = useTranslation();
  const [isDaysOpen, setIsDaysOpen] = useState<boolean>(false);
  const startAt = useWatch({ control, name: 'startAt' });
  const isPublished = useWatch({ control, name: 'isPublished' });
  const minAvailableTickets = useWatch({ control, name: 'minAvailableTickets' });

  return (
    <Box>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 5 }}>
        <Grid item xs={12}>
          <Controller
            name="tournamentDays"
            control={control}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error: fieldError },
            }) => (
              <FormControl error={!!fieldError} fullWidth>
                <InputLabel id="multiple-days-label">
                  {t('events.tournamentDays')}
                </InputLabel>
                <Select
                  id="multiple-days"
                  labelId="multiple-days-label"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  open={isDaysOpen}
                  label={t('events.tournamentDays')}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {selected.map((item) => (
                        <Chip key={item} label={item} sx={{ px: 0.5, height: 24 }} />
                      ))}
                    </Box>
                  )}
                  onOpen={() => {
                    if (!isPublished) {
                      setIsDaysOpen(true);
                    }
                  }}
                  onClose={() => {
                    if (!isPublished) {
                      setIsDaysOpen(false);
                    }
                  }}
                  onClick={onTournamentDaysClick}
                  multiple
                >
                  {tournamentDaysInterval.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={value.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
                {fieldError?.message ? <FormHelperText>{fieldError.message}</FormHelperText> : null}
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="startAt"
            control={control}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error: fieldError },
            }) => (
              <DatePicker
                label={t('events.startSellingDate')}
                value={value}
                onChange={onChange}
                onClose={onBlur}
                slotProps={{
                  textField: {
                    error: !!fieldError,
                    helperText: fieldError?.message,
                    fullWidth: true,
                    onBlur,
                  },
                }}
                format={DEFAULT_DATE_FORMAT}
                maxDate={maxSalesDate}
                timezone={maxSalesDate?.zoneName || undefined}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="endAt"
            control={control}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error: fieldError },
            }) => (
              <DatePicker
                label={t('events.endSellingDate')}
                value={value}
                onChange={onChange}
                onClose={onBlur}
                slotProps={{
                  textField: {
                    error: !!fieldError,
                    helperText: fieldError?.message,
                    fullWidth: true,
                    onBlur,
                  },
                }}
                format={DEFAULT_DATE_FORMAT}
                maxDate={maxSalesDate}
                minDate={startAt}
                timezone={maxSalesDate?.zoneName || undefined}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="availableTickets"
            control={control}
            render={({
              field: { onChange, onBlur, value = '' },
              fieldState: { error: fieldError },
            }) => (
              <>
                <TextField
                  helperText={fieldError?.message}
                  error={!!fieldError}
                  onChange={({ target }) => onChange(target.value || null)}
                  onBlur={onBlur}
                  value={value?.toString()}
                  label={t('events.availableTickets')}
                  variant="outlined"
                  InputProps={{
                    inputComponent: TextMask as any,
                    inputProps: { mask: Number, min: 0 },
                  }}
                  fullWidth
                />
                {formType === EVENT_FORM_TYPES.EDIT ? (
                  <Typography variant="body2" mt={1}>
                    {t('events.availableTicketsLimitHint', { limit: isPublished ? minAvailableTickets : '[X]' })}
                  </Typography>
                ) : null}
              </>
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default MainInfoSection;
