const AppRoutes = {
  home: '/',
  login: '/login',
  events: '/events',
  singleEvent: '/events/:eventId/details',
  singleEventEdit: '/events/:eventId/edit',
  singleEventAnalytics: '/events/:eventId',
  singleEventTeams: '/events/:eventId/teams',
  singleEventSchedule: '/events/:eventId/schedule',
  singleEventStandings: '/events/:eventId/standings',
  singleEventSignatures: '/events/:eventId/signatures',
  singleEventHotels: '/events/:eventId/hotels',
  singleEventPartners: '/events/:eventId/partners',
  singleEventWaivers: '/events/:eventId/waivers',
  singleEventRules: '/events/:eventId/rules',
  singleEventDivisions: '/events/:eventId/divisions',
  singleEventSpectators: '/events/:eventId/spectators-tickets',
  singleEventDates: '/events/:eventId/dates',
  singleEventSocial: '/events/:eventId/social-network',
  singleEventPoolPlayTimeFrame: '/events/:eventId/pool-play/time-frame',
  singleEventPoolPlayLogisticsAndGameSetup: '/events/:eventId/pool-play/logistics-and-game-setup',
  singleEventPoolPlaySchedule: '/events/:eventId/pool-play/schedule',
  singleEventPlayOffTimeFrame: '/events/:eventId/play-off/time-frame',
  singleEventPlayOffGameSetup: '/events/:eventId/play-off/game-setup',
  singleEventPlayOffSchedule: '/events/:eventId/play-off/schedule',
  singleEventNotifications: '/events/:eventId/notifications',
  createEvent: '/events/create/event',
  hotelsAndPartners: '/events/create/hotels-and-partners',
  documents: '/events/create/documents',
  teamsTickets: '/events/create/teams-tickets',
  spectatorsTickets: '/events/create/spectators-tickets',
  settings: '/settings',
  editProfile: '/settings/edit-profile',
  members: '/settings/members',
  notifications: '/settings/notifications',
  qrCode: '/settings/qr-code',
  paymentOptions: '/settings/payment-options',
  brackets: '/brackets',
} as const;

export type TAppRoutes = typeof AppRoutes[keyof typeof AppRoutes];

export default AppRoutes;
