import { DateTime } from 'luxon';

import scheduleApi from '../store/slices/schedule/apis/scheduleApi';
import {
  chunkArray, getFirstLetters, getNextChar, sumSecondsToMinutes,
} from './helpers';
import { MAX_DIVISION_AGE } from '../constants/general';
import type { IPoolPlayTeam, IGamePoolTeam, IGamePool } from '../store/slices/poolPlays/interfaces/IPoolPlayDivisionRules';
import type { IScheduleGame, IScheduleGamesFilters } from '../store/slices/poolPlays/interfaces/ISchedule';
import type { AppDispatch } from '../store/types/TStore';
import type { IEventRound } from '../store/slices/schedule/interfaces/IScheduleBracket';
import type { TDivisionGameSettingFormSchema } from './validators';
import type { ICreatePPPools } from '../store/slices/poolPlays/interfaces/IPoolPlayDivisionRulesReq';
import type { ISwapPPPools } from '../store/slices/poolPlays/interfaces/ISwapPPPoolTeamsRequest';
import type ICheckValidity from '../store/slices/poolPlays/interfaces/ICheckValidity';
import type { TItems } from '../pages/LogisticsAndGameSetup/interfaces/IPools';

export const createPPPools = (teams: IPoolPlayTeam[], teamsInPool: number, poolNextFreeName: string) => {
  const chunkTeams = chunkArray<IPoolPlayTeam>(teams, teamsInPool);

  return chunkTeams.reduce<Record<string, { id?: number, name?: string, teams: IPoolPlayTeam[] }>>((acc, item) => {
    const poolNames = Object.keys(acc);
    const lastPoolName = poolNames[poolNames.length - 1];
    const newPoolName = lastPoolName ? getNextChar(lastPoolName) : poolNextFreeName;

    return ({
      ...acc,
      [newPoolName]: { id: undefined, name: undefined, teams: item },
    });
  }, {});
};

export const parsePPGamesPools = (teams: IPoolPlayTeam[], gamesPools: IGamePool[]) => (
  gamesPools.reduce((acc, item) => ({
    ...acc,
    [item.name]: {
      ...item,
      teams: item.teams.map((team) => teams.find(({ id }) => id === team.id) || team),
    },
  }), {})
);

export const filterGame = (game: IScheduleGame, filters?: IScheduleGamesFilters) => {
  if (!filters) {
    return false;
  }

  let isShow = true;

  if (filters.teams.length) {
    isShow = filters.teams?.includes(game.teamA?.id) || filters.teams?.includes(game.teamB?.id);
  }
  if (filters.statisticians.length) {
    if (game.tOrgGamesStatisticianId) {
      isShow &&= filters.statisticians?.includes(game.tOrgGamesStatisticianId);
    } else {
      isShow = filters.statisticians?.includes(-1);
    }
  }
  if (filters.divisions.length) {
    isShow &&= filters.divisions.includes(game.tOrgEventDivisionId);
  }
  if (filters.pools.length) {
    isShow &&= filters.pools.includes(game.tOrgDivisionGamesPoolId);
  }

  return !isShow;
};

export const getGameError = (gameId: number, validity?: ICheckValidity) => {
  if (!validity) {
    return false;
  }

  let isError = false;

  if (validity.teamPlayManyGamesOneTime.length) {
    isError = validity.teamPlayManyGamesOneTime.includes(gameId);
  }

  return isError;
};

export const updateQueryData = (
  dispatch: AppDispatch,
  endpointName: string,
  originalArgs: any,
  field: any,
): any | null => {
  if (endpointName === 'getScheduleTimeRange') {
    return dispatch(scheduleApi.util.updateQueryData(endpointName, originalArgs, (draft: any) => {
      const arrayOfDate = draft.data
        ?.map(({ date }: { date: string }) => DateTime.fromISO(date).setZone('utc').toISODate());
      const startTime = DateTime.fromISO(field.startTime).toISODate();

      if (!arrayOfDate.includes(startTime)) {
        const dates = Array
          .from(new Set([...arrayOfDate, startTime]))
          .map((item) => ({ date: `${item}T00:00:00.000Z` }));

        Object.assign(draft.data, dates);
      }

      return draft;
    }));
  }

  if (endpointName === 'getScheduleGames') {
    return dispatch(scheduleApi.util.updateQueryData(endpointName, originalArgs, (draft: any) => {
      if (!('games' in draft.data)) {
        return draft;
      }

      const gameIndex = draft.data?.games?.findIndex(({ id }: any) => id === field.gameId);

      if (gameIndex >= 0) {
        const { durationSeconds = [], finishedAt: prevFinishedAt } = draft.data.games[gameIndex];
        const minutes = sumSecondsToMinutes(durationSeconds);
        const finishedAt = field.startTime
          ? (DateTime.fromISO(field.startTime).plus({ minutes }).toISO() || prevFinishedAt)
          : undefined;

        Object.assign(draft.data.games[gameIndex], field, { finishedAt });
      }

      return draft;
    }));
  }

  return null;
};

export const getGameRoundShortName = (game: IScheduleGame, eventRounds: IEventRound[]) => {
  const round = eventRounds.find(({ id }) => id === game.tOrgEventPlayOffRoundId);

  return round?.name ? getFirstLetters(round.name) : undefined;
};

export const compareByAge = (a: { divisionAge: string }, b: { divisionAge: string }) => {
  const ageA = parseInt(a.divisionAge);
  const ageB = parseInt(b.divisionAge);

  if (ageA === MAX_DIVISION_AGE && ageB === MAX_DIVISION_AGE) {
    if (a.divisionAge.toUpperCase().includes('O')) return 1;
    if (b.divisionAge.toUpperCase().includes('O')) return -1;
  }

  return ageA - ageB;
};

export const getPoolsForCreate = (pools: TDivisionGameSettingFormSchema['pools']) => (
  Object.values(pools).reduce<ICreatePPPools[]>((acc, item) => {
    const tempTeams = item.teams.reduce<ICreatePPPools['teams']>((teamsAcc, team, index) => (
      [...teamsAcc, { teamId: team.id, position: index + 1 }]
    ), []);

    return ([{ teams: tempTeams }, ...acc]);
  }, [])
);

export const getPoolsForSwap = (pools: TDivisionGameSettingFormSchema['pools']) => (
  Object.values(pools).reduce<ISwapPPPools[]>((acc, item) => {
    const tempTeams = item.teams.reduce<ISwapPPPools['teams']>((teamsAcc, team, index) => (
      [...teamsAcc, { teamId: team.id, teamIdForReplace: team.teamIdForReplace || team.id, position: index + 1 }]
    ), []);

    return ([...acc, { poolId: item.id!, teams: tempTeams }]);
  }, [])
);

export const swapItemsBetweenArrays = (
  array1: IGamePoolTeam[],
  index1: number,
  array2: IGamePoolTeam[],
  index2: number,
): [IGamePoolTeam[], IGamePoolTeam[]] => {
  const newItemArray1 = [...array1];
  const newItemArray2 = [...array2];

  const itemToMove = newItemArray1[index1];
  const itemToSwap = newItemArray2[index2];

  newItemArray1[index1] = { ...itemToSwap, teamIdForReplace: itemToMove.id };
  newItemArray2[index2] = { ...itemToMove, teamIdForReplace: itemToSwap.id };

  return [newItemArray1, newItemArray2];
};

export const poolSameClubIdError = (items: TItems) => (
  Object.values(items).some((item) => {
    const clubIds = new Set();
    return item.teams.some((team) => {
      if (clubIds.has(team.clubId)) {
        return true;
      }
      clubIds.add(team.clubId);
      return false;
    });
  })
);
