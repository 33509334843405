export enum AppThemeColors {
  Grey = 'grey',
  Red = 'red',
  Green = 'green',
  Blue = 'blue',
  Purple = 'purple',
  Orange = 'orange',
  LightGreen = 'lightGreen',
  LightBlue = 'lightBlue',
  Brown = 'brown',
}

export enum AppThemeMode {
  Dark = 'dark',
  Light = 'light',
}

export enum AppThemeDirection {
  Ltr = 'ltr',
  Rtl = 'rtl',
}
