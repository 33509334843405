import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import AppRoutes from '../../constants/AppRoutes';
import type IErrorBlock from './interfaces/IErrorBlock';
import useStyles from './errorStyle-tss';

/**
 * UI component for displaying an error page.
 */
function ErrorBlock({ title, desc }: IErrorBlock): React.ReactElement {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <div className={classes.errorWrap}>
      <Typography className={classes.title} variant="h1">{title}</Typography>
      <Typography variant="h5" sx={{ mb: 3 }}>{desc}</Typography>
      <Button variant="contained" component={NavLink} to={AppRoutes.home}>
        {t('common.goToDashboard')}
      </Button>
    </div>
  );
}

export default ErrorBlock;
