import { useOutletContext } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { toggleReplaceDatesDialog } from '../store/slices/spectators/slice';
import { useGetEventQuery } from '../store/slices/events/apis/eventsApi';
import { selectCreatedEvent } from '../store/slices/events/selectors';
import { selectEventSpectators } from '../store/slices/spectators/selectors';
import {
  useCreateSpectatorDayRulesMutation,
  useCreateSpectatorRuleMutation,
  useCreateSpectatorTypeRuleMutation,
  useDeleteSpectatorDayRuleMutation,
  useDeleteSpectatorTypeRuleMutation,
  useGetSpectatorRuleQuery,
  useUpdateSpectatorDayRulesMutation,
  useUpdateSpectatorRuleMutation,
  useUpdateSpectatorTypeRuleDiscountMutation,
  useDeleteSpectatorTypeRuleDiscountMutation,
  useUpdateSpectatorTypeRuleMutation,
  useCreateSpectatorTypeRuleDiscountMutation,
} from '../store/slices/spectators/apis/spectatorsApi';
import { useAppDispatch } from '../store/hooks/useApp';
import { createEventStepFiveSchema } from '../utils/validators';
import { getInterval } from '../utils/dates';
import { genTicketType } from '../utils/spectatorsTickets';
import { getErrorMessage } from '../utils/helpers';
import { AGE_RANGE } from '../constants/general';
import type ICreateEventContext from '../layout/CreateEventLayout/interfaces/ICreateEventContext';
import type { ISpectatorsTicketsFields } from '../components/SpectatorsTicketsForm/interfaces/ISpectatorsTicketsForm';
import type { IReplaceDates } from '../components/SpectatorsTicketsForm/interfaces/IReplaceDatesDialog';
import type { IUpdateSpectatorRuleReq } from '../store/slices/spectators/interfaces/IUpdateSpectatorRuleReq';

interface IHookProps {
  navigate: () => void
  onCancel: (isDirty: boolean) => void
}

const useEventSpectatorsForm = ({ navigate, onCancel }: IHookProps) => {
  const {
    eventId, orgId, isFetch,
  } = useOutletContext<ICreateEventContext>();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fetchQuery = isFetch ? { orgId, eventId: +eventId } : skipToken;

  const { event } = useGetEventQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, event: selectCreatedEvent(result.data) }),
    },
  );
  const { eventSpectatorRules } = useGetSpectatorRuleQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, eventSpectatorRules: selectEventSpectators(result.data) }),
    },
  );

  const [createSpectatorRule] = useCreateSpectatorRuleMutation();
  const [updateSpectatorRule] = useUpdateSpectatorRuleMutation();
  const [createSpectatorTypeRuleDiscount] = useCreateSpectatorTypeRuleDiscountMutation();
  const [updateSpectatorTypeRuleDiscount] = useUpdateSpectatorTypeRuleDiscountMutation();
  const [deleteSpectatorTypeRuleDiscount] = useDeleteSpectatorTypeRuleDiscountMutation();

  const [createSpectatorTypeRule] = useCreateSpectatorTypeRuleMutation();
  const [updateSpectatorTypeRule] = useUpdateSpectatorTypeRuleMutation();
  const [deleteSpectatorTypeRule] = useDeleteSpectatorTypeRuleMutation();

  const [createSpectatorDayRules] = useCreateSpectatorDayRulesMutation();
  const [updateSpectatorDayRules] = useUpdateSpectatorDayRulesMutation();
  const [deleteSpectatorDayRules] = useDeleteSpectatorDayRuleMutation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
  } = useForm<ISpectatorsTicketsFields>({
    mode: 'onBlur',
    resolver: yupResolver<ISpectatorsTicketsFields>(createEventStepFiveSchema),
    defaultValues: {
      isPublished: event.published,
      spectatorId: eventSpectatorRules?.spectatorId || undefined,
      tournamentDays: event.startAt && event.endAt
        ? getInterval(
          event.startAt.startOf('day'),
          event.endAt.endOf('minute'),
        )
        : [],
      startAt: DateTime.now().startOf('day'),
      endAt: event.endAt,
      minAvailableTickets: eventSpectatorRules?.availableTickets || 0,
      availableTickets: eventSpectatorRules?.availableTickets || undefined,
      isOneDayPass: eventSpectatorRules?.isOneDayPass || false,
      isWholeTournamentPass: eventSpectatorRules?.isWholeTournamentPass || false,
      oneDayPass: eventSpectatorRules?.oneDayPass || {
        price: '',
        ageRange: AGE_RANGE.ADULT,
        discount: { price: '', endAt: null },
        isDiscount: false,
        isChildTicket: false,
        isSeniorTicket: false,
        childTicket: {
          price: '',
          ageRange: AGE_RANGE.CHILD,
          isDiscount: false,
          discount: { price: '', endAt: null },
        },
        seniorTicket: {
          price: '',
          ageRange: AGE_RANGE.SENIOR,
          isDiscount: false,
          discount: { price: '', endAt: null },
        },
      },
      wholeTournamentPass: eventSpectatorRules?.wholeTournamentPass || {
        price: '',
        ageRange: AGE_RANGE.ADULT,
        discount: { price: '', endAt: null },
        isDiscount: false,
        isChildTicket: false,
        isSeniorTicket: false,
        childTicket: {
          price: '',
          ageRange: AGE_RANGE.CHILD,
          isDiscount: false,
          discount: { price: '', endAt: null },
        },
        seniorTicket: {
          price: '',
          ageRange: AGE_RANGE.SENIOR,
          isDiscount: false,
          discount: null,
        },
      },
    },
  });

  const handleCancel = () => {
    onCancel(isDirty);
  };

  const handleDatesSave = ({ update, remove, add }: IReplaceDates) => {
    setIsLoading(true);
    const updatePromises = update.length ? [
      updateSpectatorDayRules({
        orgId,
        eventId: +eventId,
        spOneDayRules: update.map((item) => ({
          id: item.dateId,
          accessToDay: DateTime.fromJSDate(item.accessToDay as Date).toFormat('MM/dd/yyyy'),
        })),
      }),
    ] : [];

    const removePromises = remove.map((item) => deleteSpectatorDayRules({
      orgId,
      eventId: +eventId,
      dayRuleId: item.dateId,
    }));

    const addPromises = add.map(({ accessToDay }) => createSpectatorDayRules({
      orgId,
      eventId: +eventId,
      accessToDay: DateTime.fromJSDate(accessToDay as Date).toFormat('MM/dd/yyyy'),
    }));

    Promise.all([...updatePromises, ...addPromises, ...removePromises]).then((res) => {
      const errors = res
        .map((item) => ('error' in item ? getErrorMessage(item.error) : null))
        .filter(Boolean);

      if (errors.length > 0) {
        errors.forEach((errorMessage) => {
          toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT });
        });
      } else {
        toast.success(t('events.changesSaved'), { position: toast.POSITION.TOP_RIGHT });
        dispatch(toggleReplaceDatesDialog(false));
      }
    }).finally(() => setIsLoading(false));
  };

  const handleCreateSubmit = handleSubmit(async (values) => {
    let allPromises;
    const { newTicketTypes, removeTicketTypes } = genTicketType(values, event.timezone);

    if (values.spectatorId) {
      const existingDateSet = new Set(values.tournamentDays.map((date) => DateTime.fromFormat(date, 'dd LLL yyyy').toISODate()));
      const apiDateSet = new Set(eventSpectatorRules?.oneDayTicketRules.map((apiDate) => DateTime.fromFormat(apiDate.accessToDay, 'MM/dd/yyyy').toISODate()));

      const idsDateToRemove = eventSpectatorRules?.oneDayTicketRules
        .filter((apiDate) => !existingDateSet.has(DateTime.fromFormat(apiDate.accessToDay, 'MM/dd/yyyy').toISODate()))
        .map((apiDate) => apiDate.id) || [];

      const newDates = values.tournamentDays
        .filter((date) => !apiDateSet.has(DateTime.fromFormat(date, 'dd LLL yyyy').toISODate()))
        .map((date) => DateTime.fromFormat(date, 'dd LLL yyyy').toFormat('MM/dd/yyyy'));

      const spectatorPromisesData: IUpdateSpectatorRuleReq = {
        orgId,
        eventId: +eventId,
        sellEndAt: DateTime.fromJSDate(values.endAt as Date).setZone(event.timezone).endOf('day').toISO(),
        sellStartAt: DateTime.fromJSDate(values.startAt as Date).setZone(event.timezone).startOf('day').toISO(),
      };

      if (eventSpectatorRules?.availableTickets !== values.availableTickets) {
        spectatorPromisesData.ticketCount = values.availableTickets ? +values.availableTickets : 0;
      }

      const spectatorPromises = updateSpectatorRule(spectatorPromisesData);

      const ticketTypesPromises = [...newTicketTypes].map(({ id: typeId, ...type }) => {
        const data = {
          orgId,
          eventId: +eventId,
        };

        return typeId
          ? updateSpectatorTypeRule({
            ...data,
            price: type.price,
            ageRange: type.ageRange,
            ruleId: typeId,
          })
          : createSpectatorTypeRule({ ...data, ...type });
      });

      const updateDiscountPromises = [...newTicketTypes]
        .filter(({ isDiscountDeleted, discount }) => !!discount && discount?.id && !isDiscountDeleted)
        .map(({ id: ruleId, discount }) => updateSpectatorTypeRuleDiscount({
          orgId,
          eventId: +eventId,
          ruleId: ruleId as number,
          price: discount?.price as number,
          endAt: discount?.endAt || null,
        }));

      const createDiscountPromises = [...newTicketTypes]
        .filter(({ id: ruleId, discount }) => ruleId && !!discount && !discount?.id)
        .map(({ id: ruleId, discount }) => createSpectatorTypeRuleDiscount({
          orgId,
          eventId: +eventId,
          ruleId: ruleId as number,
          price: discount?.price as number,
          endAt: discount?.endAt || null,
        }));

      const deleteDiscountPromises = [...newTicketTypes]
        .filter(({ isDiscountDeleted, id: ruleId }) => isDiscountDeleted && ruleId)
        .map(({ id: ruleId }) => deleteSpectatorTypeRuleDiscount({
          orgId,
          eventId: +eventId,
          ruleId: ruleId as number,
        }));

      const typeRulePromises = [...removeTicketTypes].map((ruleId) => deleteSpectatorTypeRule({
        orgId,
        eventId: +eventId,
        ruleId,
      }));

      const newDaysRulesPromises = newDates.map((date) => createSpectatorDayRules({
        orgId,
        eventId: +eventId,
        accessToDay: date,
      }));

      const removeDaysRulesPromises = idsDateToRemove.map((item) => deleteSpectatorDayRules({
        orgId,
        eventId: +eventId,
        dayRuleId: item,
      }));

      allPromises = [
        ...ticketTypesPromises,
        ...typeRulePromises,
        ...newDaysRulesPromises,
        ...removeDaysRulesPromises,
        ...createDiscountPromises,
        ...updateDiscountPromises,
        ...deleteDiscountPromises,
        spectatorPromises,
      ];
    } else {
      const ticketTypes = newTicketTypes.map(({ isDiscountDeleted, ...rest }) => rest);

      allPromises = [
        createSpectatorRule({
          orgId,
          eventId: +eventId,
          ticketTypes,
          ticketCount: values.availableTickets ? +values.availableTickets : 0,
          spOneDayRules: values.tournamentDays.map((day) => ({
            accessToDay: DateTime.fromFormat(day, 'dd LLL yyyy').toFormat('LL/dd/yyyy'),
          })),
          sellEndAt: DateTime.fromJSDate(values.endAt as Date).setZone(event.timezone).endOf('day').toISO(),
          sellStartAt: DateTime.fromJSDate(values.startAt as Date).setZone(event.timezone).startOf('day').toISO(),
        }),
      ];
    }

    Promise.all(allPromises).then((res) => {
      const errors = res
        .map((item) => ('error' in item ? getErrorMessage(item.error) : null))
        .filter(Boolean);

      if (errors.length > 0) {
        errors.forEach((errorMessage) => {
          toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT });
        });
      } else {
        navigate();
        reset();
      }
    });
  });

  useEffect(() => {
    if (eventSpectatorRules?.spectatorId) {
      reset({
        spectatorId: eventSpectatorRules.spectatorId,
        startAt: eventSpectatorRules?.startAt,
        endAt: eventSpectatorRules?.endAt,
        tournamentDays: eventSpectatorRules.tournamentDays,
        availableTickets: eventSpectatorRules.availableTickets,
        oneDayPass: {
          ...eventSpectatorRules.oneDayPass,
          childTicket: {
            ...eventSpectatorRules.oneDayPass.childTicket,
          },
          seniorTicket: {
            ...eventSpectatorRules.oneDayPass.seniorTicket,
          },
        },
        wholeTournamentPass: {
          ...eventSpectatorRules.wholeTournamentPass,
          childTicket: {
            ...eventSpectatorRules.wholeTournamentPass.childTicket,
          },
          seniorTicket: {
            ...eventSpectatorRules.wholeTournamentPass.seniorTicket,
          },
        },
        isOneDayPass: eventSpectatorRules.isOneDayPass,
        isWholeTournamentPass: eventSpectatorRules.isWholeTournamentPass,
        minAvailableTickets: eventSpectatorRules.availableTickets || 0,
        isPublished: event.published,
      });
    }
  }, [reset, event, eventSpectatorRules]);

  return {
    control,
    event,
    handleCancel,
    handleCreateSubmit,
    handleDatesSave,
    isDirty,
    isSubmitting,
    tournamentDates: eventSpectatorRules?.oneDayTicketRules || [],
    isLoading,
  };
};

export default useEventSpectatorsForm;
