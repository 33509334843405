import { useEffect, useState } from 'react';

import { findRequiredEventFields, getErrorDetails } from '../utils/helpers';
import { usePublishEventMutation } from '../store/slices/events/apis/eventsApi';
import FIELDS_VALIDATION from '../constants/hooksCacheKeys';
import type { IEventValidation } from '../mocks/eventValidation';

function useRequiredEventFields() {
  const [errorsList, setErrorList] = useState<IEventValidation[]>([]);

  const [, { error, isError }] = usePublishEventMutation({
    fixedCacheKey: FIELDS_VALIDATION,
  });

  useEffect(() => {
    const errors = getErrorDetails(error);

    if (errors) {
      const currentErrors = findRequiredEventFields(errors);
      setErrorList(currentErrors);
    }
  }, [error]);

  return {
    errorsList,
    isError,
  };
}

export default useRequiredEventFields;
