const PREFIX = 'POOL_PLAYS/';

export const POOL_PLAYS_RULES = `${PREFIX}RULES`;
export const POOL_PLAYS_PLAYING_FIELDS = `${PREFIX}PLAYING_FIELDS`;
export const POOL_PLAYS_RULES_LIST = `${PREFIX}RULES_LIST`;
export const POOL_PLAYS_SCHEDULE_TAG = `${PREFIX}SCHEDULE`;
export const POOL_PLAYS_SCHEDULE_GAME_TAG = `${PREFIX}SCHEDULE_GAME`;
export const POOL_PLAYS_PUBLISH_TAG = `${PREFIX}PUBLISH`;
export const POOL_PLAYS_CHECK_VALIDATION_TAG = `${PREFIX}CHECK_VALIDATION`;

export default POOL_PLAYS_PLAYING_FIELDS;
