import { useTranslation } from 'react-i18next';
import { Controller, useWatch } from 'react-hook-form';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import type { DateTime } from 'luxon';

import { DEFAULT_DATE_FORMAT, TIME_STEPS_FOR_TIME_PICKER } from '../../../constants/general';
import type { IEventRegistrationEndSection } from '../interfaces/IEventRegistrationEndSection';

export default function EventRegistrationEndSection({
  control,
}: IEventRegistrationEndSection) {
  const { t } = useTranslation();
  const endEventDate = useWatch({ control, name: 'endEventDate' }) as DateTime;

  return (
    <Paper sx={{ px: 3, pt: 3, pb: 6 }}>
      <Grid container rowSpacing={3} columnSpacing={5}>
        <Grid item xs={12}>
          <Typography component="h4" variant="h6">
            {t('events.teamRegistrationEnds')}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="endEventRegDate"
            control={control}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error: fieldError },
            }) => (
              <DatePicker
                label={t('events.date')}
                value={value}
                onChange={onChange}
                onClose={onBlur}
                maxDate={endEventDate || null}
                slotProps={{
                  textField: {
                    error: !!fieldError,
                    helperText: fieldError?.message,
                    fullWidth: true,
                    onBlur,
                  },
                }}
                format={DEFAULT_DATE_FORMAT}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="endEventRegDate"
            control={control}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error: fieldError },
            }) => (
              <TimePicker
                label={t('events.time')}
                value={value}
                onChange={onChange}
                onClose={onBlur}
                maxTime={endEventDate || null}
                timeSteps={TIME_STEPS_FOR_TIME_PICKER}
                slotProps={{
                  textField: {
                    error: !!fieldError,
                    helperText: fieldError?.message,
                    fullWidth: true,
                    onBlur,
                  },
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
