import { Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';

import SearchInput from '../../../components/SearchInput/SearchInput';
import type ITeamsFilterSelect from '../interfaces/ITeamsFilterSelect';

function TeamsFilterSelect({
  control,
  selectName,
  label,
  searchName,
  children,
  onOpen,
  onSearch,
}: ITeamsFilterSelect) {
  return (
    <Controller
      name={selectName}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth>
          <InputLabel htmlFor={`outlined-${selectName}-name`}>
            {label}
          </InputLabel>
          <Select
            id={`outlined-${selectName}-name`}
            value={value}
            variant="outlined"
            label={label}
            onChange={onChange}
            onOpen={onOpen}
            MenuProps={{
              MenuListProps: {
                sx: {
                  padding: '12px 0',
                },
              },
            }}
          >
            <Box
              mb={1}
              px={2}
              sx={{
                '.MuiPaper-root': {
                  background: '#757575',
                  padding: '6px 12px',
                },
                '.MuiInputBase-input': {
                  padding: '2px',
                },
                '.MuiButtonBase-root': {
                  width: '25px',
                  height: '25px',
                },
              }}
              onKeyDown={(e) => e.stopPropagation()}
            >
              <SearchInput
                control={control}
                name={searchName}
                onSearchChange={onSearch}
              />
            </Box>
            {children}
          </Select>
        </FormControl>
      )}
    />
  );
}

export default TeamsFilterSelect;
