import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type ICreateEventSlice from './interfaces/ICreateEventSlice';
import type { ILeaveModal } from './interfaces/ICreateEventSlice';

export const initialState: ICreateEventSlice = {
  completedSteps: {},
  leaveModal: {
    isOpen: false,
    link: '',
  },
};

export const createEventSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    resetCreateEventState: () => initialState,
    addCompletedStep: (state, { payload }: PayloadAction<number>) => {
      state.completedSteps[payload] = true;
    },
    setCompletedSteps: (state, { payload }: PayloadAction<{ [k: number]: boolean; }>) => {
      state.completedSteps = payload;
    },
    setLeaveModal: (state, { payload }: PayloadAction<Partial<ILeaveModal>>) => {
      state.leaveModal = { ...state.leaveModal, ...payload };
    },
  },
});

export const {
  resetCreateEventState,
  addCompletedStep,
  setCompletedSteps,
  setLeaveModal,
} = createEventSlice.actions;

export default createEventSlice.reducer;
