import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type INotificationSlice from './interfaces/INotificationSlice';

export const initialState: INotificationSlice = {
  isSubmitDialogOpen: false,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    toggleSubmitDialog: (state, { payload }: PayloadAction<boolean>) => {
      state.isSubmitDialogOpen = payload;
    },
  },
});

export const { toggleSubmitDialog } = notificationsSlice.actions;

export default notificationsSlice.reducer;
