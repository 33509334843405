import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import EventsTable from './components/EventsTable';
import SearchInput from '../../components/SearchInput/SearchInput';
import EventsFilter from './components/EventsFilter';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import AppRoutes from '../../constants/AppRoutes';
import type IEventsView from './interfaces/IEventsView';

/**
 * Events page view
 */
function EventsView({
  control,
  events,
  pagination,
  eventConfirmModal,
  currentEventAction,
  onCreateEventClick,
  onSortChange,
  onApplyFilters,
  onResetFilters,
  onSearchChange,
  onCloseConfirmModal,
  isEventsLoading,
  getTableActions,
  admin,
}: IEventsView): React.ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <Button
        variant="contained"
        size="large"
        sx={{ position: 'absolute', top: { xs: 92, lg: 102 }, right: { xs: 24, lg: 48 } }}
        component={NavLink}
        to={AppRoutes.createEvent}
        onClick={onCreateEventClick}
      >
        {t('events.createAnEvent')}
      </Button>
      <Stack direction="row" sx={{ mb: 3 }} justifyContent="space-between">
        <Box sx={{ width: 276 }}>
          <SearchInput control={control} onSearchChange={onSearchChange} />
        </Box>
        <EventsFilter control={control} onApplyFilters={onApplyFilters} onResetFilters={onResetFilters} />
      </Stack>
      <EventsTable
        control={control}
        events={events}
        pagination={pagination}
        isEventsLoading={isEventsLoading}
        onSortChange={onSortChange}
        getTableActions={getTableActions}
        admin={admin}
      />
      {eventConfirmModal.isOpen ? (
        <ConfirmDialog
          title={eventConfirmModal.title}
          description={eventConfirmModal.description}
          secondDescription={eventConfirmModal.secondDescription}
          open={eventConfirmModal.isOpen}
          onClose={onCloseConfirmModal}
          buttonText={t('common.delete')}
          onConfirm={currentEventAction}
        />
      ) : null}
    </>
  );
}

export default EventsView;
