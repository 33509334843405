import { useParams, useSearchParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import SingleEventScheduleView from './SingleEventScheduleView';
import { useAppSelector } from '../../store/hooks/useApp';
import { selectUserOrg } from '../../store/slices/user/selectors';
import { useGetPPFullInfoQuery } from '../../store/slices/poolPlays/apis/poolPlaysApi';
import { useGetPOFullInfoQuery } from '../../store/slices/playOff/apis/playOffApi';
import AppRoutes from '../../constants/AppRoutes';
import { ScheduleTypes, ScheduleView } from '../../constants/general';
import type { INoGameData } from './interfaces/ISingleEventScheduleView';

function SingleEventSchedule(): React.ReactElement {
  const { eventId } = useParams() as { eventId: string };
  const [searchParams, setSearchParams] = useSearchParams();

  const initialTab = searchParams.get('tab') as ScheduleTypes | null;
  const initialView = searchParams.get('view') as ScheduleView | null;

  const organization = useAppSelector(selectUserOrg);
  const [tabValue, setTabValue] = useState<ScheduleTypes>(initialTab || ScheduleTypes.POOL_PLAY);
  const [scheduleView, setScheduleView] = useState<ScheduleView>(initialView || ScheduleView.bracket);

  const { data: poolPlayFullInfo } = useGetPPFullInfoQuery(organization ? {
    orgId: organization.id, eventId: +eventId,
  } : skipToken);

  const { data: playOffFullInfo } = useGetPOFullInfoQuery(organization ? {
    orgId: organization.id, eventId: +eventId,
  } : skipToken);

  const poolPlayLink = useMemo(() => {
    if (poolPlayFullInfo?.data.allDivisionsCompleted && poolPlayFullInfo?.data.published) {
      return AppRoutes.singleEventPoolPlaySchedule;
    }
    if (poolPlayFullInfo?.data.commonRulesExist) {
      return AppRoutes.singleEventPoolPlayLogisticsAndGameSetup;
    }
    return AppRoutes.singleEventPoolPlayTimeFrame;
  }, [poolPlayFullInfo]);

  const playOffLink = useMemo(() => {
    if (playOffFullInfo?.data.allDivisionsCompleted && playOffFullInfo?.data.published) {
      return AppRoutes.singleEventPlayOffSchedule;
    }
    if (playOffFullInfo?.data.commonRulesExist) {
      return AppRoutes.singleEventPlayOffGameSetup;
    }
    return AppRoutes.singleEventPlayOffTimeFrame;
  }, [playOffFullInfo]);

  const noGamesData = useMemo(() => {
    const mapByTab: Record<ScheduleTypes, INoGameData> = {
      [ScheduleTypes.POOL_PLAY]: {
        text: 'createTournamentPoolPlaySchedule',
        link: poolPlayLink.replace(':eventId', eventId),
        isShow: !(poolPlayFullInfo?.data.published
          && poolPlayFullInfo?.data.allDivisionsCompleted
          && poolPlayFullInfo?.data.gamesExist
          && poolPlayFullInfo?.data.commonRulesExist),
      },
      [ScheduleTypes.PLAY_OFF]: {
        text: 'createTournamentPlayOffSchedule',
        link: playOffLink.replace(':eventId', eventId),
        isShow: !(playOffFullInfo?.data.published
          && playOffFullInfo?.data.allDivisionsCompleted
          && playOffFullInfo?.data.gamesExist
          && playOffFullInfo?.data.commonRulesExist),
      },
    };

    return mapByTab[tabValue];
  }, [poolPlayLink, playOffLink, eventId, poolPlayFullInfo?.data, playOffFullInfo?.data, tabValue]);

  const handleTabChange = (event: React.SyntheticEvent, value: ScheduleTypes) => {
    setTabValue(value);
    searchParams.set('tab', value);
    setSearchParams(searchParams, { replace: true });
  };

  const handleScheduleViewChange = (view: ScheduleView) => {
    setScheduleView(view);
    searchParams.set('view', view);
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <SingleEventScheduleView
      scheduleView={scheduleView}
      tabValue={tabValue}
      noGamesData={noGamesData}
      onTabChange={handleTabChange}
      poolPlayFullInfo={poolPlayFullInfo?.data}
      onViewChange={handleScheduleViewChange}
      fullInfoId={poolPlayFullInfo?.data?.id || playOffFullInfo?.data?.id}
    />
  );
}

export default SingleEventSchedule;
