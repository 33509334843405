import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

import DropZone from '../../DropZone/DropZone';
import PlacesAutocomplete from '../../PlacesAutocomplete/PlacesAutocomplete';
import CreateTopicDialog from './CreateTopicDialog';
import { useAppSelector } from '../../../store/hooks/useApp';
import { selectUserOrg } from '../../../store/slices/user/selectors';
import { useLazyGetEventsTopicsQuery } from '../../../store/slices/events/apis/eventsApi';
import { getAddressErrorMessage } from '../../../utils/helpers';
import { COVER_MIME_TYPES_ACCEPT } from '../../../constants/general';
import type { IMainInfoSection } from '../interfaces/ICreateEventForm';

function MainInfoSection({ control, onDeleteCoverPhoto }: IMainInfoSection): React.ReactElement {
  const { t } = useTranslation();
  const org = useAppSelector(selectUserOrg);

  const [getEventsTopics, { data: topics }] = useLazyGetEventsTopicsQuery();

  return (
    <>
      <Typography component="h4" mb={2} fontSize={20} fontWeight={500}>
        {t('events.mainInfo')}
      </Typography>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 5 }}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="name"
            control={control}
            render={({
              field: { value, onChange, ...props },
              fieldState: { error: fieldError },
            }) => (
              <TextField
                value={value}
                helperText={fieldError?.message || `${value?.length || 0}/60 ${t('symbols')}`}
                error={!!fieldError}
                label={t('events.name')}
                variant="outlined"
                fullWidth
                onChange={(e) => onChange(e.target.value.slice(0, 60))}
                {...props}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="topic"
            control={control}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error: fieldError },
            }) => (
              <FormControl error={!!fieldError} fullWidth>
                <InputLabel htmlFor="outlined-topic" shrink>
                  {`${t('events.topic')} (${t('optional')})`}
                </InputLabel>
                <Select
                  value={value?.name || ''}
                  name="topic"
                  variant="outlined"
                  id="outlined-topic"
                  label={`${t('events.topic')} (${t('optional')})`}
                  onOpen={() => (org?.id ? getEventsTopics({ orgId: org.id }) : null)}
                  onBlur={onBlur}
                  notched
                  displayEmpty
                  fullWidth
                >
                  <Box mx={1} mb={1}>
                    <CreateTopicDialog onCreate={() => (org?.id ? getEventsTopics({ orgId: org.id }) : null)} />
                  </Box>
                  <MenuItem value="" onClick={() => onChange(null)}>
                    <em>None</em>
                  </MenuItem>
                  {topics?.data.map((topic) => (
                    <MenuItem key={topic.id} value={topic.name} onClick={() => onChange(topic)}>
                      {topic.name}
                    </MenuItem>
                  ))}
                  {!topics && !!value ? (
                    <MenuItem key={value.id} value={value.name} onClick={() => onChange(value)}>
                      {value.name}
                    </MenuItem>
                  ) : null}
                </Select>
                {fieldError?.message ? <FormHelperText>{fieldError.message}</FormHelperText> : null}
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="tagline"
            control={control}
            render={({
              field: { value, onChange, ...props },
              fieldState: { error: fieldError },
            }) => (
              <TextField
                helperText={fieldError?.message || `${value?.length || 0}/100 ${t('symbols')}`}
                error={!!fieldError}
                value={value}
                label={`${t('events.tagline')} (${t('optional')})`}
                variant="outlined"
                fullWidth
                onChange={(e) => onChange(e.target.value.slice(0, 100))}
                {...props}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="address"
            control={control}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error: fieldError },
            }) => (
              <PlacesAutocomplete
                defaultValue={value?.streetAddress1 || ''}
                onBlur={onBlur}
                onChange={onChange}
                label={t('events.address')}
                helperText={getAddressErrorMessage(fieldError)}
                error={!!fieldError}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="description"
            control={control}
            render={({
              field: { value = '', onChange, ...props },
              fieldState: { error: fieldError },
            }) => (
              <TextField
                value={value}
                helperText={fieldError?.message || `${value?.length || 0}/512 ${t('symbols')}`}
                error={!!fieldError}
                label={t('events.addressComment')}
                variant="outlined"
                multiline
                fullWidth
                onChange={(e) => onChange(e.target.value.slice(0, 512))}
                {...props}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography mb={1.5}>Cover</Typography>
          <Controller
            name="cover"
            control={control}
            render={({
              field: { onChange, value },
            }) => (
              <DropZone
                onUploadChange={([file]) => onChange(file)}
                onDelete={onDeleteCoverPhoto}
                accept={COVER_MIME_TYPES_ACCEPT}
                files={value ? [value] : []}
                filesLimit={1}
                maxSize={30000000}
                text={t('events.dragImageText')}
                desc={`${t('events.dragImageDesc')}`}
                isMultiFiles={false}
                showPreviews
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default memo(MainInfoSection);
