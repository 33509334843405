import { EventStatuses } from './Statuses';
import AppRoutes from './AppRoutes';
import type { ITextDiscount } from '../interfaces/IDiscountOptions';

export const IMAGES_MIME_TYPES_ACCEPT = {
  'image/heic': [],
  'image/svg+xml': [],
  'image/png': [],
  'image/jpeg': [],
};

export const COVER_MIME_TYPES_ACCEPT = {
  'image/heic': [],
  'image/jpg': [],
  'image/jpeg': [],
  'image/png': [],
};

export const AGE_RANGE = {
  ADULT: 'from 18 to 65 y.o',
  CHILD: 'from 5 to 17 y.o.',
  SENIOR: 'over 65 y.o.',
} as const;
export const MIN_MINUTES_FOR_GAME = 30;
export const SECONDS_IN_MINUTE = 60;
export const CENTS_PER_DOLLAR = 100;
export const DEFAULT_DATE_FORMAT = 'LLL dd yyyy';
export const DEFAULT_PER_PAGE = 25;
export const DEFAULT_MAX_PER_PAGE = 100;
export const STATISTICIANS_MAX_PER_PAGE = 10;
export const DEFAULT_EVENT_TYPE = 'TOURNAMENT_7ON7';
export const MAX_DIVISION_AGE = 18;
export const DATE_RANGE = 'dateRange';
export const DEFAULT_EVENT_STATUS_ORDER_BY = [
  EventStatuses.Draft, EventStatuses.Current, EventStatuses.Upcoming, EventStatuses.Canceled, EventStatuses.Over,
];

export const GOOGLE_PLACES_AUTOCOMPLETE_PARAMS_TYPES = {
  REGIONS: '(regions)',
  ADDRESS: 'address',
} as const;

export const GOOGLE_PLACES_ADDRESS_COMPONENTS_TYPES = {
  STREET_ADDRESS: 'street_address',
  POSTAL_CODE: 'postal_code',
  STATE: 'administrative_area_level_1',
  CITY: 'locality',
  ROUTE: 'route',
  NEIGHBORHOOD: 'neighborhood',
} as const;

export const ORDERS = {
  ASC: 'asc',
  DESC: 'desc',
} as const;

export const GENDERS = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  MIXED: 'CO_ED',
} as const;

export const GENDERS_ORDER = {
  [GENDERS.MALE]: 1,
  [GENDERS.FEMALE]: 2,
  [GENDERS.MIXED]: 3,
} as const;

export const TEAM_AGES = ['4U', '5U', '6U', '7U', '8U', '9U', '10U', '11U', '12U', '13U', '14U', '15U', '16U', '17U', '18U', '18O'];

export const INSTAGRAM_URL = 'https://www.instagram.com/';
export const FACEBOOK_URL = 'https://www.facebook.com/';
export const TWITTER_URL = 'https://twitter.com/';
export const TIKTOK_URL = 'https://www.tiktok.com/';
export const HUDL_URL = 'https://www.hudl.com/';
export const YOUTUBE = 'https://www.youtube.com/';

export const SOCIAL_NAMES = {
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  HUDL: 'Hudl',
  X: 'X',
  TIKTOK: 'Tiktok',
  YOUTUBE: 'Youtube',
} as const;
export type TSocialName = typeof SOCIAL_NAMES[keyof typeof SOCIAL_NAMES];

export const CREATE_EVENT_STEPS = [
  {
    label: 'createEvent',
    route: AppRoutes.createEvent,
  },
  {
    label: 'hotelsAndPartners',
    route: AppRoutes.hotelsAndPartners,
  },
  {
    label: 'documents',
    route: AppRoutes.documents,
  },
  {
    label: 'teamsTickets',
    route: AppRoutes.teamsTickets,
  },
  {
    label: 'spectatorsTickets',
    route: AppRoutes.spectatorsTickets,
  },
] as const;

export const POOL_PLAY_SCHEDULE_STEPS = [
  {
    label: 'schedule.timeFrame',
    route: AppRoutes.singleEventPoolPlayTimeFrame,
  },
  {
    label: 'schedule.logisticsAndGameSetup',
    route: AppRoutes.singleEventPoolPlayLogisticsAndGameSetup,
  },
  {
    label: 'schedule.schedule',
    route: AppRoutes.singleEventPoolPlaySchedule,
  },
] as const;

export const PLAY_OFF_SCHEDULE_STEPS = [
  {
    label: 'schedule.timeFrame',
    route: AppRoutes.singleEventPlayOffTimeFrame,
  },
  {
    label: 'schedule.divisionSetUpFields',
    route: AppRoutes.singleEventPlayOffGameSetup,
  },
  {
    label: 'schedule.playOffSchedule',
    route: AppRoutes.singleEventPlayOffSchedule,
  },
] as const;

export const EVENT_FORM_TYPES = {
  CREATE: 'create',
  EDIT: 'edit',
} as const;
export type TEventFormTypes = typeof EVENT_FORM_TYPES[keyof typeof EVENT_FORM_TYPES];

export const STATUSES_PALLET_MAP: Record<EventStatuses, string> = {
  [EventStatuses.Current]: 'success.main',
  [EventStatuses.Over]: 'primary.main',
  [EventStatuses.Upcoming]: 'warning.dark',
  [EventStatuses.Draft]: 'text.disabled',
  [EventStatuses.Canceled]: 'error.light',
};

export const SPECTATORS_TICKET_PASS = {
  DAY_PASS: 'DAY_PASS',
  WHOLE_PASS: 'WHOLE_PASS',
} as const;
export type TSpectatorsTicketPass = typeof SPECTATORS_TICKET_PASS[keyof typeof SPECTATORS_TICKET_PASS];

export const SPECTATORS_TICKET_TYPE = {
  ADULT: 'ADULT',
  CHILD: 'CHILD',
  SENIOR: 'SENIOR',
} as const;
export type TSpectatorsTicketType = typeof SPECTATORS_TICKET_TYPE[keyof typeof SPECTATORS_TICKET_TYPE];

export const TIME_STEPS_FOR_TIME_PICKER = { minutes: 1 };

export const RADIAN = Math.PI / 180;

export const MONTHS = [
  'JAN', 'FEB', 'MAR', 'APR',
  'MAY', 'JUN', 'JUL', 'AUG',
  'SEP', 'OCT', 'NOV', 'DEC',
] as const;

export const SIGNATURE_STATUS = {
  PENDING: 'Pending',
  SIGNED: 'Signed',
} as const;
export type TSignatureStatus = typeof SIGNATURE_STATUS[keyof typeof SIGNATURE_STATUS];

export const SIGNATURES_ORDER = {
  [SIGNATURE_STATUS.SIGNED]: 1,
  [SIGNATURE_STATUS.PENDING]: 2,
} as const;

export const EARNED_TYPE = {
  TEAM: 'TEAM',
  SPECTATOR: 'SPECTATOR',
  VERIFICATION: 'VERIFICATION',
} as const;
export type TEarnedType = typeof EARNED_TYPE[keyof typeof EARNED_TYPE];

export const DISCOUNT_OPTION: ITextDiscount = {
  OFF: 'off',
  SUBSEQUENT: 'subsequent',
  SIMULTANEOUS: 'simultaneous',
} as const;

export const TEXT_DISCOUNT_OPTION: ITextDiscount = {
  OFF: 'offOption',
  SUBSEQUENT: 'subsequentOption',
  SIMULTANEOUS: 'simultaneousOption',
} as const;

export const DASHBOARD = 'Dashboard';

export enum ScheduleTypes {
  POOL_PLAY = 'POOL_PLAY',
  PLAY_OFF = 'PLAYOFF',
}

export enum GamesTypes {
  POOL_PLAY = 'POOL_PLAY',
  PLAYOFF = 'PLAYOFF',
  CHAMPIONSHIP = 'CHAMPIONSHIP',
}

export const USER_ROLES = {
  ADMIN: 'TOURNAMENT_ORGANIZATION_ADMINISTRATION',
  STATISTICIAN: 'TOURNAMENT_ORGANIZATION_STATISTICIAN',
} as const;

export const TOAST_CONTAINER = {
  SCHEDULE: 'SCHEDULE',
} as const;

export enum ScheduleView {
  bracket = 'bracket',
  calendar = 'calendar',
}

export const NOTIFICATION_TYPE = {
  REGULAR: 'REGULAR',
  CRITICAL: 'CRITICAL',
} as const;
export type TNotificationType = typeof NOTIFICATION_TYPE[keyof typeof NOTIFICATION_TYPE];

export const RECIPIENTS_TYPE = {
  ALL: 'ALL',
  COACH: 'COACH',
  ATHLETE_AND_GUARDIAN: 'ATHLETE_AND_GUARDIAN',
  ORGANIZATION_MEMBER: 'ORGANIZATION_MEMBER',
  TEAM: 'TEAM',
  SPECTATOR: 'SPECTATOR',
} as const;
export type TRecipientsType = typeof RECIPIENTS_TYPE[keyof typeof RECIPIENTS_TYPE];

export const USER_VERIFICATION_TYPE = {
  VERIFIED: 'VERIFIED',
  PENDING: 'PENDING',
} as const;
export type TUserVerificationType = typeof USER_VERIFICATION_TYPE[keyof typeof USER_VERIFICATION_TYPE];

export const TIMEFRAME_YEARS_RANGE = {
  MIN: 2020,
  MAX: 3000,
};
