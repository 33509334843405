import { NavLink, useNavigate } from 'react-router-dom';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import OrgSwitcher from './components/OrgSwitcher';
import { setUserOrganization } from '../../store/slices/user/slice';
import { selectUserOrg, selectUserOrgList } from '../../store/slices/user/selectors';
import { logout } from '../../store/slices/auth/slice';
import mainApi from '../../store/apis/mainApi';
import { useAppSelector, useAppDispatch } from '../../store/hooks/useApp';
import brand from '../../mocks/brand';
import AppRoutes from '../../constants/AppRoutes';
import { USER_DEVICE_ID } from '../../constants/localStorageKeys';
import type IOrganization from '../../store/slices/user/interfaces/IOrganization';
import useStyles from './headerStyles-tss';

/**
 * Main layout header component
 */
function Header({ title }: { title?: string }): React.ReactElement {
  const { classes, cx } = useStyles();
  const [turnDarker, setTurnDarker] = useState<boolean>(false);
  const [showTitle, setShowTitle] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const organization = useAppSelector(selectUserOrg);
  const organizationList = useAppSelector(selectUserOrgList);
  const navigate = useNavigate();

  const flagDarker = useRef<boolean>(false);
  const flagTitle = useRef<boolean>(false);

  const handleScroll = useCallback(() => {
    const doc = document.documentElement;
    const scroll = doc.scrollTop - (doc.clientTop || 0);
    const newFlagDarker = (scroll > 30);
    const newFlagTitle = (scroll > 40);
    if (flagDarker.current !== newFlagDarker) {
      setTurnDarker(newFlagDarker);
      flagDarker.current = newFlagDarker;
    }
    if (flagTitle.current !== newFlagTitle) {
      setShowTitle(newFlagTitle);
      flagTitle.current = newFlagTitle;
    }
  }, []);

  const handleSwitchOrg = (org: IOrganization) => {
    if (organization?.id !== org.id) {
      dispatch(setUserOrganization(org));
      navigate(AppRoutes.home);
    }
  };

  const handleLogout = () => {
    dispatch(mainApi.util.resetApiState());
    dispatch(logout());
    localStorage.removeItem(USER_DEVICE_ID);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <AppBar
      className={cx(
        classes.appBar,
        classes.floatingBar,
        turnDarker && classes.darker,
      )}
    >
      <Toolbar className={classes.appToolbar} disableGutters>
        <NavLink to={AppRoutes.home}>
          <img src={brand.logo} alt={brand.logoText} />
        </NavLink>
        {!!title && (
          <div className={classes.headerProperties}>
            <Typography
              component="h2"
              className={cx(classes.headerTitle, showTitle && classes.show)}
            >
              {title}
            </Typography>
          </div>
        )}
        {organization && organizationList ? (
          <OrgSwitcher
            selected={organization}
            list={organizationList}
            onSwitch={handleSwitchOrg}
            onLogout={handleLogout}
          />
        ) : null}
      </Toolbar>
    </AppBar>
  );
}

Header.defaultProps = {
  title: undefined,
};

export default Header;
