import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {
  XAxis, YAxis, AreaChart, ResponsiveContainer, CartesianGrid, Tooltip, Area,
} from 'recharts';

import EmptyData from '../EmptyData/EmptyData';
import type IRevenueWidget from './interfaces/IRevenueWidget';

const tooltipContentStyles = {
  color: 'white',
  fontFamily: 'Maven Pro',
  fontWeight: 500,
};

const tickStyles = {
  fill: 'white',
  fontFamily: 'Maven Pro',
  fontSize: 12,
  fontWeight: 500,
};

function RevenueWidget({
  title,
  chartData,
}: IRevenueWidget) {
  const { t } = useTranslation();

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h5" lineHeight="24px" mb={4.25}>
        {title}
      </Typography>
      {chartData.length ? (
        <ResponsiveContainer width="100%" height={370}>
          <AreaChart
            data={chartData}
            margin={{
              top: 8, right: 8, left: 8, bottom: 8,
            }}
          >
            <defs>
              <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                <stop stopColor="#02A9F4" />
                <stop offset="1" stopColor="#9C27B0" stopOpacity="0" />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="data"
              height={40}
              tickMargin={18}
              tick={tickStyles}
            />
            <YAxis
              width={45}
              tickCount={11}
              tickMargin={10}
              axisLine={false}
              tick={tickStyles}
            />
            <CartesianGrid
              strokeDasharray="5"
              vertical={false}
            />
            <Tooltip
              formatter={(value) => [`${Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(value as number)}`, t('dashboard.earned')]}
              contentStyle={{
                backgroundColor: '#1F1F1F',
                border: '1px solid #1F1F1F',
                borderRadius: '8px',
                padding: '10px',
              }}
              labelStyle={tooltipContentStyles}
              itemStyle={tooltipContentStyles}
            />
            <Area
              type="linear"
              dataKey="amount"
              stroke="#02A9F4"
              fill="url(#gradient)"
              dot={{
                stroke: '#02A9F4',
                fill: '#1F1F1F',
                strokeWidth: 1,
              }}
            />
          </AreaChart>
        </ResponsiveContainer>
      ) : <EmptyData description={t('common.noData')} />}
    </Paper>
  );
}

export default RevenueWidget;
