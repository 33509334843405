import { createContext, useCallback, useState } from 'react';
import { createTheme, ThemeProvider as MUIThemeProvider, type PaletteMode } from '@mui/material';
import { ToastContainer } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../store/hooks/useApp';
import { selectThemeColor, selectThemeMode } from '../../store/slices/ui/selectors';
import { changeThemeMode } from '../../store/slices/ui/slice';
import appTheme from '../../themes/theme';
import { AppThemeDirection } from '../../constants/AppThemes';
import { TOAST_CONTAINER } from '../../constants/general';
import type IThemeProvider from './interfaces/IThemeProvider';
import type TThemeContext from './types/TThemeContext';

export const ThemeContext = createContext<TThemeContext | null>(null);

function ThemeProvider({ children }: IThemeProvider) {
  const dispatch = useAppDispatch();
  const themeColor = useAppSelector(selectThemeColor);
  const themeMode = useAppSelector(selectThemeMode);
  const [theme, setTheme] = useState(
    createTheme(appTheme(themeColor, themeMode, AppThemeDirection.Ltr)),
  );

  const handleChangeMode = useCallback((mode: PaletteMode) => {
    setTheme(createTheme(appTheme(themeColor, mode, AppThemeDirection.Ltr)));
    dispatch(changeThemeMode({ mode }));
  }, [dispatch, themeColor]);

  return (
    <MUIThemeProvider theme={theme}>
      <ThemeContext.Provider value={handleChangeMode}>
        {children}
        <ToastContainer enableMultiContainer theme={themeMode} />
        <ToastContainer
          enableMultiContainer
          containerId={TOAST_CONTAINER.SCHEDULE}
          theme={themeMode}
          style={{ width: '100%', maxWidth: 760 }}
        />
      </ThemeContext.Provider>
    </MUIThemeProvider>
  );
}

export default ThemeProvider;
