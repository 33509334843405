import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { alpha } from '@mui/material/styles';

import useStyles from './bracketStyle-tss';
import GhostIcon from '../Icons/GhostIcon';
import { GameTeamResults, GameTeamTypes } from '../../store/slices/schedule/interfaces/IScheduleBracket';
import { useAppSelector } from '../../store/hooks/useApp';
import selectScheduleBracket from '../../store/slices/schedule/selectors';
import type ITeamProps from './interfaces/ITeam';

const mapIcons = {
  [GameTeamTypes.GHOST]: <GhostIcon sx={{ width: 14, height: 14 }} />,
  [GameTeamTypes.TEMPORARY]: <HelpOutlineIcon sx={{ width: 14, height: 14 }} />,
  [GameTeamTypes.REAL]: null,
};

function Team({ team }: ITeamProps) {
  const { classes, cx } = useStyles();

  const { teams: selectedTeams } = useAppSelector(selectScheduleBracket);

  return (
    <Box
      className={classes.team}
      sx={(theme) => ({
        bgcolor: selectedTeams.some(({ value }) => value === team.id)
          ? alpha(theme.palette.primary.contrastText, 0.1)
          : undefined,
      })}
    >
      <Avatar
        src={team.profilePhoto || undefined}
        sx={{ width: 20, height: 20 }}
      >
        {mapIcons[team.teamType]}
      </Avatar>

      {team.teamType === GameTeamTypes.TEMPORARY ? (
        <Box
          sx={(theme) => ({
            borderRadius: 8,
            height: 6,
            width: 100,
            bgcolor: alpha(theme.palette.primary.contrastText, 0.2),
          })}
        />
      ) : (
        <Typography
          className={classes.teamName}
          color={team.teamType === GameTeamTypes.GHOST || team.stats?.result === GameTeamResults.LOSE ? 'text.disabled' : undefined}
          whiteSpace="nowrap"
        >
          {`${team.numberInSeeding || ''} ${team.name}`.trim()}
        </Typography>
      )}

      <Box
        className={cx(
          classes.teamScore,
          team.stats?.result === GameTeamResults.WIN && classes.win,
          team.stats?.result === GameTeamResults.LOSE && classes.lose,
        )}
      >
        {team.stats?.pointsFor}
      </Box>
    </Box>
  );
}

export default Team;
