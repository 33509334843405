import Box from '@mui/material/Box';

import { testIdErrorMessage } from '../../constants/TestId';
import type IErrorMessage from './interfaces/IErrorMessage';

/**
 * Primary UI component to display error message for form fields.
 */
function ErrorMessage({ message }: IErrorMessage): React.ReactElement {
  return (
    <Box
      component="span"
      data-testid={testIdErrorMessage}
      sx={{
        color: 'error.main',
        my: 0.5,
        textAlign: 'center',
        fontSize: 12,
      }}
    >
      {message}
    </Box>
  );
}

export default ErrorMessage;
