import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import EditOutlined from '@mui/icons-material/EditOutlined';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import EventIcon from '@mui/icons-material/Event';

import PaperBlock from '../../../components/PaperBlock/PaperBlock';
import { getRelativePath } from '../../../utils/helpers';
import AppRoutes from '../../../constants/AppRoutes';
import useStyles from '../../../components/PaperBlock/papperStyle-tss';
import type { IDatesWidget } from '../interfaces/IDatesWidget';

function DatesWidget({
  dates,
  tournamentsDays,
  teamRegistrationDates,
  teamDiscountsList = [],
  eventSpectatorsDates,
  oneDayTickets = [],
  wholeEventTickets = [],
}: IDatesWidget): React.ReactElement {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { eventId } = useParams();

  return (
    <PaperBlock
      title={t('events.dates')}
      endAdornment={(
        <Button
          startIcon={<EditOutlined />}
          component={Link}
          to={`/events/${eventId}${getRelativePath(AppRoutes.singleEventDates, 'route')}`}
          size="small"
        >
          {t('common.edit')}
        </Button>
      )}
      whiteBg
      noMargin
      fullHeight
    >
      <Divider sx={{ my: 3 }} />
      <List
        sx={{
          py: 0,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 3,
        }}
      >
        {dates.map((item) => (
          <ListItem
            key={`EventDatesList_${item.id}`}
            sx={{ p: 0, flexBasis: '45%' }}
          >
            <ListItemAvatar>
              <Avatar sx={{ color: 'white' }}>
                {item.icon}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={item.name}
              secondary={item.date}
              sx={{ my: 0 }}
            />
          </ListItem>
        ))}
      </List>

      {tournamentsDays.length ? (
        <>
          <Divider sx={{ my: 3 }} />
          <Typography variant="h6" component="h2" className={classes.title}>
            {t('events.tournamentDays')}
          </Typography>
          <Stack
            sx={{ mt: 2 }}
            flexDirection="row"
            flexWrap="wrap"
            columnGap={1}
            rowGap={1}
          >
            {tournamentsDays.map((item) => (
              <Chip key={`TournamentsDaysList_${item}`} label={item} sx={{ px: 0.5, height: 24 }} />
            ))}
          </Stack>
        </>
      ) : null}

      <Divider sx={{ my: 3 }} />
      <Typography variant="h6" component="h2" mb={2} className={classes.title}>
        {t('events.teamRegistration')}
      </Typography>
      <List
        sx={{
          py: 0,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 3,
        }}
      >
        {teamRegistrationDates.map((item) => (
          <ListItem
            key={`DatesRegistrationList_${item.id}`}
            sx={{ p: 0, flexBasis: '45%' }}
          >
            <ListItemAvatar>
              <Avatar sx={{ color: 'white' }}>
                {item.icon}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={item.name}
              secondary={item.date}
              sx={{ my: 0 }}
            />
          </ListItem>
        ))}
      </List>

      {teamDiscountsList.map((item) => (
        <Stack key={`TeamTicketsDiscountList_${item.id}`}>
          <Divider sx={{ my: 3 }} />
          <Typography variant="h6" component="h2" mb={2} className={classes.title}>
            {item.name}
          </Typography>
          <List disablePadding>
            <ListItem disablePadding disableGutters>
              <ListItemAvatar>
                <Avatar sx={{ color: 'white' }}>
                  <EventIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={t('events.discountSalesEndsOn')}
                secondary={item.date}
                sx={{ my: 0 }}
              />
            </ListItem>
          </List>
        </Stack>
      ))}

      <Divider sx={{ my: 3 }} />
      <Typography variant="h6" component="h2" mb={2} className={classes.title}>
        {t('events.spectatorsTickets')}
      </Typography>
      <List
        sx={{
          py: 0,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 3,
        }}
      >
        {eventSpectatorsDates.map((item) => (
          <ListItem
            key={`SpectatorsDatesList_${item.id}`}
            sx={{ p: 0, flexBasis: '45%' }}
          >
            <ListItemAvatar>
              <Avatar sx={{ color: 'white' }}>
                {item.icon}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={item.name}
              secondary={item.date}
              sx={{ my: 0 }}
            />
          </ListItem>
        ))}
      </List>

      {oneDayTickets?.length ? (
        <>
          <Divider sx={{ my: 3 }} />
          <Typography variant="h6" component="h2" className={classes.title} mb={2}>
            {t('events.oneDayPass')}
          </Typography>
          <List sx={{ py: 0 }}>
            {oneDayTickets.map((item) => (
              <Stack key={`SpectatorsOnePassDiscountList_${item.id}`} mb={2}>
                <Typography variant="body1" component="h3" mb={2}>
                  {item.name}
                </Typography>
                <ListItem sx={{ p: 0, flexBasis: '50%' }}>
                  <ListItemAvatar>
                    <Avatar sx={{ color: 'white' }}>
                      <EventIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('events.discountSalesEndsOn')}
                    secondary={item.date}
                    sx={{ my: 0 }}
                  />
                </ListItem>
              </Stack>
            ))}
          </List>
        </>
      ) : null}

      {wholeEventTickets?.length ? (
        <>
          <Divider sx={{ my: 3 }} />
          <Typography variant="h6" component="h2" className={classes.title} mb={3}>
            {t('events.wholeTournamentPass')}
          </Typography>
          <List sx={{ py: 0 }}>
            {wholeEventTickets.map((item) => (
              <Stack key={`SpectatorsWholePassDiscountList_${item.id}`} mb={2}>
                <Typography variant="body1" component="h3" mb={2}>
                  {item.name}
                </Typography>
                <ListItem sx={{ p: 0, flexBasis: '50%' }}>
                  <ListItemAvatar>
                    <Avatar sx={{ color: 'white' }}>
                      <EventIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('events.discountSalesEndsOn')}
                    secondary={item.date}
                    sx={{ my: 0 }}
                  />
                </ListItem>
              </Stack>
            ))}
          </List>
        </>
      ) : null}
    </PaperBlock>
  );
}

export default DatesWidget;
