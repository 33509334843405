import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { SingleInputTimeRangeField } from '@mui/x-date-pickers-pro/SingleInputTimeRangeField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import CircularProgress from '@mui/material/CircularProgress';

import TextMask from '../../components/TextMask/TextMask';
import { DEFAULT_DATE_FORMAT, ScheduleTypes } from '../../constants/general';
import type { ITimeFrameView } from './interfaces/ITimeFrameView';

/**
 * Time frame page view
 */
function TimeFrameView({
  type,
  control,
  timeFrames,
  maxDate,
  minDate,
  onCreateSubmit,
  onAppendTimeFrame,
  onRemoveTimeFrame,
  onBackClick,
  onValidateDate,
  isSubmitting,
  isLoading,
}: ITimeFrameView) {
  const { t } = useTranslation();

  return (
    <>
      <Paper
        sx={{
          px: 8, py: 5, mt: 4, mb: 3,
        }}
        elevation={0}
      >
        <Typography component="h2" variant="h4" mb={4}>
          {type === ScheduleTypes.POOL_PLAY ? t('schedule.timeFramePoolPlayTitle') : t('schedule.timeFramePlayOffTitle')}
        </Typography>

        {isLoading ? (
          <Stack alignItems="center" my={8}>
            <CircularProgress size={45} thickness={1} color="secondary" />
          </Stack>
        ) : (
          <>
            <Typography component="h4" mb={2} fontSize={20} fontWeight={500}>
              {t('schedule.timeSlotsAvailableForSchedule')}
            </Typography>

            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <Grid container spacing={3}>
                {timeFrames.map((item, index) => (
                  <Grid key={item.id} container item spacing={3}>
                    <Grid item xs={5.5}>
                      <Controller
                        name={`timeFrames.${index}.date`}
                        control={control}
                        render={({
                          field: { value = null, onChange, onBlur },
                          fieldState: { error: fieldError },
                        }) => (
                          <DatePicker
                            label={t('common.date')}
                            value={value}
                            onClose={onBlur}
                            onChange={(date) => {
                              onChange(date);
                              onValidateDate(index);
                            }}
                            slotProps={{
                              textField: {
                                error: !!fieldError,
                                helperText: fieldError?.message,
                                fullWidth: true,
                                onBlur,
                              },
                            }}
                            format={DEFAULT_DATE_FORMAT}
                            maxDate={maxDate}
                            minDate={minDate}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={5.5}>
                      <Controller
                        name={`timeFrames.${index}.time`}
                        control={control}
                        render={({
                          field,
                          fieldState: { error: fieldError },
                        }) => (
                          <SingleInputTimeRangeField
                            label={t('common.startTime')}
                            slotProps={{
                              textField: {
                                error: !!fieldError,
                                helperText: fieldError?.message,
                                fullWidth: true,
                              },
                            }}
                            {...field}
                            value={field.value || undefined}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={1} alignSelf="center">
                      {index > 0 ? (
                        <IconButton onClick={() => onRemoveTimeFrame(index)}>
                          <CloseIcon />
                        </IconButton>
                      ) : null}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </LocalizationProvider>

            <Button
              variant="outlined"
              size="small"
              onClick={() => onAppendTimeFrame({
                frameId: null,
                date: null,
                time: null,
              })}
              sx={{ mt: 3 }}
            >
              {t('common.addDate')}
            </Button>

            <Divider sx={{ my: 4 }} />

            <Typography component="h4" mb={2} fontSize={20} fontWeight={500}>
              {t('schedule.timeBetweenGames')}
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={5.5}>
                <Controller
                  name="timeBetweenGames"
                  control={control}
                  render={({
                    field: { value = '', ...field },
                    fieldState: { error: fieldError },
                  }) => (
                    <TextField
                      value={value.toString()}
                      error={!!fieldError}
                      helperText={fieldError?.message}
                      label={t('schedule.timeBetweenGames')}
                      variant="outlined"
                      placeholder={`${t('schedule.timeBetweenGamesPlaceholder')}`}
                      InputProps={{
                        inputComponent: TextMask as any,
                        inputProps: { mask: Number, min: 0 },
                      }}
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Stack direction="row" alignItems="center" spacing={1} mt={4}>
              <InfoIcon />
              <Typography fontSize={14}>
                {t('schedule.timeFrameAlert')}
              </Typography>
            </Stack>
          </>
        )}
      </Paper>

      <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
        <Button
          variant="outlined"
          onClick={onBackClick}
          sx={{ width: 140 }}
        >
          {t('common.back')}
        </Button>
        <Button
          variant="contained"
          onClick={onCreateSubmit}
          sx={{ width: 140 }}
          disabled={isSubmitting}
        >
          {t('common.next')}
        </Button>
      </Stack>
    </>
  );
}

export default TimeFrameView;
