import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import SettingsSection from './SettingsSection';
import MessageSection from './MessageSection';
import SendDialog from './SendDialog';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/useApp';
import selectIsSubmitDialogOpen from '../../../store/slices/notifications/selectors';
import { toggleSubmitDialog } from '../../../store/slices/notifications/slice';
import type INotificationForm from '../interfaces/INotificationForm';

function NotificationForm({
  control,
  onSelectRecipient,
  onValidateRecipient,
  onSelectType,
  onSubmit,
  isSubmitting,
}: INotificationForm) {
  const { t } = useTranslation();
  const open = useAppSelector(selectIsSubmitDialogOpen);
  const dispatch = useAppDispatch();

  const messageValues = useWatch({ control, name: ['title', 'message'] });

  const isDisabled = messageValues[0] && messageValues[1];

  return (
    <Paper sx={{ px: 3, py: 5 }} elevation={0}>
      <Typography variant="h4" component="h1" mb={4.25}>
        {t('notifications.sendNotification')}
      </Typography>
      <Stack spacing={3}>
        <SettingsSection
          control={control}
          onSelectRecipient={onSelectRecipient}
          onValidateRecipient={onValidateRecipient}
          onSelectType={onSelectType}
        />
        <MessageSection
          control={control}
        />
      </Stack>
      <Stack alignItems="flex-end" mt={4.25}>
        <Button
          variant="contained"
          disabled={!isDisabled}
          onClick={() => dispatch(toggleSubmitDialog(true))}
        >
          {t('notifications.submitNotification')}
        </Button>
      </Stack>
      <SendDialog
        control={control}
        open={open}
        onConfirm={onSubmit}
        onClose={() => dispatch(toggleSubmitDialog(false))}
        isSubmitting={isSubmitting}
      />
    </Paper>
  );
}

export default NotificationForm;
