export default {
  qrCodeTitle: 'QR-Code',
  downloadPng: 'Download in .png',
  downloadSvg: 'Download in .svg',
  paymentMethodTitle: 'Connect Stripe',
  paymentMethodDescription: 'Stripe must be connected and activated in order to receive payment.',
  paymentMethodInstructions: 'Detailed instructions',
  paymentMethodConnect: 'Connect with Stripe',
  paymentMethodRequired: 'Stripe activation required',
  paymentMethodConnected: 'Stripe account connected',
  paymentMethodDisconnect: 'Disconnect stripe account',
  paymentHintFirst: 'Click "Connect with Stripe."',
  paymentHintSecond: 'Set up a Stripe account through your Admin Console.',
  paymentHintThird: 'Ensure you provide all (required and optional) information in Stripe.',
  paymentHintFourth: 'Once you have provided the necessary information to your Stripe account, it will be connected.',
  firstSubtitle: '(redirect to the Organization page on the GRIT website)',
  secondSubtitle: '(redirect to the Organization page on the GRIT app)',
  fileNameWebsite: '{{name}} website',
  fileNameApp: '{{name}} application',
};
