import ButtonBase from '@mui/material/ButtonBase';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';

import OrgLogo from '../../../components/OrgLogo/OrgLogo';
import LogoutDialog from './LogoutDialog';
import useMenu from '../../../hooks/useMenu';
import type IOrgSwitcher from '../interfaces/IOrgSwitcher';

function OrgSwitcher({
  selected, list, onSwitch, onLogout,
}: IOrgSwitcher) {
  const {
    anchorEl,
    menuId,
    open,
    handleClick,
    handleClose,
  } = useMenu();

  return (
    <Paper elevation={0}>
      <ButtonBase
        id={menuId}
        aria-controls={open ? `${menuId}-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          display: 'flex', alignItems: 'center', gap: 1, p: 1.25, borderRadius: 2,
        }}
      >
        <OrgLogo logo={selected?.profilePhoto} />
        <span>{selected?.name}</span>
        <ArrowDropDownIcon />
      </ButtonBase>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': menuId,
        }}
        elevation={8}
        anchorOrigin={{
          vertical: anchorEl?.clientHeight ? anchorEl.clientHeight + 8 : 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{ paper: { sx: { minWidth: 210 } } }}
      >
        {list.map((item) => (
          <MenuList key={`HeaderOrgSwitcher_${item.id}`} dense disablePadding>
            <MenuItem
              onClick={() => {
                handleClose();
                onSwitch(item);
              }}
              selected={selected.id === item.id}
            >
              <ListItemIcon sx={{ minWidth: 24 }}>
                <OrgLogo logo={item?.profilePhoto} />
              </ListItemIcon>
              <ListItemText>{item.name}</ListItemText>
              {item.id === selected.id ? <CheckCircleIcon color="primary" sx={{ ml: 1 }} /> : null}
            </MenuItem>
            <Divider sx={{ mx: 1 }} />
          </MenuList>
        ))}
        <LogoutDialog onLogout={onLogout} />
      </Menu>
    </Paper>
  );
}

export default OrgSwitcher;
