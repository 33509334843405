import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import useMenu from '../../hooks/useMenu';
import type ITableActions from './interfaces/ITableActions';

/**
 * UI component for displaying a table row menu of actions.
 */
function TableRowActions({ actions, status }: ITableActions) {
  const {
    anchorEl,
    menuId,
    open,
    handleClick,
    handleClose,
  } = useMenu();

  return (
    <>
      <IconButton
        id={menuId}
        color="primary"
        aria-controls={open ? `${menuId}-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': menuId,
        }}
        elevation={2}
      >
        {actions.map(({
          label, onClick, disabled, statuses,
        }) => (
          statuses.includes(status) && (
          <MenuItem
            key={`TableRowActions_${label}`}
            onClick={() => {
              handleClose();
              onClick();
            }}
            disabled={disabled}
          >
            {label}
          </MenuItem>
          )
        ))}
      </Menu>
    </>
  );
}

export default TableRowActions;
