import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

import PlayingFieldsTable from './PlayingFieldsTable';
import type IPlayingFieldsTab from '../interfaces/IPlayingFieldsTab';

function PlayingFieldsTab({ type, playingFields, fullInfoId }: IPlayingFieldsTab) {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h6">{t('schedule.fields')}</Typography>
      <PlayingFieldsTable fullInfoId={fullInfoId} type={type} playingFields={playingFields} />
    </>
  );
}

export default PlayingFieldsTab;
