import type IEventPlayingField from './IEventPlayingField';
import type IGame7x7Settings from './IGame7x7Settings';

export enum TeamTypes {
  REAL = 'REAL',
  GHOST = 'GHOST',
}

export interface IPPlaysDivRules {
  id: number
  gamesPerTeam: number
  game7x7Settings: IGame7x7Settings
  preferredPlayingFields: IEventPlayingField[]
}

export interface IPoolPlayTeam {
  id: number
  clubId: number
  name: string
  teamType: TeamTypes
  profilePhoto?: string
}

export interface IDistributionTeamInPool {
  gamesPerTeam: number
  teamsInPool: number
  countPools: number
  type: TeamTypes
}

export interface IGamePoolTeam extends IPoolPlayTeam {
  position: number
  teamIdForReplace?: number
}

export interface IGamePool {
  id: number
  name: string
  teams: IGamePoolTeam[]
}

export default interface IPoolPlayDivisionRules {
  pPlaysDivRules: IPPlaysDivRules | null
  gamesPools: IGamePool[]
  distributionTeamsToPools: {
    withGhost: IDistributionTeamInPool[]
    withoutGhost: IDistributionTeamInPool[]
  },
  teams: IPoolPlayTeam[],
  poolNextFreeName: string
}
