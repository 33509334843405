import { useNavigate } from 'react-router-dom';

import SingleEventDivisionsView from './SingleEventDivisionsView';
import useEventDivisionsForm from '../../hooks/useEventDivisionsForm';
import { DEFAULT_DATE_FORMAT, EVENT_FORM_TYPES } from '../../constants/general';

function SingleEventDivisions(): React.ReactElement {
  const navigate = useNavigate();
  const {
    event,
    control,
    divisions,
    handlePrecreateDivisions,
    handleRemoveDivision,
    handleRemoveDiscount,
    handleAddOneDivision,
    handleAppendDiscount,
    handleCreateSubmit,
    handleCancel,
    isSubmitting,
    handlePrecreateDivisionName,
  } = useEventDivisionsForm({
    navigate: () => navigate(-1),
    onCancel: () => navigate(-1),
    type: EVENT_FORM_TYPES.EDIT,
  });

  return (
    <SingleEventDivisionsView
      eventName={event.nameWithTagline}
      control={control}
      divisions={divisions}
      eventStartDate={event.startAt?.toFormat(DEFAULT_DATE_FORMAT) || '-'}
      eventEndDate={event.endAt?.toFormat(DEFAULT_DATE_FORMAT) || '-'}
      maxRegistrationDate={event.endAt || null}
      maxDiscountDate={event.endAt}
      onRemoveDivision={handleRemoveDivision}
      onRemoveDiscount={handleRemoveDiscount}
      onCreateSubmit={handleCreateSubmit}
      onPrecreateDivisions={handlePrecreateDivisions}
      onAddOneDivision={handleAddOneDivision}
      onAppendDiscount={handleAppendDiscount}
      onCancelEdit={handleCancel}
      isSubmitting={isSubmitting}
      isPublished={event.published}
      onPrecreateDevisionName={handlePrecreateDivisionName}
    />
  );
}

export default SingleEventDivisions;
