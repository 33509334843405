import AppRoutes from './AppRoutes';

export const tabs = [
  {
    label: 'analytics',
    route: AppRoutes.singleEventAnalytics,
  },
  {
    label: 'details',
    route: AppRoutes.singleEvent,
  },
  {
    label: 'teams',
    route: AppRoutes.singleEventTeams,
  },
  {
    label: 'schedule',
    route: AppRoutes.singleEventSchedule,
  },
  {
    label: 'standings',
    route: AppRoutes.singleEventStandings,
  },
  {
    label: 'signatures',
    route: AppRoutes.singleEventSignatures,
  },
];

export const tabsFree = [
  {
    label: 'details',
    route: AppRoutes.singleEvent,
  },
  {
    label: 'teams',
    route: AppRoutes.singleEventTeams,
  },
  {
    label: 'schedule',
    route: AppRoutes.singleEventSchedule,
  },
  {
    label: 'signatures',
    route: AppRoutes.singleEventSignatures,
  },
];
