import { defaultDropAnimationSideEffects, DragOverlay } from '@dnd-kit/core';
import Box from '@mui/material/Box';
import type { PropsWithChildren } from 'react';
import type { DropAnimation } from '@dnd-kit/core';

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.4',
      },
    },
  }),
};

function PoolOverlay({ children }: PropsWithChildren) {
  return (
    <DragOverlay dropAnimation={dropAnimationConfig}>
      <Box sx={{ borderRadius: 2, border: '1px solid #02A9F4' }}>
        {children}
      </Box>
    </DragOverlay>
  );
}

export default PoolOverlay;
