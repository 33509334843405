import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

import TeamsFilterSelect from './TeamsFilterSelect';
import SearchInput from '../../../components/SearchInput/SearchInput';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import FiltersPopup from '../../../components/FiltersPopup/FiltersPopup';
import type { ITeamsFilters } from '../interfaces/ITeamsFilters';
import type { ISearchInputHandler } from '../../../components/SearchInput/interfaces/ISearchInput';

function TeamsFilters({
  control,
  onSearchChange,
  onApplyFilters,
  onResetFilters,
  onSearchClub,
  onSearchCoach,
  clubs,
  coaches,
}: ITeamsFilters) {
  const { t } = useTranslation();

  const debouncedClubSearch = useDebouncedCallback(onSearchClub);

  const handleClubsSearchChange = ({ search, onChange }: ISearchInputHandler) => {
    onChange(search);
    debouncedClubSearch();
  };

  const debouncedCoachesSearch = useDebouncedCallback(onSearchCoach);

  const handleCoachesSearchChange = ({ search, onChange }: ISearchInputHandler) => {
    onChange(search);
    debouncedCoachesSearch();
  };

  return (
    <Stack direction="row" spacing={3}>
      <Box sx={{ width: 276 }}>
        <SearchInput control={control} onSearchChange={onSearchChange} />
      </Box>
      <FiltersPopup
        title={t('events.filters')}
        onApplyFilters={onApplyFilters}
        onResetFilters={onResetFilters}
      >
        <Grid item xs={12}>
          <TeamsFilterSelect
            control={control}
            selectName="clubId"
            label={t('events.clubName')}
            searchName="clubSearch"
            onOpen={onSearchClub}
            onSearch={handleClubsSearchChange}
          >
            <MenuItem value="">
              <em>{t('events.none')}</em>
            </MenuItem>
            {clubs.map((club) => (
              <MenuItem key={club.id} value={club.id}>
                {club.name}
              </MenuItem>
            ))}
          </TeamsFilterSelect>
        </Grid>
        <Grid item xs={12}>
          <TeamsFilterSelect
            control={control}
            selectName="coachId"
            label={t('events.coach')}
            searchName="coachSearch"
            onOpen={onSearchCoach}
            onSearch={handleCoachesSearchChange}
          >
            <MenuItem value="">
              <em>{t('events.none')}</em>
            </MenuItem>
            {coaches.map((coach) => (
              <MenuItem key={coach.id} value={coach.id}>
                {`${coach.firstName} ${coach.lastName}`.trim()}
              </MenuItem>
            ))}
          </TeamsFilterSelect>
        </Grid>
      </FiltersPopup>
    </Stack>
  );
}

export default TeamsFilters;
