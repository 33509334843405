import { makeStyles } from 'tss-react/mui';
import { lighten } from '@mui/material';

import { AppThemeMode } from '../../constants/AppThemes';
import cover from '../../assets/images/loginCover.svg';

const useStyles = makeStyles()(
  (theme) => ({
    rootFull: {
      display: 'flex',
      width: '100%',
      zIndex: 1,
      position: 'relative',
      height: '100%',
    },
    containerSide: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      color: theme.palette.text.primary,
      [theme.breakpoints.down('lg')]: {
        overflow: 'hidden',
      },
    },
    opening: {
      width: '35%',
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#02A9F4',
      backgroundImage: `url(${cover})`,
      backgroundPosition: 'bottom',
      backgroundRepeatX: 'no-repeat',
      backgroundSize: 'contain',
      padding: theme.spacing(3),
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(5.5),
      },
    },
    sideFormWrap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      backgroundColor: theme.palette.mode === AppThemeMode.Dark
        ? '#001725'
        : lighten(theme.palette.primary.light, 0.9),
      width: '65%',
      zIndex: 1,
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    textFields: {
      '.MuiInputBase-root': {
        borderRadius: 8,
      },
    },
  }),
);

export default useStyles;
