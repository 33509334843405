import { DateTime, Interval } from 'luxon';
import type { DateInput } from 'luxon';
import type { stringOrDate } from 'react-big-calendar';

export const getInterval = (startDate: DateInput, endDate: DateInput) => Interval.fromDateTimes(
  startDate,
  endDate,
)
  .splitBy({ day: 1 })
  .map((date: Interval) => date.start?.toFormat('dd LLL yyyy') || '')
  .filter((date) => !!date);

export default getInterval;

export const findDuplicatedDates = (arr: DateTime[]) => {
  const indexMap = new Map();
  const duplicates: number[] = [];

  arr.forEach((item, index) => {
    if (indexMap.has(item.valueOf())) {
      duplicates.push(index, indexMap.get(item.valueOf()));
    }
    indexMap.set(item.valueOf(), index);
  });

  return duplicates;
};

export const findEqualDatesInArrays = (arrI: DateTime[], arrJ: DateTime[]) => {
  const firstArrDuplicates: number[] = [];
  const secondArrDuplicates: number[] = [];

  if (arrI && arrJ) {
    arrI.forEach((dateI, i) => {
      arrJ.forEach((dateJ, j) => {
        if (dateI.valueOf() === dateJ.valueOf()) {
          firstArrDuplicates.push(i);
          secondArrDuplicates.push(j);
        }
      });
    });
  }

  return {
    firstArrDuplicates,
    secondArrDuplicates,
  };
};

export const parseStringOrDateToDateTime = (date: stringOrDate) => {
  if (typeof date === 'string') {
    return DateTime.fromISO(date);
  }
  return DateTime.fromJSDate(date);
};

export const groupedByDate = <T>(data: T[] | undefined, key: keyof T, timezone?: string) => {
  if (!data) {
    return undefined;
  }

  return data?.reduce<Record<string, T[]>>((acc, item) => {
    const date = DateTime.fromISO(item[key] as string).setZone(timezone).toISO();
    const dateWithoutTime = date?.split('T')[0];

    if (dateWithoutTime) {
      acc[dateWithoutTime] = acc[dateWithoutTime] || [];
      acc[dateWithoutTime].push(item);
    }

    return acc;
  }, {});
};

export const getUniqueDates = <T extends any>(
  data: T[] | undefined,
  key: keyof T,
  timezone?: string,
) => {
  if (!data) {
    return [];
  }

  return Array
    .from(new Set(data.map((item) => DateTime.fromISO(`${item[key]}`).setZone(timezone).toISODate())))
    .filter((item): item is string => !!item)
    .sort();
};
