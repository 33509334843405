import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import type IEditButton from '../interfaces/IEditButton';

function EditButton({ setupCompleted, onEditClick, onSetupClick }: IEditButton) {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      gap={3}
      alignItems="center"
      justifyContent="flex-end"
    >
      {setupCompleted ? (
        <>
          <Chip
            icon={<CheckIcon color="success" />}
            label={t('common.set')}
            sx={{ textTransform: 'uppercase', fontSize: 14 }}
          />
          <Button onClick={onEditClick} variant="outlined">
            {t('common.edit')}
          </Button>
        </>
      ) : (
        <Button onClick={onSetupClick} variant="contained">
          {t('common.setup')}
        </Button>
      )}
    </Stack>
  );
}

export default EditButton;
