import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import EmptyData from '../../../components/EmptyData/EmptyData';
import type { IDevisionsRegistrationWidget } from '../interfaces/IDevisionsRegistrationWidget';

const useStyle = makeStyles()(
  (theme) => ({
    outer: {
      height: '6px',
      backgroundColor: '#757575',
    },
    inner: {
      height: 'inherit',
      backgroundColor: theme.palette.primary.main,
      transition: 'width 800ms 200ms ease',
    },
  }),
);

function DevisionsRegistrationWidget({
  teamsCapacity,
}: IDevisionsRegistrationWidget) {
  const { t } = useTranslation();
  const { classes } = useStyle();
  const [showBar, setShowBar] = useState<boolean>(false);

  useEffect(() => setShowBar(true), []);

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h5" lineHeight="24px" mb={3}>
        {t('events.teamRegistrationPerDevision')}
      </Typography>
      <Stack direction="column" spacing={3}>
        {teamsCapacity.length ? teamsCapacity.map((item) => (
          <Stack key={item.divisionName} spacing={1.5}>
            <Typography variant="body1" lineHeight="24px">
              {item.divisionName}
            </Typography>
            <Box className={classes.outer}>
              <Box
                className={classes.inner}
                sx={{
                  width: showBar ? `calc((100% * ${item.registeredTeam}) / ${item.ticketCount})` : 0,
                }}
              />
            </Box>
            <Typography variant="body2" lineHeight="18px" color="#757575">
              {t('events.registeredTeamsCount', {
                count: item.registeredTeam,
                total: item.ticketCount,
              })}
            </Typography>
          </Stack>
        )) : <EmptyData description={t('common.noData')} />}
      </Stack>
    </Paper>
  );
}

export default DevisionsRegistrationWidget;
