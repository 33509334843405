import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useWatch } from 'react-hook-form';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/Info';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import type { DateTime } from 'luxon';

import { DEFAULT_DATE_FORMAT, TIME_STEPS_FOR_TIME_PICKER } from '../../../constants/general';
import type ICreateEventContext from '../../../layout/CreateEventLayout/interfaces/ICreateEventContext';
import type IEventDatesSection from '../interfaces/IEventDatesSection';

function EventDatesSection({
  control,
  onCreateSubmit,
  onChangeWithRedirect,
}: IEventDatesSection): React.ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { eventId } = useOutletContext<ICreateEventContext>();
  const startAt = useWatch({ control, name: 'startAt' });
  const endAt = useWatch({ control, name: 'endAt' });
  const isPublished = useWatch({ control, name: 'isPublished' });

  const handleClick = () => {
    onChangeWithRedirect();
    onCreateSubmit();
    navigate(`/events/${eventId}/dates`);
  };

  return (
    <>
      <Typography component="h4" mb={2} fontSize={20} fontWeight={500}>
        {t('events.eventDates')}
      </Typography>
      {isPublished ? (
        <Alert
          icon={<InfoIcon sx={{ fontSize: 24 }} />}
          severity="info"
          variant="filled"
          sx={{ alignItems: 'center', mb: 3 }}
        >
          <Box>
            <Typography variant="body2" component="span">
              {t('events.eventDatesHint')}
            </Typography>
            <Typography
              component="span"
              variant="body2"
              onClick={handleClick}
              color="inherit"
              fontWeight={500}
              sx={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {t('events.eventDatesEditPage')}
            </Typography>
          </Box>
        </Alert>
      ) : null}
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 5 }}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="startAt"
              control={control}
              render={({
                field: { onBlur, ...props },
                fieldState: { error: fieldError },
              }) => (
                <DatePicker
                  label={t('events.startDate')}
                  maxDate={endAt}
                  onClose={onBlur}
                  disabled={isPublished}
                  slotProps={{
                    textField: {
                      error: !!fieldError,
                      helperText: fieldError?.message,
                      fullWidth: true,
                    },
                  }}
                  format={DEFAULT_DATE_FORMAT}
                  {...props}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="startAt"
              control={control}
              render={({
                field: { onBlur, ...props },
                fieldState: { error: fieldError },
              }) => (
                <TimePicker
                  label={`${t('events.startTime')} (${t('events.optional')})`}
                  maxTime={endAt}
                  onClose={onBlur}
                  timeSteps={TIME_STEPS_FOR_TIME_PICKER}
                  disabled={isPublished}
                  slotProps={{
                    textField: {
                      error: !!fieldError,
                      helperText: fieldError?.message,
                      fullWidth: true,
                    },
                  }}
                  {...props}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="endAt"
              control={control}
              render={({
                field: { onBlur, onChange, ...props },
                fieldState: { error: fieldError },

              }) => (
                <DatePicker
                  label={t('events.endDate')}
                  minDate={startAt}
                  onChange={(e) => {
                    const date = e as DateTime;
                    onChange(date?.set({ hour: 23, minute: 59 }));
                  }}
                  onClose={onBlur}
                  format={DEFAULT_DATE_FORMAT}
                  disabled={isPublished}
                  slotProps={{
                    textField: {
                      error: !!fieldError,
                      helperText: fieldError?.message,
                      fullWidth: true,
                    },
                  }}
                  {...props}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="endAt"
              control={control}
              render={({
                field: { onBlur, ...props },
                fieldState: { error: fieldError },
              }) => (
                <TimePicker
                  label={`${t('events.endTime')} (${t('events.optional')})`}
                  onClose={onBlur}
                  minTime={startAt}
                  timeSteps={TIME_STEPS_FOR_TIME_PICKER}
                  disabled={isPublished}
                  slotProps={{
                    textField: {
                      error: !!fieldError,
                      helperText: fieldError?.message,
                      fullWidth: true,
                    },
                  }}
                  {...props}
                />
              )}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>
    </>
  );
}

export default memo(EventDatesSection);
