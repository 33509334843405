import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import CountWidget from '../../../components/CountWidget/CountWidget';
import { toPriceFormat } from '../../../utils/helpers';
import type IRevenueDataSection from '../interfaces/IRevenueDataSection';

function RevenueDataSection({
  verificationsAmount,
  spectatorsAmount,
  teamsAmount,
  totalAmount,
}: IRevenueDataSection) {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} md={6} xl={3}>
        <CountWidget
          title={t('dashboard.totalRevenue')}
          value={toPriceFormat(totalAmount)}
        />
      </Grid>
      <Grid item xs={12} md={6} xl={3}>
        <CountWidget
          title={t('dashboard.teamsTicketsRevenue')}
          value={toPriceFormat(teamsAmount)}
        />
      </Grid>
      <Grid item xs={12} md={6} xl={3}>
        <CountWidget
          title={t('dashboard.spectatorsTicketsRevenue')}
          value={toPriceFormat(spectatorsAmount)}
        />
      </Grid>
      <Grid item xs={12} md={6} xl={3}>
        <CountWidget
          title={t('dashboard.verificationRevenue')}
          value={toPriceFormat(verificationsAmount)}
        />
      </Grid>
    </>
  );
}

export default RevenueDataSection;
