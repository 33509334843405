import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import ConnectStripeButton from './ConnectStripeButton';
import instructionsPdf from '../../../assets/instructions.pdf';
import type IConnectStripeBanner from '../interfaces/IConnectStripeBanner';

function ConnectStripeBanner({
  onConnect,
  onDisconnect,
  isShowDisconnect,
  status,
  stripeConnectUrl,
}: IConnectStripeBanner): React.ReactElement {
  const { t } = useTranslation();

  const paymentHintsList = [
    t('settings.paymentHintFirst'),
    t('settings.paymentHintSecond'),
    t('settings.paymentHintThird'),
    t('settings.paymentHintFourth'),
  ];

  return (
    <Paper
      sx={{ maxWidth: 880, px: 7.5, py: 5 }}
      elevation={0}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <Box>
          <Typography fontSize={34} variant="h1" mb={3}>
            {t('settings.paymentMethodTitle')}
          </Typography>
          <Typography variant="body2">
            {t('settings.paymentMethodDescription')}
          </Typography>
        </Box>
        <Button
          component="a"
          href={instructionsPdf}
          target="_blank"
          rel="noopener noreferrer"
          size="small"
          variant="text"
        >
          {t('settings.paymentMethodInstructions')}
        </Button>
      </Stack>
      <Stack direction="row" spacing={1.5}>
        <ConnectStripeButton
          size="small"
          variant="contained"
          conectionStatus={status}
          onClick={onConnect}
          href={stripeConnectUrl}
          sx={{
            color: '#fff',
            pointerEvents: isShowDisconnect ? 'none' : 'initial',
          }}
        />
        {isShowDisconnect ? (
          <Button
            size="small"
            variant="text"
            color="error"
            onClick={onDisconnect}
          >
            {t('settings.paymentMethodDisconnect')}
          </Button>
        ) : null}
      </Stack>
      <Stack component="ol" mt={3} mb={0} pl={3}>
        {paymentHintsList.map((hint) => (
          <Typography
            key={hint}
            variant="body2"
            component="li"
          >
            {hint}
          </Typography>
        ))}
      </Stack>
    </Paper>
  );
}

export default ConnectStripeBanner;
