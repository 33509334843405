import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import type IConfirmDialog from './interfaces/IConfirmDialog';

function ConfirmDialog({
  title,
  description,
  secondDescription,
  buttonText,
  open,
  onConfirm,
  onClose,
}: IConfirmDialog): React.ReactElement {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: 600,
          p: '40px 34px',
        },
      }}
    >
      <DialogTitle variant="h5" fontWeight="bold" sx={{ p: 'unset', mb: 2 }}>
        {title}
      </DialogTitle>
      <DialogContent sx={{ maxWidth: 475, p: 'unset' }}>
        <DialogContentText variant="body2">
          {description}
        </DialogContentText>
        {secondDescription ? (
          <DialogContentText variant="body2" mt={2}>
            {secondDescription}
          </DialogContentText>
        ) : null}
      </DialogContent>
      <DialogActions sx={{ p: 'unset', mt: 4.25 }}>
        <Button onClick={onClose} sx={{ color: 'text.disabled' }}>
          {t('common.cancel')}
        </Button>
        <Button variant="contained" onClick={onConfirm}>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
