import { useState } from 'react';
import { alpha } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ListItem from '@mui/material/ListItem';

import { AppThemeMode } from '../../../constants/AppThemes';
import type { ISignatureListItem } from '../interfaces/ISignatureListItem';

function SignatureListItem({
  teamPhoto, teamName, isEven, children,
}: ISignatureListItem): React.ReactElement {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <Divider component="li" sx={{ borderColor: 'primary.dark' }} />
      <ListItem
        disablePadding
        sx={(theme) => ({
          flexDirection: 'column',
          alignItems: 'stretch',
          ...(isEven && {
            background: theme.palette.mode === AppThemeMode.Dark
              ? alpha(theme.palette.grey[900], 0.5)
              : theme.palette.grey[50],
          }),
        })}
      >
        <ListItemButton onClick={handleClick}>
          <ListItemIcon sx={{ minWidth: 40 }}>
            <ArrowForwardIosIcon sx={{ rotate: open ? '90deg' : 0 }} />
          </ListItemIcon>
          <ListItemAvatar>
            <Avatar src={teamPhoto || undefined} />
          </ListItemAvatar>
          <ListItemText primary={teamName} />
        </ListItemButton>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
        >
          {children}
        </Collapse>
      </ListItem>
    </>
  );
}

export default SignatureListItem;
