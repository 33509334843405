import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import CreateEventTitle from '../CreateEventTitle/CreateEventTitle';
import EventPeriod from '../EventPeriod/EventPeriod';
import MainInfoSection from './components/MainInfoSection';
import TicketVariantShell from './components/TicketVariantShell';
import SpectatorsTicketVariant from './components/SpectatorsTicketVariant';
import { EVENT_FORM_TYPES } from '../../constants/general';
import type { ISpectatorsTicketsForm } from './interfaces/ISpectatorsTicketsForm';

function SpectatorsTicketsForm({
  title,
  type = EVENT_FORM_TYPES.CREATE,
  control,
  eventStartDate,
  eventEndDate,
  eventName,
  tournamentDaysInterval,
  maxSalesDate,
  onTournamentDaysClick,
}: ISpectatorsTicketsForm) {
  const { t } = useTranslation();

  const hintList = [
    t('events.editSpectatorFirstHint'),
    t('events.editSpectatorSecondHint'),
    t('events.editSpectatorThirdHint'),
    t('events.editSpectatorFourthHint'),
  ];

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Stack spacing={4}>
        <CreateEventTitle title={title} eventName={eventName} isOptional />
        {type === EVENT_FORM_TYPES.EDIT ? (
          <Alert
            icon={<InfoIcon sx={{ fontSize: 24 }} />}
            severity="info"
            variant="filled"
            sx={{ alignItems: 'center' }}
          >
            <Stack component="ul" m={0} pl={3}>
              {hintList.map((hint) => (
                <Box key={hint} component="li">
                  <Typography variant="body2">
                    {hint}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Alert>
        ) : null}
        <EventPeriod eventStartDate={eventStartDate} eventEndDate={eventEndDate} />
        <MainInfoSection
          control={control}
          tournamentDaysInterval={tournamentDaysInterval}
          maxSalesDate={maxSalesDate}
          formType={type}
          onTournamentDaysClick={onTournamentDaysClick}
        />
        <Divider />
        <TicketVariantShell control={control} label={t('events.oneDayPass')} name="isOneDayPass">
          <SpectatorsTicketVariant variant="oneDayPass" control={control} timezone={maxSalesDate?.zoneName} />
        </TicketVariantShell>
        <Divider />
        <TicketVariantShell control={control} label={t('events.wholeTournamentPass')} name="isWholeTournamentPass">
          <SpectatorsTicketVariant variant="wholeTournamentPass" control={control} timezone={maxSalesDate?.zoneName} />
        </TicketVariantShell>
      </Stack>
    </LocalizationProvider>
  );
}

export default SpectatorsTicketsForm;
