import mainApi from '../../../apis/mainApi';
import {
  ORG_EVENT_SPECTATOR,
  ORG_EVENT_SPECTATOR_DAY_RULE,
  ORG_EVENT_SPECTATOR_DAY_RULE_ID,
  ORG_EVENT_SPECTATOR_DAY_RULES,
  ORG_EVENT_SPECTATOR_TYPE_RULE,
  ORG_EVENT_SPECTATOR_TYPE_RULE_ID,
  ORG_EVENT_SPECTATOR_TYPE_RULE_ID_DISCOUNT,
} from '../../../../constants/requestUrls';
import { EVENT_SPECTATORS_RULES_TAG } from '../../../tags/selectors';
import { CENTS_PER_DOLLAR } from '../../../../constants/general';
import { EVENT_DAYS_TAG, EVENT_TAG } from '../../../tags/events';
import type IBaseQueryResponse from '../../../types/IBaseQueryResponse';
import type { IOneDayTicketRules, ISpectatorRule } from '../interfaces/ISpectatorRule';
import type { ISpectatorRuleReq, ITicketType } from '../interfaces/ISpectatorRuleReq';
import type { IBaseQueryRequest } from '../../../types/IBaseQueryRequest';
import type { IUpdateSpectatorRuleReq } from '../interfaces/IUpdateSpectatorRuleReq';
import type IResponseMeta from '../../../types/IResponseMeta';

const spectatorsApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    createSpectatorRule: builder.mutation<IBaseQueryResponse<ISpectatorRule>, ISpectatorRuleReq>({
      query: ({
        orgId,
        eventId,
        ...rest
      }) => ({
        url: ORG_EVENT_SPECTATOR
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'POST',
        body: { ...rest },
      }),
    }),

    updateSpectatorRule: builder.mutation<
    IBaseQueryResponse<ISpectatorRule>,
    IUpdateSpectatorRuleReq
    >({
      query: ({
        orgId,
        eventId,
        ...rest
      }) => ({
        url: ORG_EVENT_SPECTATOR
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'PATCH',
        body: { ...rest },
      }),
      invalidatesTags: [EVENT_SPECTATORS_RULES_TAG],
    }),

    createSpectatorTypeRule: builder.mutation<
    IBaseQueryResponse<ISpectatorRule>,
    IBaseQueryRequest & Partial<ITicketType>
    >({
      query: ({
        orgId,
        eventId,
        ...rest
      }) => ({
        url: ORG_EVENT_SPECTATOR_TYPE_RULE
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'POST',
        body: { ...rest },
      }),
      invalidatesTags: [EVENT_SPECTATORS_RULES_TAG],
    }),

    updateSpectatorTypeRule: builder.mutation<
    IBaseQueryResponse<ISpectatorRule>,
    IBaseQueryRequest & { ruleId: number } & Partial<Pick<ITicketType, 'price' | 'ageRange'>>
    >({
      query: ({
        orgId,
        eventId,
        ruleId,
        ...rest
      }) => ({
        url: ORG_EVENT_SPECTATOR_TYPE_RULE_ID
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{ruleId}', `${ruleId}`),
        method: 'PATCH',
        body: { ...rest },
      }),
      invalidatesTags: [EVENT_SPECTATORS_RULES_TAG],
    }),

    deleteSpectatorTypeRule: builder.mutation<
    IBaseQueryResponse<ISpectatorRule>,
    IBaseQueryRequest & { ruleId: number }
    >({
      query: ({
        orgId,
        eventId,
        ruleId,
      }) => ({
        url: ORG_EVENT_SPECTATOR_TYPE_RULE_ID
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{ruleId}', `${ruleId}`),
        method: 'DELETE',
      }),
      invalidatesTags: [EVENT_SPECTATORS_RULES_TAG],
    }),

    createSpectatorTypeRuleDiscount: builder.mutation<
    IBaseQueryResponse<ISpectatorRule>,
    IBaseQueryRequest & { ruleId: number, price: number, endAt: string | null }
    >({
      query: ({
        orgId,
        eventId,
        ruleId,
        ...rest
      }) => ({
        url: ORG_EVENT_SPECTATOR_TYPE_RULE_ID_DISCOUNT
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{ruleId}', `${ruleId}`),
        method: 'POST',
        body: { ...rest },
      }),
      invalidatesTags: [EVENT_SPECTATORS_RULES_TAG, EVENT_DAYS_TAG],
    }),

    updateSpectatorTypeRuleDiscount: builder.mutation<
    IBaseQueryResponse<ISpectatorRule>,
    IBaseQueryRequest & { ruleId: number, price: number, endAt: string | null }
    >({
      query: ({
        orgId,
        eventId,
        ruleId,
        ...rest
      }) => ({
        url: ORG_EVENT_SPECTATOR_TYPE_RULE_ID_DISCOUNT
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{ruleId}', `${ruleId}`),
        method: 'PATCH',
        body: { ...rest },
      }),
      invalidatesTags: [EVENT_SPECTATORS_RULES_TAG, EVENT_DAYS_TAG],
    }),

    deleteSpectatorTypeRuleDiscount: builder.mutation<
    IBaseQueryResponse<ISpectatorRule>,
    IBaseQueryRequest & { ruleId: number }
    >({
      query: ({
        orgId,
        eventId,
        ruleId,
      }) => ({
        url: ORG_EVENT_SPECTATOR_TYPE_RULE_ID_DISCOUNT
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{ruleId}', `${ruleId}`),
        method: 'DELETE',
      }),
      invalidatesTags: [EVENT_SPECTATORS_RULES_TAG, EVENT_DAYS_TAG],
    }),

    createSpectatorDayRules: builder.mutation<
    IBaseQueryResponse<ISpectatorRule>,
    IBaseQueryRequest & { accessToDay: string }
    >({
      query: ({
        orgId,
        eventId,
        accessToDay,
      }) => ({
        url: ORG_EVENT_SPECTATOR_DAY_RULE
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'POST',
        body: { accessToDay },
      }),
      invalidatesTags: (result, error, { eventId }) => [
        { type: EVENT_TAG, id: eventId },
        EVENT_SPECTATORS_RULES_TAG,
      ],
    }),

    updateSpectatorDayRules: builder.mutation<
    IBaseQueryResponse<ISpectatorRule>,
    IBaseQueryRequest & { spOneDayRules: IOneDayTicketRules[] }
    >({
      query: ({
        orgId,
        eventId,
        ...rest
      }) => ({
        url: ORG_EVENT_SPECTATOR_DAY_RULES
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'PATCH',
        body: { ...rest },
      }),
      invalidatesTags: (result, error, { eventId }) => [
        { type: EVENT_TAG, id: eventId },
        EVENT_SPECTATORS_RULES_TAG,
      ],
    }),

    deleteSpectatorDayRule: builder.mutation<
    IBaseQueryResponse<ISpectatorRule>,
    IBaseQueryRequest & { dayRuleId: number }
    >({
      query: ({
        orgId,
        eventId,
        dayRuleId,
      }) => ({
        url: ORG_EVENT_SPECTATOR_DAY_RULE_ID
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{dayRuleId}', `${dayRuleId}`),
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { eventId }) => [
        { type: EVENT_TAG, id: eventId },
        EVENT_SPECTATORS_RULES_TAG,
      ],
    }),

    getSpectatorRule: builder.query<
    IBaseQueryResponse<ISpectatorRule>,
    IBaseQueryRequest & { eventId: number }
    >({
      query: ({
        orgId,
        eventId,
      }) => ({
        url: ORG_EVENT_SPECTATOR
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
      }),
      transformResponse: (data: IBaseQueryResponse<ISpectatorRule>, meta: IResponseMeta) => {
        let timezone;
        if (meta?.response?.headers?.get) {
          timezone = meta.response.headers.get('X-Event-Tz');
        }

        return ({
          ...data,
          data: {
            ...data.data,
            ticketTypeRules: data.data?.ticketTypeRules?.map((item) => ({
              ...item,
              price: item.price / CENTS_PER_DOLLAR,
              discount: item.discount ? { ...item.discount, price: item.discount.price / CENTS_PER_DOLLAR } : null,
            })) || [],
            timezone,
          },
        });
      },
      providesTags: [EVENT_SPECTATORS_RULES_TAG],
    }),
  }),
});

export const {
  useCreateSpectatorRuleMutation,
  useUpdateSpectatorRuleMutation,
  useCreateSpectatorTypeRuleMutation,
  useUpdateSpectatorTypeRuleMutation,
  useDeleteSpectatorTypeRuleMutation,
  useCreateSpectatorTypeRuleDiscountMutation,
  useUpdateSpectatorTypeRuleDiscountMutation,
  useDeleteSpectatorTypeRuleDiscountMutation,
  useCreateSpectatorDayRulesMutation,
  useUpdateSpectatorDayRulesMutation,
  useDeleteSpectatorDayRuleMutation,
  useGetSpectatorRuleQuery,
} = spectatorsApi;

export default spectatorsApi;
