import { Controller } from 'react-hook-form';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';

import type ITableSort from './interfaces/ITableSort';
import { ORDERS } from '../../constants/general';

/**
 * UI component for displaying table head cell with sort.
 * Use react-hook-form control for state sort value.
 */
function TableSort({
  label, property, disablePadding, control, onSortChange,
}: ITableSort) {
  return (
    <Controller
      name="sort"
      control={control}
      render={({
        field: { onChange, value },
      }) => (
        <TableCell
          key={property}
          padding={disablePadding ? undefined : 'normal'}
          sortDirection={value.orderBy === property ? value.order : false}
        >
          <TableSortLabel
            active={value.orderBy === property}
            direction={value.orderBy === property ? value.order : ORDERS.ASC}
            onClick={() => onSortChange({ property, onChange })}
          >
            {label}
          </TableSortLabel>
        </TableCell>
      )}
    />
  );
}

export default TableSort;
