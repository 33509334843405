import mainApi from '../../../apis/mainApi';
import { EVENT_WAIVERS_LIST_TAG } from '../../../tags/waivers';
import {
  ORG_EVENTS_WAIVER,
  ORG_EVENTS_WAIVER_MUTATION,
  ORG_EVENTS_WAIVER_AGREEMENT,
  ORG_EVENTS_WAIVER_ID,
  ORG_EVENTS_WAIVER_AGREEMENT_TEAMS,
  ORG_EVENTS_WAIVER_AGREEMENT_WAIVER_LIST,
} from '../../../../constants/requestUrls';
import { SIGNATURES_ORDER } from '../../../../constants/general';
import type IBaseQueryResWithPagination from '../../../types/IBaseQueryResWithPagination';
import type { IBaseQueryReqWithParams } from '../../../types/IBaseQueryReqWithParams';
import type { IWaiver } from '../interfaces/IWaiver';
import type { IWaiverWithAgreements } from '../interfaces/IWaiverWithAgreements';
import type IBaseQueryResponse from '../../../types/IBaseQueryResponse';
import type { IBaseQueryRequest } from '../../../types/IBaseQueryRequest';
import type IFiltersWaiversListRequest from '../interfaces/IFiltersWaiversListRequest';
import type IFilterTeam from '../interfaces/IFilterTeam';
import type IGetEventWaiversWithAgreementRequest from '../interfaces/IGetEventWaiversWithAgreementRequest';

const waiversApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    createEventWaiver: builder.mutation<
    IBaseQueryResponse<IWaiver>,
    { orgId: number, eventId: number, title: string, file: File }
    >({
      query: ({
        orgId, eventId, title, file,
      }) => {
        const form = new FormData();
        form.append('file', file, file.name);
        form.append('title', title);

        return ({
          url: ORG_EVENTS_WAIVER_MUTATION
            .replace('{organizationId}', `${orgId}`)
            .replace('{eventId}', `${eventId}`),
          method: 'POST',
          body: form,
        });
      },
      invalidatesTags: [EVENT_WAIVERS_LIST_TAG],
    }),

    updateEventWaiver: builder.mutation<
    IBaseQueryResponse<IWaiver>,
    { orgId: number, eventId: number, waiverId: number, title: string }
    >({
      query: ({
        orgId, eventId, waiverId, title,
      }) => ({
        url: ORG_EVENTS_WAIVER_ID
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{id}', `${waiverId}`),
        method: 'PATCH',
        body: { title },
      }),
      invalidatesTags: (result, error, { waiverId }) => [{ type: EVENT_WAIVERS_LIST_TAG, id: waiverId }],
    }),

    deleteWaiverByEvent: builder.mutation<IBaseQueryResponse<{}>, IBaseQueryRequest & { waiverId: number }>({
      query: ({ orgId, eventId, waiverId }) => ({
        url: ORG_EVENTS_WAIVER_ID
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{id}', `${waiverId}`),
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { waiverId }) => [{ type: EVENT_WAIVERS_LIST_TAG, id: waiverId }],
    }),

    getEventWaivers: builder.query<IBaseQueryResWithPagination<IWaiver[]>, { orgId: number, eventId: number }>({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENTS_WAIVER
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
        params: {
          page: 1,
          perPage: 10,
        },
      }),
      transformResponse: (data: IBaseQueryResWithPagination<IWaiver[]>) => ({
        ...data,
        data: data.data.map((item) => ({
          ...item,
          link: `${item.waiver}`,
        })),
      }),
      providesTags: (result) => (
        result
          ? [...result.data.map(({ id }) => ({ type: EVENT_WAIVERS_LIST_TAG, id })), EVENT_WAIVERS_LIST_TAG]
          : [EVENT_WAIVERS_LIST_TAG]
      ),
    }),

    getEventWaiversWithAgreement: builder.query<
    IBaseQueryResWithPagination<IWaiverWithAgreements[]>,
    IGetEventWaiversWithAgreementRequest
    >({
      query: ({ orgId, eventId, ...rest }) => ({
        url: ORG_EVENTS_WAIVER_AGREEMENT
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
        params: { ...rest },
      }),
      transformResponse: (data: IBaseQueryResWithPagination<IWaiverWithAgreements[]>) => ({
        ...data,
        data: data.data.map((item) => ({
          ...item,
          players: item.players.sort((a, b) => a.firstName.localeCompare(b.firstName)).map((player) => ({
            ...player,
            waivers: player.waivers
              .sort((a, b) => SIGNATURES_ORDER[a.status] - SIGNATURES_ORDER[b.status])
              .map((waiver) => ({
                ...waiver,
                waiver: waiver.waiver,
                agreement: waiver.agreement ? {
                  ...waiver.agreement,
                  agreementFileName: waiver.agreement.agreementFileName,
                } : null,
              })),
          })),
        })),
      }),
    }),

    getFiltersTeamsList: builder.query<
    IBaseQueryResWithPagination<IFilterTeam[]>,
    IBaseQueryReqWithParams
    >({
      query: ({ orgId, eventId, ...rest }) => ({
        url: ORG_EVENTS_WAIVER_AGREEMENT_TEAMS
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
        params: { ...rest },
      }),
      transformResponse: (data: IBaseQueryResWithPagination<IFilterTeam[]>) => ({
        ...data,
        data: data.data.sort((a, b) => a.name.localeCompare(b.name)),
      }),
    }),

    getFiltersWaiversList: builder.query<
    IBaseQueryResWithPagination<Pick<IWaiver, 'id' | 'title'>[]>,
    IFiltersWaiversListRequest
    >({
      query: ({ orgId, eventId, ...rest }) => ({
        url: ORG_EVENTS_WAIVER_AGREEMENT_WAIVER_LIST
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
        params: { ...rest },
      }),
      transformResponse: (data: IBaseQueryResWithPagination<Pick<IWaiver, 'id' | 'title'>[]>) => ({
        ...data,
        data: data.data.sort((a, b) => a.title.localeCompare(b.title)),
      }),
    }),
  }),
});

export const {
  useGetEventWaiversQuery,
  useCreateEventWaiverMutation,
  useUpdateEventWaiverMutation,
  useGetEventWaiversWithAgreementQuery,
  useDeleteWaiverByEventMutation,
  useLazyGetFiltersTeamsListQuery,
  useLazyGetFiltersWaiversListQuery,
} = waiversApi;

export default waiversApi;
