import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import ScheduleTabs from '../PlayOffSchedule/components/ScheduleTabs';
import PoolPlayTab from './components/PoolPlayTab';
import PlayOffTab from './components/PlayOffTab';
import a11yProps from '../../utils/a11yProps';
import { ScheduleTypes } from '../../constants/general';
import type ISingleEventScheduleView from './interfaces/ISingleEventScheduleView';

/**
 * Single Event Schedule page view
 */
function SingleEventScheduleView({
  scheduleView,
  tabValue,
  noGamesData,
  onTabChange,
  poolPlayFullInfo,
  onViewChange,
}: ISingleEventScheduleView): React.ReactElement {
  const { t } = useTranslation();

  return (
    <Stack gap={3}>
      {poolPlayFullInfo?.published ? (
        <Paper sx={{ p: 3 }} elevation={0}>
          <Tabs
            component={Paper}
            value={tabValue}
            onChange={onTabChange}
            variant="fullWidth"
            scrollButtons="auto"
            sx={{ mb: 2, borderRadius: 0, bgcolor: 'background.secondary' }}
            elevation={0}
          >
            {Object.values(ScheduleTypes).map((tab, index) => (
              <Tab
                value={tab}
                key={`SingleEventScheduleTab_${tab}`}
                label={t(`schedule.${tab}`)}
                {...a11yProps('tab', 'tabpanel', index)}
              />
            ))}
          </Tabs>
          {!noGamesData.isShow ? (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                gap={2}
                alignItems="center"
                mb={3}
              >
                {tabValue === ScheduleTypes.POOL_PLAY ? (
                  <Typography variant="h6" fontWeight={700}>
                    {t('schedule.poolPlaySchedule')}
                  </Typography>
                ) : (
                  <>
                    <Typography variant="h6" fontWeight={700}>
                      {t('schedule.playOffSchedule')}
                    </Typography>
                    <ScheduleTabs view={scheduleView} onChange={onViewChange} />
                  </>
                )}
              </Stack>
              {tabValue === ScheduleTypes.POOL_PLAY
                ? <PoolPlayTab />
                : <PlayOffTab view={scheduleView} />}
            </>
          ) : null}
        </Paper>
      ) : null}
      {noGamesData.isShow ? (
        <Paper sx={{ p: 3 }}>
          <Stack gap={3} alignItems="center">
            <CalendarTodayIcon sx={{ width: 48, height: 48 }} color="disabled" />
            <Typography variant="h6">
              {t('schedule.noGamesScheduledYet')}
            </Typography>
            <Button
              component={NavLink}
              to={noGamesData.link}
              variant="contained"
            >
              {t(`schedule.${noGamesData.text}`)}
            </Button>
          </Stack>
        </Paper>
      ) : null}
    </Stack>
  );
}

export default SingleEventScheduleView;
