import mainApi from '../../../apis/mainApi';
import AUTHENTICATED_USER from '../../../tags/auth';
import { AUTH_SIGN_IN } from '../../../../constants/requestUrls';
import type IBaseQueryResponse from '../../../types/IBaseQueryResponse';
import type ILoginRequest from '../interfaces/ILoginRequest';
import type ITokens from '../interfaces/ITokens';

const authApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<IBaseQueryResponse<ITokens>, ILoginRequest>({
      invalidatesTags: [AUTHENTICATED_USER],
      query: ({ email, ...rest }) => ({
        url: AUTH_SIGN_IN,
        method: 'POST',
        body: { email: email.trim(), ...rest },
      }),
    }),
  }),
});

export const { useLoginMutation } = authApi;

export default authApi;
