import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import type ITableRowsLoader from './interfaces/ITableRowsLoader';

function TableRowsLoader({ rowsNum, columnsNum }: ITableRowsLoader): React.ReactElement {
  return (
    <>
      {[...Array(rowsNum)].map((row, rowIndex) => {
        const rowKey = `TableRowsLoader_row_${rowIndex.toString()}`;

        return (
          <TableRow key={rowKey}>
            {[...Array(columnsNum)].map((column, columnIndex) => (
              <TableCell key={`${rowKey}_column_${columnIndex.toString()}`}>
                <Skeleton animation="wave" variant="text" />
              </TableCell>
            ))}
          </TableRow>
        );
      })}
    </>
  );
}

export default TableRowsLoader;
