import mainApi from '../../../apis/mainApi';
import {
  GAME_7X7_SETTINGS_BY_ORG_ID,
  ORG_EVENT_PLAYING_FIELDS,
  ORG_EVENT_PLAYING_FIELDS_ID,
  ORG_EVENT_PLAYING_FIELDS_ID_STATISTICIANS,
  ORG_EVENT_POOL_PLAYS_RULES,
  ORG_EVENT_PP_DIVISION_ID_RULES,
  ORG_EVENT_PP_DIVISION_ID_RULES_PLAYING_FIELDS,
  ORG_EVENT_PP_DIVISION_ID_RULES_SETUP_ALL,
  ORG_EVENT_PP_RULES_TIME_FRAMES_RANGE,
  ORG_EVENT_PP_SCHEDULE_CHECK_VALIDITY,
  ORG_EVENT_PP_SCHEDULE_SETUP_GAMES,
  ORG_EVENT_PP_RULES_FULL_INFO,
  ORG_EVENT_PP_RULES_TIME_FRAME_ID,
  ORG_EVENT_PP_RULES_ID_TIME_FRAME,
  ORG_EVENT_PP_RULES_TIME_FRAME,
  ORG_EVENT_PP_SCHEDULE_CHANGE_GAME_POSITION,
  ORG_EVENT_PP_SCHEDULE_REGENERATE,
  ORG_EVENT_PP_RULES_PUBLISH,
  ORG_MEMBERS_ADDRESS,
  ORG_EVENT_PP_DIVISION_ID_RULES_SWAP_POOL_TEAMS,
} from '../../../../constants/requestUrls';
import {
  POOL_PLAYS_CHECK_VALIDATION_TAG,
  POOL_PLAYS_PLAYING_FIELDS,
  POOL_PLAYS_PUBLISH_TAG,
  POOL_PLAYS_RULES,
  POOL_PLAYS_RULES_LIST,
  POOL_PLAYS_SCHEDULE_GAME_TAG,
  POOL_PLAYS_SCHEDULE_TAG,
} from '../../../tags/poolPlays';
import { EVENT_DIVISIONS_GAME_SETUP } from '../../../tags/divisions';
import { SCHEDULE_BRACKET_TAG, SCHEDULE_TIME_RANGE_TAG } from '../../../tags/schedule';
import { TeamTypes } from '../interfaces/IPoolPlayDivisionRules';
import { transformResponseWithTimezone } from '../../../../utils/transformResponseWithTimezone';
import { updateQueryData } from '../../../../utils/poolPlays';
import type IBaseQueryResponse from '../../../types/IBaseQueryResponse';
import type IPoolPlay from '../interfaces/IPoolPlay';
import type IPoolPlayRequest from '../interfaces/IPoolPlayRequest';
import type { IBaseQueryRequest } from '../../../types/IBaseQueryRequest';
import type IUpdatePPTimeFrameReq from '../interfaces/IUpdatePPTimeFrameReq';
import type IPoolPlayDivisionRulesReq from '../interfaces/IPoolPlayDivisionRulesReq';
import type IPoolPlayDivisionRules from '../interfaces/IPoolPlayDivisionRules';
import type { IBaseQueryReqWithParams } from '../../../types/IBaseQueryReqWithParams';
import type IBaseQueryResWithPagination from '../../../types/IBaseQueryResWithPagination';
import type IGame7x7Settings from '../interfaces/IGame7x7Settings';
import type IEventPlayingField from '../interfaces/IEventPlayingField';
import type { IEventPlayingFieldRequest } from '../interfaces/IEventPlayingField';
import type IPPTimeFramesRange from '../interfaces/IPPTimeFramesRange';
import type { ICreatePPDivisionRulesReq } from '../interfaces/IPoolPlayDivisionRulesReq';
import type IAddStatisticianToPlayingFieldReq from '../interfaces/IAddStatisticianToPlayingFieldReq';
import type IOrgMember from '../interfaces/IOrgMember';
import type IOrgMemberReq from '../interfaces/IOrgMemberReq';
import type IUpdateEventPlayingFieldReq from '../interfaces/IUpdateEventPlayingFieldReq';
import type IUpdatePPDivisionFieldsReq from '../../../../pages/LogisticsAndGameSetup/interfaces/IUpdatePPDivisionFieldsReq';
import type ICreatePPTimeFrameReq from '../interfaces/ICreatePPTimeFrameReq';
import type ICheckValidity from '../interfaces/ICheckValidity';
import type IPPFullInfo from '../interfaces/IPPFullInfo';
import type IUpdateGamePositionRequest from '../interfaces/IUpdateGamePositionRequest';
import type ITimeFrame from '../interfaces/ITimeFrame';
import type IRegeneratePPSchedule from '../interfaces/IRegeneratePPSchedule';
import type IResponseMeta from '../../../types/IResponseMeta';
import type ISwapPPPoolTeamsRequest from '../interfaces/ISwapPPPoolTeamsRequest';

const poolPlaysApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getPoolPlays: builder.query<IBaseQueryResponse<IPoolPlay>, IBaseQueryRequest>({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENT_POOL_PLAYS_RULES
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
      }),
      transformResponse: (data: IBaseQueryResponse<IPoolPlay>, meta: IResponseMeta) => (
        transformResponseWithTimezone(data, meta)
      ),
      providesTags: [POOL_PLAYS_RULES],
    }),

    createPoolPlays: builder.mutation<IBaseQueryResponse<IPoolPlay>, IPoolPlayRequest>({
      query: ({ orgId, eventId, ...body }) => ({
        url: ORG_EVENT_POOL_PLAYS_RULES
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'POST',
        body,
      }),
      invalidatesTags: [POOL_PLAYS_RULES_LIST],
    }),

    updatePoolPlays: builder.mutation<IBaseQueryResponse<IPoolPlay>, Omit<IPoolPlayRequest, 'timeFrames'>>({
      query: ({ orgId, eventId, ...body }) => ({
        url: ORG_EVENT_POOL_PLAYS_RULES
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [POOL_PLAYS_RULES_LIST],
    }),

    updatePPTimeFrame: builder.mutation<IBaseQueryResponse<IPoolPlay>, IUpdatePPTimeFrameReq>({
      query: ({
        orgId, eventId, timeFrameId, ...body
      }) => ({
        url: ORG_EVENT_PP_RULES_TIME_FRAME_ID
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{timeFrameId}', `${timeFrameId}`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_result, _error, { timeFrameId }) => [
        { type: POOL_PLAYS_RULES_LIST, id: timeFrameId }, POOL_PLAYS_RULES_LIST, SCHEDULE_TIME_RANGE_TAG,
      ],
    }),

    getPPTimeFrame: builder.query<IBaseQueryResponse<ITimeFrame[]>, IBaseQueryRequest>({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENT_PP_RULES_TIME_FRAME
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
      }),
      transformResponse: (data: IBaseQueryResponse<ITimeFrame[]>, meta: IResponseMeta) => (
        transformResponseWithTimezone(data, meta)
      ),
      providesTags: (result) => (
        result
          ? [...result.data.map(({ id }) => ({ type: POOL_PLAYS_RULES_LIST, id })), POOL_PLAYS_RULES_LIST]
          : [POOL_PLAYS_RULES_LIST]
      ),
    }),

    createPPTimeFrame: builder.mutation<IBaseQueryResponse<IPoolPlay>, ICreatePPTimeFrameReq>({
      query: ({
        orgId, eventId, playRuleId, ...body
      }) => ({
        url: ORG_EVENT_PP_RULES_ID_TIME_FRAME
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{playRuleId}', `${playRuleId}`),
        method: 'POST',
        body,
      }),
      invalidatesTags: [POOL_PLAYS_RULES_LIST, SCHEDULE_TIME_RANGE_TAG],
    }),

    deletePPTimeFrame: builder.mutation<IBaseQueryResponse<IPoolPlay>, Omit<IUpdatePPTimeFrameReq, 'startAt' | 'endAt'>>({
      query: ({ orgId, eventId, timeFrameId }) => ({
        url: ORG_EVENT_PP_RULES_TIME_FRAME_ID
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{timeFrameId}', `${timeFrameId}`),
        method: 'DELETE',
      }),
      onQueryStarted: async ({ timeFrameId }, { dispatch, queryFulfilled, getState }) => {
        const state = getState();
        const entries = poolPlaysApi.util.selectInvalidatedBy(
          state,
          [POOL_PLAYS_RULES],
        );

        const patchResults = entries.map(({ endpointName, originalArgs }: any) => (
          dispatch(poolPlaysApi.util.updateQueryData(endpointName, originalArgs, (draft: any) => {
            const currentData = draft.data;
            const timeFrames = draft.data.timeFrames.filter((item: any) => item.id !== timeFrameId);

            Object.assign(draft.data, { ...currentData, timeFrames });

            return draft;
          }))
        ));

        queryFulfilled.catch(() => {
          patchResults.forEach((item) => item?.undo());
        });
      },
      invalidatesTags: (_result, _error, { timeFrameId }) => [
        { type: POOL_PLAYS_RULES_LIST, id: timeFrameId }, POOL_PLAYS_RULES_LIST, SCHEDULE_TIME_RANGE_TAG,
      ],
    }),

    getPPFullInfo: builder.query<IBaseQueryResponse<IPPFullInfo>, IBaseQueryRequest>({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENT_PP_RULES_FULL_INFO
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
      }),
      providesTags: [POOL_PLAYS_PUBLISH_TAG],
    }),

    setupAllPPDivisions: builder.mutation<
    IBaseQueryResponse<IPoolPlayDivisionRules[]>,
    IBaseQueryRequest & { divisionId: number }
    >({
      query: ({ orgId, eventId, divisionId }) => ({
        url: ORG_EVENT_PP_DIVISION_ID_RULES_SETUP_ALL
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{divisionId}', `${divisionId}`),
        method: 'POST',
      }),
      invalidatesTags: (result, error, { divisionId }) => [{ type: EVENT_DIVISIONS_GAME_SETUP, id: divisionId }],
    }),

    updatePPDivisionPlayingFields: builder.mutation<void, IUpdatePPDivisionFieldsReq>({
      query: ({
        orgId, eventId, divisionId, ...body
      }) => ({
        url: ORG_EVENT_PP_DIVISION_ID_RULES_PLAYING_FIELDS
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{divisionId}', `${divisionId}`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { divisionId }) => [{ type: EVENT_DIVISIONS_GAME_SETUP, id: divisionId }],
    }),

    getPPTimeFramesRange: builder.query<IBaseQueryResponse<IPPTimeFramesRange>, IBaseQueryRequest>({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENT_PP_RULES_TIME_FRAMES_RANGE
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
      }),
      transformResponse: (data: IBaseQueryResponse<IPPTimeFramesRange>, meta: IResponseMeta) => (
        transformResponseWithTimezone(data, meta)
      ),
      providesTags: [POOL_PLAYS_RULES_LIST],
    }),

    getPoolPlaysDivisionRules: builder.query<IBaseQueryResponse<IPoolPlayDivisionRules>, IPoolPlayDivisionRulesReq>({
      query: ({ orgId, eventId, divisionId }) => ({
        url: ORG_EVENT_PP_DIVISION_ID_RULES
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{divisionId}', `${divisionId}`),
        method: 'GET',
      }),
      transformResponse: (data: IBaseQueryResponse<IPoolPlayDivisionRules>) => ({
        ...data,
        data: {
          ...data.data,
          distributionTeamsToPools: {
            withGhost: data.data.distributionTeamsToPools.withGhost
              .map((item) => ({ ...item, type: TeamTypes.GHOST })),
            withoutGhost: data.data.distributionTeamsToPools.withoutGhost
              .map((item) => ({ ...item, type: TeamTypes.REAL })),
          },
        },
      }),
    }),

    updatePoolPlaysDivisionRules: builder.mutation<
    IBaseQueryResponse<IPoolPlayDivisionRules>,
    ICreatePPDivisionRulesReq
    >({
      query: ({
        orgId, eventId, divisionId, ...body
      }) => ({
        url: ORG_EVENT_PP_DIVISION_ID_RULES
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{divisionId}', `${divisionId}`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { divisionId }) => [
        { type: EVENT_DIVISIONS_GAME_SETUP, id: divisionId },
        POOL_PLAYS_CHECK_VALIDATION_TAG,
      ],
    }),

    createPoolPlaysDivisionRules: builder.mutation<
    IBaseQueryResponse<IPoolPlayDivisionRules, { isFirstDivisionSetup: boolean }>,
    ICreatePPDivisionRulesReq
    >({
      query: ({
        orgId, eventId, divisionId, ...body
      }) => ({
        url: ORG_EVENT_PP_DIVISION_ID_RULES
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{divisionId}', `${divisionId}`),
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { divisionId }) => [
        { type: EVENT_DIVISIONS_GAME_SETUP, id: divisionId },
        POOL_PLAYS_CHECK_VALIDATION_TAG,
      ],
    }),

    getGame7x7SettingsByOrg: builder.query<IBaseQueryResWithPagination<IGame7x7Settings[]>, IBaseQueryReqWithParams>({
      query: ({ orgId, ...params }) => ({
        url: GAME_7X7_SETTINGS_BY_ORG_ID.replace('{organizationId}', `${orgId}`),
        method: 'GET',
        params,
      }),
    }),

    getEventPlayingFields: builder.query<IBaseQueryResWithPagination<IEventPlayingField[]>, IBaseQueryReqWithParams>({
      query: ({ orgId, eventId, ...params }) => ({
        url: ORG_EVENT_PLAYING_FIELDS
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
        params,
      }),
      transformResponse: (data: IBaseQueryResWithPagination<IEventPlayingField[]>) => {
        const sortedData = [...data.data].sort((a, b) => a.id - b.id);

        return ({
          ...data,
          data: sortedData,
        });
      },
      providesTags: [POOL_PLAYS_PLAYING_FIELDS],
    }),

    createEventPlayingField: builder.mutation<IBaseQueryResponse<IEventPlayingField>, IEventPlayingFieldRequest>({
      query: ({ orgId, eventId, ...body }) => ({
        url: ORG_EVENT_PLAYING_FIELDS
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'POST',
        body,
      }),
      invalidatesTags: [POOL_PLAYS_PLAYING_FIELDS],
    }),

    updateEventPlayingField: builder.mutation<IBaseQueryResponse<IEventPlayingField>, IUpdateEventPlayingFieldReq>({
      query: ({
        orgId, eventId, fieldId, ...body
      }) => ({
        url: ORG_EVENT_PLAYING_FIELDS_ID
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{id}', `${fieldId}`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [POOL_PLAYS_PLAYING_FIELDS, SCHEDULE_BRACKET_TAG],
    }),

    deleteEventPlayingField: builder.mutation<IBaseQueryResponse<IEventPlayingField>, IUpdateEventPlayingFieldReq>({
      query: ({
        orgId, eventId, fieldId, ...body
      }) => ({
        url: ORG_EVENT_PLAYING_FIELDS_ID
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{id}', `${fieldId}`),
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [POOL_PLAYS_PLAYING_FIELDS, POOL_PLAYS_SCHEDULE_TAG],
    }),

    getOrgMembers: builder.query<IBaseQueryResWithPagination<IOrgMember[]>, IOrgMemberReq>({
      query: ({ orgId, ...params }) => ({
        url: ORG_MEMBERS_ADDRESS.replace('{organizationId}', `${orgId}`),
        method: 'GET',
        params,
      }),
    }),

    updateStatisticianOfPlayingField: builder.mutation<
    IBaseQueryResponse<IEventPlayingField>,
    IAddStatisticianToPlayingFieldReq
    >({
      query: ({
        orgId, eventId, fieldId, ...body
      }) => ({
        url: ORG_EVENT_PLAYING_FIELDS_ID_STATISTICIANS
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{id}', `${fieldId}`),
        method: 'PUT',
        body,
      }),
    }),

    setupGamesForPPSchedule: builder.mutation<void, IBaseQueryRequest>({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENT_PP_SCHEDULE_SETUP_GAMES
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'POST',
      }),
    }),

    checkValidityPPSchedule: builder.query<
    IBaseQueryResponse<ICheckValidity>,
    IBaseQueryRequest & { isInvalidate?: boolean }
    >({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENT_PP_SCHEDULE_CHECK_VALIDITY
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
      }),
      providesTags: (result, error, { isInvalidate }) => (
        isInvalidate ? [POOL_PLAYS_CHECK_VALIDATION_TAG] : []
      ),
    }),

    updateGamePosition: builder.mutation<unknown, IUpdateGamePositionRequest>({
      query: ({
        orgId, eventId, pPlaysRulesId, ...body
      }) => ({
        url: ORG_EVENT_PP_SCHEDULE_CHANGE_GAME_POSITION
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{pPlaysRulesId}', `${pPlaysRulesId}`),
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async ({ gameFields }, { dispatch, queryFulfilled, getState }) => {
        const field = gameFields[0];
        const state = getState();
        const entries = poolPlaysApi.util.selectInvalidatedBy(
          state,
          [{ type: POOL_PLAYS_SCHEDULE_GAME_TAG, id: field.gameId }, SCHEDULE_TIME_RANGE_TAG],
        );

        const patchResults = entries.map(({ endpointName, originalArgs }: any) => (
          updateQueryData(dispatch, endpointName, originalArgs, field)
        ));

        queryFulfilled.catch(() => {
          patchResults.forEach((item) => item?.undo());
        });
      },
      invalidatesTags: [POOL_PLAYS_CHECK_VALIDATION_TAG],
    }),

    regeneratePPSchedule: builder.mutation<unknown, IRegeneratePPSchedule>({
      query: ({ orgId, eventId, pPlaysRulesId }) => ({
        url: ORG_EVENT_PP_SCHEDULE_REGENERATE
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{pPlaysRulesId}', `${pPlaysRulesId}`),
        method: 'PATCH',
      }),
      invalidatesTags: [POOL_PLAYS_SCHEDULE_TAG],
    }),

    publishPPSchedule: builder.mutation<unknown, IBaseQueryRequest>({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENT_PP_RULES_PUBLISH
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'PATCH',
      }),
      invalidatesTags: [POOL_PLAYS_PUBLISH_TAG],
    }),

    swapPPPoolTeams: builder.mutation<void, ISwapPPPoolTeamsRequest>({
      query: ({
        orgId, eventId, divisionId, ...body
      }) => ({
        url: ORG_EVENT_PP_DIVISION_ID_RULES_SWAP_POOL_TEAMS
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{divisionId}', `${divisionId}`),
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const {
  useGetPoolPlaysQuery,
  useCreatePoolPlaysMutation,
  useGetPPFullInfoQuery,
  useUpdatePoolPlaysMutation,
  useUpdatePPTimeFrameMutation,
  useDeletePPTimeFrameMutation,
  useCreatePPTimeFrameMutation,
  useSetupAllPPDivisionsMutation,
  useUpdatePPDivisionPlayingFieldsMutation,
  useLazyGetPoolPlaysDivisionRulesQuery,
  useLazyGetGame7x7SettingsByOrgQuery,
  useGetPPTimeFramesRangeQuery,
  useCreateEventPlayingFieldMutation,
  useCreatePoolPlaysDivisionRulesMutation,
  useLazyGetOrgMembersQuery,
  useGetEventPlayingFieldsQuery,
  useUpdateEventPlayingFieldMutation,
  useDeleteEventPlayingFieldMutation,
  useUpdateStatisticianOfPlayingFieldMutation,
  useUpdatePoolPlaysDivisionRulesMutation,
  useSetupGamesForPPScheduleMutation,
  useLazyCheckValidityPPScheduleQuery,
  useCheckValidityPPScheduleQuery,
  useUpdateGamePositionMutation,
  useGetPPTimeFrameQuery,
  useRegeneratePPScheduleMutation,
  usePublishPPScheduleMutation,
  useSwapPPPoolTeamsMutation,
} = poolPlaysApi;

export default poolPlaysApi;
