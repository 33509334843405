import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useNavigate, useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import SingleEventWaiversView from './SingleEventWaiversView';
import { useAppSelector } from '../../store/hooks/useApp';
import { selectUserOrg } from '../../store/slices/user/selectors';
import {
  useCreateEventWaiverMutation, useDeleteWaiverByEventMutation, useGetEventWaiversQuery, useUpdateEventWaiverMutation,
} from '../../store/slices/waivers/apis/waiversApi';
import { selectEventStatus } from '../../store/slices/events/selectors';
import { selectEventWaivers } from '../../store/slices/waivers/selectors';
import { eventWaiversSchema } from '../../utils/validators';
import { getErrorMessage } from '../../utils/helpers';
import { useGetEventQuery } from '../../store/slices/events/apis/eventsApi';
import type { IDocument, IDocuments } from '../../components/DocumentForm/interfaces/IDocumentsForm';
import type { IFileWithPath } from '../../interfaces/IFileWithPath';
import { EventStatuses } from '../../constants/Statuses';

type SingleEventWaiversForm = Pick<IDocuments, 'waivers'>;

function SingleEventWaivers(): React.ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { eventId } = useParams();
  const organization = useAppSelector(selectUserOrg);
  const [isWaiverLoading, setIsWaiverLoading] = useState(false);

  const areIdsSettled = organization?.id && eventId;

  const { eventStatus } = useGetEventQuery(
    areIdsSettled ? { orgId: organization?.id as number, eventId: +eventId } : skipToken,
    {
      selectFromResult: (result) => ({ eventStatus: selectEventStatus(result.data) }),
    },
  );

  const { eventWaivers } = useGetEventWaiversQuery(
    areIdsSettled ? { orgId: organization?.id as number, eventId: +eventId } : skipToken,
    {
      selectFromResult: (result) => ({ ...result, eventWaivers: selectEventWaivers(result.data) }),
    },
  );

  const [createEventWaiver] = useCreateEventWaiverMutation();
  const [updateEventWaiver] = useUpdateEventWaiverMutation();
  const [deleteWaiverByEvent] = useDeleteWaiverByEventMutation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<SingleEventWaiversForm>({
    resolver: yupResolver<SingleEventWaiversForm>(eventWaiversSchema),
    mode: 'onChange',
    defaultValues: {
      waivers: eventWaivers || [],
    },
  });

  useEffect(() => {
    reset({ waivers: eventWaivers });
  }, [reset, eventWaivers]);

  const {
    fields: waivers,
    append: appendWaiver,
    remove: handleRemoveWaiverByForm,
  } = useFieldArray({ control, name: 'waivers' });

  const handleAppendWaivers = (files: IFileWithPath[]) => {
    const newWaivers = files.map(({ file }) => {
      const name = file.name.split('.').slice(0, -1).join('.');

      return ({ name, file });
    });
    appendWaiver(newWaivers);
  };

  const handleCancel = () => {
    reset();
    navigate(-1);
  };

  const handleCreateSubmit = handleSubmit((values) => {
    setIsWaiverLoading(false);

    const waiversPromises = (values.waivers as IDocument[]).map((waiver) => {
      if (!waiver.documentId) {
        setIsWaiverLoading(true);
      }

      const data = {
        orgId: organization?.id as number,
        eventId: Number(eventId),
        title: waiver.name,
      };

      return waiver.documentId
        ? updateEventWaiver({ ...data, waiverId: waiver.documentId })
        : createEventWaiver({ ...data, file: waiver.file as File });
    });

    const waiversIdsToDelete = eventWaivers
      .filter((waiver) => !values?.waivers?.some((item) => item.documentId === waiver.documentId))
      .map((waiver) => waiver.documentId);

    const waiversDeletePromises = waiversIdsToDelete.length
      ? waiversIdsToDelete.map((waiverId) => deleteWaiverByEvent({
        orgId: organization?.id as number,
        eventId: +(eventId as string),
        waiverId,
      })) : undefined;

    const requestPromises = [
      ...waiversPromises,
      waiversDeletePromises,
    ].filter(Boolean);

    Promise.all(requestPromises).then((res) => {
      const errors = res
        .map((item) => (item && 'error' in item ? getErrorMessage(item.error) : null))
        .filter(Boolean);

      if (errors.length > 0) {
        errors.forEach((errorMessage) => {
          toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT });
        });
      } else {
        handleCancel();
        toast.success(t('events.changesSaved'), { position: toast.POSITION.TOP_RIGHT });
      }
    });
  });

  return (
    <SingleEventWaiversView
      control={control}
      waivers={waivers}
      onCancel={handleCancel}
      onAppendDocument={handleAppendWaivers}
      onRemoveDocument={handleRemoveWaiverByForm}
      onCreateSubmit={handleCreateSubmit}
      isSubmitting={isSubmitting}
      withConfirm={eventStatus !== EventStatuses.Draft}
      isWaiverLoading={isWaiverLoading}
    />
  );
}

export default SingleEventWaivers;
