import { useTranslation } from 'react-i18next';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';

import PaperBlock from '../../../components/PaperBlock/PaperBlock';
import EmptyData from '../../../components/EmptyData/EmptyData';
import useStyles from './tableStyles-tss';
import { useAppSelector } from '../../../store/hooks/useApp';
import { selectThemeMode } from '../../../store/slices/ui/selectors';
import { AppThemeMode } from '../../../constants/AppThemes';
import { toPriceFormat } from '../../../utils/helpers';
import type { IRefundsTable } from '../interfaces/IRefundsTable';

const tableHeadCells = [
  'events.date',
  'events.refundTo',
  'events.refundType',
  'events.refundAmount',
];

function RefundsTable({
  refundsData,
}: IRefundsTable) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const themeMode = useAppSelector(selectThemeMode);
  const colorMode = themeMode === AppThemeMode.Dark ? AppThemeMode.Dark : AppThemeMode.Light;

  return (
    <PaperBlock
      title={t('events.refunds')}
      colorMode={colorMode}
      whiteBg
      noMargin
      titleClassName={classes.title}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow className={classes.head}>
              {tableHeadCells.map((item) => (
                <TableCell key={['RefundsTable', item].join('_')}>
                  {t(item)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {refundsData.length
              ? (
                <>
                  {refundsData.map((refund) => (
                    <TableRow
                      key={['RefundsTable', refund.id].join('_')}
                      className={classes.stripped}
                    >
                      <TableCell>
                        <Typography variant="body2">
                          {refund.refundedAt}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {refund.recipientName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {refund.refundType}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {`- ${toPriceFormat(refund.amount)}`}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <EmptyData description={t('common.noData')} />
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </PaperBlock>
  );
}

export default RefundsTable;
