/* eslint-disable @typescript-eslint/no-unused-vars */
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { extractUsernameFromLink } from '../../../utils/helpers';
import {
  FACEBOOK_LINK_REGEXP,
  HUDL_LINK_REGEXP,
  INSTAGRAM_LINK_REGEXP,
  TIKTOK_LINK_REGEXP,
  TWITTER_LINK_REGEXP,
  YOUTUBE_LINK_REGEXP,
} from '../../../utils/validators';
import type { ISocialNetworksSection } from '../interfaces/ISocialNetworksSection';

function SocialNetworksSection({ showTitle = true, control }: ISocialNetworksSection): React.ReactElement {
  const { t } = useTranslation();

  return (
    <>
      {showTitle ? (
        <Typography component="h4" mb={2} fontSize={20} fontWeight={500}>
          {t('events.socialNetworks')}
        </Typography>
      ) : null}
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 5 }}>
        <Grid item xs={12} md={6}>
          <Controller
            name="socialMedia.twitter"
            control={control}
            render={({
              field: { onChange, ...props },
              fieldState: { error: fieldError },
            }) => (
              <TextField
                helperText={fieldError?.message}
                error={!!fieldError}
                onChange={({ target }) => onChange(extractUsernameFromLink(target.value, TWITTER_LINK_REGEXP))}
                label="X (optional)"
                variant="outlined"
                fullWidth
                {...props}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="socialMedia.facebook"
            control={control}
            render={({
              field: { onChange, ...props },
              fieldState: { error: fieldError },
            }) => (
              <TextField
                helperText={fieldError?.message}
                error={!!fieldError}
                onChange={({ target }) => onChange(extractUsernameFromLink(target.value, FACEBOOK_LINK_REGEXP))}
                label="Facebook (optional)"
                variant="outlined"
                fullWidth
                {...props}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="socialMedia.instagram"
            control={control}
            render={({
              field: { onChange, ...props },
              fieldState: { error: fieldError },
            }) => (
              <TextField
                helperText={fieldError?.message}
                error={!!fieldError}
                onChange={({ target }) => onChange(extractUsernameFromLink(target.value, INSTAGRAM_LINK_REGEXP))}
                label="Instagram (optional)"
                variant="outlined"
                fullWidth
                {...props}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="socialMedia.tiktok"
            control={control}
            render={({
              field: { onChange, ...props },
              fieldState: { error: fieldError },
            }) => (
              <TextField
                helperText={fieldError?.message}
                error={!!fieldError}
                onChange={({ target }) => onChange(extractUsernameFromLink(target.value, TIKTOK_LINK_REGEXP))}
                label="Tiktok (optional)"
                variant="outlined"
                fullWidth
                {...props}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="socialMedia.hudl"
            control={control}
            render={({
              field: { onChange, ...props },
              fieldState: { error: fieldError },
            }) => (
              <TextField
                helperText={fieldError?.message}
                error={!!fieldError}
                onChange={({ target }) => onChange(extractUsernameFromLink(target.value, HUDL_LINK_REGEXP))}
                label="Hudl (optional)"
                variant="outlined"
                fullWidth
                {...props}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="socialMedia.youtube"
            control={control}
            render={({
              field: { onChange, ...props },
              fieldState: { error: fieldError },
            }) => (
              <TextField
                helperText={fieldError?.message}
                error={!!fieldError}
                onChange={({ target }) => onChange(extractUsernameFromLink(target.value, YOUTUBE_LINK_REGEXP))}
                label="Youtube (optional)"
                variant="outlined"
                fullWidth
                {...props}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default memo(SocialNetworksSection);
