import { createSelector } from '@reduxjs/toolkit';

import { extractSocialMedia, parseISODateTime } from '../../../utils/helpers';
import { DEFAULT_EVENT_TYPE, SPECTATORS_TICKET_PASS } from '../../../constants/general';
import type IEventsSlice from './interfaces/IEventsSlice';
import type { RootState } from '../../types/TStore';
import type IBaseQueryResponse from '../../types/IBaseQueryResponse';
import type { ISingleEvent } from './interfaces/ISingleEvent';
import type { IHotel } from '../hotels/interfaces/IHotel';
import type { IPartner } from '../partners/interfaces/IPartner';
import type IBaseQueryResWithPagination from '../../types/IBaseQueryResWithPagination';
import type { IEventDays } from './interfaces/IEventDays';

const selectEvents = (state: RootState): IEventsSlice => state.events;

const selectPageFilters = createSelector(selectEvents, (events) => ({
  search: events.search,
  order: events.order,
  orderBy: events.orderBy,
  status: events.status,
  topicId: events.topicId,
}));

export const selectEventConfirmModal = createSelector(
  selectEvents,
  (events) => events.confirmModal,
);

export const selectCreatedEvent = createSelector(
  (data: IBaseQueryResponse<ISingleEvent> | undefined) => data?.data,
  (data: IBaseQueryResponse<ISingleEvent> | undefined) => data?.timezone,
  (event, timezone) => {
    const {
      id,
      createdAt,
      updatedAt,
      ...address
    } = event?.address || {};

    return ({
      id: event?.id,
      profilePhoto: event?.profilePhoto || undefined,
      cover: event?.cover || undefined,
      name: event?.name || '',
      nameWithTagline: (event?.name || '') + (event?.topic ? ` (${event.topic.name})` : ''),
      tagline: event?.tagline || '',
      address: Object.keys(address).length ? address : undefined,
      description: event?.address?.description || '',
      topic: event?.topic,
      type: event?.type || DEFAULT_EVENT_TYPE,
      email: event?.email || undefined,
      phone: event?.phone || undefined,
      about: event?.about || '',
      socialMedia: extractSocialMedia(event?.socialMedia),
      startAt: parseISODateTime(event?.startAt, timezone),
      endAt: parseISODateTime(event?.endAt, timezone),
      divisionRegistrationEndAt: parseISODateTime(event?.divisionRegistrationEndAt, event?.timezone),
      published: event?.published,
      status: event?.status,
      timezone: event?.timezone,
    });
  },
);

export const selectEventDates = createSelector(
  (data: IBaseQueryResponse<ISingleEvent> | undefined) => data?.data,
  (event) => ({
    id: event?.id,
    name: event?.name || '',
    startAt: parseISODateTime(event?.startAt, event?.timezone),
    endAt: parseISODateTime(event?.endAt, event?.timezone),
    endEventRegDate: parseISODateTime(event?.teamTicketRules?.divisionRegistrationEndAt, event?.timezone),
    ageCutoffDate: parseISODateTime(event?.teamTicketRules?.ageValidateFrom, event?.timezone),
    eventDivisionsLength: event?.eventDivisions.length || 0,
    sellStartAt: parseISODateTime(event?.spectatorRules?.sellStartAt, event?.timezone),
    sellEndAt: parseISODateTime(event?.spectatorRules?.sellEndAt, event?.timezone),
    published: !!event?.published,
    timezone: event?.timezone,
  }),
);

export const selectEventDays = createSelector(
  (data: IBaseQueryResponse<IEventDays> | undefined) => data?.data,
  (day) => ({
    id: day?.id,
    startAt: parseISODateTime(day?.startAt, day?.timezone),
    endAt: parseISODateTime(day?.endAt, day?.timezone),
    eventDiscounts: day?.eventDivisions
      .filter((division) => division.discount)
      .map((item) => ({
        divisionId: item.id,
        name: item.division.name,
        price: item.discount.price,
        endAt: parseISODateTime(item.discount.endAt, day.timezone),
      })) || [],
    oneDayTickets: day?.spectatorRules?.ticketTypeRules
      .filter((item) => item.pass === SPECTATORS_TICKET_PASS.DAY_PASS && item.discount)
      .map(({ id, type, discount }) => ({
        ruleId: id,
        type,
        price: discount.price,
        endAt: parseISODateTime(discount.endAt, day?.timezone),
      })) || [],
    wholeEventTickets: day?.spectatorRules?.ticketTypeRules
      .filter((item) => item.pass === SPECTATORS_TICKET_PASS.WHOLE_PASS && item.discount)
      .map(({ id, type, discount }) => ({
        ruleId: id,
        type,
        price: discount.price,
        endAt: parseISODateTime(discount.endAt, day?.timezone),
      })) || [],
  }),
);

export const selectEventStatus = createSelector(
  (data: IBaseQueryResponse<ISingleEvent> | undefined) => data?.data,
  (event) => event?.status,
);

export const selectEventIsPublished = createSelector(
  (data: IBaseQueryResponse<ISingleEvent> | undefined) => data?.data,
  (event) => !!event?.published,
);

export const selectEventHotels = createSelector(
  (data: IBaseQueryResWithPagination<IHotel[]> | undefined) => data?.data || [],
  (hotels) => hotels.map((hotel) => {
    const { id, ...address } = hotel.address;

    return ({
      hotelId: hotel.id,
      name: hotel.name,
      link: hotel.link,
      address,
    });
  }),
);

export const selectEventPartners = createSelector(
  (data: IBaseQueryResWithPagination<IPartner[]> | undefined) => data?.data || [],
  (partners) => partners.map((partner) => ({
    partnerId: partner.id,
    name: partner.name,
    link: partner.link,
    cover: partner.cover,
  })),
);

export default selectPageFilters;
