import { lazy, Suspense } from 'react';
import type { ReactElement } from 'react';
import {
  BrowserRouter, Routes, Route, Navigate,
} from 'react-router-dom';
import type { Libraries } from '@googlemaps/js-api-loader';
import { Loader } from '@googlemaps/js-api-loader';

import PageProtected from './layout/Page/PageProtected';
import PageNotFound from './layout/Page/PageNotFound';
import CreateEventLayout from './layout/CreateEventLayout/CreateEventLayout';
import EditEventLayout from './layout/EditEventLayout/EditEventLayout';
import SingleEventLayout from './layout/SingleEventLayout/SingleEventLayout';
import SettingsLayout from './layout/SettingsLayout/SettingsLayout';
import PoolPlayScheduleLayout from './layout/PoolPlayScheduleLayout/PoolPlayScheduleLayout';
import EventNotificationsLayout from './layout/EventNotificationsLayout/EventNotificationsLayout';
import Login from './pages/Login/Login';
import Events from './pages/Events/Events';
import SingleEvent from './pages/SingleEvent/SingleEvent';
import SingleEventEdit from './pages/SingleEventEdit/SingleEventEdit';
import SingleEventSignatures from './pages/SingleEventSignatures/SingleEventSignatures';
import SingleEventTeams from './pages/SingleEventTeams/SingleEventTeams';
import SingleEventPoolPlaySchedule from './pages/SingleEventPoolPlaySchedule/SingleEventPoolPlaySchedule';
import SingleEventHotels from './pages/SingleEventHotels/SingleEventHotels';
import SingleEventPartners from './pages/SingleEventPartners/SingleEventPartners';
import SingleEventWaivers from './pages/SingleEventWaivers/SingleEventWaivers';
import SingleEventRules from './pages/SingleEventRules/SingleEventRules';
import SingleEventDivisions from './pages/SingleEventDivisions/SingleEventDivisions';
import SingleEventSpectators from './pages/SingleEventSpectators/SingleEventSpectators';
import SingleEventStandings from './pages/SingleEventStandings/SingleEventStandings';
import SingleEventDates from './pages/SingleEventDates/SingleEventDates';
import SingleEventSocial from './pages/SingleEventSocial/SingleEventSocial';
import SingleEventAnalytics from './pages/SingleEventAnalytics/SingleEventAnalytics';
import SingleEventSchedule from './pages/SingleEventSchedule/SingleEventSchedule';
import SingleEventNotifications from './pages/SingleEventNotifications/SingleEventNotifications';
import Documents from './pages/Documents/Documents';
import TeamsTickets from './pages/TeamsTickets/TeamsTickets';
import SpectatorsTickets from './pages/SpectatorsTickets/SpectatorsTickets';
import QrCode from './pages/QrCode/QrCode';
import PaymentOptions from './pages/PaymentOptions/PaymentOptions';
import Dashboard from './pages/Dashboard/Dashboard';
import TimeFrame from './pages/TimeFrame/TimeFrame';
import LogisticsAndGameSetup from './pages/LogisticsAndGameSetup/LogisticsAndGameSetup';
import PlayOffSchedule from './pages/PlayOffSchedule/PlayOffSchedule';
import ThemeProvider from './components/ThemeProvider/ThemeProvider';
import SplashScreen from './components/SplashScreen/SplashScreen';
import AppRoutes from './constants/AppRoutes';
import { PLAY_OFF_SCHEDULE_STEPS, POOL_PLAY_SCHEDULE_STEPS, ScheduleTypes } from './constants/general';

import './i18n';
import './styles/globals.scss';
import 'react-toastify/dist/ReactToastify.css';

const options = {
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
  version: 'weekly',
  libraries: ['places'] as Libraries,
  language: 'en',
};

const loader = new Loader(options);
loader.importLibrary('places').then().catch();

const CreateEvent = lazy(() => import('./pages/CreateEvent/CreateEvent'));
const HotelsAndPartners = lazy(() => import('./pages/HotelsAndPartners/HotelsAndPartners'));

function App(): ReactElement {
  return (
    <ThemeProvider>
      <BrowserRouter future={{ v7_startTransition: true }}>
        <Routes>
          <Route path={AppRoutes.home}>
            <Route path={AppRoutes.login} element={<Login />} />
            <Route element={<PageProtected redirectUri={AppRoutes.login} />}>
              <Route index element={<Dashboard />} />
              <Route path={AppRoutes.events} element={<Events />} />
              <Route element={<SingleEventLayout />}>
                <Route path={AppRoutes.singleEvent} element={<SingleEvent />} />
                <Route path={AppRoutes.singleEventAnalytics} element={<SingleEventAnalytics />} />
                <Route path={AppRoutes.singleEventTeams} element={<SingleEventTeams />} />
                <Route path={AppRoutes.singleEventSchedule} element={<SingleEventSchedule />} />
                <Route path={AppRoutes.singleEventStandings} element={<SingleEventStandings />} />
                <Route path={AppRoutes.singleEventSignatures} element={<SingleEventSignatures />} />
              </Route>
              <Route element={<EditEventLayout />}>
                <Route path={AppRoutes.singleEventEdit} element={<SingleEventEdit />} />
                <Route path={AppRoutes.singleEventHotels} element={<SingleEventHotels />} />
                <Route path={AppRoutes.singleEventPartners} element={<SingleEventPartners />} />
                <Route path={AppRoutes.singleEventWaivers} element={<SingleEventWaivers />} />
                <Route path={AppRoutes.singleEventRules} element={<SingleEventRules />} />
                <Route path={AppRoutes.singleEventDivisions} element={<SingleEventDivisions />} />
                <Route path={AppRoutes.singleEventSpectators} element={<SingleEventSpectators />} />
                <Route path={AppRoutes.singleEventDates} element={<SingleEventDates />} />
                <Route path={AppRoutes.singleEventSocial} element={<SingleEventSocial />} />
              </Route>
              <Route element={<EventNotificationsLayout />}>
                <Route path={AppRoutes.singleEventNotifications} element={<SingleEventNotifications />} />
              </Route>
              <Route element={<CreateEventLayout />}>
                <Route
                  path={AppRoutes.createEvent}
                  element={(
                    <Suspense fallback={<SplashScreen />}>
                      <CreateEvent />
                    </Suspense>
                  )}
                />
                <Route
                  path={AppRoutes.hotelsAndPartners}
                  element={(
                    <Suspense fallback={<SplashScreen />}>
                      <HotelsAndPartners />
                    </Suspense>
                  )}
                />
                <Route path={AppRoutes.documents} element={<Documents />} />
                <Route path={AppRoutes.teamsTickets} element={<TeamsTickets />} />
                <Route path={AppRoutes.spectatorsTickets} element={<SpectatorsTickets />} />
              </Route>
              <Route path={AppRoutes.settings} element={<Navigate to={AppRoutes.editProfile} />} />
              <Route element={<SettingsLayout />}>
                <Route path={AppRoutes.editProfile} element={null} />
                <Route path={AppRoutes.members} element={null} />
                <Route path={AppRoutes.notifications} element={null} />
                <Route path={AppRoutes.qrCode} element={<QrCode />} />
                <Route path={AppRoutes.paymentOptions} element={<PaymentOptions />} />
              </Route>
              <Route element={<PoolPlayScheduleLayout steps={POOL_PLAY_SCHEDULE_STEPS} />}>
                <Route
                  path={AppRoutes.singleEventPoolPlayTimeFrame}
                  element={<TimeFrame type={ScheduleTypes.POOL_PLAY} />}
                />
                <Route
                  path={AppRoutes.singleEventPoolPlayLogisticsAndGameSetup}
                  element={<LogisticsAndGameSetup type={ScheduleTypes.POOL_PLAY} />}
                />
                <Route path={AppRoutes.singleEventPoolPlaySchedule} element={<SingleEventPoolPlaySchedule />} />
              </Route>
              <Route element={<PoolPlayScheduleLayout steps={PLAY_OFF_SCHEDULE_STEPS} />}>
                <Route
                  path={AppRoutes.singleEventPlayOffTimeFrame}
                  element={<TimeFrame type={ScheduleTypes.PLAY_OFF} />}
                />
                <Route
                  path={AppRoutes.singleEventPlayOffGameSetup}
                  element={<LogisticsAndGameSetup type={ScheduleTypes.PLAY_OFF} />}
                />
                <Route
                  path={AppRoutes.singleEventPlayOffSchedule}
                  element={<PlayOffSchedule />}
                />
              </Route>
              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
