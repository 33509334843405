import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import Stack from '@mui/material/Stack';

import CreateEventTitle from '../../../components/CreateEventTitle/CreateEventTitle';
import EventDatesSection from './EventDatesSection';
import EventRegistrationEndSection from './EventRegistrationEndSection';
import EventEligibilityDateSection from './EventEligibilityDateSection';
import EventDiscountDatesSection from './EventDiscountDatesSection';
import EventSpectatorsDatesSection from './EventSpectatorsDatesSection';
import type { ISingleEventDatesForm } from '../interfaces/ISingleEventDatesForm';

export default function SingleEventDatesForm({
  control,
  title,
  eventName,
  discountsList,
  spectatorsOneDay,
  spectatorsWholeDays,
  tournamentDaysInterval,
  onTournamentDaysClick,
}: ISingleEventDatesForm) {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <CreateEventTitle title={title} eventName={eventName} />
      <Stack spacing={3}>
        <EventDatesSection control={control} />
        <EventRegistrationEndSection control={control} />
        <EventEligibilityDateSection control={control} />
      </Stack>
      {discountsList.length ? (
        <EventDiscountDatesSection
          control={control}
          discountsList={discountsList}
        />
      ) : null}
      <EventSpectatorsDatesSection
        control={control}
        spectatorsOneDay={spectatorsOneDay}
        spectatorsWholeDays={spectatorsWholeDays}
        tournamentDaysInterval={tournamentDaysInterval}
        onTournamentDaysClick={onTournamentDaysClick}
      />
    </LocalizationProvider>
  );
}
