import mainApi from '../../../apis/mainApi';
import EVENT_RULES_LIST_TAG from '../../../tags/rules';
import {
  ORG_EVENTS_RULES,
  ORG_EVENTS_RULES_MUTATION,
  ORG_EVENTS_RULES_ID,
} from '../../../../constants/requestUrls';
import type IBaseQueryResWithPagination from '../../../types/IBaseQueryResWithPagination';
import type { IRule } from '../interfaces/IRule';
import type IBaseQueryResponse from '../../../types/IBaseQueryResponse';
import type { IBaseQueryRequest } from '../../../types/IBaseQueryRequest';

const waiversApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    createEventRule: builder.mutation<
    IBaseQueryResponse<IRule>,
    { orgId: number, eventId: number, title: string, file: File }
    >({
      query: ({
        orgId, eventId, title, file,
      }) => {
        const form = new FormData();
        form.append('file', file, file.name);
        form.append('title', title);

        return ({
          url: ORG_EVENTS_RULES_MUTATION
            .replace('{organizationId}', `${orgId}`)
            .replace('{eventId}', `${eventId}`),
          method: 'POST',
          body: form,
        });
      },
      invalidatesTags: [EVENT_RULES_LIST_TAG],
    }),

    updateEventRule: builder.mutation<
    IBaseQueryResponse<IRule>,
    { orgId: number, eventId: number, ruleId: number, title: string }
    >({
      query: ({
        orgId, eventId, ruleId, title,
      }) => ({
        url: ORG_EVENTS_RULES_ID
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{id}', `${ruleId}`),
        method: 'PATCH',
        body: { title },
      }),
      invalidatesTags: (result, error, { ruleId }) => [{ type: EVENT_RULES_LIST_TAG, id: ruleId }],
    }),

    deleteEventRule: builder.mutation<IBaseQueryResponse<{}>, IBaseQueryRequest & { ruleId: number }>({
      query: ({ orgId, eventId, ruleId }) => ({
        url: ORG_EVENTS_RULES_ID
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`)
          .replace('{id}', `${ruleId}`),
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { ruleId }) => [{ type: EVENT_RULES_LIST_TAG, id: ruleId }],
    }),

    getEventRules: builder.query<IBaseQueryResWithPagination<IRule[]>, { orgId: number, eventId: number }>({
      query: ({ orgId, eventId }) => ({
        url: ORG_EVENTS_RULES
          .replace('{organizationId}', `${orgId}`)
          .replace('{eventId}', `${eventId}`),
        method: 'GET',
        params: {
          page: 1,
          perPage: 10,
        },
      }),
      transformResponse: (data: IBaseQueryResWithPagination<IRule[]>) => ({
        ...data,
        data: data.data.map((item) => ({
          ...item,
          link: `${item.rulesFile}`,
        })),
      }),
      providesTags: (result) => (
        result
          ? [...result.data.map(({ id }) => ({ type: EVENT_RULES_LIST_TAG, id })), EVENT_RULES_LIST_TAG]
          : [EVENT_RULES_LIST_TAG]
      ),
    }),
  }),
});

export const {
  useGetEventRulesQuery,
  useCreateEventRuleMutation,
  useUpdateEventRuleMutation,
  useDeleteEventRuleMutation,
} = waiversApi;

export default waiversApi;
