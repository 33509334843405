import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AppsIcon from '@mui/icons-material/Apps';

import type IEmptyData from './interfaces/IEmptyData';

function EmptyData({ description, showDevider }: IEmptyData): React.ReactElement {
  return (
    <>
      {showDevider ? <Divider sx={{ borderColor: 'primary.dark', mb: 3 }} /> : null}
      <Stack spacing={1} direction="row" justifyContent="center">
        <AppsIcon color="disabled" />
        <Typography variant="body2">
          {description}
        </Typography>
      </Stack>
    </>
  );
}

export default EmptyData;
