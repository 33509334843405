import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import RevenueWidget from '../../components/RevenueWidget/RevenueWidget';
import PieWidget from '../../components/PieWidget/PieWidget';
import BreakdownTable from './components/BreakdownTable';
import RevenueDataSection from './components/RevenueDataSection';
import EventDataSection from './components/EventDataSection';
import type IDashBoardView from './interfaces/IDashBoardView';

function DashBoardView({
  earnedAmounts,
  totalEventsCounts,
  earnedByEvents,
  earnedBySeason,
  pieData,
}: IDashBoardView) {
  const { t } = useTranslation();

  return (
    <Grid columnSpacing={5} rowSpacing={4} container>
      <RevenueDataSection {...earnedAmounts} />
      <Grid item xs={12} md={6}>
        <RevenueWidget
          title={t('dashboard.seasonRevenue')}
          chartData={earnedBySeason}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <PieWidget
          title={t('dashboard.totalEarned')}
          pieData={pieData}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" fontWeight={700}>
          {t('dashboard.events')}
        </Typography>
      </Grid>
      <EventDataSection {...totalEventsCounts} />
      <Grid item xs={12}>
        <BreakdownTable {...earnedByEvents} />
      </Grid>
    </Grid>
  );
}

export default DashBoardView;
