import { DateTime } from 'luxon';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import UserCellContent from './UserCellContent';
import { USER_VERIFICATION_TYPE } from '../../../constants/general';
import signaturesHeaderCells from '../../../constants/signatureTableHead';
import type { ISignatureAthletesTable } from '../interfaces/ISignatureAthletesTable';

const chipStyles = {
  maxWidth: 'max-content',
  height: 22,
  fontSize: 12,
  fontWeight: 500,
  lineHeight: 1.5,
};

function SignatureAthletesTable({ athletes }: ISignatureAthletesTable): React.ReactElement {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ '.MuiTableCell-root': { borderBottomColor: 'primary.dark' } }}>
            {signaturesHeaderCells.map((cell) => (
              <TableCell key={cell.label} sx={{ pl: cell.padding ? 7 : undefined }}>
                <Typography variant="body2" color="text.disabled">
                  {t(`events.${cell.label}`)}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {athletes.filter((athlete) => !!athlete.waivers.length).map((athlete, index) => (
            <TableRow
              key={`AthletesTableRow_${athlete.firstName}_${athlete.lastName}_${index.toString()}`}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '.MuiTableCell-root': { borderBottomColor: 'primary.dark' },
                verticalAlign: 'super',
              }}
            >
              <TableCell
                sx={{
                  pl: 7,
                  width: '250px',
                  wordBreak: 'break-word',
                }}
              >
                <UserCellContent
                  firstName={athlete.firstName}
                  lastName={athlete.lastName}
                  email={athlete.email}
                  phone={athlete.phone}
                />
              </TableCell>
              <TableCell
                sx={{
                  width: '250px',
                  wordBreak: 'break-word',
                }}
              >
                {athlete.guardian ? (
                  <UserCellContent
                    firstName={athlete.guardian.firstName}
                    lastName={athlete.guardian.lastName}
                    email={athlete.guardian.email}
                    phone={athlete.guardian.phone}
                  />
                ) : '-'}
              </TableCell>
              <TableCell>
                <Stack spacing={2} alignItems="flex-start">
                  <Chip
                    label={t(athlete.userVerification === USER_VERIFICATION_TYPE.VERIFIED
                      ? 'events.verified'
                      : 'events.pending')}
                    size="small"
                    sx={{
                      ...chipStyles,
                      bgcolor: athlete.userVerification === USER_VERIFICATION_TYPE.VERIFIED
                        ? 'success.main'
                        : 'warning.dark',
                    }}
                  />
                </Stack>
              </TableCell>
              <TableCell>
                <Stack
                  spacing={2}
                  alignItems="flex-start"
                >
                  {athlete.waivers.map((waiver) => (
                    <Typography
                      key={`WaiverLink_${waiver.id}`}
                      component="a"
                      variant="body2"
                      href={waiver.waiver}
                      sx={{
                        maxWidth: '600px',
                        textDecoration: 'underline',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                      color="primary.main"
                      target="_blank"
                      rel="nofollow noreferrer"
                      textOverflow="ellipsis"
                    >
                      {waiver.title}
                    </Typography>
                  ))}
                </Stack>
              </TableCell>
              <TableCell>
                <Stack spacing={2} alignItems="flex-start">
                  {athlete.waivers.map((waiver) => (
                    <Chip
                      key={`WaiverStatus_${waiver.id}`}
                      label={t(waiver.agreement ? 'events.signed' : 'events.pending')}
                      size="small"
                      sx={{
                        ...chipStyles,
                        bgcolor: waiver.agreement ? 'success.main' : 'warning.dark',
                      }}
                    />
                  ))}
                </Stack>
              </TableCell>
              <TableCell>
                <Stack spacing={2} alignItems="flex-start">
                  {athlete.waivers.map((waiver) => (
                    waiver.agreement ? (
                      <Typography variant="body2">
                        {`${waiver.agreement.waiverSignerFirstName} ${waiver.agreement.waiverSignerLastName}`}
                      </Typography>
                    ) : null
                  ))}
                </Stack>
              </TableCell>
              <TableCell>
                <Stack spacing={2} alignItems="flex-start">
                  {athlete.waivers.map((waiver) => (
                    waiver.agreement ? (
                      <Typography variant="body2" key={`WaiverSignDate_${waiver.agreement.id}`}>
                        {DateTime.fromISO(waiver.agreement.createdAt).toFormat('ccc, LLL d, t a')}
                      </Typography>
                    ) : null
                  ))}
                </Stack>
              </TableCell>
              <TableCell>
                <Stack spacing={2} alignItems="flex-start">
                  {athlete.waivers.map((waiver) => (
                    waiver.agreement ? (
                      <Typography
                        key={`WaiverSign_${waiver.agreement.id}`}
                        component="a"
                        variant="body2"
                        href={waiver.agreement.agreementFileName}
                        sx={{ textDecoration: 'underline' }}
                        color="primary.main"
                        target="_blank"
                        rel="nofollow noreferrer"
                      >
                        {t('file')}
                      </Typography>
                    ) : null
                  ))}
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SignatureAthletesTable;
