import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Button from '@mui/material/Button';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import PlayingFieldRow from './PlayingFieldRow';
import PlayingFieldEditableRow from './PlayingFieldEditableRow';
import PlayingFieldsEmpty from './PlayingFieldsEmpty';
import {
  useUpdateStatisticianOfPlayingFieldMutation,
  useCreateEventPlayingFieldMutation,
  useDeleteEventPlayingFieldMutation,
  useUpdateEventPlayingFieldMutation,
} from '../../../store/slices/poolPlays/apis/poolPlaysApi';
import { poolPlayFieldFormSchema } from '../../../utils/validators';
import { getErrorMessage } from '../../../utils/helpers';
import { PlayingFieldsTableStatuses } from '../interfaces/IPlayingFieldsTable';
import type IEventPlayingField from '../../../store/slices/poolPlays/interfaces/IEventPlayingField';
import type { TPoolPlayFieldFormSchema } from '../../../utils/validators';
import type ICreateEventContext from '../../../layout/CreateEventLayout/interfaces/ICreateEventContext';
import type IPlayingFieldsTable from '../interfaces/IPlayingFieldsTable';
import { ScheduleTypes } from '../../../constants/general';

const initialValues = {
  name: '',
  address: undefined,
  statisticians: [],
};

function PlayingFieldsTable({
  type, playingFields, fullInfoId, handleCheckField, checkedFields,
}: IPlayingFieldsTable) {
  const { t } = useTranslation();
  const { orgId, eventId } = useOutletContext<ICreateEventContext>();
  const [editingId, setEditingId] = useState<number | null>(null);
  const [status, setStatus] = useState<PlayingFieldsTableStatuses | null>(null);

  const [createEventPlayingField] = useCreateEventPlayingFieldMutation();
  const [deleteEventPlayingField, { isLoading: isDeleting }] = useDeleteEventPlayingFieldMutation();
  const [updateEventPlayingField] = useUpdateEventPlayingFieldMutation();
  const [updateStatisticianToPlayingField] = useUpdateStatisticianOfPlayingFieldMutation();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<TPoolPlayFieldFormSchema>({
    resolver: yupResolver(poolPlayFieldFormSchema),
    mode: 'onBlur',
    defaultValues: initialValues,
  });

  const handleEditRow = (item: IEventPlayingField) => {
    setEditingId(item.id);
    setStatus(PlayingFieldsTableStatuses.editing);
    reset({
      name: item.name,
      address: item.address,
      statisticians: item.gamesStatisticians.map(({ user }) => user),
    });
  };

  const handleDeleteRow = async (fieldId: number) => {
    try {
      await deleteEventPlayingField({
        orgId,
        eventId: +eventId,
        fieldId,
        ...(type === ScheduleTypes.PLAY_OFF
          ? { eventPlayOffRulesId: fullInfoId }
          : { eventPoolPlaysRulesId: fullInfoId }
        ),
      });
    } catch (error) {
      toast.error(getErrorMessage(error as FetchBaseQueryError));
    } finally {
      setEditingId(null);
      setStatus(null);
      reset(initialValues);
    }
  };

  const handleDeleteUnsavedRow = () => {
    setStatus(null);
    reset();
  };

  const handleSaveRow = handleSubmit(async ({ statisticians, ...values }) => {
    const body = {
      orgId,
      eventId: +eventId,
      ...values,
    };
    const statisticiansIds = statisticians.map(({ id }) => id);

    try {
      if (status === PlayingFieldsTableStatuses.adding) {
        await createEventPlayingField({
          ...body,
          ...(type === ScheduleTypes.PLAY_OFF
            ? { playoffRulesId: fullInfoId }
            : { poolPlayRulesId: fullInfoId }),
          statisticians: statisticiansIds,
        });
      } else if (status === PlayingFieldsTableStatuses.editing && editingId && fullInfoId) {
        if (statisticiansIds.length) {
          await updateStatisticianToPlayingField({
            orgId,
            eventId: +eventId,
            fieldId: editingId,
            statisticianIds: statisticiansIds,
          });
        }

        await updateEventPlayingField({
          fieldId: editingId,
          ...(type === ScheduleTypes.PLAY_OFF
            ? { eventPlayOffRulesId: fullInfoId }
            : { eventPoolPlaysRulesId: fullInfoId }),
          ...body,
        });
      }
    } catch (error) {
      toast.error(getErrorMessage(error as FetchBaseQueryError));
    } finally {
      setEditingId(null);
      setStatus(null);
      reset();
    }
  });

  if (playingFields.length === 0 && !status) {
    return (
      <PlayingFieldsEmpty
        onAddClick={() => setStatus(PlayingFieldsTableStatuses.adding)}
        sx={{ my: 5 }}
      />
    );
  }

  return (
    <>
      <TableContainer sx={{ mb: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              {handleCheckField ? <TableCell /> : null}
              <TableCell>#</TableCell>
              <TableCell padding="none">{t('schedule.name')}</TableCell>
              <TableCell>{t('schedule.fieldAddress')}</TableCell>
              <TableCell padding="none">{t('schedule.assignedStatisticians')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {playingFields.map((item, index) => (item.id === editingId ? (
              <PlayingFieldEditableRow
                key={`PlayingFieldEditableRow_${item.id}`}
                control={control}
                number={index + 1}
                onDelete={() => handleDeleteRow(item.id)}
                onSave={handleSaveRow}
                disabled={isDeleting || isSubmitting}
                isCheckEnabled={!!handleCheckField}
                isChecked={checkedFields?.includes(item.id)}
              />
            ) : (
              <PlayingFieldRow
                key={`PlayingFieldRow_${item.id}`}
                data={item}
                number={index + 1}
                onEdit={() => handleEditRow(item)}
                disabled={!!status}
                handleCheck={handleCheckField ? () => handleCheckField(item.id) : undefined}
                isChecked={checkedFields?.includes(item.id)}
              />
            )))}
            {status === PlayingFieldsTableStatuses.adding && (
              <PlayingFieldEditableRow
                isCheckEnabled={!!handleCheckField}
                control={control}
                number={playingFields.length + 1}
                onDelete={handleDeleteUnsavedRow}
                onSave={handleSaveRow}
                disabled={isSubmitting}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        disabled={!!status}
        onClick={() => setStatus(PlayingFieldsTableStatuses.adding)}
        variant="outlined"
      >
        {t('schedule.addField')}
      </Button>
    </>
  );
}

export default PlayingFieldsTable;
