import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ViewListIcon from '@mui/icons-material/ViewList';
import GroupsIcon from '@mui/icons-material/Groups';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import InfoCard from '../../../components/InfoCard/InfoCard';
import Pools from './Pools';
import PoolItem from './PoolItem';
import PreferredPlayingFields from './PreferredPlayingFields';
import GameSettings from './GameSettings';
import GhoustIcon from '../../../assets/images/ghoust.svg';
import { createPPPools } from '../../../utils/poolPlays';
import { TeamTypes } from '../../../store/slices/poolPlays/interfaces/IPoolPlayDivisionRules';
import { GamesTypes, ScheduleTypes } from '../../../constants/general';
import { GameTeamTypes } from '../../../store/slices/schedule/interfaces/IScheduleBracket';
import type IGameSettingsDialog from '../interfaces/IGameSettingsDialog';

function GameSettingsDialog({
  gameSettingsControl,
  divisionRules: { teams, distributionTeamsToPools, poolNextFreeName },
  game7x7Settings,
  playingFields,
  onCreateGameSetting,
  onClose,
  isOpen,
  isLoading,
  divisionsGameSetup,
  fullInfoId,
}: IGameSettingsDialog) {
  const { t } = useTranslation();
  const {
    field: poolsField,
  } = useController({ name: 'pools', control: gameSettingsControl });
  const {
    field: gamesPerTeamField,
    fieldState: gamesPerTeamFieldState,
  } = useController({ name: 'gamesPerTeam', control: gameSettingsControl });
  const {
    field: game7x7SettingField,
  } = useController({ name: 'game7x7Setting', control: gameSettingsControl });
  const {
    field: preferredPlayingField,
  } = useController({ name: 'preferredPlayingFields', control: gameSettingsControl });
  const {
    field: poolPlayDivisionId,
  } = useController({ name: 'divisionId', control: gameSettingsControl });

  const poolPlayDivisionName = `${divisionsGameSetup.find(({ id }) => id === poolPlayDivisionId.value)?.name || ''}`;

  const gamesPerTeamOptions = useMemo(
    () => ([...distributionTeamsToPools.withGhost, ...distributionTeamsToPools.withoutGhost]),
    [distributionTeamsToPools.withGhost, distributionTeamsToPools.withoutGhost],
  );

  const poolPlayGameSettings = useMemo(
    () => game7x7Settings.filter(({ gameType }) => gameType === GamesTypes.POOL_PLAY),
    [game7x7Settings],
  );

  const handleGamesPerTeamChange = useCallback((value: number) => {
    const find = gamesPerTeamOptions.find((item) => item.gamesPerTeam === value);

    if (find) {
      const filteredTeams = find.type === TeamTypes.REAL
        ? teams.filter(({ teamType }) => teamType === find.type)
        : teams;
      const generatedPools = createPPPools(filteredTeams, find.teamsInPool, poolNextFreeName);
      gamesPerTeamField.onChange(find);
      poolsField.onChange(generatedPools);
    }
  }, [gamesPerTeamField, gamesPerTeamOptions, poolNextFreeName, poolsField, teams]);

  useEffect(() => {
    if (poolsField.value && !Object.keys(poolsField.value).length) {
      handleGamesPerTeamChange(gamesPerTeamField.value?.gamesPerTeam);
    }
  }, [gamesPerTeamField, handleGamesPerTeamChange, poolsField]);

  return (
    <Dialog
      open={isOpen}
      sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: 824 } }}
      PaperProps={{ elevation: 0 }}
    >
      <DialogTitle component="h2" variant="h4" fontSize={32}>
        {t('schedule.poolPlayGameSettings', { divisionName: poolPlayDivisionName })}
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Stack alignItems="center" my={8}>
            <CircularProgress size={45} thickness={1} color="secondary" />
          </Stack>
        ) : (
          <>
            <GameSettings
              label={t('schedule.statKeeperGameSettings')}
              game7x7Settings={poolPlayGameSettings}
              game7x7SettingField={game7x7SettingField}
            />

            <Divider sx={{ my: 4 }} />

            <PreferredPlayingFields
              title={`${t('schedule.preferredFields')}`}
              fields={playingFields}
              checkedFields={preferredPlayingField.value}
              onChange={preferredPlayingField.onChange}
              type={ScheduleTypes.POOL_PLAY}
              fullInfoId={fullInfoId}
            />

            <Divider sx={{ my: 4 }} />

            <Box>
              <Typography variant="h6" mb={4}>
                {t('schedule.gamesPerTeam')}
              </Typography>

              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth error={!!gamesPerTeamFieldState.error}>
                    <InputLabel htmlFor="outlined-poolPlayGamesPerTeam" shrink>
                      {t('schedule.poolPlayGamesPerTeam')}
                    </InputLabel>
                    <Select
                      value={gamesPerTeamField.value?.gamesPerTeam || ''}
                      name="poolPlayGamesPerTeam"
                      variant="outlined"
                      id="outlined-poolPlayGamesPerTeam"
                      label={t('schedule.poolPlayGamesPerTeam')}
                      onChange={({ target }) => handleGamesPerTeamChange(target.value as number)}
                      notched
                      displayEmpty
                      fullWidth
                    >
                      {gamesPerTeamOptions.map((item) => (
                        <MenuItem key={`GamesPerTeamOption_${item.gamesPerTeam}`} value={item.gamesPerTeam}>
                          {item.gamesPerTeam}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid container item spacing={3}>
                  <Grid item sm={6} xs={12}>
                    <InfoCard
                      icon={<GroupsIcon />}
                      desc={teams.filter(({ teamType }) => teamType === TeamTypes.REAL).length.toString() || '-'}
                      label={t('schedule.registeredTeamsInDivision')}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <InfoCard
                      icon={<ViewListIcon />}
                      desc={gamesPerTeamField.value?.countPools?.toString() || '-'}
                      label={t('schedule.generatedPoolsInDivision')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Divider sx={{ my: 4 }} />

            <Pools
              items={poolsField.value || {}}
              onChange={(items) => poolsField.onChange(items)}
              renderItem={(item, isError) => (
                <PoolItem id={item.id} isError={isError}>
                  <Avatar
                    src={item.teamType === GameTeamTypes.GHOST as string ? GhoustIcon : item?.profilePhoto}
                    sx={{ width: 28, height: 28 }}
                  />
                  <span>{item.name}</span>
                </PoolItem>
              )}
            />
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ gap: 1 }}>
        <Button color="secondary" onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          onClick={onCreateGameSetting}
        >
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default GameSettingsDialog;
