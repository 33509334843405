import { useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useTranslation } from 'react-i18next';

import SingleEventAnalyticsView from './SingleEventAnalyticsView';
import { useAppSelector } from '../../store/hooks/useApp';
import { selectUserOrg } from '../../store/slices/user/selectors';
import {
  useGetEventEarnedAmountQuery,
  useGetEventMembersCountQuery,
  useGetEventRevenueQuery,
  useGetRefundsQuery,
  useGetSpectatorsByPassQuery,
  useGetSpectatorsByTypeQuery,
  useGetSpectatorsEarnedQuery,
  useGetTeamsEarnedByDevisionQuery,
  useGetTeamsPerDevisionQuery,
} from '../../store/slices/analytics/apis/analyticsApi';
import {
  selectEventEarnings,
  selectEventMembersCount,
  selectEventRevenue,
  selectSpectatorByPass,
  selectSpectatorByType,
  selectSpectatorsEarned,
  selectTeamsEarned,
} from '../../store/slices/analytics/selectors';
import { checkAdmin, generateDashboardPieData, generateSpectatorTypePieData } from '../../utils/helpers';
import { useGetDivisionsListQuery } from '../../store/slices/divisions/apis/divisionsApi';
import { selectEventDivisions } from '../../store/slices/divisions/selectors';
import AppRoutes from '../../constants/AppRoutes';

function SingleEventAnalytics() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const organization = useAppSelector(selectUserOrg);

  const trueAdmin = checkAdmin(organization?.roles || []);

  const { eventId } = useParams();
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (!trueAdmin) {
      navigate(AppRoutes.events);
    }
  }, [navigate, trueAdmin]);

  const fetchQuery = organization?.id && eventId ? {
    orgId: organization.id,
    eventId: +eventId,
  } : skipToken;

  const { eventEarnings } = useGetEventEarnedAmountQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, eventEarnings: selectEventEarnings(result.data) }),
    },
  );
  const { teamsPerDevision } = useGetTeamsPerDevisionQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, teamsPerDevision: result.data?.data || [] }),
    },
  );
  const { eventRevenue } = useGetEventRevenueQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, eventRevenue: selectEventRevenue(result.data) }),
    },
  );
  const { membersCount } = useGetEventMembersCountQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, membersCount: selectEventMembersCount(result.data) }),
    },
  );
  const { eventDivisions } = useGetDivisionsListQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, eventDivisions: selectEventDivisions(result.data) }),
    },
  );
  const { teamsEarned } = useGetTeamsEarnedByDevisionQuery(
    organization?.id && eventId ? {
      orgId: organization.id,
      eventId: +eventId,
      eventDivisionId: tabValue ? `${tabValue}` : '',
    } : skipToken,
    {
      selectFromResult: (result) => ({ ...result, teamsEarned: selectTeamsEarned(result.data) }),
    },
  );
  const { spectatorsEarned } = useGetSpectatorsEarnedQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, spectatorsEarned: selectSpectatorsEarned(result.data) }),
    },
  );
  const { refunds } = useGetRefundsQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, refunds: result.data?.data || [] }),
    },
  );
  const { spectatorsByType } = useGetSpectatorsByTypeQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, spectatorsByType: selectSpectatorByType(result.data) }),
    },
  );
  const { spectatorsByPass } = useGetSpectatorsByPassQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, spectatorsByPass: selectSpectatorByPass(result.data) }),
    },
  );

  const pieEarnedData = useMemo(() => generateDashboardPieData({
    totalAmount: eventEarnings.total,
    teamsAmount: eventEarnings.earnings[0]?.totalAmount,
    spectatorsAmount: eventEarnings.earnings[1]?.totalAmount,
    verificationsAmount: eventEarnings.earnings[2]?.totalAmount,
  }), [eventEarnings]);

  const divisionsTabsList = useMemo(() => eventDivisions
    .reduce((acc, division) => [...acc, { value: division.id, name: division.name }], [{
      value: 0,
      name: t('events.all'),
    }]), [eventDivisions, t]);

  const pieSpectatorsTypeData = useMemo(() => generateSpectatorTypePieData(spectatorsByType), [spectatorsByType]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <SingleEventAnalyticsView
      earnings={eventEarnings}
      pieEarnedData={pieEarnedData}
      teamsCapacity={teamsPerDevision}
      eventRevenue={eventRevenue}
      membersCount={membersCount}
      divisions={divisionsTabsList}
      tabValue={tabValue}
      onTabChange={handleTabChange}
      teamsEarnedData={teamsEarned}
      spectatorsEarnedData={spectatorsEarned}
      refundsData={refunds}
      pieSpectatorsTypeData={pieSpectatorsTypeData}
      pieSpectatorsPassData={spectatorsByPass}
    />
  );
}

export default SingleEventAnalytics;
