import Box from '@mui/material/Box';

import Round from './Round';
import { RoundHalf } from './interfaces/IRound';
import useStyles from './bracketStyle-tss';
import type ITournamentBracket from './interfaces/ITournamentBracket';
import type { IRoundAttr } from './interfaces/IRound';

const getRoundAttr = (roundIndex: number, roundsCount: number): IRoundAttr => {
  const middle = Math.floor(roundsCount / 2);
  let half = RoundHalf.middle;

  if (roundIndex < middle) {
    half = RoundHalf.first;
  }

  if (roundIndex > middle) {
    half = RoundHalf.second;
  }

  return { half };
};

function TournamentBracket({ data, timezone }: ITournamentBracket) {
  const { classes } = useStyles();

  return (
    <Box className={classes.bracket}>
      {data.map((round, index) => (round.round.type !== 'THIRD_PLACE_FINAL' ? (
        <Round
          key={`TournamentBracketRound_${index.toString()}`}
          index={index}
          round={round.round}
          games={round.games}
          attr={getRoundAttr(index, data.length)}
          timezone={timezone}
        />
      ) : null))}
    </Box>
  );
}

export default TournamentBracket;
