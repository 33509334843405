import { useTranslation } from 'react-i18next';
import {
  ResponsiveContainer, PieChart, Pie, Cell,
} from 'recharts';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import PieLabel from './PieLabel';
import EmptyData from '../EmptyData/EmptyData';
import type { IPieWidget } from './interfaces/IPieWidget';

function PieWidget({
  title,
  pieData,
  dataKey = 'value',
}: IPieWidget) {
  const { t } = useTranslation();

  const isShowData = pieData.reduce((acc, item) => item.value + acc, 0) > 0;

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h5" lineHeight="24px" mb={3}>
        {title}
      </Typography>
      {isShowData ? (
        <ResponsiveContainer width="100%" height={339}>
          <PieChart width={339} height={339}>
            <Pie
              data={pieData}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={PieLabel}
              outerRadius="100%"
              fill="white"
              dataKey={dataKey}
            >
              {pieData.map((entry, index) => (
                <Cell
                  key={`cell-${entry.name}`}
                  fill={pieData[index % pieData.length].color}
                  style={{
                    outline: 'none',
                  }}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      ) : <EmptyData description={t('common.noData')} />}
      {isShowData ? (
        <Grid
          columnSpacing={5}
          rowSpacing={3}
          container
          mt={1}
        >
          {pieData.map((item) => (
            <Grid
              item
              xs={12}
              md={6}
              key={item.label}
            >
              <Typography
                variant="body2"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  '&:before': {
                    content: '""',
                    display: 'block',
                    width: '10px',
                    height: '10px',
                    background: item.color,
                    borderRadius: '50%',
                  },
                }}
              >
                {t(item.label)}
              </Typography>
            </Grid>
          ))}
        </Grid>
      ) : null}
    </Paper>
  );
}

export default PieWidget;
