import { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import { DATE_RANGE, DEFAULT_DATE_FORMAT, TIME_STEPS_FOR_TIME_PICKER } from '../../constants/general';
import { parseStringOrDateToDateTime } from '../../utils/dates';
import { getFullNameByUser } from '../../utils/helpers';
import { gameDetailsSchema } from '../../utils/validators';
import type { TGameDetailsSchema } from '../../utils/validators';
import type IGameDetailsDialog from './interfaces/IGameDetailsDialog';

const useStyles = makeStyles()({
  select: {
    display: 'flex',
  },
});

function GameDetailsDialog({
  open, onClose, onConfirm, fields, game, statisticians, isEditable, selectedDate = '',
}: IGameDetailsDialog) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const {
    control, handleSubmit, setValue, clearErrors,
  } = useForm<TGameDetailsSchema>({
    resolver: yupResolver<TGameDetailsSchema>(gameDetailsSchema),
    mode: 'onBlur',
    defaultValues: {
      resourceId: null,
      statisticianId: null,
      startAt: null,
      isUnscheduled: true,
    },
  });

  const participants = useMemo(() => [game?.divisionName, game?.roundName].filter(Boolean).join(' - '), [game]);

  const onSave = handleSubmit((data) => {
    if (game && onConfirm) {
      onConfirm({
        ...game,
        ...data,
        startAt: data.startAt?.toISO() || undefined,
      });
    }
  });

  useEffect(() => {
    setValue('resourceId', game?.resourceId);
    setValue('statisticianId', game?.statisticianId);
    if (game?.startAt) {
      setValue('startAt', parseStringOrDateToDateTime(game.startAt));
    } else {
      setValue('startAt', parseStringOrDateToDateTime(selectedDate));
    }
    setValue('isUnscheduled', !game?.startAt);
  }, [setValue, game, selectedDate]);

  useEffect(
    () => clearErrors(),
    [open],
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: 600, backgroundColor: '#292929' } }}
    >
      <DialogTitle variant="h4" fontWeight="700">
        {t('schedule.gameDetails')}
      </DialogTitle>
      <DialogContent>
        <form>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {t('schedule.participants', { division: participants })}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="teamA-label">
                    {t('schedule.team')}
                  </InputLabel>
                  <Select
                    id="teamA"
                    labelId="teamA-label"
                    label={t('schedule.team')}
                    value={`${game?.teamA?.id}`}
                    disabled
                    className={classes.select}
                    defaultValue=""
                  >
                    <MenuItem value={game?.teamA?.id}>
                      {game?.teamA?.name}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="teamB-label">
                    {t('schedule.team')}
                  </InputLabel>
                  <Select
                    id="teamB"
                    labelId="teamB-label"
                    label={t('schedule.team')}
                    value={`${game?.teamB?.id}`}
                    disabled
                    className={classes.select}
                    defaultValue=""
                  >
                    <MenuItem value={game?.teamB?.id}>
                      {game?.teamB?.name}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" fontWeight="700">
                  Game Date and Time
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="startAt"
                  control={control}
                  render={({
                    field: { value = null, onChange, onBlur },
                    fieldState: { error: fieldError },
                  }) => (
                    <DatePicker
                      label={t('common.date')}
                      value={value}
                      onClose={onBlur}
                      onChange={onChange}
                      disabled={!isEditable}
                      slotProps={{
                        textField: {
                          error: !!fieldError,
                          helperText: fieldError?.message,
                          fullWidth: true,
                          onBlur,
                        },
                      }}
                      format={DEFAULT_DATE_FORMAT}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="startAt"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error: fieldError },
                  }) => (
                    <TimePicker
                      label={t('events.time')}
                      value={value}
                      onChange={onChange}
                      onClose={onBlur}
                      timeSteps={TIME_STEPS_FOR_TIME_PICKER}
                      disabled={!isEditable}
                      slotProps={{
                        textField: {
                          error: !!fieldError,
                          helperText: fieldError?.type !== DATE_RANGE ? fieldError?.message : '',
                          fullWidth: true,
                          onBlur,
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="h6" fontWeight="700">
                  {t('schedule.field')}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="h6" fontWeight="700">
                  {t('schedule.statistician')}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="resourceId"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error: fieldError },
                  }) => (
                    <FormControl error={!!fieldError} fullWidth>
                      <InputLabel htmlFor="field-label" shrink>
                        {t('schedule.field')}
                      </InputLabel>
                      <Select
                        id="field"
                        labelId="field-label"
                        label={t('schedule.field')}
                        value={value}
                        onChange={onChange}
                        onClose={onBlur}
                        className={classes.select}
                        disabled={!isEditable}
                        notched
                        displayEmpty
                      >
                        <MenuItem value={0}>
                          <em>{t('common.none')}</em>
                        </MenuItem>
                        {fields.map(({ id, name }) => (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                      {fieldError?.message ? <FormHelperText>{fieldError.message}</FormHelperText> : null}
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="statisticianId"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error: fieldError },
                  }) => (
                    <FormControl error={!!fieldError} fullWidth>
                      <InputLabel id="statistician-label">
                        {t('schedule.statistician')}
                      </InputLabel>
                      <Select
                        id="statistician"
                        labelId="statistician-label"
                        label={t('schedule.statistician')}
                        value={value || ''}
                        onChange={onChange}
                        onClose={onBlur}
                        className={classes.select}
                        disabled={!isEditable}
                      >
                        {statisticians?.map(({ id, user }) => (
                          <MenuItem key={id} value={id}>
                            {getFullNameByUser(user)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>

          <DialogActions sx={{ p: 'unset', mt: 4.25 }}>
            {isEditable ? (
              <>
                <Button onClick={onClose} sx={{ color: 'text.disabled' }}>
                  {t('common.cancel')}
                </Button>
                <Button variant="contained" onClick={onSave}>
                  {t('common.saveButton')}
                </Button>
              </>
            ) : (
              <Button onClick={onClose} variant="contained">
                {t('common.cancel')}
              </Button>
            )}
          </DialogActions>
        </form>
      </DialogContent>

    </Dialog>
  );
}

export default GameDetailsDialog;
