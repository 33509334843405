import mainApi from '../../../apis/mainApi';
import { ORG_ME_MEMBERSHIP } from '../../../../constants/requestUrls';
import type IBaseQueryResWithPagination from '../../../types/IBaseQueryResWithPagination';
import type IOrganization from '../interfaces/IOrganization';

const userApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizationByMe: builder.query<IBaseQueryResWithPagination<IOrganization[]>, null>({
      query: () => ({
        url: ORG_ME_MEMBERSHIP,
        method: 'GET',
      }),
      transformResponse: (data: IBaseQueryResWithPagination<IOrganization[]>) => ({
        ...data,
        data: data.data.map((item) => ({
          ...item,
          profilePhoto: item.profilePhoto ? `${item.profilePhoto}` : null,
        })),
      }),
    }),
  }),
});

export const { useGetOrganizationByMeQuery } = userApi;

export default userApi;
