import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Filters from '../../pages/SingleEventPoolPlaySchedule/components/Filters';
import GameCalendar from '../GameCalendar/GameCalendar';
import GameDetailsDialog from '../GameDetailsDialog/GameDetailsDialog';
import FieldsDialog from '../../pages/SingleEventPoolPlaySchedule/components/FieldsDialog';
import BuyGamesListDialog from '../../pages/SingleEventPoolPlaySchedule/components/BuyGamesListDialog';
import type { IScheduleCalendar } from './interfaces/IScheduleCalendar';

function ScheduleCalendar({
  control, games, unscheduledList, fields, filterData, buyGames,
  timeFrames, selectedGame, isOpenEditFields,
  onEditFieldsClick, toggleGameDetailsDialog, timezone,
  onAddFromUnscheduled, onUpdateGame, onSelectGame, heading,
  isEditable = true, toggleBuyGamesDialog, isOpenBuyGames, fullInfoId,
  selectedDate, type,
}: IScheduleCalendar) {
  const { t } = useTranslation();
  const date = useWatch({ control, name: 'date' });
  const [noScroll, setNoScroll] = useState<boolean>(false);

  useEffect(() => {
    setNoScroll(false);
  }, [date]);

  const handlePreventScroll = () => setNoScroll(true);

  return (
    <>
      <Stack mb={3}>
        {heading ? (
          <Typography variant="h6" fontWeight={700} mb={3}>
            {heading}
          </Typography>
        ) : null}

        <Stack direction="row" mb={2} alignItems="center" onClick={() => setNoScroll(true)}>
          <Filters
            control={control}
            divisions={filterData?.eventDivisions}
            pools={filterData?.eventDivGamePools}
            teams={filterData?.teams}
            statisticians={filterData?.eventGamesStatisticians}
            dates={filterData?.dates}
          />

          <Box>
            <Button variant="outlined" sx={{ whiteSpace: 'nowrap', ml: 1 }} onClick={onEditFieldsClick}>
              {t(isEditable ? 'schedule.editFields' : 'schedule.editSchedule')}
            </Button>
          </Box>
        </Stack>

        <GameCalendar
          timezone={timezone}
          games={games}
          unscheduledList={unscheduledList}
          fields={fields}
          onAddFromUnscheduled={onAddFromUnscheduled}
          onMoveGame={onUpdateGame}
          onSelectGame={onSelectGame}
          isEditable={isEditable}
          onOpenBuyGames={toggleBuyGamesDialog}
          timeFrames={timeFrames || []}
          date={date}
          noScroll={noScroll}
          onPreventScroll={handlePreventScroll}
        />
      </Stack>

      <GameDetailsDialog
        game={selectedGame}
        open={!!selectedGame}
        onConfirm={onUpdateGame}
        onClose={toggleGameDetailsDialog}
        fields={fields}
        statisticians={filterData?.eventGamesStatisticians}
        isEditable={isEditable}
        selectedDate={selectedDate}
      />

      <FieldsDialog
        open={!!isOpenEditFields}
        onClose={onEditFieldsClick}
        fields={fields}
        fullInfoId={fullInfoId}
        type={type}
      />

      <BuyGamesListDialog
        open={isOpenBuyGames}
        onClose={toggleBuyGamesDialog}
        games={buyGames}
      />
    </>
  );
}

export default ScheduleCalendar;
