import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import PlayOffScheduleView from './PlayOffScheduleView';
import useScheduleBracket from '../../hooks/useScheduleBracket';
import {
  useGetPOFullInfoQuery,
  useLazyCheckValidityPOScheduleQuery,
  usePublishPOScheduleMutation,
  useSetupGamesForPOScheduleMutation,
} from '../../store/slices/playOff/apis/playOffApi';
import { getErrorMessage, logisticsAndGameSetupCheckValidity } from '../../utils/helpers';
import AppRoutes from '../../constants/AppRoutes';
import { ScheduleTypes, ScheduleView } from '../../constants/general';
import type ICreateEventContext from '../../layout/CreateEventLayout/interfaces/ICreateEventContext';

function PlayOffSchedule() {
  const { eventId, orgId } = useOutletContext<ICreateEventContext>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const initialView = searchParams.get('view') as ScheduleView | null;

  const [scheduleView, setScheduleView] = useState<ScheduleView>(initialView || ScheduleView.bracket);

  const [publishPOSchedule, { isLoading: isPublishing }] = usePublishPOScheduleMutation();
  const [regeneratePOSchedule, { isLoading: isRegeneration }] = useSetupGamesForPOScheduleMutation();
  const [checkValidityPOSchedule, { isLoading: isCheckValidityLoading }] = useLazyCheckValidityPOScheduleQuery();

  const { data: playOffRules } = useGetPOFullInfoQuery({ orgId, eventId: +eventId });

  const {
    tabValue,
    statisticianName,
    teamName,
    teams,
    statisticians,
    playingFields,
    tournamentBracket,
    divisionsTabs,
    timezone,
    handleBack,
    handleTabChange,
    handleStatisticianChange,
    handleTeamChange,
    handleStatisticianClear,
    handleTeamClear,
    handleEditFieldsOpenChange,
    isOpenEditFields,
    isLoading,
  } = useScheduleBracket({
    eventId: +eventId,
    orgId,
  });

  const handleScheduleViewChange = (view: ScheduleView) => {
    setScheduleView(view);
    searchParams.set('view', view);
    setSearchParams(searchParams, { replace: true });
  };

  const handleValidity = async () => {
    const body = { orgId, eventId: +eventId };

    const checkValidity = await checkValidityPOSchedule(body).unwrap()
      .then((res) => res.data)
      .catch((error) => {
        toast.error(getErrorMessage(error));
      });

    if (checkValidity) {
      logisticsAndGameSetupCheckValidity(checkValidity, t);
    }
  };

  const handlePOSchedulePublish = async () => {
    const body = { orgId, eventId: +eventId };
    const nextPage = AppRoutes.singleEventSchedule.replace(':eventId', eventId);
    const pathname = `${nextPage}?tab=${ScheduleTypes.PLAY_OFF}&view=${scheduleView}`;

    await handleValidity();

    if (playOffRules?.data.published) {
      navigate(pathname);
    } else {
      publishPOSchedule(body).unwrap()
        .then(() => navigate(pathname))
        .catch((error) => toast.error(getErrorMessage(error)));
    }
  };

  const handlePOScheduleRegenerate = async () => {
    await regeneratePOSchedule({ orgId, eventId: +eventId, playOffRulesId: playOffRules?.data.id! }).unwrap()
      .catch((error) => {
        toast.error(getErrorMessage(error));
      });
    await handleValidity();
  };

  return (
    <PlayOffScheduleView
      timezone={timezone}
      scheduleView={scheduleView}
      tournamentBracketData={tournamentBracket}
      divisionsTabs={divisionsTabs}
      tabValue={tabValue}
      statisticianName={statisticianName}
      teamName={teamName}
      teams={teams}
      statisticians={statisticians}
      playingFields={playingFields}
      onRegenerate={handlePOScheduleRegenerate}
      onViewChange={handleScheduleViewChange}
      onBack={handleBack}
      onSave={handlePOSchedulePublish}
      onTabChange={handleTabChange}
      onStatisticianChange={handleStatisticianChange}
      onTeamChange={handleTeamChange}
      onStatisticianClear={handleStatisticianClear}
      onTeamClear={handleTeamClear}
      onEditFieldsOpenChange={handleEditFieldsOpenChange}
      isOpenEditFields={isOpenEditFields}
      isLoading={isLoading}
      isPublishing={isCheckValidityLoading || isPublishing}
      isRegeneration={isCheckValidityLoading || isRegeneration}
      isSchedulePublished={playOffRules?.data.published}
      fullInfoId={playOffRules?.data?.id}
    />
  );
}

export default PlayOffSchedule;
