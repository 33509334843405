import type { EventProps } from 'react-big-calendar';

import GameItem from './GameItem';
import type { ICalendarEvent } from './interfaces/ICalendarEvent';

function GameCalendarEvent({
  event: {
    error, division, roundName, duration,
  }, title,
}: EventProps<ICalendarEvent>) {
  return (
    <GameItem
      error={error}
      division={division}
      roundName={roundName}
      title={title}
      duration={duration}
    />
  );
}

export default GameCalendarEvent;
