import { RADIAN } from '../../constants/general';
import type { IPieLabel } from './interfaces/IPieLabel';

function PieLabel({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  payload: { payload },
}: IPieLabel) {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const textContent = payload?.isText
    ? `${payload.value} (${(percent * 100).toFixed(0)}%)`
    : `$${payload.value} (${(percent * 100).toFixed(0)}%)`;

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontFamily: 'Maven Pro',
        fontWeight: '500',
      }}
    >
      {payload.value !== 0 ? textContent : null}
    </text>
  );
}

export default PieLabel;
