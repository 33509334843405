import { makeStyles } from 'tss-react/mui';
import { darken } from '@mui/material/styles';

export type TNestedSelectors =
  | 'listItem'
  | 'match'
  | 'win'
  | 'lose'
  | 'thirdPlace'
  | 'error';

const useStyles = makeStyles<void, TNestedSelectors>()(
  (theme, params, classes) => ({
    bracket: {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyItems: 'center',
      minHeight: '550px',
      overflow: 'auto',

      '&::-webkit-scrollbar': {
        width: 6,
        height: 6,
        padding: '2px 0',
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(255,255,255,.5)',
        borderRadius: 100,
      },

      '&::-webkit-scrollbar-track': {
        backgroundColor: 'rgba(255,255,255,.1)',
        borderRadius: 100,
        outline: '1px solid rgba(255,255,255,.1)',
      },
    },
    round: {
      maxWidth: 220,
      '&[data-half=first], &[data-half=second]': {
        '&:not([data-type=SEMI_FINAL])': {
          [`& .${classes.listItem}`]: {
            '&:before, &:after': {
              position: 'absolute',
              content: '""',
              display: 'block',
              width: 18,
              height: '50%',
            },
          },
        },
      },
      '&[data-half=first]': {
        '&:not([data-type=SEMI_FINAL])': {
          [`& .${classes.listItem}`]: {
            '&:after': {
              right: 0,
              borderRight: `2px solid ${theme.palette.secondary.main}`,
            },
            '&:nth-of-type(odd):after': {
              top: '50%',
              borderTop: `2px solid ${theme.palette.secondary.main}`,
              transform: 'translateY(-1px)',
            },
            '&:nth-of-type(even):after': {
              bottom: '50%',
              borderBottom: `2px solid ${theme.palette.secondary.main}`,
              transform: 'translateY(1px)',
            },
          },
        },
        '&:not(:first-of-type)': {
          [`& .${classes.match}`]: {
            '&:before, &:after': {
              left: 0,
              borderLeft: `2px solid ${theme.palette.secondary.main}`,
              position: 'absolute',
              zIndex: 1,
              content: '""',
              display: 'block',
              width: 20,
              height: '10%',
            },
            '&:before': {
              bottom: '50%',
              borderBottom: `2px solid ${theme.palette.secondary.main}`,
              transform: 'translate(-100%, 1px)',
            },
            '&:after': {
              top: '50%',
              borderTop: `2px solid ${theme.palette.secondary.main}`,
              transform: 'translate(-100%, -1px)',
            },
          },
        },
      },
      '&[data-half=second]': {
        '&:not([data-type=SEMI_FINAL])': {
          [`& .${classes.listItem}`]: {
            '&:before': {
              left: 0,
              borderLeft: `2px solid ${theme.palette.secondary.main}`,
            },
            '&:nth-of-type(odd):before': {
              top: '50%',
              borderTop: `2px solid ${theme.palette.secondary.main}`,
              transform: 'translateY(-1px)',
            },
            '&:nth-of-type(even):before': {
              bottom: '50%',
              borderBottom: `2px solid ${theme.palette.secondary.main}`,
              transform: 'translateY(1px)',
            },
          },
        },
        '&:not(:last-of-type)': {
          [`& .${classes.match}`]: {
            '&:before, &:after': {
              right: 0,
              borderRight: `2px solid ${theme.palette.secondary.main}`,
              position: 'absolute',
              zIndex: 1,
              content: '""',
              display: 'block',
              width: 20,
              height: '10%',
            },
            '&:before': {
              bottom: '50%',
              borderBottom: `2px solid ${theme.palette.secondary.main}`,
              transform: 'translate(100%, 1px)',
            },
            '&:after': {
              top: '50%',
              borderTop: `2px solid ${theme.palette.secondary.main}`,
              transform: 'translate(100%, -1px)',
            },
          },
        },
      },
      '&[data-half=middle]:not(:first-of-type)': {
        [`& .${classes.listItem}:not(.${classes.thirdPlace})`]: {
          '&:before, &:after': {
            content: '""',
            backgroundColor: theme.palette.secondary.main,
            position: 'absolute',
            height: 2,
            width: 40,
            top: '50%',
            transform: 'translate(0, -1px)',
          },
          '&:before': {
            left: -20,
          },
          '&:after': {
            right: -20,
          },
        },
      },
    },
    title: {
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
    list: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'center',
      flex: 1,
      padding: '16px 0',
      position: 'relative',
    },
    listItem: {
      width: '100%',
      display: 'flex',
      flex: '0 1 auto',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'flex-start',
      position: 'relative',
      padding: '16px 20px',
    },
    match: {
      flex: '0 1 auto',
      position: 'relative',
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 2,
      verticalAlign: 'middle',
      width: '100%',
      minWidth: 180,
      backgroundColor: darken(theme.palette.secondary.main, 0.5),

      [`&.${classes.error}`]: {
        backgroundColor: theme.palette.background.error,
      },
    },
    matchInfo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 2,
      width: '100%',
      fontSize: 10,
      fontWeight: 400,
    },
    team: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: 2,
      gap: 8,
    },
    teamName: {
      flex: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 12,
    },
    teamScore: {
      width: 24,
      height: 24,
      padding: '8px 0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'transparent',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 1,

      [`&.${classes.win}`]: {
        backgroundColor: theme.palette.success.main,
      },

      [`&.${classes.lose}`]: {
        backgroundColor: theme.palette.error.main,
      },
    },
    error: {},
    win: {},
    lose: {},
    thirdPlace: {
      position: 'absolute',
      top: 'calc(50% + 110px)',
    },
  }),
);

export default useStyles;
