import { useNavigate, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';

import SingleEventSocialView from './SingleEventSocialView';
import { useGetEventQuery, useUpdateEventMutation } from '../../store/slices/events/apis/eventsApi';
import { selectCreatedEvent } from '../../store/slices/events/selectors';
import { editSocialMediaSchema } from '../../utils/validators';
import { getErrorMessage } from '../../utils/helpers';
import type ICreateEventContext from '../../layout/CreateEventLayout/interfaces/ICreateEventContext';
import type { ISocialNetworksFields } from '../../components/CreateEventForm/interfaces/ICreateEventForm';

function SingleEventSocial(): React.ReactElement {
  const {
    eventId,
    orgId,
    isFetch,
  } = useOutletContext<ICreateEventContext>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fetchQuery = isFetch ? { orgId, eventId: +eventId } : skipToken;

  const { event } = useGetEventQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, event: selectCreatedEvent(result.data) }),
    },
  );

  const [updateEvent] = useUpdateEventMutation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<ISocialNetworksFields>({
    resolver: yupResolver<ISocialNetworksFields>(editSocialMediaSchema),
    mode: 'onBlur',
    defaultValues: {
      socialMedia: event?.socialMedia || {
        twitter: '',
        facebook: '',
        instagram: '',
        tiktok: '',
        hudl: '',
        youtube: '',
      },
    },
  });

  const handleCancel = () => {
    navigate(-1);
    reset();
  };

  const handleCreateSubmit = handleSubmit(async (values) => {
    const data = {
      orgId,
      eventId: +eventId,
      socialMedia: values.socialMedia,
    };

    const response = await updateEvent(data);

    if (!('error' in response)) {
      handleCancel();
      toast.success(t('events.changesSaved'), { position: toast.POSITION.TOP_RIGHT });
    } else {
      toast.error(getErrorMessage(response.error), { position: toast.POSITION.TOP_RIGHT });
    }
  });

  useEffect(() => {
    if (event) {
      reset({ socialMedia: event.socialMedia });
    }
  }, [reset, event]);

  return (
    <SingleEventSocialView
      control={control}
      eventName={event.nameWithTagline}
      onCancelEdit={handleCancel}
      onCreateSubmit={handleCreateSubmit}
      isSubmitting={isSubmitting}
    />
  );
}

export default SingleEventSocial;
