import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';
import CircularProgress from '@mui/material/CircularProgress';

import CalendarView from './components/CalendarView';
import BaseDialog from '../../components/BaseDialog/BaseDialog';
import PlayingFieldsTable from '../LogisticsAndGameSetup/components/PlayingFieldsTable';
import ScheduleTabs from './components/ScheduleTabs';
import ScheduleBracket from '../../components/ScheduleBracket/ScheduleBracket';
import { ScheduleView, ScheduleTypes } from '../../constants/general';
import type IPlayOffScheduleView from './interfaces/IPlayOffScheduleView';

function PlayOffScheduleView({
  scheduleView,
  tournamentBracketData,
  divisionsTabs,
  tabValue,
  statisticianName,
  teamName,
  teams,
  statisticians,
  playingFields,
  timezone,
  onRegenerate,
  onBack,
  onSave,
  onViewChange,
  onTabChange,
  onTeamChange,
  onStatisticianChange,
  onTeamClear,
  onStatisticianClear,
  onEditFieldsOpenChange,
  isLoading,
  isOpenEditFields,
  isPublishing,
  isRegeneration,
  isSchedulePublished,
  fullInfoId,
}: IPlayOffScheduleView) {
  const { t } = useTranslation();

  return (
    <>
      <BaseDialog
        open={isOpenEditFields}
        onClose={onEditFieldsOpenChange}
        title={t('schedule.editFieldsForPlayOff')}
        sx={{
          '& .MuiDialog-paper': {
            width: '100%', maxWidth: 1200,
          },
        }}
      >
        <PlayingFieldsTable type={ScheduleTypes.PLAY_OFF} playingFields={playingFields} fullInfoId={fullInfoId} />
      </BaseDialog>

      <Paper
        sx={{ p: 3, mt: 4, mb: 3 }}
        elevation={0}
      >
        <Stack gap={2}>
          <Stack direction="row" justifyContent="space-between" gap={2}>
            <Typography component="h4" variant="h6">
              {t('schedule.playOffSchedule')}
            </Typography>
            <ScheduleTabs view={scheduleView} onChange={onViewChange} />
          </Stack>

          {isLoading ? (
            <Stack alignItems="center" my={8}>
              <CircularProgress size={45} thickness={1} color="secondary" />
            </Stack>
          ) : (
            <>
              {scheduleView === ScheduleView.bracket ? (
                <ScheduleBracket
                  timezone={timezone}
                  tournamentBracketData={tournamentBracketData}
                  divisionsTabs={divisionsTabs}
                  tabValue={tabValue}
                  teamName={teamName}
                  statisticianName={statisticianName}
                  teams={teams}
                  statisticians={statisticians}
                  onTabChange={onTabChange}
                  onTeamChange={onTeamChange}
                  onTeamClear={onTeamClear}
                  onStatisticianChange={onStatisticianChange}
                  onStatisticianClear={onStatisticianClear}
                  onEditFieldsOpenChange={onEditFieldsOpenChange}
                />
              ) : <CalendarView />}
            </>
          )}
        </Stack>
      </Paper>
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        color="text.secondary"
        sx={{ pl: 3, maxWidth: 820 }}
      >
        <InfoIcon />
        <Typography variant="body2">
          {t('schedule.bracketAlertInfo')}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="flex-end" spacing={1.5} mt={4}>
        <Button
          variant="outlined"
          onClick={onBack}
          sx={{ width: 140 }}
        >
          {t('common.back')}
        </Button>
        <Button
          variant="outlined"
          onClick={onRegenerate}
          disabled={isRegeneration || isPublishing}
          sx={{ width: 140 }}
        >
          {t('schedule.regenerate')}
        </Button>
        <Button
          variant="contained"
          onClick={onSave}
          disabled={isPublishing || isRegeneration}
        >
          {t(isSchedulePublished ? 'common.exitEdit' : 'common.saveAndPublish')}
        </Button>
      </Stack>
    </>
  );
}

export default PlayOffScheduleView;
