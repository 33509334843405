import { createSelector } from '@reduxjs/toolkit';

import type IBaseQueryResWithPagination from '../../types/IBaseQueryResWithPagination';
import type { IRule } from './interfaces/IRule';

export const selectEventRules = createSelector(
  (data: IBaseQueryResWithPagination<IRule[]> | undefined) => data?.data || [],
  (rules) => rules.map((rule) => ({
    documentId: rule.id,
    name: rule.title,
    file: rule.rulesFile,
  })),
);

export default selectEventRules;
