import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import userApi from './apis/userApi';
import type IUserSlice from './interfaces/IUserSlice';
import type IOrganization from './interfaces/IOrganization';
import { logout } from '../auth/slice';

export const initialState: IUserSlice = {
  organization: undefined,
  organizationList: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserOrganization: (state, action: PayloadAction<IOrganization>) => {
      state.organization = action.payload;
    },
  },
  extraReducers: (builder): void => {
    builder.addCase(logout, (): IUserSlice => initialState);

    builder.addMatcher(
      userApi.endpoints.getOrganizationByMe.matchFulfilled,
      (state, { payload }): void => {
        state.organization = payload.data?.[0];
        state.organizationList = payload.data;
      },
    );
  },
});

export const { setUserOrganization } = userSlice.actions;

export default userSlice.reducer;
