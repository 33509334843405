import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ActionDeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import Box from '@mui/material/Box';

import type { IDocumentCard } from './interfaces/IDocumentCard';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

function DocumentCard({
  name,
  control,
  isWaivers,
  document,
  cardIndex,
  onRemove,
  withConfirm,
  modalTitle,
  modalDescription,
  modalSecondDescription,
}: IDocumentCard): React.ReactElement {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleToggleModal = () => setOpen((prev) => !prev);

  const handleRemove = () => onRemove(cardIndex);

  return (
    <>
      <Box sx={{ alignSelf: 'stretch' }}>
        <Stack direction="row" justifyContent="space-between" spacing={3} mb={1.5}>
          <Typography component="h4" mb={2} fontSize={20}>
            {isWaivers ? t('events.waiverCount', { number: cardIndex + 1 })
              : t('events.rulesCount', { number: cardIndex + 1 })}
          </Typography>
          <IconButton onClick={withConfirm ? handleToggleModal : handleRemove}>
            <ActionDeleteIcon />
          </IconButton>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Stack direction="row" alignItems="center" spacing={1.25} height="100%">
              <DescriptionIcon sx={{ fontSize: 24 }} color="disabled" />
              <Typography
                component="a"
                mb={2}
                fontSize={20}
                href={typeof document.file === 'object' ? URL.createObjectURL(document.file) : document.file}
                sx={{ textDecoration: 'underline', wordBreak: 'break-all' }}
                color="primary.main"
                target="_blank"
                rel="nofollow noreferrer"
              >
                {typeof document.file === 'object' ? document.file.name : document.name}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name={name}
              control={control}
              render={({
                field,
                fieldState: { error: fieldError },
              }) => (
                <TextField
                  helperText={fieldError?.message}
                  error={!!fieldError}
                  label={t('events.title')}
                  variant="outlined"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      {withConfirm ? (
        <ConfirmDialog
          title={modalTitle}
          description={modalDescription}
          secondDescription={modalSecondDescription}
          open={open}
          onClose={handleToggleModal}
          buttonText={t('common.delete')}
          onConfirm={handleRemove}
        />
      ) : null}
    </>
  );
}

export default DocumentCard;
