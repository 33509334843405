import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';

function GhostIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M7.99972 2.04688C6.57892 2.04688 5.21631 2.61129 4.21165 3.61595C3.20699 4.6206 2.64258 5.98322 2.64258 7.40402V13.9516L4.42829 12.1659L6.21401 13.9516L7.99972 12.1659L9.78544 13.9516L11.5711 12.1659L13.3569 13.9516V7.40402C13.3569 5.98322 12.7925 4.6206 11.7878 3.61595C10.7831 2.61129 9.42052 2.04688 7.99972 2.04688ZM6.21401 5.6183C6.52974 5.6183 6.83254 5.74373 7.0558 5.96699C7.27906 6.19024 7.40448 6.49305 7.40448 6.80878C7.40448 7.12451 7.27906 7.42732 7.0558 7.65057C6.83254 7.87383 6.52974 7.99926 6.21401 7.99926C5.89827 7.99926 5.59547 7.87383 5.37221 7.65057C5.14896 7.42732 5.02353 7.12451 5.02353 6.80878C5.02353 6.49305 5.14896 6.19024 5.37221 5.96699C5.59547 5.74373 5.89827 5.6183 6.21401 5.6183ZM9.78544 5.6183C10.1012 5.6183 10.404 5.74373 10.6272 5.96699C10.8505 6.19024 10.9759 6.49305 10.9759 6.80878C10.9759 7.12451 10.8505 7.42732 10.6272 7.65057C10.404 7.87383 10.1012 7.99926 9.78544 7.99926C9.4697 7.99926 9.1669 7.87383 8.94364 7.65057C8.72038 7.42732 8.59496 7.12451 8.59496 6.80878C8.59496 6.49305 8.72038 6.19024 8.94364 5.96699C9.1669 5.74373 9.4697 5.6183 9.78544 5.6183Z"
        fill="white"
      />
    </SvgIcon>
  );
}

export default GhostIcon;
