import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import CreateEventTitle from '../../components/CreateEventTitle/CreateEventTitle';
import SocialNetworksSection from '../../components/CreateEventForm/components/SocialNetworksSection';
import type { ISingleEventSocialView } from './interfaces/ISingleEventSocialView';

/**
 * Single event edit social page view
 */
function SingleEventSocialView({
  control,
  eventName,
  onCancelEdit,
  onCreateSubmit,
  isSubmitting,
}: ISingleEventSocialView): React.ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <Paper sx={{ px: 8, py: 5, mb: 3 }} elevation={0}>
        <CreateEventTitle title={t('events.socialNetworks')} eventName={eventName} />
        <SocialNetworksSection control={control} showTitle={false} />
      </Paper>
      <Stack direction="row" justifyContent="space-between" spacing={1.5}>
        <Button
          variant="outlined"
          color="error"
          onClick={onCancelEdit}
          sx={{ width: 140 }}
        >
          {t('events.cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={onCreateSubmit}
          disabled={isSubmitting}
          sx={{ width: 126 }}
        >
          {t('events.save')}
        </Button>
      </Stack>
    </>
  );
}

export default SingleEventSocialView;
