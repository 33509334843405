import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import EditOutlined from '@mui/icons-material/EditOutlined';
import PeopleIcon from '@mui/icons-material/People';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TodayIcon from '@mui/icons-material/Today';
import Box from '@mui/material/Box';

import PaperBlock from '../../../components/PaperBlock/PaperBlock';
import EmptyData from '../../../components/EmptyData/EmptyData';
import MultiGenderIcon from '../../../components/Icons/MultiGenderIcon';
import TicketDiscountIcon from '../../../components/Icons/TicketDiscountIcon';
import { getRelativePath } from '../../../utils/helpers';
import AppRoutes from '../../../constants/AppRoutes';
import type { IDivisionsWidget } from '../interfaces/IDivisionsWidget';

function DivisionsWidget({ divisions = [], divisionsDetails, timezone }: IDivisionsWidget): React.ReactElement {
  const { t } = useTranslation();
  const { eventId } = useParams();

  return (
    <PaperBlock
      title="Divisions and team tickets"
      endAdornment={(
        <Button
          startIcon={<EditOutlined />}
          component={Link}
          to={`/events/${eventId}${getRelativePath(AppRoutes.singleEventDivisions, 'route')}`}
          size="small"
        >
          {t('common.edit')}
        </Button>
      )}
      whiteBg
      noMargin
    >
      <List sx={{ mt: -2 }} disablePadding>
        <ListItem disableGutters>
          <ListItemAvatar>
            <Avatar sx={{ color: 'white' }}>
              <TicketDiscountIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t('events.multipleTeamsRegDiscount')}
            secondary={divisionsDetails.multipleDiscountAmount && divisionsDetails.multipleDiscountTeamsCount
              ? t('events.multipleTeamsRegDiscountInfo', {
                amount: Intl.NumberFormat(
                  'en-US',
                  { style: 'currency', currency: 'USD' },
                ).format(divisionsDetails.multipleDiscountAmount),
                number: divisionsDetails.multipleDiscountTeamsCount,
              }) : '-'}
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemAvatar>
            <Avatar sx={{ color: 'white' }}>
              <CheckCircleIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t('events.age')}
            secondary={divisionsDetails.ageRange || '-'}
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemAvatar>
            <Avatar sx={{ color: 'white' }}>
              <MultiGenderIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t('events.gender')}
            secondary={divisionsDetails.gender
              ? divisionsDetails.gender.split(', ').map((item) => t(`events.${item}`)).join(', ')
              : '-'}
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemAvatar>
            <Avatar sx={{ color: 'white' }}>
              <TodayIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t('events.teamRegistrationEnds')}
            secondary={divisionsDetails.teamRegistrationEndsOn}
          />
        </ListItem>
      </List>
      <Divider sx={{ my: 3 }} />
      {divisions.length ? (
        <List sx={{ py: 0 }} disablePadding>
          {divisions.map((item) => {
            const isDiscountActive = item.discount?.endAt
              ? DateTime.fromISO(item.discount?.endAt).setZone(timezone).startOf('minute') >= DateTime.now().setZone(timezone).startOf('minute')
              : false;

            const currentDiscount = isDiscountActive && item.discount?.price
              ? item.discount.price
              : item.ticketPrice;

            return (
              <ListItem key={`DetailsWidgetList_${item.id}`} disableGutters>
                <ListItemAvatar>
                  <Avatar sx={{ color: 'white' }}>
                    <PeopleIcon />
                  </Avatar>
                </ListItemAvatar>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <ListItemText
                    primary={item.name}
                    secondary={(
                      <>
                        {item.discount && isDiscountActive ? (
                          <Typography variant="body2">
                            {t('events.discountedPriceUntil', { date: DateTime.fromISO(item.discount.endAt).setZone(timezone).toFormat('LLL dd, yyyy ZZZZ') })}
                          </Typography>
                        ) : null}
                        <Typography variant="body2">
                          {`${t(`events.${item.division.gender}`)}, ${t('events.divisionInfo', {
                            age: item.division.age,
                            limit: item.ticketCount,
                          })}`}
                        </Typography>
                      </>
                  )}
                    secondaryTypographyProps={{ component: Box }}
                    sx={{ my: 0, whiteSpace: 'initial' }}
                  />
                  <ListItemText
                    primary={Intl
                      .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                      .format(currentDiscount)}
                    secondary={item.discount?.price && isDiscountActive ? (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ textDecoration: 'line-through', textAlign: 'right' }}
                      >
                        {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.ticketPrice)}
                      </Typography>
                    ) : undefined}
                    sx={{ my: 0, flexGrow: 0 }}
                  />
                </Stack>
              </ListItem>
            );
          })}
        </List>
      ) : <EmptyData description={t('events.noDivisions')} />}
    </PaperBlock>
  );
}

export default DivisionsWidget;
