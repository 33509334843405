import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import type { ICreateEventTitle } from './interfaces/ICreateEventTitle';

function CreateEventTitle({ title, isOptional, eventName }: ICreateEventTitle) {
  const { t } = useTranslation();

  return (
    <Stack
      spacing={3}
      direction="row"
      justifyContent="space-between"
      mb={4}
    >
      <Stack direction="row" alignItems="center" spacing={1.5}>
        <Typography component="h2" variant="h4">
          {title}
        </Typography>
        {isOptional ? (
          <Typography variant="body2" color="text.secondary">
            {`(${t('common.optional')})`}
          </Typography>
        ) : null}
      </Stack>
      <Typography variant="body2">
        {eventName}
      </Typography>
    </Stack>
  );
}

export default CreateEventTitle;
