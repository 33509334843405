import { useOutletContext } from 'react-router-dom';
import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';

import LogisticsAndGameSetupView from './LogisticsAndGameSetupView';
import { useGetEventPlayingFieldsQuery, useGetPPFullInfoQuery } from '../../store/slices/poolPlays/apis/poolPlaysApi';
import usePoolPlayGameSetupForm from '../../hooks/usePoolPlayGameSetupForm';
import usePlayOffGameSetupForm from '../../hooks/usePlayOffGameSetupForm';
import { DEFAULT_MAX_PER_PAGE, ScheduleTypes } from '../../constants/general';
import { useGetPOFullInfoQuery } from '../../store/slices/playOff/apis/playOffApi';
import getPlayingFieldsWhereParam from '../../utils/playingFields';
import type ICreateEventContext from '../../layout/CreateEventLayout/interfaces/ICreateEventContext';
import type { ILogisticsAndGameSetupPage } from './interfaces/ILogisticsAndGameSetupPage';

function LogisticsAndGameSetup({ type }: ILogisticsAndGameSetupPage): React.ReactElement {
  const { eventId, orgId } = useOutletContext<ICreateEventContext>();

  const { data: poolPlayFullInfo } = useGetPPFullInfoQuery(type === ScheduleTypes.POOL_PLAY ? {
    orgId, eventId: +eventId,
  } : skipToken);

  const { data: playOffFullInfo } = useGetPOFullInfoQuery(type === ScheduleTypes.PLAY_OFF ? {
    orgId, eventId: +eventId,
  } : skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const playingFieldsWhereParam = getPlayingFieldsWhereParam({
    playOffRules: playOffFullInfo,
    poolPlaysRules: poolPlayFullInfo,
  });

  const { data: playingFields } = useGetEventPlayingFieldsQuery(playingFieldsWhereParam ? {
    orgId,
    eventId: +eventId,
    perPage: DEFAULT_MAX_PER_PAGE,
    where: playingFieldsWhereParam,
  } : skipToken);

  const poolPlayForm = usePoolPlayGameSetupForm(type === ScheduleTypes.PLAY_OFF);
  const playOffForm = usePlayOffGameSetupForm(type === ScheduleTypes.POOL_PLAY, playOffFullInfo?.data.gamesExist);

  const {
    tabValue,
    modalValue,
    gameSettingsControl,
    divisionsGameSetup,
    timeFramesRange,
    divisionRules,
    game7x7Settings,
    handleSetupAll,
    handleCreateGameSetting,
    handleSetupGamesSchedule,
    handleTabChange,
    handleCloseModal,
    handleSetupClick,
    handleBackClick,
    isDivisionRulesLoading,
    isGame7x7SettingsLoading,
    isSetupGamesSubmitting,
    isCheckValidityLoading,
    isGameSettingSubmitting,
  } = type === ScheduleTypes.POOL_PLAY ? poolPlayForm : playOffForm;

  const poolPlaySetupTabs = useMemo(() => (
    [
      { text: 'schedule.divisionSetup', options: {} },
      {
        text: type === ScheduleTypes.POOL_PLAY ? 'schedule.poolPlayFields' : 'schedule.playOffFields',
        options: { total: playingFields?.meta.total || 0 },
      },
    ]
  ), [type, playingFields?.meta.total]);

  return (
    <LogisticsAndGameSetupView
      type={type}
      gameSettingsControl={gameSettingsControl}
      tabValue={tabValue}
      modalValue={modalValue}
      fullInfoId={playOffFullInfo?.data?.id || poolPlayFullInfo?.data?.id}
      divisionsGameSetup={divisionsGameSetup}
      divisionRules={divisionRules?.data}
      game7x7Settings={game7x7Settings?.data || []}
      playingFields={playingFields?.data || []}
      timeFramesRange={timeFramesRange?.data}
      timezone={timeFramesRange?.timezone}
      poolPlaySetupTabs={poolPlaySetupTabs}
      onSetupGamesSchedule={handleSetupGamesSchedule}
      onSetupAll={handleSetupAll}
      onCreateGameSetting={handleCreateGameSetting}
      onTabChange={handleTabChange}
      onCloseModal={handleCloseModal}
      onSetupClick={handleSetupClick}
      onBackClick={handleBackClick}
      isDivisionRulesLoading={isDivisionRulesLoading}
      isGame7x7SettingsLoading={isGame7x7SettingsLoading}
      isGameSettingSubmitting={isGameSettingSubmitting}
      isSubmitting={isSetupGamesSubmitting || isCheckValidityLoading}
      isNextDisabled={playOffFullInfo?.data?.allDivisionsCompleted !== undefined
        && !playOffFullInfo.data.allDivisionsCompleted}
    />
  );
}

export default LogisticsAndGameSetup;
