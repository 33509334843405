import type { PaletteMode } from '@mui/material';

import { lightPalettes, darkPalettes } from './palettes';
import { AppThemeMode } from '../constants/AppThemes';
import type TThemeColor from './types/TThemeColor';

const getThemePalette = (color: TThemeColor, mode: PaletteMode) => {
  let palette = lightPalettes[color];
  if (mode === AppThemeMode.Dark) {
    palette = darkPalettes[color];
  }
  return palette;
};

export default getThemePalette;
