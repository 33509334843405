import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate, useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import SingleEventHotelsView from './SingleEventHotelsView';
import { useGetEventHotelsQuery } from '../../store/slices/events/apis/eventsApi';
import { selectEventHotels } from '../../store/slices/events/selectors';
import { useAppSelector } from '../../store/hooks/useApp';
import { selectUserOrg } from '../../store/slices/user/selectors';
import {
  useCreateHotelMutation,
  useDeleteHotelByEventMutation,
  useUpdateHotelMutation,
} from '../../store/slices/hotels/apis/hotelsApi';
import { getErrorMessage } from '../../utils/helpers';
import { editEventHotelsSchema } from '../../utils/validators';
import type { IHotelsForm } from './interfaces/IHotelsForm';
import type IAddressFields from '../../interfaces/IAddressFields';

function SingleEventHotels(): React.ReactElement {
  const { t } = useTranslation();
  const { eventId } = useParams();
  const navigate = useNavigate();
  const organization = useAppSelector(selectUserOrg);

  const { eventHotels } = useGetEventHotelsQuery(
    eventId ? { orgId: organization?.id as number, eventId: +eventId } : skipToken,
    {
      selectFromResult: (result) => ({ ...result, eventHotels: selectEventHotels(result.data) }),
    },
  );

  const [createHotel] = useCreateHotelMutation();
  const [updateHotel] = useUpdateHotelMutation();
  const [deleteHotelByEvent] = useDeleteHotelByEventMutation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<IHotelsForm>({
    resolver: yupResolver<IHotelsForm>(editEventHotelsSchema),
    mode: 'onChange',
    defaultValues: {
      hotels: eventHotels || [],
    },
  });

  const {
    fields: hotels,
    append: handleAppendHotelByForm,
    remove: handleRemoveHotelByForm,
  } = useFieldArray({ control, name: 'hotels' });

  const handleAppendEmptyHotel = () => {
    const emptyHotel = { name: '', address: null, link: '' };
    handleAppendHotelByForm(emptyHotel);
  };

  const handleCancel = () => {
    navigate(-1);
    reset();
  };

  const handleSave = handleSubmit((values) => {
    const hotelsPromises = values.hotels.map((hotel) => {
      const data = {
        orgId: organization?.id as number,
        name: hotel.name,
        link: hotel.link,
        address: hotel.address as IAddressFields,
      };

      return hotel.hotelId
        ? updateHotel({ ...data, hotelId: hotel.hotelId })
        : createHotel({ ...data, eventId: +(eventId as string) });
    });

    const hotelsIdsToDelete = eventHotels
      .filter((hotel) => !values.hotels.some((item) => item.hotelId === hotel.hotelId))
      .map((hotel) => hotel.hotelId);

    const hotelsDeletePromise = hotelsIdsToDelete.length ? deleteHotelByEvent({
      orgId: organization?.id as number,
      eventId: +(eventId as string),
      hotelsIds: hotelsIdsToDelete,
    }) : undefined;

    const requestPromises = [
      ...hotelsPromises,
      hotelsDeletePromise,
    ].filter(Boolean);

    Promise.all(requestPromises).then((res) => {
      let isSuccess = true;

      res.forEach((item) => {
        if (item && 'error' in item) {
          const errorMessage = getErrorMessage(item.error);

          toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
          isSuccess = false;
        }
      });

      if (isSuccess) {
        reset();
        navigate(-1);
        toast.success(t('events.changesSaved'), { position: toast.POSITION.TOP_RIGHT });
      }
    });
  });

  useEffect(() => {
    reset({ hotels: eventHotels });
  }, [reset, eventHotels]);

  return (
    <SingleEventHotelsView
      control={control}
      hotels={hotels}
      onCancel={handleCancel}
      onSave={handleSave}
      onAppendEmptyHotel={handleAppendEmptyHotel}
      onRemoveHotel={handleRemoveHotelByForm}
      isSubmitting={isSubmitting}
    />
  );
}

export default SingleEventHotels;
