import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import PaperBlock from '../../../components/PaperBlock/PaperBlock';
import TeamTicketsTable from './TeamTicketsTable';
import TableTabs from '../../../components/TableTabs/TableTabs';
import { useAppSelector } from '../../../store/hooks/useApp';
import { selectThemeMode } from '../../../store/slices/ui/selectors';
import { AppThemeMode } from '../../../constants/AppThemes';
import type { ITeamTicketsWidget } from '../interfaces/ITeamTicketsWidget';

const useStyle = makeStyles()(
  () => ({
    title: {
      '& > .MuiTypography-root': {
        fontSize: '24px',
        lineHeight: 0.9,
        textTransform: 'unset',
      },
    },
  }),
);

function TeamTicketsWidget({
  divisions,
  tabValue,
  onTabChange,
  teamsEarnedData,
}: ITeamTicketsWidget) {
  const { t } = useTranslation();
  const { classes } = useStyle();
  const themeMode = useAppSelector(selectThemeMode);
  const colorMode = themeMode === AppThemeMode.Dark ? AppThemeMode.Dark : AppThemeMode.Light;

  return (
    <PaperBlock
      title={t('events.teamTickets')}
      colorMode={colorMode}
      whiteBg
      noMargin
      titleClassName={classes.title}
    >
      {divisions.length > 2 && (
        <TableTabs
          tabs={divisions}
          tabValue={tabValue}
          onTabChange={onTabChange}
        />
      )}
      <TeamTicketsTable
        teamsEarnedData={teamsEarnedData}
      />
    </PaperBlock>
  );
}

export default TeamTicketsWidget;
