import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import QrCodeView from './QrCodeView';
import SplashScreen from '../../components/SplashScreen/SplashScreen';
import PageNotFound from '../../layout/Page/PageNotFound';
import useQrCode from '../../hooks/useQrCode';
import { useAppSelector } from '../../store/hooks/useApp';
import { selectUserOrg } from '../../store/slices/user/selectors';
import { getOrgLinkById } from '../../utils/helpers';

function QrCode() {
  const { t } = useTranslation();
  const organisation = useAppSelector(selectUserOrg);
  const generationData = organisation
    ? `${process.env.REACT_APP_QR_CODE_URL}${organisation.id}`
    : '';
  const generationAppData = organisation
    ? getOrgLinkById(organisation.id)
    : '';

  const {
    qrCodeDataURL,
    isLoading,
    isError,
  } = useQrCode(generationData);

  const {
    qrCodeDataURL: appQrCodeDataURL,
    isLoading: appQrCodeDataIsLoading,
    isError: appQrCodeDataIsError,
  } = useQrCode(generationAppData);

  const qrCodeList = useMemo(() => ([
    {
      data: qrCodeDataURL,
      subtitle: t('settings.firstSubtitle'),
      fileName: t('settings.fileNameWebsite', { name: organisation?.name }),
    },
    {
      data: appQrCodeDataURL,
      subtitle: t('settings.secondSubtitle'),
      fileName: t('settings.fileNameApp', { name: organisation?.name }),
    },
  ]), [appQrCodeDataURL, organisation, qrCodeDataURL, t]);

  if (isLoading || appQrCodeDataIsLoading) {
    return <SplashScreen />;
  }

  if (isError || appQrCodeDataIsError) {
    return <PageNotFound />;
  }

  return (
    <Stack spacing={6}>
      {qrCodeList.map(({ data, subtitle, fileName }, index) => (
        <QrCodeView
          key={[index].join('_qrcode')}
          qrCodeDataURL={data}
          subtitle={subtitle}
          fileName={fileName}
        />
      ))}
    </Stack>
  );
}

export default QrCode;
