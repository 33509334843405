import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';

import EditButton from './EditButton';
import type IPoolPLayTableRow from '../interfaces/IPoolPLayTableRow';

function PoolPLayTableRow({ data, onEditClick, onSetupClick }: IPoolPLayTableRow) {
  return (
    <Paper
      component={TableRow}
      sx={{
        '& > td': { borderBottom: 'none' },
        backgroundColor: data.error ? (theme) => theme.palette.background.error : undefined,
      }}
    >
      <TableCell sx={{ borderRadius: '10px 0 0 10px' }}>
        {data.name}
      </TableCell>
      <TableCell>
        {data.game7x7Settings?.description || '-'}
      </TableCell>
      <TableCell>
        {data.gamesPools.map(({ name }) => `Pool ${name}`).join(', ') || '-'}
      </TableCell>
      <TableCell>
        {data.gamesPerTeam || '-'}
      </TableCell>
      <TableCell>
        {data.teamsCount}
      </TableCell>
      <TableCell sx={{ borderRadius: '0 10px 10px 0' }} align="right">
        <EditButton
          setupCompleted={data.setupCompleted}
          onSetupClick={onSetupClick}
          onEditClick={onEditClick}
        />
      </TableCell>
    </Paper>
  );
}

export default PoolPLayTableRow;
