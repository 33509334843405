import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';

import EventsView from './EventsView';
import {
  useDuplicateEventMutation,
  useGetAllEventsQuery,
  usePublishEventMutation,
} from '../../store/slices/events/apis/eventsApi';
import { resetConfirmModalData, setConfirmModalData, setEventsState } from '../../store/slices/events/slice';
import selectPageFilters, { selectEventConfirmModal } from '../../store/slices/events/selectors';
import { selectUserOrg } from '../../store/slices/user/selectors';
import { resetCreateEventState, setCompletedSteps } from '../../store/slices/createEvent/slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks/useApp';
import useTablePagination from '../../hooks/useTablePagination';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import useEventActions from '../../hooks/useEventActions';
import { eventsFiltersSchema } from '../../utils/validators';
import { checkAdmin, getErrorMessage, toWhereFormat } from '../../utils/helpers';
import {
  CREATE_EVENT_STEPS,
  DEFAULT_EVENT_STATUS_ORDER_BY,
  ORDERS,
} from '../../constants/general';
import { CREATE_EVENT_EVENT_ID } from '../../constants/localStorageKeys';
import FIELDS_VALIDATION from '../../constants/hooksCacheKeys';
import { EventStatuses } from '../../constants/Statuses';
import AppRoutes from '../../constants/AppRoutes';
import EventActions from '../../constants/EventActions';
import { VALIDATION_ERROR_CODE } from '../../constants/apiCodeErrors';
import type { IEventsFilterForm } from './interfaces/IEventsFilterForm';
import type { ISearchInputHandler } from '../../components/SearchInput/interfaces/ISearchInput';
import type { ITableSortHandler } from '../../components/TableSort/interfaces/ITableSort';
import type IListEvent from '../../store/slices/events/interfaces/IListEvent';
import type { ITableActionItem } from '../../components/TableRowActions/interfaces/ITableActions';
import SplashScreen from '../../components/SplashScreen/SplashScreen';

function Events(): React.ReactElement {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const pagination = useTablePagination();
  const organization = useAppSelector(selectUserOrg);
  const filters = useAppSelector(selectPageFilters);
  const eventConfirmModal = useAppSelector(selectEventConfirmModal);
  const trueAdmin = checkAdmin(organization?.roles || []);

  const statusOrderBy = filters.orderBy === 'status' && filters.order === ORDERS.DESC
    ? [...DEFAULT_EVENT_STATUS_ORDER_BY].reverse().join(', ')
    : DEFAULT_EVENT_STATUS_ORDER_BY.join(', ');

  const { data: events, isLoading: isEventsLoading, refetch } = useGetAllEventsQuery(
    organization?.id ? {
      orgId: organization?.id,
      statusOrderBy,
      search: filters.search,
      page: pagination.page + 1,
      perPage: pagination.perPage,
      where: toWhereFormat({
        topicId: filters.topicId,
        status: filters.status,
      }),
      orderBy: `${filters.orderBy}:${filters.order}`,
    } : skipToken,
  );
  const [publishEvent, { isLoading: isPublishing }] = usePublishEventMutation({
    fixedCacheKey: FIELDS_VALIDATION,
  });
  const [duplicateEvent] = useDuplicateEventMutation();

  const currentEventAction = useEventActions({
    type: eventConfirmModal.type,
    onSuccess: () => dispatch(resetConfirmModalData()),
  });

  const defaultValues = useMemo(() => ({
    search: filters.search,
    sort: {
      order: filters.order,
      orderBy: filters.orderBy,
    },
    status: filters.status,
    topicId: filters.topicId,
  }), [filters]);

  const {
    control,
    getValues,
    reset,
  } = useForm<IEventsFilterForm>({
    resolver: yupResolver<IEventsFilterForm>(eventsFiltersSchema),
    defaultValues,
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    if (events?.meta.perPage === events?.meta.total) {
      pagination.handleChangePage(null, 0);
    }
  }, [events, pagination]);

  const debouncedSearch = useDebouncedCallback(() => {
    const { search } = getValues();
    dispatch(setEventsState({ search }));
    pagination.handleChangePage(null, 0);
  });

  const debouncedSort = useDebouncedCallback(() => {
    const { sort: { order, orderBy } } = getValues();
    dispatch(setEventsState({ order, orderBy }));
  });

  const handleConfirmModalClose = () => {
    dispatch(resetConfirmModalData());
  };

  const handleSearchChange = ({ search, onChange }: ISearchInputHandler) => {
    onChange(search);
    debouncedSearch();
  };

  const handleSortChange = ({ property, onChange }: ITableSortHandler) => {
    const { sort: { order, orderBy } } = getValues();
    const isAsc = orderBy === property && order === ORDERS.ASC;

    onChange({ orderBy: property, order: isAsc ? ORDERS.DESC : ORDERS.ASC });
    debouncedSort();
  };

  const handleResetFilters = () => {
    reset({ status: '', topicId: '' });
  };

  const handleApplyFilters = () => {
    const { status, topicId } = getValues();
    dispatch(setEventsState({ status, topicId }));
  };

  const handleCreateEventClick = () => {
    dispatch(resetCreateEventState());
    localStorage.removeItem(CREATE_EVENT_EVENT_ID);
  };

  const handleEditEvent = useCallback((event: IListEvent) => {
    dispatch(resetCreateEventState());
    dispatch(
      setCompletedSteps(
        CREATE_EVENT_STEPS.reduce((acc, item, index) => ({ ...acc, [index]: true }), {}),
      ),
    );
    localStorage.setItem(CREATE_EVENT_EVENT_ID, event.id.toString());
    navigate(AppRoutes.createEvent.concat('?editPage=true'));
  }, [dispatch, navigate]);

  const handlePublishEvent = useCallback((event: IListEvent) => {
    const isEventInPast = event?.startAt
      ? DateTime.fromISO(event?.startAt).startOf('day') < DateTime.now().startOf('day')
      : false;

    if (isEventInPast) {
      toast.error(t('events.publishingError', { event: event.name || '' }), { position: toast.POSITION.TOP_RIGHT });
    } else {
      publishEvent({
        orgId: organization?.id as number,
        eventId: event.id,
      })
        .unwrap()
        .then(() => toast.success(t('events.publishSuccess', { eventName: event.name }), { position: toast.POSITION.TOP_RIGHT }))
        .catch((error) => {
          const errorMessage = error.data.error.code === VALIDATION_ERROR_CODE ? t('events.publishError') : getErrorMessage(error);

          navigate(`/events/${event.id}`);
          toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT });
        });
    }
  }, [navigate, organization?.id, publishEvent, t]);

  const handleDuplicateEvent = useCallback((event: IListEvent) => {
    duplicateEvent({
      orgId: organization?.id as number,
      eventId: event.id,
    })
      .unwrap()
      .then(({ data }) => {
        toast.success(t('events.duplicateSuccess', { eventName: event.name }), { position: toast.POSITION.TOP_RIGHT });
        window.open(`/events/${data.id}`, '_blank');
      })
      .catch((error) => toast.error(getErrorMessage(error), { position: toast.POSITION.TOP_RIGHT }));
  }, [duplicateEvent, organization?.id, t]);

  const handleCancelEvent = useCallback((event: IListEvent) => {
    dispatch(
      setConfirmModalData({
        isOpen: true,
        title: `${t('events.cancelEventTitle')}`,
        description: `${t('events.cancelEventUndone')}`,
        secondDescription: `${t('events.cancelEventRefund')}`,
        type: EventActions.Cancel,
        event,
      }),
    );
  }, [dispatch, t]);

  const handleDeleteEvent = useCallback((event: IListEvent) => {
    dispatch(
      setConfirmModalData({
        isOpen: true,
        title: `${t('events.deleteEventTitle')}`,
        description: `${t('events.deleteEventUndone')}`,
        secondDescription: `${t('events.deleteEventRefund')}`,
        type: EventActions.Delete,
        event,
      }),
    );
  }, [dispatch, t]);

  const getEventActions = useCallback((event: IListEvent): ITableActionItem[] => ([
    {
      label: t('common.edit'),
      onClick: () => handleEditEvent(event),
      statuses: [
        EventStatuses.Draft,
        EventStatuses.Upcoming,
        EventStatuses.Current,
      ],
    },
    {
      label: t('common.publish'),
      onClick: () => handlePublishEvent(event),
      disabled: event.published,
      statuses: [
        EventStatuses.Draft,
      ],
    },
    {
      label: t('common.duplicate'),
      onClick: () => handleDuplicateEvent(event),
      statuses: [
        EventStatuses.Draft,
        EventStatuses.Upcoming,
        EventStatuses.Current,
        EventStatuses.Over,
        EventStatuses.Canceled,
      ],
    },
    {
      label: t('common.cancel'),
      onClick: () => handleCancelEvent(event),
      statuses: [
        EventStatuses.Upcoming,
        EventStatuses.Current,
      ],
    },
    {
      label: t('common.delete'),
      onClick: () => handleDeleteEvent(event),
      statuses: [
        EventStatuses.Draft,
      ],
    },
  ]), [
    t,
    handleEditEvent,
    handlePublishEvent,
    handleDuplicateEvent,
    handleCancelEvent,
    handleDeleteEvent,
  ]);

  if (isPublishing) {
    return <SplashScreen />;
  }

  return (
    <EventsView
      control={control}
      events={events?.data || []}
      pagination={{ ...pagination, total: events?.meta.total || 0 }}
      eventConfirmModal={eventConfirmModal}
      currentEventAction={() => currentEventAction(eventConfirmModal.event)}
      onCloseConfirmModal={handleConfirmModalClose}
      onSearchChange={handleSearchChange}
      onSortChange={handleSortChange}
      onResetFilters={handleResetFilters}
      onApplyFilters={handleApplyFilters}
      onCreateEventClick={handleCreateEventClick}
      isEventsLoading={isEventsLoading}
      getTableActions={getEventActions}
      admin={trueAdmin}
    />
  );
}

export default Events;
