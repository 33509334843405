import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import CreateEventStepper from '../../components/CreateEventStepper/CreateEventStepper';
import SpectatorsTicketsForm from '../../components/SpectatorsTicketsForm/SpectatorsTicketsForm';
import ReplaceDatesDialog from '../../components/SpectatorsTicketsForm/components/ReplaceDatesDialog';
import type { ISpectatorsTicketsView } from './interfaces/ISpectatorsTicketsView';

function SpectatorsTicketsView({
  eventName,
  tournamentDates,
  control,
  tournamentDaysInterval,
  maxSalesDate,
  eventEndDate,
  eventStartDate,
  activeStep,
  onDatesSave,
  onDatesDialogClose,
  onTournamentDaysClick,
  onStepClick,
  onCreateSubmit,
  onSkipStep,
  isSubmitting,
  isDatesDialogOpen,
  isLoading,
}: ISpectatorsTicketsView): React.ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <ReplaceDatesDialog
        isOpen={isDatesDialogOpen}
        eventStartDate={eventStartDate}
        eventEndDate={eventEndDate}
        onClose={onDatesDialogClose}
        dates={tournamentDates}
        allowedDates={tournamentDaysInterval}
        onSave={onDatesSave}
        isLoading={isLoading}
      />
      <CreateEventStepper activeStep={activeStep} onStepClick={onStepClick} />
      <Paper
        sx={{
          px: 8, py: 5, mt: 4, mb: 3,
        }}
        elevation={0}
      >
        <SpectatorsTicketsForm
          title={t('events.spectatorsTickets')}
          control={control}
          maxSalesDate={maxSalesDate}
          tournamentDaysInterval={tournamentDaysInterval}
          eventName={eventName}
          eventStartDate={eventStartDate}
          eventEndDate={eventEndDate}
          onTournamentDaysClick={onTournamentDaysClick}
        />
      </Paper>
      <Stack spacing={1.5} direction="row" justifyContent="flex-end">
        <Button
          variant="outlined"
          onClick={onSkipStep}
          sx={{ width: 140 }}
        >
          {t('events.back')}
        </Button>
        <Button
          variant="contained"
          onClick={onCreateSubmit}
          disabled={isSubmitting}
          sx={{ width: 126 }}
        >
          {t('events.continue')}
        </Button>
      </Stack>
    </>
  );
}

export default SpectatorsTicketsView;
