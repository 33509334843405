import { makeStyles } from 'tss-react/mui';
import { darken } from '@mui/material/styles';
import { lighten } from '@mui/material';

import { AppThemeMode } from '../../constants/AppThemes';

const appFrame = {
  display: 'flex',
  width: '100%',
  minHeight: '100%',
  zIndex: 1,
};

export type TNestedSelectors =
  | 'headerBg'
  | 'halfBg'
  | 'sidebarLayout'
  | 'fullBg'
  | 'mtNone'
  | 'topbarLayout';

const useStyles = makeStyles<void, TNestedSelectors>()(
  (theme, _params, classes) => ({
    root: {
      width: '100%',
      marginTop: 0,
      zIndex: 1,
      overflow: 'auto',
    },
    blogWrap: {
      position: 'relative',
    },
    appFrameInner: {
      background: theme.palette.mode === AppThemeMode.Dark
        ? darken(theme.palette.primary.dark, 0.8)
        : lighten(theme.palette.primary.light, 0.9),
      color: theme.palette.text.primary,
      ...appFrame,
    },
    appFrameOuter: {
      ...appFrame,
    },
    appFrameLanding: {
      backgroundColor: theme.palette.background.default,
      minHeight: 1000,
    },
    appFrameSlider: {
      display: 'flex',
      width: '100%',
      height: '100%',
      [theme.breakpoints.up('lg')]: {
        position: 'absolute',
        overflow: 'hidden',
      },
      backgroundColor: theme.palette.background.default,
    },
    topNav: {
      flexDirection: 'column',
    },
    sideNav: {
      flexDirection: 'row',
    },
    mtNone: {},
    content: {
      width: '100%',
      padding: `${theme.spacing(4)} ${theme.spacing(6)}`,
      minHeight: '100%',
      overflow: 'hidden',
      marginTop: 64,
      [theme.breakpoints.down('lg')]: {
        padding: theme.spacing(3),
      },
      [`&.${classes.mtNone}`]: {
        marginTop: 0,
      },
    },
    outerContent: {
      width: '100%',
      backgroundSize: 'cover',
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    bgWrap: {
      position: 'fixed',
      background: theme.palette.background.default,
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
    },
    headerBg: {},
    halfBg: {},
    fullBg: {},
    bgbar: {
      backgroundAttachment: 'fixed',
      width: '100%',
      top: 0,
      left: 0,
      position: 'absolute',
      [`&.${classes.headerBg}`]: {
        height: 64,
      },
      [`&.${classes.halfBg}`]: {
        height: 400,
      },
      [`&.${classes.fullBg}`]: {
        height: '100%',
      },
    },
    solidBg: {
      backgroundColor: theme.palette.mode === AppThemeMode.Dark
        ? darken(theme.palette.primary.main, 0.4)
        : theme.palette.primary.main,
    },
    sidebarLayout: {},
    topbarLayout: {},
    mainWrap: {
      height: '100%',
      position: 'relative',
      '& > div': {
        willChange: 'inherit !important',
      },
      [`&.${classes.sidebarLayout}`]: {
        paddingTop: theme.spacing(8),
      },
      [`&.${classes.topbarLayout}`]: {
        width: '100%',
        marginTop: theme.spacing(3),
      },
    },
    preloader: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      zIndex: 1000,
      background: 'transparent',
      height: 3,
    },
    materialBg: {
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      opacity: 0.5,
    },
    contentPaddingLeft: {
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(2),
    },
    contentPaddingRight: {
      paddingRight: theme.spacing(10),
      paddingLeft: theme.spacing(2),
    },
    contentPaddingLeftSm: {
      paddingLeft: theme.spacing(2),
    },
    contentPaddingRightSm: {
      paddingRight: theme.spacing(2),
    },
    hideApp: {
      display: 'none',
    },
    circularProgress: {
      position: 'fixed',
      top: 'calc(50% - 45px)',
      left: 'calc(50% - 45px)',
    },
    brand: {
      height: 54,
      display: 'flex',
      padding: '10px 10px 5px',
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      '& img': {
        width: 20,
      },
      '& h3': {
        margin: 0,
        fontSize: 16,
        fontWeight: 500,
        paddingLeft: 10,
        color: theme.palette.common.white,
      },
    },
    light: {},
    pageTitle: {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        alignItems: 'flex-end',
      },
      '& h4': {
        fontWeight: 700,
        fontSize: 40,
        textTransform: 'capitalize',
        [theme.breakpoints.down('lg')]: {
          marginBottom: theme.spacing(3),
        },
      },
    },
  }),
);

export default useStyles;
