import { makeStyles } from 'tss-react/mui';

import { AppThemeMode } from '../../constants/AppThemes';
import COLORS from '../../constants/colors';

export type TNestedSelectors =
  | 'noMargin'
  | 'title'
  | 'fullHeight'
  | 'description';

const useStyles = makeStyles<void, TNestedSelectors>()(
  (theme, _params, classes) => ({
    root: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(3),
      boxShadow: theme.shade.light,
      color: theme.palette.text.primary,
      [`&.${classes.noMargin}`]: {
        margin: 0,
      },
      [`&.${classes.fullHeight}`]: {
        height: '100%',
      },
    },
    descBlock: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3),
      },
    },
    titleText: {
      flex: 1,
    },
    title: {
      position: 'relative',
      textTransform: 'capitalize',
      fontSize: 18,
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        fontWeight: 600,
        marginBottom: theme.spacing(1),
      },
    },
    description: {
      maxWidth: 960,
      paddingTop: theme.spacing(0.5),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    content: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(1),
      borderRadius: theme.rounded.medium,
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(2),
      },
    },
    whiteBg: {
      backgroundColor: 'transparent',
      margin: 0,
      padding: 0,
    },
    noMargin: {},
    fullHeight: {},
    colorDark: {
      backgroundColor: theme.palette.mode === AppThemeMode.Dark
        ? COLORS.bcPrimaryDark
        : theme.palette.primary.main,
      [`& .${classes.title}`]: {
        color: theme.palette.grey[100],
      },
      [`& .${classes.description}`]: {
        color: theme.palette.grey[100],
      },
    },
    colorLight: {
      backgroundColor: theme.palette.mode === AppThemeMode.Dark
        ? COLORS.bcPrimaryDark
        : theme.palette.primary.light,
      [`& .${classes.title}`]: {
        color: theme.palette.mode === AppThemeMode.Dark ? theme.palette.common.white : theme.palette.primary.dark,
      },
      [`& .${classes.description}`]: {
        color: theme.palette.text.primary,
      },
    },
    overflowX: {
      width: '100%',
      overflowX: 'auto',
    },
    iconTitle: {
      borderRadius: '50%',
      background: theme.palette.background.default,
      width: 48,
      height: 48,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      lineHeight: '44px',
      verticalAlign: 'middle',
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      '& span': {
        verticalAlign: 'middle',
        color: theme.palette.mode === AppThemeMode.Dark ? theme.palette.secondary.light : theme.palette.primary.main,
      },
    },
  }),
);

export default useStyles;
