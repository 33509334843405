import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

import PlacesAutocomplete from '../../../components/PlacesAutocomplete/PlacesAutocomplete';
import { useAppSelector } from '../../../store/hooks/useApp';
import { selectUserOrg } from '../../../store/slices/user/selectors';
import { getAddressErrorMessage } from '../../../utils/helpers';
import type IPlayingFieldEditableRow from '../interfaces/IPlayingFieldEditableRow';
import StatisticiansDropdownSearch from '../../../components/StatisticiansDropdownSearch/StatisticiansDropdownSearch';

function PlayingFieldEditableRow({
  control,
  number,
  onDelete,
  onSave,
  disabled,
  isChecked,
  isCheckEnabled,
}: IPlayingFieldEditableRow) {
  const { t } = useTranslation();
  const organization = useAppSelector(selectUserOrg);

  return (
    <Paper component={TableRow}>
      {isCheckEnabled ? (
        <TableCell padding="none">
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Checkbox
              checked={isChecked}
              disabled
            />
          </Stack>
        </TableCell>
      ) : null}
      <TableCell width="5%">
        {number}
      </TableCell>
      <TableCell width="30%" padding="none">
        <Controller
          name="name"
          control={control}
          render={({
            field,
            fieldState: { error: fieldError },
          }) => (
            <TextField
              helperText={fieldError?.message}
              error={!!fieldError}
              label={t('schedule.fieldName')}
              variant="outlined"
              {...field}
              fullWidth
            />
          )}
        />
      </TableCell>
      <TableCell width="30%">
        <Controller
          name="address"
          control={control}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error: fieldError },
          }) => (
            <PlacesAutocomplete
              defaultValue={value?.streetAddress1 || ''}
              onBlur={onBlur}
              onChange={onChange}
              label={t('common.address')}
              helperText={getAddressErrorMessage(fieldError)}
              error={!!fieldError}
            />
          )}
        />
      </TableCell>
      <TableCell width="30%" padding="none">
        <Controller
          name="statisticians"
          control={control}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error: fieldError },
          }) => (
            <StatisticiansDropdownSearch
              orgId={organization?.id}
              value={value}
              onBlur={onBlur}
              onChange={(values) => onChange({ target: { value: values } })}
              fieldError={fieldError}
            />
          )}
        />
      </TableCell>
      <TableCell width="5%" align="right">
        <Stack direction="row" gap={2} justifyContent="flex-end">
          <Button
            onClick={onDelete}
            size="small"
            variant="contained"
            color="error"
            sx={{ minWidth: 'max-content' }}
            disabled={disabled}
          >
            <DeleteOutlineIcon />
          </Button>
          <Button onClick={onSave} variant="contained" disabled={disabled}>
            {t('common.save')}
          </Button>
        </Stack>
      </TableCell>
    </Paper>
  );
}

export default PlayingFieldEditableRow;
