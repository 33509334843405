import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import CreateEventStepper from '../../components/CreateEventStepper/CreateEventStepper';
import CreateEventTitle from '../../components/CreateEventTitle/CreateEventTitle';
import DocumentsForm from '../../components/DocumentForm/DocumentsForm';
import type { IDocumentsView } from './interfaces/IDocumentsView';

/**
 * Documents page view
 */
function DocumentsView({
  eventName,
  control,
  waivers,
  rules,
  activeStep,
  onStepClick,
  onAppendWaiver,
  onRemoveWaiver,
  onAppendRule,
  onRemoveRule,
  onSkipStep,
  onCreateSubmit,
  isSubmitting,
  withConfirm,
  isWaiverLoading,
  isRuleLoading,
}: IDocumentsView): React.ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <CreateEventStepper activeStep={activeStep} onStepClick={onStepClick} />
      <Paper
        sx={{
          px: 8, py: 5, mt: 4, mb: 3,
        }}
        elevation={0}
      >
        <CreateEventTitle title={t('events.documents')} eventName={eventName} />
        <Stack spacing={4.25}>
          <DocumentsForm
            subTitle={t('events.waivers')}
            type="waivers"
            name="waivers"
            control={control}
            documents={waivers}
            onAppendDocument={onAppendWaiver}
            onRemoveDocument={onRemoveWaiver}
            withConfirm={withConfirm}
            modalTitle={t('events.waiverDialogDeleteTitle')}
            modalDescription={t('events.waiverHint')}
            modalSecondDescription={t('events.secondWaiverHint')}
            isLoading={isWaiverLoading}
          />
          <Divider />
          <DocumentsForm
            subTitle={t('events.rules')}
            type="rules"
            name="rules"
            control={control}
            documents={rules}
            onAppendDocument={onAppendRule}
            onRemoveDocument={onRemoveRule}
            withConfirm={withConfirm}
            modalTitle={t('events.fileDialogDeleteTitle')}
            modalDescription={t('events.fileHint')}
            modalSecondDescription={t('events.secondFileHint')}
            isLoading={isRuleLoading}
          />
        </Stack>
      </Paper>
      <Stack spacing={1.5} direction="row" justifyContent="flex-end">
        <Button
          variant="outlined"
          onClick={onSkipStep}
          sx={{ width: 140 }}
        >
          {t('events.back')}
        </Button>
        <Button
          variant="contained"
          onClick={onCreateSubmit}
          disabled={isSubmitting}
          sx={{ width: 126 }}
        >
          {t('events.continue')}
        </Button>
      </Stack>
    </>
  );
}

export default DocumentsView;
