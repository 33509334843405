import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import eventsApi from './apis/eventsApi';
import { setUserOrganization } from '../user/slice';
import { logout } from '../auth/slice';
import { ORDERS } from '../../../constants/general';
import type IEventsSlice from './interfaces/IEventsSlice';
import type { IConfirmModal } from './interfaces/IEventsSlice';

export const initialState: IEventsSlice = {
  search: '',
  order: ORDERS.DESC,
  orderBy: 'startAt',
  status: '',
  topicId: '',
  createEvent: undefined,
  confirmModal: {
    isOpen: false,
    event: undefined,
    title: '',
    description: '',
    secondDescription: '',
    type: null,
  },
};

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setEventsState: (state, { payload }: PayloadAction<Partial<IEventsSlice>>) => ({ ...state, ...payload }),
    setConfirmModalData: (state, { payload }: PayloadAction<Partial<IConfirmModal>>) => {
      state.confirmModal = { ...state.confirmModal, ...payload };
    },
    resetConfirmModalData: (state) => {
      state.confirmModal = initialState.confirmModal;
    },
  },
  extraReducers: (builder): void => {
    builder.addCase(setUserOrganization, (): IEventsSlice => initialState);
    builder.addCase(logout, (): IEventsSlice => initialState);

    builder.addMatcher(
      eventsApi.endpoints.getEvent.matchFulfilled,
      (state, { payload }) => {
        state.createEvent = payload.data;
      },
    );
  },
});

export const {
  setEventsState,
  setConfirmModalData,
  resetConfirmModalData,
} = eventsSlice.actions;

export default eventsSlice.reducer;
