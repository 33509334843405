import React, { useEffect, useMemo, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { ListSubheader } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import { getFullNameByUser } from '../../utils/helpers';
import type { IStatisticiansDropdownSearch } from './interfaces/IStatisticiansDropdownSearch';
import { useLazyGetOrgMembersQuery } from '../../store/slices/poolPlays/apis/poolPlaysApi';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import COLORS from '../../constants/colors';
import { STATISTICIANS_MAX_PER_PAGE } from '../../constants/general';
import type IStatisticiansMinInfo from '../../store/slices/poolPlays/interfaces/IStatisticiansMinInfo';

function StatisticiansDropdownSearch({
  fieldError,
  value,
  onBlur,
  orgId,
  onChange,
}: IStatisticiansDropdownSearch) {
  const { t } = useTranslation();
  const [searchingValue, setSearchingValue] = useState('');
  const [chosenOrgMembers, setChosenOrgMembers] = useState<IStatisticiansMinInfo[]>([]);
  const [getOrgMembers, { data: orgMembers }] = useLazyGetOrgMembersQuery();

  const handleStatisticiansOpen = () => {
    if (orgId) {
      getOrgMembers({ orgId, search: searchingValue, perPage: STATISTICIANS_MAX_PER_PAGE });
    }
  };

  const handleStatisticiansChange = (
    statisticians: IStatisticiansMinInfo[],
    valueIds: number[],
  ) => {
    const combinedArray = [
      ...statisticians,
      ...orgMembers?.data.filter((obj) => (
        !statisticians.some((item) => item.id === obj.id)
      )) || [],
    ];
    const filteredArray = combinedArray.filter((obj) => (
      valueIds.includes(obj.id)
    ));
    if (filteredArray.length !== 0) {
      setChosenOrgMembers(filteredArray);
      onChange(filteredArray);
    }
  };

  const debounceSearch = useDebouncedCallback((currentValue: string) => {
    if (orgId) {
      getOrgMembers({ orgId, search: currentValue, perPage: STATISTICIANS_MAX_PER_PAGE });
    }
  });

  const handleInputChange = (inputValue: string) => {
    setSearchingValue(inputValue);
    debounceSearch(inputValue);
  };

  const statisticiansList = useMemo(() => {
    if (orgMembers) {
      const filteredOrgMembers = orgMembers.data
        .filter(({ id }) => !chosenOrgMembers
          .some(({ id: currentId }) => currentId === id));
      return [...chosenOrgMembers, ...filteredOrgMembers];
    }
    return [...chosenOrgMembers];
  }, [chosenOrgMembers, orgMembers]);

  useEffect(() => {
    if (orgId) {
      getOrgMembers({ orgId, search: searchingValue, perPage: STATISTICIANS_MAX_PER_PAGE });
    }
  }, [getOrgMembers, orgId, searchingValue]);

  useEffect(() => {
    setChosenOrgMembers(value);
  }, [value]);

  return (
    <FormControl error={!!fieldError} fullWidth>
      <InputLabel id="multiple-statisticians">
        {t('schedule.assignedStatisticians')}
      </InputLabel>
      <Select
        id="multiple-statisticians"
        labelId="multiple-statisticians-label"
        value={value.map(({ id }) => id)}
        onChange={(currentValue) => handleStatisticiansChange(value, currentValue.target.value as number[])}
        onBlur={onBlur}
        label={t('schedule.assignedStatisticians')}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {selected.map((item) => (
              <Chip
                key={item}
                label={getFullNameByUser(value.find(({ id }) => id === item)!)}
                sx={{ px: 0.5, height: 24 }}
              />
            ))}
          </Box>
        )}
        onOpen={handleStatisticiansOpen}
        multiple
      >
        <ListSubheader sx={{
          bgcolor: 'transparent', mt: '6px', height: 'fit-content',
        }}
        >
          <TextField
            sx={{
              height: 'fit-content',
              borderRadius: 1,
              bgcolor: COLORS.bcSecondaryDark,
              input: { padding: '6px' },
            }}
            placeholder={t('schedule.searchByNameOrLocation').toString()}
            onKeyDown={(e) => e.stopPropagation()}
            fullWidth
            value={searchingValue}
            onChange={(e) => handleInputChange(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </ListSubheader>
        {statisticiansList.map((item) => (
          <MenuItem
            autoFocus={!orgMembers}
            onKeyDown={(e) => e.stopPropagation()}
            key={item.id}
            value={item.id}
          >
            <Checkbox checked={value.some(({ id }) => id === item.id)} />
            <Avatar
              src={item.avatar}
              sx={{
                width: 40, height: 40, mr: 1.5, ml: 0.25,
              }}
            />
            <ListItemText primary={`${item.firstName} ${item.lastName}`} />
          </MenuItem>
        ))}
      </Select>
      {fieldError?.message ? <FormHelperText>{fieldError.message}</FormHelperText> : null}
    </FormControl>
  );
}

export default StatisticiansDropdownSearch;
