import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import SingleEventDatesForm from './components/SingleEventDatesForm';
import ReplaceDatesDialog from '../../components/SpectatorsTicketsForm/components/ReplaceDatesDialog';
import type { ISingleEventDatesView } from './interfaces/ISingleEventDatesView';

export default function SingleEventDatesView({
  control,
  eventName,
  eventStartDate,
  eventEndDate,
  discountsList,
  tournamentDates,
  tournamentDaysInterval,
  spectatorsOneDay,
  spectatorsWholeDays,
  onCreateSubmit,
  onCancelEdit,
  isSubmitting,
  isDatesDialogOpen,
  onDatesSave,
  onTournamentDaysClick,
  onDatesDialogClose,
  isLoading,
}: ISingleEventDatesView) {
  const { t } = useTranslation();

  return (
    <>
      <ReplaceDatesDialog
        isOpen={isDatesDialogOpen}
        eventStartDate={eventStartDate}
        eventEndDate={eventEndDate}
        onClose={onDatesDialogClose}
        dates={tournamentDates}
        allowedDates={tournamentDaysInterval}
        onSave={onDatesSave}
        isLoading={isLoading}
      />
      <Paper sx={{ px: 8, py: 5, mb: 3 }} elevation={0}>
        <SingleEventDatesForm
          control={control}
          title={t('events.eventDates')}
          eventName={eventName}
          discountsList={discountsList}
          spectatorsOneDay={spectatorsOneDay}
          spectatorsWholeDays={spectatorsWholeDays}
          tournamentDaysInterval={tournamentDaysInterval}
          onTournamentDaysClick={onTournamentDaysClick}
        />
      </Paper>
      <Stack direction="row" justifyContent="space-between" spacing={1.5}>
        <Button
          variant="outlined"
          color="error"
          onClick={onCancelEdit}
          sx={{ width: 140 }}
        >
          {t('events.cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={onCreateSubmit}
          disabled={isSubmitting}
          sx={{ width: 126 }}
        >
          {t('events.save')}
        </Button>
      </Stack>
    </>
  );
}
