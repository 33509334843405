import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import DocumentForm from '../../components/DocumentForm/DocumentsForm';
import type { ISingleEventWaiversView } from './interfaces/ISingleEventWaiversView';

function SingleEventWaiversView({
  control,
  waivers,
  onCancel,
  onAppendDocument,
  onRemoveDocument,
  onCreateSubmit,
  isSubmitting,
  withConfirm,
  isWaiverLoading,
}: ISingleEventWaiversView): React.ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <Paper
        sx={{ px: 8, py: 5, mb: 3 }}
        elevation={0}
      >
        <DocumentForm
          title={t('events.editWaivers')}
          hint={`${t('events.waiverHint')} ${t('events.secondWaiverHint')}`}
          type="waivers"
          name="waivers"
          control={control}
          documents={waivers}
          onAppendDocument={onAppendDocument}
          onRemoveDocument={onRemoveDocument}
          withConfirm={withConfirm}
          modalTitle={t('events.waiverDialogDeleteTitle')}
          modalDescription={t('events.waiverHint')}
          modalSecondDescription={t('events.secondWaiverHint')}
          isLoading={isWaiverLoading}
        />
      </Paper>
      <Stack spacing={1.5} direction="row" justifyContent="space-between">
        <Button
          color="error"
          onClick={onCancel}
          sx={{ width: 140 }}
        >
          {t('events.cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={onCreateSubmit}
          disabled={isSubmitting}
          sx={{ width: 126 }}
        >
          {t('events.save')}
        </Button>
      </Stack>
    </>
  );
}

export default SingleEventWaiversView;
