import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import TeamsWidget from './components/TeamsWidget';
import TeamsFilters from './components/TeamsFilters';
import type { ISingleEventTeamsView } from './interfaces/ISingleEventTeamsView';

function SingleEventTeamsView({
  control,
  teams,
  pagination,
  divisions,
  tabValue,
  onTabChange,
  onSearchChange,
  onSortChange,
  onApplyFilters,
  onResetFilters,
  onSearchClub,
  onSearchCoach,
  clubs,
  coaches,
}: ISingleEventTeamsView): React.ReactElement {
  const { t } = useTranslation();

  return (
    <Grid columnSpacing={5} rowSpacing={4.25} container>
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="h2" fontSize={24} fontWeight="bold">
            {t('events.teams')}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <TeamsWidget
          control={control}
          title={t('events.registeredTeams')}
          titleEndAdornment={(
            <TeamsFilters
              control={control}
              onSearchChange={onSearchChange}
              onApplyFilters={onApplyFilters}
              onResetFilters={onResetFilters}
              onSearchClub={onSearchClub}
              onSearchCoach={onSearchCoach}
              clubs={clubs}
              coaches={coaches}
            />
          )}
          teams={teams}
          pagination={pagination}
          divisions={divisions}
          tabValue={tabValue}
          onTabChange={onTabChange}
          onSortChange={onSortChange}
        />
      </Grid>
    </Grid>
  );
}

export default SingleEventTeamsView;
