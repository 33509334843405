enum Statuses {
  idle = 'idle',
  loading = 'loading',
  failed = 'failed',
}

export enum EventStatuses {
  Draft = 'Draft',
  Upcoming = 'Upcoming',
  Current = 'Current',
  Canceled = 'Canceled',
  Over = 'Over',
}

export enum PaymentConnectionStatuses {
  NEW = 'NEW',
  REQUIRED_ACTIONS = 'REQUIRED_ACTIONS',
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

export const ScheduleGameStatus = {
  FINISHED: 'FINISHED',
  UPCOMING: 'UPCOMING',
} as const;
export type TScheduleGameStatus = typeof ScheduleGameStatus[keyof typeof ScheduleGameStatus];

export default Statuses;
