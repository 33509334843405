import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import MainInfoSection from './components/MainInfoSection';
import EventDatesSection from './components/EventDatesSection';
import ContactDetailsSection from './components/ContactDetailsSection';
import SocialNetworksSection from './components/SocialNetworksSection';
import type { ICreateEventForm } from './interfaces/ICreateEventForm';

function CreateEventForm({
  title,
  control,
  onDeleteCoverPhoto,
  onCreateSubmit,
  onChangeWithRedirect,
}: ICreateEventForm): React.ReactElement {
  return (
    <>
      <Typography component="h2" variant="h4" mb={4}>
        {title}
      </Typography>
      <MainInfoSection
        control={control}
        onDeleteCoverPhoto={onDeleteCoverPhoto}
      />
      <Divider sx={{ my: 4 }} />
      <ContactDetailsSection control={control} />
      <Divider sx={{ my: 4 }} />
      <EventDatesSection
        control={control}
        onCreateSubmit={onCreateSubmit}
        onChangeWithRedirect={onChangeWithRedirect}
      />
      <Divider sx={{ my: 4 }} />
      <SocialNetworksSection control={control} />
    </>
  );
}

export default CreateEventForm;
