import { useTranslation } from 'react-i18next';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';

import PaperBlock from '../../../components/PaperBlock/PaperBlock';
import EmptyData from '../../../components/EmptyData/EmptyData';
import useStyles from './tableStyles-tss';
import { useAppSelector } from '../../../store/hooks/useApp';
import { selectThemeMode } from '../../../store/slices/ui/selectors';
import { AppThemeMode } from '../../../constants/AppThemes';
import { toPriceFormat } from '../../../utils/helpers';
import type { ISpectatorsTicketsTable } from '../interfaces/ISpectatorsTicketsTable';

const tableHeadCells = [
  'events.ADULT',
  'events.adultDiscounted',
  'events.SENIOR',
  'events.seniorDiscounted',
  'events.CHILD',
  'events.childDiscounted',
  'common.total',
];

function SpectatorsTicketsTable({
  rows,
  total,
}: ISpectatorsTicketsTable) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const themeMode = useAppSelector(selectThemeMode);
  const colorMode = themeMode === AppThemeMode.Dark ? AppThemeMode.Dark : AppThemeMode.Light;

  return (
    <PaperBlock
      title={t('events.soldSpectator')}
      colorMode={colorMode}
      whiteBg
      noMargin
      titleClassName={classes.title}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow className={classes.head}>
              <TableCell>{t('events.date')}</TableCell>
              {tableHeadCells.map((item) => (
                <TableCell
                  key={['SpectatorTable', item].join('_')}
                  align="center"
                >
                  {t(item)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.length
              ? (
                <>
                  {rows.map((spectator, index) => (
                    <TableRow
                      key={['SpectatorTable', index].join('_')}
                      className={classes.stripped}
                    >
                      <TableCell>
                        <Typography variant="body2">
                          {spectator.date}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {`${toPriceFormat(spectator.adultAmount)} (${spectator.adultCount})`}
                      </TableCell>
                      <TableCell align="center">
                        {`${toPriceFormat(spectator.adultDiscountedAmount)} (${spectator.adultDiscountedCount})`}
                      </TableCell>
                      <TableCell align="center">
                        {`${toPriceFormat(spectator.seniorAmount)} (${spectator.seniorCount})`}
                      </TableCell>
                      <TableCell align="center">
                        {`${toPriceFormat(spectator.seniorDiscountedAmount)} (${spectator.seniorDiscountedCount})`}
                      </TableCell>
                      <TableCell align="center">
                        {`${toPriceFormat(spectator.childAmount)} (${spectator.childCount})`}
                      </TableCell>
                      <TableCell align="center">
                        {`${toPriceFormat(spectator.childDiscountedAmount)} (${spectator.childDiscountedCount})`}
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2" fontWeight={700}>
                          {`${toPriceFormat(spectator.totalAmount)} (${spectator.totalCount})`}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow className={classes.stripped}>
                    <TableCell>
                      <Typography variant="body2" fontWeight={700}>
                        {t('common.total')}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2" fontWeight={700}>
                        {`${toPriceFormat(total.adultAmount)} (${total.adultCount})`}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2" fontWeight={700}>
                        {`${toPriceFormat(total.adultDiscountedAmount)} (${total.adultDiscountedCount})`}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2" fontWeight={700}>
                        {`${toPriceFormat(total.seniorAmount)} (${total.seniorCount})`}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2" fontWeight={700}>
                        {`${toPriceFormat(total.seniorDiscountedAmount)} (${total.seniorDiscountedCount})`}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2" fontWeight={700}>
                        {`${toPriceFormat(total.childAmount)} (${total.childCount})`}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2" fontWeight={700}>
                        {`${toPriceFormat(total.childDiscountedAmount)} (${total.childDiscountedCount})`}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2" fontWeight={700}>
                        {`${toPriceFormat(total.totalAmount)} (${total.totalCount})`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <EmptyData description={t('common.noData')} />
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </PaperBlock>
  );
}

export default SpectatorsTicketsTable;
