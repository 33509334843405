import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import InfoIcon from '@mui/icons-material/Info';
import LinearProgress from '@mui/material/LinearProgress';

import DropZone from '../DropZone/DropZone';
import DocumentCard from '../DocumentCard/DocumentCard';
import type { IDocumentsForm } from './interfaces/IDocumentsForm';
import type { IFileWithPath } from '../../interfaces/IFileWithPath';

function DocumentForm({
  title,
  subTitle,
  hint,
  type,
  control,
  documents,
  onAppendDocument,
  onRemoveDocument,
  withConfirm,
  modalTitle,
  modalDescription,
  modalSecondDescription,
  isLoading,
}: IDocumentsForm): React.ReactElement {
  const { t } = useTranslation();

  return (
    <Box component="form">
      <Stack spacing={4}>
        {title ? (
          <Typography component="h2" variant="h4">
            {title}
          </Typography>
        ) : null}
        {subTitle ? (
          <Typography component="h3" variant="h5">
            {subTitle}
          </Typography>
        ) : null}
        {hint ? (
          <Alert
            variant="filled"
            color="info"
            icon={<InfoIcon />}
            sx={{ mb: 4.25 }}
          >
            <Typography variant="body2">{hint}</Typography>
          </Alert>
        ) : null}
        <Stack spacing={1.5} alignItems="start">
          <Typography mb={2}>
            {t('events.document')}
          </Typography>
          <DropZone
            onUploadChange={(files) => onAppendDocument(files as IFileWithPath[])}
            accept={{ 'application/pdf': [] }}
            files={[]}
            maxSize={30000000}
            text={t('events.dragFileText')}
            desc={`${t('events.dragFileDesc')}`}
          />
        </Stack>
        {!isLoading ? documents?.map((document, index) => (
          <DocumentCard
            key={`DocumentCard_${document.id}`}
            name={`${type}.${index}.name`}
            isWaivers={type === 'waivers'}
            document={document}
            control={control}
            cardIndex={index}
            onRemove={onRemoveDocument}
            withConfirm={withConfirm}
            modalTitle={modalTitle}
            modalDescription={modalDescription}
            modalSecondDescription={modalSecondDescription}
          />
        )) : (
          <Stack spacing={1}>
            <LinearProgress />
            <LinearProgress />
            <LinearProgress />
          </Stack>
        )}
      </Stack>
    </Box>
  );
}

export default DocumentForm;
