import { createSelector } from '@reduxjs/toolkit';

import { parseISODateTime, toPriceFormat } from '../../../utils/helpers';
import type IBaseQueryResWithPagination from '../../types/IBaseQueryResWithPagination';
import type { IDivision } from './interfaces/IDivision';

export const selectEventDivisions = createSelector(
  (data: IBaseQueryResWithPagination<IDivision[]> | undefined) => data?.data || [],
  (divisions) => divisions.map((division) => ({
    ...division,
    divisionId: division.id,
    discount: division.discount ? {
      endAt: parseISODateTime(division.discount.endAt, division.timezone),
      price: division.discount.price.toString(),
    } : null,
    gender: division.division.gender,
    age: division.division.age,
    ageValidateFrom: parseISODateTime(division.ageValidateFrom, division.timezone),
    ticketCount: division.ticketCount,
    minTicketCountLimit: division.ticketCount,
    ticketPrice: toPriceFormat(division.ticketPrice),
  })),
);

export default selectEventDivisions;
