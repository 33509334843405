import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import type { Control } from 'react-hook-form';

import TabPanel from '../../components/TabPanel/TabPanel';
import GameSettingsDialog from './components/GameSettingsDialog';
import PlayOffGameSettingsDialog from './components/PlayOffGameSettingsDialog';
import ApplySettingsDialog from './components/ApplySettingsDialog';
import DivisionsSetupTab from './components/DivisionsSetupTab';
import PlayingFieldsTab from './components/PlayingFieldsTab';
import a11yProps from '../../utils/a11yProps';
import { GameSetupModals } from './interfaces/ILogisticsAndGameSetupView';
import type ILogisticsAndGameSetupView from './interfaces/ILogisticsAndGameSetupView';
import type IPoolPlayDivisionRules from '../../store/slices/poolPlays/interfaces/IPoolPlayDivisionRules';
import type IPlayOffDivisionRules from '../../store/slices/playOff/interfaces/IPlayOffDivisionRules';
import type { TDivisionPlayOffGameSettingFormSchema, TDivisionGameSettingFormSchema } from '../../utils/validators';

/**
 * Logistics and game setup page view
 */
function LogisticsAndGameSetupView({
  type,
  timezone,
  gameSettingsControl,
  tabValue,
  modalValue,
  fullInfoId,
  divisionsGameSetup,
  divisionRules,
  game7x7Settings,
  playingFields,
  timeFramesRange,
  poolPlaySetupTabs,
  onSetupAll,
  onCreateGameSetting,
  onTabChange,
  onCloseModal,
  onSetupClick,
  onBackClick,
  onSetupGamesSchedule,
  isDivisionRulesLoading,
  isGame7x7SettingsLoading,
  isGameSettingSubmitting,
  isSubmitting,
  isNextDisabled,
}: ILogisticsAndGameSetupView) {
  const { t } = useTranslation();

  const getTabContent = useCallback((index: number) => {
    switch (index) {
      case 0:
        return (
          <DivisionsSetupTab
            type={type}
            divisionsGameSetup={divisionsGameSetup}
            timeFramesRange={timeFramesRange}
            onSetupClick={onSetupClick}
            timezone={timezone}
          />
        );
      case 1:
        return (
          <PlayingFieldsTab
            type={type}
            playingFields={playingFields}
            fullInfoId={fullInfoId}
          />
        );
      default:
        return 'Ooopss';
    }
  }, [type, divisionsGameSetup, timeFramesRange, onSetupClick, timezone, playingFields, fullInfoId]);

  return (
    <>
      {modalValue === GameSetupModals.PoolPlayGameSettings && divisionRules ? (
        <GameSettingsDialog
          divisionsGameSetup={divisionsGameSetup}
          gameSettingsControl={gameSettingsControl as Control<TDivisionGameSettingFormSchema>}
          divisionRules={divisionRules as IPoolPlayDivisionRules}
          game7x7Settings={game7x7Settings}
          playingFields={playingFields}
          onCreateGameSetting={onCreateGameSetting}
          onClose={onCloseModal}
          isLoading={isDivisionRulesLoading || isGame7x7SettingsLoading}
          isOpen
          fullInfoId={fullInfoId}
        />
      ) : null}
      {modalValue === GameSetupModals.PlayOffGameSettings && divisionRules ? (
        <PlayOffGameSettingsDialog
          divisionsGameSetup={divisionsGameSetup}
          gameSettingsControl={gameSettingsControl as Control<TDivisionPlayOffGameSettingFormSchema>}
          divisionRules={divisionRules as IPlayOffDivisionRules}
          game7x7Settings={game7x7Settings}
          playingFields={playingFields}
          onCreateGameSetting={onCreateGameSetting}
          onClose={onCloseModal}
          isLoading={isDivisionRulesLoading || isGame7x7SettingsLoading}
          isOpen
          fullInfoId={fullInfoId}
        />
      ) : null}
      {modalValue === GameSetupModals.ApplySettings ? (
        <ApplySettingsDialog
          type={type}
          gameSettingsControl={gameSettingsControl as Control<TDivisionGameSettingFormSchema>}
          onApply={onSetupAll}
          onClose={onCloseModal}
          isLoading={isGameSettingSubmitting}
          isOpen
        />
      ) : null}
      <Paper
        sx={{ p: 3, mt: 4, mb: 3 }}
        elevation={0}
      >
        <Paper sx={{ mb: 3 }}>
          <Tabs
            value={tabValue}
            onChange={onTabChange}
            variant="fullWidth"
            scrollButtons="auto"
          >
            {poolPlaySetupTabs.map((tab, index) => (
              <Tab
                key={`PoolPlayTab_${tab.text}`}
                label={t(tab.text, tab.options)}
                {...a11yProps('tab', 'tabpanel', index)}
              />
            ))}
          </Tabs>
        </Paper>

        {poolPlaySetupTabs.map((tab, index) => (
          <TabPanel
            key={`PoolPlayTabPanel_${tab.text}`}
            value={tabValue}
            index={index}
            slugId="tabpanel"
            slugLabelledby="tab"
          >
            {getTabContent(index)}
          </TabPanel>
        ))}
      </Paper>

      <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
        <Button
          variant="outlined"
          onClick={onBackClick}
          sx={{ width: 140 }}
        >
          {t('common.back')}
        </Button>
        <Button
          variant="contained"
          onClick={onSetupGamesSchedule}
          sx={{ width: 140 }}
          disabled={isSubmitting || isNextDisabled}
        >
          {t('common.next')}
        </Button>
      </Stack>
    </>
  );
}

export default LogisticsAndGameSetupView;
