import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

import TextMask from '../../TextMask/TextMask';
import { DEFAULT_PHONE_MASK } from '../../../constants/textMasks';
import type { ICreateEventFormControl } from '../interfaces/ICreateEventForm';

function ContactDetailsSection({ control }: ICreateEventFormControl): React.ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <Typography component="h4" mb={2} fontSize={20} fontWeight={500}>
        {t('events.contactAndDetails')}
      </Typography>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 5 }}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="phone"
            control={control}
            render={({
              field: { value = '', onChange, onBlur },
              fieldState: { error: fieldError },
            }) => (
              <TextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                helperText={fieldError?.message}
                error={!!fieldError}
                label={t('events.phone')}
                variant="outlined"
                InputProps={{
                  inputComponent: TextMask as any,
                  inputProps: { mask: DEFAULT_PHONE_MASK },
                }}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="email"
            control={control}
            render={({
              field: { value = '', onChange, onBlur },
              fieldState: { error: fieldError },
            }) => (
              <TextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                helperText={fieldError?.message}
                error={!!fieldError}
                label={t('events.email')}
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="type"
            control={control}
            render={({
              field: { value, ...props },
              fieldState: { error: fieldError },
            }) => (
              <FormControl error={!!fieldError} fullWidth>
                <InputLabel htmlFor="outlined-topic">
                  {t('events.type')}
                </InputLabel>
                <Select
                  value={value}
                  variant="outlined"
                  id="outlined-topic"
                  label={t('events.type')}
                  disabled
                  fullWidth
                  {...props}
                >
                  <MenuItem value={value}>{t(`common.${value}`)}</MenuItem>
                </Select>
                {fieldError?.message ? <FormHelperText>{fieldError.message}</FormHelperText> : null}
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="about"
            control={control}
            render={({
              field: { value = '', onChange, ...props },
              fieldState: { error: fieldError },
            }) => (
              <TextField
                value={value}
                helperText={fieldError?.message || `${value?.length || 0}/200 ${t('symbols')}`}
                error={!!fieldError}
                label={`${t('events.about')} (${t('events.optional')})`}
                variant="outlined"
                multiline
                fullWidth
                onChange={(e) => onChange(e.target.value.slice(0, 200))}
                {...props}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default memo(ContactDetailsSection);
