import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { Controller, useController, useWatch } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { DateTime } from 'luxon';
import InputBase from '@mui/material/InputBase';
import { darken } from '@mui/material/styles';
import Box from '@mui/material/Box';

import type IFilters from '../interfaces/IFilters';
import { DEFAULT_DATE_FORMAT } from '../../../constants/general';
import SearchInput from '../../../components/SearchInput/SearchInput';
import type { ISearchInputHandler } from '../../../components/SearchInput/interfaces/ISearchInput';

const boxStyle = {
  '.MuiPaper-root': {
    background: '#757575',
    padding: '6px 12px',
  },
  '.MuiInputBase-input': {
    padding: '2px',
  },
  '.MuiButtonBase-root': {
    width: '25px',
    height: '25px',
  },
};

function Filters({
  control, dates, divisions, pools, statisticians, teams = [],
}: IFilters) {
  const { t } = useTranslation();
  const { field: searchedTeam } = useController({ name: 'search', control });
  const teamsIdsList = useWatch({ control, name: 'teams' });

  const getInputBase = useCallback((isActive: boolean) => (
    <InputBase
      sx={(theme) => ({
        px: 1,
        py: 1,
        width: '143px',
        borderRadius: 2,
        bgcolor: isActive ? theme.palette.primary.contrastText : darken(theme.palette.secondary.main, 0.5),
        color: isActive ? darken(theme.palette.primary.contrastText, 1) : 'inherit',
      })}
    />
  ), []);

  const teamsList = useMemo(
    () => [...teams]
      .sort((a, b) => ((teamsIdsList.includes(a.id) && !teamsIdsList.includes(b.id)) ? -1 : 1))
      .filter(({ name }) => name.toLowerCase().includes(searchedTeam.value.toLowerCase())),
    [teamsIdsList, searchedTeam],
  );

  const renderValuePools = (selected: number[]) => (
    `${t('schedule.pools')} `
      .concat(selected
        .map((valueId) => pools?.find(({ id }) => id === valueId)?.name)
        .join(', '))
  );

  const renderValueDivision = (selected: number[]) => (
    `${t('events.divisions')} `
      .concat(selected
        .map((valueId) => divisions?.find(({ id }) => id === valueId)?.name)
        .join(', '))
  );

  const renderValueTeams = (selected: number[]) => (
    `${t('events.teams')} `
      .concat(selected
        .map((valueId) => teams?.find(({ id }) => id === valueId)?.name)
        .join(', '))
  );

  const renderValueStatisticians = (selected: number[]) => (
    `${t('schedule.statisticians')} `
      .concat(selected
        .map((valueId) => {
          const { user } = statisticians?.find(({ id }) => id === valueId) || {};
          return [user?.firstName, user?.lastName].join(' ');
        })
        .join(', '))
  );

  const handleTeamsSearchChange = ({ search, onChange }: ISearchInputHandler) => {
    onChange(search);
  };

  return (
    <Grid container gap={2}>
      <Grid item>
        <Controller
          name="date"
          control={control}
          render={({
            field: { onChange, value, onBlur },
            fieldState: { error: fieldError },
          }) => (
            <FormControl error={!!fieldError} fullWidth>
              <Select
                id="date"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                input={getInputBase(!!value)}
              >
                {dates?.map((date) => (
                  <MenuItem key={date} value={date}>
                    {DateTime.fromISO(date).toFormat(DEFAULT_DATE_FORMAT)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Grid>

      <Grid item>
        <Controller
          name="divisions"
          control={control}
          render={({
            field: { onChange, value, onBlur },
            fieldState: { error: fieldError },
          }) => (
            <FormControl error={!!fieldError}>
              <Select
                id="divisions"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                renderValue={renderValueDivision}
                multiple
                input={getInputBase(!!value.length)}
                displayEmpty
              >
                {divisions?.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    <Checkbox checked={value.includes(id)} />
                    <ListItemText>{name}</ListItemText>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Grid>

      <Grid item>
        <Controller
          name="pools"
          control={control}
          render={({
            field: { onChange, value, onBlur },
            fieldState: { error: fieldError },
          }) => (
            <FormControl error={!!fieldError} fullWidth>
              <Select
                id="pools"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                renderValue={renderValuePools}
                multiple
                input={getInputBase(!!value.length)}
                displayEmpty
              >
                {pools?.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    <Checkbox checked={value.includes(id)} />
                    <ListItemText>{name}</ListItemText>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Grid>

      <Grid item>
        <Controller
          name="teams"
          control={control}
          render={({
            field: { onChange, value, onBlur },
            fieldState: { error: fieldError },
          }) => (
            <FormControl error={!!fieldError} fullWidth>
              <Select
                id="team-search"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                onOpen={() => searchedTeam.onChange('')}
                renderValue={renderValueTeams}
                multiple
                input={getInputBase(!!value.length)}
                displayEmpty
              >
                <Box>
                  <Box
                    mb={1}
                    px={2}
                    sx={boxStyle}
                    onKeyDown={(e) => e.stopPropagation()}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => e.stopPropagation()}
                  >
                    <SearchInput
                      control={control}
                      name="search"
                      onSearchChange={handleTeamsSearchChange}
                    />
                  </Box>
                </Box>
                {teamsList.map(({ id, name, profilePhoto }) => (
                  <MenuItem key={id} value={id}>
                    <Checkbox checked={value.includes(id)} />
                    <ListItemAvatar>
                      <Avatar alt={name} src={profilePhoto || undefined} />
                    </ListItemAvatar>
                    <ListItemText>{name}</ListItemText>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Grid>

      <Grid item>
        <Controller
          name="statisticians"
          control={control}
          render={({
            field: { onChange, value, onBlur },
            fieldState: { error: fieldError },
          }) => (
            <FormControl error={!!fieldError} fullWidth>
              <Select
                id="statisticians"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                renderValue={renderValueStatisticians}
                multiple
                input={getInputBase(!!value.length)}
                displayEmpty
              >
                <MenuItem value={-1}>
                  <Checkbox checked={value.includes(-1)} />
                  <Avatar
                    sx={{
                      width: 40, height: 40, mr: 1.5, ml: 0.25,
                    }}
                  />
                  <ListItemText>
                    {t('schedule.unassigned')}
                  </ListItemText>
                </MenuItem>
                {statisticians?.map(({ id, user }) => (
                  <MenuItem key={id} value={id}>
                    <Checkbox checked={value.includes(id)} />
                    <ListItemText>
                      {user.firstName}
                      {' '}
                      {user.lastName}
                    </ListItemText>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Grid>
    </Grid>
  );
}

export default Filters;
