import { createSelector } from '@reduxjs/toolkit';

import type IUi from './interfaces/IUi';
import type { RootState } from '../../types/TStore';

const selectUi = (state: RootState): IUi => state.ui;

export const selectThemeColor = createSelector(selectUi, (ui) => ui.themeColor);

export const selectThemeMode = createSelector(selectUi, (ui) => ui.themeMode);
