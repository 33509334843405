import { useTranslation } from 'react-i18next';
import { Controller, useWatch } from 'react-hook-form';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import EligibilityField from './EligibilityField';
import { DEFAULT_DATE_FORMAT } from '../../constants/general';
import type { IEligibilitySection } from './interfaces/IEligibilitySection';

function EligibilitySection({ control, isPublished }: IEligibilitySection): React.ReactElement {
  const { t } = useTranslation();
  const coachLimit = useWatch({ control, name: 'minCoachLimit' });
  const athletesLimit = useWatch({ control, name: 'minPlayerLimit' });

  return (
    <Paper sx={{ p: 3 }}>
      <Grid container rowSpacing={3} columnSpacing={5}>
        <Grid item xs={12}>
          <Typography component="h4" variant="h6">
            {t('events.eligibility')}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="ageValidateFrom"
            control={control}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error: fieldError },
            }) => (
              <DatePicker
                label={t('events.ageValidationDate')}
                value={value}
                onChange={onChange}
                onClose={onBlur}
                format={DEFAULT_DATE_FORMAT}
                slotProps={{
                  textField: {
                    error: !!fieldError,
                    fullWidth: true,
                    helperText: fieldError?.message,
                  },
                }}
              />
            )}
          />
          <Typography variant="body2" color="text.secondary" mt={2}>
            {t('events.ageValidationDateDesc')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container rowSpacing={3} columnSpacing={5}>
            <EligibilityField
              name="limitOfCoaches"
              control={control}
              label={t('events.limitOfCoaches')}
              limit={coachLimit}
              hint={t('events.limitOfCoachesHint')}
              publishedHint={t('events.limitOfCoachesEditHint', { limit: coachLimit })}
              isPublished={isPublished}
            />

            <EligibilityField
              name="limitOfAthletes"
              control={control}
              label={t('events.limitOfAthletes')}
              limit={athletesLimit}
              hint={t('events.limitOfAthletesHint')}
              publishedHint={t('events.limitOfAthletesEditHint', { limit: athletesLimit })}
              isPublished={isPublished}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default EligibilitySection;
