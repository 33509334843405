import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ isSmall: boolean | undefined }>()(
  (theme, { isSmall }) => {
    const pPaperY = theme.spacing(isSmall ? 1.5 : 2);
    const pPaperRight = theme.spacing(isSmall ? 2 : 6);

    return ({
      paper: {
        minWidth: 276,
        position: 'relative',
        padding: `${pPaperY} ${pPaperRight} ${pPaperY} ${theme.spacing(2)}`,
        backgroundColor: '#1C1C1C',
        borderRadius: theme.rounded.small,
        gap: theme.spacing(2),
        display: 'flex',
        flexDirection: isSmall ? 'row' : 'column',
        alignItems: isSmall ? 'center' : 'normal',
      },
      editBtn: {
        position: 'absolute',
        right: 10,
        top: 10,
      },
      wrapper: {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      desc: {
        fontSize: theme.typography.fontSize,
        color: theme.palette.secondary.main,
        textDecoration: 'none',
        wordBreak: 'break-all',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    });
  },
);

export default useStyles;
