import { NOTIFICATION_TYPE } from './general';

const NOTIFICATIONS = [
  {
    title: 'notifications.REGULAR',
    text: 'notifications.regularText',
    value: NOTIFICATION_TYPE.REGULAR,
  },
  {
    title: 'notifications.CRITICAL',
    text: 'notifications.criticalText',
    value: NOTIFICATION_TYPE.CRITICAL,
  },
];

export default NOTIFICATIONS;
