import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import PlayingFieldsTable from '../../LogisticsAndGameSetup/components/PlayingFieldsTable';
import { ScheduleTypes } from '../../../constants/general';
import type IFieldsDialog from '../interfaces/IFieldsDialog';

function FieldsDialog({
  type, open, fields, onClose, fullInfoId,
}: IFieldsDialog) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%', maxWidth: 1200, backgroundColor: '#292929', backgroundImage: 'none',
        },
      }}
    >
      <DialogTitle>
        {type === ScheduleTypes.PLAY_OFF
          ? t('schedule.editFieldsForPlayOff')
          : t('schedule.editFieldsForPoolPlay')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 24,
          top: 16,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <PlayingFieldsTable type={type} playingFields={fields} fullInfoId={fullInfoId} />
      </DialogContent>
    </Dialog>
  );
}

export default FieldsDialog;
