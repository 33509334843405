import { useEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import SplashScreen from '../../components/SplashScreen/SplashScreen';
import PaymentOptionsView from './PaymentOptionsView';
import { useAppSelector } from '../../store/hooks/useApp';
import { selectUserOrg } from '../../store/slices/user/selectors';
import {
  useDeleteOrganizationPaymentMutation,
  useGetOrganizationPaymentQuery,
  useSetOrganizationPaymentMutation,
} from '../../store/slices/payment/api/paymentApi';
import { PaymentConnectionStatuses } from '../../constants/Statuses';

function PaymentOptions() {
  const org = useAppSelector(selectUserOrg);

  const [
    setOrganizationPayment,
    { data: connectData, isLoading: isLoadingConnect },
  ] = useSetOrganizationPaymentMutation();
  const stripeConnectUrl = connectData?.data.redirectUrl;
  const [deleteOrganizationPayment] = useDeleteOrganizationPaymentMutation();
  const { data, isLoading } = useGetOrganizationPaymentQuery(
    org?.id ? { orgId: org?.id as number } : skipToken,
  );

  useEffect(() => {
    if (stripeConnectUrl) {
      window.location.assign(stripeConnectUrl);
    }
  }, [stripeConnectUrl]);

  const handleConnect = () => {
    setOrganizationPayment({
      orgId: org?.id as number,
      returnUrl: window.location.href,
    });
  };

  const handleDisconnect = () => {
    deleteOrganizationPayment({ orgId: org?.id as number });
  };

  const status = data?.data.status;
  const isConnected = status === PaymentConnectionStatuses.CONNECTED;
  const isRequiredActions = status === PaymentConnectionStatuses.REQUIRED_ACTIONS;
  const isShowDisconnect = isConnected || isRequiredActions;

  const stripeLink = isRequiredActions
    ? process.env.REACT_APP_STRIPE_DASHBOARD
    : stripeConnectUrl;

  if (isLoading || isLoadingConnect) {
    return <SplashScreen />;
  }

  return (
    <PaymentOptionsView
      onConnect={handleConnect}
      onDisconnect={handleDisconnect}
      isShowDisconnect={isShowDisconnect}
      status={status}
      stripeConnectUrl={stripeLink}
    />
  );
}

export default PaymentOptions;
