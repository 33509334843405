import TablePagination from '@mui/material/TablePagination';

import PaperBlock from '../../../components/PaperBlock/PaperBlock';
import TeamsTable from './TeamsTable';
import TableTabs from '../../../components/TableTabs/TableTabs';
import { useAppSelector } from '../../../store/hooks/useApp';
import { selectThemeMode } from '../../../store/slices/ui/selectors';
import { AppThemeMode } from '../../../constants/AppThemes';
import type ITeamsWidget from '../interfaces/ITeamsWidget';

function TeamsWidget({
  control,
  title,
  teams,
  titleEndAdornment,
  isInvitedTable,
  pagination,
  divisions,
  tabValue,
  onTabChange,
  onSortChange,
}: ITeamsWidget): React.ReactElement {
  const themeMode = useAppSelector(selectThemeMode);
  const colorMode = themeMode === AppThemeMode.Dark ? AppThemeMode.Dark : AppThemeMode.Light;

  return (
    <>
      <PaperBlock
        title={title}
        endAdornment={titleEndAdornment}
        colorMode={colorMode}
        whiteBg
        noMargin
      >
        {divisions.length > 2 && (
          <TableTabs
            tabs={divisions}
            tabValue={tabValue}
            onTabChange={onTabChange}
          />
        )}
        <TeamsTable
          control={control}
          teams={teams}
          onSortChange={onSortChange}
          isInvitedTable={isInvitedTable}
        />
      </PaperBlock>
      <TablePagination
        rowsPerPageOptions={pagination.perPageOptions}
        component="div"
        count={pagination.total}
        rowsPerPage={pagination.perPage}
        page={pagination.page}
        onPageChange={pagination.handleChangePage}
        onRowsPerPageChange={pagination.handleChangePerPage}
      />
    </>
  );
}

export default TeamsWidget;
