import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DEFAULT_DATE_FORMAT } from '../../../constants/general';
import type { IEventEligibilityDateSection } from '../interfaces/IEventEligibilityDateSection';

export default function EventEligibilityDateSection({
  control,
}: IEventEligibilityDateSection) {
  const { t } = useTranslation();

  return (
    <Paper sx={{ p: 3 }}>
      <Grid container rowSpacing={3} columnSpacing={5}>
        <Grid item xs={12}>
          <Typography component="h4" variant="h6">
            {t('events.eligibility')}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="ageCutoffDate"
            control={control}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error: fieldError },
            }) => (
              <DatePicker
                label={t('events.ageValidationDate')}
                value={value}
                onChange={onChange}
                onClose={onBlur}
                slotProps={{
                  textField: {
                    error: !!fieldError,
                    helperText: fieldError?.message,
                    fullWidth: true,
                    onBlur,
                  },
                }}
                format={DEFAULT_DATE_FORMAT}
              />
            )}
          />
          <Typography variant="body2" color="text.secondary" mt={2}>
            {t('events.ageValidationDateDesc')}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
