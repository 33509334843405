export default {
  sendNotification: 'Send Notification',
  notificationSettings: 'Notification settings',
  selectRecipients: 'Select Recipients',
  all: 'All',
  coaches: 'Coaches',
  athletesAndGuardians: 'Athletes & Guardians',
  organizationMembers: 'Organization members',
  teams: 'Teams',
  spectators: 'Spectators',
  notificationType: 'Notification Type',
  REGULAR: 'Regular',
  regularText: "Users will be informed via push and inbox notification. Example: 'Hey there! Head to our partners' hot dog stand before entering the stadium and snag a 10% discount – just flash your participant bracelet!",
  CRITICAL: 'Critical',
  criticalText: "Users will be informed via SMS, email, push, and inbox notification. Example: 'Due to severe weather in the area the tournament has been delayed 2 hours. Stay tuned for updated information on the event page",
  notificationText: 'Notification Text',
  submitNotification: 'Submit notification',
  subject: 'Subject',
  message: 'Message',
  notificationsLog: 'Notifications Log',
  date: 'Date',
  recipients: 'Recipients',
  type: 'Type',
  notificationTitle: 'Notification Title',
  notificationDialogTitle: 'Are you sure you want to send this notification?',
  cancel: 'Cancel',
  send: 'Send',
  ALL: 'All',
  COACH: 'Coaches',
  ATHLETE_AND_GUARDIAN: 'Athletes & Guardians',
  ORGANIZATION_MEMBER: 'Organization members',
  TEAM: 'Teams',
  SPECTATOR: 'Spectators',
};
