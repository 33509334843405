import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import EarnedSection from './components/EarnedSection';
import PieWidget from '../../components/PieWidget/PieWidget';
import RevenueWidget from '../../components/RevenueWidget/RevenueWidget';
import CountsSection from './components/CountsSection';
import DevisionsRegistrationWidget from './components/DevisionsRegistrationWidget';
import TeamTicketsWidget from './components/TeamTicketsWidget';
import SpectatorsTicketsTable from './components/SpectatorsTicketsTable';
import type { ISingleEventAnalyticsView } from './interfaces/ISingleEventAnalyticsView';
import RefundsTable from './components/RefundsTable';
import SpectatorByPassChart from './components/SpectatorByPassChart';

function SingleEventAnalyticsView({
  earnings,
  pieEarnedData,
  teamsCapacity,
  eventRevenue,
  membersCount,
  divisions,
  tabValue,
  onTabChange,
  teamsEarnedData,
  spectatorsEarnedData,
  refundsData,
  pieSpectatorsTypeData,
  pieSpectatorsPassData,
}: ISingleEventAnalyticsView) {
  const { t } = useTranslation();

  return (
    <Grid columnSpacing={5} rowSpacing={4} container>
      <Grid item xs={12}>
        <Typography variant="h5" fontWeight={700}>
          {t('events.overview')}
        </Typography>
      </Grid>
      <EarnedSection {...earnings} />
      <Grid item xs={12} md={6}>
        <PieWidget
          title={t('dashboard.totalEarned')}
          pieData={pieEarnedData}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DevisionsRegistrationWidget
          teamsCapacity={teamsCapacity}
        />
      </Grid>
      <Grid item xs={12}>
        <RevenueWidget
          title={t('events.eventRevenue')}
          chartData={eventRevenue}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" fontWeight={700}>
          {t('events.teamTickets')}
        </Typography>
      </Grid>
      <CountsSection {...membersCount} />
      <Grid item xs={12}>
        <TeamTicketsWidget
          divisions={divisions}
          tabValue={tabValue}
          onTabChange={onTabChange}
          teamsEarnedData={teamsEarnedData}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" fontWeight={700}>
          {t('events.spectatorTickets')}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <PieWidget
          title={t('events.spectatorTickets')}
          pieData={pieSpectatorsTypeData}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <SpectatorByPassChart
          pieData={pieSpectatorsPassData}
        />
      </Grid>
      <Grid item xs={12}>
        <SpectatorsTicketsTable {...spectatorsEarnedData} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" fontWeight={700}>
          {t('events.refunds')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RefundsTable
          refundsData={refundsData}
        />
      </Grid>
    </Grid>
  );
}

export default SingleEventAnalyticsView;
