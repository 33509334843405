import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import CountWidget from '../../../components/CountWidget/CountWidget';
import { toPriceFormat } from '../../../utils/helpers';
import type { ICountsSection } from '../interfaces/ICountsSection';

function CountsSection({
  teamsCount,
  playersCount,
  coachesCount,
  earningsTeamTickets,
}: ICountsSection) {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} md={6} xl={3}>
        <CountWidget
          title={t('events.earnedTEAM')}
          value={toPriceFormat(earningsTeamTickets)}
        />
      </Grid>
      <Grid item xs={12} md={6} xl={3}>
        <CountWidget
          title={t('events.teamsAmount')}
          value={teamsCount.toString()}
        />
      </Grid>
      <Grid item xs={12} md={6} xl={3}>
        <CountWidget
          title={t('events.teamsAthletesAmount')}
          value={playersCount.toString()}
        />
      </Grid>
      <Grid item xs={12} md={6} xl={3}>
        <CountWidget
          title={t('events.teamsCoachesAmount')}
          value={coachesCount.toString()}
        />
      </Grid>
    </>
  );
}

export default CountsSection;
